import cn from 'classnames';
import React from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { UnstyledButton } from '@react-md/button';
import { Typography } from '@react-md/typography';
import { TextIconSpacing } from '@react-md/icon';
import { Fade } from '@components/animations';
import { AlertIcon } from '@components/alert';
import { Icons } from '@components/icons';
import { DeleteIconButton } from '@components/common-buttons';
import { selectReadyPreselection } from '@modules/preselection';
import { selectParentModelById, ModelRow, ModelLabel, ModelThumb } from '@modules/models';
import {
    removeModel,
    selectPreselectionModeOn,
    selectWidgetViewedModels,
    selectCurrentProduct,
    quotationActions,
} from '@modules/quotation';
import { useWidgetModelHasAlerts } from '@pages/widget/order/hooks';
import { lineItemId } from '../helpers';

import styles from './line-item.module.scss';

type ViewModelStatus = 'viewed' | 'ready' | 'analyzed';

const LineItemModelStatus = ({ status }: { status: ViewModelStatus }) => {
    const { t } = useTranslation();

    const props = {
        ready: {
            className: 'rmd-typography--theme-success',
            label: t('labels.ready', 'Ready'),
            icon: <Icons.CheckCircleThin />,
        },
        analyzed: {
            className: 'rmd-typography--theme-warning',
            label: t('labels.analyzing', 'Analyzing...'),
            icon: <Icons.CheckCircleThin />,
        },
        viewed: null,
    }[status];

    if (!props) return null;

    const { className, label, icon } = props;

    return (
        <Typography type="headline-6" component={'div'} className={cn(className, styles.status)}>
            <TextIconSpacing icon={icon}>{label}</TextIconSpacing>
        </Typography>
    );
};

export interface LineItemModelProps extends React.HTMLAttributes<HTMLLIElement> {
    in?: boolean;
    delay: number;
    modelId: number;
    isCurrentModel: boolean;
}

export const LineItemModel: React.FC<LineItemModelProps> = ({ modelId, isCurrentModel, children, ...rest }) => {
    const dispatch = useAppDispatch();
    const preselectionModeOn = useAppSelector(selectPreselectionModeOn);
    const model = useAppSelector(state => selectParentModelById(state, modelId));
    const isActive = useAppSelector(state => isCurrentModel && !selectCurrentProduct(state));
    const isModelViewed = useAppSelector(state => selectWidgetViewedModels(state).includes(modelId));
    const status: ViewModelStatus = useAppSelector(state => {
        const readyPreselection = selectReadyPreselection(state);
        return !preselectionModeOn || isModelViewed
            ? 'viewed'
            : Boolean(readyPreselection[modelId])
            ? 'ready'
            : 'analyzed';
    });

    const isAnalyzed = status === 'analyzed';

    const { warning, error } = useWidgetModelHasAlerts(modelId);

    return (
        <Fade
            as="li"
            // mountOnEnter
            unmountOnExit
            className={cn(styles.row, {
                [styles.active]: isActive,
            })}
            {...rest}
        >
            <UnstyledButton
                className={styles.btn}
                disabled={isAnalyzed || isActive}
                onClick={() => dispatch(quotationActions.setModel(modelId))}
            />
            <ModelRow className={styles.grid}>
                <ModelThumb size={'small'} thumb={model?.thumb_120x120}>
                    {(warning || error) && (
                        <div className={styles.warnings}>
                            {error && <AlertIcon type={'error'} className={styles.error} width={16} />}
                            {warning && <AlertIcon type={'warning'} className={styles.warning} width={16} />}
                        </div>
                    )}
                </ModelThumb>

                <ModelLabel label={model?.title} />

                <div className={styles.actions}>
                    <LineItemModelStatus status={status} />

                    <DeleteIconButton
                        id={lineItemId('delete_model', modelId)}
                        className={cn(styles.action, styles.delete)}
                        onClick={e => {
                            e.stopPropagation();
                            model && dispatch(removeModel({ model, isCurrentModel }));
                        }}
                    />
                </div>

                {children}
            </ModelRow>
        </Fade>
    );
};
