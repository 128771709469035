import { matchPath } from 'react-router';
import { ROUTES, IQT_MODE_ROUTES, SHORT_IQT_MODE_ROUTES, NON_PERSISTED_ROUTES_NAMES } from '@constants';

export function getCompanyNameByPath() {
    const urlObj = new URL(window.location.href);
    const pathname = urlObj.pathname;
    const match = matchPath('/:company/*', pathname);

    if (match && match.params?.company) {
        return match.params.company;
    }

    return '';
}

export function matchesRoutes(pathname: string, routes: Record<string, string> = ROUTES) {
    return Object.values(routes).some(route => matchPath(route, pathname));
}

export function matchesIqtRoutes(pathname: string) {
    return matchesRoutes(pathname, IQT_MODE_ROUTES);
}

export function matchesShortIqtRoutes(pathname: string) {
    return matchesRoutes(pathname, SHORT_IQT_MODE_ROUTES);
}

export function matchesAnyRoutes(pathname: string) {
    return matchesRoutes(pathname) || matchesIqtRoutes(pathname) || matchesShortIqtRoutes(pathname);
}

export function findPathMatchInRoutes(pathname: string, list: ReadonlyArray<keyof typeof ROUTES> = []) {
    return list.map(route => matchPath(ROUTES[route], pathname)).find(match => match);
}

export function findNonPersistedRoutes(pathname: string) {
    return findPathMatchInRoutes(pathname, NON_PERSISTED_ROUTES_NAMES);
}

export function matchesOrderPage(pathname: string) {
    return [ROUTES.widgetOrder, IQT_MODE_ROUTES.widgetOrder, SHORT_IQT_MODE_ROUTES.widgetOrder].some(route =>
        matchPath(route, pathname),
    );
}
