import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AuthPopup } from '@types';
import { AuthFormFooter, AuthFormFooterMessage, AuthFormLink } from '../components';
import { AuthFooterProps } from '../types';

export const OtpFormFooter: React.FC<AuthFooterProps> = ({ type, children }) => {
    const { t } = useTranslation();
    return (
        <AuthFormFooter>
            <AuthFormFooterMessage>
                <Trans
                    i18nKey="messages.passwordSignInLink"
                    components={{
                        ln: <AuthFormLink auth={AuthPopup.SignIn} type={type} />,
                    }}
                    defaults={'Have a password? <ln>Sign In</ln>'}
                />
            </AuthFormFooterMessage>

            {children}
        </AuthFormFooter>
    );
};
