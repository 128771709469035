import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@react-md/button';
import { Tooltipped } from '@react-md/tooltip';
import { ModelRow, ModelLabel, ModelThumb, ModelStatus } from '@modules/models';
import { Icons } from '@components/icons';
import { getNodeIdComposer } from '@utils';
import { RootPrefix } from '@pages/widget/order/constants';
import { useModelRejectedStatus } from '../../hooks';
import { RejectedModelProps } from '../../types';

import styles from './uploaded-model.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'rejected_model');

export const RejectedModel: React.FC<RejectedModelProps> = ({
    uuid,
    label,
    status,
    onDelete,
    error,
    className,
    ...rest
}) => {
    const { t } = useTranslation();
    const modelStatus = useModelRejectedStatus(status);

    return (
        <ModelRow {...rest} className={cn(styles.row, className)}>
            <ModelThumb size={'small'} isRejected={true} />
            <ModelLabel label={label}>
                <ModelStatus status={modelStatus.status}>{error || modelStatus.label}</ModelStatus>
            </ModelLabel>
            <Tooltipped
                id={_id('delete', uuid)}
                tooltip={t('buttons.delete', 'Delete')}
                position="above"
                lineWrap={false}
                spacing={10}
                disableAutoSpacing
            >
                <Button
                    buttonType="icon"
                    theme="error"
                    // theme="clear"
                    themeType="flat"
                    className={cn('rmd-button--icon-circle', styles.delete)}
                    onClick={() => onDelete(uuid)}
                    aria-label={t('buttons.delete', 'Delete')}
                >
                    <Icons.Trash />
                </Button>
            </Tooltipped>
        </ModelRow>
    );
};
