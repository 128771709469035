import difference from 'lodash/difference';
import { createSelector } from 'reselect';
import { SuitableMaterials, RootState } from '@types';
import { selectPreselectionModeOn, selectWidgetModelId } from '../quotation';
import { selectReadyPreselection } from '../preselection';

export const selectIsPollingSuitableMaterialsActive = (state: RootState) => state.suitableMaterials.isPollingActive;
export const selectSuitableMaterialsData = (state: RootState) => {
    return state.suitableMaterials.data;
};

export const selectReadySuitableMaterials = createSelector(selectSuitableMaterialsData, data =>
    Object.entries(data).reduce((acc, [modelId, value]) => {
        if (!value.is_ready) {
            return acc;
        }

        return { ...acc, [modelId]: value };
    }, {} as Record<string, SuitableMaterials>),
);

// export const selectReadySuitableMaterialsModelsIds = createSelector(selectSuitableMaterials, data =>
//     Object.entries(data).reduce<number[]>((acc, [key, value]) => {
//         if (value.is_ready) {
//             return [...acc, parseInt(key)];
//         }
//
//         return acc;
//     }, []),
// );

export const selectModelsIdsForSuitableMaterials = createSelector(
    selectReadyPreselection,
    selectSuitableMaterialsData,
    (preselection, suitableMaterials) => {
        const modelsIds = Object.entries(preselection).map(([modelId, _]) => parseInt(modelId));
        const suitableMaterialsEntries = Object.entries(suitableMaterials);

        if (!suitableMaterialsEntries.length) {
            return modelsIds;
        }

        const requestedModelsIds = Object.keys(suitableMaterials).map(id => parseInt(id));
        const newReadyPreselection = difference(modelsIds, requestedModelsIds);

        return suitableMaterialsEntries.reduce<number[]>((acc, [id, suitableMaterial]) => {
            if (!suitableMaterial.is_ready) {
                return [...acc, parseInt(id)];
            }

            return acc;
        }, newReadyPreselection);
    },
);

const suitableMaterialsDefault = { is_ready: false, suitable_materials: [] };

export const selectSuitableMaterials = (state: RootState) => {
    const modelId = selectWidgetModelId(state);
    const data = selectSuitableMaterialsData(state);

    if (!modelId) return suitableMaterialsDefault;

    return data[modelId] || suitableMaterialsDefault;
};

export const selectHasNotReadySuitableMaterial = (state: RootState) => {
    const preselectionModeOn = selectPreselectionModeOn(state);
    const suitableMaterial = selectSuitableMaterials(state);
    return preselectionModeOn && !suitableMaterial.is_ready;
};
