import React from 'react';
import { useAppSelector } from '@app/hooks';
import { selectPreselectionModeOn } from '../selectors';
import { usePreselectionModeListeners, useRepeatSpecsModeListeners } from '../listeners';

const PreselectionMode = () => {
    usePreselectionModeListeners();
    return null;
};

const RepeatSpecsMode = () => {
    useRepeatSpecsModeListeners();
    return null;
};

export const MaterialSelectionMode = () => {
    const preselectionModeOn = useAppSelector(selectPreselectionModeOn);
    return preselectionModeOn ? <PreselectionMode /> : <RepeatSpecsMode />;
};
