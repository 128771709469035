import React from 'react';
import { LineItems, LineItem } from '@components/line-items';
import type { Purchase } from '@types';

interface Props {
    fields: Purchase['config_formatted_json'];
}

export const PurchaseSpecification = ({ fields }: Props) => {
    return (
        <LineItems>
            {fields.map(({ key, value }) => (
                <LineItem key={key} component="li" label={key} value={value} className="block" />
            ))}
        </LineItems>
    );
};
