import { useTranslation } from 'react-i18next';
import { FeatureContent } from '@components/feature-content';
import { viewerSectionId } from '../../helpers';

import styles from './model-viewer-rotation-description.module.scss';

interface ModelViewerRotationDescriptionProps {
    isShown: boolean;
    isIqtMode?: boolean;
}

export function ModelViewerRotationDescription({ isShown, isIqtMode = false }: ModelViewerRotationDescriptionProps) {
    const { t } = useTranslation();

    const RotationWarning = (
        <FeatureContent
            contentKey={viewerSectionId('rotation_warning')}
            fallback={t(
                'messages.rotationWarning',
                "When the model is rotated, it's checked to ensure it still fits within the envelope.",
            )}
        />
    );

    const CenterOfMassWarning = (
        <FeatureContent
            contentKey={viewerSectionId('center_of_mass_warning')}
            fallback={t(
                'messages.centerOfMassWarning',
                'After rotating the model, it will align itself based on the center of mass rule.',
            )}
        />
    );

    return isShown ? (
        <div className={styles.description}>
            {isIqtMode ? (
                <p>{RotationWarning}</p>
            ) : (
                <ul>
                    <li>{RotationWarning}</li>
                    <li>{CenterOfMassWarning}</li>
                </ul>
            )}
        </div>
    ) : null;
}
