import React, { ReactNode } from 'react';
import { TFunction } from 'i18next';
import { OrderStatuses } from '@types';

export function useOrderSubmitLabels(t: TFunction): { [Status in `${OrderStatuses}`]?: ReactNode } {
    return {
        [OrderStatuses.FirmOfferSent]: t('buttons.sendOffer', 'Send offer'),
        [OrderStatuses.Placed]: t('links.confirmOrder', 'Confirm order'),
        [OrderStatuses.WaitingForReview]: t('buttons.submitForReview', 'Submit for review'),
        [OrderStatuses.Created]: t('buttons.saveAsDraft', 'Save as draft'),
    };
}
