import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterOrderStatusKeys, Order, PagedResponse, StoreResponseErrors } from '@types';

interface State {
    data?: PagedResponse<Order>;
    isLoading: boolean;
    error: boolean;
}

const initialState: State = {
    isLoading: false,
    error: false,
};

export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        load: (state, action: PayloadAction<{ status: FilterOrderStatusKeys; page: number }>) => {
            state.isLoading = true;
        },
        loadSuccess: (state, action: PayloadAction<PagedResponse<Order>>) => {
            state.isLoading = false;
            state.data = action.payload;
            state.error = false;
        },
        loadFailure: (state, action: PayloadAction<StoreResponseErrors>) => {
            state.isLoading = false;
            state.error = true;
        },
    },
});

export const ordersActions = ordersSlice.actions;
