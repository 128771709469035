import isBoolean from 'lodash/isBoolean';
import { Navigate, NavigateProps, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from '@app/hooks';
import { Fallback } from '@components/fallback';
import { AppSettings, AppLocationState, FCC } from '@types';
import { useBuildLocation, useRedirectUrl } from '@hooks';
import { reverse } from '@utils';
import { selectAppSettings } from '@modules/app';
import { selectIsAuthenticated } from '@modules/auth';
import { Permissions, selectAuthorizedUser, selectUserPermissions } from '@modules/user';

export interface PrivateRouteChecker {
    settings: AppSettings;
    isAuthenticated: boolean;
    permissions: Permissions;
}

// for routes with flexible checks
interface PrivateRouteProps extends Partial<NavigateProps> {
    isAllowed: boolean | ((props: PrivateRouteChecker) => boolean);
}

export const PrivateRoute: FCC<PrivateRouteProps> = ({
    isAllowed: _isAllowed,
    children,
    to = reverse('forbidden'),
    ...rest
}) => {
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const settings = useAppSelector(selectAppSettings);
    const permissions = useAppSelector(selectUserPermissions);

    const isAllowed = isBoolean(_isAllowed) ? _isAllowed : _isAllowed({ isAuthenticated, settings, permissions });

    if (!isAllowed) {
        return <Navigate replace to={to} {...rest} />;
    }

    return children ? <>{children}</> : <Outlet />;
};

// for auth required routes
interface AuthRouteProps {
    customAuthChecker?: (props: PrivateRouteChecker) => boolean; // e.g. checks anonymous access
}

export const AuthRoute: FCC<AuthRouteProps> = ({
    children,
    customAuthChecker = ({ isAuthenticated }) => isAuthenticated,
}) => {
    const signInLocation = useBuildLocation({
        to: reverse('signIn'),
        setFromLocation: true,
    });

    return (
        <PrivateRoute isAllowed={customAuthChecker} {...signInLocation}>
            {children ? children : <Outlet />}
        </PrivateRoute>
    );
};

// for anonymous
interface GuestRouteProps {}

export const GuestRoute: FCC<GuestRouteProps> = ({ children }) => {
    const location = useLocation();
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const authUser = useAppSelector(selectAuthorizedUser);
    const redirectUrl = useRedirectUrl();

    const next = (location.state as AppLocationState)?.from || redirectUrl || reverse('widgetUpload');

    if (!isAuthenticated) return children ? <>{children}</> : <Outlet />;

    // we have to wait for the user to get permissions
    return authUser ? <Navigate to={next} replace /> : <Fallback />;
};
