import { AxiosError } from 'axios';
import { concat, from, of, EMPTY } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom, finalize, tap } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { isAnyOf } from '@reduxjs/toolkit';
import { AppEpic, InvalidateLevel, SignupForm, SignInFormErrors, SignupFormErrors } from '@types';
import { AuthService } from '@services';
import { formatResponseErrors, filterEmptyValues } from '@utils';
import { ROUTES } from '@constants';
import { appActions, selectAppCompanyName, getAppSettingsObservable$ } from '../app';
import { uploadModelsActions, selectUploadJob } from '../upload-models';
import { authActions } from './slice';

const initAppOnGettingTokenEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(
            isAnyOf(
                authActions.obtainAnonymousTokenSuccess,
                authActions.obtainSessionTokenSuccess,
                authActions.silentLoginSuccess,
            ),
        ),
        switchMap(action => getAppSettingsObservable$(state$)),
    );

const rebindUploadJobEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(isAnyOf(authActions.obtainAuthTokenSuccess, authActions.otpLoginSuccess, authActions.signupSuccess)),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const uj = selectUploadJob(state);
            return uj ? of(uploadModelsActions.rebindUploadJob(uj)) : EMPTY;
        }),
    );

const obtainAnonymousTokenEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(authActions.obtainAnonymousToken.match),
        withLatestFrom(state$),
        switchMap(([_, state]) => {
            const companyName = selectAppCompanyName(state);

            return from(AuthService.init(companyName).obtainAnonymousToken()).pipe(
                map(({ data }) => authActions.obtainAnonymousTokenSuccess(data)),
                catchError((error: AxiosError) => {
                    const errorCode = error.response?.status;

                    // non-existent company name
                    if (errorCode === 422) {
                        return concat([
                            authActions.obtainAnonymousTokenFailure(),
                            appActions.invalidateStore({
                                purge: InvalidateLevel.Full,
                                redirect: ROUTES.notFound,
                                redirectByRouter: true,
                            }),
                        ]);
                    }

                    return of(authActions.obtainAnonymousTokenFailure());
                }),
            );
        }),
    );

const sessionLoginEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(authActions.obtainSessionToken.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const companyName = selectAppCompanyName(state);

            return from(AuthService.init(companyName).obtainSessionToken()).pipe(
                switchMap(res => {
                    // backend redirect case
                    if (!res.request.responseURL.includes(res.config.url)) {
                        return of(authActions.obtainSessionTokenFailure());
                    }

                    return of(authActions.obtainSessionTokenSuccess(res.data));
                }),
                catchError(() => of(authActions.obtainSessionTokenFailure())),
            );
        }),
    );

const silentLoginEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(authActions.silentLogin.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const companyName = selectAppCompanyName(state);

            return from(AuthService.init(companyName).obtainAuthToken(action.payload)).pipe(
                switchMap(({ data }) =>
                    of(
                        appActions.invalidateStore({
                            purge: InvalidateLevel.Client,
                        }),
                        authActions.silentLoginSuccess(data),
                    ),
                ),
                tap(() => window.history.replaceState(null, '', window.location.pathname)),
                catchError(() => of(authActions.silentLoginFailure())),
            );
        }),
    );

const obtainAuthTokenEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(authActions.obtainAuthToken.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const companyName = selectAppCompanyName(state);
            const { form, onSuccess, onError } = action.payload;

            return from(AuthService.init(companyName).obtainAuthToken(form)).pipe(
                switchMap(({ data }) =>
                    of(authActions.obtainAuthTokenSuccess(data)).pipe(finalize(() => onSuccess && onSuccess(data))),
                ),
                catchError((error: AxiosError<SignInFormErrors>) => {
                    const errors = formatResponseErrors<SignInFormErrors>(
                        error?.response?.data || ({} as SignInFormErrors),
                    );

                    return of(authActions.obtainAuthTokenFailure(errors)).pipe(
                        finalize(() => onError && onError(errors)),
                    );
                }),
            );
        }),
    );

const obtainOtpEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(authActions.obtainOtp.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const companyName = selectAppCompanyName(state);
            const { form, onSuccess, onError } = action.payload;

            return from(AuthService.init(companyName).obtainOtp({ ...form, company_name: companyName })).pipe(
                switchMap(() => of(authActions.obtainOtpSuccess()).pipe(finalize(() => onSuccess && onSuccess()))),
                catchError((error: AxiosError) => {
                    const errors = formatResponseErrors(error?.response?.data || {});

                    return of(authActions.obtainOtpFailure(errors)).pipe(finalize(() => onError && onError(errors)));
                }),
            );
        }),
    );

const otpLoginEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(authActions.otpLogin.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const companyName = selectAppCompanyName(state);
            const { form, onSuccess, onError } = action.payload;

            return from(AuthService.init(companyName).obtainAuthToken(form)).pipe(
                switchMap(({ data }) =>
                    of(authActions.otpLoginSuccess(data)).pipe(finalize(() => onSuccess && onSuccess(data))),
                ),
                catchError((error: AxiosError) => {
                    const errors = formatResponseErrors(error?.response?.data || {});
                    return of(authActions.otpLoginFailure()).pipe(finalize(() => onError && onError(errors)));
                }),
            );
        }),
    );

const signupEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(authActions.signup.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const companyName = selectAppCompanyName(state);
            const { form, onSuccess, onError } = action.payload;

            return from(AuthService.init(companyName).signup(filterEmptyValues(form) as SignupForm)).pipe(
                switchMap(({ data }) =>
                    of(authActions.signupSuccess(data)).pipe(finalize(() => onSuccess && onSuccess(data))),
                ),
                catchError((error: AxiosError<SignupFormErrors>) => {
                    const errors = formatResponseErrors<SignupFormErrors>(
                        error?.response?.data || ({} as SignupFormErrors),
                    );

                    return of(authActions.signupFailure(errors)).pipe(finalize(() => onError && onError(errors)));
                }),
            );
        }),
    );

const sendPasswordRecoveryEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(authActions.sendPasswordRecovery.match),
        withLatestFrom(state$),
        switchMap(([{ payload }, state]) => {
            const companyName = selectAppCompanyName(state);
            return from(AuthService.init(companyName).sendPasswordRecovery(payload)).pipe(
                map(() => authActions.sendPasswordRecoverySuccess()),
                catchError((error: AxiosError) => {
                    return of(
                        authActions.sendPasswordRecoveryFailure(formatResponseErrors(error?.response?.data || {})),
                    );
                }),
            );
        }),
    );

const confirmPasswordRecoveryEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(authActions.confirmPasswordRecovery.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const { form, onSuccess, onError } = action.payload;
            return from(AuthService.init('').confirmPasswordRecovery(form)).pipe(
                switchMap(() =>
                    of(authActions.confirmPasswordRecoverySuccess()).pipe(finalize(() => onSuccess && onSuccess())),
                ),
                catchError((error: AxiosError<SignupFormErrors>) => {
                    const errors = formatResponseErrors(error?.response?.data || {});

                    return of(authActions.confirmPasswordRecoveryFailure(errors)).pipe(
                        finalize(() => onError && onError(errors)),
                    );
                }),
            );
        }),
    );

export const authEpics = combineEpics(
    initAppOnGettingTokenEpic,
    obtainAnonymousTokenEpic,
    rebindUploadJobEpic,
    obtainAuthTokenEpic,
    otpLoginEpic,
    obtainOtpEpic,
    signupEpic,
    sendPasswordRecoveryEpic,
    confirmPasswordRecoveryEpic,
    silentLoginEpic,
    sessionLoginEpic,
);
