import cn from 'classnames';
import React from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { SubmitButton } from '@components/submit-button';
import { PromocodeForm } from '@types';
import { TextField } from '@react-md/form';
import { selectAppSettings } from '@modules/app';
import {
    renderOrderDiscount,
    orderActions,
    selectOrderAppliedDiscount,
    selectOrderPromocodeError,
    selectWholeOrderTbd,
} from '@modules/order';
import { FeatureContent } from '@components/feature-content';
import { FormField, NonFieldErrors } from '@components/form-field';
import { SidebarSection } from '@components/sidebar-section';
import { getNodeIdComposer } from '@utils';
import { RootPrefix } from '../constants';

import styles from './order-promocode.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'promo_code');
const formId = getNodeIdComposer('order_promo_code_form');

interface OrderPromocodeFormProps {
    orderId: number;
}

export const OrderPromocodeForm = ({ orderId }: OrderPromocodeFormProps) => {
    const dispatch = useAppDispatch();
    const errorCode = useAppSelector(selectOrderPromocodeError);

    const form = useForm<PromocodeForm>({
        defaultValues: {
            code: '',
        },
    });

    const { control, handleSubmit } = form;

    const onSubmit: SubmitHandler<PromocodeForm> = ({ code }) => {
        dispatch(orderActions.applyPromocode({ orderId, data: { code: code.trim() } }));
    };

    const { t } = useTranslation();

    const error =
        errorCode === 422
            ? t('errors.haveBiggerDiscount', 'You already have a bigger discount applied')
            : errorCode === 404
            ? t('errors.promoCodeIsInvalid', 'Entered promo code is invalid')
            : undefined;

    return (
        <>
            <form
                id={formId()}
                onSubmit={handleSubmit(onSubmit)}
                className={cn('flex', 'justify-center', 'items-end', 'small-margin-top')}
            >
                <FormField
                    prefix={formId()}
                    name="code"
                    control={control}
                    component={TextField}
                    className={styles.code}
                    label={t('labels.promoCode', 'Promo code')}
                />
                <SubmitButton prefix={_id('apply')} isSending={false} theme="primary" themeType="outline">
                    <FeatureContent contentKey={_id('apply')} fallback={t('buttons.apply', 'Apply')} />
                </SubmitButton>
            </form>

            <NonFieldErrors className="small-margin-top" errors={error} />
        </>
    );
};

interface OrderPromocodeProps {
    orderId: number;
}

export const OrderPromocode = ({ orderId }: OrderPromocodeProps) => {
    const { company_has_promo_discounts } = useAppSelector(selectAppSettings);
    const discount = useAppSelector(selectOrderAppliedDiscount);
    const allTBDs = useAppSelector(selectWholeOrderTbd);

    const hasDiscount = !!discount;
    const showPromocodeForm = hasDiscount && discount?.isPromo ? false : company_has_promo_discounts;

    // check the existing discount or create a promocode
    if (!hasDiscount && !showPromocodeForm) {
        return null;
    }

    return (
        <SidebarSection span>
            {hasDiscount && allTBDs.length === 0 && renderOrderDiscount(discount)}
            {showPromocodeForm && <OrderPromocodeForm orderId={orderId} />}
        </SidebarSection>
    );
};
