import cn from 'classnames';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Divider } from '@react-md/divider';
import { withBaseErrorBoundary } from '@components/error-boundaries';
import { BackLink } from '@components/link';
import { Fallback } from '@components/fallback';
import { PreloaderBox } from '@components/preloader';
import { selectAppSettings } from '@modules/app';
import { orderPageActions, selectOrderPageData, selectIsOrderPageLoading } from '@modules/order-page';
import { useBuildLocation } from '@hooks';
import { composeFullAddress, composeNodeId, reverse } from '@utils';
import { DeliveryType } from '@types';
import { OrderPageHeader, OrderPageParts, OrderPageDelivery, OrderPageNotes, OrderPageSidebar } from './components';
import { RootPrefix } from './constants';
import { useGetResizeRef } from '@utils/parant-site-comunicator';

import styles from './order-page.module.scss';

const Page = () => {
    const { orderId } = useParams();

    const dispatch = useAppDispatch();
    const { company_address: companyAddress } = useAppSelector(selectAppSettings);
    const order = useAppSelector(selectOrderPageData);
    const isLoading = useAppSelector(selectIsOrderPageLoading);

    const containerRef = useGetResizeRef();

    const prevLocation = useBuildLocation({
        to: reverse('userOrders'),
    });

    useEffect(() => {
        dispatch(orderPageActions.load(orderId!));
    }, [orderId, dispatch]);

    if (!order) {
        return <Fallback />;
    }

    const {
        company_order_id: companyOrderId,
        date: createdAt,
        placed_at: placedAt,
        total_price: totalPrice,
        tracking_number: trackingNumber,
        status,
        status_display: statusDisplay,
        delivery_type_title: deliveryTypeTitle,
        delivery_type: deliveryType,
        shipping_address: shippingAddress,
        notes,
        attached_file: attachedFile,
    } = order;

    const orderDate = placedAt || createdAt;

    return (
        <div id={RootPrefix} className={styles.layout} ref={containerRef}>
            {/*todo use PageLayout*/}

            <div className="container">
                <BackLink id={composeNodeId(RootPrefix, 'back', 'link')} to={prevLocation.to} />
                <PreloaderBox show={isLoading} fade>
                    <div className={styles.grid}>
                        <div className={cn('rounded-box', styles.left)}>
                            <OrderPageHeader
                                id={companyOrderId}
                                orderDate={orderDate}
                                totalPrice={totalPrice}
                                trackingNumber={trackingNumber}
                                status={status}
                                statusDisplay={statusDisplay}
                            />
                            <OrderPageParts order={order} />
                            <Divider />
                            <OrderPageDelivery
                                deliveryTypeTitle={deliveryTypeTitle}
                                pickupAddress={
                                    deliveryType === DeliveryType.NotSpecifiedDelivery
                                        ? composeFullAddress(companyAddress)
                                        : ''
                                }
                                shippingAddress={shippingAddress ? composeFullAddress(shippingAddress) : ''}
                            />
                            {(notes || attachedFile) && (
                                <>
                                    <Divider />
                                    <OrderPageNotes notes={notes} file={attachedFile} />
                                </>
                            )}
                        </div>

                        <OrderPageSidebar order={order} />
                    </div>
                </PreloaderBox>
            </div>
        </div>
    );
};

export const UserOrderPage = withBaseErrorBoundary(Page);
