import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { FeatureContent } from '@components/feature-content';
import { shipmentId } from './helpers';
import { OrderShipmentRates } from './order-shipment-rates';

interface OrderFormShipmentProps {}

export const OrderFormShipment: React.FC<OrderFormShipmentProps> = () => {
    const { t } = useTranslation();
    return (
        <div className="margin-top">
            <Typography id={shipmentId('title')} type="headline-3" component="div" className="margin-bottom">
                <FeatureContent contentKey={shipmentId('title')} fallback={t('titles.shipment', 'Shipment')} />
            </Typography>

            <OrderShipmentRates />
        </div>
    );
};
