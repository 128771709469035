import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { AppEpic } from '@types';
import { OrderService } from '@services';
import { orderPageActions } from './slice';

const getOrderPageEpic: AppEpic = (action$, state$) => {
    return action$.pipe(
        filter(orderPageActions.load.match),
        switchMap(data => {
            return from(OrderService.init().getOrder(data.payload)).pipe(
                map(({ data }) => orderPageActions.loadSuccess(data)),
                catchError(error => of(orderPageActions.loadFailure(error))),
            );
        }),
    );
};

export const orderPageEpics = combineEpics(getOrderPageEpic);
