import cn from 'classnames';
import { useRef } from 'react';
import { Button, DialogContent, FixedDialog, TextIconSpacing, useToggle } from 'react-md';
import { CompanyUserAddress } from '@services/df/clients';
import { FeatureContent } from '@components/feature-content';
import { Icons } from '@components/icons';
import { deliveryId } from './helpers';

import styles from './order-recent-addresses-popover.module.scss';

interface OrderRecentAddressesPopoverProps {
    addressesList?: CompanyUserAddress[];
    buttonLabel: string;
    onAddressPick: (props: CompanyUserAddress) => void;
}

export function OrderRecentAddressesPopover({
    addressesList,
    buttonLabel,
    onAddressPick,
}: OrderRecentAddressesPopoverProps) {
    const hasAddresses = Boolean(addressesList && addressesList.length > 0);
    const [visible, show, hide] = useToggle(false);
    const fixedTo = useRef(null);

    return hasAddresses ? (
        <>
            <FeatureContent
                contentKey={deliveryId('use_recent_address')}
                fallback={buttonLabel}
                wrapper={props => (
                    <Button
                        ref={fixedTo}
                        theme="secondary"
                        className="rmd-button--flat"
                        onClick={() => show()}
                        {...props}
                    >
                        <TextIconSpacing icon={<Icons.Notepad />} forceIconWrap>
                            {props.children}
                        </TextIconSpacing>
                    </Button>
                )}
            />
            <FixedDialog
                id={deliveryId('recent_address_list')}
                className={styles['address-list-container']}
                aria-labelledby={deliveryId('recent_address_list')}
                fixedTo={fixedTo}
                visible={visible}
                onRequestClose={hide}
                options={{ anchor: { x: 'inner-left', y: 'below' } }}
            >
                <DialogContent className={styles['address-list']}>
                    {addressesList!.map(address => (
                        <div
                            key={address.id}
                            className={styles['address']}
                            onClick={() => {
                                onAddressPick(address);
                                hide();
                            }}
                        >
                            {address.full_address}
                        </div>
                    ))}
                </DialogContent>
            </FixedDialog>
        </>
    ) : null;
}
