import * as Yup from 'yup';
import { useMemo } from 'react';

export function useValidation<CreateValidation extends (...rest: any[]) => Schema, Schema = Yup.AnyObjectSchema>(
    createValidation: CreateValidation,
    ...deps: Parameters<CreateValidation>
) {
    return useMemo<Schema>(() => {
        return createValidation(...deps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createValidation, ...deps]);
}
