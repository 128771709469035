import cn from 'classnames';
import { RefObject, createContext, useContext, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useParams, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SidebarAskForHelp } from '@components/ask-questions';
import { withBaseErrorBoundary } from '@components/error-boundaries';
import { BackLink } from '@components/link';
import { Fallback } from '@components/fallback';
import { FeatureContent } from '@components/feature-content';
import { FeatureKeys as FF, FeatureOn, FeatureToggle } from '@components/feature-flags';
import { PreloaderBox } from '@components/preloader';
import { SidebarSection } from '@components/sidebar-section';
import { initOrderSubmitPage, selectWholeOrderTbd } from '@modules/order';
import { selectAppSettings } from '@modules/app';
import { selectParentModelsLoaded } from '@modules/models';
import { selectProductIsUpdating } from '@modules/product';
import { selectIqtModeOn, selectUser } from '@modules/user';
import {
    selectOrderData,
    selectIsOrderLoading,
    selectIsOrderUpdating,
    selectIsOrderSubmitting,
    selectOrderQuestionnaireSchema,
    selectIsQuestionnaireLoading,
} from '@modules/order';
import { useQuotationListeners } from '@modules/quotation/listeners';
import { OrderSubmitPdf } from './order-submit-pdf';
import { useSchemaObject } from '@hooks';
import { useGetOrderUrl } from '@pages/widget/order/hooks';
import { decimalToBoolean, composeNodeId } from '@utils';
import { OrderStartupCostBox } from './order-startup-cost';
import { OrderSidebar } from './order-sidebar';
import { OrderPriceCorrections } from './order-price-corrections';
import { OrderProducts } from './order-products';
import { OrderSubmit } from './order-submit';
import { OrderPromocode } from './order-promocode';
import { OrderRevealBlocks } from './order-reveal-blocks';
import { OrderForms, OrderFormProvider, OrderFormId } from './order-forms';
import { RootPrefix } from './constants';
import { useGetResizeRef } from '@utils/parant-site-comunicator';

import styles from './order-submit-page.module.scss';

const OrderSubmitContext = createContext<RefObject<HTMLTableElement> | null>(null);
export const useOrderSubmitContext = () => useContext(OrderSubmitContext);

const Page = () => {
    const dispatch = useAppDispatch();
    const { company: _company, orderId } = useParams();
    const company = _company!;
    const order = useAppSelector(selectOrderData);
    const allTBDs = useAppSelector(selectWholeOrderTbd);
    const user = useAppSelector(selectUser);
    const settings = useAppSelector(selectAppSettings);
    const iqtModeOn = useAppSelector(selectIqtModeOn);
    const isOrderLoading = useAppSelector(selectIsOrderLoading);
    const isOrderUpdating = useAppSelector(selectIsOrderUpdating);
    const isOrderSubmitting = useAppSelector(selectIsOrderSubmitting);
    const isProductUpdating = useAppSelector(selectProductIsUpdating);
    const isQuestionnaireLoading = useAppSelector(selectIsQuestionnaireLoading);
    const questionnaireSchema = useAppSelector(selectOrderQuestionnaireSchema);
    const modelsLoaded = useAppSelector(selectParentModelsLoaded);
    const [getOrderUrl] = useGetOrderUrl();
    const fieldGroups = useSchemaObject(questionnaireSchema);

    const tableRef = useRef<HTMLTableElement>(null);
    const containerRef = useGetResizeRef();

    useQuotationListeners();
    useEffect(() => {
        dispatch(
            initOrderSubmitPage({
                company,
                orderId,
            }),
        );
    }, [dispatch, orderId, company]);

    const { t } = useTranslation();

    if (!order || !user || !modelsLoaded || isQuestionnaireLoading) {
        return <Fallback />;
    }

    const orderUrl = getOrderUrl();

    if (order.product_count === 0) {
        return <Navigate to={orderUrl} replace />;
    }

    const availableOrderInitialStatuses = order.available_order_initial_statuses;

    const submitBtnDisabled = [
        isOrderSubmitting,
        decimalToBoolean(order.price_min_difference) && !order.use_additional_startup_cost && allTBDs.length === 0,
        // absentRequiredDrawings,
    ].some(Boolean);

    const backLinkId = composeNodeId(RootPrefix, 'back_to_cart', 'link');
    const lineItemsCount = order.products?.length || 0;

    return (
        <OrderSubmitContext.Provider value={tableRef}>
            <div id={RootPrefix} className={styles.layout} ref={containerRef}>
                <div className="container">
                    <BackLink
                        id={backLinkId}
                        label={
                            <FeatureContent contentKey={backLinkId} fallback={t('links.backToCart', 'Back to cart')} />
                        }
                        to={orderUrl}
                    />

                    <PreloaderBox show={isOrderSubmitting} fade>
                        <OrderFormProvider settings={settings} order={order} user={user} fieldGroups={fieldGroups}>
                            <div className={styles.grid}>
                                <div className={styles.left}>
                                    <OrderProducts show={!!order.products?.length} products={order.products} />

                                    {iqtModeOn && (
                                        <OrderRevealBlocks offset={lineItemsCount}>
                                            <OrderPriceCorrections currency={settings.currency} />
                                        </OrderRevealBlocks>
                                    )}

                                    <OrderForms
                                        offset={iqtModeOn ? lineItemsCount + 1 : lineItemsCount} // +1 for OrderPriceCorrections block
                                        dynamicFieldGroups={fieldGroups}
                                        settings={settings}
                                    />
                                </div>

                                <OrderSidebar
                                    order={order}
                                    loading={!order.id || isOrderLoading || isOrderUpdating || isProductUpdating}
                                >
                                    {allTBDs.length === 0 && <OrderStartupCostBox order={order} />}
                                    <OrderPromocode orderId={order.id!} />
                                    <SidebarSection span>
                                        <div className={cn('emulated-flex-gap', 'justify-center', styles.actions)}>
                                            {availableOrderInitialStatuses && (
                                                <OrderSubmit
                                                    disabled={submitBtnDisabled}
                                                    statuses={availableOrderInitialStatuses}
                                                />
                                            )}

                                            <OrderSubmitPdf pdfUrl={order.pdf_url} />
                                        </div>
                                    </SidebarSection>

                                    <FeatureToggle flagKey={FF.OrderPage.SidebarAskForHelpShown}>
                                        <FeatureOn>
                                            <SidebarAskForHelp rootPrefix={RootPrefix} email={settings.support_email} />
                                        </FeatureOn>
                                    </FeatureToggle>
                                </OrderSidebar>
                            </div>
                        </OrderFormProvider>
                    </PreloaderBox>
                </div>
            </div>
        </OrderSubmitContext.Provider>
    );
};

export const WidgetOrderSubmitPage = withBaseErrorBoundary(Page);
