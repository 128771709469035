import React from 'react';
import cn from 'classnames';

import styles from './viewer-inner-panel.module.scss';

export interface ViewerInnerPanelProps {
    className?: string;
}

export const ViewerInnerPanel: React.FC<ViewerInnerPanelProps> = ({ className, children }) => {
    return <div className={cn([styles.box, className])}>{children}</div>;
};
