import React, { ReactNode } from 'react';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@react-md/utils';
import { Button } from '@react-md/button';
import { Dialog, DialogHeader, DialogContent } from '@react-md/dialog';
import { Icons } from '@components/icons';
import { FeatureContent } from '@components/feature-content';
import { NonFieldErrors } from '@components/form-field';
import { SubmitButton } from '@components/submit-button';
import { getNodeIdComposer } from '@utils';
import { useStripeConfirmPayment } from './hooks';
import { BaseStripeElementsProps } from './types';

import { RootPrefix } from './constants';
import styles from './stripe-elements-popup.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'popup');

interface StripeElementsPopupProps extends BaseStripeElementsProps {
    title: ReactNode;
    subtitle?: ReactNode;
    isOpen: boolean;
    options?: StripePaymentElementOptions;
    close: ReturnType<typeof useToggle>[2];
}

export const StripeElementsPopup: React.FC<StripeElementsPopupProps> = ({
    title,
    subtitle,
    isOpen,
    close,
    options,
    onSuccess,
    onError,
}) => {
    const { t } = useTranslation();
    const { message, isLoading, isSending, handleSubmit } = useStripeConfirmPayment({ onSuccess, onError });

    return (
        <Dialog
            id={_id('box')}
            visible={isOpen}
            onRequestClose={close}
            aria-labelledby={_id('box')}
            className={styles.popup}
            exit={false}
        >
            <DialogHeader className={styles.header}>
                {title}
                {subtitle}

                <Button
                    buttonType="icon"
                    theme="clear"
                    themeType="outline"
                    className={styles.close}
                    onClick={close}
                    aria-label={t('buttons.close', 'Close')}
                >
                    <Icons.Cross className="rmd-typography--secondary" />
                </Button>
            </DialogHeader>

            <DialogContent className={isLoading ? 'rmd-typography--center' : undefined}>
                <form id={_id('form')} onSubmit={handleSubmit}>
                    <PaymentElement id={_id('payment_element')} options={options} />
                    <NonFieldErrors className="margin-top" errors={message} />
                    <FeatureContent
                        contentKey={_id('submit')}
                        fallback={t('buttons.pay', 'Pay')}
                        wrapper={props => (
                            <SubmitButton id={props.id!} isSending={isSending} className="margin-top">
                                {props.children}
                            </SubmitButton>
                        )}
                    />
                </form>
            </DialogContent>
        </Dialog>
    );
};
