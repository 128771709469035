import * as Sentry from '@sentry/react';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { PersistGate } from 'redux-persist/integration/react';
import { Navigate, Routes, Location, useLocation, Outlet, matchPath } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Header } from '@components/header';
import { Fallback } from '@components/fallback';
import { Fade, FADE_APPEAR_DURATION } from '@components/animations';
import { ParameterPopups } from '@components/parameter-popups';
import { DropzoneDocument } from '@components/dropzone';
import { FCC } from '@types';
import { usePasswordlessLogin, usePrevious } from '@hooks';
import { persistor } from '@app/store';
import { appActions, selectAppInitialSuccess, selectIsAppDeactivated, selectContentEditableMode } from '@modules/app';
import { selectClientId } from '@modules/client';
import { selectAuthorizedUserId } from '@modules/user';
import { useClientsMeRetrieveQuery, useClientsRetrieveQuery } from '@services/df/clients';
import { selectIsAuthenticated } from '@modules/auth';
import { reverse } from '@utils';
import { getInitAppOptions } from './helpers';
import { WidgetPanel } from '../widget-panel';
import { ROUTES } from '@constants/routes';

import styles from './page-container.module.scss';

const animationClasses = {
    appear: styles.appear,
    appearActive: styles.appearActive,
    enter: styles.enter,
    enterActive: styles.enterActive,
    exit: styles.exit,
    exitActive: styles.exitActive,
};

const timeout = { enter: 400, exit: 300 };

// https://github.com/remix-run/react-router/issues/8733
export const AnimatedRoutes: FCC<{ location: Location }> = ({ location, children }) => {
    const prevPathname = usePrevious(location.pathname);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [location.pathname]);

    return (
        // <TransitionGroup id="main" component="main">
        //     <CSSTransition
        //         key={location.key}
        //         enter={prevPathname !== location.pathname}
        //         timeout={timeout}
        //         classNames={animationClasses}
        //     >
        //         <div className={styles.inner}>
        //             <Routes location={location}>{children}</Routes>
        //         </div>
        //     </CSSTransition>
        // </TransitionGroup>
        <main id="main">
            <div className={styles.inner}>
                <Routes>{children}</Routes>
            </div>
        </main>
    );
};

export const PageContainer: FCC = ({ children }) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const query = usePasswordlessLogin();
    const isDeactivatedPage = Boolean(matchPath(ROUTES.appDeactivated, location.pathname));

    const appInitialSuccess = useAppSelector(selectAppInitialSuccess);
    const userId = useAppSelector(selectAuthorizedUserId);
    const clientId = useAppSelector(selectClientId);
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const isContentEditableMode = useAppSelector(selectContentEditableMode);
    const isAppDeactivated = useAppSelector(selectIsAppDeactivated);

    useEffect(() => {
        if (appInitialSuccess) return;

        dispatch(appActions.initApp(getInitAppOptions({ query })));
    }, [dispatch, appInitialSuccess, location.pathname, query]);

    useClientsMeRetrieveQuery(undefined, { skip: !isAuthenticated || !appInitialSuccess });
    useClientsRetrieveQuery({ id: clientId! }, { skip: !clientId });

    useEffect(() => {
        if (userId) {
            Sentry.setUser({ id: userId, language: i18next.language });
        } else {
            Sentry.setUser(null);
        }
    }, [userId]);

    if (!appInitialSuccess) return <Fallback />;
    if (isAppDeactivated && !isDeactivatedPage) return <Navigate to={reverse('appDeactivated')} replace />;

    return (
        <>
            <Fade in mountOnEnter unmountOnExit appear duration={FADE_APPEAR_DURATION} id="page">
                {isContentEditableMode && <WidgetPanel />}
                <Header />

                <AnimatedRoutes location={location}>{children}</AnimatedRoutes>
            </Fade>

            <ParameterPopups />
            <DropzoneDocument />
        </>
    );
};

export const CompanyPage: FCC = ({ children }) => {
    return (
        <PersistGate loading={<Fallback />} persistor={persistor}>
            <PageContainer>{children}</PageContainer>
        </PersistGate>
    );
};
