import cn from 'classnames';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import React, {
    ChangeEvent,
    FocusEvent,
    KeyboardEvent,
    KeyboardEventHandler,
    ReactElement,
    WheelEvent,
    forwardRef,
    useCallback,
    useMemo,
    useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useCallbackRef } from '@chakra-ui/react-use-callback-ref';
import { useCounter, UseCounterProps } from '@chakra-ui/counter';
import { Button } from '@react-md/button';
import { useIsomorphicLayoutEffect } from '@react-md/utils';
import { TextField, TextFieldProps } from '@react-md/form';
import { useEnsuredRef } from '@react-md/utils';
import { Icons } from '@components/icons';
import { composeNodeId } from '@utils';

import styles from './count-input.module.scss';

type InputSelection = { start: number | null; end: number | null };

const FLOATING_POINT_REGEX = /^[Ee0-9+\-.]$/;

/**
 * Determine if a character is a DOM floating point character
 * @see https://www.w3.org/TR/2012/WD-html-markup-20120329/datatypes.html#common.data.float
 */
function isFloatingPointNumericCharacter(character: string) {
    return FLOATING_POINT_REGEX.test(character);
}

function isValidNumericKeyboardEvent(event: KeyboardEvent, isValid: (key: string) => boolean) {
    if (event.key == null) return true;
    const isModifierKey = event.ctrlKey || event.altKey || event.metaKey;
    const isSingleCharacterKey = event.key.length === 1;
    if (!isSingleCharacterKey || isModifierKey) return true;
    return isValid(event.key);
}

const getStepFactor = <Event extends KeyboardEvent | WheelEvent>(event: Event) => {
    let ratio = 1;
    if (event.metaKey || event.ctrlKey) {
        ratio = 0.1;
    }
    if (event.shiftKey) {
        ratio = 10;
    }
    return ratio;
};

export interface CountInputProps<
    Cleanable extends boolean = false,
    Value = Cleanable extends true ? number | null : number,
> extends Omit<UseCounterProps, 'value' | 'onChange'>,
        Omit<TextFieldProps, 'defaultValue' | 'value' | 'max' | 'min' | 'step' | 'onChange'> {
    /**
     * Input node id
     */
    id: string;
    value?: string | number | null;
    /**
     * onChange handler
     */
    onChange: (value: Value, valueAsString: string, element?: HTMLInputElement | null) => void;
    /**
     * Allow clean input to empty string
     */
    cleanable?: Cleanable; //

    /**
     * This is used to format the value so that screen readers
     * can speak out a more human-friendly value.
     *
     * It is used to set the `aria-valuetext` property of the input
     */
    getAriaValueText?: (value: string | number) => string;
    /**
     * Whether the pressed key should be allowed in the input.
     * The default behavior is to allow DOM floating point characters defined by /^[Ee0-9+\-.]$/
     */
    isValidCharacter?: (value: string) => boolean;
    /**
     * If using a custom display format, this converts the custom format to a format `parseFloat` understands.
     */
    parse?: (value: string) => string;
    /**
     * If using a custom display format, this converts the default format to the custom format.
     */
    format?: (value: string | number) => string | number;

    // increment, decrement buttons
    controls?: boolean;
    controlsClassName?: string;

    // side effects
    selectOnFocus?: boolean;
}

function CountInputInner<Cleanable extends boolean = false>(
    {
        id,
        value,
        defaultValue,
        min = Number.MIN_SAFE_INTEGER,
        max = Number.MAX_SAFE_INTEGER,
        step: stepProp = 1,
        precision,
        onChange,
        keepWithinRange = true,
        className,
        controlsClassName,
        disabled,
        readOnly,
        selectOnFocus = true,
        controls = true,
        cleanable,
        // cleanable = false,
        getAriaValueText: getAriaValueTextProp,
        isValidCharacter: isValidCharacterProp,
        format: formatValue,
        parse: parseValue,
        onBlur: _onBlur,
        onFocus: _onFocus,
        onKeyDown: _onKeyDown,
        ...rest
    }: CountInputProps<Cleanable>,
    forwardedRef: React.ForwardedRef<HTMLInputElement>,
) {
    const isValidCharacter = useCallbackRef(isValidCharacterProp ?? isFloatingPointNumericCharacter);
    const getAriaValueText = useCallbackRef(getAriaValueTextProp);

    /**
     * Leverage the `useCounter` hook since it provides
     * the functionality to `increment`, `decrement` and `update`
     * counter values
     */
    const counter = useCounter({
        value: isUndefined(value) ? undefined : isNull(value) ? '' : value,
        defaultValue,
        min,
        max,
        step: stepProp,
        precision,
        keepWithinRange,
        onChange: (valueAsString: string, _valueAsNumber: number) => {
            // if (cleanable) {
            //     onChange(null, ref.current);
            // }
            const valueAsNumber = cleanable ? (Number.isNaN(_valueAsNumber) ? null : _valueAsNumber) : _valueAsNumber;
            // @ts-ignore
            onChange(valueAsNumber, valueAsString, ref.current);
        },
    });

    const { update: updateFn, increment: incrementFn, decrement: decrementFn } = counter;

    const isInteger = precision === 0;
    const isIncrementDisabled = counter.valueAsNumber >= max;
    const isDecrementDisabled = counter.valueAsNumber <= min;
    const sanitize = useCallback(
        (value: string) => value.split('').filter(isValidCharacter).join('') || (cleanable ? '' : min),
        [isValidCharacter, min, cleanable],
    );
    const parse = useCallback(
        (value: string) => (parseValue?.(value) ?? isInteger ? parseInt(value).toString() : value),
        [parseValue, isInteger],
    );
    const format = useCallback((value: string | number) => (formatValue?.(value) ?? value).toString(), [formatValue]);
    const formattedValue = format(counter.value);

    /**
     * Sync state with uncontrolled form libraries like `react-hook-form`.
     */
    // useIsomorphicLayoutEffect(() => {
    //     if (!ref.current) return;
    //     const notInSync = ref.current.value !== counter.value;
    //     if (notInSync) {
    //         const parsedInput = parse(ref.current.value);
    //         counter.setValue(sanitize(parsedInput));
    //     }
    // }, [parse, sanitize]);

    const isInteractive = !(readOnly || disabled);

    const increment = useCallback(
        (step = stepProp) => {
            if (isInteractive) {
                incrementFn(step);
            }
        },
        [incrementFn, isInteractive, stepProp],
    );

    const decrement = useCallback(
        (step = stepProp) => {
            if (isInteractive) {
                decrementFn(step);
            }
        },
        [decrementFn, isInteractive, stepProp],
    );

    /**
     * Function that clamps the input's value
     */
    const validateAndClamp = useCallback(() => {
        let next = counter.value as string | number;
        if (counter.value === '') {
            return;
        }

        const valueStartsWithE = /^[eE]/.test(counter.value.toString());

        if (valueStartsWithE) {
            counter.setValue('');
        } else {
            if (counter.valueAsNumber < min) {
                next = min;
            }
            if (counter.valueAsNumber > max) {
                next = max;
            }

            counter.cast(next);
        }
    }, [counter, max, min]);

    const inputSelectionRef = useRef<InputSelection | null>(null);

    /**
     * The `handleChange` handler filters out any character typed
     * that isn't floating point compatible.
     */
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const evt = event.nativeEvent as InputEvent;
        if (evt.isComposing) return;
        const parsedInput = parse(event.currentTarget.value);
        inputSelectionRef.current = {
            start: event.currentTarget.selectionStart,
            end: event.currentTarget.selectionEnd,
        };

        const value = sanitize(parsedInput);

        if (!isInteger || value === '') {
            updateFn(value);
            return;
        }

        const valueStartsWithE = /^[eE]/.test(value.toString());

        if (valueStartsWithE) {
            updateFn('');
        } else {
            let next = value;
            if (value < min) {
                next = min;
            }
            if (value > max) {
                next = max;
            }

            counter.cast(next);
        }
    };

    const onBlur = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            _onBlur?.(event);
            !isInteger && validateAndClamp();
        },
        [_onBlur, isInteger, validateAndClamp],
    );

    const onFocus = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            _onFocus?.(event);

            if (selectOnFocus) {
                event.target.select();
            } else {
                // restore selection if custom format string replacement moved it to the end
                if (!inputSelectionRef.current) return;
                event.target.selectionStart = inputSelectionRef.current.start ?? event.currentTarget.value?.length;
                event.currentTarget.selectionEnd = inputSelectionRef.current.end ?? event.currentTarget.selectionStart;
            }
        },
        [_onFocus, selectOnFocus],
    );

    const onKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            _onKeyDown?.(event);

            if (event.nativeEvent.isComposing) return;

            if (!isValidNumericKeyboardEvent(event, isValidCharacter)) {
                event.preventDefault();
            }

            /**
             * Keyboard Accessibility
             *
             * We want to increase or decrease the input's value
             * based on if the user the arrow keys.
             *
             * @see https://www.w3.org/TR/wai-aria-practices-1.1/#keyboard-interaction-17
             */
            const stepFactor = getStepFactor(event) * stepProp;

            const eventKey = event.key;

            const keyMap: Record<string, KeyboardEventHandler> = {
                ArrowUp: () => increment(stepFactor),
                ArrowDown: () => decrement(stepFactor),
                Home: () => updateFn(min),
                End: () => updateFn(max),
            };

            const action = keyMap[eventKey];

            if (action) {
                event.preventDefault();
                action(event);
            }
        },
        [_onKeyDown, isValidCharacter, stepProp, increment, decrement, updateFn, min, max],
    );

    const spinUp = useCallback(
        (event: any) => {
            event.preventDefault();
            increment();
        },
        [increment],
    );

    const spinDown = useCallback(
        (event: any) => {
            event.preventDefault();
            decrement();
        },
        [decrement],
    );

    /**
     * If user would like to use a human-readable representation
     * of the value, rather than the value itself they can pass `getAriaValueText`
     *
     * @see https://www.w3.org/TR/wai-aria-practices-1.1/#wai-aria-roles-states-and-properties-18
     * @see https://www.w3.org/TR/wai-aria-1.1/#aria-valuetext
     */
    const ariaValueText = useMemo(() => {
        const text = getAriaValueText?.(counter.value);
        if (text !== null) return text;

        const defaultText = counter.value.toString();
        // empty string is an invalid ARIA attribute value
        return !defaultText ? undefined : defaultText;
    }, [counter.value, getAriaValueText]);

    const [ref, refHandler] = useEnsuredRef<HTMLInputElement>(forwardedRef);

    const { t } = useTranslation();

    return (
        <TextField
            {...rest}
            ref={refHandler}
            id={id}
            className={cn('rmd-text-field-container--autosize', styles.box, className, { [styles.controls]: controls })}
            containerProps={{
                // @ts-ignore
                'data-replicated-value': formattedValue,
            }}
            isLeftAddon={false}
            leftChildren={
                controls && (
                    <Button
                        id={composeNodeId(id, 'decrement')}
                        buttonType="icon"
                        theme="clear"
                        themeType="outline"
                        className={cn(styles.button, styles.decrement, controlsClassName)}
                        aria-label={t('buttons.decrement', 'Decrement')}
                        onClick={spinDown}
                        disabled={disabled || (keepWithinRange && isDecrementDisabled)}
                        disableRipple
                    >
                        <Icons.Minus />
                    </Button>
                )
            }
            isRightAddon={false}
            rightChildren={
                controls && (
                    <Button
                        id={composeNodeId(id, 'increment')}
                        buttonType="icon"
                        theme="clear"
                        themeType="outline"
                        className={cn(styles.button, styles.increment, controlsClassName)}
                        aria-label={t('buttons.increment', 'Increment')}
                        onClick={spinUp}
                        disabled={disabled || (keepWithinRange && isIncrementDisabled)}
                        disableRipple
                    >
                        <Icons.Plus />
                    </Button>
                )
            }
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            value={formattedValue}
            disabled={disabled}
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={Number.isNaN(counter.valueAsNumber) ? undefined : counter.valueAsNumber}
            aria-valuetext={ariaValueText}
            autoComplete="off"
            autoCorrect="off"
        />
    );
}

export const CountInput = forwardRef<HTMLInputElement, CountInputProps>(CountInputInner) as <
    Cleanable extends boolean = false,
>(
    props: CountInputProps<Cleanable> & { ref?: React.ForwardedRef<HTMLInputElement> },
) => ReactElement;
