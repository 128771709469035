import react, { useEffect } from 'react';
import type { ListboxOption } from '@react-md/form';
import { Select } from '@react-md/form';
import { ColorFieldProps } from '../color-field';
import useSelect from './useSelect';
import { getNodeIdComposer } from '@utils';
import { cutSelectListIfTooLong, cutSelectListIfTooLongOnKeydown } from '@utils/widget/cut-select-list-if-too-long';

import styles from './color-select.module.scss';

type OptionsType = Array<Pick<ColorFieldProps, 'id' | 'value' | 'label' | 'checked' | 'color'>>;

// todo pass full id as prop (related #cart_page-specification_field-color_select-listbox)
export default function ColorSelect(props: {
    id: string;
    name: string;
    onChange: (value: string) => void;
    options: OptionsType;
}): JSX.Element {
    const id = getNodeIdComposer(props.id, 'color_select');

    const [value, handleChange] = useSelect(props.options[0].value as string);

    useEffect(() => {
        const checkedByUser = props.options.find(option => option.checked === true);
        if (checkedByUser) {
            handleChange(checkedByUser.value as string);
        }
    }, [props.options, handleChange]);

    const selectOptions: ListboxOption[] = props.options.map(({ id, value, label, color }) => ({
        id,
        label,
        value,
        leftAddon: <div className={styles.indicator} style={{ backgroundColor: color }} />,
        leftAddonType: 'avatar',
        children: <span>{label}</span>,
        // rightAddon: <div>Right icon</div>,
    }));

    return (
        <>
            <Select
                id={id()}
                portal={false}
                className={styles.select}
                // label="Select a color"
                placeholder="Select a color"
                options={selectOptions}
                value={value}
                onChange={value => {
                    handleChange(value);
                    props.onChange(value);
                }}
                disableLeftAddon={false}
                displayLabelClassName={styles.label}
                onClick={cutSelectListIfTooLong}
                onKeyDown={event => cutSelectListIfTooLongOnKeydown(event.key)}
            />
        </>
    );
}
