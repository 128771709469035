import cn from 'classnames';
import React, { useEffect } from 'react';
import { useAppSelector } from '@app/hooks';
import { Typography } from '@react-md/typography';
import { Icons } from '@components/icons';
import { Alert } from '@components/alert';
import { FeatureContentCompound } from '@components/feature-content';
import { selectHasCurrentProduct } from '@modules/quotation';
import { selectHasFoundProduct } from '@modules/product';
import { useBrowserStorage, useDebounce } from '@hooks';
import { BrowserStorageKeys } from '@constants';
import { calculationSectionId } from '../helpers';

import styles from './calculation-hints.module.scss';

export interface CalculationHintProps {
    isConfirmDisabled: boolean;
    isSameConfigAlreadyInCart: boolean;
    isSameQuantityInCart: boolean;
}

export const CalculationHints: React.FC<CalculationHintProps> = ({
    isConfirmDisabled,
    isSameConfigAlreadyInCart,
    isSameQuantityInCart,
}) => {
    const hasCurrentProduct = useAppSelector(selectHasCurrentProduct);
    const hasFoundProduct = useAppSelector(selectHasFoundProduct);
    const wasDuplicateClicked = !hasCurrentProduct && hasFoundProduct;

    const [tipOfUpdatingProductWasClosed, setTipOfUpdatingProductWasClosed] = useBrowserStorage(sessionStorage, {
        key: BrowserStorageKeys.TipOfUpdatingProductWasClosed,
        value: false,
    });

    // to avoid UI jerks when changing this value
    const isTipOfUpdatingProductVisible = useDebounce(
        [
            hasCurrentProduct,
            !isConfirmDisabled,
            !isSameConfigAlreadyInCart,
            !isSameQuantityInCart,
            !tipOfUpdatingProductWasClosed,
        ].every(Boolean),
    );

    // todo we treat the event as an effect, it is an antipattern
    //  https://react.dev/learn/synchronizing-with-effects#what-are-effects-and-how-are-they-different-from-events
    //  https://react.dev/learn/you-might-not-need-an-effect#sharing-logic-between-event-handlers
    //  https://react.dev/learn/separating-events-from-effects
    //  we can refactor this after changes to the quotation reducer (introduce actions like duplicate etc...)
    useEffect(() => {
        // duplicate happens
        if (!tipOfUpdatingProductWasClosed && wasDuplicateClicked) {
            setTipOfUpdatingProductWasClosed(true);
        }
    }, [tipOfUpdatingProductWasClosed, setTipOfUpdatingProductWasClosed, wasDuplicateClicked]);

    if (!isTipOfUpdatingProductVisible) return null;

    return (
        <div className={cn('margin-top', styles.box)}>
            <Alert
                show={isTipOfUpdatingProductVisible}
                variant="outlined"
                type="warning"
                dismissible
                className={styles.alert}
                closeButtonId={calculationSectionId('close_hints')}
                onClose={() => {
                    setTipOfUpdatingProductWasClosed(true);
                }}
            >
                <Typography type="subtitle-2" className={styles.text} component={'div'}>
                    <FeatureContentCompound
                        contentKey={calculationSectionId('change_specs_hint')}
                        i18nKey="messages.cartItemUpdateInfo"
                        defaults={
                            'By clicking "Confirm" you will change the specs for this model. ' +
                            'If you need the same model but with different specs, ' +
                            'please use "duplicate" <icon /> in the model list on the left.'
                        }
                        components={{
                            icon: <Icons.Copy />,
                        }}
                    />
                </Typography>
            </Alert>
        </div>
    );
};
