import * as Three from 'three';
import { ModelViewerVariables } from '../constants';

export class Axes extends Three.AxesHelper {
    constructor(...params: ConstructorParameters<typeof Three.AxesHelper>) {
        super(...params);

        this.material.depthTest = false;
        this.renderOrder = 3;

        this.setColors(
            ModelViewerVariables.Axes.xAxisColor,
            ModelViewerVariables.Axes.yAxisColor,
            ModelViewerVariables.Axes.zAxisColor,
        );
    }
}
