import cn from 'classnames';
import React, { useEffect } from 'react';
import { useAppSelector } from '@app/hooks';
import { selectUploadedModelFilesIds, selectUploadedModelRejectedFiles } from '@modules/upload-models';
import { selectTransformedModelsList } from '@modules/models';
import { useModelsStaggerDuration } from '@pages/widget/order/hooks';
import { FCC } from '@types';
import { Fade, FADE_STAGGER } from '@components/animations';
import { UploadedModels } from './components/uploaded-models';
import { UploaderErrorBoundary } from './components/uploader-error-boundary';

import styles from './uploader.module.scss';

export interface Props {
    appear: boolean;
    updateColumn?: () => void;
}

export const UploaderBox: FCC<Props> = ({ appear, updateColumn, children }) => {
    const rejectedFiles = useAppSelector(selectUploadedModelRejectedFiles);
    const filesIds = useAppSelector(selectUploadedModelFilesIds);
    const transformedModels = useAppSelector(selectTransformedModelsList);

    const modelsStaggerDuration = useModelsStaggerDuration();

    const progressList = [filesIds.length, rejectedFiles.length, transformedModels.length];

    // update column on items change
    useEffect(() => {
        updateColumn && updateColumn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, progressList);

    return (
        <Fade in appear={appear} className={cn(styles.box)} delay={modelsStaggerDuration + FADE_STAGGER}>
            <UploaderErrorBoundary>
                <div
                    className={cn([
                        styles.inner,
                        {
                            [styles.progress]: progressList.some(Boolean),
                        },
                    ])}
                >
                    <UploadedModels
                        filesIds={filesIds}
                        rejectedFiles={rejectedFiles}
                        transformedModels={transformedModels}
                    />
                    {children}
                </div>
            </UploaderErrorBoundary>
        </Fade>
    );
};
