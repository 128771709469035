import React from 'react';
import { useTranslation } from 'react-i18next';
import { PricingList, PricingRecord } from '@components/pricing-list';
import type { Order } from '@types';
import { toPercent } from '@utils';

interface Props {
    order: Order;
}

export const OrderAmountDue = ({ order }: Props) => {
    const { t } = useTranslation();

    return (
        <PricingList divider>
            {order.is_down_payment_shown && (
                <PricingRecord
                    label={t('labels.downPayment', 'Down payment')}
                    value={order.down_payment_amount}
                    percent={toPercent(order.down_payment_rate).value}
                />
            )}
            {order.is_remaining_amount_shown && (
                <PricingRecord label={t('labels.remainingAmount', 'Amount due')} value={order.remaining_amount} />
            )}
        </PricingList>
    );
};
