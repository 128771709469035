import type { ReactElement } from 'react';
import type { ButtonThemeProps } from '@react-md/button';
import { buttonThemeClassNames } from '@react-md/button';
import type { LinkProps } from '@react-md/link';
import { Link } from '@react-md/link';

export function LinkStyledButton({
    className,
    theme,
    themeType,
    buttonType,
    disabled,
    children,
    ...props
}: ButtonThemeProps & LinkProps): ReactElement {
    return (
        <Link
            {...props}
            className={buttonThemeClassNames({
                disabled,
                theme,
                themeType,
                buttonType,
                className,
            })}
        >
            {children}
        </Link>
    );
}

LinkStyledButton.defaultProps = {
    theme: 'primary',
    themeType: 'contained',
    buttonType: 'text',
};
