import qs from 'qs';
import * as Sentry from '@sentry/react';
import { generatePath } from 'react-router';
import { Route, Routes, createBrowserRouter } from 'react-router-dom';
import { QueryParamProvider, EncodedQuery } from 'use-query-params';
// import { QueryParamProvider, EncodedQuery, transformSearchStringJsonSafe } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { IqtRoute, IqtClientInspector } from '@components/iqt';
import { CompanyPage } from '@components/page-container';
import {
    AppDeactivatedPage,
    IqtClientPage,
    PaymentInvoicePage,
    WidgetUploadPage,
    WidgetOrderPage,
    WidgetOrderSubmitPage,
    WidgetPollSuccessPage,
    UserModelsPage,
    UserOrderPage,
    UserOrdersPage,
    ChangePasswordPage,
    SignInPage,
    SignupPage,
    OtpPage,
    CustomizeDxfViewerPage,
    CustomizeModelViewerPage,
    CustomizeIconsPage,
    ForbiddenPage,
    NotFoundPage,
    ViewerPage,
    ModelViewerPage,
    TtiStartPage,
} from '@pages';
import { ROUTES, IQT_MODE_ROUTES, SHORT_IQT_MODE_ROUTES } from '@constants';
import { AuthRoute, GuestRoute, PrivateRoute } from './route-handlers';

const queryParamOptions = {
    // enableBatching: true,
    // updateType: 'replaceIn' as const,
    searchStringToObject: (searchString: string) =>
        qs.parse(searchString, {
            ignoreQueryPrefix: true,
        }) as EncodedQuery,
    objectToSearchString: (encodedParams: EncodedQuery) => qs.stringify(encodedParams),
    // objectToSearchString: (encodedParams: EncodedQuery) => transformSearchStringJsonSafe(qs.stringify(encodedParams)),
};

const Root = () => {
    return (
        <QueryParamProvider adapter={ReactRouter6Adapter} options={queryParamOptions}>
            <Routes>
                <Route path={ROUTES.forbidden} element={<ForbiddenPage />} />
                <Route path={ROUTES.notFound} element={<NotFoundPage />} />
                <Route path={ROUTES.customizeDxfViewer} element={<CustomizeDxfViewerPage />} />
                <Route path={ROUTES.customizeModelViewer} element={<CustomizeModelViewerPage />} />
                <Route path={ROUTES.customizeIcons} element={<CustomizeIconsPage />} />
                <Route path={ROUTES.viewer} element={<ViewerPage />} />
                <Route path={ROUTES.modelViewer} element={<ModelViewerPage />} />
                <Route path={ROUTES.ttiStartPage} element={<TtiStartPage />} />

                {/* this stupid routes structure for crossfade animation https://stackoverflow.com/a/74352029 */}
                <Route
                    path="*"
                    element={
                        <CompanyPage>
                            <Route path={ROUTES.appDeactivated} element={<AppDeactivatedPage />} />
                            <Route path={ROUTES.invoice} element={<PaymentInvoicePage />} />

                            {/* end user mode */}
                            <Route path={ROUTES.widgetUpload} element={<WidgetUploadPage />} />
                            <Route path={ROUTES.widgetPollSuccess} element={<WidgetPollSuccessPage />} />
                            <Route
                                element={
                                    <AuthRoute
                                        customAuthChecker={({ permissions }) => permissions.isOrderPageAllowed}
                                    />
                                }
                            >
                                <Route path={ROUTES.widgetOrder} element={<WidgetOrderPage />} />
                            </Route>
                            <Route element={<AuthRoute />}>
                                <Route path={ROUTES.widgetOrderSubmit} element={<WidgetOrderSubmitPage />} />
                                <Route path={ROUTES.userModels} element={<UserModelsPage />} />
                                <Route path={ROUTES.userOrders} element={<UserOrdersPage />} />
                                <Route path={ROUTES.userOrder} element={<UserOrderPage />} />
                                {/*<Route path={ROUTES.userSettings} element={<UserSettingsPage />} />*/}
                            </Route>

                            {/* iqt mode */}
                            <Route
                                element={
                                    <AuthRoute>
                                        <IqtRoute />
                                    </AuthRoute>
                                }
                            >
                                <Route path={IQT_MODE_ROUTES.widgetClient} element={<IqtClientPage />} />
                                <Route
                                    path={IQT_MODE_ROUTES.widgetOrder}
                                    element={
                                        <IqtClientInspector
                                            redirectIfAbsentClient={params => {
                                                // case - app was opened from back office (repeat IQT order)
                                                // waiting for the order to load, we can get the clientId from the order entity
                                                // so we don't redirect to the client page
                                                return !params.orderId; // redirect if order is not present in params
                                            }}
                                        >
                                            <WidgetOrderPage />
                                        </IqtClientInspector>
                                    }
                                />
                                <Route element={<IqtClientInspector />}>
                                    <Route
                                        path={IQT_MODE_ROUTES.widgetOrderSubmit}
                                        element={<WidgetOrderSubmitPage />}
                                    />
                                    <Route path={IQT_MODE_ROUTES.widgetUpload} element={<WidgetUploadPage />} />

                                    <Route path={IQT_MODE_ROUTES.userModels} element={<UserModelsPage />} />
                                    <Route path={IQT_MODE_ROUTES.userOrders} element={<UserOrdersPage />} />
                                    <Route path={IQT_MODE_ROUTES.userOrder} element={<UserOrderPage />} />
                                </Route>
                            </Route>

                            {/* short iqt mode */}
                            <Route element={<PrivateRoute isAllowed={({ permissions }) => permissions.iqtAllowed} />}>
                                <Route path={SHORT_IQT_MODE_ROUTES.widgetOrder} element={<WidgetOrderPage />} />
                                <Route path={SHORT_IQT_MODE_ROUTES.userModels} element={<UserModelsPage />} />
                            </Route>

                            {/* anonymous only */}
                            <Route element={<GuestRoute />}>
                                <Route path={ROUTES.signIn} element={<SignInPage />} />
                                <Route path={ROUTES.signUp} element={<SignupPage />} />
                                <Route path={ROUTES.obtainOtp} element={<OtpPage />} />
                                <Route path={ROUTES.recoveryPassword} element={<ChangePasswordPage />} />
                            </Route>

                            {/* errors with header */}
                            <Route path={ROUTES.forbiddenInCompany} element={<ForbiddenPage />} />
                            <Route path="*" element={<NotFoundPage />} />
                        </CompanyPage>
                    }
                />
            </Routes>
        </QueryParamProvider>
    );
};

// const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
// export const router = sentryCreateBrowserRouter([{ path: '*', Component: Root }]);
export const router = createBrowserRouter([{ path: '*', Component: Root }]);

// export function http403(company?: string) {
//     if (company) {
//         router.navigate(generatePath(ROUTES.forbiddenInCompany, { company }));
//     } else {
//         router.navigate(ROUTES.forbidden);
//     }
// }

export function http404(company?: string) {
    if (company) {
        router.navigate(generatePath(ROUTES.notFoundInCompany, { company }));
    } else {
        router.navigate(ROUTES.notFound);
    }
}
