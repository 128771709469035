import { dfApi as api } from '@services/df/baseApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        priceDfmFeaturesCreate: build.query<PriceDfmFeaturesCreateApiResponse, PriceDfmFeaturesCreateApiArg>({
            query: queryArg => ({
                url: `/v2/price/dfm_features/`,
                method: 'POST',
                body: queryArg.dfmFeaturesPriceRequestRequest,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PriceDfmFeaturesCreateApiResponse = /** status 200  */ DfmFeaturesPriceResponse;
export type PriceDfmFeaturesCreateApiArg = {
    dfmFeaturesPriceRequestRequest: DfmFeaturesPriceRequestRequest;
};
export type DfmFeatureResponse = {
    type: string;
    properties: {
        [key: string]: any;
    };
    suitable_dfm_features_ids: string[];
    errors: string[];
};
export type DfmFeaturesPriceResponse = {
    model_id: number;
    model_dfm_features: DfmFeatureResponse[];
};
export type DfmFeaturesPriceRequestRequest = {
    model_id: number;
    material_id: number;
    dfm_features?: {
        [key: string]: any;
    }[];
};
export const { usePriceDfmFeaturesCreateQuery } = injectedRtkApi;
