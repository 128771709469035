import { from, of, map } from 'rxjs';
import { catchError, filter, ignoreElements, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { AppEpic, AppMode, InvalidateStoreConfig, InvalidateLevel } from '@types';
import { AppService, AuthService } from '@services';
import { AppSession, isValidUrl, matchesAnyRoutes, reverse } from '@utils';
import { router } from '@components/routes';
import { persistor } from '@app/store';
import { purgeStorageAssociatedWithMode } from '@app/persist';
import { authActions, selectIsAuthenticated, selectAuthToken } from '../auth';
import { selectIqtModeOn } from '../user';
import { getAppSettingsObservable$ } from './helpers';
import { appActions } from './slice';
import { selectAppCompanyName } from './selectors';

const invalidateStoreEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(appActions.invalidateStore.match),
        withLatestFrom(state$),
        tap(([action, state]) => {
            const iqtModeOn = selectIqtModeOn(state);
            const { redirect, redirectByRouter, purge } = action.payload;

            const fullClean = purge === InvalidateLevel.Full;

            function goNext(redirect: InvalidateStoreConfig['redirect']) {
                if (!redirect) return;

                let url;

                if (matchesAnyRoutes(redirect) || isValidUrl(redirect)) {
                    url = redirect;
                } else {
                    url = reverse('widgetUpload');
                }

                if (redirectByRouter) {
                    router.navigate(redirect, { replace: true });
                } else {
                    window.location.href = url;
                }
            }

            if (fullClean) {
                // first clear the opposite mode and then the current one
                purgeStorageAssociatedWithMode(iqtModeOn ? AppMode.EndUser : AppMode.Iqt).then(() => {
                    persistor.purge().then(() => {
                        goNext(redirect);
                    });
                });
            } else {
                goNext(redirect);
            }
        }),
        ignoreElements(),
    );

const initAppEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(appActions.initApp.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const { silentLogin } = action.payload;

            if (silentLogin) {
                return of(authActions.silentLogin(silentLogin));
            }

            const companyName = selectAppCompanyName(state);
            const authService = AuthService.init(companyName);
            const isAuthenticated = selectIsAuthenticated(state);
            const token = selectAuthToken(state);

            if (AppSession.shortIqtModeOn && !isAuthenticated) {
                return of(authActions.obtainSessionToken());
            }

            if (token) {
                authService.setHeaders(token);

                return getAppSettingsObservable$(state$);
            } else {
                return of(authActions.obtainAnonymousToken());
            }
        }),
    );

const updateAppSettingsEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(appActions.updateAppSettings.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            return from(AppService.init().updateSettings(action.payload)).pipe(
                map(({ data }) => appActions.updateAppSettingsSuccess(data)),
            );
        }),
    );

export const appEpics = combineEpics(initAppEpic, invalidateStoreEpic, updateAppSettingsEpic);
