import * as Three from 'three';
import type { Euler } from 'three';
import { AxisNames } from '@constants';
import { DisposableObject, Rotation } from './types';

export function objectCleanup(main: DisposableObject) {
    main.traverse((object: DisposableObject) => {
        object.geometry?.dispose();

        if (Array.isArray(object.material)) {
            object.material.forEach(material => {
                material.dispose();
            });
        } else {
            object.material?.dispose();
        }

        object.dispose && object.dispose();
    });
}

export const getRotationSteps = (euler: Euler = new Three.Euler()): Rotation[] => {
    return AxisNames.map(axis => ({ axis, angle: Math.round(Three.MathUtils.radToDeg(euler[axis])) }));
};
