import { combineEpics } from 'redux-observable';

import { appEpics } from '@modules/app/epics';
import { askForHelpEpics } from '@modules/ask-for-help/epics';
import { authEpics } from '@modules/auth/epics';
import { deliveryRatesEpics } from '@modules/delivery-rates/epics';
import { invoiceEpics } from '@modules/invoice/epics';
import { modelsEpics } from '@modules/models/epics';
import { modelsPageEpics } from '@modules/models-page/epics';
import { ordersEpics } from '@modules/orders/epics';
import { postProductionPricingEpics } from '@modules/post-production/epics';
import { priceCorrectionsEpics } from '@modules/price-corrections/epics';
import { technologiesEpics } from '@modules/technologies/epics';
import { popupsEpics } from '@modules/ui/epics';
import { uploadModelsEpics } from '@modules/upload-models/epics';
import { orderEpics } from '@modules/order/epics';
import { orderPageEpics } from '@modules/order-page/epics';
import { pricingEpics } from '@modules/pricing/epics';
import { productEpics } from '@modules/product/epics';
import { wallThicknessEpics } from '@modules/wall-thickness/epics';

export const rootEpic = combineEpics(
    pricingEpics,
    productEpics,
    postProductionPricingEpics,
    popupsEpics,
    modelsEpics,
    modelsPageEpics,
    uploadModelsEpics,
    askForHelpEpics,
    orderEpics,
    deliveryRatesEpics,
    ordersEpics,
    orderPageEpics,
    invoiceEpics,
    technologiesEpics,
    authEpics,
    appEpics,
    wallThicknessEpics,
    priceCorrectionsEpics,
);
