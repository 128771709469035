import React from 'react';
import ReactPaginate from 'react-paginate';
import { BasePaginationProps } from './types';

import styles from './pagination.module.scss';

export const BasePagination: React.FC<BasePaginationProps> = ({
    page,
    pageCount,
    onPageChange,
    pageRangeDisplayed = 4,
    marginPagesDisplayed = 1,
    disableInitialCallback = true,
}) => {
    return (
        <ReactPaginate
            forcePage={page - 1}
            pageCount={pageCount}
            onPageChange={onPageChange}
            disableInitialCallback={disableInitialCallback}
            pageRangeDisplayed={pageRangeDisplayed}
            marginPagesDisplayed={marginPagesDisplayed}
            containerClassName={styles.box}
            pageClassName={styles.button}
            breakClassName={styles.break}
            activeClassName={styles.active}
            pageLinkClassName={styles.button_link}
            breakLinkClassName={styles.break_link}
            activeLinkClassName={styles.active_link}
            previousLabel={<></>}
            nextLabel={<></>}
        />
    );
};
