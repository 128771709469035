import React from 'react';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { UseFormTrigger } from 'react-hook-form';
import { Button } from '@react-md/button';
import { Typography } from '@react-md/typography';
import { FeatureContent } from '@components/feature-content';
import { SignInForm } from '@types';
import { authActions, selectIsPasswordRecoverySuccess, selectIsPasswordRecoverySending } from '@modules/auth';
import { signInId } from '../helpers';

import styles from './reset-password.module.scss';

interface Props {
    email: string;
    trigger: UseFormTrigger<SignInForm>;
}

export const ResetPasswordButton: React.FC<Props> = ({ email, trigger }) => {
    const dispatch = useAppDispatch();
    const isPasswordRecoverySending = useAppSelector(selectIsPasswordRecoverySending);
    const isPasswordRecoverySuccess = useAppSelector(selectIsPasswordRecoverySuccess);
    const { pathname: redirect_path } = useLocation();

    const handleResetPassword = async (): Promise<void> => {
        const passedValidation = await trigger('email', { shouldFocus: true });
        if (passedValidation) {
            dispatch(authActions.sendPasswordRecovery({ email, redirect_path }));
        }
    };

    const { t } = useTranslation();

    return (
        <div className={styles.box}>
            {!isPasswordRecoverySending && isPasswordRecoverySuccess ? (
                <Typography
                    type="subtitle-2"
                    weight="semi-bold"
                    component={'span'}
                    className={cn('rmd-typography--theme-success', 'input-addition-font-size')}
                >
                    {t('labels.resetPasswordSent', 'Email sent')}
                </Typography>
            ) : (
                <FeatureContent
                    contentKey={signInId('reset_password')}
                    fallback={t('labels.resetPassword', 'Reset')}
                    wrapper={props => (
                        <Button
                            theme="primary"
                            disableRipple
                            disabled={isPasswordRecoverySending}
                            className={cn(
                                'fade-in',
                                'rmd-button--flat',
                                'rmd-typography--semi-bold',
                                'input-addition-font-size',
                                styles.link,
                            )}
                            onClick={handleResetPassword}
                            {...props}
                        />
                    )}
                />
            )}
        </div>
    );
};
