import * as Three from 'three';
import { useEffect, useRef } from 'react';
import { ModelViewer, ModelViewerObjects } from '@utils';
import ViewerModelUrl from '@assets/models/3-axis-machine-2_repaired.stl';
import { initGui } from './gui';

export const CustomizeModelViewerPage = () => {
    let visualizer: HTMLDivElement;
    const modelViewer = useRef<ModelViewer>();

    useEffect(() => {
        const viewer = new ModelViewer(visualizer, {
            // darkModeOn: true,
            debug: true,
            objects: {
                // [ModelViewerObjects.ModelEdges]: {
                //     draw: false,
                // },
                [ModelViewerObjects.PrinterBoundingBox]: {
                    size: new Three.Vector3().random().multiplyScalar(1000),
                    // size: '500-300-100',
                },
            },
        });

        const { promise } = viewer.loadModel(process.env.REACT_APP_VIEWER_PAGE_MODEL_SRC ?? ViewerModelUrl);

        promise.then(() => {
            initGui(viewer);
        });

        modelViewer.current = viewer;

        return () => {
            modelViewer.current?.clear();
            modelViewer.current = undefined;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            // className={styles.inner}
            style={{ position: 'fixed', top: 0, left: 0, height: '100vh', width: '100vw' }}
            ref={ref => {
                visualizer = ref as HTMLDivElement;
            }}
        />
    );
};
