import { NumberParam, withDefault } from 'use-query-params';

export const PAGINATION_CONFIG = {
    perPageLimits: [10, 25, 50, 100],
    pageSize: 10,
    outer: 1,
    inner: 2,
} as const;

export const CurrentPageParam = withDefault(NumberParam, 1);
export const PerPageLimitParam = withDefault(NumberParam, PAGINATION_CONFIG.pageSize);

export enum PaginationQueryParams {
    PageNumber = 'page',
    Limit = 'limit',
}
