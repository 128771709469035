import cn from 'classnames';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { ObjectModel, Technology } from '@types';
import { postProductionPricingActions } from '@modules/post-production';
import { selectOrderLoaded } from '@modules/order';
import {
    selectWidgetModelId,
    selectWidgetMaterialId,
    selectWidgetMaterial,
    selectWidgetPriceConfig,
    selectWidgetCount,
} from '@modules/quotation';
import { useCalculatePrice, useFindProduct } from '@pages/widget/order/hooks';

import styles from './calculation-section.module.scss';

interface Props {
    model?: ObjectModel;
    technology?: Technology;
}

export const CalculationSection: React.FC<Props> = ({ model, technology, children }) => {
    const dispatch = useAppDispatch();

    const orderExists = useAppSelector(selectOrderLoaded);
    const parentModelId = useAppSelector(selectWidgetModelId);
    const materialId = useAppSelector(selectWidgetMaterialId);
    const hasPostProduction = useAppSelector(state => Boolean(selectWidgetMaterial(state)?.post_production.length));
    const config = useAppSelector(selectWidgetPriceConfig);
    const count = useAppSelector(selectWidgetCount);

    const calculate = useCalculatePrice();
    useEffect(() => {
        calculate(count, model, technology?.id, materialId, config);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, parentModelId, materialId]);

    const findProduct = useFindProduct();
    useEffect(() => {
        if (orderExists) {
            findProduct(model?.id, materialId, config);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderExists, parentModelId, materialId, config]);

    useEffect(() => {
        if (materialId && parentModelId && hasPostProduction) {
            dispatch(
                postProductionPricingActions.load({
                    model_id: parentModelId,
                    material_id: materialId,
                    ...(config?.thickness && { thickness_id: config.thickness }),
                }),
            );
        }
    }, [dispatch, hasPostProduction, materialId, parentModelId, config?.thickness]);

    return (
        <div className={cn('rounded-box', 'overflow-clip', 'flex', 'flex-col', 'justify-between', styles.box)}>
            {children}
        </div>
    );
};
