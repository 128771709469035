import cn from 'classnames';
import React, { useCallback } from 'react';
import { useAppDispatch } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@react-md/utils';
import { Button } from '@react-md/button';
import { Divider } from '@react-md/divider';
import { Radio, useChoice } from '@react-md/form';
import { Typography } from '@react-md/typography';
import { Icons } from '@components/icons';
import { Label } from '@components/label';
import { FeatureContent, ContentKeys as FC } from '@components/feature-content';
import { ObjectModel } from '@types';
import { useModelUnitsLabels } from '@hooks';
import { transformModelInit } from '@modules/models';
import { modelDetailsId as _id } from './helpers';
import { DetailsBox } from './details-box';

import styles from './model-scale.module.scss';

interface Props {
    model: ObjectModel;
    close: ReturnType<typeof useToggle>[2];
}

function setInitScaleUnits<U extends ObjectModel['units']>(current: U, units: U[]) {
    return units.filter(unit => unit !== current)[0];
}

export const ModelScaleBox: React.FC<Props> = ({ model, close }) => {
    const dispatch = useAppDispatch();
    const unitsLabels = useModelUnitsLabels();

    // @ts-ignore
    const [selectedUnits, handleSelectedUnits] = useChoice<ObjectModel['units']>(() =>
        setInitScaleUnits(model.units, Object.keys(unitsLabels) as Array<ObjectModel['units']>),
    );

    const handleConfirmClick = useCallback(() => {
        if (model.units === selectedUnits) {
            close();
        } else {
            dispatch(
                transformModelInit({
                    fromModelId: model.parent_model || model.id,
                    type: 'scale',
                    units: selectedUnits,
                }),
            );
        }
    }, [dispatch, model, close, selectedUnits]);

    const { t } = useTranslation();

    return (
        <DetailsBox className={styles.box}>
            <div className={cn('margin-top', 'base-paddings', 'no-top')}>
                <Typography type="body-2" component={'div'}>
                    {t('messages.currentUnits', '{{label}} are the current units of measurement of the model.', {
                        label: unitsLabels[model.units].label,
                    })}
                </Typography>
                <Typography type="body-2" component={'div'} className="margin-bottom">
                    {t(
                        'messages.changeUnits',
                        'By using this feature, you will change the physical size of the model and thus the cost of the part.',
                    )}
                </Typography>
                <Typography type="headline-5">{t('labels.changeTo', 'Change to')}</Typography>

                <div className={styles.unitsBox}>
                    <Label className={styles.unitsLabel}>
                        {t('labels.units', 'Units')}
                        {': '}
                    </Label>
                    <div className={styles.units}>
                        {Object.entries(unitsLabels)
                            .filter(([key, _]) => key !== model.units)
                            .map(([key, value]) => (
                                <Radio
                                    id={_id('units', key)}
                                    key={key}
                                    name="units"
                                    onChange={handleSelectedUnits}
                                    value={key}
                                    checked={selectedUnits === key}
                                    label={value.label}
                                    icon={<Icons.Circle width={6} height={6} />}
                                    className={styles.unit}
                                    labelClassName={styles.unitName}
                                    toggleClassName="rmd-toggle--radio"
                                    disableIconOverlay
                                    disableProgrammaticRipple
                                    disableRipple
                                />
                            ))}
                    </div>
                </div>
            </div>

            <Divider style={{ marginBottom: '16px' }} />

            <div className={cn('base-paddings', 'no-top', styles.actions)}>
                <FeatureContent
                    contentKey={_id('confirm_scale')}
                    fallback={t('buttons.confirm', 'Confirm')}
                    wrapper={props => (
                        <Button theme="primary" themeType="outline" onClick={handleConfirmClick} {...props} />
                    )}
                />
                <FeatureContent
                    contentKey={_id('close_scale_box')}
                    fallback={t('buttons.cancel', 'Cancel')}
                    wrapper={props => (
                        <Button
                            theme="clear"
                            className={cn('rmd-button--flat', 'rmd-button--underlined', 'small-margin-left')}
                            onClick={close}
                            disableRipple
                            {...props}
                        />
                    )}
                />
            </div>
        </DetailsBox>
    );
};
