import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { UnstyledButton } from '@react-md/button';
import { Fade } from '@components/animations';
import { Preloader } from '@components/preloader';
import { AlertIcon } from '@components/alert';
import { DeleteIconButton, DuplicateIconButton } from '@components/common-buttons';
import { ModelRow, ModelLabel, ModelThumb } from '@modules/models';
import { productActions } from '@modules/product';
import { selectCurrentProduct, selectWidgetVisibleModelsIds, quotationActions } from '@modules/quotation';
import { Product } from '@types';
import { useWidgetModelHasAlerts } from '@pages/widget/order/hooks';
import { lineItemId } from '../helpers';

import styles from './line-item.module.scss';

export interface LineItemProductProps extends React.HTMLAttributes<HTMLLIElement> {
    in?: boolean;
    delay: number;
    product: Product;
}

export const LineItemProduct: React.FC<LineItemProductProps> = ({ product, children, ...rest }) => {
    const dispatch = useAppDispatch();

    const modelId = product.parent_model_id;
    const isActive = useAppSelector(state => product.id === selectCurrentProduct(state)?.id);
    const isModelVisible = useAppSelector(state => selectWidgetVisibleModelsIds(state).includes(modelId));

    const duplicateModel = isModelVisible
        ? undefined
        : () => {
              dispatch(quotationActions.showModel(modelId));
          };

    const deleteProduct = useCallback(product => dispatch(productActions.remove(product)), [dispatch]);
    const [isRemoving, setIsRemoving] = useState(false);
    // const { warning, error } = useWidgetModelHasAlerts(modelId);

    return (
        <Fade
            as="li"
            // mountOnEnter
            unmountOnExit
            className={cn(styles.row, styles.product, {
                [styles.active]: isActive,
            })}
            {...rest}
        >
            <UnstyledButton
                className={styles.btn}
                disabled={isActive}
                onClick={() => dispatch(quotationActions.setProduct(product))}
            />
            <ModelRow className={styles.grid}>
                <ModelThumb size={'small'} thumb={product.model_thumbnail}>
                    {/*{(warning || error) && (*/}
                    {/*    <div className={styles.warnings}>*/}
                    {/*        {error && <AlertIcon type={'error'} className={styles.error} width={16} />}*/}
                    {/*        {warning && <AlertIcon type={'warning'} className={styles.warning} width={16} />}*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </ModelThumb>

                <ModelLabel label={product.model_title} />

                <div className={styles.actions}>
                    {duplicateModel && (
                        <DuplicateIconButton
                            id={lineItemId(`duplicate_model_${product.id}`)}
                            className={styles.action}
                            onClick={e => {
                                e.stopPropagation();
                                duplicateModel();
                            }}
                        />
                    )}

                    {isRemoving ? (
                        <Preloader size={20} stroke={2} className={cn('rmd-typography--hint', styles.delete)} />
                    ) : (
                        <DeleteIconButton
                            id={lineItemId('delete_product', product.id)}
                            className={cn(styles.action, styles.delete)}
                            onClick={e => {
                                e.stopPropagation();
                                setIsRemoving(true);
                                deleteProduct(product);
                            }}
                        />
                    )}
                </div>
            </ModelRow>

            {children}
        </Fade>
    );
};
