import { NavigateFunction } from 'react-router';
import {
    AppThunk,
    OrderForm,
    UpdateOrderRatePayload,
    DeliveryType,
    ShippingTypes,
    InitialOrderDeliveryTypes,
} from '@types';
import { OrderService } from '@services/order.service';
import { createAppAsyncThunk } from '@app/helpers';
import { createModelsPolling, selectSelectedModels } from '../models';
import { uploadModelsActions } from '../upload-models';
import { technologiesActions, selectTechnologies } from '../technologies';
import { selectShippingRates } from '../delivery-rates';
import { orderActions } from './slice';
import { selectOrderData } from './selectors';
import { buildOrderUrl } from '@utils/routes';

interface UpdateShippingRates {
    rateId?: string;
}

export const updateShippingRates =
    ({ rateId }: UpdateShippingRates): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const order = selectOrderData(state);

        if (!order) return;

        const shippingRates = selectShippingRates(state);
        const rate = shippingRates.find(({ id }) => id.toString() === rateId);

        if (rate) {
            const data = {
                rate_id: rate.id,
                delivery_type: rate.custom ? DeliveryType.FlatFee : DeliveryType.EasyPost,
            };

            dispatch(
                orderActions.update({
                    orderId: order.id,
                    data,
                }),
            );
        }
    };

interface UpdateDeliveryType {
    shippingTypeIndex?: ShippingTypes;
}

export const updateDeliveryType =
    ({ shippingTypeIndex }: UpdateDeliveryType): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const order = selectOrderData(state);
        const { shippingRates } = state.deliveryRates;

        if (!order) return;

        if (typeof shippingTypeIndex !== 'undefined') {
            const data: UpdateOrderRatePayload = {
                delivery_type:
                    InitialOrderDeliveryTypes[shippingTypeIndex.toString() as keyof typeof InitialOrderDeliveryTypes],
            };

            // if rateId is not specified, request will reset rates, sending 'null'
            if (shippingTypeIndex === -1) {
                data.rate_id = 'null';
            }

            // This is for not send delivery request if shipment rates is already gotten
            // In this case we send only shipping type, not delivery type
            if (shippingTypeIndex !== 1 || shippingRates.length === 0) {
                dispatch(
                    orderActions.update({
                        orderId: order.id,
                        data,
                    }),
                );
            }
        }
    };

export const saveOrderForm =
    (form: OrderForm): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const order = selectOrderData(state);

        // case for appActions.invalidateStore, e.g. 'Get a new quote' button
        if (!order) {
            return;
        }

        dispatch(orderActions.saveForm(form));
    };

type InitOrderPagePayload = { company: string; orderId?: string };

export const initOrderPage =
    (payload: InitOrderPagePayload): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const technologies = selectTechnologies(state);

        dispatch(uploadModelsActions.initUploading([]));
        dispatch(orderActions.defineOrder({ ...payload, setupUrl: 'widgetOrder' }));

        if (!technologies.length) {
            dispatch(technologiesActions.load()); // todo move to comp
        }
    };

export const initOrderSubmitPage =
    (payload: InitOrderPagePayload): AppThunk =>
    (dispatch, getState) => {
        dispatch(orderActions.loadQuestionnaire()); // todo move to comp
        dispatch(orderActions.defineOrder({ ...payload, setupUrl: 'widgetOrderSubmit' }));
    };
