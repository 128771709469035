import cn from 'classnames';
import React, { ReactNode, useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@react-md/utils';
import { Button } from '@react-md/button';
import { Select } from '@react-md/form';
import { Typography } from '@react-md/typography';
import { TextIconSpacing } from '@react-md/icon';
import { Icons } from '@components/icons';
import { FeatureKeys as FF, useFeatureFlag } from '@components/feature-flags';
import { FeatureContent } from '@components/feature-content';
import { Label } from '@components/label';
import { SidebarSectionHead, SidebarSection } from '@components/sidebar-section';
import { selectCurrency } from '@modules/app';
import { pricingActions, selectBulkPrices } from '@modules/pricing';
import { selectWidgetMaterial } from '@modules/quotation';
import { getNodeIdComposer } from '@utils';
import { useWidgetPriceConfig } from '@pages/widget/order/hooks';
import { CalculationBulkPricesTable } from './calculation-bulk-prices-table';
import { getBulkPriceCellValue } from './helpers';
import { priceConfigId } from '../helpers';
import { RootPrefix } from '../constants';
import { cutSelectListIfTooLong, cutSelectListIfTooLongOnKeydown } from '@utils/widget/cut-select-list-if-too-long';

import styles from './calculation-bulk-prices.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'bulk_prices');

export interface CalculationBulkPricesProps {
    counter: ReactNode;
    count: number;
    setWidgetCount: (count: number) => void;
    disabled: boolean;
}

export const CalculationBulkPrices: React.FC<CalculationBulkPricesProps> = ({
    counter,
    count,
    setWidgetCount,
    disabled,
}) => {
    const dispatch = useAppDispatch();
    const currency = useAppSelector(selectCurrency);
    const material = useAppSelector(selectWidgetMaterial);
    const bulkPrices = useAppSelector(selectBulkPrices);
    const bulkPricesExpandedByDefault = useFeatureFlag(FF.CartPage.BulkPriceExpand);
    const { widgetPriceConfig: config, setWidgetPriceConfig } = useWidgetPriceConfig();

    const priorities = material?.lead_time;
    const priorityOptions = priorities?.map(item => {
        const bulkPrice = bulkPrices?.find(price => price.priority_id === item.uuid);
        const priceItem = bulkPrice?.priority_prices.find(item => item.quantity === count);

        return {
            label: item.title,
            value: item.uuid,
            rightAddon: priceItem ? (
                <Typography type="subtitle-2" component={'span'}>
                    {getBulkPriceCellValue(priceItem, currency)}
                </Typography>
            ) : null,
        };
    });
    const priorityDisabled = priorityOptions && priorityOptions.length < 2;

    const currentPriorityId = config?.lead_time;
    const currentPriority = priorities?.find(i => i.uuid === currentPriorityId);
    const handlePriorityChange = useCallback(
        value =>
            setWidgetPriceConfig(
                {
                    ...config,
                    lead_time: value,
                },
                {
                    requestPrice: true,
                },
            ),
        [config, setWidgetPriceConfig],
    );

    const bulkPricesDisabled = disabled || !currentPriorityId;

    const [isOpen, open, close, toggle] = useToggle(bulkPricesExpandedByDefault);

    useEffect(() => {
        // fixed sticky positions, for cases where column height is greater than window height
        if (isOpen) {
            window.scrollTo(window.scrollX, window.scrollY - 1);
        }
    }, [dispatch, isOpen]);

    useEffect(() => {
        // set tbd when counter is changed manually
        // for each quantity
        if (!bulkPrices) return;

        const currentPriority = config?.lead_time;

        const currentBulkPrice = bulkPrices.find(price => {
            return price.priority_id === currentPriority;
        });

        const priority_prices = currentBulkPrice?.priority_prices;

        if (!priority_prices) return;

        const priceItem = priority_prices.find(item => item.quantity === count);

        if (!priceItem) return;

        const { tbd_reason, tbd_reason_message } = priceItem;
        dispatch(pricingActions.setBulkPriceCurrentTbd({ tbd_reason, tbd_reason_message }));
    }, [dispatch, bulkPrices, count, config?.lead_time]);

    const { t } = useTranslation();

    const isShown = isOpen && !!bulkPrices && !bulkPricesDisabled;

    return (
        <>
            <SidebarSectionHead className="margin-bottom">
                <div className={cn('emulated-flex-gap', 'justify-between', 'items-center', styles.actions)}>
                    <Typography id={_id('title')} type="headline-5" className={styles.action}>
                        <FeatureContent
                            contentKey={_id('title')}
                            fallback={t('subtitles.quantityPriority', 'Quantity & Priority')}
                        />
                    </Typography>

                    <FeatureContent
                        contentKey={isShown ? _id('shown') : _id('hidden')}
                        fallback={
                            isShown
                                ? t('buttons.hideBulkPrice', 'Hide bulk prices')
                                : t('buttons.showBulkPrice', 'Show bulk prices')
                        }
                        wrapper={props => (
                            <Button
                                theme="primary"
                                disableRipple
                                disabled={disabled}
                                className={cn('fade-in', 'rmd-button--flat', styles.action, {
                                    [styles.open]: isShown,
                                })}
                                onClick={toggle}
                                {...props}
                            >
                                <TextIconSpacing icon={<Icons.Arrow />} forceIconWrap>
                                    {props.children}
                                </TextIconSpacing>
                            </Button>
                        )}
                    />
                </div>
            </SidebarSectionHead>

            {isShown && (
                <SidebarSection>
                    <CalculationBulkPricesTable
                        currency={currency}
                        priorities={priorities}
                        count={count}
                        setWidgetCount={setWidgetCount}
                        config={config}
                        setWidgetPriceConfig={setWidgetPriceConfig}
                        prices={bulkPrices}
                    />

                    <Typography id={_id('hint')} type="body-1" component={'div'}>
                        <FeatureContent
                            contentKey={_id('hint')}
                            fallback={t('messages.bulkPricesHint', 'Adjust quantity and select priority')}
                        />
                    </Typography>
                </SidebarSection>
            )}

            {/*<SidebarSection span>*/}
            <SidebarSection>
                <div className={cn('emulated-flex-gap')}>
                    <div className={cn(styles.cell, styles.quantityCell)}>{counter}</div>

                    {priorityOptions && currentPriorityId && (
                        <div className={cn(styles.cell, styles.priorityCell)}>
                            <Label id={priceConfigId('lead_time', 'label')}>
                                <FeatureContent
                                    contentKey={priceConfigId('lead_time', 'label')}
                                    fallback={t('labels.leadTime', 'Priority')}
                                />
                            </Label>

                            <Select
                                id={priceConfigId('lead_time', 'field')}
                                className={styles.priority}
                                options={priorityOptions}
                                onChange={handlePriorityChange}
                                value={currentPriorityId}
                                rightChildren={priorityDisabled ? null : undefined} // undefined means displaying default value
                                disabled={priorityDisabled}
                                onClick={cutSelectListIfTooLong}
                                onKeyDown={event => cutSelectListIfTooLongOnKeydown(event.key)}
                            />

                            {currentPriority?.note_for_user && (
                                <Typography
                                    type="subtitle-2"
                                    weight="semi-bold"
                                    className={styles.note}
                                    component={'div'}
                                >
                                    {currentPriority.note_for_user}
                                </Typography>
                            )}
                        </div>
                    )}
                </div>
            </SidebarSection>
        </>
    );
};
