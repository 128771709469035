import { useEffect, useState } from 'react';
import { isValidUrl } from '@utils';
import { useCancellablePromise } from './use-cancellable-promise';
import { usePrevious } from './use-previous';

interface Result {
    status: string;
    path: string;
}

const loadImage = (path: string) =>
    new Promise<Result>(resolve => {
        const img = new Image();
        img.onload = () => resolve({ path, status: 'ok' });
        img.onerror = () => resolve({ path, status: 'error' });
        img.src = path;
    });

type Status = 'pending' | 'loaded' | 'failed';

export function useLoadImage(src?: string) {
    const { cancellablePromise } = useCancellablePromise<Result>();
    const [status, setStatus] = useState<Status>('pending');
    const isLoaded = status === 'loaded';
    const isFailed = status === 'failed';

    // we have to cache the origin of src because the backend
    // sends us the signed fields in params (from s3) every time we fetch an entity
    const imgOrigin = src && isValidUrl(src) && new URL(src).origin;
    const prevImgOrigin = usePrevious(imgOrigin);

    useEffect(() => {
        if (!src || !imgOrigin || (imgOrigin === prevImgOrigin && isLoaded)) return;

        setStatus('pending');
        cancellablePromise(loadImage(src)).then(({ status }) =>
            status === 'ok' ? setStatus('loaded') : setStatus('failed'),
        );
    }, [cancellablePromise, src, imgOrigin, prevImgOrigin, isLoaded]);

    return [isLoaded, isFailed];
}
