import cn from 'classnames';
import React, { MouseEventHandler, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@react-md/button';
import { Chip } from '@react-md/chip';
import { Link } from '@react-md/link';
import { useToggle } from '@react-md/utils';
import { TextIconSpacing } from '@react-md/icon';
import { Icons } from '@components/icons';
import { usePrevious } from '@hooks';
import { FileObject, FileLinkObject, FilesListExpandingProps } from './types';

import styles from './file-field.module.scss';

function isFileLink(obj: FileObject | FileLinkObject): obj is FileLinkObject {
    return (obj as FileLinkObject).url !== undefined;
}

type FilesListProps<ObjectType> = FilesListExpandingProps & {
    fileObjects: Array<ObjectType>;
    handleRemove?: (index: number) => MouseEventHandler;
    previewData?: boolean;
    className?: string;
    fileClassName?: string;
};

export const FilesList = <ObjectType extends FileObject | FileLinkObject>({
    className,
    fileClassName,
    fileObjects,
    handleRemove,
    previewData,
    expandToggleId,
    expandedByDefault = true,
    expandingEnabled = true,
    expandingThreshold = 2,
    children,
}: React.PropsWithChildren<FilesListProps<ObjectType>>) => {
    const count = fileObjects.length;
    const [isOpen, open, close, toggle] = useToggle(expandedByDefault);
    const prevCount = usePrevious(count);

    const { t } = useTranslation();

    useEffect(() => {
        if (count > prevCount) {
            open();
        }
    }, [count, open, prevCount]);

    if (!count) return null;

    const visibleFilesObjects = isOpen || !expandingEnabled ? fileObjects : fileObjects.slice(0, expandingThreshold);

    return (
        <div className={cn(styles.files, className)}>
            {visibleFilesObjects.map((fileObject, i) => {
                if (isFileLink(fileObject)) {
                    return (
                        <Chip
                            key={fileObject.url}
                            className={cn(styles.file, fileClassName)}
                            contentClassName="rmd-typography--semi-bold"
                            title={fileObject.name}
                            noninteractable
                        >
                            <Link href={fileObject.url} target="_blank" className={styles.link} />
                            {fileObject.name}
                        </Chip>
                    );
                }

                return (
                    <Chip
                        key={fileObject.uuid}
                        rightIcon={<Icons.Cross />}
                        className={cn(styles.file, fileClassName)}
                        contentClassName="rmd-typography--semi-bold"
                        title={fileObject.file.name}
                        onClick={handleRemove && handleRemove(i)}
                    >
                        {fileObject.file.name}
                    </Chip>
                );
            })}

            {expandingEnabled && count > expandingThreshold && (
                <Button
                    id={expandToggleId}
                    theme="primary"
                    disableRipple
                    className={cn('rmd-button--flat', styles.btn, {
                        [styles.open]: isOpen,
                    })}
                    onClick={toggle}
                >
                    <TextIconSpacing icon={<Icons.Chevron />} forceIconWrap iconAfter={!isOpen}>
                        {isOpen
                            ? t('buttons.hideFiles', 'Hide files')
                            : t('buttons.showAllFiles', 'Show all {{count}} files', { count })}
                    </TextIconSpacing>
                </Button>
            )}
        </div>
    );
};
