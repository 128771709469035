import { AppThunk, TransformModelPayload } from '@types';
import { composeTransformedModelPollingId, findProductsByModel } from '@utils';
import { quotationActions } from '../quotation';
import { selectOrderData } from '../order';
import { modelsActions } from './slice';
import {
    selectNotLoadedModelsIds,
    selectIsPollingModelsActive,
    selectTransformedModelsData,
    selectTransformingModelsIds,
    selectIsTransformedModelPollingActive,
    selectModelsDict,
} from './selectors';

export const createModelsPolling = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const isActive = selectIsPollingModelsActive(state);
    const ids = selectNotLoadedModelsIds(state);

    if (isActive || !ids.length) return;

    dispatch(modelsActions.startModelsPolling());
};

export const createTransformedModelsPolling = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const isActive = selectIsTransformedModelPollingActive(state);
    const ids = selectTransformingModelsIds(state);

    if (isActive || !ids.length) return;

    dispatch(modelsActions.startTransformedModelPolling());
};

export const transformModelInit =
    (payload: TransformModelPayload): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const order = selectOrderData(state);
        const { fromModelId, type } = payload;
        const models = selectModelsDict(state);
        const transformedModelsData = selectTransformedModelsData(state);
        const transformedModelDataKey = composeTransformedModelPollingId(payload);

        const model = models[fromModelId];
        const parentModelId = model.parent_model || model.id;

        const modelHasProducts = order?.products.length && findProductsByModel(order.products, model).length;
        const transformedModelData = transformedModelsData[transformedModelDataKey];

        if (transformedModelData) {
            // case when we've started transform already, then duplicate model and init transform again
            const transformedModelId = transformedModelData.data.id;

            if (!transformedModelId) {
                return;
            }

            if (modelHasProducts) {
                dispatch(
                    modelsActions.addSelectedModels({
                        ids: [transformedModelId],
                        isAppend: true,
                    }),
                );
            } else {
                // substitute transformedModelId for parentModelId, when switching the scale unites
                dispatch(
                    modelsActions.replaceSelectedModel({
                        prevId: parentModelId,
                        nextId: transformedModelId,
                    }),
                );
            }

            dispatch(
                quotationActions.replaceModel({
                    prevId: parentModelId,
                    nextId: transformedModelId,
                }),
            );
        } else {
            dispatch(quotationActions.resetCurrentModel());

            if (!modelHasProducts) {
                dispatch(modelsActions.removeSelectedModel(parentModelId));
            }

            if (type === 'scale') {
                dispatch(modelsActions.scale(payload));
            } else {
                dispatch(modelsActions.rotate(payload));
            }
        }
    };
