import get from 'lodash/get';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useParams } from 'react-router-dom';
import { ObjectModel, Technology, PriceConfigForm, SetPriceConfigActionMeta } from '@types';
import { modelsActions, selectRelatedModelsDataById } from '@modules/models';
import { pricingActions } from '@modules/pricing';
import { productActions } from '@modules/product';
import { selectOrderId } from '@modules/order';
import {
    quotationActions,
    selectWidgetVisibleModelsCount,
    selectWidgetTechnology,
    selectWidgetMaterial,
    selectWidgetPriceConfig,
    selectWidgetCount,
    makeSelectWidgetSpecificationByModelId,
    makeSelectWidgetProductSpecificationByProductId,
} from '@modules/quotation';
import { useFeatureFlag, FeatureKeys as FF } from '@components/feature-flags';
import { getListStaggerDuration, buildOrderUrl } from '@utils';

export function useWidgetTechnology() {
    const dispatch = useAppDispatch();
    const widgetTechnology = useAppSelector(selectWidgetTechnology);
    const setWidgetTechnology = useCallback(
        (technology: Technology) => dispatch(quotationActions.setTechnology(technology)),
        [dispatch],
    );

    return {
        widgetTechnology,
        setWidgetTechnology,
    };
}

export function useWidgetMaterial() {
    const dispatch = useAppDispatch();
    const widgetMaterial = useAppSelector(selectWidgetMaterial);
    const setWidgetMaterial = useCallback(
        (materialId: number) => dispatch(quotationActions.setMaterial(materialId)),
        [dispatch],
    );

    return {
        widgetMaterial,
        setWidgetMaterial,
    };
}

export function useWidgetPriceConfig() {
    const dispatch = useAppDispatch();
    const widgetPriceConfig = useAppSelector(selectWidgetPriceConfig);
    const setWidgetPriceConfig = useCallback(
        (config: PriceConfigForm, meta: SetPriceConfigActionMeta) => {
            return dispatch(quotationActions.setConfig(config, meta));
        },
        [dispatch],
    );

    return {
        widgetPriceConfig,
        setWidgetPriceConfig,
    };
}

export function useWidgetCounter() {
    const dispatch = useAppDispatch();
    const widgetCount = useAppSelector(selectWidgetCount);
    const setWidgetCount = useCallback((count: number) => dispatch(quotationActions.setCount(count)), [dispatch]);

    return {
        widgetCount,
        setWidgetCount,
    };
}

export function useUpdateModel(model: ObjectModel) {
    const dispatch = useAppDispatch();

    return useMemo(
        () =>
            debounce((data: Partial<{ units: ObjectModel['units']; size: ObjectModel['size'] }>) => {
                const units = data.units || model.units;
                const size = data.size || model.size;

                dispatch(modelsActions.update({ modelId: model.id, data: { units, ...size } }));
            }, 400),
        [dispatch, model],
    );
}

export function useFindProduct() {
    const dispatch = useAppDispatch();

    return useMemo(() => {
        return debounce((model_id?: number, material_id?: number, config?: PriceConfigForm) => {
            if (!model_id || !material_id || !config) {
                return;
            }

            dispatch(
                productActions.find({
                    material_id,
                    model_id,
                    config,
                }),
            );
        }, 400);
    }, [dispatch]);
}

export function useUpdateProduct() {
    const dispatch = useAppDispatch();

    return useMemo(() => {
        return debounce((id: string, count: number) => {
            dispatch(
                productActions.update({
                    id,
                    count,
                }),
            );
        }, 400);
    }, [dispatch]);
}

export function useCalculatePrice() {
    const dispatch = useAppDispatch();
    // const settings = useAppSelector(selectAppSettings);
    const isBulkPriceEnabled = useFeatureFlag(FF.CartPage.BulkPriceOn);

    return useMemo(() => {
        return debounce(
            (
                count: number,
                model?: ObjectModel,
                technology_id?: number,
                material_id?: number,
                config?: PriceConfigForm,
            ) => {
                if (!model || !technology_id || !material_id || !config) {
                    return;
                }

                const payload = {
                    is_parent: !model.parent_model,
                    model_id: model.id,
                    technology_id,
                    material_id,
                    config: omit(config, ['extra_fieldsets']),
                    count,
                };

                if (isBulkPriceEnabled) {
                    return dispatch(pricingActions.calculateBulk(payload));
                } else {
                    return dispatch(pricingActions.calculate(payload));
                }
            },
            400,
        );
    }, [dispatch, isBulkPriceEnabled]);
}

export function useWidgetSpecification(modelId: number) {
    const selectWidgetSpecification = useMemo(makeSelectWidgetSpecificationByModelId, []);
    return useAppSelector(state => selectWidgetSpecification(state, modelId));
}

export function useWidgetProductSpecification(productId: string) {
    const selectWidgetSpecification = useMemo(makeSelectWidgetProductSpecificationByProductId, []);
    return useAppSelector(state => selectWidgetSpecification(state, productId));
}

export function useOrderId() {
    // sometimes cartId only exists in store or params (in cart page) and you want to draw loaders while it is loading,
    // so try to find it in both places
    const { orderId: urlOrderId } = useParams();
    const orderId = useAppSelector(selectOrderId);
    const _urlOrderId = urlOrderId && parseInt(urlOrderId);
    return _urlOrderId && !Number.isNaN(_urlOrderId) ? _urlOrderId : orderId;
}

type GetOrderUrlReturnValue = [(id?: number) => string, ReturnType<typeof useOrderId>];

export function useGetOrderUrl(): GetOrderUrlReturnValue {
    const currentOrderId = useOrderId();
    const getOrderUrl = useCallback(
        (id?: number) => {
            const orderId = id || currentOrderId;
            return buildOrderUrl({ orderId });
        },
        [currentOrderId],
    );

    return [getOrderUrl, currentOrderId];
}

// UI utils
export function useWidgetModelHasAlerts(modelId: number) {
    const spec = useWidgetSpecification(modelId);
    const modelData = useAppSelector(state => selectRelatedModelsDataById(state, spec.correctObjectModel || modelId));

    const error = Boolean(get(modelData, ['analysing_errors', spec.materialId]));
    const warning = false; // TODO wall_thickness

    return {
        error,
        warning,
    };
}

export function useModelsStaggerDuration() {
    return useAppSelector(state => {
        const count = selectWidgetVisibleModelsCount(state);
        return getListStaggerDuration({ count });
    });
}
