export const CustomAppConfigFields = ['widget_style_customization', 'widget_content_customization'] as const;

export enum BrowserStorageKeys {
    TipOfUpdatingProductWasClosed = 'tip-of-updating-product-was-closed',
    SuccessPaidModalWasClosed = 'success-paid-modal-was-closed',
}

export const PollingConfig = (() => {
    const defaults = { length: 5 * 60 * 1000, interval: 4 * 1000, delay: 0 };

    const getConfig = (options?: Partial<typeof defaults>) => {
        const config = Object.assign({}, defaults, options);
        return {
            attempts: Math.ceil(config.length / config.interval),
            ...config,
        };
    };

    return {
        modelsAsyncFields: getConfig({
            length: 5 * 60 * 1000, // 5 min
        }), // 75 attempts
        modelsStatus: getConfig({
            length: 3 * 60 * 1000, // 3 min
            delay: 2 * 1000,
        }),
        paymentStatus: getConfig({ delay: 1000 }),
        preselection: getConfig(),
        shippingRates: getConfig({
            length: 3 * 60 * 1000, // 3 min
        }),
        suitableMaterials: getConfig(),
    };
})();

export const AxisNames = ['x', 'y', 'z'] as const;
