import { useAppSelector } from '@app/hooks';
// import { NestedDialogContextProvider } from '@react-md/dialog';
import { selectIsAuthenticated } from '@modules/auth';
import { selectPopupState } from '@modules/ui';
import { selectUserPermissions } from '@modules/user';
import { AuthPopup, OrderPopup, PopupDeps, PopupStatus } from '@types';
import { OtpPopup, SignInPopup, SignupPopup } from '@pages/auth';
import { NotificationModal } from '../modal-notification/modal-notification';

const mappedPopups = {
    [AuthPopup.OTP]: OtpPopup,
    [AuthPopup.SignIn]: SignInPopup,
    [AuthPopup.SignUp]: SignupPopup,
    [OrderPopup.Notification]: NotificationModal,
};

function getPopupStatus(type: string, { isAuthenticated, isAuthRequired, permissions }: PopupDeps): PopupStatus {
    switch (type) {
        case AuthPopup.OTP:
        case AuthPopup.SignIn:
        case AuthPopup.SignUp: {
            return {
                isOpened: !isAuthenticated,
                isClosable: !isAuthRequired,
            };
        }
        default:
            return {
                isOpened: true,
                isClosable: true,
            };
    }
}

export const ParameterPopups = () => {
    // const { mountedPopups, popups } = useGetPopupsState();

    const isAuthRequired = true; // used to have company settings auth_required
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const { isOpen, isClosable, type: popupType } = useAppSelector(selectPopupState);
    const permissions = useAppSelector(selectUserPermissions);

    const Component = mappedPopups[popupType];
    if (!Component) {
        return null;
    }

    const status = getPopupStatus(popupType, { isAuthenticated, isAuthRequired, permissions });

    return (
        <Component key={popupType} isOpened={isOpen && status.isOpened} isClosable={isClosable ?? status.isClosable} />
    );

    // return (
    //     <TransitionGroup component={null}>
    //         {mountedPopups.map(mountedPopup => {
    //             const Component = mappedPopups[mountedPopup];
    //
    //             if (!Component) {
    //                 return null;
    //             }
    //
    //             const status = getPopupStatus(mountedPopup, { isAuthenticated, isAuthRequired });
    //
    //             return (
    //                 <Component
    //                     key={mountedPopup}
    //                     isOpened={status.isOpened && popups.includes(mountedPopup)}
    //                     isClosable={status.isClosable}
    //                 />
    //             );
    //         })}
    //     </TransitionGroup>
    // );
};
