import { useCallback, useEffect, useRef, useState } from 'react';
// import { useDropzone } from '@react-md/utils'; // we can use it instead of useDocumentDropzone, but useDropzone has some limitations

interface Props {
    onDrop?: (event: DragEvent) => void;
}

function useDocumentDropzone({ onDrop }: Props = {}) {
    const dragCount = useRef(0);
    const [isDragging, setDragging] = useState(false);

    const onDragEnterCallback = useCallback(
        event => {
            event.preventDefault();
            dragCount.current += 1;
            if (dragCount.current > 1) {
                return;
            }
            const fileIndex = Array.from(event.dataTransfer.items).findIndex(
                item => (item as DataTransferItem).kind === 'file',
            );
            if (fileIndex >= 0) {
                setDragging(true);
            }
        },
        [dragCount, setDragging],
    );

    const onDragLeaveCallback = useCallback(
        event => {
            event.preventDefault();
            dragCount.current -= 1;
            if (dragCount.current > 0) {
                return;
            }
            setDragging(false);
        },
        [dragCount, setDragging],
    );

    const onDropCallback = useCallback(
        event => {
            event.preventDefault();
            setDragging(false);
            dragCount.current = 0;
            onDrop?.(event);
        },
        [onDrop, dragCount, setDragging],
    );

    const onDragoverCallback = useCallback(event => {
        event.preventDefault();
    }, []);

    useEffect(() => {
        document.addEventListener('drop', onDropCallback);
        document.addEventListener('dragover', onDragoverCallback);
        document.addEventListener('dragenter', onDragEnterCallback);
        document.addEventListener('dragleave', onDragLeaveCallback);
        return () => {
            document.removeEventListener('drop', onDropCallback);
            document.removeEventListener('dragover', onDragoverCallback);
            document.removeEventListener('dragenter', onDragEnterCallback);
            document.removeEventListener('dragleave', onDragLeaveCallback);
        };
    }, [onDropCallback, onDragoverCallback, onDragEnterCallback, onDragLeaveCallback]);

    return { isDragging };
}

// global detection of dragging to highlight zones
export const DropzoneDocument = () => {
    const { isDragging } = useDocumentDropzone();

    useEffect(() => {
        document.body.classList.toggle('dropzone-page--active', isDragging);
    }, [isDragging]);

    return null;
};
