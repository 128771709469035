import cn from 'classnames';
import React, { forwardRef, HTMLAttributes } from 'react';

export const DetailsBox = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(function DetailsBox(
    { children, className, ...props },
    ref,
) {
    return (
        <div
            {...props}
            className={cn('fade-in', 'small-margin-top', 'rounded-box', 'bg-secondary', className)}
            ref={ref}
        >
            {children}
        </div>
    );
});
