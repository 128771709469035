import cn from 'classnames';
import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@react-md/button';
import { DialogContent, DialogTitle } from '@react-md/dialog';
import { Typography } from '@react-md/typography';
import { FeatureContent } from '@components/feature-content';
import { AppSettings } from '@types';
import { getNodeIdComposer } from '@utils';

import styles from './terms.module.scss';

const _id = getNodeIdComposer('terms_and_conditions');

interface TermsProps {
    content: AppSettings['terms_and_conditions'];
    className?: string;
}

export const Terms: React.FC<TermsProps> = ({ className, content }) => {
    const [isOpen, setOpen] = useState(false);

    const { t } = useTranslation();

    const transComponent = {
        btn: (
            <Button
                id={_id('button', 'open')}
                theme="primary"
                className={cn('rmd-button--flat', 'rmd-typography--semi-bold', 'text-inherit')}
                onClick={() => setOpen(true)}
                disableRipple
            />
        ),
    };

    return (
        <>
            <Typography type="subtitle-2" className={className} component="div">
                <Trans
                    i18nKey="messages.termsAndConditions"
                    components={transComponent}
                    defaults={`By uploading model I accept  <btn>Terms & Conditions</btn>`}
                />
            </Typography>
            <Modal
                open={isOpen}
                onClose={() => setOpen(false)}
                closeOnEsc={true}
                center={true}
                styles={{
                    modal: { minWidth: '360px' },
                }}
            >
                <DialogTitle>
                    <Typography
                        id={_id('content', 'title')}
                        type="headline-3"
                        component={'div'}
                        className={styles.title}
                    >
                        <FeatureContent
                            contentKey={_id('content', 'title')}
                            fallback={t('titles.termsAndConditions', 'Terms & Conditions')}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography margin="none" type="body-1" className={styles.desc}>
                        {content}
                    </Typography>
                </DialogContent>
            </Modal>
        </>
    );
};
