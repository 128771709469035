import cn from 'classnames';
import React, { MouseEventHandler, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Typography } from '@react-md/typography';
import { Button } from '@react-md/button';
import { Icons } from '@components/icons';
import { OrderPriceCorrection as OrderPriceCorrectionType } from '@types';
import { Price } from '@components/price';
import { priceCorrectionsActions, selectPriceCorrections } from '@modules/price-corrections';
import { _id } from './helpers';

import styles from './order-price-corrections.module.scss';

const OrderPriceCorrection = ({ id, comment, value }: OrderPriceCorrectionType) => {
    const [isRemoving, setIsRemoving] = useState(false);

    const dispatch = useAppDispatch();

    const handleRemove: MouseEventHandler<HTMLButtonElement> = ({ currentTarget: { dataset } }) => {
        setIsRemoving(true);
        dispatch(priceCorrectionsActions.deletePriceCorrection(Number(dataset.price_correction_id)));
    };

    return (
        <li key={id} className={cn('fade-in', 'small-margin-top', 'rounded-box', 'bg-secondary', styles.row)}>
            <Typography type="body-2" component="div">
                {comment || 'Unnamed'}
            </Typography>
            <Typography type="body-2" component={'div'} className={styles.value}>
                <Price>{value}</Price>
            </Typography>

            <Button
                id={_id('delete', id)}
                data-price_correction_id={id}
                theme="error"
                themeType="flat"
                buttonType="icon"
                className={cn('rmd-button--icon-circle', styles.delete)}
                disabled={isRemoving}
                onClick={handleRemove}
            >
                <Icons.Trash />
            </Button>
        </li>
    );
};

export const OrderPriceCorrectionsList = () => {
    const priceCorrectionList = useAppSelector(selectPriceCorrections);
    const hasLongText = priceCorrectionList.some(data => (data?.comment?.length || 0) > 100);

    if (!priceCorrectionList.length) {
        return null;
    }

    return (
        <ul className={cn('margin-top', styles.list, { [styles.max]: !hasLongText })}>
            {priceCorrectionList.map(data => (
                <OrderPriceCorrection key={data.id} {...data} />
            ))}
        </ul>
    );
};
