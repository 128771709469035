// List of deprecated content variables and variables with custom structure
// I hardcode these variables due to type inference, simple string variables defined by node id

enum Header {
    HeaderHowItWorksContentSet = 'header-how_it_works-html', // obsolete
}

enum CartPage {
    ViewerSectionConditionalMarkupByTech = 'cart_page-viewer_section-conditional_markup_by_tech',
    Under3dViewerConditionalTechContentSet = 'cart_3d_viewer-conditional_tech_content_under-html', // obsolete
    UnderSpecificationsContentSet = 'cart_specifications-content_under-html', // obsolete
    UnderSpecificationsGridContentSet = 'cart_specifications_grid-content_under-html', // obsolete
}

enum OrderPage {
    UnderModelContentSet = 'order_model-content_under-html', // obsolete
    UnderTotalPriceContentSet = 'order_total_price-content_under-html', // obsolete
}

export const ContentKeys = {
    Header,
    OrderPage,
    CartPage,
} as const;
