import { SpecificationFormParams } from '@pages/widget/order/specification/types';
import { CncFeatureTypesLiteral } from './cnc-feature-types';
import { PriceConfigExtraFieldset } from './price-config';

export interface PriceConfigFormExtraFieldset extends PriceConfigExtraFieldset {}

export interface PriceConfigFormPostProduction {
    uuid: string;
    option_uuid?: string;
    quantity?: number;
}

export type PriceConfigFormFeature = {
    machine_feature_id: string;
    model_feature_id: string;
};

export interface SpecificationInitialValues {
    formParams: SpecificationFormParams;
    initialThickness?: string;
}

export interface PriceConfigForm {
    filling?: string;
    color?: string;
    layer_thickness?: string;
    lead_time?: string;
    post_production?: PriceConfigFormPostProduction[];
    extra_fieldsets?: PriceConfigFormExtraFieldset[];
    thickness?: string;
    tolerance?: string;
    dfm_features?: PriceConfigFormFeature[];
}

export enum PriceConfigFormFields {
    Filling = 'filling',
    Color = 'color',
    LayerThickness = 'layer_thickness',
    LeadTime = 'lead_time',
    PostProduction = 'post_production',
    ExtraFieldsets = 'extra_fieldsets',
    Features = 'dfm_features',
    Thickness = 'thickness',
    Tolerance = 'tolerance',
}

export type PriceConfigFormField = `${PriceConfigFormFields}`;

export interface SetPriceConfigActionMeta {
    requestPrice: boolean;
    mergeRepeatSpecs?: boolean;
}
