import { AxiosRequestConfig } from 'axios';
import { Rotation } from '@utils/viewer/model-viewer/types';
import { ObjectModelCncFeature } from './cnc-feature-types';
import { ClientRepresentationSerializer } from './client';
import { GetRequestBaseParams } from './request';
import { UploadModelsObjectResponse } from './upload';
import { Point } from './point';
import { PricingError } from './pricing';
import { Nullable } from './helpers';

export enum ObjectModelStatus {
    Progress = 'in_progress',
    Failed = 'failed',
    InitialFailed = 'InitialStatus.failed',
    Ready = 'ready',
}

export enum ObjectModelThumbStatus {
    Progress = 'in_progress',
    Failed = 'failed',
    Success = 'success',
}

export enum ObjectModelUnitsTypes {
    Millimeters = 'mm',
    Centimeters = 'cm',
    Inches = 'in',
}

export type ObjectModelUnits = `${ObjectModelUnitsTypes}`;
export type ObjectModelGeometryType = 'shaft' | 'disk' | 'sheet' | 'flat_sheet' | 'sheetmetal' | 'other';

export interface ObjectModelRepairedRes {
    number_of_facets?: number;
    facets_removed?: number;
    facets_added?: number;
    facets_reversed?: number;
    edges_fixed?: number;
    // manifold: string;
}

export interface ObjectModelDfmFeatures {
    part_sizes: Nullable<Point>;
    hole: any[];
    slot: any[];
    wall: any[];
    bend: any[];
    fillets: number[];
    pocket: any[];
    pad: any[];
    notch: any[];
}

export interface ObjectModel {
    id: number;
    upload_job_id?: string;
    client?: null | ClientRepresentationSerializer; // CustomUserSerializer
    title: string;
    initial_status?: ObjectModelStatus;
    repaired_res?: ObjectModelRepairedRes;
    units: ObjectModelUnits;
    volume?: number;
    surface?: number;
    perimeter?: number;
    punches_count?: number;
    is_processable?: boolean;
    is_rotated?: boolean;
    cnc_features?: ObjectModelCncFeature[];
    dfm_features?: ObjectModelDfmFeatures;
    thumb?: string;
    thumb_120x120?: string;
    thumb_300x300?: string;
    thumb_status?: ObjectModelThumbStatus;
    filesize?: string;
    file_model_original?: string;
    file_model_repaired?: string;
    file_model_original_url?: string;
    file_model_viewer_url?: string;
    file_model_stl_original_url?: string;
    file_model_stl_repaired_url?: string;
    object_model_original_url?: string;
    date_created?: string;
    parent_model: number;
    technologies?: Array<number | unknown>;
    geometry_type?: ObjectModelGeometryType;
    cnc_complexity_level?: number;
    size: Nullable<Point>;
    child_models: number[];
    // cnc_complexity: number; // it is shown in purchase
}

interface WallThicknessData {
    status: 'passed' | 'not_passed';
    thin_faces_file_url: string;
    minimal_wall_thickness: number;
}

export interface RelatedObjectModelData {
    isModelFileLoaded?: boolean;
    analysing_errors?: Record<string, PricingError[]>; // key is material ID // todo move analysing_errors to quotation nested slice 'models meta'
    wall_thickness?: Record<string, WallThicknessData>; // key is material ID
}

// load model
export interface LoadModelsParams extends GetRequestBaseParams {
    // searching
    search?: string;

    // filtering
    id?: string; // comma separated values, e.g. 23,45,92
    title?: string;
    initial_status?: ObjectModelStatus;
    units?: ObjectModelUnits;
    geometry_type?: ObjectModelGeometryType;
    surface?: number;
    quote_succeed?: boolean;
    parent_is_null?: boolean;
    date_from?: string; // "%d/%m/%Y"
    date_to?: string; // "%d/%m/%Y"

    // sorting
    ordering?: string; // 'id' | 'date_created' | '-id' | '-date_created'
    // can be combined by comma e.g. '/v2/models/?ordering=date_created,id'
    // for descending should be prefixed with minus e.g. '/v2/models/?ordering=id,-date_created'
}

export interface LoadModelsIqtParams extends LoadModelsParams {
    // filtering
    user_id__in?: string; // comma separated values, e.g. 23,45,92
}

// update model
export type UpdateModelPayload = {};
export type UpdateNonCadModelPayload = {
    units: ObjectModelUnits;
} & Nullable<{
    x: number;
    y: number;
    z: number;
}>;

export interface LoadPreselection {
    modelsIds: number[];
    isIqtModeOn: boolean;
    config?: AxiosRequestConfig;
}

export interface LoadSuitableMaterials {
    modelsIds: number[];
    isIqtModeOn: boolean;
    config?: AxiosRequestConfig;
}

// transform model
export type ScaleModelPayload = {
    fromModelId: ObjectModel['id'];
    type: 'scale';
    units: ObjectModelUnits;
    isIqtModeOn?: boolean;
};

export type RotateModelPayload = {
    fromModelId: ObjectModel['id'];
    type: 'rotate';
    rotations: Rotation[];
    isIqtModeOn?: boolean;
};

export type TransformModelPayload = ScaleModelPayload | RotateModelPayload;

export type TransformedModelData = TransformModelPayload & {
    ts: number; // for ordering
    deleted: boolean;
    data: UploadModelsObjectResponse;
};
