import { Address } from '@types';

const emptyAddress = (): Address => ({
    company_name: '',
    city: '',
    street_address: '',
    apartment: '',
    postcode: '',
    country: '',
    state: '',
});

export const createAddress = <T extends Address = Address>(
    initialValues?: Omit<T, keyof Address> & Partial<Address>,
) => {
    return Object.assign(emptyAddress(), initialValues);
};
