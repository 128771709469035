import { GuiInput, GuiSlider, GuiInputCategory, GuiInputUnits, GuiInputType } from './helpers';

export const THEME_CONFIG_INPUTS: (GuiInput | GuiSlider)[] = [
    // surface
    {
        label: 'Primary',
        varName: 'color-background-primary',
        defaultValue: '#ffffff',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.SURFACE,
    },
    {
        label: 'Secondary',
        varName: 'color-background-secondary',
        defaultValue: '#f4f6f9',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.SURFACE,
    },
    {
        label: 'Disabled',
        varName: 'color-background-disabled',
        defaultValue: '#f4f6f9',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.SURFACE,
    },
    {
        label: 'Inverse',
        varName: 'color-background-inverse',
        defaultValue: '#e4e9f0',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.SURFACE,
    },
    {
        label: 'Custom App Background',
        varName: 'app-background',
        defaultValue: 'none',
        type: GuiInputType.TEXT,
        category: GuiInputCategory.SURFACE,
    },
    // ui
    {
        label: 'Primary',
        varName: 'color-ui-primary',
        defaultValue: '#0057d7',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'Primary Subtle',
        varName: 'color-ui-primary-subtle',
        defaultValue: '#b7cef0',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'Primary Subtlest',
        varName: 'color-ui-primary-subtlest',
        defaultValue: '#f3f8ff',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'Primary Accent',
        varName: 'color-ui-primary-accent',
        defaultValue: '#003d96',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'On Primary',
        varName: 'color-on-ui-primary',
        defaultValue: '#ffffff',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'Secondary',
        varName: 'color-ui-secondary',
        defaultValue: '#224170',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'Secondary Subtle',
        varName: 'color-ui-secondary-subtle',
        defaultValue: '#224170',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'Secondary Accent',
        varName: 'color-ui-secondary-accent',
        defaultValue: '#0c2953',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'On Secondary',
        varName: 'color-on-ui-secondary',
        defaultValue: '#ffffff',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'Success',
        varName: 'color-ui-success',
        defaultValue: '#4daf00',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'Success Subtle',
        varName: 'color-ui-success-subtle',
        defaultValue: '#d4e2c9',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'On Success',
        varName: 'color-on-ui-success',
        defaultValue: '#ffffff',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'Warning',
        varName: 'color-ui-warning',
        defaultValue: '#ffc107',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'Warning Subtle',
        varName: 'color-ui-warning-subtle',
        defaultValue: '#feebc8',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'Warning Accent',
        varName: 'color-ui-warning-accent',
        defaultValue: '#eab408',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'On Warning',
        varName: 'color-on-ui-warning',
        defaultValue: '#ffffff',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'Error',
        varName: 'color-ui-error',
        defaultValue: '#ff5b4a',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'Error Subtle',
        varName: 'color-ui-error-subtle',
        defaultValue: '#fce6e6',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    {
        label: 'On Error',
        varName: 'color-on-ui-error',
        defaultValue: '#ffffff',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.PRIMARY,
    },
    // borders
    {
        label: 'Color',
        varName: 'color-border-primary',
        defaultValue: '#e4e9f0',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.BORDER,
    },
    {
        label: 'Base Radius',
        varName: 'shape-border-radius-base',
        defaultValue: 4,
        type: GuiInputType.SLIDER,
        units: GuiInputUnits.REM,
        category: GuiInputCategory.BORDER,
        minValue: 0,
        maxValue: 50,
        step: 0.1,
    },
    // text + icons
    {
        label: 'Primary Text',
        varName: 'color-text-primary',
        defaultValue: '#121117',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.TYPOGRAPHY,
    },
    {
        label: 'Secondary Text',
        varName: 'color-text-secondary',
        defaultValue: '#7f91a2',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.TYPOGRAPHY,
    },
    {
        label: 'Disabled Text',
        varName: 'color-text-disabled',
        defaultValue: '#7f91a2',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.TYPOGRAPHY,
    },
    {
        label: 'On Inverse BG Color',
        varName: 'color-on-background-inverse-default',
        defaultValue: '#121117',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.TYPOGRAPHY,
    },
    {
        label: 'On Inverse BG Color Hover',
        varName: 'color-on-background-inverse-hover',
        defaultValue: '#0057d7',
        type: GuiInputType.PALETTE,
        category: GuiInputCategory.TYPOGRAPHY,
    },
    // font
    {
        label: 'Headline 1 Size',
        varName: 'font-size-heading-1',
        defaultValue: 48.8,
        type: GuiInputType.SLIDER,
        units: GuiInputUnits.REM,
        category: GuiInputCategory.TYPOGRAPHY,
        minValue: 8,
        maxValue: 70,
        step: 0.02,
        disabled: true,
    },
    {
        label: 'Headline 2 Size',
        varName: 'font-size-heading-2',
        defaultValue: 30,
        type: GuiInputType.SLIDER,
        units: GuiInputUnits.REM,
        category: GuiInputCategory.TYPOGRAPHY,
        minValue: 8,
        maxValue: 70,
        step: 0.02,
        disabled: true,
    },
    {
        label: 'Headline 3 Size',
        varName: 'font-size-heading-3',
        defaultValue: 24,
        type: GuiInputType.SLIDER,
        units: GuiInputUnits.REM,
        category: GuiInputCategory.TYPOGRAPHY,
        minValue: 8,
        maxValue: 70,
        step: 0.02,
    },
    {
        label: 'Headline 4 Size',
        varName: 'font-size-heading-4',
        defaultValue: 18.76,
        type: GuiInputType.SLIDER,
        units: GuiInputUnits.REM,
        category: GuiInputCategory.TYPOGRAPHY,
        minValue: 8,
        maxValue: 70,
        step: 0.02,
    },
    {
        label: 'Headline 5 Size',
        varName: 'font-size-heading-5',
        defaultValue: 15,
        type: GuiInputType.SLIDER,
        units: GuiInputUnits.REM,
        category: GuiInputCategory.TYPOGRAPHY,
        minValue: 8,
        maxValue: 70,
        step: 0.02,
    },
    {
        label: 'Headline 6 Size',
        varName: 'font-size-heading-6',
        defaultValue: 13,
        type: GuiInputType.SLIDER,
        units: GuiInputUnits.REM,
        category: GuiInputCategory.TYPOGRAPHY,
        minValue: 8,
        maxValue: 70,
        step: 0.02,
    },
    {
        label: 'Input Font Size',
        varName: 'input-font-size',
        defaultValue: 15,
        type: GuiInputType.SLIDER,
        units: GuiInputUnits.REM,
        category: GuiInputCategory.TYPOGRAPHY,
        minValue: 8,
        maxValue: 70,
        step: 0.02,
    },
    {
        label: 'Body Medium Font Size',
        varName: 'font-size-body-md',
        defaultValue: 15,
        type: GuiInputType.SLIDER,
        units: GuiInputUnits.REM,
        category: GuiInputCategory.TYPOGRAPHY,
        minValue: 8,
        maxValue: 70,
        step: 0.02,
    },
    {
        label: 'Body Small Font Size',
        varName: 'font-size-body-sm',
        defaultValue: 13,
        type: GuiInputType.SLIDER,
        units: GuiInputUnits.REM,
        category: GuiInputCategory.TYPOGRAPHY,
        minValue: 8,
        maxValue: 70,
        step: 0.02,
    },
    {
        label: 'Metadata Font Size',
        varName: 'font-size-body-xs',
        defaultValue: 12,
        type: GuiInputType.SLIDER,
        units: GuiInputUnits.REM,
        category: GuiInputCategory.TYPOGRAPHY,
        minValue: 8,
        maxValue: 70,
        step: 0.02,
    },
    {
        label: 'Button 1 Font Size',
        varName: 'button-1-font-size',
        defaultValue: 13,
        type: GuiInputType.SLIDER,
        units: GuiInputUnits.REM,
        category: GuiInputCategory.TYPOGRAPHY,
        minValue: 8,
        maxValue: 70,
        step: 0.02,
    },
    {
        label: 'Button 2 Font Size',
        varName: 'button-2-font-size',
        defaultValue: 10.24,
        type: GuiInputType.SLIDER,
        units: GuiInputUnits.REM,
        category: GuiInputCategory.TYPOGRAPHY,
        minValue: 8,
        maxValue: 70,
        step: 0.02,
    },
    {
        label: 'Tech Icon Width',
        varName: 'technology-preview-size-width',
        defaultValue: 50,
        type: GuiInputType.SLIDER,
        units: GuiInputUnits.PX,
        category: GuiInputCategory.MISCELLANEOUS,
        minValue: 5,
        maxValue: 300,
        step: 1,
    },
    {
        label: 'Tech Icon Height',
        varName: 'technology-preview-size-height',
        defaultValue: 50,
        type: GuiInputType.SLIDER,
        units: GuiInputUnits.PX,
        category: GuiInputCategory.MISCELLANEOUS,
        minValue: 5,
        maxValue: 300,
        step: 1,
    },
];
