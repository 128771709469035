import cn from 'classnames';
import React from 'react';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { TextIconSpacing } from '@react-md/icon';
import { Icons } from '@components/icons';
import { ButtonLink } from '@components/link';
import { AppMode } from '@types';
import { reverse } from '@utils';
import { useBuildLocation } from '@hooks';
import { selectClient, selectClientId, selectIsClientLoading } from '@modules/client';
import { IqtBannerEntry } from './iqt-banner-entry';
import { IqtBannerLabel } from './iqt-banner-label';
import { composeClientLabel, iqtBannerId } from './helpers';

import styles from './iqt-banner.module.scss';

export const IqtBanner = () => {
    const client = useAppSelector(selectClient);
    const clientId = useAppSelector(selectClientId);
    const isLoading = useAppSelector(selectIsClientLoading);
    const clientLocation = useBuildLocation({
        to: reverse('widgetClient', undefined, AppMode.Iqt),
    });
    const { t } = useTranslation();

    if (!clientId) return null;

    return (
        <ButtonLink
            id={iqtBannerId('quotation_tool')}
            theme="clear"
            themeType="contained"
            className={cn('fade-in', styles.box)}
            {...clientLocation}
        >
            <IqtBannerLabel />

            <TextIconSpacing icon={isLoading ? undefined : <Icons.Chevron />} iconAfter forceIconWrap>
                <IqtBannerEntry
                    isLoading={isLoading}
                    label={t('labels.client', 'Client')}
                    value={client && composeClientLabel(client)}
                />
            </TextIconSpacing>
        </ButtonLink>
    );
};
