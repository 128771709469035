import cn from 'classnames';
import React, { memo, useReducer, useRef } from 'react';
import { DxfViewer as _DxfViewer } from 'dxf-viewer';
import { useCloseOnEscape, useScrollLock } from '@react-md/utils';
import { useInitDxfViewer, useSetupDxfFile } from '@utils';
import { ViewerModePanel } from '../viewer-mode-panel';
import { ViewerThumb } from '../viewer-thumb';
import { ObjectModelModel } from '@models';

import styles from '../viewer-window.module.scss';

interface DxfViewerProps {
    model: ObjectModelModel;
    onLoadFileCallback: (modelFileUrl: string) => void;
}

export const DxfViewer = memo<DxfViewerProps>(
    ({ model, onLoadFileCallback }) => {
        const viewer = useRef<_DxfViewer>();
        const visualizer = useRef<HTMLDivElement>(null);
        const [isFullscreenModeActive, toggleFullscreenMode] = useReducer(previous => !previous, false);
        const [isThumbModeActive, toggleThumbMode] = useReducer(previous => !previous, false);

        useScrollLock(isFullscreenModeActive);
        useInitDxfViewer(viewer, visualizer);
        useSetupDxfFile(viewer, model.file_model_viewer_url!, onLoadFileCallback);

        // useEffect(() => {
        //     viewer.current?.forceUpdateSize();
        //     // eslint-disable-next-line react-hooks/exhaustive-deps
        // }, [isFullscreenModeActive]);

        return (
            <div
                className={cn([styles.box, { [styles.expanded]: isFullscreenModeActive }])}
                onKeyDown={useCloseOnEscape(toggleFullscreenMode, !isFullscreenModeActive)}
                tabIndex={-1}
            >
                <div className={styles.inner} ref={visualizer}>
                    <ViewerThumb model={model} show={isThumbModeActive} expanded={isFullscreenModeActive} />
                    <ViewerModePanel
                        expanded={isFullscreenModeActive}
                        toggleFullscreenMode={toggleFullscreenMode}
                        toggleThumbMode={toggleThumbMode}
                    />
                </div>
            </div>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.model.id === nextProps.model.id &&
            prevProps.model.allowToRenderViewer === nextProps.model.allowToRenderViewer
        );
    },
);
