import cn from 'classnames';
import React, { Children, forwardRef, ElementType, ReactElement, ReactNode } from 'react';
import { PolymorphicRef, PolymorphicComponentPropsWithRef } from '@types';
import { Fade, FADE_STAGGER } from '@components/animations';

interface Props {
    children: ReactNode;
    offset?: number; // number of previous blocks
}

type OrderRevealBlocksProps<C extends ElementType> = PolymorphicComponentPropsWithRef<C, Props>;

type OrderRevealBlocksComponent = <C extends ElementType = 'div'>(
    props: OrderRevealBlocksProps<C>,
) => ReactElement | null;

export const OrderRevealBlocks: OrderRevealBlocksComponent = forwardRef(
    <C extends ElementType = 'div'>(
        { offset = 0, as, children, className, ...props }: OrderRevealBlocksProps<C>,
        ref?: PolymorphicRef<C>,
    ) => {
        const Component = as || 'div';

        return (
            <Component {...props} className={cn('flex', 'flex-col', className)} ref={ref}>
                {Children.toArray(children).map((child, index) => (
                    <Fade
                        key={(child as ReactElement).key}
                        in
                        mountOnEnter
                        delay={(offset + 1 + index) * FADE_STAGGER}
                        className={cn('base-paddings', 'margin-top', 'rounded-box', 'relative')}
                    >
                        {child}
                    </Fade>
                ))}
            </Component>
        );
    },
);
