import { AxiosError } from 'axios';
import { concat, EMPTY, from, of, timer } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { catchError, filter, switchMap, withLatestFrom, take, map, takeWhile, finalize } from 'rxjs/operators';
import { PaymentService } from '@services';
import { AppEpic, InvalidateLevel, PaymentStage } from '@types';
import { PollingConfig, ROUTES } from '@constants';
import { http404 } from '@components/routes';
import { appActions, selectAppCompanyName } from '../app';
import { selectInvoicePaymentStage, selectIsPollingPaymentStatusActive } from './selectors';
import { invoiceActions } from './slice';

const loadInvoiceEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(invoiceActions.load.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            return from(PaymentService.init().loadInvoice(action.payload)).pipe(
                switchMap(({ data }) => {
                    const company = selectAppCompanyName(state);

                    if (data.company_short_name !== company) {
                        return EMPTY.pipe(finalize(() => http404(company)));
                    }

                    return of(invoiceActions.loadSuccess(data));
                }),
                catchError((error: AxiosError) => {
                    const errorCode = error.response?.status;

                    // non-existent invoice TODO nothing to invalidate at this moment
                    if (errorCode === 404) {
                        return concat([
                            invoiceActions.loadFailure(),
                            appActions.invalidateStore({
                                purge: InvalidateLevel.Full,
                                redirect: ROUTES.notFound,
                                redirectByRouter: true,
                            }),
                        ]);
                    }

                    return of(invoiceActions.loadFailure());
                }),
            );
        }),
    );

const updateInvoiceEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(invoiceActions.update.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            return from(PaymentService.init().updateInvoice(action.payload)).pipe(
                switchMap(({ data }) => of(invoiceActions.updateSuccess(data))),
                catchError(() => of(invoiceActions.updateFailure())),
            );
        }),
    );

const loadInvoicePaymentEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(invoiceActions.loadPayment.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            return from(PaymentService.init().loadInvoicePayment(action.payload)).pipe(
                switchMap(({ data }) => of(invoiceActions.loadPaymentSuccess(data))),
                catchError(() => of(invoiceActions.loadPaymentFailure())),
            );
        }),
    );

const startPaymentStatusPollingEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(invoiceActions.startPaymentStatusPolling.match),
        switchMap(action =>
            concat(
                timer(PollingConfig.paymentStatus.delay, PollingConfig.paymentStatus.interval).pipe(
                    take(PollingConfig.paymentStatus.attempts),
                    withLatestFrom(state$),
                    map(([_, state]) => {
                        const isActive = selectIsPollingPaymentStatusActive(state);
                        const stage = selectInvoicePaymentStage(state);
                        return isActive && stage === PaymentStage.Processing;
                    }),
                    takeWhile(needToPoll => needToPoll),
                    switchMap(() =>
                        from(PaymentService.init().loadInvoice(action.payload)).pipe(
                            map(({ data }) =>
                                invoiceActions.updatePaymentStage({
                                    nextAction: data.payment?.next_action,
                                    status: data.payment_status,
                                }),
                            ),
                            catchError((error: AxiosError) => of(invoiceActions.stopPaymentStatusPolling())),
                        ),
                    ),
                ),
                of(invoiceActions.stopPaymentStatusPolling()),
            ),
        ),
    );

export const invoiceEpics = combineEpics(
    loadInvoiceEpic,
    updateInvoiceEpic,
    loadInvoicePaymentEpic,
    startPaymentStatusPollingEpic,
);
