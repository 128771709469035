import React, { SVGProps } from 'react';
import cn from 'classnames';
import { bem } from '@react-md/utils';
const block = bem('rmd-icon');
const SvgHourglass = (props: SVGProps<SVGSVGElement>) => {
    props = Object.assign(
        {
            'aria-hidden': true,
        },
        props,
        {
            className: cn(block(), props.className),
        },
    );
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            fill="none"
            viewBox="0 0 20 20"
            role="img"
            {...props}
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4.167 18.333h11.666M4.167 1.667h11.666M14.167 18.333v-3.476c0-.442-.176-.866-.489-1.179L10 10l-3.678 3.678a1.667 1.667 0 0 0-.489 1.179v3.476M5.833 1.667v3.476c0 .442.176.866.489 1.179L10 10l3.678-3.678c.313-.313.489-.737.489-1.179V1.667"
            />
        </svg>
    );
};
export default SvgHourglass;
