import type { PaymentIntent } from '@stripe/stripe-js';
import { Order, OrderStatuses } from './order';

// paypal
export interface DataForIPN {
    business: string;
    no_shipping: number;
    return_url: string;
    notify_url: string;
    paypal_btn_url: string;
}

// payfast
export interface DataForITN {
    merchant_id: string;
    merchant_key: string;
    payfast_btn_url: string;
    return_url: string;
    cancel_url: string;
    notify_url: string;
    m_payment_id: string;
    amount: string;
    item_name: string;
    signature: string;
}

// on backend we store some stripe.PaymentIntent webhooks events
export enum InvoicePaymentStatus {
    Created = 'created',
    Processing = 'processing',
    PartiallyFunded = 'partially_funded',
    Succeeded = 'succeeded',
    Failed = 'failed',
    Cancelled = 'cancelled',
}

// wrapper for stripe.PaymentIntent
export interface InvoicePayment {
    external_id: string;
    secret: string;
    status: InvoicePaymentStatus;
    next_action?: PaymentIntent.NextAction;
    amount: string;
    created: string;
    updated: string;
    payment_methods: string[];
}

export enum PaymentMethodTypes {
    PayPal = 'paypal',
    Stripe = 'stripe',
    Robokassa = 'robokassa',
    PayFast = 'payfast',
    WiresOnly = 'wires-only', // "Other payment (outside DigiFabster's purview)"
}

export enum PaymentStage {
    Denied = 'denied', // wrong company payment settings
    NeedsInitialization = 'needs_initialization',
    Allowed = 'allowed',
    Processing = 'processing',
    Paid = 'paid',
    Cancelled = 'cancelled',
}

export interface InvoiceBase {
    id: number;
    order: Order;
    is_order_invoice: boolean;
    is_paid: boolean;
    cost: number;
    payment_received: boolean;
    pay_url: string;
    pdf_url: string;
    payment_method?: PaymentMethodTypes;
    paypal_not_paid_status?: string;
    uuid: string;
}

export interface Invoice extends Omit<InvoiceBase, 'payment_received' | 'paypal_not_paid_status' | 'pay_url'> {
    company_short_name: string;
    payment_status: PaymentStage;
    payment?: InvoicePayment;
}

export interface OrderPoData {
    po_number: string;
    attached_po: File | null;
}

export type OrderUpdateViaInvoiceData =
    | OrderPoData
    | {
          status: OrderStatuses.Placed; // "Status can be changed only from 'firm_offer_sent' to 'placed'"
      };
