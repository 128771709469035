import { ValuesType } from '@types';

export const ROUTES = {
    invoice: '/:company/invoice/:invoiceId/:hash',

    widgetUpload: '/:company/widget/upload',
    widgetOrder: '/:company/widget/order/:orderId?',
    widgetOrderSubmit: '/:company/widget/order/:orderId/submit',
    widgetPollSuccess: '/:company/widget/poll/success',
    viewer: '/:company/widget/viewer',
    modelViewer: '/:company/widget/model-viewer',

    userModels: '/:company/user/models',
    userOrders: '/:company/user/orders',
    userOrder: '/:company/user/orders/:orderId',
    userSettings: '/:company/user/settings',

    signIn: '/:company/sign-in',
    signUp: '/:company/sign-up',
    obtainOtp: '/:company/obtain-otp',
    recoveryPassword: '/:company/password-recovery/:token',
    resetPassword: '/:company/password-reset',

    // customizeComponents: '/customize/components',
    customizeIcons: '/customize/icons',
    customizeDxfViewer: '/customize/dxf-viewer',
    customizeModelViewer: '/customize/model-viewer',
    // customizeTheme: '/customize/theme',

    appDeactivated: '/:company/deactivated',

    forbidden: '/403',
    notFound: '/404',
    forbiddenInCompany: '/:company/403',
    notFoundInCompany: '/:company/404',

    //***** Custom page for TTI (DFDEV-1903) *****
    ttiStartPage: '/tti/start-page',
} as const;

export const IQT_MODE_ROUTES = {
    widgetClient: '/:company/iqt/client',
    widgetUpload: '/:company/iqt/upload',
    widgetOrder: '/:company/iqt/order/:orderId?',
    widgetOrderSubmit: '/:company/iqt/order/:orderId/submit',
    userModels: '/:company/iqt/models',
    userOrders: '/:company/iqt/orders',
    userOrder: '/:company/iqt/orders/:orderId',
    userSettings: '/:company/iqt/settings',
} as const;

export const SHORT_IQT_MODE_ROUTES = {
    widgetOrder: '/:company/short-iqt/order/:orderId',
    userModels: '/:company/short-iqt/models',
} as const;

export const NON_PERSISTED_ROUTES_NAMES = [
    'forbidden',
    'notFound',
    // 'customizeComponents',
    'customizeDxfViewer',
    'customizeModelViewer',
    // 'customizeTheme',
    'customizeIcons',
    'viewer',
    'modelViewer',
] as const;

export type RoutesNames = keyof typeof ROUTES | keyof typeof SHORT_IQT_MODE_ROUTES | keyof typeof IQT_MODE_ROUTES;
export type RoutesPatterns =
    | ValuesType<typeof ROUTES>
    | ValuesType<typeof SHORT_IQT_MODE_ROUTES>
    | ValuesType<typeof IQT_MODE_ROUTES>;

// query parameters
export enum GetParams {
    Hash = 'hash',
    Popup = 'popup',
    Email = 'email',
    Search = 'search',
    OrderStatus = 'status',
    PaginationPage = 'page',
    ModelUserType = 'userType',
    RedirectUrl = 'redirect_url',
    Ordering = 'ordering',
}
