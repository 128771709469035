import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@react-md/button';
import { Tooltipped } from '@react-md/tooltip';
import { FADE_DURATION } from '@components/animations';
import { Icons } from '@components/icons';
import { ModelRow, ModelLabel, ModelThumb, ModelStatus } from '@modules/models';
import { getNodeIdComposer } from '@utils';
import { RootPrefix } from '@pages/widget/order/constants';
import { useModelUploadedStatus } from '../../hooks';
import { UploadedModelProps } from '../../types';

import styles from './uploaded-model.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'uploaded_model');

export const UploadedModel: React.FC<UploadedModelProps> = ({
    uuid,
    label,
    status,
    error,
    modelId,
    onDelete,
    className,
    ...rest
}) => {
    const modelStatus = useModelUploadedStatus(status, modelId!, label, styles.help);
    const isFailed = modelStatus.status === 'error';
    const isReady = modelStatus.status === 'success';
    const deleteTimer = useRef<NodeJS.Timeout>();

    useEffect(() => {
        // when the status is ready, delete uploaded model
        if (isReady && !deleteTimer.current) {
            deleteTimer.current = setTimeout(() => onDelete(), FADE_DURATION);
        }
    }, [isReady, onDelete]);

    const { t } = useTranslation();

    return (
        <ModelRow {...rest} className={cn(styles.row, className)}>
            <ModelThumb size={'small'} isRejected={isFailed} />
            <ModelLabel label={label}>
                <ModelStatus status={modelStatus.status}>{(isFailed && error) || modelStatus.label}</ModelStatus>
            </ModelLabel>

            {!isReady && (
                <Tooltipped
                    id={_id('delete', uuid)}
                    tooltip={t('buttons.delete', 'Delete')}
                    position="above"
                    lineWrap={false}
                    spacing={10}
                    disableAutoSpacing
                >
                    <Button
                        buttonType="icon"
                        theme="error"
                        // theme="clear"
                        themeType="flat"
                        className={cn('rmd-button--icon-circle', styles.delete)}
                        onClick={onDelete}
                        aria-label={t('buttons.delete', 'Delete')}
                    >
                        <Icons.Trash />
                    </Button>
                </Tooltipped>
            )}
        </ModelRow>
    );
};
