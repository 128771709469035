import cn from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Button } from '@react-md/button';
import { SidebarAskForHelp } from '@components/ask-questions';
import { FeatureContent, FeatureMarkup } from '@components/feature-content';
import { FeatureKeys as FF, FeatureOn, FeatureOff, FeatureToggle } from '@components/feature-flags';
import { TbdMessages, TbdSidebarContainer } from '@components/tbd';
import { StickyColumnWithPreloader } from '@components/sticky-column';
import { Invoice, OrderStatuses, OrderUpdateViaInvoiceData } from '@types';
import { selectAppSettings } from '@modules/app';
import {
    invoiceActions,
    createPaymentStatusPolling,
    selectInvoicePaymentStage,
    selectInvoiceWholeOrderTbd,
    selectIsInvoicePaymentLoading,
    selectIsInvoiceUpdating,
} from '@modules/invoice';
import { BrowserStorageKeys } from '@constants';
import { InvoicePurchaseOrder } from './invoice-purchase-order';
import { InvoiceSidebarActions, InvoiceGeneratePdf } from './invoice-sidebar-actions';
import { InvoiceSidebarDiscount } from './invoice-sidebar-dicsount';
import { InvoiceSidebarPricing } from './invoice-sidebar-pricing';
import { InvoiceSidebarStatus } from './invoice-sidebar-status';
import { InvoiceSidebarAmountDue } from './invoice-sidebar-amount-due';
import { InvoicePaymentManager } from '../invoice-payment-manager';
import { InvoiceModalMessage } from './invoice-modal-message';
import { InvoicePaidModal } from './invoice-paid-modal';
import { getOrderStatus, getPaymentStageState, sidebarId } from '../helpers';
import { SidebarSection } from '@components/sidebar-section';
import { RootPrefix } from '../constants';

import styles from './invoice-sidebar.module.scss';

const renderMarkup = ({ divider, key }: { divider?: boolean; key: 'markup_bottom' | 'markup_top' }) => (
    <FeatureMarkup
        contentKey={sidebarId(key)}
        wrapper={props => <SidebarSection divider={divider} span {...props} />}
    />
);

interface Props {
    invoice: Invoice;
    invoiceId: string;
    hash: string;
}

export const InvoiceSidebar = ({ invoice, invoiceId, hash }: Props) => {
    const dispatch = useAppDispatch();
    const settings = useAppSelector(selectAppSettings);
    const orderTbd = useAppSelector(selectInvoiceWholeOrderTbd);
    const paymentStage = useAppSelector(selectInvoicePaymentStage);
    const isUpdating = useAppSelector(selectIsInvoiceUpdating);
    const isInvoicePaymentLoading = useAppSelector(selectIsInvoicePaymentLoading);

    const { isWaitingForReview, isFirmOfferSent, isPoProvided, isPoPaymentDue } = getOrderStatus(invoice.order);
    const { needsInitialization, isPaymentAllowed, isPaymentProcessing, isPaymentPaid } =
        getPaymentStageState(paymentStage);

    // manage polling status
    useEffect(() => {
        if (isPaymentProcessing) {
            dispatch(createPaymentStatusPolling({ invoiceId, hash }));
        }

        return () => {
            if (isPaymentProcessing) {
                dispatch(invoiceActions.stopPaymentStatusPolling());
            }
        };
    }, [dispatch, isPaymentProcessing, invoiceId, hash]);

    const { t } = useTranslation();

    const update = useCallback(
        (data: OrderUpdateViaInvoiceData) => {
            dispatch(invoiceActions.update({ invoiceId, hash, data }));
        },
        [dispatch, invoiceId, hash],
    );

    return (
        <div className={styles.column}>
            <InvoicePaidModal
                id={sidebarId()}
                hasCrossCloseButton={false}
                hasConfirmationCloseButton={true}
                companyShortName={invoice.company_short_name}
                isVisible={isPaymentPaid}
                itemNumber={invoice.id}
                localStorageItemName={BrowserStorageKeys.SuccessPaidModalWasClosed}
                dialogContent={<InvoiceModalMessage />}
            />

            <StickyColumnWithPreloader
                stickyParentClassName={styles.column}
                loading={isUpdating || isInvoicePaymentLoading}
            >
                <div className={cn('rounded-box', styles.box)}>
                    <InvoiceSidebarStatus settings={settings} invoice={invoice} paymentStage={paymentStage} />

                    {renderMarkup({ key: 'markup_top' })}

                    {orderTbd.length ? (
                        <TbdSidebarContainer>
                            <TbdMessages list={orderTbd} />
                        </TbdSidebarContainer>
                    ) : (
                        <>
                            <InvoiceSidebarPricing order={invoice.order} taxName={settings.tax_name} />
                            <InvoiceSidebarAmountDue order={invoice.order} />
                        </>
                    )}

                    <InvoiceSidebarDiscount />

                    <InvoiceSidebarActions>
                        {!isPaymentPaid && isFirmOfferSent ? (
                            <FeatureContent
                                contentKey={sidebarId('confirm_order')}
                                fallback={t('links.confirmOrder', 'Confirm order')}
                                wrapper={props => (
                                    <Button
                                        theme="primary"
                                        themeType="contained"
                                        className={cn('rmd-button--text-large', 'w-full')}
                                        onClick={() => update({ status: OrderStatuses.Placed })}
                                        {...props}
                                    />
                                )}
                            />
                        ) : needsInitialization || isPaymentAllowed ? (
                            <InvoicePaymentManager
                                paymentMethod={settings.current_payment_method}
                                invoice={invoice}
                                invoiceId={invoiceId}
                                hash={hash}
                            />
                        ) : null}

                        <InvoiceGeneratePdf
                            settings={settings}
                            isWaitingForReview={isWaitingForReview}
                            isFirmOfferSent={isFirmOfferSent}
                            pdfUrl={invoice.pdf_url}
                        />
                    </InvoiceSidebarActions>

                    {settings.po_enabled && !isPoPaymentDue && !isWaitingForReview && !isFirmOfferSent && (
                        <InvoicePurchaseOrder
                            file={invoice.order.attached_po}
                            poNumber={invoice.order.po_number}
                            update={update}
                        />
                    )}

                    {renderMarkup({ key: 'markup_bottom' })}

                    <FeatureToggle flagKey={FF.InvoicePage.SidebarAskForHelpShown}>
                        <FeatureOn>
                            <SidebarAskForHelp rootPrefix={RootPrefix} email={settings.support_email} />
                        </FeatureOn>
                    </FeatureToggle>
                </div>
            </StickyColumnWithPreloader>
        </div>
    );
};
