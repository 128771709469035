import { useFormContext } from 'react-hook-form';
import { useAddMessage } from '@react-md/alert';
import { Checkbox, Select, TextField } from '@react-md/form';
import { FieldTypes } from '@constants';
import { JSONSubSchema, OrderForm } from '@types';
import { FormField } from '@components/form-field';
import { Alert, AlertHeading } from '@components/alert';
import { cutSelectListIfTooLong, cutSelectListIfTooLongOnKeydown } from '@utils/widget/cut-select-list-if-too-long';
import { KeyboardEvent } from 'react';

interface OrderFormsDynamicFieldFactoryProps {
    field: JSONSubSchema;
}

export const OrderFormQuestionnaireFieldFactory = ({ field }: OrderFormsDynamicFieldFactoryProps) => {
    const { control } = useFormContext<OrderForm>();
    const addMessage = useAddMessage();

    switch (field?.JSONSchema?.type) {
        case FieldTypes.String: {
            const { JSONSchema, objectName } = field;
            return (
                <FormField
                    prefix="dynamic"
                    name={`custom_fields.${objectName}`}
                    component={TextField}
                    control={control}
                    label={JSONSchema.title}
                    defaultValue={JSONSchema.default}
                    widgetProps={{
                        placeholder: JSONSchema.placeholder,
                    }}
                />
            );
        }
        case FieldTypes.Number: {
            const { JSONSchema, objectName } = field;
            return (
                <FormField
                    prefix="dynamic"
                    name={`custom_fields.${objectName}`}
                    component={TextField}
                    control={control}
                    label={JSONSchema.title}
                    defaultValue={JSONSchema.default}
                    widgetProps={{
                        type: 'number',
                        placeholder: JSONSchema.placeholder,
                    }}
                />
            );
        }
        case FieldTypes.Select: {
            const { JSONSchema, objectName } = field;
            return (
                <FormField
                    prefix="dynamic"
                    name={`custom_fields.${objectName}`}
                    component={Select}
                    control={control}
                    label={JSONSchema.title}
                    defaultValue={JSONSchema.default}
                    widgetProps={{
                        options: JSONSchema.options,
                        placeholder: JSONSchema.placeholder,
                        onClick: cutSelectListIfTooLong,
                        onKeyDown: (event: KeyboardEvent<HTMLDivElement>) => cutSelectListIfTooLongOnKeydown(event.key),
                    }}
                />
            );
        }
        case FieldTypes.Checkbox: {
            const { JSONSchema, objectName } = field;
            return (
                <FormField
                    prefix="dynamic"
                    name={`custom_fields.${objectName}`}
                    component={Checkbox}
                    control={control}
                    defaultValue={JSONSchema.default || false}
                    widgetProps={{
                        label: JSONSchema.title,
                        defaultChecked: JSONSchema.default,
                        placeholder: JSONSchema.placeholder,
                        disableRipple: true,
                        disableIconOverlay: true,
                        disableProgrammaticRipple: true,
                    }}
                />
            );
        }

        default: {
            console.warn('INVALID ELEMENT IN SCHEMA');
            addMessage({
                messageId: 'schema-error',
                messagePriority: 'normal',
                className: 'rmd-toast--alert',
                children: (
                    <Alert show type="error" variant="filled">
                        <AlertHeading
                            iconType="error"
                            text="Invalid element in form schema for dynamic questionnaire"
                        />
                    </Alert>
                ),
            });
            return null;
        }
    }
};
