import React, { useState } from 'react';
import { CreatePaymentMethodAcssDebitData } from '@stripe/stripe-js';
import { useStripe } from '@stripe/react-stripe-js';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { useAddMessage } from '@react-md/alert';
import { ButtonProps } from '@react-md/button';
import { Alert, AlertHeading } from '@components/alert';
import { FeatureContent } from '@components/feature-content';
import { SubmitButton } from '@components/submit-button';
import { selectAppSettings } from '@modules/app';
import { getNodeIdComposer } from '@utils';
import { BaseStripeElementsProps } from './types';
import { RootPrefix } from './constants';

const _id = getNodeIdComposer(RootPrefix, 'acss');

type StripeElementsAcssButtonProps = ButtonProps &
    BaseStripeElementsProps & {
        clientSecret: string;
        billingDetails?: CreatePaymentMethodAcssDebitData['billing_details'];
    };

// https://stripe.com/docs/payments/acss-debit/accept-a-payment
// TODO wtf settings.company_title & settings.support_email

export function StripeElementsAcssButton({
    clientSecret,
    billingDetails,
    children,
    onError,
    onSuccess,
    id,
    ...props
}: StripeElementsAcssButtonProps) {
    const settings = useAppSelector(selectAppSettings);
    const stripe = useStripe();
    const [isSending, setIsSending] = useState(false);

    const { t } = useTranslation();
    const addMessage = useAddMessage();

    const handleClick = async () => {
        if (!stripe) {
            // Stripe.js has not yet loaded.
            return;
        }

        setIsSending(true);

        const result = await stripe.confirmAcssDebitPayment(clientSecret, {
            payment_method: {
                billing_details: billingDetails ?? {
                    name: settings.company_title,
                    email: settings.support_email,
                    // email: 'processing_mode+test_email@gmail.com',
                    // email: 'processing_mode+skip_waiting@gmail.com',
                },
            },
        });

        setIsSending(false);

        const error = result.error;
        console.log(result, 'result');

        if (error) {
            if (error.type !== 'validation_error') {
                addMessage({
                    messageId: _id('error'),
                    messagePriority: 'replace',
                    className: 'rmd-toast--alert',
                    children: (
                        <Alert show type="error" variant="filled">
                            <AlertHeading iconType="error" text={error.message} />
                        </Alert>
                    ),
                });
            }

            onError && onError(error);
        } else {
            onSuccess && onSuccess(result.paymentIntent);
        }
    };

    return (
        <SubmitButton {...props} onClick={handleClick} prefix={_id()} type="button" isSending={isSending}>
            {children || <FeatureContent contentKey={_id('submit')} fallback={t('buttons.payAcss', 'Pay with ACSS')} />}
        </SubmitButton>
    );
}

StripeElementsAcssButton.defaultProps = {
    theme: 'primary',
    themeType: 'contained',
    buttonType: 'text',
};
