import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useAppDispatch } from '@app/hooks';
import { useAddMessage } from '@react-md/alert';
import { InvalidateLevel } from '@types';
// import { Alert, AlertHeading } from '@components/alert';
import { axiosInstance, baseURL } from '@services/axios-instance';
import { appActions } from '@modules/app';

export const Interceptor: React.FC = () => {
    const dispatch = useAppDispatch();
    const addMessage = useAddMessage();

    useEffect(() => {
        // set language
        axiosInstance.interceptors.request.use(config => {
            if (i18next.language) {
                config.headers['Accept-Language'] = i18next.language;
            }
            return config;
        });

        // logout when any request returns 401, skip requests that refresh the token
        let invalidating = false;
        const ignoreInvalidatingUrls = ['/v2/obtain_anonymous_auth_token/', '/v2/obtain_auth_token/', '/v2/signup/'];

        axiosInstance.interceptors.response.use(
            response => response,
            error => {
                const originalRequest = error.config;
                const status = error.status || error.response?.status;

                if (
                    status === 401 &&
                    !invalidating &&
                    ignoreInvalidatingUrls.every(url => originalRequest.url !== baseURL + url)
                ) {
                    invalidating = true;

                    dispatch(
                        appActions.invalidateStore({
                            purge: InvalidateLevel.Full,
                            redirect: window.location.pathname,
                        }),
                    );
                }

                return Promise.reject(error);
            },
        );
    }, [addMessage, dispatch]);

    return null;
};
