import LanguageDetectorCreator, { CustomDetector, DetectorOptions } from 'i18next-browser-languagedetector';
import { AppSession } from '../app-session';

// just a copy of localStorage detector with company name key
// https://github.com/i18next/i18next-browser-languageDetector/blob/master/src/browserLookups/localStorage.js

let hasLocalStorageSupport: boolean | null = null;

const localStorageAvailable = () => {
    if (hasLocalStorageSupport !== null) return hasLocalStorageSupport;

    try {
        hasLocalStorageSupport = window && window.localStorage !== null;
        const testKey = 'i18next.translate.boo';
        window.localStorage.setItem(testKey, 'foo');
        window.localStorage.removeItem(testKey);
    } catch (e) {
        hasLocalStorageSupport = false;
    }

    return hasLocalStorageSupport;
};

const getLanguagesStorage = (options: DetectorOptions) => {
    return JSON.parse(window.localStorage.getItem(options.lookupLocalStorage!) || '{}');
};

let localStorageDetector: CustomDetector = {
    name: 'localStorage',
    lookup: function lookup(options) {
        let found;

        if (options.lookupLocalStorage && localStorageAvailable()) {
            const storage = getLanguagesStorage(options);
            const { allowPersist, companyName } = AppSession;
            const lng = allowPersist && companyName ? storage[companyName] : undefined;

            if (lng) found = lng;
        }

        return found;
    },
    cacheUserLanguage: function cacheUserLanguage(lng, options) {
        if (options.lookupLocalStorage && localStorageAvailable()) {
            const storage = getLanguagesStorage(options);
            const { allowPersist, companyName } = AppSession;

            if (!allowPersist || !companyName) return;

            storage[companyName] = lng;
            window.localStorage.setItem(options.lookupLocalStorage, JSON.stringify(storage));
        }
    },
};

export const createLanguageDetector = (storageKey: string) => {
    const languageDetector = new LanguageDetectorCreator(null, {
        lookupLocalStorage: storageKey,
    });

    Object.defineProperty(languageDetector.detectors, 'localStorage', {
        set: function (value) {
            // console.log(value, 'detector set');
        },
        get: function () {
            return localStorageDetector;
        },
    });

    return languageDetector;
};
