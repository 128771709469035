import { from, of } from 'rxjs';
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { getPaginationRequestParams } from '@components/pagination';
import { AppEpic, LoadModelsParams, LoadModelsIqtParams } from '@types';
import { ModelsFilter } from '@pages/user/models/types';
import { ModelsService } from '@services';
import { UserObjectModelModel } from '@models';
import { selectAuthorizedUserId } from '../app';
import { selectClientId } from '../client';
import { selectIqtModeOn } from '../user';
import { modelsPageActions } from './slice';

function getParams<P extends LoadModelsParams = LoadModelsParams>(filters: ModelsFilter): P {
    const params: LoadModelsParams = {
        ...getPaginationRequestParams(filters.page),
        fields: UserObjectModelModel.usedFields.join(','),
        parent_is_null: true,
        ordering: '-date_created',
    };

    if (filters.search) {
        params.search = filters.search;
    }

    return params as P;
}

const loadModelsListEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(modelsPageActions.load.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const iqtModeOn = selectIqtModeOn(state);
            const authorizedUserId = selectAuthorizedUserId(state);
            const clientId = selectClientId(state);
            const filters = action.payload;

            // build params
            let params;

            if (iqtModeOn) {
                params = getParams<LoadModelsIqtParams>(filters);

                if (filters.userType) {
                    let userId = null;

                    switch (filters.userType) {
                        case 'client': {
                            userId = clientId;
                            break;
                        }
                        case 'owner': {
                            userId = authorizedUserId;
                            break;
                        }
                    }

                    if (userId) {
                        params.user_id__in = String(userId);
                    }
                }
            } else {
                params = getParams(filters);
            }

            return from(ModelsService.init().loadModels(params, iqtModeOn)).pipe(
                switchMap(({ data }) => of(modelsPageActions.loadSuccess(data))),
                catchError(() => of(modelsPageActions.loadFailure())),
            );
        }),
    );

export const modelsPageEpics = combineEpics(loadModelsListEpic);
