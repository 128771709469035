import { from, of, EMPTY } from 'rxjs';
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { AppEpic } from '@types';
import { OrderService } from '@services';
import { selectOrderId, orderActions } from '../order';
import { priceCorrectionsActions } from './slice';

export const addPriceCorrectionEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(priceCorrectionsActions.addPriceCorrection.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const orderId = selectOrderId(state);
            if (!orderId) return EMPTY;

            return from(OrderService.init().addPriceCorrection(orderId, action.payload)).pipe(
                switchMap(({ data }) => {
                    return of(
                        priceCorrectionsActions.addPriceCorrectionSuccess(data),
                        orderActions.load({
                            orderId,
                        }),
                    );
                }),
                catchError(() => of(priceCorrectionsActions.addPriceCorrectionFailure())),
            );
        }),
    );

export const deletePriceCorrectionEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(priceCorrectionsActions.deletePriceCorrection.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const entityId = action.payload;
            const orderId = selectOrderId(state);
            if (!orderId) return EMPTY;

            return from(OrderService.init().deletePriceCorrection(orderId, entityId)).pipe(
                switchMap(() => {
                    return of(
                        priceCorrectionsActions.deletePriceCorrectionSuccess(entityId),
                        orderActions.load({
                            orderId,
                        }),
                    );
                }),
                catchError(() => of(priceCorrectionsActions.deletePriceCorrectionFailure())),
            );
        }),
    );

export const getPriceCorrectionsEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(priceCorrectionsActions.getPriceCorrections.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const orderId = selectOrderId(state);
            if (!orderId) return EMPTY;

            return from(OrderService.init().getPriceCorrections(orderId)).pipe(
                switchMap(({ data }) => {
                    return of(priceCorrectionsActions.getPriceCorrectionsSuccess(data.results));
                }),
                catchError(() => of(priceCorrectionsActions.getPriceCorrectionsFailure())),
            );
        }),
    );

export const priceCorrectionsEpics = combineEpics(
    addPriceCorrectionEpic,
    deletePriceCorrectionEpic,
    getPriceCorrectionsEpic,
);
