import React from 'react';
import { Alert, AlertProps, AlertHeading } from '@components/alert';

interface DropzoneAlertProps {
    type: AlertProps['type'];
    variant: AlertProps['variant'];
}

export const DropzoneAlert: React.FC<DropzoneAlertProps> = ({ type, variant, children }) => {
    return (
        <Alert show type={type} variant={variant}>
            <AlertHeading iconType={type} text={children} />
        </Alert>
    );
};
