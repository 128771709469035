import cn from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { useQueryParams, createEnumParam, NumberParam, StringParam, withDefault } from 'use-query-params';
import { Select } from '@react-md/form';
import { BELOW_RIGHT_ANCHOR } from '@react-md/utils';
import { Icons } from '@components/icons';
import { SearchField } from '@components/search-field';
import { modelsPageActions } from '@modules/models-page';
import { selectIqtModeOn } from '@modules/user';
import { getNodeIdComposer } from '@utils';
import { GetParams } from '@constants';
import { useFilterModelsByUserLabel } from './hooks';
import { FilterModelsByUserValues } from './types';
import { FiltersId } from './constants';
import { cutSelectListIfTooLong, cutSelectListIfTooLongOnKeydown } from '@utils/widget/cut-select-list-if-too-long';

import styles from './models-page-filters.module.scss';

const _id = getNodeIdComposer(FiltersId);

const OrderStatusParam = createEnumParam(Object.values(FilterModelsByUserValues));

export const ModelPageFilters = () => {
    const dispatch = useAppDispatch();
    const iqtModeOn = useAppSelector(selectIqtModeOn);

    const [query, setQuery] = useQueryParams({
        [GetParams.PaginationPage]: withDefault(NumberParam, 1),
        [GetParams.Search]: withDefault(StringParam, ''),
        [GetParams.ModelUserType]: withDefault(OrderStatusParam, FilterModelsByUserValues.All),
    });

    useEffect(() => {
        dispatch(modelsPageActions.load(query));
    }, [dispatch, query]);

    // user type
    const filterByUser = query.userType;
    const handleChangeFilterByUser = useCallback(
        userType => {
            setQuery({
                userType,
                page: 1,
            });
        },
        [setQuery],
    );

    // search
    const handleFinalizeSearch = useCallback(
        search => {
            setQuery({
                search,
                page: 1,
            });
        },
        [setQuery],
    );

    const { t } = useTranslation();
    const filterByUserLabels = useFilterModelsByUserLabel(t);

    return (
        <div className={styles.box}>
            {iqtModeOn && (
                <Select
                    id={_id('user', 'field')}
                    className={cn('rmd-select--as-button', 'rmd-typography--theme-primary', styles.dropdown)}
                    onChange={handleChangeFilterByUser}
                    options={Object.values(FilterModelsByUserValues).map(value => ({
                        label: filterByUserLabels[value],
                        value,
                    }))}
                    value={filterByUser}
                    leftChildren={<Icons.Sliders />}
                    rightChildren={null}
                    // anchor={BELOW_RIGHT_ANCHOR}
                    listboxClassName={styles.listbox}
                    listboxWidth="min"
                    closeOnResize
                    closeOnScroll
                    onClick={cutSelectListIfTooLong}
                    onKeyDown={event => cutSelectListIfTooLongOnKeydown(event.key)}
                />
            )}

            <SearchField id={_id('search', 'field')} value={query.search} onFinalize={handleFinalizeSearch} />
        </div>
    );
};
