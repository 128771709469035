// @ts-nocheck (to ignore typechecking on validation function parameters)
export const yupLocale = {
    mixed: {
        default: {
            key: 'errors.required',
        },
        required: {
            key: 'errors.required',
        },
        email: {
            key: 'errors.invalidEmail',
        },
        min: ({ min }) => ({
            key: 'errors.minLength',
            values: { min },
        }),
        max: ({ max }) => ({
            key: 'errors.maxLength',
            values: { max },
        }),
        notType: ({ type }) => ({
            key: 'errors.invalidType',
            values: { type },
        }),
    },
    string: {
        default: {
            key: 'errors.required',
        },
        required: {
            key: 'errors.required',
        },
        email: {
            key: 'errors.invalidEmail',
        },
        min: ({ min }) => ({
            key: 'errors.minLength',
            values: { min },
        }),
        max: ({ max }) => ({
            key: 'errors.maxLength',
            values: { max },
        }),
    },
    number: {},
    boolean: {},
};
