import cn from 'classnames';
import React, { HTMLAttributes, forwardRef } from 'react';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Button, ButtonThemeProps } from '@react-md/button';
import { TextIconSpacing } from '@react-md/icon';
import { Icons } from '@components/icons';
import { ButtonLink } from '@components/link';
import { InitialOrder } from '@types';
import { reverse } from '@utils';
import { useBuildLocation } from '@hooks';
import { selectOrderData } from '@modules/order';
import { selectProductIsRemoving } from '@modules/product';
import { FeatureContent } from '@components/feature-content';
import { AppSession } from '@utils';
import { exposedApi } from '@expose';
import { cartSectionId } from '../helpers';
import { TotalPricing } from './total-pricing';

import styles from './total.module.scss';

interface QuoteSubmitLinkProps extends ButtonThemeProps {
    orderId?: number;
}

const QuoteSubmitLink = ({ disabled, orderId, ...props }: QuoteSubmitLinkProps) => {
    const orderLocation = useBuildLocation({
        to: reverse('widgetOrderSubmit', { orderId }),
    });

    const { t } = useTranslation();

    return (
        <ButtonLink
            {...props}
            id={cartSectionId('add_order_details')}
            className={cn(props.className)}
            disabled={disabled}
            {...orderLocation}
        >
            <TextIconSpacing icon={<Icons.Chevron />} iconAfter forceIconWrap>
                <FeatureContent
                    contentKey={cartSectionId('add_order_details')}
                    fallback={t('links.orderDetails', 'Add order details')}
                />
            </TextIconSpacing>
        </ButtonLink>
    );
};

interface TotalNextActionProps {
    orderIsEmpty: boolean;
    order: Partial<InitialOrder>;
}

const TotalNextAction = ({ orderIsEmpty, order }: TotalNextActionProps) => {
    const productIsRemoving = useAppSelector(selectProductIsRemoving);
    const { t } = useTranslation();

    const buttonBaseProps: ButtonThemeProps = {
        theme: 'primary',
        themeType: 'contained',
        className: cn('rmd-button--text-large', styles.link),
    };

    return AppSession.shortIqtModeOn ? (
        <FeatureContent
            contentKey={cartSectionId('save_order')}
            fallback={t('buttons.saveOrder', 'Save order')}
            wrapper={props => <Button {...buttonBaseProps} onClick={exposedApi.saveOrder} {...props} />}
        />
    ) : (
        <QuoteSubmitLink
            {...buttonBaseProps}
            disabled={orderIsEmpty || (productIsRemoving && order.products?.length === 1)}
            orderId={order.id ?? 0}
        />
    );
};

interface TotalHandlerProps extends HTMLAttributes<HTMLDivElement> {}

export const TotalHandler = forwardRef<HTMLDivElement, TotalHandlerProps>(function TotalHandler(
    { children, className, ...props },
    ref,
) {
    const order = useAppSelector(selectOrderData) || ({ products: [] } as Partial<InitialOrder>);
    const hasProducts = Boolean(order.products?.length);
    const orderIsEmpty = !hasProducts;

    return (
        <div className={cn(styles.box, className)} ref={ref} {...props}>
            <TotalPricing order={order} orderIsEmpty={orderIsEmpty} hasProducts={hasProducts} />

            {children}

            <TotalNextAction order={order} orderIsEmpty={orderIsEmpty} />
        </div>
    );
});
