/*
    Expose the entire library globally using 'expose-loader'
    useful when we need to dynamically load scripts from 'custom_widget_head_html', e.g.

    <script>
        document.addEventListener('DOMContentLoaded', function () {
            window.DynamicAssets.importScript('https://js.recurly.com/v4/recurly.js');
            !window.AppSession.intentionToOpenIqt && window.DynamicAssets.importScript('...');
        });
    </script>
 */

import 'assets-dynamic-import';

import React from 'react';
import ReactDOM from 'react-dom';
import { sentrySetup } from '@utils/sentry';
import reportWebVitals from './reportWebVitals';

// import order of styles and components with styles is important https://stackoverflow.com/a/54675125
import 'react-responsive-modal/styles.css';
import './assets/css/root.scss';

import App from './App'; // css modules

import './assets/css/utilities/index.scss';

async function enableMocking() {
    return;
    // if (process.env.NODE_ENV !== 'development') {
    //     return;
    // }
    //
    // const { worker } = await import('./mocks/browser');
    //
    // // `worker.start()` returns a Promise that resolves
    // // once the Service Worker is up and ready to intercept requests.
    // return worker.start();
}

enableMocking().then(() => {
    sentrySetup();

    ReactDOM.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        document.getElementById('root'),
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
});
