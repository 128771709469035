import React, { SVGProps } from 'react';
import cn from 'classnames';
import { bem } from '@react-md/utils';
const block = bem('rmd-icon');
const SvgUserCheck = (props: SVGProps<SVGSVGElement>) => {
    props = Object.assign(
        {
            'aria-hidden': true,
        },
        props,
        {
            className: cn(block(), props.className),
        },
    );
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="none"
            viewBox="0 0 16 16"
            role="img"
            {...props}
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M10.667 14v-1.333A2.667 2.667 0 0 0 8 10H3.333a2.667 2.667 0 0 0-2.666 2.667V14M5.667 7.333a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333ZM11.333 7.333l1.334 1.334L15.333 6"
            />
        </svg>
    );
};
export default SvgUserCheck;
