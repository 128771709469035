import cn from 'classnames';
import React, { forwardRef, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { FeatureContent } from '@components/feature-content';
import { deliveryId } from './helpers';

import styles from './order-pickup-tab.module.scss';

export default forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(function OrderPickupTab(
    { children, className, ...props },
    ref,
) {
    const { t } = useTranslation();

    return (
        <div {...props} className={cn('margin-top', styles.box, className)} ref={ref}>
            <Typography id={deliveryId('pickup_title')} type="headline-5" component="div">
                <FeatureContent
                    contentKey={deliveryId('pickup_title')}
                    fallback={t('subtitles.pickupAddress', 'Pickup address')}
                />
            </Typography>
            <Typography
                id={deliveryId('pickup_address')}
                type="body-1"
                className={cn('small-margin-top', styles.address)}
            >
                {children}
            </Typography>
        </div>
    );
});
