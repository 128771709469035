import * as yup from 'yup';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { FallbackLng } from '@constants';
import { createLanguageDetector } from './create-language-detector';
import { yupLocale } from './yup-locale';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

console.warn(process.env, process.env.NODE_ENV);

const localStorageKey = 'i18nextDFLng';
const loadPath = '/locales/{{lng}}/{{ns}}.json';

export function i18nSetup() {
    if (i18n.isInitialized) return;

    yup.setLocale(yupLocale);

    i18n
        // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
        // learn more: https://github.com/i18next/i18next-http-backend
        .use(Backend)
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(createLanguageDetector(localStorageKey))
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            fallbackLng: FallbackLng,
            debug: false,
            // debug: true,
            keySeparator: '.',
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            backend: {
                loadPath,
            },
        });
}
