import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { PricingList, PricingRecord, PricingRecordProps } from '@components/pricing-list';
import { SidebarSection } from '@components/sidebar-section';
import { AppSettings, Order } from '@types';
import { decimalToBoolean } from '@utils';

const TotalPricingRecord = ({ keyType = 'body-2', valueType = 'body-2', ...rest }: PricingRecordProps) => (
    <PricingRecord keyType={keyType} valueType={valueType} {...rest} />
);

interface Props {
    order: Order;
    taxName: AppSettings['tax_name'];
}

export const InvoiceSidebarPricing: React.FC<Props> = ({ order, taxName }) => {
    const { t } = useTranslation();
    return (
        <>
            <SidebarSection>
                <Typography type="headline-4" component="div">
                    {t('labels.total', 'Total')}
                </Typography>
            </SidebarSection>

            <SidebarSection span>
                <PricingList divider>
                    <TotalPricingRecord
                        label={t('labels.modelsPrice', 'Models price')}
                        value={order.models_naked_price}
                    />

                    {!!order.startup_cost && (
                        <TotalPricingRecord
                            label={t('labels.startupCost', 'Startup cost')}
                            value={order.startup_cost}
                        />
                    )}

                    {!!order.delivery_price && (
                        <TotalPricingRecord label={t('titles.delivery', 'Delivery')} value={order.delivery_price} />
                    )}

                    {decimalToBoolean(order.price_corrections_cost) && (
                        <TotalPricingRecord
                            label={t('subtitles.priceCorrections', 'Additional goods and services')}
                            value={order.price_corrections_cost}
                            sign={parseInt(order.price_corrections_cost) > 0 ? '+' : undefined}
                        />
                    )}

                    {!!order.total_discount_value && (
                        <TotalPricingRecord
                            label={t('labels.discount', 'Discount')}
                            percent={
                                order.products_discount_value ? undefined : Number(order.order_discount_in_percents)
                            }
                            value={order.total_discount_value}
                            sign="-"
                        />
                    )}

                    <TotalPricingRecord
                        label={t('labels.subtotal', 'Subtotal')}
                        value={order.total_price_without_tax}
                    />

                    {!!order.tax_value && (
                        <TotalPricingRecord
                            label={taxName}
                            value={order.tax_value}
                            percent={parseFloat(order.tax_in_percents)}
                        />
                    )}
                </PricingList>
                <PricingList divider>
                    <PricingRecord
                        label={t('labels.total', 'Total')}
                        value={order.total_price}
                        keyType="headline-6"
                        valueType="headline-6"
                    />
                </PricingList>
            </SidebarSection>
        </>
    );
};
