import pick from 'lodash/pick';
import { Order, TBD } from '@types';

export const getOrderTbd = (order?: Order, iqtModeOn = false) => {
    if (!order || !order.tbd_reason || iqtModeOn) {
        return;
    }

    return pick(order, ['tbd_reason', 'tbd_reason_message']);
};

// todo cart.selectors, order-page.selectors, invoice.selectors
export const getOrderLineItemsTbd = () => {};

export const mergeTbd = () => {};
