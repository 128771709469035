import React from 'react';
import { useAppSelector } from '@app/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { AuthPopup } from '@types';
import { selectAppSettings } from '@modules/app';
import { AuthFormFooter, AuthFormFooterMessage, AuthFormLink } from '../components';
import { AuthFooterProps } from '../types';

export const SignupFormFooter: React.FC<AuthFooterProps> = ({ type, children }) => {
    const { enabled_magic_links } = useAppSelector(selectAppSettings);
    const { t } = useTranslation();

    return (
        <AuthFormFooter className={enabled_magic_links ? 'flex-col' : undefined}>
            <AuthFormFooterMessage>
                <Trans
                    i18nKey="messages.signInLink"
                    components={{
                        ln: <AuthFormLink auth={AuthPopup.SignIn} type={type} />,
                    }}
                    defaults={'Already registered? <ln>Sign In</ln>'}
                />
            </AuthFormFooterMessage>

            {enabled_magic_links && (
                <AuthFormFooterMessage className="micro-margin-top">
                    <Trans
                        i18nKey="messages.otpLink"
                        components={{
                            ln: <AuthFormLink auth={AuthPopup.OTP} type={type} />,
                        }}
                        defaults={'Or sign in <ln>with a code</ln>'}
                    />
                </AuthFormFooterMessage>
            )}

            {children}
        </AuthFormFooter>
    );
};
