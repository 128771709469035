import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import React from 'react';
import cn from 'classnames';
import { FieldErrors } from 'react-hook-form';
import { Typography } from '@react-md/typography';
import { StoreResponseErrors } from '@types';
import { NonFieldErrorsKey } from './constants';

import styles from './form-field.module.scss';

interface NonFieldErrorsProps {
    errors?: FieldErrors | StoreResponseErrors;
    className?: string;
}

export const NonFieldErrors: React.FC<NonFieldErrorsProps> = ({ errors, className }) => {
    if (!errors || isEmpty(errors) || (!isString(errors) && !errors[NonFieldErrorsKey])) return null;

    return (
        <Typography
            type="headline-6"
            component="div"
            className={cn('fade-in', 'rmd-typography--theme-error', styles.nonFieldErrors, className)}
        >
            {isString(errors)
                ? errors
                : isString(errors[NonFieldErrorsKey])
                ? errors[NonFieldErrorsKey]
                : errors[NonFieldErrorsKey]?.message}
        </Typography>
    );
};
