import { repeatOrder } from './thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Order, StoreResponseErrors } from '@types';

interface State {
    isLoading: boolean;
    order?: Order;
}

const initialState: State = {
    isLoading: false,
};

export const orderPageSlice = createSlice({
    name: 'orderPage',
    initialState,
    reducers: {
        load: (state, action: PayloadAction<string>) => {
            state.isLoading = true;
        },
        loadSuccess: (state, action: PayloadAction<Order>) => {
            state.isLoading = false;
            state.order = action.payload;
        },
        loadFailure: (state, action: PayloadAction<StoreResponseErrors>) => {
            state.isLoading = false;
        },
    },
    extraReducers: builder => {
        builder.addCase(repeatOrder.pending, (state, action: PayloadAction<undefined>) => {
            state.isLoading = true;
        });
        builder.addCase(repeatOrder.fulfilled, (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        });
        builder.addCase(repeatOrder.rejected, (state, action: PayloadAction<unknown>) => {
            state.isLoading = false;
        });
    },
});

export const orderPageActions = orderPageSlice.actions;
