// https://github.com/getsentry/sentry-javascript/issues/7275
// https://gist.github.com/jeengbe/4bc86f05a41a1831e6abf2369579cc7a

import type { Breadcrumb, ErrorEvent, EventHint } from '@sentry/types';
// import type { BrowserOptions } from '@sentry/browser';

// const client = new BrowserClient({
//     beforeSend: (error, hint) => {
//         return shouldIgnoreError(error, hint) ? null : error;
//     },
// });

export function shouldIgnoreError(error: ErrorEvent, hint: EventHint): boolean {
    return isRecaptchaBadTimeoutRejection(error, hint) || isNetworkError(error);
}

// https://github.com/getsentry/sentry-javascript/issues/2514
function isRecaptchaBadTimeoutRejection(_: ErrorEvent, hint: EventHint): boolean {
    return hint.originalException === 'Timeout';
}

// https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
// https://stackoverflow.com/questions/71280168/javascript-typeerror-load-failed-error-when-calling-fetch-on-ios
// ignore iOS errors
// https://digifabster.sentry.io/issues/3876412062/events/?project=4504163515891712&referrer=project-issue-stream
// ignore disconnection errors
// https://digifabster.sentry.io/issues/3870303356/events/?project=4504163515891712&referrer=release-issue-stream
const typeErrorFetchFailedValues = new Set([
    'Failed to fetch',
    'NetworkError when attempting to fetch resource.',
    'Load failed',
    'network error',
    'cancelled',
]);

function isNetworkError(error: ErrorEvent): boolean {
    const exception = error.exception?.values?.[0];
    const now = Date.now();

    if (exception?.type !== 'TypeError' || !typeErrorFetchFailedValues.has(exception.value as string)) {
        return false;
    }

    return true;
    // if you want to check a specific URL, uncomment the code below and fill in isErroneousBreadcrumb with urls
    // if (!error.breadcrumbs) {
    //     return false;
    // }
    //
    // // We go from the back since the last breadcrumb is most likely the erroneous one
    // for (let i = error.breadcrumbs.length - 1; i >= 0; i--) {
    //     const breadcrumb = error.breadcrumbs[i];
    //     if (!breadcrumb) continue;
    //
    //     // We only need to check the last 5s of breadcrumbs as any earlier breadcrumbs are definitely unrelated
    //     if (breadcrumb.timestamp && now - breadcrumb.timestamp * 1000 > 5000) {
    //         break;
    //     }
    //
    //     if (isErroneousBreadcrumb(breadcrumb)) {
    //         return true;
    //     }
    // }
    //
    // return false;
}

// function isErroneousBreadcrumb(breadcrumb: Breadcrumb): boolean {
//     if (breadcrumb.level !== 'error' || (breadcrumb.category !== 'xhr' && breadcrumb.category !== 'fetch')) {
//         return false;
//     }
//
//     const url = breadcrumb.data?.url as string | undefined;
//     if (!url) return false;
//
//     return url === 'urlThatIsOftenBlocked' || url.startsWith('startOfUrlThatIsOftenBlocked');
// }
