import type { Permissions } from '@modules/user';

export enum AuthPopup {
    OTP = 'otp',
    SignIn = 'sign-in',
    SignUp = 'sign-up',
}

export enum OrderPopup {
    Notification = 'notification',
}

export type PopupType = AuthPopup | OrderPopup;

export interface PopupProps {
    isOpened: boolean;
    isClosable: boolean;
}

export interface PopupDeps {
    isAuthenticated: boolean;
    isAuthRequired: boolean;
    permissions: Permissions;
}

export interface PopupStatus {
    isOpened: boolean;
    isClosable: boolean;
}

export interface PopupOpenPayload {
    type: PopupType;
    isClosable?: boolean;
    onClose?: (args: any) => void;
}
