import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useAppSelector } from '@app/hooks';
import { useWidgetMaterial, useWidgetPriceConfig } from '@pages/widget/order/hooks';
import { Fade, FADE_APPEAR_DURATION } from '@components/animations';
import { selectPreselectionByModelId } from '@modules/preselection';
import { selectCurrentProduct, selectRepeatSpecsModeOn, selectPreselectionModeOn } from '@modules/quotation';
import { SpecificationFields } from './specification-fields';
import { SpecificationMaterial } from './specification-material';
import { useSpecificationForm } from './hooks';
import { SpecificationFormProps } from './types';

import styles from './specification-form.module.scss';

export const SpecificationForm: React.FC<SpecificationFormProps> = ({ model, children }) => {
    const repeatSpecsModeOn = useAppSelector(selectRepeatSpecsModeOn);
    const preselectionModeOn = useAppSelector(selectPreselectionModeOn);
    const currentProduct = useAppSelector(selectCurrentProduct);
    const { widgetMaterial: material, setWidgetMaterial } = useWidgetMaterial();
    const parentModelId = model.parent_model || model.id;
    const preselectedModelThickness = useAppSelector(state => selectPreselectionByModelId(state, parentModelId))?.config
        ?.thickness;
    const { widgetPriceConfig: config, setWidgetPriceConfig } = useWidgetPriceConfig();
    const { defaultValues, formParams } = useSpecificationForm({
        model,
        material,
        initialThickness: preselectedModelThickness,
    })!;

    // set initial price config for model or product
    useEffect(() => {
        if (currentProduct || preselectionModeOn) {
            if (!config && !isEmpty(defaultValues)) {
                setWidgetPriceConfig(defaultValues, {
                    requestPrice: true,
                });
            }
            return;
        }

        if (repeatSpecsModeOn) {
            setWidgetPriceConfig(defaultValues, {
                requestPrice: true,
                mergeRepeatSpecs: true,
            });
            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [material?.id, parentModelId, currentProduct]);

    return (
        <Fade in timeout={FADE_APPEAR_DURATION} className="margin-top">
            <div className={cn('emulated-flex-gap', styles.box)}>
                <SpecificationMaterial material={material} setWidgetMaterial={setWidgetMaterial} />

                {children}

                {material && config && (
                    <SpecificationFields
                        formParams={formParams}
                        formInstance={config || defaultValues}
                        setWidgetPriceConfig={setWidgetPriceConfig}
                    />
                )}
            </div>
        </Fade>
    );
};
