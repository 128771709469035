import React from 'react';
import cn from 'classnames';
import { Icons } from '@components/icons';

export interface AlertIconProps extends React.SVGProps<SVGSVGElement> {
    className?: string;
    type?: 'error' | 'warning';
    fadeIn?: boolean;
}

export const AlertIcon: React.FC<AlertIconProps> = ({ className, type = 'error', fadeIn = true, ...props }) => {
    return (
        <Icons.Warning className={cn(`rmd-typography--theme-${type}`, { 'fade-in': fadeIn }, className)} {...props} />
    );
};
