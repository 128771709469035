import cn from 'classnames';
import * as yup from 'yup';
import { KeyboardEvent, SyntheticEvent, createElement, useEffect } from 'react';
import { FeatureContent } from '../feature-content';
import { Typography } from '@react-md/typography';
import { billingId } from '@pages/widget/order-submit/order-forms/helpers';
import { Chip } from '@react-md/chip';
import { TextField } from '@react-md/form';
import { Icons } from '../icons';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { orderActions, selectAdditionalContactsList } from '@modules/order';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getErrorMessage } from '../form-field';

import styles from './additional-contacts.module.scss';

// todo full refactoring
export function AdditionalContacts() {
    const dispatch = useAppDispatch();
    const additionalContactsList = useAppSelector(selectAdditionalContactsList);
    const { t } = useTranslation();

    const validationSchema = yup.object().shape({
        additional_contact: yup
            .string()
            .trim()
            .email()
            .notOneOf(additionalContactsList, { key: 'errors.emailDublicates' }),
    });

    const {
        control,
        setValue,
        handleSubmit,
        reset,
        getFieldState: errors,
        watch,
    } = useForm({ resolver: yupResolver(validationSchema) });

    useEffect(() => {
        dispatch(orderActions.setAdditionalContactsFormAttributes({ errors, watch }));
    }, [errors, watch, dispatch]);

    function handleRemoveContact(event: SyntheticEvent) {
        const removingEmail = event.currentTarget.getAttribute('data-email');
        const newContactsList = additionalContactsList.filter(email => email !== removingEmail);
        dispatch(orderActions.setAdditionalContactsList(newContactsList));
    }

    function onSubmit(data: { [key: string]: string }) {
        const input = Object.keys(data)[0];
        const newContact = Object.values(data)[0];
        if (newContact) {
            dispatch(orderActions.setAdditionalContactsList([...additionalContactsList, newContact]));
            setValue(input, '');
        }
    }

    function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
        const code = event.code;
        if (code === 'Space' || code === 'Enter' || code === 'NumpadEnter' || code === 'Comma') {
            event.preventDefault();
            handleSubmit(onSubmit)();
        }
        if (code === 'Backspace' && !event.currentTarget.value) {
            const newContactsList = [...additionalContactsList];
            newContactsList.pop();
            dispatch(orderActions.setAdditionalContactsList(newContactsList));
            reset();
        }
    }

    return (
        <Controller
            control={control}
            name={'additional_contact'}
            // defaultValue={defaultValue}
            render={({
                field: { onChange: handleChange, onBlur: handleBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState: { isSubmitted },
            }) => {
                const invalidField = (isTouched || isSubmitted) && (invalid || Boolean(error));

                let title: string = value?.toString();

                const field = createElement<any>(TextField, {
                    className: styles['email_input'],
                    id: billingId('additional_contacts_input'),
                    name,
                    value,
                    title,
                    error: invalidField,
                    theme: 'none',
                    placeholder: 'Enter an email...',
                    onChange: (event: SyntheticEvent) => {
                        handleChange(event);
                    },
                    onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => {
                        handleKeyDown(event);
                    },
                    onBlur: () => {
                        handleBlur();
                    },
                    ref,
                });

                return (
                    <div className={cn('margin-top', styles['additional_contacts-wrapper'])}>
                        <Typography
                            className="micro-margin-bottom"
                            id={billingId('additional_contacts_title')}
                            type="headline-4"
                            component="div"
                        >
                            <FeatureContent
                                contentKey={billingId('additional_contacts_title')}
                                fallback={t('labels.additionalContacts', 'Additional contacts')}
                            />
                        </Typography>

                        <Typography
                            className="margin-bottom"
                            id={billingId('additional_contacts_subtitle')}
                            type="body-1"
                            color="hint"
                            component="div"
                        >
                            <FeatureContent
                                contentKey={billingId('additional_contacts_subtitle')}
                                fallback={t(
                                    'subtitles.additionalContacts',
                                    'List the email addresses you’d like to send notifications to',
                                )}
                            />
                        </Typography>

                        <div
                            className={cn(styles['additional_contacts-container'], {
                                [styles['additional_contacts-container_error']]: invalidField,
                            })}
                        >
                            {additionalContactsList.map((email, index) => {
                                return (
                                    <Chip
                                        key={`${email}-${additionalContactsList.length}-${index}`}
                                        className={styles['email_chip']}
                                        title={email}
                                        noninteractable
                                        rightIcon={
                                            <Icons.Cross
                                                className={styles['remove_contact-button']}
                                                width="12px"
                                                data-email={email}
                                                onClick={handleRemoveContact}
                                            />
                                        }
                                    >
                                        {email}
                                    </Chip>
                                );
                            })}
                            {field}
                        </div>

                        {invalidField && (
                            <Typography
                                type="headline-6"
                                component="div"
                                className={cn('fade-in', 'rmd-typography--theme-error', 'micro-margin-top')}
                            >
                                {getErrorMessage(t, error?.message)}
                            </Typography>
                        )}
                    </div>
                );
            }}
        />
    );
}
