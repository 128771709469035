import React, { DispatchWithoutAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@react-md/button';
import { Tooltipped } from '@react-md/tooltip';
import { Icons } from '@components/icons';
import { FeatureOn, FeatureToggle, FeatureKeys as FF, useFeatureFlag } from '@components/feature-flags';
import { getNodeIdComposer } from '@utils';
import { useAppSelector } from '@app/hooks';
import { selectIqtModeOn } from '@modules/user';
import { ViewerInnerPanel } from './viewer-inner-panel';
import { ViewerPrefix } from './constants';

import styles from './viewer-mode-panel.module.scss';

const _id = getNodeIdComposer(ViewerPrefix, 'mode_panel');

export interface ViewerModePanelProps {
    expanded: boolean;
    toggleFullscreenMode: DispatchWithoutAction;
    toggleThumbMode: DispatchWithoutAction;
    enterRotationMode?: DispatchWithoutAction;
    isRotationActive?: boolean;
}

export const ViewerModePanel: React.FC<ViewerModePanelProps> = ({
    expanded,
    toggleFullscreenMode,
    toggleThumbMode,
    enterRotationMode,
    isRotationActive,
}) => {
    const { t } = useTranslation();
    const isIqtMode = useAppSelector(selectIqtModeOn);
    const rotationFeatureOn = useFeatureFlag(FF.CartPage.RotationModeShown);

    return (
        <ViewerInnerPanel className={styles.box}>
            {(isIqtMode || rotationFeatureOn) && !isRotationActive && (
                <Tooltipped
                    id={_id('toggle_rotate')}
                    tooltip="Rotate mode"
                    position="above"
                    lineWrap={false}
                    spacing={10}
                    vwMargin={20}
                    disableAutoSpacing
                >
                    <Button
                        onClick={enterRotationMode}
                        buttonType="icon"
                        theme="clear"
                        themeType="outline"
                        aria-label="Rotate mode"
                    >
                        <Icons.Rotate width={16} height={16} />
                    </Button>
                </Tooltipped>
            )}

            {!isRotationActive && (
                <FeatureToggle flagKey={FF.Viewer.RenderingBtnShown}>
                    <FeatureOn>
                        <Tooltipped
                            id={_id('toggle_thumb')}
                            tooltip={t('tooltips.toggleRenderMode', 'Toggle render mode')}
                            position="above"
                            lineWrap={false}
                            spacing={10}
                            vwMargin={20}
                            disableAutoSpacing
                        >
                            <Button
                                onClick={toggleThumbMode}
                                buttonType="icon"
                                theme="clear"
                                themeType="outline"
                                aria-label={t('tooltips.toggleRenderMode', 'Toggle render mode')}
                            >
                                <Icons.Faces />
                            </Button>
                        </Tooltipped>
                    </FeatureOn>
                </FeatureToggle>
            )}

            <Tooltipped
                id={_id('toggle_fullscreen')}
                tooltip={
                    expanded
                        ? t('tooltips.exitFullscreen', 'Exit full screen')
                        : t('tooltips.fullscreen', 'Full screen')
                }
                position="above"
                lineWrap={false}
                spacing={10}
                vwMargin={20}
                disableAutoSpacing
            >
                <Button
                    onClick={toggleFullscreenMode}
                    buttonType="icon"
                    theme="clear"
                    themeType="outline"
                    aria-label={
                        expanded
                            ? t('tooltips.exitFullscreen', 'Exit full screen')
                            : t('tooltips.fullscreen', 'Full screen')
                    }
                >
                    {expanded ? <Icons.Pinch /> : <Icons.Spread />}
                </Button>
            </Tooltipped>
        </ViewerInnerPanel>
    );
};
