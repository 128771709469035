import cn from 'classnames';
import React, { HTMLProps } from 'react';
import { Divider } from '@react-md/divider';
import { Typography, TypographyProps } from '@react-md/typography';

export const AuthFormFooterMessage = (props: TypographyProps) => (
    <Typography type="body-1" component="div" {...props} />
);

export const AuthFormFooter: React.FC<HTMLProps<HTMLDivElement>> = ({ children, className }) => {
    return (
        <>
            <Divider />
            <div className={cn('flex', 'justify-center', 'flex-wrap', 'base-paddings', 'no-top', className)}>
                {children}
            </div>
        </>
    );
};
