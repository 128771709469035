import { AcceptedModelFormats, FileFormats } from '@types';

export const ArchiveFormatsList = ['zip'] as const;

export const ModelExtensionsByFormat: Record<AcceptedModelFormats, string[]> = {
    stl: ['.stl'],
    obj: ['.obj'],
    wrl: ['.wrl'],
    step: ['.step', '.stp'],
    iges: ['.iges', '.igs'],
    '3mf': ['.3mf'],
    dxf: ['.dxf'],
    zip: ['.zip'],
};

export const AcceptedModelExtensions = Object.values(ModelExtensionsByFormat).flat();

export const ImageExtensionsByFormat: Record<FileFormats['images'], string[]> = {
    jpeg: ['.jpg', '.jpeg'],
    png: ['.png'],
    svg: ['.svg'],
};
export const OfficeExtensionsByFormat: Record<FileFormats['office'], string[]> = {
    ppt: ['.ppt', '.pptx'],
    xls: ['.xls', '.xlsx'],
};
export const ReaderExtensionsByFormat: Record<FileFormats['readers'], string[]> = {
    pdf: ['.pdf'],
    ai: ['.ai'],
    eps: ['.eps'],
    psd: ['.psd'],
};
export const AcceptedDrawingExtensions = [
    ImageExtensionsByFormat,
    ModelExtensionsByFormat.step,
    OfficeExtensionsByFormat,
    ReaderExtensionsByFormat,
]
    .map(extensions => Object.values(extensions).flat())
    .flat();

export const DrawingsMaxFiles = 20;
