import qs, { ParsedQs } from 'qs';
import { useLocation } from 'react-router-dom';
import { ValuesType } from '@types';

type Options = Parameters<typeof qs.parse>[1];

export type CustomParsedQs<T> = {
    [key: string]: T;
};

export function useQuery<T extends ValuesType<ParsedQs>>(options?: Options) {
    return qs.parse(useLocation().search, {
        ignoreQueryPrefix: true,
        ...options,
    }) as CustomParsedQs<T>;
}
