import cn from 'classnames';
import { useAppSelector } from '@app/hooks';
import { withBaseErrorBoundary } from '@components/error-boundaries';
import { PreloaderBox } from '@components/preloader';
import { selectIsOrdersLoading } from '@modules/orders';
import { useGetResizeRef } from '@utils/parant-site-comunicator';
import { UserOrdersHeader, UserOrdersList, OrdersPagePagination } from './components';
import { RootPrefix } from './constants';

import styles from './orders-page.module.scss';

const Page = () => {
    const isLoading = useAppSelector(selectIsOrdersLoading);
    const containerRef = useGetResizeRef();

    return (
        <div id={RootPrefix} className={styles.layout} ref={containerRef}>
            <div className="container">
                <PreloaderBox show={isLoading}>
                    <div className={cn('rounded-box', styles.box)}>
                        <UserOrdersHeader />
                        <UserOrdersList />
                    </div>
                </PreloaderBox>
            </div>

            <OrdersPagePagination />
        </div>
    );
};

export const UserOrdersPage = withBaseErrorBoundary(Page);
