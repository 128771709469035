import type { Action, UnknownAction, ThunkAction } from '@reduxjs/toolkit';
import type { Epic } from 'redux-observable';
import { store } from '@app/store';
import { rootReducer } from '@app/reducers';
import { appExtraApi } from '@app/helpers';
import type { PasswordlessLogin } from './auth';

export type RootState = ReturnType<typeof rootReducer>;
export type RootReducerKeys = Exclude<keyof RootState, 'dfApi'>;
export type AppDispatch = typeof store.dispatch;
export type AppExtraApi = typeof appExtraApi;
export type AppEpic = Epic<UnknownAction, UnknownAction, RootState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, AppExtraApi, Action>;

export type InitAppOptions = { silentLogin?: PasswordlessLogin };

export enum AppMode {
    EndUser = 'end-user',
    Iqt = 'iqt',
    ShortIqt = 'short-iqt',
}
