import cn from 'classnames';
import React from 'react';
import { Technologies } from './technologies';
import { FeatureMarkup, ContentKeys as FC } from '@components/feature-content';
import { specificationSectionId } from '../helpers';

import styles from './specification-section.module.scss';

export const SpecificationSection: React.FC = ({ children }) => {
    return (
        <div className={cn('relative', styles.grid)}>
            <FeatureMarkup
                contentKey={specificationSectionId('markup_top')}
                wrapper={props => <div className="small-margin-bottom" {...props} />}
            />
            <Technologies />
            <FeatureMarkup
                contentKey={specificationSectionId('markup_under_technologies')}
                wrapper={props => <div className="small-margin-top" {...props} />}
                fallback={
                    <FeatureMarkup
                        contentKey={FC.CartPage.UnderSpecificationsGridContentSet}
                        wrapper={props => <div className="small-margin-top" {...props} />}
                    />
                }
            />

            {children}

            <FeatureMarkup
                contentKey={specificationSectionId('markup_bottom')}
                wrapper={props => <div className="small-margin-top" {...props} />}
                fallback={
                    <FeatureMarkup
                        contentKey={FC.CartPage.UnderSpecificationsContentSet}
                        wrapper={props => <div className="small-margin-top" {...props} />}
                    />
                }
            />
        </div>
    );
};
