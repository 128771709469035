import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderPriceCorrectionForm, OrderPriceCorrection } from '@types';

interface State {
    data: OrderPriceCorrection[];
    isAdding: boolean;
    isLoading: boolean;
    isDeleting: boolean;
}

const initialState: State = {
    data: [],
    isAdding: false,
    isLoading: false,
    isDeleting: false,
};

export const priceCorrectionsSlice = createSlice({
    name: 'priceCorrections',
    initialState,
    reducers: {
        addPriceCorrection: (state, action: PayloadAction<OrderPriceCorrectionForm>) => {
            state.isAdding = true;
        },
        addPriceCorrectionSuccess: (state, action: PayloadAction<OrderPriceCorrection>) => {
            state.isAdding = false;
            state.data = state.data.concat(action.payload);
        },
        addPriceCorrectionFailure: state => {
            state.isAdding = false;
        },

        deletePriceCorrection: (state, action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deletePriceCorrectionSuccess: (state, action: PayloadAction<number>) => {
            state.isDeleting = false;
            state.data = state.data.filter(obj => obj.id !== action.payload);
        },
        deletePriceCorrectionFailure: state => {
            state.isDeleting = false;
        },

        getPriceCorrections: state => {
            state.isLoading = true;
        },
        getPriceCorrectionsSuccess: (state, action: PayloadAction<OrderPriceCorrection[]>) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        getPriceCorrectionsFailure: state => {
            state.isLoading = false;
        },
    },
});

export const priceCorrectionsActions = priceCorrectionsSlice.actions;
