export enum FilterModelsByUserValues {
    All = 'all',
    Client = 'client',
    Owner = 'owner',
}

export type FilterModelsByUser = `${FilterModelsByUserValues}`;
export type ModelsFilter = {
    search?: string;
    userType?: FilterModelsByUser;
    page: number;
};
