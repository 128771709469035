import cn from 'classnames';
import currency from 'currency.js';
import React, { useState } from 'react';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { ExpansionPanel } from '@react-md/expansion-panel';
import { Typography } from '@react-md/typography';
import { PricingList, PricingRecord, PricingRecordProps } from '@components/pricing-list';
import { InitialOrder } from '@types';
import { decimalToBoolean } from '@utils';
import { selectAppSettings } from '@modules/app';
import { selectWholeOrderTbd } from '@modules/order';
import { Price } from '@components/price';
import { TbdLabel, TbdMessages } from '@components/tbd';
import { TotalPrice } from '@components/total-price';
import { Icons } from '@components/icons';
import { cartSectionId } from '../helpers';

import styles from './total-pricing.module.scss';

interface Props {
    hasProducts: boolean;
    orderIsEmpty: boolean;
    order: Partial<InitialOrder>;
}

const TotalPricingRecord = ({ keyType = 'body-2', valueType = 'body-2', ...rest }: PricingRecordProps) => (
    <PricingRecord keyType={keyType} valueType={valueType} {...rest} />
);

export const TotalPricing = ({ hasProducts, orderIsEmpty, order }: Props) => {
    const settings = useAppSelector(selectAppSettings);
    const tbd = useAppSelector(selectWholeOrderTbd);

    const [expanded, setExpanded] = useState(false);

    const { t } = useTranslation();

    const hasOrder = !!order.id;
    // todo remove when we add tax_value for InitialOrder in backend
    const taxValue = Boolean(order.tax_in_percents && order.total_price && order.total_price_without_tax)
        ? currency(order.total_price!).subtract(order.total_price_without_tax!).value
        : null;

    const header = (
        <div className="rmd-typography--left">
            <TotalPrice
                className={cn([
                    'inline-flex',
                    'items-center',
                    'text-overflow-ellipsis',
                    'whitespace-pre',
                    styles.label,
                    { [styles.empty]: orderIsEmpty },
                ])}
            >
                {t('labels.total', 'Total')}
                {': '}
                {hasProducts && <Price>{order.total_price!}</Price>}
            </TotalPrice>

            {decimalToBoolean(order.total_discount_value) && (
                <Typography
                    type="body-2"
                    component={'div'}
                    color="secondary"
                    className={cn('micro-margin-top', 'text-overflow-ellipsis', styles.label, {
                        [styles.empty]: orderIsEmpty,
                    })}
                >
                    {t('labels.discount', 'Discount')}
                    {decimalToBoolean(order.products_discount_value) ? null : ` (${order.order_discount_in_percents}%)`}
                    {': '}
                    {!orderIsEmpty && <Price sign="-">{order.total_discount_value!}</Price>}
                </Typography>
            )}

            {hasProducts && (
                <Typography type="subtitle-2" weight="semi-bold" component="div" className="micro-margin-top">
                    {t('labels.totalInclSales', '{{taxName}} included {{taxPercent}}%', {
                        taxPercent: order.tax_in_percents !== undefined ? String(order.tax_in_percents) : '0.00',
                        taxName: settings.tax_name,
                    })}
                </Typography>
            )}
        </div>
    );

    return tbd.length ? (
        <div className={cn('base-paddings', styles.box)}>
            <div className={cn(['inline-flex', 'items-center', 'small-margin-bottom'])}>
                <TotalPrice className="small-margin-right">
                    {t('labels.total', 'Total')}
                    {': '}
                </TotalPrice>
                <TbdLabel />
            </div>
            <TbdMessages list={tbd} />
        </div>
    ) : (
        <ExpansionPanel
            id={cartSectionId('total_panel')}
            disablePadding
            disableSecondaryColor
            expanded={hasOrder && expanded}
            onExpandClick={() => setExpanded(!expanded)}
            className={styles.expand}
            contentClassName={cn('fade-in', 'base-paddings', styles.body)}
            headerClassName={cn('base-paddings', styles.box, styles.head)}
            header={header}
            expanderIcon={<Icons.Chevron className={styles['expansion-icon']} />}
        >
            <PricingList divider>
                <TotalPricingRecord label={t('labels.modelsPrice', 'Models price')} value={order.models_naked_price} />

                {decimalToBoolean(order.startup_cost) && (
                    <TotalPricingRecord label={t('labels.startupCost', 'Startup cost')} value={order.startup_cost} />
                )}

                <TotalPricingRecord label={t('titles.delivery', 'Delivery')} value={order.delivery_price} />

                {decimalToBoolean(order.price_corrections) && (
                    <TotalPricingRecord
                        label={t('subtitles.priceCorrections', 'Additional goods and services')}
                        value={order.price_corrections}
                        sign={parseInt(order.price_corrections!) > 0 ? '+' : undefined}
                    />
                )}

                {decimalToBoolean(order.total_discount_value) && (
                    <TotalPricingRecord
                        label={t('labels.discount', 'Discount')}
                        percent={
                            decimalToBoolean(order.products_discount_value)
                                ? undefined
                                : Number(order.order_discount_in_percents)
                        }
                        value={order.total_discount_value}
                        sign="-"
                    />
                )}

                <TotalPricingRecord label={t('labels.subtotal', 'Subtotal')} value={order.total_price_without_tax} />

                {Boolean(taxValue) && (
                    <TotalPricingRecord label={settings.tax_name} value={taxValue!} percent={order.tax_in_percents} />
                )}
            </PricingList>

            <PricingList divider className="medium-margin-bottom">
                <PricingRecord
                    label={t('labels.total', 'Total')}
                    value={order.total_price}
                    keyType="headline-6"
                    valueType="headline-6"
                />
            </PricingList>
        </ExpansionPanel>
    );
};
