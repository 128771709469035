import cn from 'classnames';
import React, { ReactNode } from 'react';
import { Label } from '@components/label';

import styles from './specification-form-item.module.scss';

interface Props {
    label?: ReactNode;
    labelId?: string;
    rowHalf?: boolean;
    className?: string;
}

export const SpecificationFormItem: React.FC<Props> = ({ children, label, labelId, rowHalf, className }) => {
    return (
        <div className={cn([styles.row, className, { [styles.rowHalf]: rowHalf }])}>
            {label && <Label id={labelId}>{label}</Label>}
            {children}
        </div>
    );
};
