import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import { Collapse } from '@react-md/transition';
import { AppSettings, OrderForm } from '@types';
import { OrderFormBillingAddress } from './order-form-billing-address';
import { OrderFormBillingContact } from './order-form-billing-contact';
import { IsCompanyOrder } from './order-is-company-order';

import styles from './order-form-billing.module.scss';

interface OrderFormBillingProps {
    settings: AppSettings;
}

export const OrderFormBilling = ({ settings }: OrderFormBillingProps) => {
    const {
        control,
        watch,
        getValues,
        setValue,
        reset,
        formState: { errors },
    } = useFormContext<OrderForm>();

    const { force_company_order } = settings;

    const [shippingType, isCompanyOrder, country, state] = watch([
        'shippingType',
        'isCompanyOrder',
        'billing_address.country',
        'billing_address.state',
    ]);

    return (
        <div>
            {!force_company_order && (
                <IsCompanyOrder
                    control={control}
                    getValues={getValues}
                    isCompanyOrder={isCompanyOrder}
                    errors={errors}
                />
            )}
            <Collapse collapsed={!isCompanyOrder}>
                <div className={cn(styles.billing, { 'margin-top': !force_company_order })}>
                    <OrderFormBillingContact control={control} setValue={setValue} getValues={getValues} />
                    <OrderFormBillingAddress
                        errors={errors}
                        country={country}
                        state={state}
                        control={control}
                        settings={settings}
                        setValue={setValue}
                        getValues={getValues}
                        reset={reset}
                        shippingType={shippingType!}
                    />
                </div>
            </Collapse>
        </div>
    );
};
