import cn from 'classnames';
import React, { ChangeEventHandler, FormEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, FieldPath } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddMessage, useMessageQueueActions } from '@react-md/alert';
import { Button } from '@react-md/button';
import { TextField } from '@react-md/form';
import { Typography } from '@react-md/typography';
import { Alert, AlertHeading } from '@components/alert';
import { FeatureContent } from '@components/feature-content';
import { FormField, NonFieldErrors } from '@components/form-field';
import { FieldLabel } from '@components/label';
import { PreloaderBox } from '@components/preloader';
import { SubmitButton } from '@components/submit-button';
import { OtpForm } from '@types';
import { selectAppSettings } from '@modules/app';
import { authActions, selectAuthErrors, selectIsOtpSending, selectIsTokenObtaining } from '@modules/auth';
import { composeNodeId, setApiErrors } from '@utils';
import { useValidation } from '@hooks';
import { useFormLabels } from '../hooks';
import { createOtpValidation } from '../form-validation';
import { AuthFormTitle } from '../components';
import { otpId } from '../helpers';
import { OtpCodeLength, OtpFormId } from '../constants';

enum Step {
    ObtainCode,
    EnterCode,
}

interface FormProps {
    actions: ReactNode;
    actionsClassName?: string;
    desc: string;
}

const Form: React.FC<FormProps> = ({ actions, actionsClassName, children, desc }) => {
    return (
        <>
            <Typography type="body-2">{desc}</Typography>
            {children}
            <div className={cn('fieldset-grid-cell', 'flex', actionsClassName)}>{actions}</div>
        </>
    );
};

const CodeNotifications = () => {
    const codeErrors = useAppSelector(selectAuthErrors);
    const addMessage = useAddMessage();
    const { hideMessage } = useMessageQueueActions();
    const { t } = useTranslation();

    useEffect(() => {
        if (codeErrors) {
            addMessage({
                messageId: otpId('error'),
                messagePriority: 'replace',
                disableAutohide: true,
                className: 'rmd-toast--alert',
                children: (
                    <Alert
                        show
                        type="error"
                        variant="filled"
                        dismissible
                        closeButtonId={otpId('close_hints')}
                        onClose={hideMessage}
                    >
                        <AlertHeading
                            iconType="error"
                            text={
                                <Trans
                                    i18nKey="messages.otpExpired"
                                    defaults={'Your code is invalid or has expired.<br/>Please request a new one.'}
                                />
                            }
                        />
                    </Alert>
                ),
            });
        } else {
            hideMessage();
        }
    }, [addMessage, hideMessage, codeErrors, t]);

    return null;
};

const formatOtp = (value: string) => value.replace(/\D/g, '').slice(0, OtpCodeLength);

interface Props {
    onSuccess?: (data?: OtpForm) => void;
}

export const OtpFormBody: React.FC<Props> = ({ onSuccess }) => {
    const dispatch = useAppDispatch();

    const settings = useAppSelector(selectAppSettings);
    const isOtpSending = useAppSelector(selectIsOtpSending);
    const isTokenObtaining = useAppSelector(selectIsTokenObtaining);

    const validationSchema = useValidation(createOtpValidation);
    const form = useForm<OtpForm>({
        defaultValues: {
            email: '',
        },
        resolver: yupResolver(validationSchema),
    });

    const {
        control,
        handleSubmit,
        getValues,
        setError,
        formState: { errors },
    } = form;

    const [step, setStep] = useState(Step.ObtainCode);
    const isObtainCodeStep = step === Step.ObtainCode;

    const obtainCode = handleSubmit(form => {
        dispatch(
            authActions.obtainOtp({
                form,
                onSuccess: () => {
                    setStep(Step.EnterCode);
                    // onSuccess?.();
                },
                onError: errors => {
                    setApiErrors(setError, errors as Record<FieldPath<OtpForm>, string>);
                },
            }),
        );
    });

    const [code, setCode] = useState('');
    const codeId = composeNodeId(OtpFormId, 'code', 'field');
    const codeLabelId = composeNodeId(OtpFormId, 'code', 'label');

    const handleCodeChange: ChangeEventHandler<HTMLInputElement> = useCallback(({ target: { value } }) => {
        setCode(formatOtp(value));
    }, []);

    const submitCode = useCallback(
        (e?: FormEvent) => {
            e?.preventDefault();

            if (code.length === OtpCodeLength) {
                const { email } = getValues();
                dispatch(
                    authActions.otpLogin({
                        form: { email, security_hash: code },
                        onSuccess: () => {
                            onSuccess?.();
                        },
                    }),
                );
            }
        },
        [dispatch, getValues, code, onSuccess],
    );

    useEffect(() => {
        submitCode();
    }, [submitCode]);

    const { t } = useTranslation();
    const labels = useFormLabels(t);

    return (
        <PreloaderBox show={isTokenObtaining || (isOtpSending && !isObtainCodeStep)}>
            <form id={OtpFormId} onSubmit={isObtainCodeStep ? obtainCode : submitCode}>
                <div className={cn('fieldset-grid', 'base-paddings')}>
                    <AuthFormTitle
                        className={undefined}
                        title={t('titles.signIn', 'Sign in to {{company}}', {
                            company: settings.company_title,
                        })}
                    />

                    {isObtainCodeStep ? (
                        <Form
                            desc={t(
                                'messages.weSendYouCode',
                                'We’ll send you the code to sign in or create an account.',
                            )}
                            actions={
                                <SubmitButton id={otpId('send_code')} isSending={isOtpSending}>
                                    <FeatureContent
                                        contentKey={otpId('send_code')}
                                        fallback={t('buttons.sendCode', 'Send code')}
                                    />
                                </SubmitButton>
                            }
                        >
                            <FormField
                                prefix={OtpFormId}
                                name="email"
                                control={control}
                                component={TextField}
                                label={labels.email}
                            />
                            <NonFieldErrors errors={errors} />
                        </Form>
                    ) : (
                        <Form
                            desc={t(
                                'messages.codeIsSent',
                                'The code is sent. Please check your inbox and enter the code sent to your email.',
                            )}
                            actions={
                                <FeatureContent
                                    contentKey={otpId('resend_code')}
                                    fallback={t('buttons.resendCode', 'Resend code')}
                                    wrapper={props => (
                                        <Button
                                            theme="clear"
                                            className={cn('rmd-button--flat')}
                                            disabled={isOtpSending}
                                            onClick={obtainCode}
                                            disableRipple
                                            {...props}
                                        />
                                    )}
                                />
                            }
                            actionsClassName="justify-center"
                        >
                            <div>
                                <FieldLabel id={codeLabelId} htmlFor={codeId}>
                                    <FeatureContent contentKey={codeLabelId} fallback={t('labels.code', 'Code')} />
                                </FieldLabel>
                                <TextField id={codeId} onChange={handleCodeChange} value={code} />
                            </div>
                            <CodeNotifications />
                        </Form>
                    )}
                </div>
            </form>
        </PreloaderBox>
    );
};
