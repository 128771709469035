import { useEffect, useMemo, useReducer, useState } from 'react';
import { ModelViewer as ModelViewerUtil, ModelViewerObjects } from '@utils';
import { useLocation } from 'react-router-dom';
import { ModelViewerDarkModeBox } from '@pages/widget/order/viewer/model-viewer/model-viewer-dark-mode-box';
import { ModelViewerSceneControlsPanel } from '@pages/widget/order/viewer/model-viewer/model-viewer-scene-controls-panel';
import { useAppSelector } from '@app/hooks';
import { selectContentCustomization } from '@modules/app';
import { useGetResizeRef } from '@utils/parant-site-comunicator';

import styles from '../widget/order/viewer/viewer-window.module.scss';

// todo outdated version of the page, remove after companies migration to new page
export const ViewerPage = () => {
    let visualizer: HTMLDivElement;

    const [modelViewer, setModelViewer] = useState<ModelViewerUtil>();

    const contentCustomization = useAppSelector(selectContentCustomization);

    const { search } = useLocation();

    const query = useMemo(() => new URLSearchParams(search), [search]);

    const fileUrl = useMemo(() => {
        const url = query.get('fileUrl');

        query.delete('fileUrl');

        return `${url}&${query.toString()}`;
    }, [query]);

    const [isDarkModeActive, toggleDarkMode] = useReducer(previous => !previous, false);

    const containerRef = useGetResizeRef();

    useEffect(() => {
        setModelViewer(
            new ModelViewerUtil(visualizer, {
                darkModeOn: isDarkModeActive,
                debug: true,
                objects: {
                    [ModelViewerObjects.Axes]: {
                        enabled: true,
                        active: true,
                    },
                    [ModelViewerObjects.Grid]: {
                        enabled: true,
                        active: true,
                    },
                },
            }),
        );

        return () => {
            modelViewer && modelViewer.clear();
            setModelViewer(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!modelViewer) return;

        const { cancel } = modelViewer.loadModel(fileUrl);

        return () => {
            cancel();
        };
    }, [modelViewer, fileUrl]);

    return (
        <>
            <div className={styles.box} tabIndex={-1} ref={containerRef}>
                <div
                    className={styles.inner}
                    ref={ref => {
                        visualizer = ref as HTMLDivElement;
                    }}
                >
                    <ModelViewerDarkModeBox
                        show
                        isDarkModeActive={isDarkModeActive}
                        toggleDarkMode={toggleDarkMode}
                        modelViewer={modelViewer}
                    />
                    <ModelViewerSceneControlsPanel
                        show
                        contentCustomization={contentCustomization}
                        modelViewer={modelViewer}
                    />
                </div>
            </div>
        </>
    );
};
