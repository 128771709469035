import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { FeatureContent } from '@components/feature-content';
import { getNodeIdComposer } from '@utils';
import { ModelPageFilters } from './models-page-filters';
import { RootPrefix } from './constants';

import styles from './models-page-header.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'header');

export const ModelsPageHeader = () => {
    const { t } = useTranslation();

    return (
        <div className={cn('margin-bottom', 'base-paddings', 'no-bottom', styles.box)}>
            <Typography id={_id('title')} type="headline-3" component="h1">
                <FeatureContent contentKey={_id('title')} fallback={t('titles.models', 'Models')} />
            </Typography>

            <ModelPageFilters />
        </div>
    );
};
