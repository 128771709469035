import {
    filter,
    switchMap,
    catchError,
    map,
    take,
    takeWhile,
    withLatestFrom,
    distinctUntilKeyChanged,
} from 'rxjs/operators';
import { concat, from, of, timer, EMPTY } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import { combineEpics } from 'redux-observable';
import { AxiosError } from 'axios';
import { OrderService } from '@services';
import { AppEpic, LoadShippingRatesResponse, CheckShippingRatesResponse } from '@types';
import { PollingConfig } from '@constants';
import { selectOrderId } from '../order';
import { deliveryRatesActions } from './slice';
import { selectIsShippingRatesReady, selectShippingRatesErrors } from './selectors';

const loadRatesEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(deliveryRatesActions.load.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const orderId = selectOrderId(state);
            if (!orderId) return EMPTY;

            return from(OrderService.init().loadRates(orderId, action.payload)).pipe(
                switchMap(({ data }) => {
                    if (data.errors) {
                        return of(deliveryRatesActions.loadFailure(data));
                    }

                    const actions: Action[] = [deliveryRatesActions.loadSuccess(data)];

                    // TODO we can remove is_ready flag from response of POST request, only task_id is necessary
                    if (data.is_ready === false && data.task_id) {
                        actions.push(deliveryRatesActions.startPolling(data.task_id));
                    }

                    return concat(actions);
                }),
                catchError((error: AxiosError<LoadShippingRatesResponse>) => {
                    return of(deliveryRatesActions.loadFailure(error?.response?.data || {}));
                }),
            );
        }),
    );

const checkRatesEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(deliveryRatesActions.check.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const orderId = selectOrderId(state);
            if (!orderId) return EMPTY;

            return from(OrderService.init().checkRates(orderId, action.payload)).pipe(
                map(({ data }) => deliveryRatesActions.checkSuccess(data)),
                catchError((error: AxiosError<CheckShippingRatesResponse>) => {
                    return of(
                        deliveryRatesActions.checkFailure(error?.response?.data || ({} as CheckShippingRatesResponse)),
                    );
                }),
            );
        }),
    );

const startRatesPollingEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(deliveryRatesActions.startPolling.match),
        distinctUntilKeyChanged('payload'),
        switchMap(action =>
            concat(
                timer(PollingConfig.shippingRates.delay, PollingConfig.shippingRates.interval).pipe(
                    take(PollingConfig.shippingRates.attempts),
                    withLatestFrom(state$),
                    map(([_, state]) => {
                        const isReady = selectIsShippingRatesReady(state);
                        const errors = selectShippingRatesErrors(state);
                        return !isReady && !errors;
                    }),
                    takeWhile(needPolling => needPolling),
                    switchMap(() => concat([deliveryRatesActions.check(action.payload)])),
                ),
                of(deliveryRatesActions.stopPolling()),
            ),
        ),
    );

export const deliveryRatesEpics = combineEpics(loadRatesEpic, checkRatesEpic, startRatesPollingEpic);
