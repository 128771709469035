import cn from 'classnames';
import React, { useCallback } from 'react';
import { useAppDispatch } from '@app/hooks';
import { Button } from '@react-md/button';
import { RouterLink } from '@components/link';
import { AuthPopup } from '@types';
import { popupsActions } from '@modules/ui';
import { useBuildLocation } from '@hooks';
import { reverse } from '@utils';
import { otpId, signInId, signUpId } from '../helpers';
import { FormType } from '../types';

const getId = ({ auth, type }: { auth: AuthPopup; type: FormType }) => {
    const part = type === 'page' ? 'page_link' : 'popup_link';
    switch (auth) {
        case AuthPopup.SignIn:
            return signInId(part);
        case AuthPopup.SignUp:
            return signUpId(part);
        case AuthPopup.OTP:
            return otpId(part);
    }
};

const getUrl = ({ auth }: { auth: AuthPopup }) => {
    switch (auth) {
        case AuthPopup.SignIn:
            return reverse('signIn');
        case AuthPopup.SignUp:
            return reverse('signUp');
        case AuthPopup.OTP:
            return reverse('obtainOtp');
    }
};

interface LinkProps {
    id: string;
    auth: AuthPopup;
}

const PageLink: React.FC<LinkProps> = ({ auth, id, children }) => {
    const url = getUrl({ auth });
    const location = useBuildLocation({
        to: url,
        preserveState: true,
    });
    return <RouterLink id={id} {...location} replace children={children} />;
};

const PopupButton: React.FC<LinkProps> = ({ auth, id, children }) => {
    const dispatch = useAppDispatch();
    const open = useCallback(
        () =>
            dispatch(
                popupsActions.open({
                    type: auth,
                }),
            ),
        [dispatch, auth],
    );
    return (
        <Button
            id={id}
            theme="primary"
            className={cn('rmd-button--flat', 'rmd-button--inline')}
            disableRipple
            onClick={open}
            children={children}
        />
    );
};

interface Props {
    auth: AuthPopup;
    type: FormType;
}

export const AuthFormLink: React.FC<Props> = ({ auth, type, children }) => {
    const id = getId({ auth, type });
    const Link = type === 'page' ? PageLink : PopupButton;
    return <Link id={id!} auth={auth} children={children} />;
};
