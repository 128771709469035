import isString from 'lodash/isString';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { UploadingModel, ObjectModelStatus } from '@types';
import { uploadModelsActions, selectUploadedModelFileById } from '@modules/upload-models';
import { isArchive } from '@utils';
import { Fade, FADE_DURATION } from '@components/animations';
import { UploadableModel } from '../uploadable-model';
import { UploadedArchive, UploadedModel } from '../uploaded-models';
import { ModelUploadHandlerProps, onDeleteModelSignature } from '../../types';

import styles from './model-upload-handler.module.scss';

export const ModelUploadHandler: React.FC<ModelUploadHandlerProps> = ({ uuid, in: show, ...rest }) => {
    const dispatch = useAppDispatch();
    const file = useAppSelector(state => selectUploadedModelFileById(state, uuid));
    const { upload, uploaded } = file ? file : ({} as UploadingModel);

    const [visible, setVisible] = useState(true);
    const enter = show && visible;

    const onDeleteFile = (uuid: string) => () => {
        setVisible(false);

        setTimeout(() => dispatch(uploadModelsActions.deleteUploadedFile(uuid)), FADE_DURATION);
    };

    const onDeleteModel: onDeleteModelSignature = (uuid, modelTitle, isLastFile) => () => {
        if (!isLastFile) {
            // remove file from archive
            dispatch(uploadModelsActions.deleteUploadedModel({ uuid, modelTitle }));
            return;
        }

        setVisible(false);

        setTimeout(() => dispatch(uploadModelsActions.deleteUploadedModel({ uuid, modelTitle })), FADE_DURATION);
    };

    return (
        <Fade
            in={enter}
            className={styles.box}
            // mountOnEnter
            // appear
            unmountOnExit
            {...rest}
        >
            <>
                {upload && <UploadableModel fileName={upload.file.name} progress={upload.progress} />}

                {(() => {
                    if (!uploaded) return null;

                    if (isString(uploaded.detail))
                        return (
                            <UploadedModel
                                uuid={uuid}
                                status={ObjectModelStatus.Failed}
                                label={uploaded.file_name}
                                error={uploaded.detail}
                                onDelete={onDeleteFile(uuid)}
                            />
                        );

                    if (isArchive(uploaded.file_name))
                        return (
                            <UploadedArchive
                                uuid={uuid}
                                archive={uploaded}
                                onDelete={onDeleteModel}
                                isLastFile={uploaded.object_models!.length === 1}
                            />
                        );

                    const model = uploaded.object_models![0];
                    return (
                        <UploadedModel
                            uuid={uuid}
                            status={model.status}
                            label={model.title}
                            error={model.detail}
                            modelId={model.id}
                            onDelete={onDeleteModel(uuid, model.title, true)}
                        />
                    );
                })()}
            </>
        </Fade>
    );
};
