import { from, of } from 'rxjs';
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { AppEpic } from '@types';
import { CompanyService } from '@services';
import { technologiesActions } from './slice';
import { selectIqtModeOn } from '@modules/user';

const loadTechnologiesEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(technologiesActions.load.match),
        withLatestFrom(state$),
        switchMap(([_, state]) => {
            const iqtModeOn = selectIqtModeOn(state);
            let loadTechnologies = CompanyService.init().loadTechnologies;
            if (iqtModeOn) loadTechnologies = CompanyService.init().loadUsersTechnologies;
            return from(loadTechnologies()).pipe(
                withLatestFrom(state$),
                switchMap(([{ data }, state]) => {
                    return of(technologiesActions.loadSuccess(data));
                }),
                catchError(() => of(technologiesActions.loadFailure())),
            );
        }),
    );

export const technologiesEpics = combineEpics(loadTechnologiesEpic);
