import { DropzoneOptions, FileError } from 'react-dropzone';
import { fileAccepted, fileMatchSize } from 'react-dropzone/src/utils';

export const getSizesByAcceptedDropzoneValidator = (
    options: Array<Pick<DropzoneOptions, 'minSize' | 'maxSize' | 'accept'>>,
) => {
    return (file: File) => {
        const errors: FileError[] = [];

        options.forEach(({ minSize, maxSize, accept }) => {
            const [accepted] = fileAccepted(file, accept);

            if (!accepted) return;

            const [_, sizeError] = fileMatchSize(file, minSize, maxSize);

            if (sizeError) {
                errors.push({ values: { minSize, maxSize }, ...sizeError });
            }
        });

        return errors.length ? errors : null;
    };
};
