import pick from 'lodash/pick';
import {
    ObjectModel,
    TechnologyMaterial,
    SpecificationInitialValues,
    PriceConfigForm,
    TechnologyMaterialOptions,
} from '@types';
import { PriceConfigFields } from '@constants';
import { SpecificationFormParams } from './types';

const hasFeaturesPredicate = (model: ObjectModel) =>
    model.dfm_features &&
    Object.values(model.dfm_features).some(topologyValues => Array.isArray(topologyValues) && topologyValues.length);

export function getSpecificationOptions(model: ObjectModel, material?: TechnologyMaterial): SpecificationFormParams {
    const fields = pick(material, PriceConfigFields);

    return Object.entries(fields)
        .filter(([key, value]) => {
            const hasOptions = !!value && Boolean(Array.isArray(value) ? value.length : Object.keys(value).length);
            return key === 'dfm_features' ? hasFeaturesPredicate(model) && hasOptions : hasOptions;
        })
        .reduce((acc, [key, value]) => {
            switch (key) {
                case 'extra_fieldsets': {
                    const options = value as SpecificationFormParams['extra_fieldsets'];
                    const groups = options.filter(list => list.options.length);
                    return groups.length ? { ...acc, [key]: groups } : acc;
                }

                default:
                    return { ...acc, [key]: value };
            }
        }, {} as SpecificationFormParams);
}

export function getSpecificationInitialValues({
    formParams,
    initialThickness,
}: SpecificationInitialValues): PriceConfigForm {
    return Object.entries(formParams).reduce((acc, [key, value]) => {
        switch (key) {
            case 'dfm_features': {
                // we can omit the default value for features since the backend can choose it itself
                // plus we don't know at the moment what features are suitable for the model
                // this is resolved in the SpecificationFeatures component
                return acc;
            }
            case 'post_production': {
                return { ...acc, [key]: [] };
            }
            case 'extra_fieldsets': {
                const options = value as SpecificationFormParams['extra_fieldsets'];
                const defaultValue = options.map(val => {
                    return {
                        fieldset: val.id,
                        choice: val.options[0].id,
                    };
                });
                return { ...acc, [key]: defaultValue };
            }
            case 'thickness': {
                const initial =
                    initialThickness &&
                    (value as TechnologyMaterialOptions).find(thickness => thickness.uuid === initialThickness);

                return {
                    // This fallback is for case if first material becomes not suitable after analysis
                    // or cases without preselection and returning config: null like CNC Cutter
                    ...acc,
                    [key]: (initial || value[0]).uuid,
                };
            }
            default:
                return { ...acc, [key]: (value as TechnologyMaterialOptions)[0].uuid };
        }
    }, {} as PriceConfigForm);
}
