import { ReadFileResult } from './types';

export function isImage(file: File) {
    if (file.type.split('/')[0] === 'image') {
        return true;
    }
}

export async function createFileFromUrl(url: string) {
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = { type: data.type };
    const filename = url.replace(/\?.+/, '').split('/').pop() || 'empty-filename';
    return new File([data], filename, metadata);
}

export function readFile(file: File): Promise<ReadFileResult> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = event => {
            resolve(event?.target?.result);
        };
        reader.onerror = event => {
            reader.abort();
            reject(event);
        };
        reader.readAsDataURL(file);
    });
}
