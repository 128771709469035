import cn from 'classnames';
import isNumber from 'lodash/isNumber';
import React, { Children, forwardRef, HTMLAttributes, ReactNode } from 'react';
import { TFunctionResult } from 'i18next';
import { Divider } from '@react-md/divider';
import { Typography, TypographyType } from '@react-md/typography';
import { Price } from '@components/price';

import styles from './pricing-list.module.scss';

export interface PricingRecordProps {
    className?: string;
    label?: ReactNode | TFunctionResult;
    value?: number | string;
    percent?: number;
    keyType?: TypographyType;
    valueType?: TypographyType;
    isCurrency?: boolean;
    fromCents?: boolean;
    sign?: string;
}

export const PricingRecord: React.FC<PricingRecordProps> = ({
    className,
    label,
    value,
    percent,
    keyType = 'body-2',
    valueType = 'body-2',
    children,
    isCurrency = true,
    fromCents = false,
    sign = '',
}) => {
    const _label = percent !== undefined ? `${label} (${percent}%)` : label;
    const _value = isCurrency ? (
        value || isNumber(value) ? (
            <Price fromCents={fromCents} sign={sign}>
                {value}
            </Price>
        ) : (
            ''
        )
    ) : (
        value
    );

    return (
        <div className={cn(styles.record, className)}>
            <Typography type={keyType} className={styles.label} component={'div'}>
                {_label}
            </Typography>

            {children || (
                <Typography type={valueType} className={cn('text-overflow-ellipsis', styles.value)} component={'div'}>
                    {_value}
                </Typography>
            )}
        </div>
    );
};

export interface PricingListProps extends HTMLAttributes<HTMLDivElement> {
    divider?: boolean;
}

export const PricingList = forwardRef<HTMLDivElement, PricingListProps>(
    ({ className, children, divider = false, ...rest }, ref) => {
        // todo FeatureToggle case is not processed
        const hasChild = Boolean(Children.toArray(children).filter(Boolean).length);
        if (!hasChild) return null;

        return (
            <div ref={ref} className={cn(styles.box, className)} {...rest}>
                {divider && <Divider className={styles.divider} />}
                {children}
            </div>
        );
    },
);
