import i18next from 'i18next';

export const oni18nextInitialized = (callback: () => void) => {
    callback();

    // fixed mozilla bug, auto detector resets language after our locale has been set
    if (!i18next.isInitialized) {
        const initialized = () => {
            // due to emitter removing issue in i18next we need to delay remove
            setTimeout(() => {
                i18next.off('initialized', initialized);
            }, 0);

            callback();
        };

        i18next.on('initialized', initialized);
    }
};
