import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagedResponse, Technology } from '@types';

interface State {
    data?: PagedResponse<Technology>;
    isLoading: boolean;
    results: Technology[];
}

const initialState: State = {
    isLoading: false,
    results: [],
};

export const technologiesSlice = createSlice({
    name: 'technologies',
    initialState,
    reducers: {
        load: state => {
            state.isLoading = true;
        },
        loadSuccess: (state, action: PayloadAction<PagedResponse<Technology>>) => {
            state.isLoading = false;
            state.data = action.payload;
            state.results = action.payload.results;
        },
        loadFailure: state => {
            state.isLoading = false;
        },
    },
});

export const technologiesActions = technologiesSlice.actions;
