import Currency from 'currency.js';
import { AppSettings, BulkPriceItem } from '@types';
import { formatPrice } from '@utils';

export function getBulkPriceCellValue({ price_info, quantity }: BulkPriceItem, currency: AppSettings['currency']) {
    const { naked_price, priority_price } = price_info;

    return formatPrice(Currency(naked_price).add(priority_price).value, {
        ...currency,
        precision: currency.decimal_digits,
    });
}
