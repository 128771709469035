import * as Three from 'three';
import { ModelViewerVariables } from '../constants';

export class Grid extends Three.Group {
    constructor(...params: ConstructorParameters<typeof Three.GridHelper>) {
        super();

        const grid1 = new Three.GridHelper(
            params[0],
            100,
            ModelViewerVariables.Grid.CellColor,
            ModelViewerVariables.Grid.CellColor,
        );

        grid1.material.color.set(ModelViewerVariables.Grid.CellColor);
        grid1.material.vertexColors = false;

        const grid2 = new Three.GridHelper(
            params[0],
            10,
            ModelViewerVariables.Grid.SectionColor,
            ModelViewerVariables.Grid.SectionColor,
        );

        grid2.material.color.set(ModelViewerVariables.Grid.SectionColor);
        grid2.material.depthFunc = Three.AlwaysDepth;
        grid2.material.vertexColors = false;

        grid1.renderOrder = grid2.renderOrder = 1;
        this.add(grid1, grid2);

        this.rotateX(Math.PI / 2); // because we changed the UP objects property to Object3D.DefaultUp.set(0, 0, 1)
    }
}
