import cn from 'classnames';
import React from 'react';
import { Typography } from '@react-md/typography';
import { TextIconSpacing } from '@react-md/icon';
import { Icons } from '@components/icons';
import { ValuesType } from '@types';

import styles from './invoice-tile-head.module.scss';

interface InvoiceTileHeadProps {
    id: string;
    className?: string;
    icon: ValuesType<typeof Icons>;
}

// todo wtf, why is the invoice in the shared folder?
export const InvoiceTileHead: React.FC<InvoiceTileHeadProps> = ({ className, children, icon, id }) => {
    const Icon = icon;

    return (
        <div className={cn(styles.box, className)}>
            <TextIconSpacing className={styles.icon} icon={<Icon className="rmd-typography--hint" />} forceIconWrap>
                <Typography id={id} type="headline-4">
                    {children}
                </Typography>
            </TextIconSpacing>
        </div>
    );
};
