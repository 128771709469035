import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import { FieldTypes, JSONSchemaRootPointer } from '@constants';
import { BasicJSONSchema, FieldGroups, JSONSubSchema, ObjectJSONSchema, SupposedJSONSchema } from '@types';

const getPathKeysFromPointer = (pointer: string) => {
    const splitted = getSplitPointer(pointer);
    const key = splitted.filter(value => value !== 'properties');

    return key.join('.');
};

const getSplitPointer = (pointer: string) => {
    const splitted = pointer.split('/');

    if (splitted[0] === JSONSchemaRootPointer) {
        splitted.shift();
    }

    return splitted;
};

const concatPointer = (path: string, newNode: string) => {
    return path + '/' + newNode;
};

function getSubSchema(subSchema: JSONSubSchema, pointer: string) {
    const { JSONSchema } = subSchema;
    if (JSONSchema?.type === FieldTypes.Object) {
        const keys = Object.keys(JSONSchema.properties);
        const fields = keys.reduce(getChildSchemas(JSONSchema, pointer), []);

        const hasTitle = 'title' in JSONSchema;

        return hasTitle ? [{ title: JSONSchema.title, order: JSONSchema.order, fields }] : fields;
    }

    return subSchema;
}

function getDescribedSchema(
    JSONSchema: SupposedJSONSchema | undefined,
    pointer: string,
    parentObjectSchema?: BasicJSONSchema,
) {
    const objectName = getSplitPointer(pointer).pop()!;
    return {
        pointer,
        objectName,
        JSONSchema,
        isRequired: Boolean(parentObjectSchema?.required?.includes(objectName)),
        key: getPathKeysFromPointer(pointer),
    };
}

const getSchemaObjectFromPointer = (schema: BasicJSONSchema, pointer: string) => {
    const splittedPointer = getSplitPointer(pointer);
    const actualPointerKeys = [
        splittedPointer[splittedPointer.length - 2],
        splittedPointer[splittedPointer.length - 1],
    ];
    const currentSchema = get(schema, actualPointerKeys) as SupposedJSONSchema | undefined;

    return getDescribedSchema(currentSchema, pointer, schema);
};

function getChildSchemas(schema: BasicJSONSchema, pointer: string) {
    return (allFields: JSONSubSchema[], key: string) => {
        const currentKeyPointer = concatPointer(concatPointer(pointer, 'properties'), key);

        const schemaObject = getSchemaObjectFromPointer(schema, currentKeyPointer);
        const subSchema = getSubSchema(schemaObject, currentKeyPointer) as JSONSubSchema;
        return allFields.concat(subSchema);
    };
}

export const getSchemaObject = (schema: BasicJSONSchema | null) => {
    try {
        if (!schema || !schema.properties || schema.type !== FieldTypes.Object) {
            return undefined;
        }

        const fieldGroups = getSubSchema(
            getDescribedSchema(schema as ObjectJSONSchema, JSONSchemaRootPointer),
            JSONSchemaRootPointer,
        ) as FieldGroups[];

        // as FieldGroups[] if we got the correct schema(field groups) with title
        // may be remake over time to a more accurate format?

        fieldGroups
            ?.sort((a, b) => toNumber(a?.order) - toNumber(b?.order))
            ?.forEach(({ fields }) =>
                fields?.sort((a, b) => toNumber(a.JSONSchema?.order) - toNumber(b.JSONSchema?.order)),
            );

        return fieldGroups;
    } catch (error) {
        console.warn(error);
    }
};
