import cn from 'classnames';
import React from 'react';
import { Typography } from '@react-md/typography';
import { Preloader } from '@components/preloader';

import styles from './iqt-banner.module.scss';

interface Props {
    isLoading: boolean;
    label: string;
    value?: string | number;
    className?: string;
}

export const IqtBannerEntry: React.FC<Props> = ({ className, isLoading, label, value }) => {
    return (
        <Typography
            type="subtitle-2"
            weight="semi-bold"
            className={cn('flex', 'items-center', className)}
            component={'span'}
        >
            {label}
            {': '}
            {isLoading ? (
                <Preloader size={16} stroke={2} className={cn('rmd-typography--theme-primary', styles.loader)} />
            ) : (
                <span className={cn('rmd-typography--theme-primary', styles.entry)}>{value ? value : '\uff0d'}</span>
            )}
        </Typography>
    );
};
