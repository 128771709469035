import React from 'react';
import cn from 'classnames';
import { useAppSelector } from '@app/hooks';
import { selectHasPricingErrors } from '@modules/pricing';
import { selectWidgetMaterialId, selectWidgetTechnologyId } from '@modules/quotation';
import { ModelNotificationsProps } from './types';
import { ModelNotificationMinBendsRadiuses, ModelNotificationWallThickness } from './components';
import { SHEETMETAL } from '@constants';

import styles from './model-notifications.module.scss';

export const ModelNotifications: React.FC<ModelNotificationsProps> = ({ model }) => {
    const materialId = useAppSelector(selectWidgetMaterialId);
    const technologyId = useAppSelector(selectWidgetTechnologyId);
    const hasCalculationErrors = useAppSelector(selectHasPricingErrors);

    const wallThickness = model.shouldCheckWallThickness && !hasCalculationErrors && (
        <ModelNotificationWallThickness key="wall-thickness" model={model} materialId={materialId} />
    );

    const minRadiuses = model.geometry_type === 'sheetmetal' &&
        technologyId === SHEETMETAL &&
        !hasCalculationErrors && <ModelNotificationMinBendsRadiuses key="min-bends-radiuses" model={model} />;

    const notifications = [wallThickness, minRadiuses].filter(Boolean);

    return notifications.length ? (
        <div className={cn('margin-top', styles.box)}>{notifications.map(notification => notification)}</div>
    ) : null;
};
