import isBoolean from 'lodash/isBoolean';
import { ContentCustomizationResponse } from '@types';
import { FeatureFlagDefaultValue, FeatureFlagsDefaults } from '@components/feature-flags/constants';

export const getFeatureFlag = (content: ContentCustomizationResponse, flagKey: string) => {
    if (Object.keys(content).length) {
        const flags = content['flags'];
        const flag = flags && flags[flagKey];

        if (flag !== undefined) {
            return isBoolean(flag) ? flag : Boolean(JSON.parse(flag));
        }
    }

    return FeatureFlagsDefaults[flagKey] !== undefined ? FeatureFlagsDefaults[flagKey] : FeatureFlagDefaultValue;
};

export const getFeatureFlags = (content: ContentCustomizationResponse, flagKeys: string[]) =>
    flagKeys.map(flagKey => getFeatureFlag(content, flagKey));
