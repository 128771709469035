import schemify from 'url-schemify/url-schemify';

// https://www.cluemediator.com/find-urls-in-string-and-make-a-link-using-javascript

const regex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

// detectURLs("Visit www.cluemediator.com and subscribe us on https://www.cluemediator.com/subscribe for regular updates.")
// Output: ["www.cluemediator.com", "https://www.cluemediator.com/subscribe"]

export function detectLinks(text: string) {
    return text.match(regex);
}

// replaceURLs("Visit www.cluemediator.com and subscribe us on https://www.cluemediator.com/subscribe for regular updates.")
// Output: Visit <a href="http://www.cluemediator.com" target="_blank" rel="noopener noreferrer">www.cluemediator.com</a>
// and subscribe us on <a href="https://www.cluemediator.com/subscribe" target="_blank"
// rel="noopener noreferrer">https://www.cluemediator.com/subscribe</a> for regular updates.

export function wrapLinks(text: string, className = 'rmd-link') {
    return text.replace(regex, function (url) {
        return `<a href="${schemify(url, {
            scheme: 'https',
        })}" target="_blank" rel="noopener noreferrer" class="${className}">${url}</a>`;
    });
}
