import React from 'react';
import cn from 'classnames';

import styles from './progress.module.scss';

export interface ProgressProps {
    value: number;
    radius?: number;
    stroke?: number;
    className?: string;
    showCounter?: boolean;
}

export const Progress: React.FC<ProgressProps> = ({
    value,
    radius = 30,
    stroke = 6,
    className = 'rmd-typography--theme-success',
    showCounter = false,
}) => {
    const width = radius * 2 + stroke;
    const center = width / 2;
    const circumference = radius * 2 * Math.PI;
    const strokeDashoffset = circumference - (value / 100) * circumference;

    return (
        <div
            title={`${value}%`}
            data-progress={value}
            className={cn([styles.box, { [styles.counter]: showCounter }, className])}
            style={{ margin: -stroke / 2 }}
            role="progressbar"
            aria-valuenow={value}
            aria-valuemin={0}
            aria-valuemax={100}
        >
            <svg
                width={width}
                height={width}
                viewBox={`0 0 ${width} ${width}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle className={styles.circle} r={radius} cx={center} cy={center} strokeWidth={stroke} />

                <circle
                    className={styles.progress}
                    r={radius}
                    cx={center}
                    cy={center}
                    strokeWidth={stroke}
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                />
            </svg>
        </div>
    );
};
