import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { UseFormReturn, FieldValues, FieldPath } from 'react-hook-form';
import { useAddMessage } from '@react-md/alert';
import { Alert, AlertHeading } from '@components/alert';
import { getFieldApiErrors } from '@utils';

function renderToastError(field: string, error?: any) {
    return error ? (
        <React.Fragment key={field}>
            <strong>{field}:</strong> {error}
        </React.Fragment>
    ) : (
        ''
    );
}

// hook for rendering form fields errors in toast (for fields that don't have a UI)
export function useFieldsResponseErrors<Fields extends FieldValues>(
    form: UseFormReturn<Fields>,
    fields?: Array<FieldPath<Fields>>,
    onExit?: () => void,
) {
    const addMessage = useAddMessage();
    const { formState, clearErrors } = form;

    useEffect(() => {
        const { errors } = formState;

        if (isEmpty(errors)) return;

        const hasFields = Boolean(fields?.length);

        const messages: (string | React.ReactElement)[] = Object.keys(errors)
            .map(field => {
                if (!hasFields || (hasFields && fields?.includes(field as FieldPath<Fields>))) {
                    const apiError = getFieldApiErrors(errors, field as FieldPath<Fields>);

                    return renderToastError(field, apiError);
                }

                return '';
            })
            .filter(Boolean);

        if (!messages.length) return;

        addMessage({
            messageId: 'fields-response-errors',
            messagePriority: 'replace',
            // disableActionHide: true,
            onExit: () => {
                // remove API errors from state
                if (hasFields) {
                    clearErrors(fields);
                } else {
                    clearErrors();
                }

                onExit && onExit();
            },
            className: 'rmd-toast--alert',
            children: (
                <Alert show type={'error'} variant={'filled'}>
                    <AlertHeading
                        iconType={'error'}
                        text={
                            <React.Fragment
                                children={
                                    messages.length > 1
                                        ? messages.map((message, i) => <div key={i}>{message}</div>)
                                        : messages[0]
                                }
                            />
                        }
                    />
                </Alert>
            ),
        });
    }, [addMessage, formState, clearErrors, fields, onExit]);
}
