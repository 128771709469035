import cn from 'classnames';
import React from 'react';
import { Typography, TypographyProps } from '@react-md/typography';

interface Props extends TypographyProps {
    title: string;
}

export const AuthFormTitle: React.FC<Props> = ({ title, className = 'small-margin-bottom', ...props }) => {
    return (
        <Typography type="headline-3" component="div" {...props} className={cn('fieldset-grid-cell', className)}>
            {title}
        </Typography>
    );
};
