import cn from 'classnames';
import React, { forwardRef, HTMLProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { TextIconSpacing } from '@react-md/icon';
import { Button } from '@react-md/button';
import { Icons } from '@components/icons';
import { composeNodeId } from '@utils';
import { AlertIcon } from './icon';

import styles from './alert.module.scss';

const iconMapping = {
    info: <Icons.Info className={cn('rmd-typography--secondary', styles.icon)} />,
    error: <AlertIcon className={styles.icon} type={'error'} fadeIn={false} />,
    warning: <AlertIcon className={styles.icon} type={'warning'} fadeIn={false} />,
    success: <Icons.CheckCircleThin className={styles.icon} width={20} height={20} />,
    customWarning: <AlertIcon className={styles.icon} type={'warning'} fadeIn={false} />,
};

export interface AlertHeadingProps extends HTMLProps<HTMLDivElement> {
    showIcon?: boolean;
    iconType?: 'info' | 'error' | 'warning' | 'success' | 'customWarning';
    className?: string;
    customIcon?: React.ReactElement;
    label?: string;
    text?: string | React.ReactElement | React.ReactNode;
}

export const AlertHeading: React.FC<AlertHeadingProps> = ({
    showIcon = true,
    label,
    iconType = 'info',
    text,
    className,
    customIcon,
    children,
    ...props
}) => {
    const result = label ? (
        <Typography type="headline-5" className={styles.label} component={'div'}>
            {label}
        </Typography>
    ) : text ? (
        <Typography type="body-1" className={cn('whitespace-pre-line', styles.text)} component={'div'}>
            {text}
        </Typography>
    ) : (
        children
    );

    return (
        <div className={styles.head} {...props}>
            {showIcon ? (
                <TextIconSpacing
                    className={cn(styles.iconOffset, { [styles.iconAlign]: text })}
                    icon={customIcon ?? iconMapping[iconType]}
                    forceIconWrap
                >
                    {result}
                </TextIconSpacing>
            ) : (
                result
            )}
        </div>
    );
};

type AlertCloseProps =
    | {
          dismissible: true;
          closeButtonId: string;
          onClose?: () => void;
          closeLabel?: string;
      }
    | {
          dismissible?: false;
          closeButtonId?: never;
          onClose?: never;
          closeLabel?: never;
      };

export type AlertProps = HTMLProps<HTMLDivElement> &
    AlertCloseProps & {
        show?: boolean;
        className?: string;
        variant?: 'standard' | 'outlined' | 'filled';
        type?: 'info' | 'error' | 'warning' | 'success' | 'customWarning';
        role?: string;
    };

export const Alert = forwardRef<HTMLDivElement, AlertProps>(
    (
        {
            show,
            className,
            children,
            variant = 'standard',
            type = 'info',
            role = 'alert',
            dismissible,
            closeButtonId,
            onClose,
            closeLabel,
            ...props
        }: AlertProps,
        ref,
    ) => {
        const { t } = useTranslation();

        if (!show) return null;

        return (
            <div
                role={role}
                {...props}
                ref={ref}
                className={cn(
                    'base-paddings',
                    styles.box,
                    styles[variant],
                    styles[type],
                    { [styles.dismissible]: dismissible },
                    className,
                )}
            >
                {dismissible && (
                    <Button
                        id={closeButtonId}
                        buttonType="icon"
                        theme="clear"
                        className={cn('rmd-button--icon-flat', styles.close)}
                        onClick={onClose}
                        aria-label={closeLabel || t('buttons.close', 'Close')}
                    >
                        <Icons.Cross />
                    </Button>
                )}
                {children}
            </div>
        );
    },
);
