import cn from 'classnames';
import React from 'react';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { selectClient, selectIsClientLoading } from '@modules/client';
import { selectOrderData, selectIsOrderLoading } from '@modules/order';
import { IqtBannerEntry } from './iqt-banner-entry';
import { IqtBannerLabel } from './iqt-banner-label';
import { composeClientLabel } from './helpers';

import styles from './short-iqt-banner.module.scss';

export const ShortIqtBanner = () => {
    const client = useAppSelector(selectClient);
    const isClientLoading = useAppSelector(selectIsClientLoading);
    const order = useAppSelector(selectOrderData);
    const isOrderLoading = useAppSelector(selectIsOrderLoading);

    const { t } = useTranslation();

    return (
        <div
            className={cn('fade-in', 'rounded-box', 'flex', 'items-center', 'justify-center', styles.box)}
            role="banner"
        >
            <IqtBannerLabel className={cn('rmd-button--uppercase', 'rmd-typography--bold', styles.label)} />

            <IqtBannerEntry
                isLoading={isOrderLoading}
                label={t('labels.orderNumber', 'Order number')}
                value={order?.company_order_id}
                className={'small-margin-right'}
            />

            <IqtBannerEntry
                isLoading={isClientLoading}
                label={t('labels.client', 'Client')}
                value={client && composeClientLabel(client)}
            />
        </div>
    );
};
