import cn from 'classnames';
import React from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FieldPath, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@react-md/form';
import { FormField, NonFieldErrors } from '@components/form-field';
import { SubmitButton } from '@components/submit-button';
import { ChangePasswordForm } from '@types';
import { authActions, selectIsChangePasswordSending } from '@modules/auth';
import { useRedirectUrl, useValidation } from '@hooks';
import { reverse, setApiErrors } from '@utils';
import { createChangePasswordValidation } from '../form-validation';
import { AuthFormTitle } from '../components';
import { passwordRecoveryId } from '../helpers';
import { PasswordRecoveryFormId } from '../constants';
import { useGetResizeRef } from '@utils/parant-site-comunicator';
import { FeatureContent } from '@components/feature-content';

import styles from '../auth-page.module.scss';

export const ChangePasswordPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { token } = useParams<{
        company: string;
        token: string;
    }>();
    const redirectUrl = useRedirectUrl();
    const next = redirectUrl || reverse('signIn');
    const isChangePasswordSending = useAppSelector(selectIsChangePasswordSending);
    const validationSchema = useValidation(createChangePasswordValidation);
    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<ChangePasswordForm>({
        defaultValues: {
            new_password: '',
            confirm_password: '',
        },
        resolver: yupResolver(validationSchema),
    });
    const containerRef = useGetResizeRef();

    const onSubmit: SubmitHandler<ChangePasswordForm> = values => {
        const { new_password } = values;

        dispatch(
            authActions.confirmPasswordRecovery({
                form: { new_password, reset_token: token! },
                onSuccess: () => navigate(next, { replace: true }),
                onError: errors => {
                    setApiErrors(setError, errors as Record<FieldPath<ChangePasswordForm>, string>);
                },
            }),
        );
    };

    const { t } = useTranslation();

    return (
        <div id={passwordRecoveryId('page')} className={styles.layout} ref={containerRef}>
            <div className="container">
                <div className={cn('rounded-box', styles.box)}>
                    <form id={PasswordRecoveryFormId} onSubmit={handleSubmit(onSubmit)}>
                        <div className={cn('fieldset-grid', 'base-paddings')}>
                            <AuthFormTitle title={t('titles.passwordRecovery', 'Password recovery')} />

                            <FormField
                                prefix={PasswordRecoveryFormId}
                                name="new_password"
                                control={control}
                                component={TextField}
                                label={t('labels.newPassword', 'New password')}
                                widgetProps={{
                                    type: 'password',
                                    isRightAddon: false,
                                }}
                            />
                            <FormField
                                prefix={PasswordRecoveryFormId}
                                name="confirm_password"
                                control={control}
                                component={TextField}
                                label={t('labels.confirmNewPassword', 'Confirm new password')}
                                widgetProps={{
                                    type: 'password',
                                    isRightAddon: false,
                                }}
                            />
                            <NonFieldErrors errors={errors} />

                            <div className={cn('fieldset-grid-cell', 'flex')}>
                                <FeatureContent
                                    contentKey={passwordRecoveryId('submit')}
                                    fallback={t('buttons.confirm', 'Confirm')}
                                    wrapper={props => (
                                        <SubmitButton id={props.id!} isSending={isChangePasswordSending}>
                                            {props.children}
                                        </SubmitButton>
                                    )}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
