import { FC } from 'react';
import { Typography } from '@react-md/typography';
import { Button } from '@react-md/button';

import styles from './widget-panel.module.scss';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@app/hooks';
import { appActions } from '@modules/app';

export const WidgetPanel: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleOnClick = () => {
        dispatch(appActions.toggleContentEditableMode());
    };

    return (
        <div className={styles['widget-panel']}>
            <Typography type="headline-6" component={'div'}>
                {t('buttons.editMode', 'Edit mode')}
            </Typography>
            <Button onClick={handleOnClick} className={cn('rmd-button--uppercase', styles['button-close'])}>
                {t('buttons.close', 'Close')}
            </Button>
        </div>
    );
};
