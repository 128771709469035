import React, { SVGProps } from 'react';
import cn from 'classnames';
import { bem } from '@react-md/utils';
const block = bem('rmd-icon');
const SvgArrowTriple = (props: SVGProps<SVGSVGElement>) => {
    props = Object.assign(
        {
            'aria-hidden': true,
        },
        props,
        {
            className: cn(block(), props.className),
        },
    );
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="none"
            viewBox="0 0 16 16"
            role="img"
            {...props}
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 10V3.333M6 5l2-2 2 2M8 10H1.333M3 12l-2-2 2-2M8 10h6.667M13 8l2 2-2 2"
            />
        </svg>
    );
};
export default SvgArrowTriple;
