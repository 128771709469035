import React, { SVGProps } from 'react';
import cn from 'classnames';
import { bem } from '@react-md/utils';
const block = bem('rmd-icon');
const SvgPaperclip = (props: SVGProps<SVGSVGElement>) => {
    props = Object.assign(
        {
            'aria-hidden': true,
        },
        props,
        {
            className: cn(block(), props.className),
        },
    );
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="none"
            viewBox="0 0 16 16"
            role="img"
            {...props}
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="m14.293 7.367-6.127 6.126a4.002 4.002 0 1 1-5.66-5.66l6.127-6.126a2.668 2.668 0 1 1 3.773 3.773l-6.133 6.127A1.334 1.334 0 0 1 4.386 9.72l5.66-5.653"
            />
        </svg>
    );
};
export default SvgPaperclip;
