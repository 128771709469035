import { TFunction } from 'i18next';

export function useFormLabels(t: TFunction): Record<string, string> {
    return {
        email: t('labels.email', 'E-mail'),
        password: t('labels.password', 'Password'),
        first_name: t('labels.firstName', 'First name'),
        last_name: t('labels.lastName', 'Last name'),
        phone: t('labels.phone', 'Phone'),
        position: t('labels.position', 'Position'),
        company_title: t('labels.company', 'Company'),
    };
}
