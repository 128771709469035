import { useAppSelector } from '@app/hooks';
import { SidebarSection } from '@components/sidebar-section';
import { selectInvoiceAppliedDiscount } from '@modules/invoice';
import { renderOrderDiscount } from '@modules/order';

export const InvoiceSidebarDiscount = () => {
    const discount = useAppSelector(selectInvoiceAppliedDiscount);

    if (!discount) {
        return null;
    }

    return <SidebarSection span>{renderOrderDiscount(discount)}</SidebarSection>;
};
