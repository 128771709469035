import Sticky from 'react-stickynode';
import React, { forwardRef } from 'react';
import { PreloaderBox } from '@components/preloader';

export interface StickyColumnWithPreloaderProps extends Sticky.Props {
    stickyParentClassName: string;
    stickyClassName?: string | undefined;
    loading?: boolean;
}

export type StickyRef = Sticky & {
    outerElement: HTMLDivElement;
    innerElement: HTMLDivElement;
};

// todo remove, check CalculationPricing
export const StickyColumnWithPreloader = forwardRef<StickyRef, StickyColumnWithPreloaderProps>(
    ({ stickyParentClassName, stickyClassName, className, loading = false, top = 20, children, ...props }, ref) => {
        return (
            <Sticky
                top={top}
                className={stickyClassName}
                bottomBoundary={'.' + stickyParentClassName}
                // @ts-ignore
                ref={ref}
                {...props}
            >
                <PreloaderBox show={loading} className={className}>
                    {children}
                </PreloaderBox>
            </Sticky>
        );
    },
);
