export const UsaStates = [
    {
        name: 'Alabama',
        code: 'AL',
    },
    {
        name: 'Alaska',
        code: 'AK',
    },
    // {
    //   name: "American Samoa",
    //   code: "AS",
    // },
    {
        name: 'Arizona',
        code: 'AZ',
    },
    {
        name: 'Arkansas',
        code: 'AR',
    },
    {
        name: 'California',
        code: 'CA',
    },
    {
        name: 'Colorado',
        code: 'CO',
    },
    {
        name: 'Connecticut',
        code: 'CT',
    },
    {
        name: 'Delaware',
        code: 'DE',
    },
    {
        name: 'District Of Columbia',
        code: 'DC',
    },
    {
        name: 'Federated States Of Micronesia',
        code: 'FM',
    },
    {
        name: 'Florida',
        code: 'FL',
    },
    {
        name: 'Georgia',
        code: 'GA',
    },
    {
        name: 'Guam',
        code: 'GU',
    },
    {
        name: 'Hawaii',
        code: 'HI',
    },
    {
        name: 'Idaho',
        code: 'ID',
    },
    {
        name: 'Illinois',
        code: 'IL',
    },
    {
        name: 'Indiana',
        code: 'IN',
    },
    {
        name: 'Iowa',
        code: 'IA',
    },
    {
        name: 'Kansas',
        code: 'KS',
    },
    {
        name: 'Kentucky',
        code: 'KY',
    },
    {
        name: 'Louisiana',
        code: 'LA',
    },
    {
        name: 'Maine',
        code: 'ME',
    },
    // {
    //   name: "Marshall Islands",
    //   code: "MH",
    // },
    {
        name: 'Maryland',
        code: 'MD',
    },
    {
        name: 'Massachusetts',
        code: 'MA',
    },
    {
        name: 'Michigan',
        code: 'MI',
    },
    {
        name: 'Minnesota',
        code: 'MN',
    },
    {
        name: 'Mississippi',
        code: 'MS',
    },
    {
        name: 'Missouri',
        code: 'MO',
    },
    {
        name: 'Montana',
        code: 'MT',
    },
    {
        name: 'Nebraska',
        code: 'NE',
    },
    {
        name: 'Nevada',
        code: 'NV',
    },
    {
        name: 'New Hampshire',
        code: 'NH',
    },
    {
        name: 'New Jersey',
        code: 'NJ',
    },
    {
        name: 'New Mexico',
        code: 'NM',
    },
    {
        name: 'New York',
        code: 'NY',
    },
    {
        name: 'North Carolina',
        code: 'NC',
    },
    {
        name: 'North Dakota',
        code: 'ND',
    },
    // {
    //   name: "Northern Mariana Islands",
    //   code: "MP",
    // },
    {
        name: 'Ohio',
        code: 'OH',
    },
    {
        name: 'Oklahoma',
        code: 'OK',
    },
    {
        name: 'Oregon',
        code: 'OR',
    },
    {
        name: 'Palau',
        code: 'PW', // TODO present in DF
    },
    {
        name: 'Pennsylvania',
        code: 'PA',
    },
    {
        name: 'Puerto Rico',
        code: 'PR',
    },
    {
        name: 'Rhode Island',
        code: 'RI',
    },
    {
        name: 'South Carolina',
        code: 'SC',
    },
    {
        name: 'South Dakota',
        code: 'SD',
    },
    {
        name: 'Tennessee',
        code: 'TN',
    },
    {
        name: 'Texas',
        code: 'TX',
    },
    {
        name: 'Utah',
        code: 'UT',
    },
    {
        name: 'Vermont',
        code: 'VT',
    },
    {
        name: 'Virgin Islands',
        code: 'VI',
    },
    {
        name: 'Virginia',
        code: 'VA',
    },
    {
        name: 'Washington',
        code: 'WA',
    },
    {
        name: 'West Virginia',
        code: 'WV',
    },
    {
        name: 'Wisconsin',
        code: 'WI',
    },
    {
        name: 'Wyoming',
        code: 'WY',
    },
] as const;

export const CanadianStates = [
    {
        name: 'Alberta',
        code: 'AB',
    },
    {
        name: 'British Columbia',
        code: 'BC',
    },
    {
        name: 'Manitoba',
        code: 'MB',
    },
    {
        name: 'New Brunswick',
        code: 'NB',
    },
    {
        name: 'Newfoundland and Labrador',
        code: 'NL',
    },
    {
        name: 'Nova Scotia',
        code: 'NS',
    },
    {
        name: 'Northwest Territories',
        code: 'NT',
    },
    {
        name: 'Nunavut',
        code: 'NU',
    },
    {
        name: 'Ontario',
        code: 'ON',
    },
    {
        name: 'Prince Edward Island',
        code: 'PE',
    },
    {
        name: 'Quebec',
        code: 'QC',
    },
    {
        name: 'Saskatchewan',
        code: 'SK',
    },
    {
        name: 'Yukon',
        code: 'YT',
    },
] as const;

export const Countries = [
    {
        name: 'Afghanistan',
        code: 'AF',
    },
    {
        name: 'Albania',
        code: 'AL',
    },
    {
        name: 'Algeria',
        code: 'DZ',
    },
    {
        name: 'Andorra',
        code: 'AD',
    },
    {
        name: 'Argentina',
        code: 'AR',
    },
    {
        name: 'Angola',
        code: 'AO',
    },
    {
        name: 'Anguilla',
        code: 'AI',
    },
    {
        name: 'Antarctica',
        code: 'AQ',
    },
    {
        name: 'Armenia',
        code: 'AM',
    },
    {
        name: 'Aruba',
        code: 'AW',
    },
    {
        name: 'Australia',
        code: 'AU',
    },
    {
        name: 'Austria',
        code: 'AT',
    },
    {
        name: 'Azerbaijan',
        code: 'AZ',
    },
    {
        name: 'Bahrain',
        code: 'BH',
    },
    {
        name: 'Bangladesh',
        code: 'BD',
    },
    {
        name: 'Barbados',
        code: 'BB',
    },
    {
        name: 'Belarus',
        code: 'BY',
    },
    {
        name: 'Belgium',
        code: 'BE',
    },
    {
        name: 'Belize',
        code: 'BZ',
    },
    {
        name: 'Benin',
        code: 'BJ',
    },
    {
        name: 'Bermuda',
        code: 'BM',
    },
    {
        name: 'Bhutan',
        code: 'BT',
    },
    {
        name: 'Bolivia',
        code: 'BO',
    },
    {
        name: 'Bosnia and Herzegovina',
        code: 'BA',
    },
    {
        name: 'Botswana',
        code: 'BW',
    },
    {
        name: 'Brazil',
        code: 'BR',
    },
    {
        name: 'Bulgaria',
        code: 'BG',
    },
    {
        name: 'Burundi',
        code: 'BL',
    },
    {
        name: 'Cabo Verde',
        code: 'CV',
    },
    {
        name: 'Cambodia',
        code: 'KH',
    },
    {
        name: 'Canada',
        code: 'CA',
    },
    {
        name: 'Chile',
        code: 'CL',
    },
    {
        name: 'China',
        code: 'CN',
    },
    {
        name: 'Colombia',
        code: 'CO',
    },
    {
        name: 'Congo',
        code: 'CD',
    },
    {
        name: 'Costa Rica',
        code: 'CR',
    },
    {
        name: 'Croatia',
        code: 'HR',
    },
    {
        name: 'Cuba',
        code: 'CU',
    },
    {
        name: 'Cyprus',
        code: 'CY',
    },
    {
        name: 'Czech Republic',
        code: 'CZ',
    },
    {
        name: 'Denmark',
        code: 'DK',
    },
    {
        name: 'Dominican Republic',
        code: 'DO',
    },
    {
        name: 'Ecuador',
        code: 'EC',
    },
    {
        name: 'Egypt',
        code: 'EG',
    },
    {
        name: 'El Salvador',
        code: 'SV',
    },
    {
        name: 'Estonia',
        code: 'EE',
    },
    {
        name: 'Ethiopia',
        code: 'ET',
    },
    {
        name: 'Finland',
        code: 'FI',
    },
    {
        name: 'France',
        code: 'FR',
    },
    {
        name: 'Georgia',
        code: 'GE',
    },
    {
        name: 'Germany',
        code: 'DE',
    },
    {
        name: 'Gibraltar',
        code: 'GI',
    },
    {
        name: 'Greece',
        code: 'GR',
    },
    {
        name: 'Greenland',
        code: 'GL',
    },
    {
        name: 'Guadeloupe',
        code: 'GP',
    },
    {
        name: 'Guatemala',
        code: 'GT',
    },
    {
        name: 'Haiti',
        code: 'HT',
    },
    {
        name: 'Honduras',
        code: 'HN',
    },
    {
        name: 'Hong Kong',
        code: 'HK',
    },
    {
        name: 'Hungary',
        code: 'HU',
    },
    {
        name: 'Iceland',
        code: 'IS',
    },
    {
        name: 'India',
        code: 'IN',
    },
    {
        name: 'Indonesia',
        code: 'ID',
    },
    {
        name: 'Iran',
        code: 'IR',
    },
    {
        name: 'Iraq',
        code: 'IQ',
    },
    {
        name: 'Ireland',
        code: 'IE',
    },
    {
        name: 'Israel',
        code: 'IL',
    },
    {
        name: 'Italy',
        code: 'IT',
    },
    {
        name: 'Jamaica',
        code: 'JM',
    },
    {
        name: 'Japan',
        code: 'JP',
    },
    {
        name: 'Jordan',
        code: 'JO',
    },
    {
        name: 'Kazakhstan',
        code: 'KZ',
    },
    {
        name: 'Kenya',
        code: 'KE',
    },
    {
        name: 'Korea, Republic of',
        code: 'KR',
    },
    {
        name: 'Kuwait',
        code: 'KW',
    },
    {
        name: 'Kyrgyzstan',
        code: 'KG',
    },
    {
        name: 'Latvia',
        code: 'LV',
    },
    {
        name: 'Lebanon',
        code: 'LB',
    },
    {
        name: 'Lesotho',
        code: 'LS',
    },
    {
        name: 'Liberia',
        code: 'LR',
    },
    {
        name: 'Libya',
        code: 'LY',
    },
    {
        name: 'Liechtenstein',
        code: 'LI',
    },
    {
        name: 'Lithuania',
        code: 'LT',
    },
    {
        name: 'Luxembourg',
        code: 'LU',
    },
    {
        name: 'Macao',
        code: 'MO',
    },
    {
        name: 'Macedonia, The Former Yugoslav Republic of',
        code: 'MK',
    },
    {
        name: 'Malaysia',
        code: 'MY',
    },
    {
        name: 'Maldives',
        code: 'MV',
    },
    {
        name: 'Malta',
        code: 'MT',
    },
    {
        name: 'Mexico',
        code: 'MX',
    },
    {
        name: 'Moldova, Republic of',
        code: 'MD',
    },
    {
        name: 'Monaco',
        code: 'MC',
    },
    {
        name: 'Montenegro',
        code: 'ME',
    },
    {
        name: 'Morocco',
        code: 'MA',
    },
    {
        name: 'Nepal',
        code: 'NP',
    },
    {
        name: 'Netherlands',
        code: 'NL',
    },
    {
        name: 'New Caledonia',
        code: 'NC',
    },
    {
        name: 'New Zealand',
        code: 'NZ',
    },
    {
        name: 'Nicaragua',
        code: 'NI',
    },
    {
        name: 'Nigeria',
        code: 'NG',
    },
    {
        name: 'Norfolk Island',
        code: 'NF',
    },
    {
        name: 'Norway',
        code: 'NO',
    },
    {
        name: 'Oman',
        code: 'OM',
    },
    {
        name: 'Pakistan',
        code: 'PK',
    },
    {
        name: 'Panama',
        code: 'PA',
    },
    {
        name: 'Paraguay',
        code: 'PY',
    },
    {
        name: 'Peru',
        code: 'PE',
    },
    {
        name: 'Philippines',
        code: 'PH',
    },
    {
        name: 'Poland',
        code: 'PL',
    },
    {
        name: 'Portugal',
        code: 'PT',
    },
    {
        name: 'Puerto Rico',
        code: 'PR',
    },
    {
        name: 'Qatar',
        code: 'QA',
    },
    {
        name: 'Romania',
        code: 'RO',
    },
    {
        name: 'Russian Federation',
        code: 'RU',
    },
    {
        name: 'Samoa',
        code: 'WS',
    },
    {
        name: 'San Marino',
        code: 'SM',
    },
    {
        name: 'Saudi Arabia',
        code: 'SA',
    },
    {
        name: 'Serbia',
        code: 'RS',
    },
    {
        name: 'Singapore',
        code: 'SG',
    },
    {
        name: 'Slovakia',
        code: 'SK',
    },
    {
        name: 'Slovenia',
        code: 'SI',
    },
    {
        name: 'Somalia',
        code: 'SO',
    },
    {
        name: 'South Africa',
        code: 'ZA',
    },
    {
        name: 'Spain',
        code: 'ES',
    },
    {
        name: 'Sri Lanka',
        code: 'LK',
    },
    {
        name: 'Sudan',
        code: 'SD',
    },
    {
        name: 'Sweden',
        code: 'SE',
    },
    {
        name: 'Switzerland',
        code: 'CH',
    },
    {
        name: 'Taiwan',
        code: 'TW',
    },
    {
        name: 'Tajikistan',
        code: 'TJ',
    },
    {
        name: 'Thailand',
        code: 'TH',
    },
    {
        name: 'Tonga',
        code: 'TO',
    },
    {
        name: 'Tunisia',
        code: 'TN',
    },
    {
        name: 'Turkey',
        code: 'TR',
    },
    {
        name: 'Turkmenistan',
        code: 'TM',
    },
    {
        name: 'Uganda',
        code: 'UG',
    },
    {
        name: 'Ukraine',
        code: 'UA',
    },
    {
        name: 'United Arab Emirates',
        code: 'AE',
    },
    {
        name: 'United Kingdom',
        code: 'GB',
    },
    {
        name: 'United States',
        code: 'US',
    },
    {
        name: 'Uruguay',
        code: 'UY',
    },
    {
        name: 'Uzbekistan',
        code: 'UZ',
    },
    {
        name: 'Vanuatu',
        code: 'VU',
    },
    {
        name: 'Venezuela, Bolivarian Republic of',
        code: 'VE',
    },
    {
        name: 'Vietnam',
        code: 'VN',
    },
    {
        name: 'Yemen',
        code: 'YE',
    },
    {
        name: 'Zimbabwe',
        code: 'ZW',
    },
] as const;
