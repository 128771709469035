import cn from 'classnames';
import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';
import { Typography, TypographyProps } from '@react-md/typography';

import styles from './model-label.module.scss';

interface ModelLabelProps extends HTMLAttributes<HTMLDivElement> {
    label: ReactNode;
    textProps?: TypographyProps;
    children?: ReactNode;
}

export const ModelLabel = forwardRef<HTMLDivElement, ModelLabelProps>(function ModelLabel(
    { label, children, className, textProps, ...props },
    ref,
) {
    return (
        <div {...props} className={cn(styles.box, className)} ref={ref}>
            <Typography
                type="headline-5"
                component="div"
                {...textProps}
                className={cn(styles.label, textProps?.className)}
            >
                {label}
            </Typography>

            {children}
        </div>
    );
});
