import * as yup from 'yup';
import {
    getBaseStringValidator,
    getEmailValidator,
    getFirstNameValidator,
    getLastNameValidator,
    getPasswordValidator,
    getPhoneValidator,
} from '@utils';

export const createLoginValidation = () => {
    const validator = getBaseStringValidator();

    return yup.object({
        email: getEmailValidator(),
        // ignore length validation for password, because old passwords might not fit the new length limits,
        // we only created them in the new signup form
        password: validator.required(),
    });
};

export const createSignupValidation = ({
    isFirstNameRequired = false,
    isLastNameRequired = false,
    isPhoneRequired = false,
}: {
    isFirstNameRequired: boolean;
    isLastNameRequired: boolean;
    isPhoneRequired: boolean;
}) => {
    const validator = getBaseStringValidator();
    const firstNameValidator = getFirstNameValidator({ required: isFirstNameRequired });
    const lastNameValidator = getLastNameValidator({ required: isLastNameRequired });
    const phoneValidator = getPhoneValidator({ required: isPhoneRequired });

    return yup.object({
        email: getEmailValidator(),
        password: getPasswordValidator(),

        first_name: firstNameValidator,
        last_name: lastNameValidator,
        phone: phoneValidator,
        position: validator.max(255),
        company_title: validator.max(255),
    });
};

export const createOtpValidation = () => {
    return yup.object({
        email: getEmailValidator(),
    });
};

export const createChangePasswordValidation = () => {
    const validator = getPasswordValidator();

    return yup.object({
        new_password: validator,
        confirm_password: validator.test({
            message: {
                key: 'errors.matchPassword',
            },
            test: (value, context) => value === context.parent.new_password,
        }),
    });
};
