import * as Three from 'three';

class JsonLoader {
    load<T>(fileUrl: string) {
        let isCanceled = false;

        const loader = new Three.FileLoader();
        loader.setResponseType('json');

        const onProgress = (event: ProgressEvent) => {
            if (isCanceled) {
                new Error('Loading file aborted.');
            }
        };

        return {
            promise: loader.loadAsync(fileUrl, onProgress) as unknown as Promise<T>,
            cancel() {
                isCanceled = true;
            },
        };
    }
}

export const jsonLoader = new JsonLoader();
