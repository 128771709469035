import React from 'react';
import { TransitionGroup } from 'react-transition-group';
import { Fade, FADE_STAGGER } from '@components/animations';
import { ModelRow, ModelLabel, ModelThumb } from '@modules/models';
import { UploadedModel } from './uploaded-model';
import { UploadedArchiveProps } from '../../types';

import styles from './uploaded-archive.module.scss';

export const UploadedArchive: React.FC<UploadedArchiveProps> = ({ uuid, archive, onDelete, isLastFile }) => {
    return (
        <>
            <ModelRow>
                <ModelThumb size={'small'} isArchive />
                <ModelLabel label={archive.file_name} />
            </ModelRow>

            <TransitionGroup>
                {archive.object_models!.map((model, i) => (
                    <Fade key={model.title} className={styles.file} delay={FADE_STAGGER * i}>
                        <UploadedModel
                            uuid={uuid}
                            className={styles.row}
                            status={model.status}
                            label={model.title}
                            modelId={model.id}
                            error={model.detail}
                            onDelete={onDelete(uuid, model.title, isLastFile)}
                        />
                    </Fade>
                ))}
            </TransitionGroup>
        </>
    );
};
