import { TechnologyMaterial, RootState } from '@types';
import { CUTTER } from '@constants';

export const selectTechnologies = (state: RootState) => state.technologies.results;
export const selectIsLoadingTechnologies = (state: RootState) => state.technologies.isLoading;

export const selectMaterialById = (state: RootState, materialId: number) => {
    const technologies = selectTechnologies(state);

    let material: TechnologyMaterial | undefined = undefined;

    if (!materialId) return material;

    for (const technology of technologies) {
        material = technology.materials.find(material => {
            return material.id === materialId;
        });

        if (material) {
            return material;
        }
    }

    return material;
};

export const selectMaterialBelongsCncCutter = (state: RootState, materialId: number) => {
    const technologies = selectTechnologies(state);
    const technology = technologies.find(technology => technology.id === CUTTER);

    if (!technology?.materials?.length) {
        return false;
    }

    return technology.materials.some(material => material.id === materialId);
};
