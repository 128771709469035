import React, { SVGProps } from 'react';
import cn from 'classnames';
import { bem } from '@react-md/utils';
const block = bem('rmd-icon');
const SvgFaces = (props: SVGProps<SVGSVGElement>) => {
    props = Object.assign(
        {
            'aria-hidden': true,
        },
        props,
        {
            className: cn(block(), props.className),
        },
    );
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="none"
            viewBox="0 0 16 16"
            role="img"
            {...props}
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M13.422 3.773 8.69 1.157a1.194 1.194 0 0 0-1.182 0L2.775 3.773m.16.122 5.163 2.951 5.165-2.95M6.357 15l-.071-5.357a1.157 1.157 0 0 0-.592-1.012L1.033 5.892m-.028.198L1 11.982l5.17 2.943M9.642 15l.071-5.357a1.158 1.158 0 0 1 .592-1.012l4.661-2.739m.029.198L15 11.982l-5.169 2.943"
            />
        </svg>
    );
};
export default SvgFaces;
