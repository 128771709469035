import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    AppThunk,
    DrawingFile,
    Product,
    FindProductPayload,
    AddProductPayload,
    UpdateProductPayload,
    InvalidateLevel,
} from '@types';
import { appActions } from '../app';

export interface DraftedDrawings {
    parentModelId: number;
    drawings: File[];
}

export interface UpdateProductActionPayload extends UpdateProductPayload {
    id: Product['id'];
    setAsFound?: boolean;
}

interface State {
    foundProduct?: Product; // the config we are editing is in the cart
    isAdding: boolean;
    isUpdating: boolean;
    isRemoving: boolean;
    isFinding: boolean;
    isOrderChanged: boolean;
    isApplyToAllActive: boolean;
    removedProductsIds: string[];
    // attached drawings of last model, but not currently uploaded as the product has not yet been created
    draftedDrawings?: DraftedDrawings;
    drawings: Record<string, DrawingFile[]>;
}

const initialState: State = {
    isAdding: false,
    isUpdating: false,
    isRemoving: false,
    isFinding: false,
    isOrderChanged: false,
    isApplyToAllActive: false,
    removedProductsIds: [],
    drawings: {},
};

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<AddProductPayload>) => {
            state.isAdding = true;
        },
        addSuccess: (state, action: PayloadAction<{ product: Product; draftedDrawings?: DraftedDrawings }>) => {
            state.isAdding = false;
            state.isOrderChanged = true;
        },
        addFailure: state => {
            state.isAdding = false;
        },

        find: (state, action: PayloadAction<FindProductPayload>) => {
            state.isFinding = true;
        },
        findSuccess: (state, action: PayloadAction<Product>) => {
            state.isFinding = false;
            state.foundProduct = action.payload;
        },
        findFailure: state => {
            state.isFinding = false;
            state.foundProduct = undefined;
        },

        update: (state, action: PayloadAction<UpdateProductActionPayload>) => {
            state.isUpdating = true;
        },
        updateSuccess: (state, action: PayloadAction<Product | undefined>) => {
            state.isUpdating = false;
            state.isOrderChanged = true;

            if (action.payload) {
                state.foundProduct = action.payload;
            }
        },
        updateFailure: state => {
            state.isUpdating = false;
        },

        remove: (state, action: PayloadAction<Product>) => {
            state.isRemoving = true;
            state.removedProductsIds = state.removedProductsIds.concat(action.payload.id);
        },
        removeSuccess: state => {
            state.isRemoving = false;
            state.isOrderChanged = true;
        },
        removeFailure: state => {
            state.isRemoving = false;
        },

        setDraftedDrawings: (state, action: PayloadAction<DraftedDrawings | undefined>) => {
            state.draftedDrawings = action.payload;
        },
        setDrawings: (state, action: PayloadAction<Record<string, DrawingFile[]>>) => {
            state.drawings = action.payload;
        },

        loadDrawings: (state, action: PayloadAction<string>) => {},
        loadDrawingsSuccess: (
            state,
            action: PayloadAction<{
                productId: string;
                files: DrawingFile[];
            }>,
        ) => {
            state.drawings[action.payload.productId] = action.payload.files;
        },
        loadDrawingsFailure: state => {},

        addDrawing: (
            state,
            action: PayloadAction<{
                productId: string;
                file: File;
            }>,
        ) => {},
        addDrawingSuccess: (
            state,
            action: PayloadAction<{
                productId: string;
                file: DrawingFile;
            }>,
        ) => {
            const existingDrawings = state.drawings[action.payload.productId];
            const newDrawings = existingDrawings ? [...existingDrawings, action.payload.file] : [action.payload.file];

            state.isOrderChanged = true;
            state.drawings[action.payload.productId] = newDrawings;
        },
        addDrawingFailure: state => {},

        removeDrawing: (
            state,
            action: PayloadAction<{
                productId: string;
                fileId: number;
            }>,
        ) => {
            state.isOrderChanged = true;
            state.drawings = Object.entries(state.drawings).reduce((acc, [uuid, drawings]) => {
                if (uuid === action.payload.productId) {
                    const productDrawings = drawings.filter(drawing => drawing.id !== action.payload.fileId);

                    if (!productDrawings.length) {
                        return acc;
                    }

                    return { ...acc, [uuid]: productDrawings };
                }

                return { ...acc, [uuid]: drawings };
            }, {});
        },
        removeDrawingSuccess: state => {},
        removeDrawingFailure: state => {},

        runApplyToAllModels: state => {
            state.isApplyToAllActive = true;
        },
        finishApplyToAllModels: state => {
            state.isApplyToAllActive = false;
        },
    },
    extraReducers: builder => {
        builder.addCase(appActions.invalidateStore, (state, { payload }) =>
            payload.purge >= InvalidateLevel.Order ? { ...initialState } : state,
        );
    },
});

export const productActions = productSlice.actions;
