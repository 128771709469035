import { AppThunk, RootState } from '@types';
import { openAuthPopup } from '../auth';
import { orderActions, selectOrderLoaded } from '../order';
import {
    selectCurrentProduct,
    selectIsSameConfigAlreadyInCart,
    selectWidgetCount,
    selectWidgetMaterialId,
    selectWidgetModelId,
    selectWidgetPriceConfig,
} from '../quotation';
import { selectUserPermissions } from '../user';
import { productActions } from './slice';
import { selectFoundProduct } from './selectors';

export const confirmProduct = (): AppThunk => (dispatch, getState) => {
    const state = getState();

    const parentModelId = selectWidgetModelId(state);
    const materialId = selectWidgetMaterialId(state);
    const config = selectWidgetPriceConfig(state);
    const count = selectWidgetCount(state);

    const orderExists = selectOrderLoaded(state);
    const currentProduct = selectCurrentProduct(state);
    const foundProduct = selectFoundProduct(state);

    const { isConfirmProductAllowed } = selectUserPermissions(state);

    const callback = (state: RootState) => {
        const { isConfirmProductAllowed } = selectUserPermissions(state);

        // No current product === add new
        // No current product, found product exists === increment existing
        // Current product exists, found product exists and they are equal === update count field if changed
        // Current product exists, found product exists and they are not equal === isConfirmDisabled
        // Current product exists, no found product === update any field

        if (!isConfirmProductAllowed || !config || !materialId || !parentModelId) {
            return;
        }

        if (!orderExists) {
            dispatch(orderActions.create());
        }

        if (!currentProduct) {
            // add new product
            dispatch(
                productActions.add({
                    config,
                    material_id: materialId,
                    model_id: parentModelId,
                    count,
                }),
            );
        } else if (foundProduct) {
            // config is already in order, update the quantity of product if it is current
            if (currentProduct.id === foundProduct.id) {
                if (count !== currentProduct.count) {
                    dispatch(
                        productActions.update({
                            id: currentProduct.id,
                            count,
                        }),
                    );
                }
            }
        } else {
            // edit current product
            dispatch(
                productActions.update({
                    id: currentProduct.id,
                    material_id: materialId,
                    model_id: parentModelId,
                    config,
                    count: count !== currentProduct.count ? count : undefined,
                    setAsFound: true, // todo request meta
                }),
            );
        }
    };

    if (!isConfirmProductAllowed) {
        dispatch(openAuthPopup({ onClose: callback, isClosable: true }));
        return;
    }

    callback(state);
};
