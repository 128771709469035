import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, Preselection, InvalidateLevel } from '@types';
import { appActions } from '../app';
import { selectPreselectionModeOn } from '../quotation';
import { selectIsPollingPreselectionActive, selectModelsIdsForPreselection } from './selectors';

interface State {
    data: Record<string, Preselection>;
    isPollingActive: boolean;
}

const initialState: State = {
    data: {},
    isPollingActive: false,
};

export const preselectionSlice = createSlice({
    name: 'preselection',
    initialState,
    reducers: {
        pollingStarted: state => {
            state.isPollingActive = true;
        },
        pollingStopped: state => {
            state.isPollingActive = false;
        },

        load: (state, action: PayloadAction<number[]>) => {},
        loadSuccess: (state, action: PayloadAction<Record<string, Preselection>>) => {
            state.data = {
                ...state.data,
                ...action.payload,
            };
        },
        loadFailure: state => {},
        readyReceived: (state, action: PayloadAction<Array<[string, Preselection]>>) => {},
    },
    extraReducers: builder => {
        builder.addCase(appActions.invalidateStore, (state, { payload }) =>
            payload.purge >= InvalidateLevel.Models ? { ...initialState } : state,
        );
    },
});

export const preselectionActions = preselectionSlice.actions;

export const startPreselectionPolling = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const preselectionModeOn = selectPreselectionModeOn(state);
    const isActive = selectIsPollingPreselectionActive(state);
    const ids = selectModelsIdsForPreselection(state);

    if (!preselectionModeOn || isActive || !ids.length) return;

    dispatch(preselectionActions.pollingStarted());
};
