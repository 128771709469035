import cn from 'classnames';
import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from '@react-md/link';
import { Typography, TypographyProps } from '@react-md/typography';
import { FeatureContent } from '@components/feature-content';
import { SidebarSection } from '@components/sidebar-section';
import { composeNodeId } from '@utils';

import styles from './ask-questions.module.scss';

type AskQuestionsProps = TypographyProps &
    (
        | {
              rootPrefix: string;
              email: string;
              action?: never;
          }
        | {
              rootPrefix: string;
              email?: never;
              action: ReactNode;
          }
    );

export const AskQuestions: React.FC<AskQuestionsProps> = ({ rootPrefix, action, email, ...rest }) => {
    const { t } = useTranslation();

    const questionId = composeNodeId(rootPrefix, 'ask_questions', 'question');
    const linkId = composeNodeId(rootPrefix, 'ask_questions', 'link');
    const buttonText = t('buttons.askForHelp', 'Ask for help');
    const questionText = t('messages.haveAnyQuestions', 'Have any questions?');

    return (
        <Typography className={styles.box} type="body-1" component="div" align="center" {...rest}>
            <span id={questionId}>
                {/* @ts-ignore we can't calculate Id type right here because of dynamic prefix */}
                <FeatureContent contentKey={questionId} fallback={questionText} />
            </span>
            {action ? (
                action
            ) : (
                <Link id={linkId} className="rmd-typography--semi-bold" href={`mailto:${email}`}>
                    <FeatureContent
                        // @ts-ignore we can't calculate Id type right here because of dynamic prefix
                        contentKey={linkId}
                        fallback={buttonText}
                    />
                </Link>
            )}
        </Typography>
    );
};

export const SidebarAskForHelp: React.FC<AskQuestionsProps> = props => {
    return (
        <SidebarSection span>
            <AskQuestions {...props} />
        </SidebarSection>
    );
};
