import { OrderService } from '@services/order.service';
import { createAppAsyncThunk } from '@app/helpers';
import { buildOrderUrl } from '@utils/routes';

export const repeatOrder = createAppAsyncThunk('orders/repeatOrder', async (orderId: number) => {
    try {
        const {
            data: { id },
        } = await OrderService.init().repeatOrder(String(orderId));
        const newOrderPath = buildOrderUrl({ orderId: id });
        return newOrderPath;
    } catch (error) {
        console.error('error:', error);
        throw error;
    }
});
