import axios from 'axios';

export const baseURL: string = (() => {
    const environment = process.env.NODE_ENV;

    switch (environment) {
        case 'test':
            return window.location.origin;
        default:
            return process.env.REACT_APP_DF_API_BASE_URL;
    }
})();

export const axiosInstance = axios.create({
    baseURL,
    headers: {
        accept: 'application/json',
    },
});
