import cn from 'classnames';
import React, { forwardRef, HTMLAttributes } from 'react';
import { ModelLabel } from '../model-label';
import { ModelThumb } from '../model-thumb';

import styles from './model-cell.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
    label?: string;
    thumb?: string;
}

export const ModelCell = forwardRef<HTMLDivElement, Props>(function ModelRow(
    { label = '-', thumb, children, className, ...props },
    ref,
) {
    return (
        <div {...props} className={cn(styles.box, className)} ref={ref}>
            <ModelThumb thumb={thumb} />
            <ModelLabel label={label} />
            {children}
        </div>
    );
});
