import * as yup from 'yup';

yup.addMethod<yup.StringSchema>(yup.string, 'emptyAsUndefined', function () {
    return this.transform(value => (value ? value : undefined));
});

yup.addMethod<yup.NumberSchema>(yup.number, 'emptyAsUndefined', function () {
    return this.transform((value, originalValue) => (String(originalValue)?.trim() ? value : undefined));
});

yup.addMethod<yup.NumberSchema>(yup.number, 'stripZero', function () {
    return this.transform((value, originalValue) => (value === 0 ? undefined : value));
});
