import { createSelector } from '@reduxjs/toolkit';
import { enhancedApi as api } from '@services/df/clients';
import { RootState } from '@types';

export const selectClientId = (state: RootState) => state.client.clientId;
export const clientSelector = createSelector(selectClientId, id =>
    api.endpoints.clientsRetrieve.select({
        id: id!,
    }),
);
export const selectClient = createSelector(
    (state: RootState) => state,
    clientSelector,
    (state, selector) => selector(state).data,
);
export const selectIsClientLoading = createSelector(
    (state: RootState) => state,
    clientSelector,
    (state, selector) => selector(state).isLoading,
);
