import React from 'react';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { Pricing } from '@types';
import { FeatureContent } from '@components/feature-content';
import { FeatureKeys as FF, FeatureOn, FeatureToggle } from '@components/feature-flags';
import { PricingList, PricingRecord } from '@components/pricing-list';
import { SidebarSection } from '@components/sidebar-section';
import { TbdMessage, TbdSidebarContainer } from '@components/tbd';
import { selectAppSettings } from '@modules/app';
import { selectPricingData, selectOutsideIqtPricingTbd } from '@modules/pricing';
import { calculationSectionId } from '../helpers';

interface Props {
    // sometimes we aren't ready to show prices
    show: boolean;
}

export const CalculationPricing: React.FC<Props> = ({ show }) => {
    const settings = useAppSelector(selectAppSettings);
    const data = useAppSelector(selectPricingData);
    const tbd = useAppSelector(selectOutsideIqtPricingTbd);

    const presentData = Boolean(show && data);
    const pricing = presentData ? data! : ({} as Partial<Pricing>);
    const hasTax = Boolean(pricing.tax);

    const { t } = useTranslation();

    return tbd ? (
        <TbdSidebarContainer>
            <TbdMessage>{tbd.tbd_reason_message}</TbdMessage>
        </TbdSidebarContainer>
    ) : (
        <SidebarSection divider span>
            <Typography id={calculationSectionId('pricing_header')} type="headline-5">
                <FeatureContent
                    contentKey={calculationSectionId('pricing_header')}
                    fallback={t('subtitles.calcResult', 'Resulting price')}
                />
            </Typography>

            {presentData && !hasTax && (
                <Typography
                    id={calculationSectionId('tax_note')}
                    type="subtitle-2"
                    weight="semi-bold"
                    component="div"
                    className="micro-margin-top"
                >
                    <FeatureContent
                        contentKey={calculationSectionId('tax_note')}
                        fallback={t('labels.calcVatExcluded', 'VAT excluded')}
                    />
                </Typography>
            )}

            <PricingList className="margin-top">
                <FeatureToggle flagKey={FF.CartPage.PricingPerPartShown}>
                    <FeatureOn>
                        <PricingRecord
                            label={
                                <FeatureContent
                                    contentKey={calculationSectionId('per_part')}
                                    fallback={t('labels.itemManufacturing', 'Per part')}
                                />
                            }
                            value={pricing.naked_price}
                        />
                    </FeatureOn>
                </FeatureToggle>
                <FeatureToggle flagKey={FF.CartPage.PricingPostProductionShown}>
                    <FeatureOn>
                        <PricingRecord
                            label={
                                <FeatureContent
                                    contentKey={calculationSectionId('post_production')}
                                    fallback={t('labels.postProductionPrice', 'Post production per part')}
                                />
                            }
                            value={pricing.post_production_price}
                        />
                    </FeatureOn>
                </FeatureToggle>
                <FeatureToggle flagKey={FF.CartPage.PricingProductionPriorityShown}>
                    <FeatureOn>
                        <PricingRecord
                            label={
                                <FeatureContent
                                    contentKey={calculationSectionId('production_priority')}
                                    fallback={t('labels.priorityPrice', 'Production priority')}
                                />
                            }
                            value={pricing.priority_price}
                        />
                    </FeatureOn>
                </FeatureToggle>
                <PricingRecord
                    label={
                        <FeatureContent
                            contentKey={calculationSectionId('quantity')}
                            fallback={t('labels.quantity', 'Quantity')}
                        />
                    }
                    value={pricing.count}
                    isCurrency={false}
                />
                <FeatureToggle flagKey={FF.CartPage.PricingStartupCostShown}>
                    <FeatureOn>
                        <PricingRecord
                            label={
                                <FeatureContent
                                    contentKey={calculationSectionId('startup_cost')}
                                    fallback={t('labels.startupCost', 'Startup cost')}
                                />
                            }
                            value={pricing.startup_cost}
                        />
                    </FeatureOn>
                </FeatureToggle>
                <FeatureToggle flagKey={FF.CartPage.PricingSubtotalShown}>
                    <FeatureOn>
                        <PricingRecord
                            label={
                                <FeatureContent
                                    contentKey={calculationSectionId('subtotal')}
                                    fallback={t('labels.subtotal', 'Subtotal')}
                                />
                            }
                            value={pricing.subtotal}
                        />
                    </FeatureOn>
                </FeatureToggle>
            </PricingList>

            <PricingList divider>
                {!!pricing.discount_value && (
                    <PricingRecord
                        label={t('labels.discount', 'Discount')}
                        value={pricing.discount_value}
                        percent={pricing.discount_percent}
                        sign="-"
                    />
                )}

                {hasTax && (
                    <PricingRecord label={settings.tax_name} value={pricing.tax} percent={pricing.tax_percent} />
                )}

                <PricingRecord
                    label={
                        <FeatureContent
                            contentKey={calculationSectionId('total')}
                            fallback={t('labels.total', 'Total')}
                        />
                    }
                    value={pricing.total}
                    // keyType="headline-6"
                    valueType="headline-6"
                />
            </PricingList>
        </SidebarSection>
    );
};
