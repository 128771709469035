import { v4 as uuidv4 } from 'uuid';
import { FileRejection } from 'react-dropzone';
import { UploadFileRejectType } from '@types';

export const formatDropzoneRejections = (fileRejections: FileRejection[]): UploadFileRejectType[] => {
    return fileRejections.map(file => {
        const errorCodes = file.errors.map(error => error.code);
        return {
            uuid: uuidv4(),
            fileName: file.file.name,
            errorCodes,
            codeValues: errorCodes.reduce((acc, code) => {
                const error = file.errors.find(error => error.code === code)!;

                if (error.values) {
                    return {
                        ...acc,
                        [code]: error.values,
                    };
                }

                return acc;
            }, {}),
        };
    });
};
