import pickBy from 'lodash/pickBy';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';

export type PdfDocumentParams = Record<string, number | string | undefined>;

export const formatPdfDocumentUrl = (pdfUrl: string, params: PdfDocumentParams) => {
    const link = new URL(pdfUrl);
    params = pickBy(params, value => !isNil(value));
    const searchParams = new URLSearchParams({
        ...Object.fromEntries(new URLSearchParams(link.search)),
        ...mapValues(params, String),
    }).toString();
    return searchParams ? link + '?' + searchParams : pdfUrl;
};
