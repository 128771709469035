import { FieldValues, FieldPath, Path, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { UsaStates, CanadianStates } from '@constants';

const usaStates = UsaStates.slice();
const canadianStates = CanadianStates.slice();

export const getStates = (country: string | null) => {
    switch (country) {
        case 'CA':
            return canadianStates;
        case 'US':
            return usaStates;
        default:
            return [];
    }
};

export const getInitialState = (country: string | null): string => {
    const states = getStates(country);
    const hasStates = Boolean(states.length);
    return hasStates ? states[0].code : '';
};

export const getValidState = (country: string | null, suspiciousState: string): string => {
    const states = getStates(country);
    const stateObj = (states as ReadonlyArray<{ code: string }>).find(stateObj => stateObj.code === suspiciousState);

    if (stateObj) {
        return suspiciousState;
    }

    return getInitialState(country);
};
