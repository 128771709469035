import { TransitionStatus, EXITING, EXITED } from 'react-transition-group/Transition';
import { FADE_DURATION, FADE_EASING } from './constants';
import { AnimationProps } from './types';

export const getInlineStyles = ({
    delay,
    duration = FADE_DURATION,
    easing = FADE_EASING,
    status,
    style = {},
}: AnimationProps & { status: TransitionStatus }) => ({
    ...style,
    transitionDelay: status === EXITING || status === EXITED ? '0' : `${delay}ms`,
    transitionDuration: `${duration}ms`,
    transitionTimingFunction: easing,
});

export const getTimeoutValue = ({ delay = 0, duration = FADE_DURATION, enter = true } = {}) =>
    enter ? delay + duration : duration;
