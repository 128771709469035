import { startAppListening } from '@app/listenerMiddleware';
import { InvalidateLevel } from '@types';
import { appActions } from '../app';
import { selectIqtModeOn } from '../user';
import { selectClientId } from './selectors';

startAppListening({
    predicate: (action, currentState, originalState) => {
        const prev = selectClientId(originalState);
        const next = selectClientId(currentState);
        return selectIqtModeOn(currentState) && prev !== next;
    },
    effect: async (action, { dispatch }) => {
        dispatch(
            appActions.invalidateStore({
                purge: InvalidateLevel.Order,
            }),
        );
    },
});
