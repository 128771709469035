import cn from 'classnames';
import { withBaseErrorBoundary } from '@components/error-boundaries';
import { useGetResizeRef } from '@utils/parant-site-comunicator';
import { OtpFormBody } from './otp-form-body';
import { OtpFormFooter } from './otp-form-footer';
import { otpId } from '../helpers';

import styles from '../auth-page.module.scss';

export const OtpPage = withBaseErrorBoundary(() => {
    const containerRef = useGetResizeRef();

    return (
        <div id={otpId('page')} className={styles.layout} ref={containerRef}>
            <div className="container">
                <div className={cn('rounded-box', styles.box)}>
                    <OtpFormBody />
                    <OtpFormFooter type="page" />
                </div>
            </div>
        </div>
    );
});
