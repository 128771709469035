import isUndefined from 'lodash/isUndefined';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { Divider } from '@react-md/divider';
import { Checkbox } from '@react-md/form';
import { Collapse } from '@react-md/transition';
import { ColorFieldsContainer } from '@components/color-field';
import { FeatureContent } from '@components/feature-content';
import { Price } from '@components/price';
import { PostProductionOption, PostProductionPricing, PriceConfigFormPostProduction as Value } from '@types';
import { selectPostProductionPricingData } from '@modules/post-production';
import { composeNodeId } from '@utils';
import { CountInput } from '@components/count-input';
import { PostProductionProps } from '../types';
import {
    addPostProduction,
    addOrUpdatePostProduction,
    findPostProduction,
    groupPostProduction,
    removePostProduction,
} from './helpers';
import { priceConfigId } from '../../helpers';

import styles from './post-production.module.scss';

const renderGroupLabel = ({ title, isFirst }: { title: string; isFirst: boolean }) => (
    <>
        {!isFirst && <Divider className="margin-top" />}
        <Typography type="headline-5" className={styles.group}>
            {title}
        </Typography>
    </>
);

const renderLabel = ({ title, note_for_user }: PostProductionOption) => (
    <>
        {title}
        {note_for_user && (
            <Typography type="subtitle-2" weight="semi-bold" className={styles.note} component={'span'}>
                {note_for_user}
            </Typography>
        )}
    </>
);

const renderPrice = (pricing: PostProductionPricing[string], countable: boolean) => {
    return isUndefined(pricing) ? null : <Price>{countable ? pricing?.price_per_item : pricing?.price}</Price>;
};

const minimalPrice = ({ pricing, text }: { pricing: PostProductionPricing[string]; text: string }) =>
    pricing?.minimal_price && pricing?.minimal_price > pricing?.price_per_item ? (
        <Typography type="subtitle-2" component={'div'} className={cn(styles.note, styles.subprice)}>
            <FeatureContent contentKey={priceConfigId('post_production', 'minimal_price')} fallback={text} />
            <Price precision={0}>{pricing.minimal_price}</Price>
        </Typography>
    ) : null;

const setupCost = ({ pricing, text }: { pricing: PostProductionPricing[string]; text: string }) =>
    pricing?.setup_cost ? (
        <Typography type="subtitle-2" component={'div'} className={cn(styles.note, styles.subprice)}>
            <FeatureContent contentKey={priceConfigId('post_production', 'setup_cost')} fallback={text} />
            <Price precision={0}>{pricing.setup_cost}</Price>
        </Typography>
    ) : null;

export const PostProduction: React.FC<PostProductionProps> = ({ fieldName, data, priceConfigValue, onChange }) => {
    const handleCheckboxChange = useCallback(
        ({ target: { value: uuid, checked } }) => {
            const newValue = checked
                ? addPostProduction(data, priceConfigValue, { uuid })
                : removePostProduction(priceConfigValue, uuid);

            return onChange(fieldName, newValue);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onChange],
    );

    const handleCounterChange = useCallback(
        (quantity, _, { dataset }) => {
            const uuid = dataset.uuid;
            const newValue =
                quantity > 0
                    ? addOrUpdatePostProduction(data, priceConfigValue, { uuid, quantity })
                    : removePostProduction(priceConfigValue, uuid);

            return onChange(fieldName, newValue);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onChange],
    );

    const handlePostProductionOptionChange = (value: Value) =>
        onChange(fieldName, addOrUpdatePostProduction(data, priceConfigValue, value));

    const postProductionPricing = useAppSelector(selectPostProductionPricingData);
    const groups = groupPostProduction(data);

    const { t } = useTranslation();

    return (
        <div className={cn('margin-top', styles.box)}>
            {groups.map(({ title, list }, index) => {
                return (
                    <React.Fragment key={title}>
                        {title && renderGroupLabel({ title, isFirst: index === 0 })}

                        {list.map((pp, i) => {
                            const { uuid, countable, colors } = pp;
                            const nodeId = priceConfigId(fieldName, 'field', uuid);
                            const currentValue = findPostProduction(priceConfigValue, uuid);
                            const isSelected = !!currentValue;
                            const postProductionPricingItem = postProductionPricing[uuid];

                            return (
                                <div className={styles.option} key={uuid}>
                                    <div className={styles.widget}>
                                        {countable ? (
                                            <div className={styles.counterHolder}>
                                                <CountInput
                                                    id={nodeId}
                                                    data-uuid={uuid}
                                                    name={composeNodeId(fieldName, uuid)}
                                                    value={currentValue?.quantity || 0}
                                                    precision={0}
                                                    min={0}
                                                    max={1000}
                                                    onChange={handleCounterChange}
                                                    className={styles.counter}
                                                />

                                                <Typography
                                                    type="headline-6"
                                                    className={cn('small-margin-left', styles.counterLabel)}
                                                    component={'label'}
                                                    {...{ htmlFor: nodeId }}
                                                >
                                                    {renderLabel(pp)}
                                                </Typography>
                                            </div>
                                        ) : (
                                            <Checkbox
                                                id={nodeId}
                                                name={composeNodeId(fieldName, uuid)}
                                                label={renderLabel(pp)}
                                                onChange={handleCheckboxChange}
                                                checked={isSelected}
                                                value={uuid}
                                                disableIconOverlay
                                                disableProgrammaticRipple
                                                disableRipple
                                            />
                                        )}
                                        <div className={styles.pricing}>
                                            {renderPrice(postProductionPricingItem, countable)}
                                            {minimalPrice({
                                                pricing: postProductionPricingItem,
                                                text: t('labels.minPrice', 'Min. price'),
                                            })}
                                            {setupCost({
                                                pricing: postProductionPricingItem,
                                                text: t('labels.setupCost', 'Excl. setup cost of'),
                                            })}
                                        </div>
                                    </div>

                                    {/* post-production options, currently only colors may exist, if others appear, put it in a component */}
                                    {colors && (
                                        <Collapse
                                            collapsed={!isSelected}
                                            timeout={{
                                                enter: 150,
                                                exit: 150,
                                            }}
                                        >
                                            <ColorFieldsContainer
                                                name={composeNodeId(fieldName, 'color', uuid)}
                                                onChange={({ target: { value: colorUuid, checked } }) =>
                                                    handlePostProductionOptionChange({
                                                        uuid,
                                                        option_uuid: colorUuid,
                                                    })
                                                }
                                                className={styles.color}
                                                options={colors.map(({ uuid: colorUuid, label, color }) => ({
                                                    id: priceConfigId(fieldName, 'color_field', colorUuid),
                                                    value: colorUuid,
                                                    checked: currentValue?.option_uuid === colorUuid,
                                                    label,
                                                    color,
                                                }))}
                                            />
                                        </Collapse>
                                    )}
                                </div>
                            );
                        })}
                    </React.Fragment>
                );
            })}
        </div>
    );
};
