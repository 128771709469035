import isUndefined from 'lodash/isUndefined';
import { GUI } from 'lil-gui';
import { WidgetCustomizationValue } from '@types';
import {
    convertToUnits,
    setRgbColorCustomProperty,
    setCustomProperty,
    GuiInput,
    GuiInputCategory,
    GuiInputType,
    GuiSlider,
    ThemeConfigGuiOptions,
} from './helpers';
import { guiInputsHelper } from './gui-inputs-helper';
import { THEME_CONFIG_INPUTS } from './constants';

import styles from './theme.module.scss';

type Config = { [key: string]: string | number };
type Input = GuiInput | GuiSlider;
type Inputs = Input[];

type CreateMessageProps = {
    message: string;
    classes: string[];
};

function createMessage({ message, classes }: CreateMessageProps) {
    const messageContainer = document.createElement('p');
    messageContainer.innerHTML = message;

    classes && messageContainer.classList.add(...classes);

    return messageContainer;
}

type CreateButtonProps = {
    label: string;
    classes?: string[];
    attributes?: { [key: string]: string };
    onClick: () => void;
};

function createButton({ label, classes, attributes, onClick }: CreateButtonProps): HTMLButtonElement {
    const button = document.createElement('button');
    button.innerHTML = label;

    attributes &&
        Object.entries(attributes).forEach(([key, value]) => {
            button.setAttribute(key, value);
        });

    classes && button.classList.add(...classes);

    button.addEventListener('click', () => onClick());

    return button;
}

type CreatePanelProps = {
    gui: GUI;
};

function createTopPanel({ gui }: CreatePanelProps) {
    const themeConfigPanel = gui.domElement;

    const panel = document.createElement('div');
    panel.classList.add(styles.topButtonsContainer);

    const styleGuideLink = createButton({
        label: 'The Styling Guide',
        classes: [styles.controlPanelStyleGuidLink],
        attributes: { title: 'Read the Guide' },
        onClick: () =>
            window.open(
                'https://docs.google.com/document/d/1TJ5NbpXIl0zvWj5Cs71Nxzf7vMBAg9sEEgvkaDlC_Dg/edit#heading=h.7cc7esa98474',
            ),
    });

    function handleMovePanel() {
        if (themeConfigPanel) {
            if (themeConfigPanel.classList.contains(styles.left)) {
                themeConfigPanel.classList.remove(styles.left);
                themeConfigPanel.classList.add(styles.right);
            } else {
                themeConfigPanel.classList.remove(styles.right);
                themeConfigPanel.classList.add(styles.left);
            }
        }
    }

    function createMoveButton() {
        const leftArrow = document.createElement('div');
        leftArrow.classList.add(styles.arrow, styles.leftArrow);
        leftArrow.innerHTML = '&lArr;';
        const rightArrow = document.createElement('div');
        rightArrow.classList.add(styles.arrow, styles.rightArrow);
        rightArrow.innerHTML = '&rArr;';

        const moveButton = createButton({
            label: 'Move Panel',
            classes: [styles.controlPanelButton, styles.controlPanelMoveButton],
            attributes: { title: 'Move panel to opposite side' },
            onClick: () => handleMovePanel(),
        });

        moveButton.insertBefore(leftArrow, moveButton.firstChild);
        moveButton.appendChild(rightArrow);

        return moveButton;
    }

    const moveButton = createMoveButton();

    panel.append(styleGuideLink);
    panel.append(moveButton);

    return panel;
}

function createBottomPanel({ gui }: CreatePanelProps) {
    const panel = document.createElement('div');
    panel.classList.add(styles.bottomButtonsContainer);

    return panel;
}

function createUndoButton(input: Input) {
    return createButton({
        label: 'Undo',
        classes: [styles.controlPanelButton, styles.controlPanelInputButton],
        attributes: { title: 'Undo Change' },
        onClick: () => input.controller?.reset(),
    });
}

function createResetButton(input: Input) {
    return createButton({
        label: 'Reset',
        classes: [styles.controlPanelButton, styles.controlPanelInputButton],
        attributes: { title: 'Reset to Default' },
        onClick: () => input.controller?.setValue(input.defaultValue),
    });
}

function createInputControls(input: Input) {
    const undoButton = createUndoButton(input);
    const resetButton = createResetButton(input);
    input.controller!.domElement.append(undoButton);
    input.controller!.domElement.append(resetButton);
}

function assignController(folder: GUI, input: Input, config: Config) {
    switch (input.type) {
        case GuiInputType.PALETTE: {
            return folder.addColor(config, input.varName);
        }
        case GuiInputType.TEXT: {
            return folder.add(config, input.varName);
        }
        case GuiInputType.SLIDER: {
            return folder.add(
                config,
                input.varName,
                (input as GuiSlider).minValue,
                (input as GuiSlider).maxValue,
                (input as GuiSlider).step,
            );
        }
        default: {
            const _exhaustiveCheck: never = input.type;
            return _exhaustiveCheck;
        }
    }
}

type SetupInputsOptions = {
    gui: GUI;
    inputs: Inputs;
    onChange: (params: { input: Input; value: string | number }) => void;
};

function setupInputs({ gui, inputs, onChange }: SetupInputsOptions) {
    const config: Config = {};

    const surfaceFolder = gui.addFolder('Surface');
    const borderFolder = gui.addFolder('Border');
    const primaryFolder = gui.addFolder('Brand and services UI colors');
    const typographyFolder = gui.addFolder('Typography');
    const miscellaneousFolder = gui.addFolder('Miscellaneous');
    const foldersMap = {
        [GuiInputCategory.SURFACE]: surfaceFolder,
        [GuiInputCategory.PRIMARY]: primaryFolder,
        [GuiInputCategory.BORDER]: borderFolder,
        [GuiInputCategory.TYPOGRAPHY]: typographyFolder,
        [GuiInputCategory.MISCELLANEOUS]: miscellaneousFolder,
        [GuiInputCategory.NONE]: gui,
    };

    inputs.forEach(input => {
        config[input.varName] = input.startValue!;

        input.folder = foldersMap[input.category];
        input.controller = assignController(input.folder, input, config);
        input.controller
            .name(input.label)
            .onChange((value: string | number) => {
                onChange({ input, value });
            })
            .onFinishChange((value: string | number) => {
                if (input.type === GuiInputType.PALETTE) {
                    setRgbColorCustomProperty(input.varName, value.toString());
                }
            });

        createInputControls(input);

        if (input.disabled) {
            input.controller.disable();
            input.controller.domElement.title = 'Not currently used on the site'; // todo blocked by pointer-events styles
        }
    });
}

export function createThemeConfigsGui({ onSave, onClose, customTheme }: ThemeConfigGuiOptions) {
    const gui = new GUI({ width: 400, title: 'Configs' });
    const themeConfigPanel = gui.domElement;
    themeConfigPanel.classList.add(styles.right);

    const updateMessage = createMessage({
        message: 'Press "Save Changes" button to save current changes. Page reload may be required to apply them all.',
        classes: [styles.message, styles.updateMessage, 'hidden'],
    });

    const handleChange: SetupInputsOptions['onChange'] = ({ input, value }) => {
        if (input.startValue !== value) {
            updateMessage.classList.remove('hidden');
        }

        setCustomProperty(input.varName, convertToUnits({ input, value }));
    };

    const _inputs = guiInputsHelper.prepareInputs(THEME_CONFIG_INPUTS);
    const inputs = _inputs.filter(input => input.enabled && !isUndefined(input.startValue));
    setupInputs({ gui, inputs, onChange: handleChange });

    // gui controls
    function closePanelFunction() {
        gui.destroy();
        onClose?.();
    }

    const controls = {
        undoAllChanges: () => {
            gui.reset();
        },
        resetAllToDefaults: () => {
            inputs.forEach((input: Input) => {
                input.controller?.setValue(input.defaultValue);
            });
        },
        saveChanges: () => {
            const controllers = gui.controllersRecursive();

            // customTheme contains other variables too, so we have to preserve them on update config
            const payload: WidgetCustomizationValue = { ...customTheme };

            controllers.forEach(({ property, initialValue, object }) => {
                const value = (object as Config)[property];
                const _isChanged = initialValue !== value;
                if (!_isChanged) return;

                const input = inputs.find(input => input.varName === property)!;
                const sameAsDefault = input.defaultValue === value;
                const shouldReset = customTheme[property] && sameAsDefault;
                const isChanged = !sameAsDefault;

                if (isChanged) {
                    payload[input.varName] = convertToUnits({ input, value });
                } else if (shouldReset) {
                    delete payload[input.varName];
                }
            });

            onSave?.(payload);
            closePanelFunction();
        },
        closePanel: () => closePanelFunction(),
    };

    const undoChangesButton = createButton({
        label: 'Undo all Changes',
        classes: [styles.controlPanelButton],
        attributes: { title: 'Undo all current Changes' },
        onClick: controls.undoAllChanges,
    });

    const resetChangesButton = createButton({
        label: 'Reset all to Defaults',
        classes: [styles.controlPanelButton],
        attributes: { title: 'Reset all the settings to Default values' },
        onClick: controls.resetAllToDefaults,
    });

    const saveChangesButton = createButton({
        label: 'Save Changes',
        classes: [styles.controlPanelButton],
        attributes: { title: 'Save current settings' },
        onClick: controls.saveChanges,
    });

    function createCloseButton({ label, classes }: Pick<CreateButtonProps, 'label' | 'classes'>) {
        return createButton({
            label,
            classes,
            attributes: { title: 'Close panel' },
            onClick: controls.closePanel,
        });
    }

    // topPanel fill
    const topPanel = createTopPanel({ gui });

    const topCloseButton = createCloseButton({
        label: 'X',
        classes: [styles.controlPanelButton, styles.controlPanelCloseButton],
    });

    topPanel.append(topCloseButton);

    // bottomPanel fill
    const bottomPanel = createBottomPanel({ gui });

    const bottomCloseButton = createCloseButton({
        label: 'Close Panel',
        classes: [styles.controlPanelButton],
    });

    bottomPanel.append(undoChangesButton);
    bottomPanel.append(resetChangesButton);
    bottomPanel.append(saveChangesButton);
    bottomPanel.append(bottomCloseButton);

    bottomPanel.prepend(updateMessage);

    // themeConfigPanel fill
    themeConfigPanel.append(bottomPanel);
    themeConfigPanel.append(topPanel);

    return gui;
}
