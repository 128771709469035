import * as yup from 'yup';
import { getBaseStringValidator } from '@utils';

export const createServiceValidation = () => {
    const yupBaseStr = getBaseStringValidator();

    return yup.object({
        comment: yupBaseStr.max(255),
        value: yup.number().required(),
    });
};
