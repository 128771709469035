import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createPersistReducer, PersistReducerConfigs } from '@app/persist';
import { enhancedApi as api } from '@services/df/clients';
import { ClientRepresentationDetailedSerializer, ClientRepresentationSerializer, InvalidateLevel } from '@types';
import { appActions } from '../app/slice';

export interface ClientReducerState {
    clientId?: number;
    data?: ClientRepresentationSerializer | ClientRepresentationDetailedSerializer;
    isLoading: boolean;
    isCreating: boolean;
}

const initialState: ClientReducerState = { isLoading: false, isCreating: false };

export const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setClientId: (state, action: PayloadAction<number | undefined>) => {
            state.clientId = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(appActions.invalidateStore, (state, { payload }) =>
            payload.purge >= InvalidateLevel.Client ? { ...initialState } : state,
        );
        builder.addMatcher(api.endpoints.clientsCreate.matchFulfilled, (state, { payload }) => {
            state.clientId = payload.id;
        });
    },
});

export const clientReducer = createPersistReducer(PersistReducerConfigs.client, clientSlice.reducer);
export const clientActions = clientSlice.actions;
