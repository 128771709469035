import {
    Order,
    OrderPriceCorrection,
    OrderPriceCorrectionForm,
    FormDataType,
    OrderCreateResponse,
    LoadShippingRatesRequest,
    LoadShippingRatesResponse,
    CheckShippingRatesResponse,
    LoadOrdersParams,
    PagedResponse,
    InitialOrder,
    InitialOrderCreateRequest,
    InitialOrderUpdateRequest,
    AddProductPayload,
    FindProductPayload,
    Product,
    UpdateProductPayload,
    DrawingFile,
    PricingError,
} from '@types';
import { AppSession } from '@utils';
import { axiosInstance } from './axios-instance';

export class OrderService {
    static init() {
        return new OrderService();
    }

    // initial order
    createInitialOrder(data: InitialOrderCreateRequest) {
        return axiosInstance.post<InitialOrder>('/v2/orders/', data);
    }

    createInitialOrderIqt(userId: number, data: InitialOrderCreateRequest) {
        return axiosInstance.post<InitialOrder>(`v2/orders/users/${userId}/adm/`, data);
    }

    loadInitialOrder(id: number) {
        return axiosInstance.get<InitialOrder>(`/v2/orders/${id}/initial/`);
    }

    updateInitialOrder(id: number, data: InitialOrderUpdateRequest) {
        return axiosInstance.patch<InitialOrder>(`/v2/orders/${id}/`, data);
    }

    submitInitialOrder(orderId: number, data: FormDataType) {
        return axiosInstance.post<OrderCreateResponse>(`v2/orders/${orderId}/submit_initial_order/`, data);
    }

    // product
    addProduct(orderId: number, data: AddProductPayload) {
        return axiosInstance.post<{
            order?: InitialOrder;
            purchase_id?: string;
            analysing_errors?: PricingError[];
        }>(`/v2/orders/${orderId}/purchases/`, {
            ...data,
            from_short_iqt: AppSession.shortIqtModeOn,
        });
    }

    findProduct(orderId: number, data: FindProductPayload) {
        return axiosInstance.post<Product>(`/v2/orders/${orderId}/purchases/find/`, data);
    }

    updateProduct(orderId: number, productId: string, data: UpdateProductPayload) {
        return axiosInstance.patch<{ order: InitialOrder; purchase_id: string }>(
            `/v2/orders/${orderId}/purchases/${productId}/`,
            { ...data, from_short_iqt: AppSession.shortIqtModeOn },
        );
    }

    removeProduct(orderId: number, productId: string) {
        return axiosInstance.delete<{ order: InitialOrder }>(`/v2/orders/${orderId}/purchases/${productId}/`, {
            data: { from_short_iqt: AppSession.shortIqtModeOn },
        });
    }

    // drawings
    getProductDrawings(orderId: number, productId: string) {
        return axiosInstance.get<PagedResponse<DrawingFile>>(`v2/orders/${orderId}/purchases/${productId}/drawings/`);
    }

    addProductDrawing(orderId: number, productId: string, file: File) {
        const formData = new FormData();
        formData.append('file', file);

        return axiosInstance.post<DrawingFile>(`v2/orders/${orderId}/purchases/${productId}/drawings/`, formData);
    }

    removeProductDrawing(orderId: number, productId: string, fileId: number) {
        return axiosInstance.delete<DrawingFile>(`v2/orders/${orderId}/purchases/${productId}/drawings/${fileId}`);
    }

    // orders
    getOrders(params: LoadOrdersParams) {
        return axiosInstance.get<PagedResponse<Order>>('/v2/orders/', {
            params,
        });
    }

    getOrder(orderId: string) {
        return axiosInstance.get<Order>(`/v2/orders/${orderId}/?expand=~all`);
    }

    repeatOrder(orderId: string) {
        return axiosInstance.post<Order>(`/v2/orders/${orderId}/repeat/`);
    }

    // rates
    loadRates(orderId: number, data: LoadShippingRatesRequest) {
        return axiosInstance.post<LoadShippingRatesResponse>(`/v2/retrieve_delivery_rates/${orderId}/`, {
            delivery_info: JSON.stringify(data),
        });
    }

    checkRates(orderId: number, taskId: string) {
        return axiosInstance.get<CheckShippingRatesResponse>(`/v2/retrieve_delivery_rates/${orderId}/`, {
            params: {
                task_id: taskId,
                format: 'json',
            },
        });
    }

    // price corrections
    getPriceCorrections(orderId: number) {
        return axiosInstance.get<PagedResponse<OrderPriceCorrection>>(`/v2/orders/${orderId}/corrections/`);
    }

    addPriceCorrection(orderId: number, data: OrderPriceCorrectionForm) {
        return axiosInstance.post<OrderPriceCorrection>(`/v2/orders/${orderId}/corrections/`, data);
    }

    deletePriceCorrection(orderId: number, entityId: number) {
        return axiosInstance.delete(`/v2/orders/${orderId}/corrections/${entityId}/`);
    }

    // other
    applyPromocode(orderId: number, data: { code: string }) {
        return axiosInstance.post<InitialOrder>(`/v2/orders/${orderId}/apply_promocode/`, data);
    }
}
