import { Translation } from 'react-i18next';
import { Typography, TypographyProps } from '@react-md/typography';
import type { OrderDiscountData } from '@modules/order';

const Discount = (props: TypographyProps) => (
    <Typography type="headline-6" weight="semi-bold" component="div" color="secondary" {...props} />
);

interface Props extends TypographyProps {
    value: string;
}

export const OrderDiscount = ({ value, ...props }: Props) => (
    <Discount {...props}>
        <Translation>
            {t =>
                t('messages.anotherDiscountApplied', '{{value}}% discount has been applied to your order.', {
                    value,
                })
            }
        </Translation>
    </Discount>
);

interface OrderDiscountPromocodeProps extends Props {
    name: string;
}

export const OrderDiscountPromocode = ({ name, value, ...props }: OrderDiscountPromocodeProps) => (
    <Discount {...props}>
        <Translation>
            {t =>
                t(
                    'messages.promoCodeApplied',
                    'Promo code "{{name}}" has been applied to your order. {{value}}% discount.',
                    { name, value },
                )
            }
        </Translation>
    </Discount>
);

export const renderOrderDiscount = ({ name, value, isPromo }: OrderDiscountData) =>
    isPromo ? <OrderDiscountPromocode name={name} value={value} /> : <OrderDiscount value={value} />;
