import React from 'react';
import { Select } from '@react-md/form';
import { SpecificationDropdownProps } from './types';
import { cutSelectListIfTooLong, cutSelectListIfTooLongOnKeydown } from '@utils/widget/cut-select-list-if-too-long';

export const SpecificationDropdown = React.memo<SpecificationDropdownProps>(
    ({ id, fieldName, priceConfigValue, onChange, data, className }) => {
        const dropdownOptions = data.map(item => ({
            label: item.title,
            value: item.uuid,
        }));

        const disabled = data.length < 2;

        return (
            <Select
                id={id}
                options={dropdownOptions}
                onChange={value => onChange(fieldName, value)}
                value={priceConfigValue}
                rightChildren={disabled ? null : undefined} // undefined means displaying default value
                disabled={disabled}
                className={className}
                onClick={cutSelectListIfTooLong}
                onKeyDown={event => cutSelectListIfTooLongOnKeydown(event.key)}
            />
        );
    },
);
