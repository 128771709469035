import React, { useCallback } from 'react';
import { Typography } from '@react-md/typography';
import { Divider } from '@react-md/divider';
import { ColorFieldsContainer } from '@components/color-field';
import { FeatureContent } from '@components/feature-content';
import { FeatureKeys as FF, FeatureOff, FeatureOn, FeatureToggle, useFeatureFlag } from '@components/feature-flags';
import { composeNodeId, getNodeIdComposer } from '@utils';
import { PriceConfigFormFields } from '@types';
import { PriceConfigFields } from '@constants';
import { SpecificationExtraFieldsets } from './specification-extra-fieldsets';
import { PostProduction } from './post-production';
import { SpecificationFeatures } from './specification-features';
import { SpecificationFormItem } from './specification-form-item';
import { SpecificationDropdown } from './specification-dropdown';
import { ChangePriceConfig, SpecificationFieldsProps } from './types';
import { useSpecificationFieldsLabels } from './hooks';
import { priceConfigId } from '../helpers';
import { RootPrefix } from '../constants';
import ColorSelect from '@components/color-select/ColorSelect';

import styles from './specification-form.module.scss';

// todo remove, clear styles with 'specification_field'
const _id = getNodeIdComposer(RootPrefix, 'specification_field');

export const SpecificationFields: React.FC<SpecificationFieldsProps> = ({
    formParams,
    formInstance,
    setWidgetPriceConfig,
}) => {
    const labels = useSpecificationFieldsLabels();
    const handlePriceConfigChange: ChangePriceConfig = useCallback(
        (fieldName, value) => {
            setWidgetPriceConfig(
                {
                    ...formInstance,
                    [fieldName]: value,
                },
                {
                    requestPrice: fieldName !== PriceConfigFormFields.ExtraFieldsets,
                },
            );
        },
        [formInstance, setWidgetPriceConfig],
    );

    const isBulkPriceEnabled = useFeatureFlag(FF.CartPage.BulkPriceOn);

    const fields = PriceConfigFields.filter(fieldName => formParams[fieldName]).map(fieldName => {
        const fieldId = priceConfigId(fieldName, 'field');
        const labelId = priceConfigId(fieldName, 'label');
        const label = <FeatureContent contentKey={labelId} fallback={labels[fieldName]} />;

        switch (fieldName) {
            case 'dfm_features': {
                // the name "dfm_features" cannot be used because there could have been saved feature flags and custom labels
                const oldName = 'cnc_features_types';
                const labelId = priceConfigId(oldName, 'label');
                return (
                    <FeatureToggle key={fieldName} flagKey={FF.MaterialSpecificationFieldsShown[oldName]}>
                        <FeatureOn>
                            <SpecificationFormItem>
                                <Divider />
                                <Typography id={labelId} type="headline-5">
                                    <FeatureContent contentKey={labelId} fallback={labels[fieldName]} />
                                </Typography>
                                <SpecificationFeatures
                                    fieldName={PriceConfigFormFields.Features}
                                    data={formParams[fieldName]}
                                    priceConfigValue={formInstance[PriceConfigFormFields.Features]!}
                                    onChange={handlePriceConfigChange}
                                />
                            </SpecificationFormItem>
                        </FeatureOn>
                    </FeatureToggle>
                );
            }
            case 'extra_fieldsets': {
                return (
                    <FeatureToggle key={fieldName} flagKey={FF.MaterialSpecificationFieldsShown[fieldName]}>
                        <FeatureOn>
                            <SpecificationExtraFieldsets
                                key={fieldName}
                                fieldName={PriceConfigFormFields.ExtraFieldsets}
                                data={formParams[fieldName]}
                                onChange={handlePriceConfigChange}
                                priceConfigValue={formInstance[fieldName]!}
                            />
                        </FeatureOn>
                    </FeatureToggle>
                );
            }
            case 'post_production': {
                return (
                    <FeatureToggle key={fieldName} flagKey={FF.MaterialSpecificationFieldsShown[fieldName]}>
                        <FeatureOn>
                            <SpecificationFormItem key={fieldName}>
                                <Divider />
                                <Typography id={labelId} type="headline-5">
                                    {label}
                                </Typography>
                                <PostProduction
                                    fieldName={PriceConfigFormFields.PostProduction}
                                    data={formParams[fieldName]}
                                    priceConfigValue={formInstance[fieldName]!}
                                    onChange={handlePriceConfigChange}
                                />
                            </SpecificationFormItem>
                        </FeatureOn>
                    </FeatureToggle>
                );
            }
            case 'color': {
                const options = formParams[fieldName];
                const priceConfigValue = formInstance[fieldName]!;
                const currentField = options.find(i => i.uuid === priceConfigValue);
                const note = currentField?.note_for_user;
                const _options = options.map(option => ({
                    id: composeNodeId(fieldId, option.uuid),
                    value: option.uuid,
                    checked: currentField?.uuid === option.uuid,
                    label: option.title,
                    color: option.color,
                }));

                return (
                    <FeatureToggle key={fieldName} flagKey={FF.MaterialSpecificationFieldsShown[fieldName]}>
                        <FeatureOn>
                            <SpecificationFormItem labelId={labelId} label={label} key={fieldName}>
                                <FeatureToggle key={fieldName} flagKey={FF.CartPage.SpecificationColorFieldAsList}>
                                    <FeatureOn>
                                        <ColorFieldsContainer
                                            name={fieldName}
                                            onChange={({ target: { value } }) =>
                                                handlePriceConfigChange(fieldName, value)
                                            }
                                            options={_options}
                                        />
                                    </FeatureOn>
                                    <FeatureOff>
                                        <ColorSelect
                                            id={_id()}
                                            name={fieldName}
                                            onChange={value => handlePriceConfigChange(fieldName, value)}
                                            options={_options}
                                        />
                                    </FeatureOff>
                                </FeatureToggle>

                                {note && (
                                    <Typography
                                        type="subtitle-2"
                                        weight="semi-bold"
                                        className={styles.note}
                                        component={'div'}
                                    >
                                        {note}
                                    </Typography>
                                )}
                            </SpecificationFormItem>
                        </FeatureOn>
                    </FeatureToggle>
                );
            }
            default: {
                const options = formParams[fieldName];
                const priceConfigValue = formInstance[fieldName]!;
                const currentField = options.find(i => i.uuid === priceConfigValue);
                const note = currentField?.note_for_user;
                if (!priceConfigValue || (fieldName === 'lead_time' && isBulkPriceEnabled)) return null;

                return (
                    <FeatureToggle key={fieldName} flagKey={FF.MaterialSpecificationFieldsShown[fieldName]}>
                        <FeatureOn>
                            <SpecificationFormItem labelId={labelId} label={label} key={fieldName} rowHalf>
                                <SpecificationDropdown
                                    id={fieldId}
                                    fieldName={fieldName}
                                    data={options}
                                    priceConfigValue={priceConfigValue}
                                    onChange={handlePriceConfigChange}
                                />

                                {note && (
                                    <Typography
                                        type="subtitle-2"
                                        weight="semi-bold"
                                        className={styles.note}
                                        component={'div'}
                                    >
                                        {note}
                                    </Typography>
                                )}
                            </SpecificationFormItem>
                        </FeatureOn>
                    </FeatureToggle>
                );
            }
        }
    });

    return <>{fields}</>;
};
