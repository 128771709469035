import cn from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { Link } from '@react-md/link';
import { Select } from '@react-md/form';
import { Preloader } from '@components/preloader';
import { FeatureContent, FeatureMarkup } from '@components/feature-content';
import { useWidgetTechnology } from '@pages/widget/order/hooks';
import { pricingActions } from '@modules/pricing';
import { selectSuitableMaterials } from '@modules/suitable-materials';
import { selectFullPreselectionModeOn, selectPreselectionModeOn } from '@modules/quotation';
import { getAvailableMaterials } from '@utils';
import { selectIqtModeOn } from '@modules/user';
import { cutSelectListIfTooLong, cutSelectListIfTooLongOnKeydown } from '@utils/widget/cut-select-list-if-too-long';
import { TechnologyMaterial } from '@types';
import { SpecificationFormItem } from './specification-form-item';
import { SpecificationMaterialProps } from './types';
import { specificationSectionId, priceConfigId } from '../helpers';

import formStyles from './specification-form.module.scss';
import styles from './specification-material.module.scss';

function getMarkedLabel(material: TechnologyMaterial) {
    return (
        <div>
            {material.title} <span className={styles.mark}>IQT-only</span>
        </div>
    );
}

export const SpecificationMaterial: React.FC<SpecificationMaterialProps> = ({ material, setWidgetMaterial }) => {
    const dispatch = useAppDispatch();
    const preselectionModeOn = useAppSelector(selectPreselectionModeOn);
    const fullPreselectionModeOn = useAppSelector(selectFullPreselectionModeOn);
    const { is_ready, suitable_materials } = useAppSelector(selectSuitableMaterials);
    const { widgetTechnology } = useWidgetTechnology();
    const isIqtModeOn = useAppSelector(selectIqtModeOn);
    let availableMaterials = useMemo(
        () =>
            getAvailableMaterials({
                technology: widgetTechnology,
                material,
                suitable: suitable_materials,
                preselectionModeOn,
                fullPreselectionModeOn,
                isIqtModeOn,
                getMarkedLabel,
            }),
        [suitable_materials, widgetTechnology, material, isIqtModeOn, preselectionModeOn, fullPreselectionModeOn],
    );

    // initial set material for model or product
    useEffect(() => {
        if (!!material) return;

        if (availableMaterials.length) {
            setWidgetMaterial(availableMaterials[0].value);
        } else {
            dispatch(pricingActions.setPricing(undefined));
        }
    }, [dispatch, material, setWidgetMaterial, availableMaterials]);

    const isLoadingSuitable = preselectionModeOn && !is_ready;
    const disabled = preselectionModeOn ? !availableMaterials.length : availableMaterials.length < 2;

    const { t } = useTranslation();

    const getDisplayLabel = useCallback(() => {
        if (material) {
            const { title, is_iqt_only: isIqtOnly } = material;
            const needToMarkAsIqtOnly = isIqtModeOn && isIqtOnly;
            if (material.spec_sheet_url) {
                return (
                    <span className={cn('flex', 'items-center', 'justify-between')}>
                        <span className="text-overflow-ellipsis">
                            {needToMarkAsIqtOnly ? getMarkedLabel(material) : title}
                        </span>
                        <Typography
                            type="subtitle-2"
                            weight="semi-bold"
                            component={'span'}
                            className={cn('input-addition-font-size', styles.spec)}
                        >
                            <Link href={material.spec_sheet_url} target="_blank">
                                {t('links.specSheet', 'Spec sheet')}
                            </Link>
                        </Typography>
                    </span>
                );
            }

            if (needToMarkAsIqtOnly) return getMarkedLabel(material);

            return title;
        }

        if (isLoadingSuitable) {
            return (
                <div className={cn('flex', 'items-center', 'justify-center')}>
                    <Preloader size={16} stroke={2} className="rmd-typography--hint" />
                    <Typography type="subtitle-2" weight="semi-bold" component="div" className="text-overflow-ellipsis">
                        {t('messages.materialsAnalyzed', 'Materials are being analyzed')}
                    </Typography>
                </div>
            );
        }

        return null;
    }, [material, isLoadingSuitable, isIqtModeOn, t]);

    if (isLoadingSuitable) {
        availableMaterials = [
            ...availableMaterials,
            {
                // @ts-ignore
                disabled: true,
                label: 'loading',
                children: (
                    <div className={cn('flex', 'items-center', 'justify-center')}>
                        <Preloader
                            size={16}
                            stroke={2}
                            className={cn('small-margin-right', 'rmd-typography--hint', styles.loader)}
                        />
                        <Typography
                            type="subtitle-2"
                            weight="semi-bold"
                            component={'div'}
                            className="text-overflow-ellipsis"
                        >
                            {t('messages.materialsAnalyzed', 'Materials are being analyzed')}
                        </Typography>
                    </div>
                ),
            },
        ];
    }

    return !material && !isLoadingSuitable ? null : (
        <SpecificationFormItem
            labelId={priceConfigId('material', 'label')}
            label={
                <FeatureContent
                    contentKey={priceConfigId('material', 'label')}
                    fallback={t('labels.material', 'Material')}
                />
            }
        >
            <Select
                id={priceConfigId('material', 'field')}
                options={availableMaterials}
                onChange={current => {
                    setWidgetMaterial(parseInt(current));
                }}
                value={material ? material.id.toString() : ''}
                getDisplayLabel={getDisplayLabel}
                displayLabelClassName={cn('w-full', styles.displayLabel)}
                title={material && material.title}
                rightChildren={disabled ? null : undefined} // undefined means displaying default value
                disabled={disabled}
                onClick={cutSelectListIfTooLong}
                onKeyDown={event => cutSelectListIfTooLongOnKeydown(event.key)}
            />

            {material?.note_for_user && (
                <Typography type="subtitle-2" weight="semi-bold" className={formStyles.note} component={'div'}>
                    {material.note_for_user}
                </Typography>
            )}

            <FeatureMarkup
                contentKey={specificationSectionId('markup_under_material')}
                wrapper={props => <div className="small-margin-top" {...props} />}
            />
        </SpecificationFormItem>
    );
};
