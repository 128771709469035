import each from 'lodash/each';
import { FormDataType } from '@types';

export function prepareFormData<T extends FormDataType>(data: T) {
    const formData = new FormData();

    each(data, (value, field) => {
        if (typeof value === 'number' || typeof value === 'boolean' || Array.isArray(value)) {
            formData.append(field, value.toString());
        } else if (value) {
            formData.append(field, value);
        }
    });

    return formData;
}
