import cn from 'classnames';
import React, { forwardRef } from 'react';
import { Typography, TypographyProps } from '@react-md/typography';
import { TBD } from '@types';

import styles from './tbd-messages.module.scss';

export const TbdMessage = (props: TypographyProps) => <Typography type="body-1" {...props} />;

interface Props extends TypographyProps {
    list: TBD[];
    listClassName?: string;
}

export const TbdMessages = forwardRef<HTMLUListElement, Props>(({ list, listClassName, ...rest }: Props, ref) => (
    <ul ref={ref} className={cn(styles.box, listClassName)}>
        {list.map(({ tbd_reason_message }, index) => (
            <TbdMessage component="li" className={styles.item} key={index} {...rest}>
                {tbd_reason_message}
            </TbdMessage>
        ))}
    </ul>
));
