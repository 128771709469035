import cn from 'classnames';
import sortBy from 'lodash/sortBy';
import { memo, useEffect } from 'react';
import { i18n } from 'i18next';
import { Select, useSelectState } from '@react-md/form';
import { Icons } from '@components/icons';
import { AppSettings } from '@types';
import { FallbackLng, LANGUAGES_FALLBACK, LanguageList } from '@constants';
import { adaptLanguageList, menuId } from './helpers';
import { cutSelectListIfTooLong, cutSelectListIfTooLongOnKeydown } from '@utils/widget/cut-select-list-if-too-long';
import { useAppSelector } from '@app/hooks';
import { selectLanguageList } from '@modules/app';

import styles from './header.module.scss';

const getInitLanguage = ({
    currentLanguage,
    languageList,
}: {
    currentLanguage: string;
    languageList: LanguageList;
}) => {
    let language = languageList.find(language => language.code.toLowerCase() === currentLanguage.toLowerCase());

    if (language) {
        return language.code;
    }

    //***** I'm sure that this piece of code never works at the moment, but we leave it for historical reasons *****
    const langPart = currentLanguage.split('-')[0];
    language = languageList.find(language => language.code === langPart);
    if (language) {
        return langPart;
    }

    return FallbackLng;
};

interface LanguagesProps {
    i18n: i18n;
    customLocale: AppSettings['custom_locale'];
}

export const LanguagesList = memo<LanguagesProps>(({ customLocale, i18n }) => {
    const languages = adaptLanguageList(useAppSelector(selectLanguageList) || LANGUAGES_FALLBACK);
    const sortedLanguages = sortBy(languages.slice(), 'code');
    const [language, handleChangeLanguage] = useSelectState(() =>
        getInitLanguage({ currentLanguage: i18n.language, languageList: sortedLanguages }),
    );

    useEffect(() => {
        if (customLocale || !i18n.changeLanguage || language === i18n.language) return;
        i18n.changeLanguage(language);
    }, [i18n, customLocale, language]);

    return (
        <Select
            className={cn(['rmd-select--as-button', { hidden: Boolean(customLocale) }])}
            id={menuId('languages')}
            options={sortedLanguages}
            onChange={handleChangeLanguage}
            labelKey="name"
            valueKey="code"
            value={language}
            leftChildren={
                <Icons.Globe
                // className="rmd-typography--secondary"
                />
            }
            listboxClassName={styles.listbox}
            listboxWidth="min"
            closeOnResize
            closeOnScroll
            onClick={cutSelectListIfTooLong}
            onKeyDown={event => cutSelectListIfTooLongOnKeydown(event.key)}
        />
    );
});
