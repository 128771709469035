import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { Typography } from '@react-md/typography';
import { TextField } from '@react-md/form';
import { OrderForm } from '@types';
import { Button } from '@react-md/button';
import { Icons } from '@components/icons';
import { TextIconSpacing } from '@react-md/icon';
import { FormField } from '@components/form-field';
import { FeatureContent, FeatureMarkup } from '@components/feature-content';
import { BillingContactFieldNames, ContactFieldNames, OrderFormId } from './constants';
import { billingId } from './helpers';
import { useOrderContactsFormLabels } from './hooks';

import styles from './order-form-billing.module.scss';

interface OrderFormBillingContactProps {
    control: Control<any>;
    setValue: UseFormSetValue<OrderForm>;
    getValues: UseFormGetValues<OrderForm>;
}

export const OrderFormBillingContact = ({ control, setValue, getValues }: OrderFormBillingContactProps) => {
    const { t } = useTranslation();
    const contactLabels = useOrderContactsFormLabels(t);

    const handleCopyFromContact = () => {
        const values = getValues(ContactFieldNames);
        values.forEach((value, i) => setValue(BillingContactFieldNames[i], value));
    };

    return (
        <div className="margin-top">
            <Typography id={billingId('billing_title')} type="headline-3" component="div" className="margin-bottom">
                <FeatureContent
                    contentKey={billingId('billing_title')}
                    fallback={t('titles.billingContact', 'Billing contact')}
                />
            </Typography>

            <FeatureMarkup
                contentKey={billingId('markup_top')}
                wrapper={props => <div className="medium-margin-bottom" {...props} />}
            />

            <FeatureContent
                contentKey={billingId('copy_contact')}
                fallback={t('buttons.copyFromContact', 'Copy from contact')}
                wrapper={props => (
                    <Button theme="secondary" className="rmd-button--flat" onClick={handleCopyFromContact} {...props}>
                        <TextIconSpacing icon={<Icons.Copy />} forceIconWrap>
                            {props.children}
                        </TextIconSpacing>
                    </Button>
                )}
            />

            <div className={cn('fieldset-grid', 'margin-top', styles.grid)}>
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="billing_name"
                    component={TextField}
                    label={contactLabels.first_name}
                />
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="billing_surname"
                    component={TextField}
                    label={contactLabels.last_name}
                />
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="billing_phone"
                    component={TextField}
                    label={contactLabels.phone}
                />
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="billing_email"
                    component={TextField}
                    label={contactLabels.email}
                />
            </div>
        </div>
    );
};
