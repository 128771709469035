import React, { ComponentProps, useCallback } from 'react';
import { useAppDispatch } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { DropzoneOptions } from 'react-dropzone';
import { FileField } from '@components/file-field';
import { DrawingFile } from '@types';
import { productActions } from '@modules/product';
import { getNodeIdComposer } from '@utils';
import { AcceptedDrawingExtensions, DrawingsMaxFiles } from '@constants';
import { FeatureContent } from '@components/feature-content';

const _id = getNodeIdComposer('product_drawings');

export interface ProductDrawingsProps extends React.HTMLAttributes<HTMLElement> {
    parentModelId?: number;
    productId?: string;
    className?: string;
    draftedDrawings?: { parentModelId: number; drawings: File[] };
    drawings?: DrawingFile[];
}

export const ProductDrawings: React.FC<ProductDrawingsProps> = ({
    className,
    parentModelId,
    productId,
    draftedDrawings,
    drawings,
}) => {
    const dispatch = useAppDispatch();

    // store drafted drawings in redux
    const handleChange: (drawings: File[]) => void = useCallback(
        drawings => {
            if (!parentModelId || productId) return;
            const modelChanged = draftedDrawings && draftedDrawings.parentModelId !== parentModelId;
            const sameDrawings = draftedDrawings && draftedDrawings.drawings.length === drawings.length;

            if (modelChanged) {
                dispatch(productActions.setDraftedDrawings(undefined));
            } else if (!sameDrawings) {
                dispatch(productActions.setDraftedDrawings({ parentModelId, drawings }));
            }
        },
        [dispatch, draftedDrawings, parentModelId, productId],
    );

    // product drawings
    const handleDropAccepted: DropzoneOptions['onDropAccepted'] = useCallback(
        acceptedFiles => {
            if (!productId) return;
            acceptedFiles.forEach((file: File) => dispatch(productActions.addDrawing({ productId, file })));
        },
        [dispatch, productId],
    );
    const handleRemove: ComponentProps<typeof FileField>['onDelete'] = useCallback(
        (_, index) => {
            if (!productId || !drawings?.length) return;
            const fileId = drawings.find((_, i) => index === i)!.id;
            dispatch(productActions.removeDrawing({ productId, fileId }));
        },
        [dispatch, productId, drawings],
    );

    const { t } = useTranslation();

    const initialFiles =
        productId && drawings
            ? drawings.map(file => file.url)
            : (draftedDrawings?.parentModelId === parentModelId && draftedDrawings?.drawings) || undefined;

    const chooseFileLabelId = _id('choose_file', 'label');

    return (
        <FileField
            className={className}
            initialFiles={initialFiles}
            onChange={handleChange}
            onDelete={handleRemove}
            dropzoneProps={{
                noClick: true,
                maxFiles: DrawingsMaxFiles,
                // accept: AcceptedDrawingExtensions,
                // accept: ['.txt', '.step'],
                // maxFiles: 3,
                minSize: 1,
                onDropAccepted: handleDropAccepted,
            }}
            inputProps={{
                id: _id('drawing_file', 'field', productId ? productId : parentModelId),
                labelId: chooseFileLabelId,
                name: 'drawing_file',
                theme: 'primary',
                themeType: 'outline',
                icon: false,
                children: (
                    <FeatureContent contentKey={chooseFileLabelId} fallback={t('buttons.chooseFile', 'Choose file')} />
                ),
            }}
        />
    );
};
