import filter from 'lodash/filter';
import find from 'lodash/find';
import React, { useCallback } from 'react';
import { Select } from '@react-md/form';
import { SpecificationFormItem } from './specification-form-item';
import { SpecificationExtraFieldsetsProps } from './types';
import { priceConfigId } from '../helpers';
import { cutSelectListIfTooLong, cutSelectListIfTooLongOnKeydown } from '@utils/widget/cut-select-list-if-too-long';

export const SpecificationExtraFieldsets = React.memo<SpecificationExtraFieldsetsProps>(
    ({ fieldName, data, onChange, priceConfigValue, className }) => {
        const handleChangeOption = useCallback(
            (fieldName, groupId, dropdownOption) => {
                const value = dropdownOption.value;
                const newValue = filter(priceConfigValue, item => item.choice !== value && item.fieldset !== groupId);

                newValue.push({ fieldset: groupId, choice: value });

                onChange(fieldName, newValue);
            },
            [onChange, priceConfigValue],
        );

        return (
            <>
                {data.map(group => {
                    const options = group.options;
                    const disabled = options?.length < 2;
                    const currentGroup = find(priceConfigValue, option => option.fieldset === group.id);
                    const currentValue = currentGroup?.choice || group.options[0].id;

                    return (
                        <SpecificationFormItem label={group.title} rowHalf={true}>
                            <Select
                                id={priceConfigId(fieldName, 'field', group.id)}
                                className={className}
                                disabled={disabled}
                                value={currentValue}
                                options={options.map(item => ({
                                    label: item.option,
                                    value: item.id,
                                }))}
                                onChange={(nextValue, option) => handleChangeOption(fieldName, group.id, option)}
                                rightChildren={disabled ? null : undefined}
                                onClick={cutSelectListIfTooLong}
                                onKeyDown={event => cutSelectListIfTooLongOnKeydown(event.key)}
                            />
                        </SpecificationFormItem>
                    );
                })}
            </>
        );
    },
);
