import React, { SVGProps } from 'react';
import cn from 'classnames';
import { bem } from '@react-md/utils';
const block = bem('rmd-icon');
const SvgInbox = (props: SVGProps<SVGSVGElement>) => {
    props = Object.assign(
        {
            'aria-hidden': true,
        },
        props,
        {
            className: cn(block(), props.className),
        },
    );
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="none"
            viewBox="0 0 16 16"
            role="img"
            {...props}
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M14.667 8h-4l-1.333 2H6.667L5.334 8h-4"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M3.634 3.407 1.334 8v4a1.333 1.333 0 0 0 1.333 1.333h10.667A1.334 1.334 0 0 0 14.667 12V8l-2.3-4.593a1.333 1.333 0 0 0-1.193-.74H4.827a1.333 1.333 0 0 0-1.193.74v0Z"
            />
        </svg>
    );
};
export default SvgInbox;
