import React, { SVGProps } from 'react';
import cn from 'classnames';
import { bem } from '@react-md/utils';
const block = bem('rmd-icon');
const SvgNotepad = (props: SVGProps<SVGSVGElement>) => {
    props = Object.assign(
        {
            'aria-hidden': true,
        },
        props,
        {
            className: cn(block(), props.className),
        },
    );
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="none"
            viewBox="0 0 16 16"
            role="img"
            {...props}
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.2}
                d="M13.105 2.734c-.395-.395-1.046-.393-1.046-.393H4.02c-.355 0-.947.393-.947.393s-.392.592-.392.947v10.72a1.34 1.34 0 0 0 1.34 1.34h8.04a1.34 1.34 0 0 0 1.34-1.34V3.68s.1-.552-.295-.947Z"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M10.72 10.001H5.36M7.36 5.501h-2M10.72 12.39H5.36M10.72 7.681H5.36"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.943 1.183a.62.62 0 1 1 .876.876l-3.65 3.65L10 6.001l.292-1.168 3.65-3.65Z"
            />
        </svg>
    );
};
export default SvgNotepad;
