import cn from 'classnames';
import React, { ReactNode } from 'react';
import { NavLinkProps, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TextIconSpacing } from '@react-md/icon';
import { Icons } from '@components/icons';
import { ButtonLink } from './button-link';

import styles from './link.module.scss';

interface BackLinkProps {
    id?: string;
    className?: string;
    label?: ReactNode;
    to?: NavLinkProps['to'];
    replace?: NavLinkProps['replace'];
}

export const BackLink: React.FC<BackLinkProps> = ({ id, label, className, to, replace, children }) => {
    const { state } = useLocation();
    const backToLocation = state && state.from;

    const { t } = useTranslation();

    if (!backToLocation && !to) {
        return null;
    }

    return (
        <div className={cn('margin-bottom', className)}>
            <ButtonLink
                id={id}
                themeType="contained"
                className={cn('rmd-button--text-primary', 'rmd-button--text-large', styles.back)}
                to={to || (backToLocation as NavLinkProps['to'])}
                replace={replace}
            >
                <TextIconSpacing icon={<Icons.Chevron className="rmd-typography--secondary" />} forceIconWrap>
                    {label || t('links.back', 'Back')}
                </TextIconSpacing>
            </ButtonLink>

            {children}
        </div>
    );
};
