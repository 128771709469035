import React, { SVGProps } from 'react';
import cn from 'classnames';
import { bem } from '@react-md/utils';
const block = bem('rmd-icon');
const SvgBoxThin = (props: SVGProps<SVGSVGElement>) => {
    props = Object.assign(
        {
            'aria-hidden': true,
        },
        props,
        {
            className: cn(block(), props.className),
        },
    );
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            fill="none"
            viewBox="0 0 32 32"
            role="img"
            {...props}
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M28 21.333V10.667a2.667 2.667 0 0 0-1.333-2.307l-9.334-5.333a2.667 2.667 0 0 0-2.666 0L5.333 8.36A2.667 2.667 0 0 0 4 10.667v10.666a2.667 2.667 0 0 0 1.333 2.307l9.334 5.333a2.667 2.667 0 0 0 2.666 0l9.334-5.333A2.667 2.667 0 0 0 28 21.333Z"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4.36 9.28 16 16.013 27.64 9.28M16 29.44V16"
            />
        </svg>
    );
};
export default SvgBoxThin;
