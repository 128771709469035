import { createSelector } from 'reselect';
import { RootState } from '@types';
import { AppSession } from '@utils';
import { enhancedApi as api } from '@services/df/clients';
import { selectAppSettings } from '../app';
import { selectIsAuthenticated } from '../auth';
import { selectClient } from '../client';
import { defineUserPermissions } from './helpers';

export const clientsMeSelector = api.endpoints.clientsMeRetrieve.select();
export const selectAuthorizedUser = (state: RootState) => clientsMeSelector(state).data;
export const selectAuthorizedUserId = (state: RootState) =>
    selectAuthorizedUser(state)?.id || selectAppSettings(state).user_id;
export const selectUserPermissions = createSelector(
    selectAppSettings,
    selectIsAuthenticated,
    selectAuthorizedUser,
    (settings, isAuthenticated, user) => {
        return defineUserPermissions({
            isAuthenticated: isAuthenticated || Boolean(settings.user_id),
            userRoles: user?.roles || settings.roles,
            settings,
        });
    },
);

export const selectIqtModeOn = (state: RootState) => {
    const permissions = selectUserPermissions(state);
    return permissions.iqtAllowed && AppSession.intentionToOpenIqt;
};
// export const selectIqtModeOn = createSelector(
//     selectUserPermissions,
//     permissions => permissions.iqtAllowed && AppSession.intentionToOpenIqt,
// );

export const selectUser = (state: RootState) => {
    const iqtModeOn = selectIqtModeOn(state);
    const client = selectClient(state);
    const user = selectAuthorizedUser(state);
    return iqtModeOn ? client : user;
};
