import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { createPersistReducer, PersistReducerConfigs } from '@app/persist';
import {
    AuthState,
    ConfirmPasswordRecoveryRequest,
    OtpForm,
    PasswordlessLogin,
    PasswordRecoveryRequest,
    SignupForm,
    FormOnSubmit,
    SignInForm,
    StoreResponseErrors,
} from '@types';

export interface AuthReducerState {
    token?: string;
    isAuthenticated: boolean;
    isTokenObtaining: boolean;
    isOtpSending: boolean;
    isPasswordRecoverySending: boolean;
    isPasswordRecoverySuccess: boolean;
    isChangePasswordSending: boolean;
    errors?: StoreResponseErrors;
}

const initialState: AuthReducerState = {
    isAuthenticated: false,
    isTokenObtaining: false,
    isOtpSending: false,
    isPasswordRecoverySending: false,
    isPasswordRecoverySuccess: false,
    isChangePasswordSending: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthState: (state, action: PayloadAction<AuthState>) => {},

        // anon session
        obtainAnonymousToken: state => {
            state.isTokenObtaining = true;
        },
        obtainAnonymousTokenSuccess: (state, action: PayloadAction<{ token: string }>) => {
            state.token = action.payload.token;
            state.isTokenObtaining = false;
        },
        obtainAnonymousTokenFailure: state => {
            state.isTokenObtaining = false;
        },

        // login
        obtainAuthToken: (state, action: PayloadAction<FormOnSubmit<SignInForm>>) => {
            state.isTokenObtaining = true;
            state.errors = undefined;
        },
        obtainAuthTokenSuccess: (state, action: PayloadAction<{ token: string }>) => {
            state.isTokenObtaining = false;
            state.isAuthenticated = true;
            state.token = action.payload.token;
            state.errors = undefined;
        },
        obtainAuthTokenFailure: (state, action: PayloadAction<StoreResponseErrors>) => {
            state.isTokenObtaining = false;
            state.errors = action.payload;
        },

        obtainOtp: (state, action: PayloadAction<FormOnSubmit<OtpForm>>) => {
            state.isOtpSending = true;
            state.errors = undefined;
        },
        obtainOtpSuccess: state => {
            state.isOtpSending = false;
            state.errors = undefined;
        },
        obtainOtpFailure: (state, action: PayloadAction<StoreResponseErrors>) => {
            state.isOtpSending = false;
            state.errors = action.payload;
        },

        otpLogin: (state, action: PayloadAction<FormOnSubmit<PasswordlessLogin>>) => {
            state.isTokenObtaining = true;
            state.errors = undefined;
        },
        otpLoginSuccess: (state, action: PayloadAction<{ token: string }>) => {
            state.isTokenObtaining = false;
            state.isAuthenticated = true;
            state.token = action.payload.token;
            state.errors = undefined;
        },
        otpLoginFailure: state => {
            state.isTokenObtaining = false;
            state.errors = 'error';
        },

        obtainSessionToken: state => {
            state.isTokenObtaining = true;
            state.errors = undefined;
        },
        obtainSessionTokenSuccess: (state, action: PayloadAction<{ token: string }>) => {
            state.isTokenObtaining = false;
            state.isAuthenticated = true;
            state.token = action.payload.token;
            state.errors = undefined;
        },
        obtainSessionTokenFailure: state => {
            state.isTokenObtaining = false;
            state.errors = undefined;
        },

        silentLogin: (state, action: PayloadAction<PasswordlessLogin>) => {
            state.isTokenObtaining = true;
            state.errors = undefined;
        },
        silentLoginSuccess: (state, action: PayloadAction<{ token: string }>) => {
            state.isTokenObtaining = false;
            state.isAuthenticated = true;
            state.token = action.payload.token;
            state.errors = undefined;
        },
        silentLoginFailure: state => {
            state.isTokenObtaining = false;
            state.errors = undefined;
        },

        // signup
        signup: (state, action: PayloadAction<FormOnSubmit<SignupForm>>) => {
            state.isTokenObtaining = true;
            state.errors = undefined;
        },
        signupSuccess: (state, action: PayloadAction<{ token: string }>) => {
            state.isTokenObtaining = false;
            state.isAuthenticated = true;
            state.token = action.payload.token;
            state.errors = undefined;
        },
        signupFailure: (state, action: PayloadAction<StoreResponseErrors>) => {
            state.isTokenObtaining = false;
            state.errors = action.payload;
        },

        // change password
        sendPasswordRecovery: (state, action: PayloadAction<Omit<PasswordRecoveryRequest, 'company_name'>>) => {
            state.isPasswordRecoverySending = true;
            state.errors = undefined;
        },
        sendPasswordRecoverySuccess: state => {
            state.isPasswordRecoverySending = false;
            state.isPasswordRecoverySuccess = true;
            state.errors = undefined;
        },
        sendPasswordRecoveryFailure: (state, action: PayloadAction<StoreResponseErrors>) => {
            state.isPasswordRecoverySending = false;
            state.errors = action.payload;
        },

        confirmPasswordRecovery: (state, action: PayloadAction<FormOnSubmit<ConfirmPasswordRecoveryRequest>>) => {
            state.isChangePasswordSending = true;
            state.errors = undefined;
        },
        confirmPasswordRecoverySuccess: state => {
            state.isChangePasswordSending = false;
            state.errors = undefined;
        },
        confirmPasswordRecoveryFailure: (state, action: PayloadAction<StoreResponseErrors>) => {
            state.isChangePasswordSending = false;
            state.errors = action.payload;
        },
    },
    // todo [authActions.obtainSessionToken.request,authActions.silentLogin.request,authActions.obtainAuthToken.request,authActions.otpLogin.request,]
    // todo [authActions.obtainSessionToken.success,authActions.silentLogin.success,authActions.obtainAuthToken.success,authActions.otpLogin.success,]
    // todo [authActions.obtainSessionToken.failure, authActions.silentLogin.failure]
    // extraReducers: builder => {
    //     builder.addMatcher(
    //         isAnyOf(
    //             contentApi.endpoints.getContent.matchPending,
    //             dailyPlayersApi.endpoints.dailyPlayersList.matchPending,
    //             cardsApi.endpoints.cardsList.matchPending,
    //             setsApi.endpoints.setsCreate.matchPending,
    //             usersApi.endpoints.usersRetrieve.matchPending,
    //         ),
    //         (state, { payload }) => {
    //             state.activeLoaders += 1;
    //         },
    //     );
    // },
});

export const authReducer = createPersistReducer(PersistReducerConfigs.auth, authSlice.reducer);
export const authActions = authSlice.actions;
