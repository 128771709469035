import cn from 'classnames';
import React, { useCallback } from 'react';
import { useAppDispatch } from '@app/hooks';
import { Dialog } from '@react-md/dialog';
import { PopupProps } from '@types';
import { popupsActions } from '@modules/ui';
import { OtpFormBody } from './otp-form-body';
import { OtpFormFooter } from './otp-form-footer';
import { otpId } from '../helpers';

import styles from '../auth-page.module.scss';

export const OtpPopup: React.FC<PopupProps> = ({ isOpened, isClosable }) => {
    const dispatch = useAppDispatch();
    const close = useCallback(() => dispatch(popupsActions.close()), [dispatch]);

    return (
        <Dialog
            id={otpId('popup')}
            visible={isOpened}
            onRequestClose={() => (isClosable ? close() : {})}
            aria-labelledby={otpId('popup')}
            overlayStyle={{ transition: 'none' }}
            // defaultFocus={`#${composeNodeId(SignInFormId, 'email', 'field')}`}
            disableFocusOnMount
            portal={false}
            modal={!isClosable}
            appear
        >
            <div className={cn('rounded-box', styles.box)}>
                <OtpFormBody onSuccess={close} />
                <OtpFormFooter type="popup" />
            </div>
        </Dialog>
    );
};
