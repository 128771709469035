import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { TextIconSpacing } from '@react-md/icon';
import { CircularProgress } from '@react-md/progress';
import { Button } from '@react-md/button';
import { ButtonLink } from '@components/link/button-link';
import { DateTransform } from '@components/date';
import { Fade } from '@components/animations';
import { FeatureContent } from '@components/feature-content';
import { Price } from '@components/price';
import { TbdLabel } from '@components/tbd';
import { makeSelectOrdersPageTbdById } from '@modules/orders';
import { getNodeIdComposer } from '@utils';
import { OrderStatuses } from '@types';
import { OrdersItemProps } from '../types';
import { RootPrefix } from '../constants';
import { selectIsOrderPageLoading } from '@modules/order-page';
import { repeatOrder } from '@modules/order-page/thunks';

import styles from '../orders-page.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'order');

export const UserOrdersItem: React.FC<OrdersItemProps> = ({ order, in: show, isAbandoned, ...rest }) => {
    const {
        id,
        date: createdAt,
        placed_at: placedAt,
        total_price: total,
        status,
        status_display: statusDisplay,
        company_order_id: companyOrderId,
        can_repeat_order: canRepeatOrder,
    } = order;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const selectOrdersPageTbdById = useMemo(makeSelectOrdersPageTbdById, []);
    const tbd = useAppSelector(state => selectOrdersPageTbdById(state, id));
    const isCancelled = status === OrderStatuses.CancelledByAdmin || status === OrderStatuses.CancelledByUser;
    const isLoading = useSelector(selectIsOrderPageLoading);
    const orderDate = placedAt || createdAt;

    const { t } = useTranslation();

    const handleClick = async () => {
        const newOrderPath = await dispatch(repeatOrder(id)).unwrap();
        navigate(newOrderPath);
    };

    return (
        <Fade in={show} unmountOnExit {...rest}>
            <Typography type="body-1" component={'div'} className={styles.cell}>
                {companyOrderId || id}
            </Typography>
            <Typography type="body-1" component={'div'} className={styles.cell}>
                {orderDate ? <DateTransform dateString={orderDate} /> : '-'}
            </Typography>

            {tbd ? (
                <div className={styles.cell}>
                    <TbdLabel />
                </div>
            ) : (
                <Typography type="body-1" component={'div'} className={styles.cell}>
                    <Price>{total}</Price>
                </Typography>
            )}

            {isAbandoned ? (
                <Typography type="body-1" component={'div'} className={styles.cell}>
                    {!canRepeatOrder && 'Printer or material, used in this order, is unavailable'}
                </Typography>
            ) : (
                <Typography
                    type="body-1"
                    component={'div'}
                    className={cn(styles.cell, styles.right, {
                        'rmd-typography--theme-success': status === OrderStatuses.Placed,
                        'rmd-typography--theme-warning':
                            status === OrderStatuses.WaitingForReview || status === OrderStatuses.FirmOfferSent,
                        'rmd-typography--theme-error': isCancelled,
                    })}
                >
                    {statusDisplay}
                </Typography>
            )}
            <div className={cn(styles.cell, styles.right)}>
                {isAbandoned ? (
                    <Button
                        id={_id('resume', id)}
                        theme={isLoading ? 'disabled' : 'primary'}
                        themeType="outline"
                        onClick={handleClick}
                        className={cn(styles.button, { [styles.buttonLoading]: isLoading && canRepeatOrder })}
                        disabled={!canRepeatOrder}
                    >
                        <div className={cn(styles.buttonContainer, 'flex')}>
                            <TextIconSpacing
                                icon={isLoading && canRepeatOrder && <CircularProgress id={_id('progress')} />}
                                forceIconWrap
                            >
                                <FeatureContent contentKey={_id('resume')} fallback={t('buttons.resume', 'Resume')} />
                            </TextIconSpacing>
                        </div>
                    </Button>
                ) : (
                    <ButtonLink
                        id={_id('details', id)}
                        theme="primary"
                        themeType="outline"
                        to={id.toString()}
                        className={styles.button}
                    >
                        <FeatureContent contentKey={_id('details')} fallback={t('buttons.details', 'Details')} />
                    </ButtonLink>
                )}
            </div>
        </Fade>
    );
};
