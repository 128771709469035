import { Login, SignupForm, PasswordRecoveryRequest, ConfirmPasswordRecoveryRequest, ObtainOtpRequest } from '@types';
import { axiosInstance } from './axios-instance';

export class AuthService {
    static init(companyName: string) {
        return new AuthService(companyName);
    }

    constructor(private companyName: string) {}

    setHeaders(token: string) {
        axiosInstance.defaults.headers['Authorization'] = `Token ${token}`;
    }

    // requests
    obtainAnonymousToken() {
        return axiosInstance
            .post<{ token: string }>('/v2/obtain_anonymous_auth_token/', {
                company_name: this.companyName,
            })
            .then(response => {
                const token = response.data.token;
                this.setHeaders(token);
                return response;
            });
    }

    obtainAuthToken(login: Login) {
        return axiosInstance
            .post<{ token: string }>('/v2/obtain_auth_token/', {
                company_name: this.companyName,
                ...login,
            })
            .then(response => {
                const token = response.data.token;
                this.setHeaders(token);
                return response;
            });
    }

    obtainSessionToken() {
        return axiosInstance
            .post<{ token: string }>('/v2/obtain_session_token/', null, { maxRedirects: 0, withCredentials: true })
            .then(response => {
                const token = response.data.token;
                this.setHeaders(token);
                return response;
            });
    }

    signup(form: SignupForm) {
        return axiosInstance
            .post<{ token: string }>('/v2/signup/', {
                company_name: this.companyName,
                ...form,
            })
            .then(response => {
                const token = response.data.token;
                this.setHeaders(token);
                return response;
            });
    }

    sendPasswordRecovery(data: PasswordRecoveryRequest) {
        return axiosInstance.post('/v2/password/recovery/request/', {
            ...data,
            company_name: this.companyName,
        });
    }

    confirmPasswordRecovery(data: ConfirmPasswordRecoveryRequest) {
        return axiosInstance.post('/v2/password/recovery/reset/', data);
    }

    obtainOtp(data: ObtainOtpRequest) {
        return axiosInstance.post('/v2/obtain-magic-link/', data);
    }
}
