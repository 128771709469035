import React, { useEffect } from 'react';
import { PaymentIntent } from '@stripe/stripe-js';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { useAddMessage } from '@react-md/alert';
import { Link } from '@react-md/link';
import { Typography } from '@react-md/typography';
import { LinkStyledButton } from '@components/link';
// import { getNodeIdComposer } from '@utils';
// import { RootPrefix } from './constants';

// const _id = getNodeIdComposer(RootPrefix, 'next_action');

const getPaymentVerifyAction = (t: TFunction) => {
    return {
        theme: 'secondary',
        themeType: 'contained',
        children: t('buttons.done', 'Done'),
    };
};

const getPaymentVerifyMessage = (url: string, t: TFunction) => (
    <>
        <Typography type="headline-4" className="small-margin-bottom">
            {t('messages.verifyAccount', 'Please verify your account')}
        </Typography>
        <Typography type="body-1">
            <Trans
                i18nKey="messages.followLinkAndInstructions"
                defaults={'Follow the <lnk>link</lnk> and proceed with instructions.'}
                components={{
                    lnk: <Link className="rmd-typography--bold" href={url} target="_blank" />,
                }}
            />
        </Typography>
    </>
);

interface StripeMicrodepositsVerificationProps {
    instructions: PaymentIntent.NextAction.VerifyWithMicrodeposits;
}

function StripeMicrodepositsVerification({ instructions }: StripeMicrodepositsVerificationProps) {
    const addMessage = useAddMessage();
    const { t } = useTranslation();
    const url = instructions.hosted_verification_url;

    useEffect(() => {
        addMessage({
            messageId: 'microdeposits_verification',
            action: getPaymentVerifyAction(t),
            className: 'rmd-toast--notification fade-in',
            children: getPaymentVerifyMessage(url, t),
            disableAutohide: true,
        });
    }, [addMessage, url, t]);

    return null;
}

interface StripeBankTransferInstructionProps {
    instructions: PaymentIntent.NextAction.BankTransferInstruction;
}

export function StripeBankTransferInstruction({ instructions }: StripeBankTransferInstructionProps) {
    const { hosted_instructions_url } = instructions;
    const { t } = useTranslation();
    return (
        <LinkStyledButton themeType="outline" href={hosted_instructions_url} target="_blank">
            {t('buttons.viewRequisites', 'View bank details')}
        </LinkStyledButton>
    );
}

interface StripeElementsNextActionProps {
    action: PaymentIntent.NextAction;
}

export function StripeElementsNextAction({ action }: StripeElementsNextActionProps) {
    switch (action.type) {
        case 'verify_with_microdeposits': {
            return <StripeMicrodepositsVerification instructions={action.verify_with_microdeposits!} />;
        }
        case 'display_bank_transfer_instructions': {
            return <StripeBankTransferInstruction instructions={action.display_bank_transfer_instructions!} />;
        }
        default:
            return null;
    }
}
