import omit from 'lodash/omit';
import { AxiosError } from 'axios';
import { concat, from, of } from 'rxjs';
import { catchError, filter, map, finalize, switchMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { AppEpic, AskForHelpFormErrors, AskForHelpUploadErrors } from '@types';
import { CompanyService } from '@services';
import { formatResponseErrors } from '@utils';
import { askForHelpActions } from './slice';

const sendAskForHelpEpic: AppEpic = action$ =>
    action$.pipe(
        filter(askForHelpActions.sendAskForHelp.match),
        map(action => {
            const {
                form: { file },
            } = action.payload;

            if (file) {
                return askForHelpActions.uploadFile(action.payload);
            }

            return askForHelpActions.sendForm(action.payload);
        }),
    );

const uploadAskForHelpFileEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(askForHelpActions.uploadFile.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const {
                form: { file },
                onError,
            } = action.payload;

            return from(CompanyService.init().uploadFile(file as File)).pipe(
                switchMap(({ data }) =>
                    concat([
                        askForHelpActions.uploadFileSuccess(),
                        askForHelpActions.sendForm({
                            ...action.payload,
                            form: {
                                ...action.payload.form,
                                uuid: data.uuid,
                            },
                        }),
                    ]),
                ),
                catchError((error: AxiosError<AskForHelpUploadErrors>) => {
                    const errors = formatResponseErrors<AskForHelpUploadErrors>(
                        error?.response?.data || ({} as AskForHelpUploadErrors),
                    );

                    return of(askForHelpActions.uploadFileFailure(errors)).pipe(
                        finalize(() => onError && onError(errors)),
                    );
                }),
            );
        }),
    );

const sendAskForHelpFormEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(askForHelpActions.sendForm.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const { form, modelId, onSuccess, onError } = action.payload;

            return from(CompanyService.init().askForHelp(modelId, omit(form, ['file']))).pipe(
                switchMap(() => of(askForHelpActions.sendFormSuccess()).pipe(finalize(() => onSuccess && onSuccess()))),
                catchError((error: AxiosError<AskForHelpFormErrors>) => {
                    const errors = formatResponseErrors<AskForHelpFormErrors>(
                        error?.response?.data || ({} as AskForHelpFormErrors),
                    );

                    return of(askForHelpActions.sendFormFailure(errors)).pipe(
                        finalize(() => onError && onError(errors)),
                    );
                }),
            );
        }),
    );

export const askForHelpEpics = combineEpics(sendAskForHelpFormEpic, uploadAskForHelpFileEpic, sendAskForHelpEpic);
