import trim from 'lodash/trim';
import { ChangeEventHandler, KeyboardEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, TextField, TextFieldProps } from '@react-md/form';
import { Icons } from '@components/icons';

export interface Props extends TextFieldProps {
    /**
     * The value from the query params
     */
    value: string;

    /**
     * The finalize event means onBlur or onKeyDown
     */
    onFinalize: (value: string) => void;
}

export function SearchField({ value, onFinalize: _onFinalize, ...props }: Props) {
    const onFinalize = useCallback(
        (nextValue: string) => {
            nextValue = trim(nextValue);
            if (nextValue !== value) {
                _onFinalize(nextValue);
            }
        },
        [value, _onFinalize],
    );
    const handleBlur: ChangeEventHandler<HTMLInputElement> = useCallback(
        ({ target }) => {
            onFinalize(target.value);
        },
        [onFinalize],
    );
    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
        ({ code, currentTarget }) => {
            if (code === 'Enter' || code === 'NumpadEnter') {
                onFinalize(currentTarget.value);
            }
        },
        [onFinalize],
    );

    const { t } = useTranslation();

    return (
        <TextField
            placeholder={t('messages.searchByName', 'Search by name')}
            rightChildren={<Icons.Search className="rmd-typography--secondary" />}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            defaultValue={value} // make it uncontrolled
            {...props}
            title={value}
        />
    );
}
