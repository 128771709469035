import React, { ReactElement } from 'react';
import cn from 'classnames';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { ButtonThemeProps, buttonThemeClassNames } from '@react-md/button';

import styles from './link.module.scss';

export function ButtonLink({
    className,
    theme,
    themeType,
    buttonType,
    disabled,
    children,
    ...props
}: ButtonThemeProps & NavLinkProps): ReactElement {
    return (
        <NavLink
            {...props}
            className={buttonThemeClassNames({
                disabled,
                theme,
                themeType,
                buttonType,
                className: cn(styles.btn, className),
            })}
        >
            {children}
        </NavLink>
    );
}

ButtonLink.defaultProps = {
    theme: 'clear',
    themeType: 'flat',
    buttonType: 'text',
};
