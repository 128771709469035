export const OrderFormId = 'update_or_create_order_form';

export const ContactFieldNames = ['first_name', 'last_name', 'phone', 'email'] as const;

export const BillingContactFieldNames = ['billing_name', 'billing_surname', 'billing_phone', 'billing_email'] as const;

export const BillingAddressFieldNames = [
    'billing_address.company_name',
    'billing_address.city',
    'billing_address.street_address',
    'billing_address.apartment',
    'billing_address.postcode',
    'billing_address.country',
    'billing_address.state',
] as const;

export const DeliveryAddressFieldNames = [
    'delivery_address.company_name',
    'delivery_address.city',
    'delivery_address.street_address',
    'delivery_address.apartment',
    'delivery_address.postcode',
    'delivery_address.country',
    'delivery_address.state',
] as const;

export const RatesDeps = [...DeliveryAddressFieldNames, 'first_name', 'last_name', 'phone'] as const;
