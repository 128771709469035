import * as yup from 'yup';
import { NumberSchema, StringSchema } from 'yup/lib';
import { MixedLocale } from 'yup/lib/locale';
import isBoolean from 'lodash/isBoolean';

type ValidatorOptions = { required?: MixedLocale['required'] | boolean; max?: Parameters<StringSchema['max']> };

export const getBaseStringValidator = () => {
    return yup.string().ensure().trim();
};

export const getPasswordValidator = () => {
    return getBaseStringValidator().required().min(10).max(254);
};

export const getRequiredStringValidator = (required: ValidatorOptions['required'], base = getBaseStringValidator()) => {
    return required ? base.required(isBoolean(required) ? undefined : required) : base;
};

export const getFirstNameValidator = ({ required = true, max = [254] }: ValidatorOptions = {}) => {
    let errorMassage: boolean | string = false;
    if (required) {
        errorMassage = 'First name is required';
    }
    return getRequiredStringValidator(errorMassage)
        .max(...max);
};

export const getLastNameValidator = ({ required = false, max = [254] }: ValidatorOptions = {}) => {
    let errorMassage: boolean | string = false;
    if (required) {
        errorMassage = 'Last name is required';
    }
    return getRequiredStringValidator(errorMassage)
        .max(...max);
};

export const getEmailValidator = ({ required = true, max = [254] }: ValidatorOptions = {}) => {
    return getRequiredStringValidator(required)
        .email()
        .max(...max);
};

export const getPhoneValidator = ({ required = false, max = [40] }: ValidatorOptions = {}) => {
    let errorMassage: boolean | string = false;
    if (required) {
        errorMassage = 'Phone number is required';
    }
    return getRequiredStringValidator(errorMassage).max(...max);
    // return getRequiredStringValidator(required, yup.number('Phone must be a number type')).max(...max);
};
