import { default as AlertTriangle } from './alert-triangle';
import { default as ArrowTriple } from './arrow-triple';
import { default as Arrow } from './arrow';
import { default as BoxThin } from './box-thin';
import { default as Box } from './box';
import { default as Cart } from './cart';
import { default as CheckCircleThin } from './check-circle-thin';
import { default as CheckCircleTwo } from './check-circle-two';
import { default as CheckCircle } from './check-circle';
import { default as Checkmark } from './checkmark';
import { default as Chevron } from './chevron';
import { default as Circle } from './circle';
import { default as Clock } from './clock';
import { default as Copy } from './copy';
import { default as Cross } from './cross';
import { default as DollarSign } from './dollar-sign';
import { default as Download } from './download';
import { default as Edit } from './edit';
import { default as Error } from './error';
import { default as ExternalLink } from './external-link';
import { default as Faces } from './faces';
import { default as File } from './file';
import { default as Folder } from './folder';
import { default as Globe } from './globe';
import { default as Grid } from './grid';
import { default as HelpCircle } from './help-circle';
import { default as Hourglass } from './hourglass';
import { default as Inbox } from './inbox';
import { default as Info } from './info';
import { default as LifeBuoy } from './life-buoy';
import { default as Lock } from './lock';
import { default as Menu } from './menu';
import { default as Minus } from './minus';
import { default as Notepad } from './notepad';
import { default as Notes } from './notes';
import { default as Package } from './package';
import { default as Paperclip } from './paperclip';
import { default as Pinch } from './pinch';
import { default as PlayCircle } from './play-circle';
import { default as Plus } from './plus';
import { default as Rotate } from './rotate';
import { default as Search } from './search';
import { default as Settings } from './settings';
import { default as Slash } from './slash';
import { default as SlidersHorizontal } from './sliders-horizontal';
import { default as Sliders } from './sliders';
import { default as Spread } from './spread';
import { default as Square } from './square';
import { default as Trash } from './trash';
import { default as UserCheck } from './user-check';
import { default as Warning } from './warning';

export const Icons = {
    AlertTriangle,
    ArrowTriple,
    Arrow,
    BoxThin,
    Box,
    Cart,
    CheckCircleThin,
    CheckCircleTwo,
    CheckCircle,
    Checkmark,
    Chevron,
    Circle,
    Clock,
    Copy,
    Cross,
    DollarSign,
    Download,
    Edit,
    Error,
    ExternalLink,
    Faces,
    File,
    Folder,
    Globe,
    Grid,
    HelpCircle,
    Hourglass,
    Inbox,
    Info,
    LifeBuoy,
    Lock,
    Menu,
    Minus,
    Notepad,
    Notes,
    Package,
    Paperclip,
    Pinch,
    PlayCircle,
    Plus,
    Rotate,
    Search,
    Settings,
    Slash,
    SlidersHorizontal,
    Sliders,
    Spread,
    Square,
    Trash,
    UserCheck,
    Warning,
};
