import { loadStripe } from '@stripe/stripe-js';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@types';
import { selectUploadError } from '@modules/upload-models';

const deactivateErrorCodes = [403];

export const selectAppCompanyName = (state: RootState) => state.app.companyName;
export const selectAppInitialSuccess = (state: RootState) => state.app.initialSuccess;
export const selectAppSettings = (state: RootState) => state.app.settings;
export const selectAuthorizedUserId = (state: RootState) => state.app.settings.user_id;
export const selectIsAppDeactivated = (state: RootState) => {
    const { is_blocked, is_deactivated, is_widget_blocked, is_user_blocked } = selectAppSettings(state);
    const { status } = selectUploadError(state) || {};
    const hasDeactivateError = status ? deactivateErrorCodes.includes(status) : false;
    return hasDeactivateError || is_blocked || is_deactivated || is_widget_blocked || is_user_blocked;
};

export const selectCurrency = (state: RootState) => state.app.settings.currency;
export const selectContentCustomization = (state: RootState) => state.app.widget_content_customization;
export const selectStyleCustomization = (state: RootState) => state.app.widget_style_customization;
export const selectContentEditableMode = (state: RootState) => state.app.contentEditableMode;
export const selectLanguageList = (state: RootState) => state.app.settings.lang_codes;

// Make sure to call `loadStripe` outside a component’s render to avoid
// recreating the `Stripe` object on every render.
export const selectStripePromise = createSelector(
    (state: RootState) => state.app.settings,
    ({ stripe_public_key: stripePublishableKey, stripe_account_id: stripeAccount }) =>
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
            ? loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
            : stripePublishableKey
            ? loadStripe(stripePublishableKey, {
                  stripeAccount,
              })
            : null,
);
