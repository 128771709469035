import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useBrowserStorage } from '@hooks';
import { ModaMessagelInterface, ModalMessage } from '@components/modal-message/ModalMessage';

import styles from './invoice-paid-modal.module.scss';

interface InvoiceModalPaidInterface extends ModaMessagelInterface {
    companyShortName: string;
    itemNumber: number;
    // This is for saving different items, like orders
    // If theres is only one item, you can pass any number
    isVisible: boolean;
    localStorageItemName: string;
}

type StoredObject = {
    [key: string]: boolean;
};

export const InvoicePaidModal: React.FC<InvoiceModalPaidInterface> = (props: InvoiceModalPaidInterface) => {
    const {
        id,
        className,
        hasCrossCloseButton,
        hasConfirmationCloseButton,
        companyShortName,
        itemNumber,
        isVisible,
        localStorageItemName,
        headerContent,
        dialogContent,
        footerContent,
    } = props;

    const itemName = `${companyShortName}-${itemNumber}`;

    const [isCreated, setIsCreated] = useState(false);

    let storedState = useMemo(() => {
        try {
            const storedStateValue = localStorage.getItem(localStorageItemName);
            if (storedStateValue) {
                return JSON.parse(storedStateValue as string);
            }
        } catch {
            return {};
        }
        return {};
    }, [localStorageItemName]);

    const setStoredState = useCallback((params: { storedState: StoredObject; key: string; value: boolean }) => {
        const { storedState, key, value } = params;
        const newStoredState: StoredObject = { ...storedState, [key]: value };
        return newStoredState;
    }, []);

    const [modalWasClosed, setModalWasClosed] = useBrowserStorage(localStorage, {
        key: localStorageItemName,
        value: storedState,
    });

    const handleModalClose = () => {
        setModalWasClosed(setStoredState({ storedState, key: itemName, value: true }));
    };

    useEffect(() => {
        if (!isVisible && !isCreated && !storedState[itemName]) {
            setModalWasClosed(setStoredState({ storedState, key: itemName, value: false }));
            setIsCreated(true);
        } else if (!isVisible && !isCreated && storedState[itemName]) {
            // This is for reloading modal window if set it unpaid throw backoffice
            setIsCreated(true);
            setModalWasClosed(setStoredState({ storedState, key: itemName, value: false }));
        }
    }, [isVisible, setModalWasClosed, itemName, setStoredState, storedState, isCreated]);

    if (modalWasClosed) {
        if (!isVisible || modalWasClosed[itemName] === undefined || modalWasClosed[itemName]) return null;
    }

    return (
        <ModalMessage
            id={`${id}-modal`}
            className={`${styles.modal} ${className}`}
            hasCrossCloseButton={hasCrossCloseButton}
            hasConfirmationCloseButton={hasConfirmationCloseButton}
            headerContent={headerContent}
            dialogContent={dialogContent}
            footerContent={footerContent}
            onClose={handleModalClose}
        />
    );
};
