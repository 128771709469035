import cn from 'classnames';
import uniq from 'lodash/uniq';
import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { Label } from '@components/label';
import { TbdLabel } from '@components/tbd';
import {
    AppSettings,
    PriceConfigForm,
    SetPriceConfigActionMeta,
    PriorityPriceItem,
    TechnologyMaterialOption,
    TBD,
} from '@types';
import { selectIqtModeOn } from '@modules/user';
import { pricingActions } from '@modules/pricing';
import { customVariables as CV } from '@utils';
import { getBulkPriceCellValue } from './helpers';

import styles from './calculation-bulk-prices-table.module.scss';

export interface CalculationBulkPricesTableProps {
    currency: AppSettings['currency'];
    priorities?: TechnologyMaterialOption[];
    count: number;
    setWidgetCount: (count: number) => void;
    config: PriceConfigForm;
    setWidgetPriceConfig: (config: PriceConfigForm, meta: SetPriceConfigActionMeta) => void;
    prices: PriorityPriceItem[];
}

export const CalculationBulkPricesTable: React.FC<CalculationBulkPricesTableProps> = ({
    currency,
    priorities,
    count,
    setWidgetCount,
    config,
    setWidgetPriceConfig,
    prices,
}) => {
    const dispatch = useAppDispatch();
    const currentPriority = config?.lead_time;
    const iqtModeOn = useAppSelector(selectIqtModeOn);

    const handleCellClick = useCallback(
        ({ currentTarget: { dataset } }, tbd: TBD) => {
            dispatch(pricingActions.setBulkPriceCurrentTbd(tbd));

            const quantity = parseInt(dataset.quantity);
            if (quantity !== count) {
                setWidgetCount(quantity);
            }

            const priority = dataset.priority_id;
            if (priority !== currentPriority) {
                setWidgetPriceConfig(
                    {
                        ...config,
                        lead_time: priority,
                    },
                    {
                        requestPrice: true,
                    },
                );
            }
        },
        [dispatch, count, currentPriority, setWidgetCount, setWidgetPriceConfig, config],
    );

    const { t } = useTranslation();

    return (
        <div className={cn('margin-bottom', 'overflow-x-auto', styles.box)}>
            <table className={styles.table}>
                <thead className={styles.head}>
                    <tr className={styles.row}>
                        <td colSpan={1} className={cn('text-overflow-ellipsis', styles.cell, styles.sticky)}>
                            <Label className={styles.label}>{t('labels.quantity', 'Quantity')}</Label>
                        </td>

                        {prices.map(price => {
                            const priority = priorities?.find(priority => priority.uuid === price.priority_id);
                            return (
                                <td
                                    key={price.priority_id}
                                    colSpan={1}
                                    className={cn('text-overflow-ellipsis', styles.cell)}
                                >
                                    <Label className={styles.label}>
                                        {price?.priority_name_for_user || priority?.title}
                                    </Label>
                                </td>
                            );
                        })}
                    </tr>
                </thead>

                <tbody className={styles.body}>
                    {uniq(CV.BulkPriceQuantities).map(quantity => {
                        return (
                            <tr key={quantity} className={styles.row}>
                                <td
                                    colSpan={1}
                                    className={cn(
                                        'text-overflow-ellipsis',
                                        styles.cell,
                                        styles.sticky,
                                        styles.quantity,
                                    )}
                                >
                                    <Typography type="body-2" component={'span'}>
                                        {quantity}
                                    </Typography>
                                </td>

                                {prices.map(({ priority_id, priority_prices }) => {
                                    const priceItem = priority_prices.find(item => item.quantity === quantity);

                                    if (!priceItem) {
                                        return null;
                                    }

                                    const { tbd_reason, tbd_reason_message } = priceItem;

                                    const isActive = count === quantity && priority_id === currentPriority;

                                    return (
                                        <td
                                            key={`${quantity}-${priority_id}`}
                                            colSpan={1}
                                            data-quantity={quantity}
                                            data-priority_id={priority_id}
                                            onClick={event =>
                                                handleCellClick(event, { tbd_reason, tbd_reason_message })
                                            }
                                            className={cn('text-overflow-ellipsis', styles.cell, styles.price, {
                                                [styles.active]: isActive,
                                            })}
                                        >
                                            {tbd_reason && !iqtModeOn ? (
                                                <TbdLabel />
                                            ) : (
                                                <Typography
                                                    type="body-2"
                                                    component={'span'}
                                                    className={cn({
                                                        'rmd-typography--semi-bold': isActive,
                                                    })}
                                                >
                                                    {getBulkPriceCellValue(priceItem, currency)}
                                                </Typography>
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
