import { createSelector } from 'reselect';
import { RootState } from '@types';
import { findParentModel } from '@utils';
import { selectAppSettings } from '../app';
import { selectModelsDict } from '../models';
import { selectOrderData, selectOrderProducts } from '../order';

export const selectFoundProduct = (state: RootState) => state.product.foundProduct; // the config we are editing is in the cart
export const selectHasFoundProduct = (state: RootState) => Boolean(selectFoundProduct(state));
export const selectProductIsAdding = (state: RootState) => state.product.isAdding;
export const selectProductIsUpdating = (state: RootState) => state.product.isUpdating;
export const selectProductIsRemoving = (state: RootState) => state.product.isRemoving;
export const selectProductIsFinding = (state: RootState) => state.product.isFinding;
export const selectIsOrderChanged = (state: RootState) => state.product.isOrderChanged;
export const selectIsApplyToAllModelsActive = (state: RootState) => state.product.isApplyToAllActive;
export const selectRemovedProductsIds = (state: RootState) => state.product.removedProductsIds;
export const selectDraftedDrawings = (state: RootState) => state.product.draftedDrawings;
export const selectDrawings = (state: RootState) => state.product.drawings;
export const selectDrawingsByProductId = (state: RootState, productId?: string) =>
    productId ? state.product.drawings[productId] : undefined;

export const selectIsRequiredDrawingsAbsent = createSelector(
    selectAppSettings,
    selectModelsDict,
    selectOrderData,
    selectDrawings,
    (settings, models, cart, drawings) => {
        if (!cart) return false;

        const { is_drawings_optional_for_non_processable_file_formats: nonCadDrawingsOptional } = settings;

        for (const product of cart.products) {
            const model = findParentModel(models, product.model_id);
            const requiredDrawings =
                model && !model.is_processable && nonCadDrawingsOptional ? false : product.require_drawing;

            if (requiredDrawings && !drawings[product.id]?.length) {
                return true;
            }
        }

        return false;
    },
);

export const selectProductsHasSanityNotifications = createSelector(selectOrderProducts, products => {
    const productsWithSanityNotifications = products?.filter(
        product => (product.sanity_notifications ?? []).length > 0,
    );
    return (productsWithSanityNotifications ?? []).length > 0;
});
