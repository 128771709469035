import cn from 'classnames';
import { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { useQueryParams, createEnumParam, NumberParam, withDefault } from 'use-query-params';
import { Radio } from '@react-md/form';
import { Typography } from '@react-md/typography';
import { FeatureContent } from '@components/feature-content';
import { ordersActions } from '@modules/orders';
import { FilterOrderStatus } from '@types';
import { getNodeIdComposer } from '@utils';
import { GetParams } from '@constants';
import { FilterLinkProps } from '../types';
import { RootPrefix, FiltersId } from '../constants';
import { useOrdersQueryParams } from './use-orders-query-params';

import styles from '../orders-page.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'header');
const filtersId = getNodeIdComposer(FiltersId);

// const OrderStatusParam = createEnumParam(Object.values(FilterOrderStatus));

const FilterLink = ({ status, label, checked, onChangeStatus, id }: FilterLinkProps) => {
    return (
        <Radio
            id={id}
            name="filters"
            onChange={() => onChangeStatus(status)}
            value={status}
            checked={checked}
            label={<FeatureContent contentKey={id} fallback={label} />}
            className={cn(styles.radio, { [styles.checked]: checked })}
            labelClassName={cn(styles.label, 'rmd-typography--semi-bold')}
            toggleClassName={styles.input}
            disableProgrammaticRipple
            disableRipple
        />
    );
};

export const UserOrdersHeader = () => {
    const dispatch = useAppDispatch();
    const { query, setQuery } = useOrdersQueryParams();
    // const [query, setQuery] = useQueryParams({
    //     [GetParams.PaginationPage]: withDefault(NumberParam, 1),
    //     [GetParams.OrderStatus]: withDefault(OrderStatusParam, FilterOrderStatus.All),
    // });

    useEffect(() => {
        dispatch(ordersActions.load(query));
    }, [dispatch, query]);

    const onChangeStatus = useCallback(
        (status: FilterLinkProps['status']) => {
            setQuery({
                status,
                page: 1,
            });
        },
        [setQuery],
    );

    const { t } = useTranslation();

    const links: Array<Pick<FilterLinkProps, 'id' | 'status' | 'label'>> = useMemo(
        () => [
            {
                id: filtersId('status', 'field', 'all'),
                status: FilterOrderStatus.All,
                label: t('links.ordersAll', 'All'),
            },
            {
                id: filtersId('status', 'field', 'placed'),
                status: FilterOrderStatus.Placed,
                label: t('links.ordersPlaced', 'Placed orders'),
            },
            {
                id: filtersId('status', 'field', 'quotes'),
                status: FilterOrderStatus.Quotes,
                label: t('links.ordersQuotes', 'Quotes'),
            },
            {
                id: filtersId('status', 'field', 'abandoned'),
                status: FilterOrderStatus.Abandoned,
                label: t('links.ordersAbandoned', 'Abandoned Carts'),
            },
        ],
        [t],
    );

    return (
        <div className="base-paddings">
            <Typography id={_id('title')} type="headline-3" component="h1">
                <FeatureContent contentKey={_id('title')} fallback={t('titles.orders', 'Orders')} />
            </Typography>

            <div className={cn('small-margin-top', 'flex', 'medium-gap')}>
                {links.map(link => (
                    <FilterLink
                        key={link.status}
                        checked={query.status === link.status}
                        onChangeStatus={onChangeStatus}
                        {...link}
                    />
                ))}
            </div>
        </div>
    );
};
