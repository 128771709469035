import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { getPaginationRequestParams } from '@components/pagination';
import { AppEpic, LoadOrdersParams } from '@types';
import { OrderService } from '@services';
import { OrderStatusesMap } from '@constants';
import { ordersActions } from './slice';

const getOrdersListEpic: AppEpic = (action$, state$) => {
    return action$.pipe(
        filter(ordersActions.load.match),
        switchMap(action => {
            const { page, status } = action.payload;
            const params: LoadOrdersParams = {
                ...getPaginationRequestParams(page),
                ordering: '-date',
            };

            if (OrderStatusesMap[status]) {
                params.status__in = OrderStatusesMap[status].statusIn;
                params.status__not__in = OrderStatusesMap[status].statusNotIn;
            }

            return from(OrderService.init().getOrders(params)).pipe(
                switchMap(({ data }) => {
                    return of(ordersActions.loadSuccess(data));
                }),
                catchError(error => of(ordersActions.loadFailure(error))),
            );
        }),
    );
};

export const ordersEpics = combineEpics(getOrdersListEpic);
