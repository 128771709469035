import { useEffect } from 'react';
import { useAppSelector } from '@app/hooks';
import { selectAppInitialSuccess } from '@modules/app';
import { customVariables as CV } from '@utils';

function updateDocument() {
    document.title = CV.AppDocumentTitle;
}

export const DocumentHeadOverrides = () => {
    const appInitialSuccess = useAppSelector(selectAppInitialSuccess);

    useEffect(() => {
        // wait for the settings response, it will update customVariables
        if (!appInitialSuccess) return;
        updateDocument();
    }, [appInitialSuccess]);

    return null;
};
