import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FileField } from '../file-field';
import { useAttachmentsHandlers } from './attachments-hooks';
import { Typography } from '@react-md/typography';
import { formId } from '@pages/widget/order-submit/order-forms/helpers';
import { FeatureContent } from '@components/feature-content';
import { InvoiceTileHead } from '../invoice-title-head';
import { Icons } from '../icons';

interface FileAttachmentsProps {
    entityId: number;
    entityName: string;
    className?: string;
    addTitle?: boolean;
}

// todo
//  1) remove ids, if necessary pass them as props
//  2) check backoffice for component implementation
export const FileAttachments: React.FC<FileAttachmentsProps> = ({ entityId, entityName, className, addTitle }) => {
    const { handleFileChange, handleRemove, attachments, isLoading } = useAttachmentsHandlers(entityId, entityName);
    const { t } = useTranslation();

    const attachFileLabelId = formId('attached_file', 'label');
    const attachFileTitleId = formId('attached_file', 'title');

    return !isLoading ? (
        <div className={className}>
            {addTitle && (
                <InvoiceTileHead id={attachFileTitleId} className="margin-bottom" icon={Icons.File}>
                    <FeatureContent
                        contentKey={attachFileTitleId}
                        fallback={t('titles.additionalFiles', 'Additional files')}
                    />
                </InvoiceTileHead>
            )}

            <FileField
                expandedByDefault={false}
                initialFiles={attachments}
                onDelete={handleRemove}
                onChange={handleFileChange}
                dropzoneProps={{ noClick: true, maxFiles: 20 }}
                inputProps={{
                    id: formId('attached_file', 'field'),
                    labelId: attachFileLabelId,
                    theme: 'primary',
                    themeType: 'outline',
                    icon: false,
                    multiple: true,
                    children: (
                        <FeatureContent
                            contentKey={attachFileLabelId}
                            fallback={t('buttons.attachFile', 'Attach file')}
                        />
                    ),
                }}
            />
        </div>
    ) : null;
};
