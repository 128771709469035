import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { useMemo, useRef } from 'react';
import { useAppDispatch } from '@app/hooks';
import { TFunction } from 'i18next';
import { FormState, useForm, UseFormGetValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ClientExtendedRepresentationDetailed } from '@services/df/clients';
import {
    AppSettings,
    BillingAddress,
    ClientRepresentationDetailedSerializer,
    FieldGroups,
    InitialOrder,
    LoadShippingRatesRequest,
    OrderForm,
    ShippingTypes,
} from '@types';
import { useValidation } from '@hooks';
import { deliveryRatesActions } from '@modules/delivery-rates';
import { createAddress } from '@modules/order';
import { getInitialState, getValidState } from '@utils';
import { createOrderValidation, createRatesValidation } from './form-validation';
import { getInitialShippingType, getRatesDeps } from './helpers';

interface OrderFormOptions {
    settings: AppSettings;
    form?: OrderForm;
    order: InitialOrder;
    user?: ClientExtendedRepresentationDetailed;
    fieldGroups?: FieldGroups[];
}

function createDeliveryAddress(settings: AppSettings) {
    const { state: companyState, country } = settings.company_address;
    const state = companyState ? getValidState(country, companyState) : getInitialState(country);
    return createAddress({ state, country });
}

function getDefaultValues(settings: AppSettings, order: InitialOrder, user?: ClientExtendedRepresentationDetailed) {
    const billingAddress = user?.billing_addresses?.[0];
    const deliveryAddress = user?.delivery_addresses?.[0];
    const hasBillingAddress = !!billingAddress?.street_address;
    const hasDeliveryAddress = !!billingAddress?.street_address; // check 6e4670a9b69345b7c0d1572bac23174d75127d37 commit why we set it as billing
    const shippingRateId = order.easypost_rate || order.custom_rate?.toString() || '';

    return {
        // form data
        first_name: user?.name,
        last_name: user?.surname,
        phone: user?.phone_number,
        email: user?.email,
        billing_address: billingAddress || createAddress<BillingAddress>({ vat_number: '' }),
        delivery_address: deliveryAddress || createDeliveryAddress(settings),
        notes: '',

        shippingRateId,

        // form conditions
        shippingType: getInitialShippingType(settings, hasDeliveryAddress),
        isCompanyOrder: settings.force_company_order || hasBillingAddress,
    };
}

export function useOrderForm({ form, settings, order, user, fieldGroups }: OrderFormOptions) {
    const defaultValues = form ?? getDefaultValues(settings, order, user);
    const validationSchema = useValidation(createOrderValidation, settings, fieldGroups);

    return useForm<OrderForm>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        // @ts-ignore
        defaultValues,
        resolver: yupResolver(validationSchema),
    });
}

export function useOrderContactsFormLabels(t: TFunction): Record<string, string> {
    return {
        first_name: t('labels.firstName', 'First name'),
        last_name: t('labels.lastName', 'Last name'),
        phone: t('labels.phone', 'Phone'),
        email: t('labels.email', 'E-mail'),
    };
}

export function useOrderBillingAddressFormLabels(t: TFunction, settings: AppSettings): Record<string, string> {
    const { vat_number_required, tax_name, tax_number_name } = settings;
    const optional = t('labels.optional', 'optional');
    const number = t('labels.number', 'number');
    const vat_number = tax_number_name ? tax_number_name : `${tax_name} ${number}`;

    return {
        'billing_address.company_name': [t('labels.company', 'Company'), optional].join(', '),
        'billing_address.city': t('labels.city', 'City'),
        'billing_address.street_address': t('labels.streetAddress', 'Street address'),
        'billing_address.apartment': [t('labels.apartment', 'Apartment'), optional].join(', '),
        'billing_address.postcode': t('labels.postalCode', 'Postal code'),
        'billing_address.country': t('labels.country', 'Country'),
        'billing_address.state': t('labels.state', 'State'),
        'billing_address.vat_number': vat_number_required ? vat_number : [vat_number, optional].join(', '),
        'billing_address.use_recent_address': t('buttons.useRecentAddress', 'Use recent address'),
    };
}

export function useOrderDeliveryFormLabels(t: TFunction): Record<string, string> {
    const optional = t('labels.optional', 'optional');

    return {
        'delivery_address.company_name': [t('labels.company', 'Company'), optional].join(', '),
        'delivery_address.city': t('labels.city', 'City'),
        'delivery_address.street_address': t('labels.address1', 'Address Line 1'),
        'delivery_address.apartment': [t('labels.address2', 'Address Line 2'), optional].join(', '),
        'delivery_address.postcode': t('labels.postalCode', 'Postal code'),
        'delivery_address.country': t('labels.country', 'Country'),
        'delivery_address.state': t('labels.state', 'State'),
        'delivery_address.use_recent_address': t('buttons.useRecentAddress', 'Use recent address'),
    };
}

export function useGetShippingRates() {
    const dispatch = useAppDispatch();
    const ratesValidation = useValidation(createRatesValidation);
    const prevDeps = useRef<LoadShippingRatesRequest>();

    return useMemo(() => {
        return debounce(
            (getValues: UseFormGetValues<OrderForm>, errors: FormState<OrderForm>['errors'], field: string) => {
                const values = getValues();
                const deps = getRatesDeps(values);

                if (values.shippingType !== ShippingTypes.Delivery || isEqual(deps, prevDeps.current)) {
                    return;
                }

                prevDeps.current = deps;

                if (get(errors, field)) {
                    dispatch(deliveryRatesActions.setRatesDepsValidity(false));
                    return;
                }

                ratesValidation.isValid(deps).then((valid: boolean) => {
                    if (valid) {
                        dispatch(deliveryRatesActions.load(deps));
                    }

                    dispatch(deliveryRatesActions.setRatesDepsValidity(valid));
                });
            },
            400,
        );
    }, [dispatch, ratesValidation]);
}

export type GetShippingRatesSignature = ReturnType<typeof useGetShippingRates>;
