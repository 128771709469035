import React from 'react';
import { useTranslation } from 'react-i18next';
import { TypographyProps } from '@react-md/typography';
import { ObjectModel } from '@types';
import { LineItem } from '@components/line-items';
import { useModelUnitsLabels } from '@hooks';
import { convertVolume, formatVolume, getForcedVolumeUnits } from './helpers';

interface Props extends TypographyProps {
    model: ObjectModel;
    convertTo?: ObjectModel['units'];
}

export const ModelVolume: React.FC<Props> = ({ model: { geometry_type, volume, units }, convertTo, ...props }) => {
    const fromUnits = units;
    const viewUnits = getForcedVolumeUnits(convertTo || fromUnits);

    const { t } = useTranslation();
    const unitsLabels = useModelUnitsLabels();
    const unitsLabel = unitsLabels[viewUnits].symbol;

    const visible = volume && geometry_type !== 'flat_sheet';

    if (!visible) return null;

    const convertedVolume = convertVolume(volume, { from: fromUnits, to: viewUnits });

    return (
        <LineItem label={t('labels.modelVolume', 'Volume')} {...props}>
            <span>
                {formatVolume(convertedVolume, viewUnits)} {unitsLabel}
                <sup style={{ fontSize: '50%' }}>3</sup>
            </span>
        </LineItem>
    );
};
