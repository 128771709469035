import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModelsFilter } from '@pages/user/models/types';
import { ObjectModel, PagedResponse } from '@types';
import { UserObjectModelModel } from '@models';

interface State {
    data: UserObjectModelModel[];
    totalCount: number;
    isLoading: boolean;
    error: boolean;
    selectedModelIds: string[];
}

const initialState: State = {
    data: [],
    error: false,
    isLoading: false,
    totalCount: 0,
    selectedModelIds: [],
};

export const modelsPageSlice = createSlice({
    name: 'modelsPage',
    initialState,
    reducers: {
        setSelectedModelIds: (state, action: PayloadAction<string[]>) => {
            state.selectedModelIds = action.payload;
        },

        load: (state, action: PayloadAction<ModelsFilter>) => {
            state.isLoading = true;
        },
        loadSuccess: (state, action: PayloadAction<PagedResponse<ObjectModel>>) => {
            state.isLoading = false;
            state.data = action.payload.results.map(item => UserObjectModelModel.from(item));
            state.totalCount = action.payload.count;
            state.error = false;
        },
        loadFailure: state => {
            state.isLoading = false;
            state.error = true;
        },
    },
});

export const modelsPageActions = modelsPageSlice.actions;
