import cn from 'classnames';
import React, { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { TextField } from '@react-md/form';
import { OrderForm } from '@types';
import { Typography } from '@react-md/typography';
import { FeatureContent, FeatureMarkup } from '@components/feature-content';
import { FeatureKeys as FF, useFeatureFlag } from '@components/feature-flags';
import { FormField } from '@components/form-field';
import { AdditionalContacts } from '@components/additional-contacts';
import { OrderFormId, RatesDeps } from './constants';
import { useOrderContactsFormLabels, GetShippingRatesSignature } from './hooks';
import { contactId } from './helpers';

import styles from './order-form-billing.module.scss';

interface OrderFormContactProps {
    getRates: GetShippingRatesSignature;
}

export const OrderFormContact: React.FC<OrderFormContactProps> = ({ getRates }) => {
    const {
        control,
        getValues,
        formState: { errors },
    } = useFormContext<OrderForm>();

    const handleRatesDepsBlur: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        const field = target.name;
        if ((RatesDeps as ReadonlyArray<string>).includes(field)) {
            getRates(getValues, errors, field);
        }
    };

    const { t } = useTranslation();
    const contactLabels = useOrderContactsFormLabels(t);
    const isPersonalFieldsUneditable = useFeatureFlag(FF.OrderPage.PersonalInfoUneditable);

    return (
        <div>
            <Typography id={contactId('title')} type="headline-3" component="div" className="margin-bottom">
                <FeatureContent contentKey={contactId('title')} fallback={t('titles.contact', 'Contact')} />
            </Typography>

            <FeatureMarkup
                contentKey={contactId('markup_top')}
                wrapper={props => <div className="medium-margin-bottom" {...props} />}
            />

            <div className={cn('fieldset-grid', styles.grid)}>
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="first_name"
                    component={TextField}
                    label={contactLabels.first_name}
                    widgetProps={{
                        onBlur: handleRatesDepsBlur,
                        disabled: isPersonalFieldsUneditable,
                    }}
                />
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="last_name"
                    component={TextField}
                    label={contactLabels.last_name}
                    widgetProps={{
                        onBlur: handleRatesDepsBlur,
                        disabled: isPersonalFieldsUneditable,
                    }}
                />
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="phone"
                    component={TextField}
                    label={contactLabels.phone}
                    widgetProps={{
                        onBlur: handleRatesDepsBlur,
                        disabled: isPersonalFieldsUneditable,
                    }}
                />
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="email"
                    component={TextField}
                    label={contactLabels.email}
                    widgetProps={{
                        disabled: isPersonalFieldsUneditable,
                    }}
                />
            </div>
            <AdditionalContacts />
        </div>
    );
};
