import cn from 'classnames';
import React from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { useForm, FieldPath, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@react-md/form';
import { FeatureContent } from '@components/feature-content';
import { FormField, NonFieldErrors } from '@components/form-field';
import { SubmitButton } from '@components/submit-button';
import { SignInForm } from '@types';
import { selectAppSettings } from '@modules/app';
import { authActions, selectIsTokenObtaining } from '@modules/auth';
import { setApiErrors } from '@utils';
import { useValidation } from '@hooks';
import { useFormLabels } from '../hooks';
import { createLoginValidation } from '../form-validation';
import { AuthFormTitle } from '../components';
import { ResetPasswordButton } from './reset-password-button';
import { signInId } from '../helpers';
import { SignInFormId } from '../constants';

interface Props {
    onSuccess?: (data?: SignInForm) => void;
}

export const SignInFormBody: React.FC<Props> = ({ onSuccess }) => {
    const dispatch = useAppDispatch();
    const isTokenObtaining = useAppSelector(selectIsTokenObtaining);
    const settings = useAppSelector(selectAppSettings);

    const validationSchema = useValidation(createLoginValidation);
    const form = useForm<SignInForm>({
        defaultValues: {
            email: '',
            password: '',
        },
        resolver: yupResolver(validationSchema),
    });

    const {
        control,
        handleSubmit,
        watch,
        trigger,
        setError,
        formState: { errors },
    } = form;

    const onSubmit: SubmitHandler<SignInForm> = form => {
        dispatch(
            authActions.obtainAuthToken({
                form,
                onSuccess,
                onError: errors => {
                    setApiErrors(setError, errors as Record<FieldPath<SignInForm>, string>);
                },
            }),
        );
    };

    const { t } = useTranslation();
    const labels = useFormLabels(t);

    return (
        <form id={SignInFormId} onSubmit={handleSubmit(onSubmit)}>
            <div className={cn('fieldset-grid', 'base-paddings')}>
                <AuthFormTitle
                    title={t('titles.signIn', 'Sign in to {{company}}', {
                        company: settings.company_full_title || settings.company_title,
                    })}
                />

                <FormField
                    prefix={SignInFormId}
                    name="email"
                    control={control}
                    component={TextField}
                    label={labels.email}
                />
                <FormField
                    prefix={SignInFormId}
                    name="password"
                    control={control}
                    component={TextField}
                    label={labels.password}
                    widgetProps={{
                        type: 'password',
                        className: 'rmd-text-field-container--autogrow-addon',
                        isRightAddon: false,
                        rightChildren: <ResetPasswordButton email={watch('email')} trigger={trigger} />,
                    }}
                />

                <NonFieldErrors errors={errors} />

                <div className={cn('fieldset-grid-cell', 'flex')}>
                    <SubmitButton prefix={signInId()} isSending={isTokenObtaining}>
                        <FeatureContent contentKey={signInId('submit')} fallback={t('buttons.signIn', 'Sign in')} />
                    </SubmitButton>
                </div>
            </div>
        </form>
    );
};
