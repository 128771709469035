import cn from 'classnames';
import React, { AnchorHTMLAttributes } from 'react';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import type { ButtonThemeProps } from '@react-md/button';
import { Typography } from '@react-md/typography';
import { ButtonLink } from '@components/link';
import { FeatureContent } from '@components/feature-content';
import { StickyColumnWithPreloader } from '@components/sticky-column';
import { PricingList, PricingRecord } from '@components/pricing-list';
import { SidebarSectionHead, SidebarSection } from '@components/sidebar-section';
import { TbdMessages, TbdSidebarContainer } from '@components/tbd';
import { selectAppSettings } from '@modules/app';
import { selectWholeOrderPageTbd } from '@modules/order-page';
import { LinkStyledButton } from '@components/link';
import { useBuildLocation } from '@hooks';
import { decimalToBoolean, getFileNameByUrl, getNodeIdComposer, reverse } from '@utils';
import { Order } from '@types';
import { RootPrefix } from '../../constants';

import styles from './order-page-sidebar.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'sidebar');

interface OrderPageSidebarProps {
    order: Partial<Order>;
}

export const OrderPageSidebar: React.FC<OrderPageSidebarProps> = ({ order }) => {
    const settings = useAppSelector(selectAppSettings);
    const orderTbd = useAppSelector(selectWholeOrderPageTbd);

    const { t } = useTranslation();

    const baseInvoiceLinkProps: Partial<ButtonThemeProps> & AnchorHTMLAttributes<HTMLAnchorElement> = {
        id: _id('invoice_link'),
        theme: 'primary',
        themeType: 'outline',
        className: cn('rmd-button--text-large', 'w-full'),
        children: (
            <FeatureContent contentKey={_id('invoice_link')} fallback={t('buttons.invoicePage', 'Invoice Page')} />
        ),
    };
    const invoice = order?.main_invoice;
    const legacyInvoicePage = invoice?.pay_url; // old widget invoice page link
    const invoiceLocation = useBuildLocation({
        to: reverse('invoice', {
            invoiceId: invoice?.id ?? '-',
            hash: legacyInvoicePage ? getFileNameByUrl(legacyInvoicePage.replace(/\/$/, '')) : '-',
        }),
    });

    return (
        <div className={styles.column}>
            <StickyColumnWithPreloader
                stickyParentClassName={styles.column}
                className={cn('rounded-box', styles.box)}
                loading={false}
            >
                <SidebarSectionHead className="margin-bottom">
                    <Typography type="headline-3" component="div">
                        {t('labels.total', 'Total')}
                    </Typography>
                </SidebarSectionHead>

                {orderTbd.length ? (
                    <TbdSidebarContainer>
                        <TbdMessages list={orderTbd} />
                    </TbdSidebarContainer>
                ) : (
                    <SidebarSection span>
                        <PricingList>
                            <PricingRecord
                                label={t('labels.modelsPrice', 'Models price')}
                                value={order.models_naked_price}
                            />

                            {!!order.startup_cost && (
                                <PricingRecord
                                    label={t('labels.startupCost', 'Startup Cost')}
                                    value={order.startup_cost}
                                />
                            )}

                            {!!order.delivery_price && (
                                <PricingRecord label={t('titles.delivery', 'Delivery')} value={order.delivery_price} />
                            )}

                            {decimalToBoolean(order.price_corrections_cost) && (
                                <PricingRecord
                                    label={t('subtitles.priceCorrections', 'Additional goods and services')}
                                    value={order.price_corrections_cost}
                                    sign={parseInt(order.price_corrections_cost!) > 0 ? '+' : undefined}
                                />
                            )}

                            {!!order.total_discount_value && (
                                <PricingRecord
                                    label={t('labels.discount', 'Discount')}
                                    percent={
                                        order.products_discount_value
                                            ? undefined
                                            : Number(order.order_discount_in_percents)
                                    }
                                    value={order.total_discount_value}
                                    sign="-"
                                />
                            )}

                            <PricingRecord
                                label={t('labels.subtotal', 'Subtotal')}
                                value={order.total_price_without_tax}
                            />

                            {!!order.tax_value && (
                                <PricingRecord
                                    label={settings.tax_name}
                                    value={order.tax_value}
                                    percent={parseFloat(order.tax_in_percents ?? '0')}
                                />
                            )}
                        </PricingList>
                        <PricingList divider>
                            <PricingRecord
                                label={t('labels.total', 'Total')}
                                value={order.total_price}
                                keyType="headline-6"
                                valueType="headline-6"
                            />
                        </PricingList>
                    </SidebarSection>
                )}

                <SidebarSection span>
                    <div className={cn('flex', 'justify-center')}>
                        {settings.use_new_payment_page ? (
                            <ButtonLink {...baseInvoiceLinkProps} {...invoiceLocation} />
                        ) : (
                            <LinkStyledButton {...baseInvoiceLinkProps} href={legacyInvoicePage} />
                        )}
                    </div>
                </SidebarSection>
            </StickyColumnWithPreloader>
        </div>
    );
};
