import React, { ReactElement } from 'react';
import cn from 'classnames';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { SidebarSection } from '@components/sidebar-section';
import { AppSettings, Invoice, PaymentStage } from '@types';
import { getOrderStatus, getPaymentStageState, sidebarId } from '../helpers';
import { FeatureContent } from '@components/feature-content';

import styles from './invoice-sidebar.module.scss';

interface Props {
    settings: AppSettings;
    paymentStage: PaymentStage;
    invoice: Invoice;
}

type InvoiceStatusType = 'info' | 'error' | 'warning' | 'success';
type InvoicePaymentStatus = [string, InvoiceStatusType, string];
type InvoiceStatus = [ReactElement, InvoiceStatusType];

// function resolveStatusByOrder({ settings, invoice }: Omit<Props, 'paymentStage'>, t: TFunction): InvoiceStatus {
//     let label: string;
//     let labelType: InvoiceStatusType = 'info';
//
//     const { isCreated, isCancelled, isWaitingForReview, isFirmOfferSent, isPoProvided, isPoPaymentDue } =
//         getOrderStatus(invoice.order);
//
//     if (isCreated) {
//         label = t('labels.notReady', 'Not ready');
//     } else if (isCancelled) {
//         label = t('labels.cancelled', 'Cancelled');
//         labelType = 'error';
//     } else if (isWaitingForReview) {
//         label = settings.pre_offer_title;
//     } else if (isFirmOfferSent) {
//         label = settings.offer_title;
//     } else if (isPoProvided) {
//         label = t('labels.poProvided', 'PO provided');
//         labelType = 'warning';
//     } else if (isPoPaymentDue) {
//         label = t('labels.poPaymentDue', 'Payment due');
//         labelType = 'warning';
//     } else {
//         label = t('labels.readyForPayment', 'Ready for payment');
//         labelType = 'warning';
//     }
//
//     return [label, labelType];
// }
//
// // it's a crazy mix between order statuses and payment transitions...
// function resolveInvoiceStatus({ paymentStage, ...props }: Props, t: TFunction): InvoiceStatus {
//     switch (paymentStage) {
//         case PaymentStage.Allowed:
//         case PaymentStage.Denied:
//         case PaymentStage.NeedsInitialization:
//             return resolveStatusByOrder(props, t);
//         case PaymentStage.Cancelled:
//             return [t('labels.cancelled', 'Cancelled'), 'error'];
//         case PaymentStage.Paid:
//             return [t('labels.paymentSucceed', 'Payment succeeded'), 'success'];
//         case PaymentStage.Processing:
//             return [t('labels.paymentProcessing', 'Payment is processing'), 'warning'];
//     }
// }

export function resolveStatusByPayment(stage: PaymentStage, t: TFunction): InvoicePaymentStatus {
    switch (stage) {
        case PaymentStage.Allowed:
        case PaymentStage.Denied:
        case PaymentStage.NeedsInitialization:
            return [t('labels.readyForPayment', 'Ready for payment'), 'warning', sidebarId('ready')];
        case PaymentStage.Cancelled:
            return [t('labels.cancelled', 'Cancelled'), 'error', sidebarId('cancelled')];
        case PaymentStage.Paid:
            return [t('labels.paymentSucceed', 'Payment succeeded'), 'success', sidebarId('succeeded')];
        case PaymentStage.Processing:
            return [t('labels.paymentProcessing', 'Payment is processing'), 'warning', sidebarId('processing')];
    }
}

// todo need to go back to the commented code when we:
//  1) get rid of invoice.is_paid, backend should return PaymentStage.Paid (in invoice.payment_status)
//      if invoice.is_paid is true for deprecated providers
//  2) we will approve the priority of order statuses and PaymentStage
function resolveInvoiceStatus({ paymentStage, settings, invoice }: Props, t: TFunction): InvoiceStatus {
    let label: string;
    let labelId: string;
    let labelType: InvoiceStatusType = 'info';

    const { isCreated, isCancelled, isWaitingForReview, isFirmOfferSent, isPoProvided, isPoPaymentDue } =
        getOrderStatus(invoice.order);
    const { isPaymentPaid } = getPaymentStageState(paymentStage);

    // first we set up the old invoices with invoice.is_paid and force some order statuses, then fallback to PaymentStage
    if (invoice.is_paid || isPaymentPaid) {
        label = t('labels.paymentSucceed', 'Payment succeeded');
        labelType = 'success';
        labelId = sidebarId('succeeded');
    } else if (isCreated) {
        label = t('labels.notReady', 'Not ready');
        labelId = sidebarId('not_ready');
    } else if (isCancelled) {
        label = t('labels.cancelled', 'Cancelled');
        labelType = 'error';
        labelId = sidebarId('cancelled');
    } else if (isWaitingForReview) {
        label = settings.pre_offer_title;
        labelId = sidebarId('pre_offer');
    } else if (isFirmOfferSent) {
        label = settings.offer_title;
        labelId = sidebarId('firm_offer');
    } else if (isPoProvided) {
        label = t('labels.poProvided', 'PO provided');
        labelType = 'warning';
        labelId = sidebarId('po_provided');
    } else if (isPoPaymentDue) {
        label = t('labels.poPaymentDue', 'Payment due');
        labelType = 'warning';
        labelId = sidebarId('payment_due');
    } else {
        [label, labelType, labelId] = resolveStatusByPayment(paymentStage, t);
    }

    const labelElement = <FeatureContent contentKey={labelId} fallback={label} />;

    return [labelElement, labelType];
}

export const InvoiceSidebarStatus: React.FC<Props> = props => {
    const { t } = useTranslation();
    const [label, labelType] = resolveInvoiceStatus(props, t);
    return (
        <SidebarSection className={'rmd-typography--center'} span>
            <Typography type="headline-4" component="div" className={cn(styles.status, styles[labelType])}>
                {label}
            </Typography>
        </SidebarSection>
    );
};
