import flatten from 'lodash/flatten';
import { createSelector } from 'reselect';
import { RootState, UploadFileAcceptType, ObjectModelStatus } from '@types';

export const selectUploadJob = (state: RootState) => state.uploadModels.uploadJob;
export const selectIsUploadJobCreating = (state: RootState) => state.uploadModels.isUploadJobCreating;
export const selectIsUploadJobBinding = (state: RootState) => state.uploadModels.isUploadJobBinding;
export const selectIsModelsUploading = (state: RootState) => state.uploadModels.isUploading;
export const selectIsModelsStatusChecking = (state: RootState) => state.uploadModels.isStatusChecking;
export const selectIsPollingCheckModelsStatusActive = (state: RootState) => state.uploadModels.isPollingActive;
export const selectUploadError = (state: RootState) => state.uploadModels.error;

export const selectUploadedModelRejectedFiles = (state: RootState) => state.uploadModels.rejectedFiles;

export const selectUploadedModelFiles = (state: RootState) => state.uploadModels.acceptedFiles;
export const selectUploadedModelFilesIds = (state: RootState) => state.uploadModels.acceptedFilesIds;
export const selectUploadedModelFileById = (state: RootState, uuid: string) => state.uploadModels.acceptedFiles[uuid];
// export const selectUploadedModelFileById = (state: RootState, uuid: string) =>
//     state.uploadModels.acceptedFiles.hasOwnProperty(uuid) && state.uploadModels.acceptedFiles[uuid];

export const selectUploadModelsQueue = createSelector(selectUploadedModelFiles, acceptedFiles =>
    Object.values(acceptedFiles)
        .filter(uploading => uploading.upload)
        .map(uploading => uploading.upload as UploadFileAcceptType),
);

export const selectAnalyzingModelIds = createSelector(selectUploadedModelFiles, acceptedFiles =>
    flatten(
        Object.values(acceptedFiles)
            .filter(uploading => uploading.uploaded && uploading.uploaded.object_models)
            .map(uploading => uploading.uploaded!.object_models!.filter(model => model.id)),
    )
        .filter(model => model.status === ObjectModelStatus.Progress)
        .map(model => model.id as number),
);
