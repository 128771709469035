import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { Configuration, ConfigurationProps } from '@react-md/layout';
import { MessageQueue } from '@react-md/alert';
import { AppAnalyticsProvider } from '@components/analytics';
import { DocumentHeadOverrides } from '@components/document-head-overrides';
import { getBaseErrorBoundaryProps } from '@components/error-boundaries';
import { Fallback } from '@components/fallback';
import { Icons } from '@components/icons';
import { Interceptor } from '@components/interceptor';
import { router } from '@components/routes';
import { Theme } from '@components/theme';
import { store } from '@app/store';

const overrides: ConfigurationProps = {
    // defaultDir: "rtl",
    // tooltipDefaultDelay: 500, // TODO move
    // tooltipDelayTimeout: 500,
    hoverMode: {
        defaultVisibleInTime: 500,
    },
    formTheme: {
        theme: 'outline',
    },
    desktopLargeMinWidth: 1440,
    desktopMinWidth: 1024,
    tabletMaxWidth: 1023,
    tabletMinWidth: 600,
    phoneMaxWidth: 599,
    icons: {
        checkbox: <Icons.Checkmark />,
        radio: <Icons.Circle />,
        expander: <Icons.Chevron className="rmd-typography--secondary" />,
        dropdown: <Icons.Chevron className="rmd-typography--secondary" />,
        upload: <Icons.Paperclip className="rmd-typography--secondary" />,
    },
    children: '',
};

function App() {
    return (
        <Suspense fallback={<Fallback />}>
            <ErrorBoundary {...getBaseErrorBoundaryProps({ className: 'margin-top' })}>
                <AppAnalyticsProvider>
                    <Provider store={store}>
                        <Theme />
                        <DocumentHeadOverrides />
                        <Configuration {...overrides}>
                            <MessageQueue id="notifications" duplicates="restart">
                                <Interceptor />
                                <RouterProvider router={router} />
                            </MessageQueue>
                        </Configuration>
                    </Provider>
                </AppAnalyticsProvider>
            </ErrorBoundary>
        </Suspense>
    );
}

export default App;
