import { Invoice, OrderUpdateViaInvoiceData, OrderPoData, DataForIPN, DataForITN } from '@types';
import { axiosInstance } from '@services/axios-instance';
import { prepareFormData } from '@utils';
import { AxiosResponse } from 'axios';

function sleep<T>(ms: number) {
    return new Promise<T>(resolve => setTimeout(resolve, ms));
}

export class PaymentService {
    static init() {
        return new PaymentService();
    }

    // loadInvoice(params: { invoiceId: string; hash: string }) {
    //     return sleep<{ invoice: Invoice; compane_name: string }>(4000).then(() => {
    //         console.log('sdfsdfsdf');
    //         return {
    //             company_short_name: '4taps',
    //             invoice: {
    //                 id: 234,
    //                 is_order_invoice: false,
    //                 is_paid: false,
    //                 cost: 3243432,
    //                 payment_received: false,
    //                 pay_url: 'sdfsf',
    //             },
    //         };
    //
    //         // throw {response: {status: 404}}
    //     });
    // }

    loadInvoicePayment(params: { invoiceId: string; hash: string }) {
        return axiosInstance.post<Invoice>(`/v2/invoices/${params.invoiceId}/${params.hash}/payment-intent/`, {
            params: {
                format: 'json',
            },
        });
    }

    loadInvoice(params: { invoiceId: string; hash: string }) {
        return axiosInstance.get<Invoice>(`/v2/invoices/${params.invoiceId}/${params.hash}/`, {
            params: {
                format: 'json',
            },
        });
    }

    updateInvoice({ invoiceId, hash, data }: { invoiceId: string; hash: string; data: OrderUpdateViaInvoiceData }) {
        return axiosInstance.patch<Invoice>(
            `/v2/invoices/${invoiceId}/${hash}/`,
            (data as OrderPoData).attached_po !== undefined ? prepareFormData({ ...data }) : data,
        );
    }

    getDataForIPN(invoiceId: string, hash: string): Promise<AxiosResponse<DataForIPN>> {
        return axiosInstance.get<DataForIPN>(`/v2/invoices/${invoiceId}/${hash}/paypal_ipn_data/`, {
            params: {
                format: 'json',
            },
        });
    }

    getDataForITN(invoiceId: string, hash: string): Promise<AxiosResponse<DataForITN>> {
        return axiosInstance.get<DataForITN>(`/v2/invoices/${invoiceId}/${hash}/payfast_itn_data/`, {
            params: {
                format: 'json',
            },
        });
    }
}
