import { TFunction } from 'i18next';
import { Point, ObjectModel, ObjectModelRepairedRes, ObjectModelStatus, ObjectModelUnits } from '@types';

export class UserObjectModelModel implements ObjectModel {
    static readonly usedFields: Array<keyof ObjectModel> = [
        'parent_model',
        'id',
        'title',
        'size',
        'units',
        'thumb_120x120',
        'child_models',
        'repaired_res',
        'file_model_original_url',
        'file_model_stl_repaired_url',
        'initial_status',
        'date_created',
    ];

    static from(data: Record<string, any>) {
        return new UserObjectModelModel(
            data.id,
            data.parent_model,
            data.title,
            data.size,
            data.units,
            data.thumb_120x120,
            data.child_models,
            data.repaired_res,
            data.file_model_original_url,
            data.file_model_stl_repaired_url,
            data.initial_status,
            data.date_created,
        );
    }

    constructor(
        public id: number,
        public parent_model: number,
        public title: string,
        public size: Point,
        public units: ObjectModelUnits,
        public thumb_120x120: string,
        public child_models: number[],
        public repaired_res: ObjectModelRepairedRes,
        public file_model_original_url: string,
        public file_model_stl_repaired_url: string,
        public initial_status: ObjectModelStatus,
        public date_created: string,
    ) {}

    getFacets(t: TFunction) {
        const labels: { [Field in keyof ObjectModelRepairedRes]-?: string } = {
            number_of_facets: t('labels.numberOfFacets', 'Number of facets'),
            facets_removed: t('labels.facetsRemoved', 'Facets removed'),
            facets_added: t('labels.facetsAdded', 'Facets added'),
            facets_reversed: t('labels.facetsReversed', 'Facets reversed'),
            edges_fixed: t('labels.edgesFixed', 'Edges fixed'),
        };

        for (let field of Object.keys(labels) as Array<keyof ObjectModelRepairedRes>) {
            const value = this.repaired_res[field];
            if (value) {
                return { label: labels[field], value };
            }
        }
    }
}
