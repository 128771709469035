import { DxfViewer } from 'dxf-viewer';
import { useEffect, MutableRefObject, RefObject } from 'react';
import { initDxfViewer, mergeDxfViewerLoadParams } from './dxf-viewer';

export const useInitDxfViewer = (
    ref: MutableRefObject<DxfViewer | undefined>,
    visualizer: RefObject<HTMLDivElement>,
) => {
    useEffect(() => {
        if (!visualizer.current) return;

        console.log(visualizer.current, 'initDxfViewer');
        ref.current = initDxfViewer(visualizer.current);

        return () => {
            console.log(ref.current, 'Destroy');
            ref.current?.Destroy();
            ref.current = undefined;
        };
    }, [ref, visualizer]);
};

export const useSetupDxfFile = (
    viewer: MutableRefObject<DxfViewer | undefined>,
    url: string,
    callback?: (fileUrl: string) => void,
) => {
    useEffect(() => {
        const load = async () => {
            if (!viewer.current) return;

            try {
                await viewer.current.Load(
                    mergeDxfViewerLoadParams({
                        url,
                        progressCbk: (phase, progress, total) => {
                            console.log(phase, progress, total, 'progress');
                        },
                    }),
                );
            } catch (error) {
                console.warn(error);
            } finally {
                callback && callback(url);
                console.log(viewer.current, 'success');
            }
        };

        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewer, url]);
};
