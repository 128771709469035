import * as Three from 'three';
import type { Mesh, Group } from 'three';
import { ThreeMFLoader } from 'three/examples/jsm/loaders/3MFLoader';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { getFileExtensionByUrl } from '@utils';

class ModelsLoader {
    load(fileUrl: string) {
        let isCanceled = false;

        return {
            promise: new Promise<Group | Mesh>((resolve, reject) => {
                const onProgress = (event: ProgressEvent) => {
                    if (isCanceled) {
                        new Error('Loading file aborted.');
                    }
                };

                const extension = getFileExtensionByUrl(fileUrl);

                switch (extension) {
                    case '3mf': {
                        const loader = new ThreeMFLoader();
                        loader.load(
                            fileUrl,
                            group => {
                                resolve(group);
                            },
                            onProgress,
                        );
                        break;
                    }

                    case 'stl': {
                        const loader = new STLLoader();
                        loader.load(
                            fileUrl,
                            bufferGeometry => {
                                resolve(new Three.Mesh(bufferGeometry));
                            },
                            onProgress,
                        );
                        break;
                    }

                    default: {
                        reject(new Error('Unsupported model file extension.'));
                    }
                }
            }),
            cancel() {
                isCanceled = true;
            },
        };
    }
}

export const modelsLoader = new ModelsLoader();
