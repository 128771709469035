import { Invoice, Order, OrderStatuses, PaymentStage } from '@types';
import { getNodeIdComposer } from '@utils';
import { RootPrefix } from './constants';
import { LINE_ITEM_EMPTY_VALUE } from '@components/line-items';

export const tilesId = getNodeIdComposer(RootPrefix, 'tiles');
export const partsId = getNodeIdComposer(RootPrefix, 'parts');
export const sidebarId = getNodeIdComposer(RootPrefix, 'sidebar');

export function getOrderStatus(order: Invoice['order']) {
    const isInitial = order.status === OrderStatuses.Initial;
    const isCreated = order.status === OrderStatuses.Created;
    const isCancelled =
        order.status === OrderStatuses.CancelledByAdmin || order.status === OrderStatuses.CancelledByUser;
    const isWaitingForReview = order.status === OrderStatuses.WaitingForReview;
    const isFirmOfferSent = order.status === OrderStatuses.FirmOfferSent;
    const isPoProvided = order.status === OrderStatuses.PoProvided;
    const isPoPaymentDue = order.status === OrderStatuses.PoPaymentDue;

    return {
        isInitial,
        isCreated,
        isCancelled,
        isWaitingForReview,
        isFirmOfferSent,
        isPoProvided,
        isPoPaymentDue,
    };
}

export function getPaymentStageState(paymentStage: PaymentStage) {
    const needsInitialization = paymentStage === PaymentStage.NeedsInitialization;
    const isPaymentAllowed = paymentStage === PaymentStage.Allowed;
    const isPaymentCancelled = paymentStage === PaymentStage.Cancelled;
    const isPaymentProcessing = paymentStage === PaymentStage.Processing;
    const isPaymentPaid = paymentStage === PaymentStage.Paid;

    return {
        needsInitialization,
        isPaymentAllowed,
        isPaymentProcessing,
        isPaymentCancelled,
        isPaymentPaid,
    };
}

export function convertDataToString(data: Order['additional_billing_contacts']) {
    if (Array.isArray(data) && data.length > 0) return data.join(', ');
    // Fallback for orders, saved before (type was change from string to array)
    if (typeof data === 'string') return data;
    return LINE_ITEM_EMPTY_VALUE;
}
