import cn from 'classnames';
import trim from 'lodash/trim';
import React, { ChangeEventHandler, KeyboardEventHandler, useCallback, useEffect, memo } from 'react';
import { DropzoneOptions } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@react-md/typography';
import { TextField } from '@react-md/form';
import { FeatureContent } from '@components/feature-content';
import { FormField } from '@components/form-field';
import { FileField } from '@components/file-field';
import { SidebarSection } from '@components/sidebar-section';
import { Order, OrderUpdateViaInvoiceData, OrderPoData } from '@types';
import { useValidation } from '@hooks';
import { getNodeIdComposer } from '@utils';
import { createPurchaseOrderValidation } from '../form-validation';
import { PurchaseOrderFormId, RootPrefix } from '../constants';
import { FeatureKeys as FF, useFeatureFlag } from '@components/feature-flags';

import styles from './invoice-purchase-order.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'purchase_order');
const formId = getNodeIdComposer(PurchaseOrderFormId);

interface InvoicePurchaseOrderProps {
    update: (data: OrderUpdateViaInvoiceData) => void;
    poNumber: Order['po_number'];
    file: Order['attached_po'];
}

export const InvoicePurchaseOrder = memo<InvoicePurchaseOrderProps>(({ poNumber, file, update }) => {
    const isPoFileRequired = useFeatureFlag(FF.InvoicePage.PoFileRequired);

    const {
        control,
        register,
        getValues,
        setValue,
        trigger,
        formState: { errors, isValid },
    } = useForm<Partial<OrderPoData>>({
        defaultValues: {
            po_number: poNumber,
        },
        resolver: yupResolver(createPurchaseOrderValidation(isPoFileRequired)),
    });

    useEffect(() => {
        register('attached_po');
    }, [register]);

    const handlePurchaseOrderSubmit = useCallback(
        (values: Partial<OrderPoData>) => {
            const data = { ...getValues(), ...values };
            update(data as OrderPoData);
        },
        [getValues, update],
    );

    // PO number
    const updateNumber = useCallback(
        (value: string) => {
            value = trim(value);
            if (isValid && value && value !== poNumber) {
                handlePurchaseOrderSubmit({ po_number: value });
            }
        },
        [handlePurchaseOrderSubmit, poNumber, isValid],
    );

    const handleBlurNumber: ChangeEventHandler<HTMLInputElement> = useCallback(
        ({ target }) => {
            updateNumber(target.value);
        },
        [updateNumber],
    );

    const handleKeyDownNumber: KeyboardEventHandler<HTMLInputElement> = useCallback(
        ({ code, currentTarget }) => {
            if (code === 'Enter' || code === 'NumpadEnter') {
                updateNumber(currentTarget.value);
            }
        },
        [updateNumber],
    );

    // PO file
    const handleDropAccepted: DropzoneOptions['onDropAccepted'] = useCallback(
        acceptedFiles => handlePurchaseOrderSubmit({ attached_po: acceptedFiles[0] }),
        [handlePurchaseOrderSubmit],
    );

    const handleFileChange = useCallback(
        files => {
            setValue('attached_po', files.length ? files[0] : undefined);
            trigger();
        },
        [setValue, trigger],
    );

    const { t } = useTranslation();

    const getFileAddedMessage = (file: string) => (
        <Trans i18nKey="messages.poUploaded">
            File <strong>{{ file }}</strong> PO uploaded.
        </Trans>
    );

    const attachFileLabelId = formId('attached_po', 'label');

    return (
        <SidebarSection className={cn(styles.box)} span>
            <Typography id={_id('title')} type="headline-5" component="div" className="small-margin-bottom">
                <FeatureContent contentKey={_id('title')} fallback={t('labels.purchaseOrder', 'Purchase Order')} />
            </Typography>
            <div className={cn('fieldset-grid', styles.form)}>
                <FormField
                    prefix={PurchaseOrderFormId}
                    name="po_number"
                    className={styles.number}
                    control={control}
                    component={TextField}
                    widgetProps={{
                        placeholder: t('labels.poNumber', 'PO Number'),
                        onBlur: handleBlurNumber,
                        onKeyDown: handleKeyDownNumber,
                        onChange: () => trigger(),
                    }}
                />
                <FileField
                    className="small-margin-top"
                    fileClassName={styles.file}
                    initialFiles={file ? [file] : undefined}
                    onChange={handleFileChange}
                    getFileAddedMessage={getFileAddedMessage}
                    dropzoneProps={{
                        noClick: true,
                        maxFiles: 1,
                        // accept: ['.txt', '.step'],
                        minSize: 1,
                        onDropAccepted: handleDropAccepted,
                    }}
                    inputProps={{
                        id: formId('attached_po', 'field'),
                        labelId: attachFileLabelId,
                        name: 'attached_po',
                        className: cn('rmd-file-input-label', 'rmd-button--flat'),
                        children: (
                            <FeatureContent
                                contentKey={attachFileLabelId}
                                fallback={t('buttons.attachPoFile', 'Attach PO file')}
                            />
                        ),
                    }}
                    showAlerts={true}
                >
                    {errors.attached_po?.message && (
                        <Typography
                            type="body-2"
                            component="div"
                            className={cn('fade-in', 'rmd-typography--theme-error', styles.error)}
                        >
                            {errors.attached_po?.message}
                        </Typography>
                    )}
                </FileField>
            </div>
        </SidebarSection>
    );
});
