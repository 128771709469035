import cn from 'classnames';
import get from 'lodash/get';
import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio } from '@react-md/form';
import { Button } from '@react-md/button';
import { Typography } from '@react-md/typography';
import { PreloaderBox } from '@components/preloader';
import { Price } from '@components/price';
import { Alert, AlertHeading } from '@components/alert';
import { updateShippingRates } from '@modules/order';
import { OrderForm } from '@types';
import {
    selectIsLoadingRates,
    selectIsPollingRatesActive,
    selectIsShippingRatesReady,
    selectIsShippingRatesDepsValid,
    selectShippingRatesErrors,
    selectShippingRates,
} from '@modules/delivery-rates';
import { formId, shipmentId } from './helpers';
import { RatesDeps } from './constants';

import styles from './order-shipment-rates.module.scss';

interface OrderShipmentRatesProps {}

export const OrderShipmentRates: React.FC<OrderShipmentRatesProps> = () => {
    const dispatch = useAppDispatch();
    const isLoadingRates = useAppSelector(selectIsLoadingRates);
    const isPollingRatesActive = useAppSelector(selectIsPollingRatesActive);
    const isShippingRatesReady = useAppSelector(selectIsShippingRatesReady);
    const isShippingRatesDepsValid = useAppSelector(selectIsShippingRatesDepsValid);
    const shippingRatesErrors = useAppSelector(selectShippingRatesErrors);
    const shippingRates = useAppSelector(selectShippingRates);

    const hasRates = Boolean(shippingRates.length);

    const { control, trigger, watch, setValue, formState } = useFormContext<OrderForm>();

    const shippingRateId = watch('shippingRateId');

    useEffect(() => {
        if (hasRates) {
            // set default
            if (!shippingRateId || !shippingRates.find(rate => rate.id.toString() === shippingRateId)) {
                setValue('shippingRateId', shippingRates[0].id.toString());
            }
        } else {
            // reset obsolete
            shippingRateId && setValue('shippingRateId', '');
        }
    }, [shippingRateId, hasRates, shippingRates, setValue]);

    useEffect(() => {
        // update order entity to display shipping price in order sidebar
        dispatch(updateShippingRates({ rateId: shippingRateId ? shippingRateId : undefined }));
    }, [dispatch, shippingRateId]);

    const validateRatesDeps = useCallback(() => {
        trigger(RatesDeps, { shouldFocus: true }).then(isValid => {
            if (isValid) return;

            RatesDeps.forEach(field => {
                const error = get(formState.errors, field);
                if (error && error.type === 'required') {
                    setValue(field, '', { shouldTouch: true });
                }
            });
        });
    }, [trigger, formState.errors, setValue]);

    const { t } = useTranslation();

    return (
        <PreloaderBox show={isLoadingRates || (isPollingRatesActive && !isShippingRatesReady && !shippingRatesErrors)}>
            {!isShippingRatesDepsValid ? (
                <Alert show variant="outlined" type="warning">
                    <AlertHeading iconType="warning">
                        <Trans
                            parent={Typography}
                            type="body-1"
                            className={styles.text}
                            component={'div'}
                            i18nKey="messages.shippingRatesDeps"
                            defaults={'Fill in the <btn>required fields</btn> to get delivery price.'}
                            components={{
                                btn: (
                                    <Button
                                        id={shipmentId('validate_rates_deps')}
                                        theme="primary"
                                        className={cn('rmd-button--flat', 'rmd-button--inline')}
                                        disableRipple
                                        onClick={validateRatesDeps}
                                    />
                                ),
                            }}
                        />
                    </AlertHeading>
                </Alert>
            ) : !hasRates ? (
                <Alert show variant="outlined" type="warning">
                    <AlertHeading iconType="warning">
                        <Typography type="body-1" className={styles.text} component={'div'}>
                            {t(
                                'messages.shippingRatesError',
                                'No shipping rates found. Please check your address information to get delivery price.',
                            )}
                        </Typography>
                    </AlertHeading>
                </Alert>
            ) : (
                <Controller
                    control={control}
                    name="shippingRateId"
                    // defaultValue={shippingRateId}
                    render={({ field: { onChange: handleChange, onBlur: handleBlur, value, name } }) => (
                        <div
                            className={cn('base-paddings', 'fieldset-grid', 'rounded-box', 'bg-secondary', styles.box)}
                        >
                            {shippingRates.map(rate => {
                                const rateId = rate.id.toString();
                                return (
                                    <div key={rateId} className={cn('fieldset-grid-cell', styles.cell)}>
                                        <Radio
                                            id={formId(name, 'field', rateId)}
                                            name={name}
                                            // defaultValue={shippingRateId}
                                            label={rate.title}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={rateId}
                                            checked={rateId === shippingRateId}
                                            toggleClassName="rmd-toggle--radio"
                                            disableIconOverlay
                                            disableProgrammaticRipple
                                            disableRipple
                                        />

                                        {rate.note && (
                                            <Typography
                                                type="subtitle-2"
                                                weight="semi-bold"
                                                className={styles.note}
                                                component={'span'}
                                            >
                                                {rate.note}
                                            </Typography>
                                        )}

                                        <Typography
                                            type="headline-5"
                                            className={cn(styles.cost, {
                                                'shipment_section-rates-custom_price': rate.custom,
                                            })}
                                            component={'div'}
                                        >
                                            <Price>{rate.cost}</Price>{' '}
                                            {rate.round_way_shipment &&
                                                t('labels.rateRounds', '(doubled because of round-way)')}
                                        </Typography>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                />
            )}
        </PreloaderBox>
    );
};
