import type { Controller, GUI } from 'lil-gui';
import isString from 'lodash/isString';
import pickBy from 'lodash/pickBy';
import { colord } from 'colord';
import { WidgetCustomizationValue } from '@types';

export enum GuiInputType {
    PALETTE, // color
    TEXT, // string
    SLIDER, // number
}

export enum GuiInputUnits {
    PX,
    REM,
}

export enum GuiInputCategory {
    NONE,
    SURFACE,
    BORDER,
    PRIMARY,
    TYPOGRAPHY,
    MISCELLANEOUS,
}

export interface GuiInput {
    label: string;
    type: GuiInputType;
    category: GuiInputCategory;
    varName: string; // name of the theme field in settings
    startValue?: string | number; // a value obtained from the document's CSS properties and handled by the GUI controller
    defaultValue: string | number; // default value if omitted startValue
    folder?: GUI; // Adds automatically when creates
    controller?: Controller; // Adds automatically when creates
    enabled?: boolean;
    disabled?: boolean;
}

export interface GuiSlider extends GuiInput {
    minValue: number;
    maxValue: number;
    step: number;
    units: GuiInputUnits;
}

export interface ThemeConfigGuiOptions {
    onSave?: (themeConfig: WidgetCustomizationValue) => void;
    onClose?: () => void;
    customTheme: WidgetCustomizationValue;
}

let prevProperties: Array<string> = [];
const currentProperties: Set<string> = new Set();

function composeCustomPropertyName(...path: string[]) {
    return path.join('-');
}

export function setCustomProperty(name: string, value: string) {
    if (isString(value)) {
        const { style } = document.documentElement;
        const property = '--' + name.replace(/^--/, '');
        style.setProperty(property, value);
        currentProperties.add(property);
    }
}

const rgbColors = [
    'color-background-primary',
    'color-text-primary',
    'color-text-secondary',
    'color-ui-primary',
    'color-ui-secondary',
    'color-ui-success',
    'color-ui-warning',
    'color-ui-error',
];

export function setRgbColorCustomProperty(property: string, hex: string) {
    if (rgbColors.includes(property)) {
        const color = colord(hex);
        if (!color.isValid()) return;

        const { r, g, b } = color.toRgb();

        setCustomProperty(composeCustomPropertyName(property, 'rgb'), `${r}, ${g}, ${b}`);
    }
}

function setCustomProperties(data: WidgetCustomizationValue) {
    Object.entries(data).forEach(([property, value]) => {
        setRgbColorCustomProperty(property, value);
        setCustomProperty(property, value);
    });
}

export function resetObsoleteProperties() {
    if (prevProperties.length) {
        const obsoleteProperties = prevProperties.filter(property => !currentProperties.has(property)); // difference

        obsoleteProperties.forEach(property => {
            const { style } = document.documentElement;
            style.removeProperty(property);
        });
    }

    prevProperties = Array.from(currentProperties);
    currentProperties.clear();
}

// when the application starts we set the old variables (from local storage),
// after getting the application settings we have to set the variables again and remove the obsolete ones
export function setCustomTheme(data: WidgetCustomizationValue) {
    setCustomProperties(pickBy(data, value => typeof value !== 'object'));
    resetObsoleteProperties();
}

export function getDocumentVarValue(property: string): string {
    const style = getComputedStyle(document.documentElement);
    return style.getPropertyValue(property);
}

export function convertPxToRem(value: number): number {
    const baseFontSize = +getDocumentVarValue('font-size').replace('px', '');
    return value / baseFontSize;
}

export function convertToUnits({ value, input }: { value: string | number; input: GuiInput | GuiSlider }): string {
    if (input.type === GuiInputType.SLIDER) {
        if ((input as GuiSlider).units === GuiInputUnits.REM && typeof value === 'number')
            return `${convertPxToRem(value)}rem`;

        if ((input as GuiSlider).units === GuiInputUnits.PX && typeof value === 'number') return `${value}px`;
    }

    return value.toString().trim();
}
