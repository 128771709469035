import React from 'react';
import { useFeatureFlag } from './hooks';
import { IFeatureToggle } from './types';

export const FeatureToggle = ({ children, flagKey }: IFeatureToggle) => {
    const enabled = useFeatureFlag(flagKey);
    const toggledChildren = React.Children.map(children, child =>
        React.cloneElement(child, {
            enabled,
        }),
    );

    return <>{toggledChildren}</>;
};
