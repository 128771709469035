import { unitToNumber } from '@react-md/utils';
import { getDocumentVarValue, GuiInput, GuiInputType, GuiSlider } from './helpers';

type Input = GuiInput | GuiSlider;
type Inputs = Input[];

class GuiInputsHelper {
    constructor() {
        this.prepareInput = this.prepareInput.bind(this);
    }

    prepareInputs(guiInputsData: Inputs) {
        return guiInputsData.map(this.prepareInput);
    }
    prepareInput(input: Input): GuiInput {
        const { defaultValue, enabled: _enabled } = input;
        const startValue = this.getCurrentValue(input) || defaultValue;
        const enabled = _enabled !== false;
        return { ...input, startValue, enabled };
    }
    getCurrentValue({ varName, type }: Pick<GuiInput, 'type' | 'varName'>) {
        switch (type) {
            case GuiInputType.PALETTE:
            case GuiInputType.TEXT: {
                return this.getStringValue(varName);
            }
            case GuiInputType.SLIDER: {
                return this.getNumberValue(varName);
            }
        }
    }
    getStringValue(varName: string) {
        return getDocumentVarValue(`--${varName}`).trim();
    }
    getNumberValue(varName: string) {
        return unitToNumber(getDocumentVarValue(`--${varName}`));
    }
}

export const guiInputsHelper = new GuiInputsHelper();
