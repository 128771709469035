import { useEffect } from 'react';
import { useQueryParams, JsonParam, withDefault } from 'use-query-params';
import { useAppDispatch } from '@app/hooks';
import { orderActions } from '../slice';

export const useCollectOrderCreationPayload = () => {
    const dispatch = useAppDispatch();
    const [{ payload_passthrough }] = useQueryParams({
        payload_passthrough: withDefault(JsonParam, undefined),
    });

    useEffect(() => {
        dispatch(orderActions.collectCreationPayload(payload_passthrough));
    }, [dispatch, payload_passthrough]);
};
