import cn from 'classnames';
import React from 'react';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { DateTransform } from '@components/date';
import { Label } from '@components/label';
import { Price } from '@components/price';
import { TbdLabel } from '@components/tbd';
import { Order, OrderStatuses } from '@types';
import { selectWholeOrderPageTbd } from '@modules/order-page';

import styles from './order-page-header.module.scss';

interface OrderPageHeaderProps {
    id: Order['company_order_id'];
    orderDate: Order['date'];
    statusDisplay: Order['status_display'];
    totalPrice: Order['total_price'];
    trackingNumber: Order['tracking_number'];
    status: Order['status'];
}

export const OrderPageHeader: React.FC<OrderPageHeaderProps> = ({
    id,
    orderDate,
    totalPrice,
    trackingNumber,
    statusDisplay,
    status,
}) => {
    const orderTbd = useAppSelector(selectWholeOrderPageTbd);
    const isCancelled = status === OrderStatuses.CancelledByAdmin || status === OrderStatuses.CancelledByUser;

    const { t } = useTranslation();
    return (
        <>
            <Typography className="base-paddings" type="headline-3">
                {t('titles.order', 'Order #{{orderId}}', { orderId: id })}
            </Typography>
            <div className={cn('overflow-x-auto', styles.table)}>
                <table>
                    <thead className={styles.head}>
                        <tr>
                            <td className={styles.cell}>
                                <Label className={styles.label}>{t('labels.orderDate', 'Order date')}</Label>
                            </td>
                            <td className={cn(styles.cell, 'rmd-typography--center')}>
                                <Label className={styles.label}>{t('labels.total', 'Total')}</Label>
                            </td>
                            <td className={cn(styles.cell, 'rmd-typography--center')}>
                                <Label className={styles.label}>{t('labels.trackingNumber', 'Tracking number')}</Label>
                            </td>
                            <td className={cn(styles.cell, styles.status)}>
                                <Label className={cn(styles.label, 'rmd-typography--right')}>
                                    {t('labels.status', 'Status')}
                                </Label>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={styles.row}>
                            <td className={styles.cell}>
                                <Typography type="body-2" component={'span'}>
                                    {orderDate ? <DateTransform dateString={orderDate} /> : '-'}
                                </Typography>
                            </td>
                            <td className={cn(styles.cell, 'rmd-typography--center')}>
                                {orderTbd.length ? (
                                    <TbdLabel />
                                ) : (
                                    <Typography type="body-2" component={'span'}>
                                        <Price>{totalPrice ?? 0}</Price>
                                    </Typography>
                                )}
                            </td>
                            <td className={cn(styles.cell, 'rmd-typography--center')}>
                                <Typography type="body-2" component={'span'} className={styles.value}>
                                    {trackingNumber}
                                </Typography>
                            </td>
                            <td
                                className={cn(styles.cell, styles.status, 'rmd-typography--right', {
                                    'rmd-typography--theme-success': status === OrderStatuses.Placed,
                                    'rmd-typography--theme-warning':
                                        status === OrderStatuses.WaitingForReview ||
                                        status === OrderStatuses.FirmOfferSent,
                                    'rmd-typography--theme-error': isCancelled,
                                })}
                            >
                                <Typography type="body-2" component={'span'} className={styles.value}>
                                    {statusDisplay}
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};
