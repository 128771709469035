import cn from 'classnames';
import React, { memo, useMemo } from 'react';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Chip } from '@react-md/chip';
import { Tooltipped } from '@react-md/tooltip';
import { Icons } from '@components/icons';
import { LineItems, LineItem, LineItemProps } from '@components/line-items';
import { Price } from '@components/price';
import { TbdLabel, TbdMessages } from '@components/tbd';
import { Product } from '@types';
import { makeSelectOrderExactTriggersTbd } from '@modules/order';
import { selectDrawingsByProductId } from '@modules/product';
import { lineItemId } from '../helpers';

import styles from './line-item.module.scss';

interface ProductDrawingsLabelProps {
    product: Product;
}

const ProductDrawingsLabel = ({ product }: ProductDrawingsLabelProps) => {
    const drawings = useAppSelector(state => selectDrawingsByProductId(state, product.id));
    const { t } = useTranslation();

    if (!product.accept_drawing) return null;

    return (
        <div className={cn('small-margin-top', styles.files)}>
            <Chip noninteractable leftIcon={<Icons.Paperclip width={12} height={12} />} className={styles.file}>
                {t('labels.filesAttached', '{{count}} files attached', { count: drawings?.length || 0 })}
            </Chip>
        </div>
    );
};

interface ProductFieldProps extends LineItemProps {}

const ProductField = ({ labelClassName, valueClassName, ...props }: ProductFieldProps) => {
    return (
        <LineItem
            type="body-2"
            component="li"
            className="block"
            labelClassName={cn('rmd-typography--hint', labelClassName)}
            valueClassName={cn(styles.value, valueClassName)}
            {...props}
        />
    );
};

interface ProductFieldsProps {
    product: Product;
}

export const LineItemProductDetails = memo<ProductFieldsProps>(
    ({ product }) => {
        const { material_display_name, config_formatted_json, count, total_price, price_per_part } = product;

        const selectOrderExactTriggersTbd = useMemo(makeSelectOrderExactTriggersTbd, []);
        const triggersTbd = useAppSelector(state => selectOrderExactTriggersTbd(state, product.id));

        const { t } = useTranslation();

        const renderPriceWithTbd = (id: string, price: number) =>
            triggersTbd.length ? (
                <Tooltipped id={id} tooltip={<TbdMessages list={triggersTbd} />}>
                    <TbdLabel truncatedTooltipPos="above" />
                </Tooltipped>
            ) : (
                <Price>{price}</Price>
            );

        return (
            <>
                <LineItems className={cn('small-margin-top', styles.fields)}>
                    <ProductField label={t('labels.material', 'Material')} value={material_display_name} />
                    {config_formatted_json.map(({ key, value }) => (
                        <ProductField key={key} label={key} value={value} />
                    ))}
                    <ProductField label={t('labels.quantity', 'Quantity')} value={count} />

                    <ProductField
                        label={t('labels.productPricePerPart', 'Price per part')}
                        valueClassName="text-overflow-ellipsis"
                        value={renderPriceWithTbd(lineItemId('tbd_per_part'), price_per_part)}
                    />
                    <ProductField
                        label={t('labels.productTotal', 'Price total')}
                        valueClassName="text-overflow-ellipsis"
                        value={renderPriceWithTbd(lineItemId('tbd_total'), total_price)}
                    />
                </LineItems>

                <ProductDrawingsLabel product={product} />
            </>
        );
    },
    (prev, next) => {
        return (
            prev.product.material_id === next.product.material_id &&
            prev.product.count === next.product.count &&
            prev.product.spec_string === next.product.spec_string &&
            prev.product.drawing_files?.length === next.product.drawing_files?.length
        );
    },
);

LineItemProductDetails.displayName = 'ProductFields';
