import { useCallback, useEffect, useState } from 'react';
import { AttachmentsService } from '@services/attachments-service';
import { Attachment } from '@types';

export function useAttachmentsHandlers(entityId: number = 0, entityName: string) {
    const { attachments, setAttachments, isLoading } = useAttachments(entityId, entityName);

    const handleFileChange = useCallback(
        (files: File[]) => {
            if (!files.length) {
                return;
            }

            files.splice(0, attachments.length);

            if (entityId && files.length > 0) {
                AttachmentsService.init(entityName)
                    .addAttachment({ entityId, attachmentFiles: files })
                    .then(({ data }) => {
                        setAttachments([...attachments, ...data]);
                    });
            }
        },
        [entityId, entityName, setAttachments, attachments],
    );

    const handleRemove = useCallback(
        (_, index) => {
            const attachment = attachments.at(index);

            attachments.splice(index, 1);

            setAttachments([...attachments]);

            if (entityId && attachment) {
                AttachmentsService.init(entityName).removeAttachment(entityId, attachment.id);
            }
        },
        [entityId, entityName, attachments, setAttachments],
    );

    return { handleFileChange, handleRemove, attachments, isLoading };
}

export function useAttachments(entityId: number = 0, entityName: string) {
    const [attachments, setAttachments] = useState<Attachment[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (entityId) {
            setIsLoading(true);

            AttachmentsService.init(entityName)
                .getAttachments(entityId)
                .then(({ data }) => {
                    setAttachments(data);
                })
                .catch(() => setIsLoading(false))
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [entityId, entityName, setIsLoading]);

    return { attachments, setAttachments, isLoading };
}
