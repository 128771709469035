import { getFullDocumentHeight } from './get-full-document-height';

// ** Sufficient sign-in, sign-up, upload, change password and 404 window height is 850 **
// ** pass it to setWidgetIframeHeight **
// ** If not passed, widget will try to set it's full height **

export function setWidgetIframeHeight(height?: number | 'default') {
    let text = 'Set DF widget height';
    if (height === 'default') {
        text = 'Set DF widget default height';
    }
    const minHeight = 850;
    let widgetHeight = minHeight;
    if (typeof height === 'number') {
        widgetHeight = height;
    }
    if (!height) {
        widgetHeight = getFullDocumentHeight();
    }
    window.parent.postMessage({ text, height: widgetHeight > minHeight ? Math.ceil(widgetHeight) : minHeight }, '*');
}
