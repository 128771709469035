import React, { SVGProps } from 'react';
import cn from 'classnames';
import { bem } from '@react-md/utils';
const block = bem('rmd-icon');
const SvgCheckCircle = (props: SVGProps<SVGSVGElement>) => {
    props = Object.assign(
        {
            'aria-hidden': true,
        },
        props,
        {
            className: cn(block(), props.className),
        },
    );
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={12}
            height={12}
            fill="none"
            viewBox="0 0 12 12"
            role="img"
            {...props}
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M11 5.54V6a5 5 0 1 1-2.965-4.57"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M11 2 6 7.005l-1.5-1.5"
            />
        </svg>
    );
};
export default SvgCheckCircle;
