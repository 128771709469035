import cn from 'classnames';
import React, { useRef } from 'react';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';
import { DropdownMenu } from '@react-md/menu';
import { BELOW_INNER_LEFT_ANCHOR, useAppSize, useResizeListener, useTimeout } from '@react-md/utils';
import { Icons } from '@components/icons';
import { FeatureKeys as FF, FeatureOn, FeatureToggle } from '@components/feature-flags';
import { ContentKeys as FC, FeatureMarkup } from '@components/feature-content';
import { AppSettings } from '@types';
import { AppSession } from '@utils';
import { selectAppSettings } from '@modules/app';
import { selectIsAuthenticated } from '@modules/auth';
import { selectUserPermissions } from '@modules/user';
import { _id, menuId, setHeaderHeight } from './helpers';
import { useHeaderActions } from './hooks';
import { LanguagesList } from './languages-list';
import { LogoBox } from './logo-box';
import { ShortIqtBanner } from './short-iqt-banner';

import styles from './header.module.scss';

export const NavItem: React.FC<{
    className?: string;
}> = ({ className, children, ...props }) => {
    return (
        <li className={cn('inline-flex', 'items-center', styles.navItem, className)} {...props}>
            {children}
        </li>
    );
};

interface NavbarProps {
    isDesktop: boolean;
    settings: AppSettings;
}

export const Navbar: React.FC<NavbarProps> = ({ isDesktop, settings }) => {
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const { userIsCompanyOwner: isOwner } = useAppSelector(selectUserPermissions);

    const { i18n, t } = useTranslation();

    const { actions, accountActions, accountActionsMobile, auxiliaryState } = useHeaderActions({
        isAuthenticated,
        isOwner,
        isDesktop,
        t,
    });

    return (
        <>
            <nav className={styles.nav}>
                <ul className={styles.navList}>
                    <NavItem className={styles.language} aria-label={t('buttons.language', 'Language')}>
                        <FeatureToggle flagKey={FF.Header.LanguageShown}>
                            <FeatureOn>
                                <LanguagesList i18n={i18n} customLocale={settings.custom_locale} />
                            </FeatureOn>
                        </FeatureToggle>
                    </NavItem>

                    {isDesktop && (
                        <>
                            <FeatureMarkup
                                contentKey={menuId('custom_item_markup')}
                                wrapper={props => <NavItem className={styles.leftIndents} {...props} />}
                                isEditable={false}
                            />

                            <FeatureToggle flagKey={FF.Header.HowItWorksShown}>
                                <FeatureOn>
                                    <NavItem
                                        className={styles.leftIndents}
                                        aria-label={actions.howItWorks.label?.props.fallback}
                                    >
                                        {actions.howItWorks.button}
                                    </NavItem>
                                </FeatureOn>
                            </FeatureToggle>

                            <NavItem className={styles.newQuote} aria-label={actions.newQuote.label?.props.fallback}>
                                {actions.newQuote.button}
                            </NavItem>
                        </>
                    )}

                    <FeatureToggle flagKey={FF.Header.MyAccountShown}>
                        <FeatureOn>
                            <NavItem
                                className={styles.leftIndents}
                                aria-label={actions.myAccount.label?.props.fallback}
                            >
                                {isDesktop ? (
                                    actions.myAccount.button
                                ) : (
                                    <DropdownMenu
                                        id={actions.myAccount.id}
                                        className={cn('rmd-button--flat', styles.navLink)}
                                        menuClassName={styles.mobileMenu}
                                        onClick={actions.myAccount.onClick}
                                        anchor={BELOW_INNER_LEFT_ANCHOR}
                                        buttonChildren={<Icons.Menu />}
                                        children={accountActionsMobile}
                                        disableDropdownIcon
                                        disableRipple
                                        portal
                                    />
                                )}
                            </NavItem>
                        </FeatureOn>
                    </FeatureToggle>
                </ul>
            </nav>

            <FeatureToggle flagKey={FF.Header.HowItWorksShown}>
                <FeatureOn>
                    <Modal
                        open={auxiliaryState.howItWorksIsOpen}
                        onClose={() => auxiliaryState.setHowItWorksOpen(false)}
                        closeOnEsc={true}
                        center={true}
                        classNames={{
                            modal: `${styles.howItWorksModal}`,
                        }}
                    >
                        <FeatureMarkup
                            contentKey={_id('how_it_works', 'markup')}
                            isEditable={false}
                            fallback={
                                <FeatureMarkup
                                    contentKey={FC.Header.HeaderHowItWorksContentSet}
                                    isEditable={false}
                                    fallback={
                                        <iframe
                                            src="https://www.youtube.com/embed/BkzKEuldn70"
                                            title={t('buttons.howItWorks', 'How it works')}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                            width="500"
                                            height="420"
                                            frameBorder="0"
                                        />
                                    }
                                />
                            }
                        />
                    </Modal>
                </FeatureOn>
            </FeatureToggle>
        </>
    );
};

export const Header: React.FC = () => {
    const settings = useAppSelector(selectAppSettings);

    const { isDesktop } = useAppSize();
    const headerElement = useRef<HTMLDivElement>(null);

    useTimeout(
        () => {
            setHeaderHeight(isDesktop, headerElement.current);
        },
        1000,
        isDesktop,
    );

    useResizeListener({
        enabled: isDesktop,
        immediate: true,
        onResize: event => {
            setHeaderHeight(isDesktop, headerElement.current);
        },
    });

    return (
        <header className={styles.box} ref={headerElement}>
            <div className="container">
                <div className={styles.inner}>
                    {AppSession.shortIqtModeOn ? (
                        <ShortIqtBanner />
                    ) : (
                        <>
                            <LogoBox settings={settings} />
                            <Navbar settings={settings} isDesktop={isDesktop} />
                        </>
                    )}
                </div>
            </div>
        </header>
    );
};
