import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AskForHelpForm, FormOnSubmit, StoreResponseErrors } from '@types';

interface AskForHelpSendRequest extends FormOnSubmit<AskForHelpForm> {
    modelId: number;
}

interface State {
    isLoading: boolean;
    isFileUploading: boolean;
    errors?: StoreResponseErrors;
}

const initialState: State = {
    isLoading: false,
    isFileUploading: false,
};

export const askForHelpSlice = createSlice({
    name: 'askForHelp',
    initialState,
    reducers: {
        sendAskForHelp: (state, action: PayloadAction<AskForHelpSendRequest>) => {},

        uploadFile: (state, action: PayloadAction<AskForHelpSendRequest>) => {
            state.isFileUploading = true;
            state.errors = {};
        },
        uploadFileSuccess: state => {
            state.isFileUploading = false;
            state.errors = {};
        },
        uploadFileFailure: (state, action: PayloadAction<StoreResponseErrors>) => {
            state.isFileUploading = false;
            state.errors = action.payload;
        },

        sendForm: (state, action: PayloadAction<AskForHelpSendRequest>) => {
            state.isLoading = true;
            state.errors = {};
        },
        sendFormSuccess: state => {
            state.isLoading = false;
            state.errors = {};
        },
        sendFormFailure: (state, action: PayloadAction<StoreResponseErrors>) => {
            state.isLoading = false;
            state.errors = action.payload;
        },
    },
});

export const askForHelpActions = askForHelpSlice.actions;
