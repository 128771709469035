import { TbdReasons } from '@constants/tbd';
import { RootState } from '@types';
import { selectIqtModeOn } from '../user';

export const selectPricingData = (state: RootState) => {
    return state.pricing.data;
};

export const selectHasPricingData = (state: RootState) => {
    return Boolean(state.pricing.data);
};

export const selectBulkPrices = (state: RootState) => {
    return state.pricing.bulkPricesData;
};

export const selectIsPricingLoading = (state: RootState) => {
    return state.pricing.isLoading || state.pricing.isBulkPricesLoading;
};

export const selectPricingErrors = (state: RootState) => {
    return state.pricing.errors;
};

export const selectPricingWarnings = (state: RootState) => {
    return state.pricing.warnings;
};

export const selectHasPricingErrors = (state: RootState) => {
    return Boolean(state.pricing.errors?.length);
};

export const selectPricingTbd = (state: RootState) => {
    return state.pricing.tbd;
};

export const selectOutsideIqtPricingTbd = (state: RootState) => {
    const iqtModeOn = selectIqtModeOn(state);
    const tbd = selectPricingTbd(state);

    // disable tbd for iqt
    if (iqtModeOn) {
        return null;
    }

    if (tbd?.tbd_reason !== TbdReasons.OrderTotal) {
        return tbd;
    }

    return null;
};
