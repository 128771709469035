import cn from 'classnames';
import { forwardRef, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '@components/icons';
import { SidebarSection, SidebarSectionProps } from '@components/sidebar-section';
import { TotalPrice } from '@components/total-price';
import { TbdLabel } from './tbd-label';

import styles from './tbd-siderbar.module.scss';

const TbdSidebarInfo = forwardRef<HTMLDivElement, SidebarSectionProps>(({ children, className, ...rest }, ref) => (
    <SidebarSection ref={ref} className={cn(styles.notice, className)} {...rest}>
        <Icons.Info width={26} height={26} className="rmd-typography--theme-warning" />
        {children}
    </SidebarSection>
));

const TbdSidebarTotal = forwardRef<HTMLDivElement, SidebarSectionProps>(({ children, ...rest }, ref) => {
    const { t } = useTranslation();

    return (
        <SidebarSection ref={ref} divider span {...rest}>
            <div className={styles.total}>
                <TotalPrice>{t('labels.total', 'Total')}</TotalPrice>
                <TbdLabel />
            </div>
        </SidebarSection>
    );
});

export const TbdSidebarContainer = (props: HTMLAttributes<HTMLDivElement>) => {
    return (
        <>
            <TbdSidebarInfo {...props} />
            <TbdSidebarTotal />
        </>
    );
};
