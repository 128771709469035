import { useEvent } from 'react-use';
import cn from 'classnames';
import { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useParams } from 'react-router-dom';
import { useAppSize } from '@react-md/utils';
import { initOrderPage, selectOrderLoadedOrAbsent } from '@modules/order';
import { MaterialSelectionMode, selectWidgetModel, selectWidgetTechnology } from '@modules/quotation';
import { useQuotationListeners } from '@modules/quotation/listeners';
import { Fade, FADE_APPEAR_DURATION } from '@components/animations';
import { withBaseErrorBoundary } from '@components/error-boundaries';
import { useFirstOpenedPage } from '@hooks';
import { ViewerSection, ViewerSectionCustomContent } from './viewer';
import { SpecificationSection, SpecificationDetails } from './specification';
import { CalculationSection, CalculationDetails } from './calculation';
import { LineItemsColumn } from './line-items-column';
import { TotalHandler } from './total';
import { RootPrefix } from './constants';
import { useGetResizeRef } from '@utils/parant-site-comunicator';
import { handleOrderChange } from './helpers';

import styles from './order-page.module.scss';

interface WidgetBoxProps {}

export const WidgetBox = memo<WidgetBoxProps>(() => {
    const model = useAppSelector(selectWidgetModel);
    const technology = useAppSelector(selectWidgetTechnology);

    const { isPhone } = useAppSize();

    return (
        <>
            <div className={cn('base-paddings', 'rounded-box', 'overflow-initial', styles.widget)}>
                <ViewerSection model={model}>
                    {technology && <ViewerSectionCustomContent technology={technology} />}
                </ViewerSection>
                <SpecificationSection>
                    {model && technology && <SpecificationDetails model={model} />}
                </SpecificationSection>
            </div>

            <CalculationSection model={model} technology={technology}>
                {isPhone ? (
                    <CalculationDetails model={model} technology={technology} />
                ) : (
                    <div className={cn('flex-1')}>
                        <div className="sticky-top">
                            <CalculationDetails model={model} technology={technology} />
                        </div>
                    </div>
                )}

                <TotalHandler className={isPhone ? undefined : 'sticky-bottom'} />
            </CalculationSection>
        </>
    );
});

const Page = () => {
    const { company: _company, orderId } = useParams();
    const company = _company!;
    const dispatch = useAppDispatch();
    const appear = useFirstOpenedPage();

    const orderLoadedOrAbsent = useAppSelector(selectOrderLoadedOrAbsent);

    const containerRef = useGetResizeRef();

    useQuotationListeners();
    useEffect(() => {
        dispatch(
            initOrderPage({
                company,
                orderId,
            }),
        );
    }, [dispatch, orderId, company]);

    useEvent('storage', (event: StorageEvent) => handleOrderChange({ event, company }));

    return (
        <div id={RootPrefix} className={styles.layout} ref={containerRef}>
            <Fade
                in={orderLoadedOrAbsent}
                appear={appear}
                duration={FADE_APPEAR_DURATION}
                className={cn(['container', 'no-gutters-mobile', styles.columns])}
            >
                <LineItemsColumn appear={appear} />
                <WidgetBox />
            </Fade>
            <MaterialSelectionMode />
        </div>
    );
};

// Page.whyDidYouRender = true;

export const WidgetOrderPage = withBaseErrorBoundary(Page);
