import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@react-md/button';
import { Dialog, DialogHeader, DialogContent, DialogFooter } from '@react-md/dialog';
import { useToggle } from '@react-md/utils';
import { Icons } from '@components/icons';

import styles from './modal-message.module.scss';

export interface ModaMessagelInterface {
    id: string;
    className?: string;
    hasCrossCloseButton: boolean;
    hasConfirmationCloseButton: boolean;
    confirmationButton?: JSX.Element;
    headerContent?: JSX.Element;
    dialogContent?: JSX.Element;
    footerContent?: JSX.Element;
    onClose?: Function;
}

export const ModalMessage: React.FC<ModaMessagelInterface> = (props: ModaMessagelInterface) => {
    const {
        id,
        className,
        hasCrossCloseButton,
        hasConfirmationCloseButton,
        confirmationButton,
        headerContent,
        dialogContent,
        footerContent,
        onClose,
    } = props;

    const [visible, enable, disable] = useToggle(true);

    const handleModalClose = () => {
        disable();
        if (onClose) {
            setTimeout(() => {
                onClose();
            }, 500);
        }
    };

    const { t } = useTranslation();

    return (
        <Dialog
            id={`${id}-modal`}
            className={`${styles.modal} ${className}`}
            visible={visible}
            onRequestClose={handleModalClose}
            aria-labelledby="dialog-title"
        >
            {hasCrossCloseButton && (
                <Button
                    id={`${id}-modal_close_button`}
                    className={styles.crossButton}
                    theme="clear"
                    themeType="contained"
                    onClick={handleModalClose}
                >
                    <Icons.Cross width={12} height={12} />
                </Button>
            )}
            <DialogHeader className={styles.modalHeader}>{headerContent}</DialogHeader>
            <DialogContent className={styles.modalContent}>{dialogContent}</DialogContent>
            <DialogFooter className={styles.modalFooter}>
                {footerContent}
                {hasConfirmationCloseButton &&
                    (confirmationButton ? (
                        <div onClick={handleModalClose}>{confirmationButton}</div>
                    ) : (
                        <Button
                            id={`${id}-modal_ok_button`}
                            className={styles.confirmationButton}
                            theme="primary"
                            themeType="contained"
                            onClick={handleModalClose}
                        >
                            {t('buttons.ok', 'OK')}
                        </Button>
                    ))}
            </DialogFooter>
        </Dialog>
    );
};
