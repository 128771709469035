import cn from 'classnames';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { Select } from '@react-md/form';
// import { BELOW_RIGHT_ANCHOR } from '@react-md/utils';
import { useAppSelector } from '@app/hooks';
import { DfmFeature, HoleModelDfmFeature, isHoleModelDfmFeature, getModelFeatureLabel } from '@entities';
import { selectWidgetModelId, selectWidgetMaterial } from '@modules/quotation';
import { FeatureContent } from '@components/feature-content';
import { usePriceDfmFeaturesCreateQuery } from '@services/df/machines-materials';
import { Label } from '@components/label';
import { SpecificationFeaturesProps } from './types';
import { priceConfigId } from '../helpers';
import { cutSelectListIfTooLong, cutSelectListIfTooLongOnKeydown } from '@utils/widget/cut-select-list-if-too-long';

import styles from './specification-features.module.scss';

const defaultList: DfmFeature[] = [];

export const SpecificationFeatures: React.FC<SpecificationFeaturesProps> = ({
    fieldName,
    data: machineFeatures,
    onChange,
    priceConfigValue,
}) => {
    const parentModelId = useAppSelector(selectWidgetModelId);
    const widgetMaterial = useAppSelector(selectWidgetMaterial);

    const { dfmFeatures, isLoading, isFetching } = usePriceDfmFeaturesCreateQuery(
        {
            dfmFeaturesPriceRequestRequest: { model_id: parentModelId!, material_id: widgetMaterial?.id! },
        },
        {
            skip: !parentModelId || !widgetMaterial,
            selectFromResult: ({ data, isLoading, isFetching }) => ({
                dfmFeatures:
                    (data?.model_dfm_features as unknown as DfmFeature[])?.filter(
                        dfmFeature =>
                            isHoleModelDfmFeature(dfmFeature.properties) ||
                            !dfmFeature.suitable_dfm_features_ids.length,
                    ) ?? defaultList,
                isLoading,
                isFetching,
            }),
        },
    );

    const handleChange = (hashId: string, machineFeatureId: string) => {
        const prevValue = priceConfigValue ?? [];
        const index = prevValue.findIndex(thread => thread.model_feature_id === hashId);

        const nextValue =
            index !== -1
                ? prevValue.map((thread, _index) =>
                      _index === index ? { ...thread, machine_feature_id: machineFeatureId } : thread,
                  )
                : [...prevValue, { model_feature_id: hashId, machine_feature_id: machineFeatureId }];

        onChange(fieldName, nextValue);
    };

    const renderThreads = (dfmFeature: DfmFeature, index: number) => {
        const suitableIds = dfmFeature.suitable_dfm_features_ids;
        const hole = dfmFeature.properties as HoleModelDfmFeature;
        const threads = suitableIds.map(id => {
            const feature = machineFeatures?.find(feature => feature.id === id);
            return {
                value: id,
                label: feature?.name,
            };
        });
        const disabled = suitableIds.length < 2;
        const priceConfigThread = priceConfigValue?.find(thread => thread.model_feature_id === hole.hash_id);
        const currentThreadId = priceConfigThread?.machine_feature_id || suitableIds?.[0];

        return currentThreadId ? (
            <Select
                id={priceConfigId(fieldName, 'thread_field', index)}
                className={styles.dropdown}
                onChange={value => handleChange(hole.hash_id, value)}
                options={threads}
                disabled={disabled}
                value={currentThreadId}
                rightChildren={disabled ? null : undefined} // undefined means displaying default value
                // anchor={BELOW_RIGHT_ANCHOR}
                listboxClassName={cn('rmd-listbox--small', styles.listbox)}
                listboxWidth="min"
                inline
                closeOnResize
                closeOnScroll
                onClick={cutSelectListIfTooLong}
                onKeyDown={event => cutSelectListIfTooLongOnKeydown(event.key)}
            />
        ) : (
            '-'
        );
    };

    const { t } = useTranslation();

    if (isLoading || isFetching || !dfmFeatures.length) return null;

    return (
        <div className="overflow-x-auto">
            <table className={styles.table}>
                <thead className={styles.head}>
                    <tr>
                        <th className={styles.cell}>
                            <Label className={styles.label}>
                                <FeatureContent
                                    contentKey={priceConfigId(fieldName, 'hole_type')}
                                    fallback={t('labels.featureType', 'Feature type')}
                                />
                            </Label>
                        </th>
                        <th className={styles.cell}>
                            <Label className={styles.label}>
                                <FeatureContent
                                    contentKey={priceConfigId(fieldName, 'measurements')}
                                    fallback={`'⌀, ${t('measurements.mm', 'mm')}'`}
                                />
                            </Label>
                        </th>
                        <th className={styles.cell}>
                            <Label className={styles.label}>
                                <FeatureContent
                                    contentKey={priceConfigId(fieldName, 'quantity')}
                                    fallback={t('labels.quantity', 'Quantity')}
                                />
                            </Label>
                        </th>
                        <th className={styles.cell}>
                            <Label className={styles.label}>
                                <FeatureContent
                                    contentKey={priceConfigId(fieldName, 'fit_thread')}
                                    fallback={t('labels.fitThread', 'Fit / thread')}
                                />
                            </Label>
                        </th>
                    </tr>
                </thead>
                <tbody className={styles.body}>
                    {dfmFeatures.length ? (
                        dfmFeatures.map((dfmFeature, index) => {
                            const modelFeature = dfmFeature.properties;
                            const suitableIds = dfmFeature.suitable_dfm_features_ids;
                            const hasError = !suitableIds.length || !!dfmFeature.errors.length;

                            return (
                                <Fragment key={index}>
                                    <tr className={cn({ [styles['hole-error']]: hasError })}>
                                        <td className={styles.cell}>
                                            <Typography type="body-2" component={'div'} className="whitespace-pre-line">
                                                {getModelFeatureLabel(modelFeature)}
                                            </Typography>
                                        </td>
                                        <td className={styles.cell}>
                                            <Typography type="body-2" component={'div'}>
                                                {isHoleModelDfmFeature(modelFeature) ? modelFeature.diam01 : '-'}
                                            </Typography>
                                        </td>
                                        <td className={styles.cell}>
                                            <Typography type="body-2" component={'div'}>
                                                1
                                            </Typography>
                                        </td>
                                        <td className={styles.cell}>{renderThreads(dfmFeature, index)}</td>
                                    </tr>
                                    <tr className={styles['label-error']}>
                                        {hasError && (
                                            <td className={styles.error} colSpan={4}>
                                                {
                                                    <FeatureContent
                                                        contentKey={priceConfigId(fieldName, 'error')}
                                                        fallback={t(
                                                            'errors.featureNotManufactured',
                                                            'Feature can not be manufactured',
                                                        )}
                                                        wrapper={({ children }) => <span>{children}</span>}
                                                    />
                                                }
                                            </td>
                                        )}
                                    </tr>
                                </Fragment>
                            );
                        })
                    ) : (
                        <tr>
                            <td className={styles.cell} colSpan={4}>
                                <Typography type="body-2" component={'div'} className="rmd-typography--center">
                                    {t('messages.noMatchingResults', 'No matching results')}
                                </Typography>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};
