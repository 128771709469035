import pick from 'lodash/pick';
import * as Sentry from '@sentry/react';
import { UnknownAction, configureStore } from '@reduxjs/toolkit';
// import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { persistStore } from 'redux-persist';
import { createEpicMiddleware } from 'redux-observable';
import { dfApi } from '@services/df/baseApi';
import { ModelsService } from '@services';
import type { RootState } from '@types';
import { appExtraApi } from './helpers';
import { rootReducer } from './reducers';
import { rootEpic } from './epics';
import { listenerMiddleware } from './listenerMiddleware';
import './listeners';

const epicMiddleware = createEpicMiddleware<UnknownAction, UnknownAction, RootState>();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: action => {
        if (action.type.startsWith('auth') || action.type.startsWith('user') || action.type.startsWith('client')) {
            // Return null to not log the action to Sentry
            return null;
        }
        // if (action.type === 'SET_PASSWORD') {
        //     // Return a transformed action to remove sensitive information
        //     return {
        //         ...action,
        //         password: null,
        //     };
        // }

        return action;
    },
    stateTransformer: state => {
        // Transform the state to remove sensitive information
        const maskedState = {
            ...pick(state, ['app', 'invoice', 'quotation']),
        };

        return maskedState;
    },
    // configureScopeWithState: (scope, state) => {
    //     // Set tag if the user is using imperial units.
    //     if (state.settings.useImperialUnits) {
    //         scope.setTag('user.usesImperialUnits', true);
    //     }
    // },
    attachReduxState: false,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware({
            serializableCheck: false,
            // serializableCheck: {
            //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            // },
            thunk: {
                extraArgument: appExtraApi,
            },
        })
            .prepend(listenerMiddleware.middleware)
            .concat(epicMiddleware)
            .concat(dfApi.middleware);
    },
    enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(sentryReduxEnhancer),
});

export const persistor = persistStore(store);

epicMiddleware.run(rootEpic);

ModelsService.store = store;
