import cn from 'classnames';
import React from 'react';
import { Icons } from '@components/icons';
import { useLoadImage } from '@hooks';

import styles from './model-thumb.module.scss';

export interface ModelThumbProps {
    thumb?: string;
    className?: string;
    size?: 'large' | 'medium' | 'small';
    isArchive?: boolean;
    isRejected?: boolean;
}

export const ModelThumb: React.FC<ModelThumbProps> = ({
    thumb,
    className,
    size = 'medium',
    isArchive = false,
    isRejected = false,
    children,
}) => {
    const [isLoaded, isFailed] = useLoadImage(thumb);

    return (
        <div
            className={cn([
                'rounded-box',
                styles.box,
                styles[size],
                { [styles.placeholder]: !thumb || !isLoaded },
                className,
            ])}
        >
            {isRejected || isFailed ? (
                <Icons.Error className={styles.icon} />
            ) : isArchive ? (
                <Icons.Folder className={styles.icon} />
            ) : thumb && isLoaded ? (
                <img className={cn('fade-in', styles.image)} src={thumb} alt="Model thumbnail" />
            ) : (
                <Icons.BoxThin className={styles.icon} />
            )}

            {children}
        </div>
    );
};
