import React, { useState } from 'react';
import { Typography } from '@react-md/typography';
import { useTranslation } from 'react-i18next';

import styles from './invoice-modal-message.module.scss';

export function InvoiceModalMessage(): JSX.Element {
    const { t } = useTranslation();

    const [isLoaded, setIsLoaded] = useState(false);

    const SuccessImage = new Image();
    SuccessImage.src = '/img/png/petard.png';
    SuccessImage.onload = () => {
        setIsLoaded(true);
    };

    return (
        <div className={styles.message}>
            {isLoaded ? (
                <img className={styles.image} src={SuccessImage.src} alt="Petard" />
            ) : (
                <div className={styles.emodji}>&#x1F389;</div>
            )}
            <Typography type="body-1" component={'div'}>
                {t('messages.invoicePaidMessage', 'Your order has been paid successfully')}
            </Typography>
        </div>
    );
}
