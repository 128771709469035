import React, { SVGProps } from 'react';
import cn from 'classnames';
import { bem } from '@react-md/utils';
const block = bem('rmd-icon');
const SvgSettings = (props: SVGProps<SVGSVGElement>) => {
    props = Object.assign(
        {
            'aria-hidden': true,
        },
        props,
        {
            className: cn(block(), props.className),
        },
    );
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="none"
            viewBox="0 0 16 16"
            role="img"
            {...props}
        >
            <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
                <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                <path d="M12.933 10a1.1 1.1 0 0 0 .22 1.213l.04.04a1.333 1.333 0 1 1-1.887 1.887l-.04-.04a1.1 1.1 0 0 0-1.213-.22 1.1 1.1 0 0 0-.667 1.007V14a1.333 1.333 0 0 1-2.667 0v-.06A1.1 1.1 0 0 0 6 12.933a1.1 1.1 0 0 0-1.213.22l-.04.04a1.333 1.333 0 1 1-1.887-1.886l.04-.04a1.1 1.1 0 0 0 .22-1.214 1.1 1.1 0 0 0-1.006-.666h-.114A1.333 1.333 0 0 1 2 6.72h.06A1.1 1.1 0 0 0 3.066 6a1.1 1.1 0 0 0-.22-1.213l-.04-.04A1.334 1.334 0 1 1 4.693 2.86l.04.04a1.1 1.1 0 0 0 1.213.22h.053a1.1 1.1 0 0 0 .667-1.007V2a1.333 1.333 0 1 1 2.667 0v.06a1.1 1.1 0 0 0 .666 1.007 1.1 1.1 0 0 0 1.214-.22l.04-.04a1.334 1.334 0 1 1 1.886 1.886l-.04.04a1.101 1.101 0 0 0-.22 1.214V6a1.101 1.101 0 0 0 1.007.667h.113a1.333 1.333 0 1 1 0 2.666h-.06a1.1 1.1 0 0 0-1.006.667v0Z" />
            </g>
        </svg>
    );
};
export default SvgSettings;
