import cn from 'classnames';
import React, { useCallback } from 'react';
import { useAppDispatch } from '@app/hooks';
import { Dialog } from '@react-md/dialog';
import { PopupProps } from '@types';
import { popupsActions } from '@modules/ui';
import { SignupFormBody } from './signup-form-body';
import { SignupFormFooter } from './signup-form-footer';
import { signUpId } from '../helpers';

import styles from '../auth-page.module.scss';

export const SignupPopup: React.FC<PopupProps> = ({ isOpened, isClosable }) => {
    const dispatch = useAppDispatch();
    const close = useCallback(() => dispatch(popupsActions.close()), [dispatch]);

    return (
        <Dialog
            id={signUpId('popup')}
            visible={isOpened}
            onRequestClose={() => (isClosable ? close() : {})}
            aria-labelledby={signUpId('popup')}
            overlayStyle={{ transition: 'none' }}
            // defaultFocus={`#${composeNodeId(SignUpFormId, 'email', 'field')}`}
            disableFocusOnMount
            portal={false}
            modal={!isClosable}
            appear
        >
            <div className={cn('rounded-box', styles.box)}>
                <SignupFormBody onSuccess={close} />
                <SignupFormFooter type="popup" />
            </div>
        </Dialog>
    );
};
