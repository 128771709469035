import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundaryProps, FallbackRender, withErrorBoundary } from '@sentry/react';
import { Button } from '@react-md/button';
import { Typography } from '@react-md/typography';
import { Alert, AlertHeading } from '@components/alert';

type FallbackProps = Parameters<FallbackRender>[0];

interface BaseErrorBoundaryFallbackProps {
    className?: string;
    label?: string;
}

export const BaseErrorBoundaryFallback = ({
    className,
    label = 'Something went wrong!',
    eventId,
    error,
    componentStack,
    resetError,
}: BaseErrorBoundaryFallbackProps & FallbackProps) => {
    const { t, ready } = useTranslation();
    return (
        <div className={className}>
            <Alert show type="error" variant="outlined">
                <AlertHeading iconType="error" label={label} />
                <pre style={{ marginBottom: 0 }}>{error.message || error}</pre>
                <Typography type="body-2" component={'div'} className="small-margin-top">
                    <span className={cn('rmd-typography--semi-bold')}>
                        {ready ? t('labels.eventId', 'Event ID') + ': ' : 'Event ID: '}
                    </span>
                    {eventId}
                </Typography>
            </Alert>

            <Button theme="secondary" themeType="contained" className="margin-top" onClick={resetError}>
                {ready ? t('buttons.refresh', 'Refresh') : 'Refresh'}
            </Button>
        </div>
    );
};

export const getBaseErrorBoundaryProps = ({
    className,
    label,
}: BaseErrorBoundaryFallbackProps = {}): ErrorBoundaryProps => ({
    fallback: props => <BaseErrorBoundaryFallback {...props} className={cn('container', className)} label={label} />,
    onReset: () => {
        window.location.assign(window.location.pathname);
    },
});

export function withBaseErrorBoundary<P extends Record<string, any>>(
    Component: React.ComponentType<P>,
    errorBoundaryProps: ErrorBoundaryProps = getBaseErrorBoundaryProps(),
) {
    return withErrorBoundary(Component, errorBoundaryProps);
}
