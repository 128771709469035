export const ClientsPageSize = 14;

export const RootPrefix = 'client_page';
export const FormId = 'create_client_form';
export const FiltersId = 'choose_client_filters';

export enum ClientsQueryParams {
    Search = 'search',
    Ordering = 'ordering',
    LastTimeUploadedFrom = 'lastTimeUploadedFrom',
    LastTimeUploadedTo = 'lastTimeUploadedTo',
}
