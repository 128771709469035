import React from 'react';
import { Technology } from '@types';
import { FeatureMarkup, ContentKeys as FC, useFeatureContent } from '@components/feature-content';
import { viewerSectionId } from '../helpers';

interface Props {
    technology: Technology;
}

export const ViewerSectionCustomContent: React.FC<Props> = ({ technology }) => {
    const deprecatedCustomContent = useFeatureContent(FC.CartPage.Under3dViewerConditionalTechContentSet);
    const contentMap = useFeatureContent(FC.CartPage.ViewerSectionConditionalMarkupByTech) || deprecatedCustomContent;
    const content = contentMap ? contentMap[technology.id] : '';

    return (
        <>
            <FeatureMarkup
                contentKey={viewerSectionId('markup_bottom')}
                wrapper={props => <div className="small-margin-top small-margin-bottom" {...props} />}
            />
            {Boolean(content) && (
                <div
                    id={FC.CartPage.ViewerSectionConditionalMarkupByTech}
                    className="margin-top"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            )}
        </>
    );
};
