import { useState, useCallback } from 'react';

export function useBrowserStorage<T>(storage: Storage, item: StorageItem<T>) {
    const { key, value } = item;

    const [storedValue, setStoredValue] = useState<T>(initValue(storage, key, value));

    const setValue = useCallback(
        (value: T | ((val: T) => T)) => {
            try {
                const valueToStore = value instanceof Function ? value(storedValue) : value;
                setStoredValue(valueToStore);
                storage.setItem(key, JSON.stringify(valueToStore));
            } catch (error) {
                console.log(error);
            }
        },
        [storage, key, storedValue],
    );

    return [storedValue, setValue] as const;
}

function initValue<T>(storage: Storage, key: string, value: T): T {
    try {
        const item = storage.getItem(key);
        return item ? JSON.parse(item) : value;
    } catch (error) {
        console.log(error);
        return value;
    }
}

interface StorageItem<T> {
    key: string;
    value: T;
}
