import type { MeshBasicMaterial, MeshPhongMaterial, ShaderMaterial, ColorRepresentation, Vector3 } from 'three';

export type ModelMaterials = MeshPhongMaterial | ShaderMaterial | MeshBasicMaterial;

export enum ModelViewerRenderMode {
    Solid = 'solid',
    XRay = 'xRay',
    Wireframe = 'wireframe',
}

export enum ModelViewerObjects {
    Axes = 'axes',
    Grid = 'grid',
    ModelEdges = 'modelEdges',
    ModelBoundingBox = 'modelBoundingBox',
    PrinterBoundingBox = 'printerBoundingBox',
}

interface BaseObjectConfig {
    enabled: boolean;
    active: boolean;
}

export type ModelViewerConfigType = {
    darkModeOn: boolean;
    debug: boolean;
    renderMode: ModelViewerRenderMode;
    rotateModeOn: boolean;
    model?: {
        color?: ColorRepresentation;
    };
    objects: {
        [ModelViewerObjects.Axes]: BaseObjectConfig;
        [ModelViewerObjects.Grid]: BaseObjectConfig;
        [ModelViewerObjects.ModelEdges]: BaseObjectConfig & { color?: ColorRepresentation };
        [ModelViewerObjects.ModelBoundingBox]: BaseObjectConfig & { color?: ColorRepresentation };
        [ModelViewerObjects.PrinterBoundingBox]: BaseObjectConfig & {
            size: string | Vector3; // length-width-height, e.g. 500-300-100 === x-y-z viewer scene axes
            color?: ColorRepresentation;
        };
    };
    backgroundColor?: string;
};

export const ModelViewerConfig: ModelViewerConfigType = {
    darkModeOn: false,
    debug: false,
    renderMode: ModelViewerRenderMode.Solid,
    rotateModeOn: false,
    objects: {
        [ModelViewerObjects.Axes]: {
            enabled: true,
            active: true,
        },
        [ModelViewerObjects.Grid]: {
            enabled: true,
            active: true,
        },
        [ModelViewerObjects.ModelEdges]: {
            enabled: true,
            active: true,
        },
        [ModelViewerObjects.ModelBoundingBox]: {
            enabled: true,
            active: true,
        },
        [ModelViewerObjects.PrinterBoundingBox]: {
            enabled: true,
            active: true,
            size: '',
        },
    },
};

// ViewerObjectsConfig
enum Scene {
    LightBackgroundColor = '#dddddd',
    DarkBackgroundColor = '#111111',
}

enum Model {
    Color = '#494949',
}

enum ModelEdges {
    Color = '#535353',
}

enum ModelWalls {
    Color = '#FF0000',
}

enum ModelBox {
    Color = '#9c9c9c',
}

enum PrinterBox {
    Color = '#b6b6b6',
    HighlightColor = '#FF0000',
}

enum Axes {
    xAxisColor = '#fb0045',
    yAxisColor = '#00dc4b',
    zAxisColor = '#5300ce',
}

enum Grid {
    SectionColor = '#c1c1c1',
    CellColor = '#cbcbcb',
    DarkColor = '#1c1c1c',
}

export const ModelViewerVariables = {
    Scene,
    Model,
    ModelEdges,
    ModelWalls,
    ModelBox,
    PrinterBox,
    Axes,
    Grid,
} as const;
