import { Technology, TechnologyMaterial } from '@types';
import { isCNC, sortByArray } from '@utils';
import { ReactElement } from 'react';

interface GetAvailableMaterials {
    technology: Technology | undefined;
    material: TechnologyMaterial | undefined;
    suitable: number[];
    preselectionModeOn: boolean;
    fullPreselectionModeOn: boolean;
    isIqtModeOn: boolean;
    getMarkedLabel: (material: TechnologyMaterial) => ReactElement;
}

export function getAvailableMaterials({
    technology,
    material,
    suitable,
    preselectionModeOn,
    fullPreselectionModeOn,
    isIqtModeOn,
    getMarkedLabel,
}: GetAvailableMaterials) {
    if (!technology) return [];

    let availableMaterials = technology.materials.map(material => {
        const children = isIqtModeOn && material.is_iqt_only ? getMarkedLabel(material) : undefined;
        return { label: material.title, value: material.id, children };
    });

    if (preselectionModeOn) {
        if (isCNC(technology.tech_id) || fullPreselectionModeOn) {
            // include suitable and unsuitable material, but installed outside
            availableMaterials = availableMaterials.filter(m => m.value === material?.id || suitable.includes(m.value));
        }

        availableMaterials.sort(sortByArray(suitable, 'value'));
    }

    return availableMaterials;
}
