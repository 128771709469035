import { isAxiosError } from 'axios';
import type { FetchBaseQueryError } from '@services/df/baseApi';

export function processRequestError(error: unknown) {
    return isAxiosError(error)
        ? {
              status: error.response?.status,
              data: error.response?.data,
              message: error.response?.data?.detail || error.message,
          }
        : undefined;
}

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
    return typeof error === 'object' && error != null && 'status' in error;
}
