import React from 'react';
import { useAppSelector } from '@app/hooks';
import { selectCurrency } from '@modules/app';
import { FormatPriceProps, formatPrice } from '@utils';

interface PriceProps extends Omit<FormatPriceProps, 'value'> {
    children: string | number;
}

/**
 * This component wrapper for a price, you can put children as any number
 * function => formatPrice will add current currency from backend settings
 */
export const Price: React.FC<PriceProps> = ({ children, precision, ...rest }) => {
    const currency = useAppSelector(selectCurrency);

    return (
        <span>{formatPrice(children, { ...currency, precision: precision ?? currency.decimal_digits, ...rest })}</span>
    );
};
