import { TFunction } from 'i18next';
import { FilterModelsByUser, FilterModelsByUserValues } from './types';

export function useFilterModelsByUserLabel(t: TFunction): Record<FilterModelsByUser, string> {
    return {
        [FilterModelsByUserValues.All]: t('forms.filterModelsCompany', 'All models of the company'),
        [FilterModelsByUserValues.Client]: t('forms.filterModelsClient', "Current client's models"),
        [FilterModelsByUserValues.Owner]: t('forms.filterModelsOwner', "Current user admin's models"),
    };
}
