import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { Link } from '@react-md/link';
import { Button } from '@react-md/button';
import { TextIconSpacing } from '@react-md/icon';
import { Icons } from '@components/icons';
import { composeNodeId } from '@utils';

import styles from './model-actions.module.scss';

export interface ActionProps extends React.HTMLAttributes<HTMLElement> {}

export interface ModelDownloadActionProps extends ActionProps {
    modelId: number | string;
    prefix: string;
    originalUrl?: string;
    repairedUrl?: string;
}

export const ModelDownloadAction: React.FC<ModelDownloadActionProps> = ({
    modelId,
    prefix,
    originalUrl,
    repairedUrl,
}) => {
    const { t } = useTranslation();

    if (!originalUrl && !repairedUrl) return null;

    return (
        <Typography
            type="body-2"
            weight="semi-bold"
            component="div"
            className={cn([
                styles.action,
                styles.download,
                {
                    [styles.dot]: originalUrl && repairedUrl,
                },
            ])}
        >
            <TextIconSpacing icon={<Icons.Download />} forceIconWrap>
                {t('labels.download', 'Download:')}
            </TextIconSpacing>

            {originalUrl && (
                <Link
                    id={composeNodeId(prefix, 'original', modelId)}
                    href={originalUrl}
                    download
                    target="_blank"
                    className={styles.link}
                >
                    {t('links.original', 'Original')}
                </Link>
            )}
            {repairedUrl && (
                <Link
                    id={composeNodeId(prefix, 'repaired', modelId)}
                    href={repairedUrl}
                    download
                    target="_blank"
                    className={styles.link}
                >
                    {t('links.repaired', 'Repaired')}
                </Link>
            )}
        </Typography>
    );
};

export const ModelDuplicateAction: React.FC<ActionProps> = ({ ...props }) => {
    const { t } = useTranslation();
    return (
        <Button
            theme="primary"
            disableRipple
            className={cn('rmd-button--flat', 'small-margin-right', styles.action, styles.duplicate)}
            {...props}
        >
            <TextIconSpacing icon={<Icons.Copy className="rmd-typography--secondary" />} forceIconWrap>
                {t('buttons.duplicate', 'Duplicate')}
            </TextIconSpacing>
        </Button>
    );
};

export const ModelDeleteAction: React.FC<ActionProps> = ({ ...props }) => {
    const { t } = useTranslation();
    return (
        <Button theme="error" disableRipple className={cn('rmd-button--flat', styles.action, styles.delete)} {...props}>
            <TextIconSpacing icon={<Icons.Trash />} forceIconWrap>
                {t('buttons.delete', 'Delete')}
            </TextIconSpacing>
        </Button>
    );
};
