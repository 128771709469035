import cn from 'classnames';
import React from 'react';
import { Typography, TypographyProps } from '@react-md/typography';

import styles from './total-price.module.scss';

interface Props extends Omit<TypographyProps, 'type'> {}

export const TotalPrice = ({ className, children, ...rest }: Props) => {
    return (
        <Typography
            type="headline-4"
            className={cn(styles.box, className)}
            component={rest.component || 'div'}
            {...rest}
        >
            {children}
        </Typography>
    );
};
