import cn from 'classnames';
import React, { Dispatch, DispatchWithoutAction, SetStateAction, useCallback } from 'react';
import { useAppDispatch } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Icons } from '@components/icons';
import { Button } from '@react-md/button';
import { Tabs, TabsManager, TabsManagerProps } from '@react-md/tabs';
import { Typography } from '@react-md/typography';
import { useTooltip, Tooltipped, Tooltip } from '@react-md/tooltip';
import { ContentCustomizationResponse } from '@types';
import { viewerActions } from '@modules/model-viewer';
import { FeatureKeys as FF, getFeatureFlag } from '@components/feature-flags';
import { getNodeIdComposer, ModelViewer as ModelViewerUtil, ModelViewerRenderMode } from '@utils';
import { ModelViewerPrefix } from '../constants';

import styles from './model-viewer-fullscreen-top-panel.module.scss';

const _id = getNodeIdComposer(ModelViewerPrefix, 'fullscreen_top_panel');

export interface ModelViewerFullscreenTopPanelProps {
    show: boolean;
    modelViewer?: ModelViewerUtil;
    toggleFullscreenMode: DispatchWithoutAction;
    contentCustomization: ContentCustomizationResponse;
    setRenderMode: Dispatch<SetStateAction<ModelViewerRenderMode>>;
    title?: string;
    isThumbModeOn?: boolean;
    className?: string;
}

export const ModelViewerFullscreenTopPanel: React.FC<ModelViewerFullscreenTopPanelProps> = ({
    show,
    toggleFullscreenMode,
    contentCustomization,
    setRenderMode,
    modelViewer,
    title = 'Model was not found',
    isThumbModeOn = false,
    className,
}) => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const { tooltipProps: solidTooltipProps, elementProps: solidTabProps } = useTooltip({
        baseId: _id('solid_mode'),
        position: 'below',
        spacing: 10,
        disableAutoSpacing: true,
    });

    const { tooltipProps: xRayTooltipProps, elementProps: xRayTabProps } = useTooltip({
        baseId: _id('xray_mode'),
        position: 'below',
        spacing: 10,
        disableAutoSpacing: true,
    });

    const { tooltipProps: wireframeTooltipProps, elementProps: wireframeTabProps } = useTooltip({
        baseId: _id('wireframe_mode'),
        position: 'below',
        spacing: 10,
        disableAutoSpacing: true,
    });

    const tabs = [
        {
            ...solidTabProps,
            'data-feature-key': FF.Viewer.SolidModeTabShown,
            children: t('buttons.viewerSolid', 'Solid'),
            className: styles.tab,
        },
        {
            ...xRayTabProps,
            'data-feature-key': FF.Viewer.XrayModeTabShown,
            children: t('buttons.viewerXRay', 'X-Ray'),
            className: styles.tab,
        },
        {
            ...wireframeTabProps,
            'data-feature-key': FF.Viewer.WireframeModeTabShown,
            children: t('buttons.viewerWireframe', 'Wireframe'),
            className: styles.tab,
        },
    ].filter(tab => getFeatureFlag(contentCustomization, tab['data-feature-key']));

    const handleClickActiveTab: TabsManagerProps['onActiveIndexChange'] = index => {
        const tab = tabs[index];

        switch (tab.id) {
            case _id('solid_mode'):
                setRenderMode(ModelViewerRenderMode.Solid);
                if (modelViewer) {
                    modelViewer.renderMode = ModelViewerRenderMode.Solid;
                    dispatch(viewerActions.setCurrentViewerRenderMode(ModelViewerRenderMode.Solid));
                }
                break;
            case _id('xray_mode'):
                setRenderMode(ModelViewerRenderMode.XRay);
                if (modelViewer) {
                    modelViewer.renderMode = ModelViewerRenderMode.XRay;
                    dispatch(viewerActions.setCurrentViewerRenderMode(ModelViewerRenderMode.XRay));
                }
                break;
            case _id('wireframe_mode'):
                setRenderMode(ModelViewerRenderMode.Wireframe);
                if (modelViewer) {
                    modelViewer.renderMode = ModelViewerRenderMode.Wireframe;
                    dispatch(viewerActions.setCurrentViewerRenderMode(ModelViewerRenderMode.Wireframe));
                }
                break;
            default:
                break;
        }
    };

    return (
        <div className={cn([className, styles.box, { hidden: !show }])}>
            <Typography type="headline-5" className={cn(styles.title, styles['centering-box'])}>
                {title}
            </Typography>
            {isThumbModeOn ? null : (
                <>
                    <Tooltip {...solidTooltipProps} lineWrap={false}>
                        {t('tooltips.displaySolidMode', 'Display in solid mode')}
                    </Tooltip>
                    <Tooltip {...xRayTooltipProps} lineWrap={false}>
                        {t('tooltips.displayXRayMode', 'Display in X-Ray mode')}
                    </Tooltip>
                    <Tooltip {...wireframeTooltipProps} lineWrap={false}>
                        {t('tooltips.displayWireframeMode', 'Display the object as wire edges')}
                    </Tooltip>

                    <TabsManager tabs={tabs} tabsId="viewer-tabs" onActiveIndexChange={handleClickActiveTab}>
                        <Tabs className={styles.tabs} disableTransition />
                    </TabsManager>
                </>
            )}

            <div className={cn(styles['centering-box'], 'flex', 'justify-end')}>
                <Tooltipped
                    id={_id('exit_fullscreen')}
                    tooltip={t('tooltips.exitFullscreen', 'Exit full screen')}
                    position="below"
                    lineWrap={false}
                    spacing={10}
                    vwMargin={10}
                    disableAutoSpacing
                >
                    <Button
                        onClick={toggleFullscreenMode}
                        buttonType="icon"
                        theme="clear"
                        themeType="outline"
                        aria-label={t('tooltips.exitFullscreen', 'Exit full screen')}
                    >
                        <Icons.Cross />
                    </Button>
                </Tooltipped>
            </div>
        </div>
    );
};
