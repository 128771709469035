import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import { AxiosError } from 'axios';
import { concat, from, timer, of, EMPTY } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { Action, isAnyOf } from '@reduxjs/toolkit';
import { AppEpic, RootState, CalculateBulkPriceResult, CalculatePriceResult } from '@types';
import { ObjectModelModel } from '@models';
import { CompanyService } from '@services';
import { getFeatureFlag, FeatureKeys as FF } from '@components/feature-flags';
import { customVariables as CV } from '@utils';
import { selectContentCustomization } from '../app';
import { modelsActions, createModelsPolling } from '../models';
import {
    quotationActions,
    selectWidgetCount,
    selectWidgetMaterial,
    selectWidgetModel,
    selectWidgetPriceConfig,
    selectWidgetTechnology,
    selectCorrectObjectModelIdByKeys,
} from '../quotation';
import { CalculatePriceActionPayload, pricingActions } from './slice';

function getPriceResultActions(
    payload: CalculatePriceActionPayload,
    data: CalculatePriceResult | CalculateBulkPriceResult,
    state: RootState,
) {
    const actions: Array<Action> = [];

    const { model_id, technology_id, material_id, is_parent } = payload;
    const { correct_object_model, analysing_errors } = data;
    const correct_model_id = correct_object_model ? correct_object_model.id : model_id;

    // after request for pricing we getting correct object model for viewer
    if (is_parent) {
        const correctId = selectCorrectObjectModelIdByKeys(state, {
            parentModelId: model_id,
            technologyId: technology_id,
        });

        if (!correctId) {
            if (correct_object_model) {
                actions.push(
                    ...[
                        modelsActions.addObjectModel(ObjectModelModel.from(correct_object_model)),
                        createModelsPolling() as unknown as Action,
                    ],
                );
            }

            actions.push(
                quotationActions.setCorrectObjectModel({
                    model_id,
                    technology_id,
                    correct_model_id,
                }),
            );
        }
    }

    if (analysing_errors?.length) {
        actions.push(
            modelsActions.updateObjectModelRelatedData({
                model_id: correct_model_id,
                data: {
                    analysing_errors: { [material_id]: analysing_errors },
                },
            }),
        );
    }

    return actions;
}

const priceEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(pricingActions.calculate.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            return timer(0, 5000).pipe(
                takeUntil(
                    action$.pipe(filter(isAnyOf(pricingActions.calculateSuccess, pricingActions.calculateFailure))),
                ),
                mergeMap(() =>
                    from(
                        CompanyService.init().calculatePrice(omit(action.payload, ['is_parent', 'technology_id'])),
                    ).pipe(
                        withLatestFrom(state$),
                        switchMap(([response, state]) => {
                            if (response.status === 202) {
                                return of();
                            }

                            const actions: Array<Action> = [
                                pricingActions.calculateSuccess(response.data),
                                ...getPriceResultActions(action.payload, response.data, state),
                            ];

                            return concat(actions);
                        }),
                        catchError((error: AxiosError) =>
                            of(
                                // pricingActions.calculateFailure(error?.message || error?.response?.data || []),
                                pricingActions.calculateFailure([]),
                            ),
                        ),
                    ),
                ),
            );
        }),
    );

const bulkPricesEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(pricingActions.calculateBulk.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const material = selectWidgetMaterial(state);
            const currentCount = action.payload.count;
            const currentPriority = action.payload.config.lead_time;
            const lead_time = material?.lead_time.map(lead_time => lead_time.uuid) || [];

            if (currentPriority && lead_time.length > 3) {
                lead_time.splice(3);

                const hasNoCurrentPriority = !lead_time.some(id => id === currentPriority);

                if (hasNoCurrentPriority) {
                    lead_time.splice(2, 3, currentPriority);
                }
            }

            return timer(0, 5000).pipe(
                takeUntil(
                    action$.pipe(
                        filter(isAnyOf(pricingActions.calculateBulkSuccess, pricingActions.calculateBulkFailure)),
                    ),
                ),
                mergeMap(() =>
                    from(
                        CompanyService.init().calculateBulkPrices({
                            ...omit(action.payload, ['is_parent', 'technology_id']),
                            config: omit(action.payload.config, ['lead_time']),
                            count: uniq([currentCount, ...CV.BulkPriceQuantities]),
                            lead_time,
                        }),
                    ).pipe(
                        withLatestFrom(state$),
                        switchMap(([response, state]) => {
                            // if (!response || response.status === 202) {
                            if (response.status === 202) {
                                return of();
                            }

                            const currentPriorityPricing = response.data.prices?.find(
                                price => price.priority_id === currentPriority,
                            );
                            const currentPricing = currentPriorityPricing?.priority_prices.find(
                                price => price.quantity === currentCount,
                            );

                            const actions: Array<Action> = [
                                pricingActions.calculateBulkSuccess(response.data),
                                ...getPriceResultActions(action.payload, response.data, state),
                            ];

                            const material = selectWidgetMaterial(state);

                            if (material) {
                                actions.push(pricingActions.setPricing(currentPricing?.price_info));
                            }

                            return concat(actions);
                        }),
                        catchError((error: AxiosError) =>
                            of(
                                // pricingActions.calculateBulkFailure(error?.message || error?.response?.data || []),
                                pricingActions.calculateBulkFailure([]),
                            ),
                        ),
                    ),
                ),
            );
        }),
    );

const allowPriceEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(quotationActions.setConfig.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const widgetModel = selectWidgetModel(state)!;
            const widgetTechnology = selectWidgetTechnology(state)!;
            const material = selectWidgetMaterial(state)!;
            // select config from store and not action.payload as it is merged in reducer when repeatSpecs mode is enabled
            const config = selectWidgetPriceConfig(state)!;
            const count = selectWidgetCount(state);
            const content = selectContentCustomization(state);
            const isBulkPriceEnabled = getFeatureFlag(content, FF.CartPage.BulkPriceOn);

            const calculatePayload = {
                is_parent: !widgetModel.parent_model,
                model_id: widgetModel.id,
                technology_id: widgetTechnology.id,
                material_id: material.id,
                config: omit(config, ['extra_fieldsets']),
                count,
            };

            if (action.meta.requestPrice) {
                if (isBulkPriceEnabled) {
                    return of(pricingActions.calculateBulk(calculatePayload));
                } else {
                    return of(pricingActions.calculate(calculatePayload));
                }
            } else {
                return EMPTY;
            }
        }),
    );

export const pricingEpics = combineEpics(priceEpic, bulkPricesEpic, allowPriceEpic);
