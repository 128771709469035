export const sortByArray = (arr: any[], property?: string) => (prev: any, next: any) => {
    prev = property ? prev[property] : prev;
    next = property ? next[property] : next;

    const containsPrev = arr.includes(prev);
    const containsNext = arr.includes(next);

    if (!containsPrev && containsNext) {
        return 1;
    }

    if (containsPrev && !containsNext) {
        return -1;
    }

    return arr.indexOf(prev) - arr.indexOf(next);
};
