import i18next from 'i18next';
import { StripeElementsOptionsClientSecret, StripeElementLocale } from '@stripe/stripe-js';

export function getDefaultStripeOptions(): StripeElementsOptionsClientSecret {
    return {
        locale: i18next.language as StripeElementLocale,
        appearance: {
            // theme: 'flat',
            // variables: {
            //     fontFamily: '"ProximaNova", sans-serif',
            // },
        },
        // fonts: [
        //     {
        //         family: 'ProximaNova',
        //         src: 'https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3',
        //         // display: 'swap',
        //         weight: '400',
        //     },
        //     // {
        //     //     family: 'ProximaNova',
        //     //     src: 'https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3',
        //     //     display: 'swap',
        //     //     weight: '400',
        //     // },
        //     {
        //         family: 'ProximaNova',
        //         src: 'https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3',
        //         // display: 'swap',
        //         weight: '700',
        //     },
        //     // {
        //     //     family: 'ProximaNova',
        //     //     src: 'https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3',
        //     //     display: 'swap',
        //     //     weight: '700',
        //     // },
        //     {
        //         family: 'ProximaNova',
        //         src: 'https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3',
        //         // display: 'swap',
        //         weight: '600',
        //     },
        //     // {
        //     //     family: 'ProximaNova',
        //     //     src: 'https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3',
        //     //     display: 'swap',
        //     //     weight: '600',
        //     // },
        // ],
    };
}
