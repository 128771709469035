import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import mapKeys from 'lodash/mapKeys';
import React, { MouseEventHandler, useState } from 'react';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useToggle } from '@react-md/utils';
import { Button } from '@react-md/button';
import { TextIconSpacing } from '@react-md/icon';
import { Icons } from '@components/icons';
import { PdfViewer } from '@components/pdf-viewer';
import { FeatureContent } from '@components/feature-content';
import { OrderForm } from '@types';
import { selectAppSettings } from '@modules/app';
import { formatPdfDocumentUrl } from '@modules/order';
import { sidebarId } from '../order-sidebar';

const PDF_USER_PARAMS_KEYS = ['first_name', 'last_name', 'phone', 'email'];

interface PdfViewerProps {
    pdfUrl: string;
}

export const OrderSubmitPdf: React.FC<PdfViewerProps> = ({ pdfUrl }) => {
    const settings = useAppSelector(selectAppSettings);
    const [isOpen, open, close] = useToggle(false);
    const [pdfLink, setPdfLink] = useState(pdfUrl);
    const { t } = useTranslation();

    const buttonId = sidebarId('review_pdf_button');

    const {
        handleSubmit,
        formState: { errors },
    } = useFormContext<OrderForm>();

    const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
        handleSubmit((form, event) => {
            const fieldsToParams = mapKeys(pick(form, PDF_USER_PARAMS_KEYS), (_, key) => `customer_${key}`);
            setPdfLink(formatPdfDocumentUrl(pdfUrl, { ...fieldsToParams, budget_offer: 1 }));

            if (isEmpty(errors)) {
                open();
            }
        })(event);
    };

    return (
        <>
            <FeatureContent
                contentKey={buttonId}
                fallback={t('links.reviewDoc', 'Review {{ docType }}', { docType: settings.pre_offer_title })}
                wrapper={props => (
                    <Button
                        theme="secondary"
                        className={cn('rmd-button--flat', 'rmd-button--underlined')}
                        onClick={handleClick}
                        {...props}
                    >
                        <TextIconSpacing icon={<Icons.ExternalLink />}>{props.children}</TextIconSpacing>
                    </Button>
                )}
            />

            <PdfViewer
                id={sidebarId('pdf')}
                title={t('titles.generatedInvoice', 'Generated PDF')}
                closeButtonTitle={t('buttons.close', 'Close')}
                pdfLink={pdfLink}
                isOpen={isOpen}
                close={close}
            />
        </>
    );
};
