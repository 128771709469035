import React from 'react';
import { useTranslation } from 'react-i18next';
import { TypographyProps } from '@react-md/typography';
import { ObjectModel } from '@types';
import { LineItem } from '@components/line-items';
import { useModelUnitsLabels } from '@hooks';
import { convertSizes, formatSizes } from './helpers';

type Props = TypographyProps & {
    size: ObjectModel['size'];
    units: ObjectModel['units'];
    convertTo?: ObjectModel['units'];
};

export const ModelSizes: React.FC<Props> = ({ size, units, children, convertTo, ...props }) => {
    const fromUnits = units;
    const viewUnits = convertTo || fromUnits;
    const convertedSize = convertSizes(size, { from: fromUnits, to: viewUnits });
    const sizes = formatSizes(convertedSize, viewUnits);

    const { t } = useTranslation();
    const unitsLabels = useModelUnitsLabels();
    const unitsLabel = unitsLabels[viewUnits].symbol;

    return (
        <LineItem label={t('labels.modelSize', 'Size')} {...props}>
            {sizes} {children || unitsLabel}
        </LineItem>
    );
};
