import { useTranslation } from 'react-i18next';
import { ObjectModel, ObjectModelUnitsTypes, ObjectModelUnits } from '@types';

export function useModelUnitsLabels(): Record<
    ObjectModelUnits,
    {
        label: string;
        symbol: string;
    }
> {
    const { t } = useTranslation();
    return {
        [ObjectModelUnitsTypes.Millimeters]: {
            symbol: t('measurements.mm', 'mm'),
            label: t('measurements.millimeters', 'Millimeters'),
        },
        [ObjectModelUnitsTypes.Centimeters]: {
            symbol: t('measurements.cm', 'cm'),
            label: t('measurements.centimeters', 'Centimeters'),
        },
        [ObjectModelUnitsTypes.Inches]: {
            symbol: t('measurements.in', 'in'),
            label: t('measurements.inches', 'Inches'),
        },
    };
}

export type ModelUnitsLabels = Record<
    ObjectModel['units'],
    {
        label: string;
        symbol: string;
    }
>;
