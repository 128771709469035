export enum DiscountTypes {
    Promo = 'promo',
    Client = 'client',
    Order = 'order',
    Batch = 'batch',
    Hourly = 'hourly',
}

export interface DiscountCondition {
    id: number;
    discount_value: number;
    condition_value: number;
    code: string;
}

export interface Discount {
    active: boolean;
    type: DiscountTypes;
    name: string;
    show_in_widget: boolean;
    name_for_user?: string;
    description_for_user?: string;
    clients?: number[];
    conditions: DiscountCondition[];
}

export interface AppliedDiscount {
    id: number;
    name_for_user: string;
    description_for_user: string;
    conditions: DiscountCondition[];
    type: DiscountTypes;
}
