import i18n from 'i18next';
import omit from 'lodash/omit';
import { ContentCustomizationResponse } from '@types';

export const composeLanguageContentKey = (contentKey: string) => `${contentKey}_${i18n.language}`;

export const prepareCustomizationRequest = (
    contentKey: string,
    contentCustomization: ContentCustomizationResponse = {},
    newContent?: string,
) => {
    const patch = newContent ? { [composeLanguageContentKey(contentKey)]: newContent } : {};
    return {
        widget_content_customization: {
            ...contentCustomization,
            texts: {
                ...omit(contentCustomization.texts, [composeLanguageContentKey(contentKey), contentKey]), // reset prev and obsolete value
                ...patch,
            },
        },
    };
};

const validateContentKey = (contentKey: string) => {
    const match = /^\w+-[.\w]+-\w+(-\w+)?$/.test(contentKey);
    if (!match)
        throw new Error(`${contentKey} has wrong key pattern, use "root_component-child_component-(action|detail)"`);
};

export const getFeatureContent = (content: ContentCustomizationResponse, contentKey: string) => {
    process.env.NODE_ENV !== 'production' && validateContentKey(contentKey);

    if (Object.keys(content).length) {
        const texts = content && content['texts'];
        const text = texts && texts[contentKey]; // text for FeatureMarkup or legacy rewrites (for "obsolete" keys)
        const languageText = texts && texts[composeLanguageContentKey(contentKey)];

        return text || languageText;
    }

    return null;
};
