import { ArchiveFormatsList } from '@constants';
import { getFileExtension } from './get-file-extension';

export const isArchive = (fileName: string | File): boolean => {
    if (fileName instanceof File) {
        fileName = fileName.name;
    }

    return (ArchiveFormatsList as ReadonlyArray<string>).includes(getFileExtension(fileName));
};
