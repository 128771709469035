import { Product } from '@types';

export function getProductsDrawings(products: Product[]) {
    return products.reduce((acc, product) => {
        if (!product.drawing_files?.length) {
            return acc;
        }

        return {
            ...acc,
            [product.id]: product.drawing_files,
        };
    }, {});
}
