import schemify from 'url-schemify/url-schemify';
import { baseURL } from '@services/axios-instance';
import { AppSettings } from '@types';

export function getBackendUrl(relativePath: string, clientDomain: AppSettings['custom_domain']) {
    if (clientDomain !== null) {
        return schemify(clientDomain + relativePath, { scheme: 'https' });
    } else {
        return baseURL + relativePath;
    }
}
