import { useAppSelector } from '@app/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from '@react-md/link';
import { Typography } from '@react-md/typography';
import { selectAppSettings } from '@modules/app';
import { ReactComponent as NotAvailable } from '@pages/widget/order/calculation/error-unsupported.svg';

import styles from './app-deactivated-page.module.scss';

export const AppDeactivatedPage = () => {
    const { support_email: email } = useAppSelector(selectAppSettings);

    const { t } = useTranslation();

    return (
        <div className={styles.wrapper}>
            <NotAvailable className={styles.img} />
            <Typography className={styles.title} type="headline-6">
                {t('messages.quotingUnavailable', 'Instant quoting solution is temporarily unavailable')}
            </Typography>
            <Typography className={styles.subtitle} type="subtitle-2">
                <Trans
                    i18nKey="messages.quotingUnavailableAssistance"
                    defaults={'Please contact <lnk>{{ email }}</lnk> for further assistance'}
                    values={{ email }}
                    components={{
                        lnk: <Link href={`mailto:${email}`} />,
                    }}
                />
            </Typography>
        </div>
    );
};
