import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { selectAppSettings } from '@modules/app';
import { GetModelUploaderOptionsArgs } from './types';
import { getModelUploaderOptions } from './helpers';

type UseModelUploaderProps = Omit<GetModelUploaderOptionsArgs, 'dispatch' | 'settings'> & DropzoneOptions;

export function useModelUploader({ initUpload, onDropComplete, ...props }: UseModelUploaderProps) {
    const dispatch = useAppDispatch();
    const settings = useAppSelector(selectAppSettings);
    const options = useMemo(
        () => getModelUploaderOptions({ dispatch, settings, initUpload, onDropComplete }),
        [dispatch, settings, initUpload, onDropComplete],
    );

    return useDropzone({ ...options, ...props });
}
