import { Address } from '@types';
import { UsaStates, CanadianStates, Countries } from '@constants';

export const getState = (country: string | null, state: string) => {
    switch (country) {
        case 'CA':
            return CanadianStates.find(s => s.code === state);
        case 'US':
            return UsaStates.find(s => s.code === state);
        default:
            return;
    }
};

const defaults = { showCompanyName: false };

export function composeFullAddress(address: Address | undefined, options?: Partial<typeof defaults>) {
    const config = Object.assign({}, defaults, options);

    if (!address || !Object.keys(address).length) {
        return '';
    }

    const address_parts = [
        // address.address_type === 'delivery' && address.company_name ? address.company_name : '',
        config.showCompanyName && address.company_name ? address.company_name : '',
        address.street_address,
        address.apartment,
        address.city,
        address.state && getState(address.country, address.state)?.name,
        address.postcode,
        Countries.find(s => s.code === address.country)?.name,
    ];

    return address_parts.filter(Boolean).join(', ');
}
