import { TFunction } from 'i18next';

export function useAskForHelpLabels(t: TFunction): Record<string, string> {
    return {
        name: t('labels.yourName', 'Your name'),
        phone: t('labels.phone', 'Phone'),
        email: t('labels.email', 'E-mail'),
        description: t('labels.message', 'Message'),
    };
}
