import find from 'lodash/find';
import filter from 'lodash/filter';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { PostProductionOption, PriceConfigFormPostProduction as Value } from '@types';

interface PostProductionGroup {
    title: string;
    list: PostProductionOption[];
}

export function groupPostProduction(list: PostProductionOption[]): PostProductionGroup[] {
    const groups = map(groupBy(list, 'group_title'), (value, key) => ({
        title: key,
        list: value,
    }));

    return sortBy(groups, group => {
        // an empty group is displayed first (post productions without group_title)
        if (!Boolean(group.title)) return -1;

        // compare pp indexes and sorting groups according to the pp order in the admin panel
        return list.findIndex(pp => group.list[0].uuid === pp.uuid);
    });
}

export function findPostProduction(selectedValues: Value[], uuid: string) {
    return find(selectedValues, { uuid });
}

export function removePostProduction(selectedValues: Value[], uuid: string) {
    return filter(selectedValues, i => i.uuid !== uuid);
}

export function addPostProduction(list: PostProductionOption[], selectedValues: Value[], newValue: Value): Value[] {
    if (!newValue.option_uuid) {
        const pp = find(list, { uuid: newValue.uuid });

        // set default color option
        if (pp?.colors) {
            newValue.option_uuid = pp.colors[0].uuid;
        }
    }

    return selectedValues?.length ? [...selectedValues, newValue] : [newValue];
}

export function addOrUpdatePostProduction(list: PostProductionOption[], selectedValues: Value[], newValue: Value) {
    const { uuid } = newValue;
    const prev = findPostProduction(selectedValues, uuid);

    if (prev) {
        // deduplicate previous choice
        return [...removePostProduction(selectedValues, uuid), { ...prev, ...newValue }];
    }

    return addPostProduction(list, selectedValues, newValue);
}
