import cn from 'classnames';
import React, { DispatchWithoutAction, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@react-md/button';
import { Typography } from '@react-md/typography';
import { Tooltipped } from '@react-md/tooltip';
import { Icons } from '@components/icons';
import { FeatureOn, FeatureToggle, FeatureKeys as FF, getFeatureFlag } from '@components/feature-flags';
import { ContentCustomizationResponse } from '@types';
import { getNodeIdComposer, ModelViewer as ModelViewerUtil, ModelViewerObjects } from '@utils';
import { ViewerInnerPanel } from '../viewer-inner-panel';
import { ModelViewerPrefix } from '../constants';

import styles from './model-viewer-scene-controls-panel.module.scss';

const _id = getNodeIdComposer(ModelViewerPrefix, 'scene_controls_panel');

// there are two levels of default values for the button state: from props (force values) and feature flags

export interface ModelViewerSceneControlsPanelProps {
    show: boolean;

    axesShown?: boolean;
    gridFloorShown?: boolean;
    modelBoxShown?: boolean;
    printerBoxShown?: boolean;

    axesBtnShown?: boolean;
    gridFloorBtnShown?: boolean;
    modelBoxBtnShown?: boolean;
    printerBoxBtnShown?: boolean;

    contentCustomization: ContentCustomizationResponse;
    modelViewer?: ModelViewerUtil;
}

export const ModelViewerSceneControlsPanel: React.FC<ModelViewerSceneControlsPanelProps> = ({
    show,
    axesShown,
    gridFloorShown,
    modelBoxShown,
    printerBoxShown,
    axesBtnShown = true,
    gridFloorBtnShown = true,
    modelBoxBtnShown = true,
    printerBoxBtnShown = true,
    contentCustomization,
    modelViewer,
}) => {
    const [isAxesVisible, toggleAxes] = useReducer(
        previous => !previous,
        undefined,
        () => axesShown ?? getFeatureFlag(contentCustomization, FF.Viewer.AxesShown),
    );
    const [isGridVisible, toggleGrid] = useReducer(
        previous => !previous,
        undefined,
        () => gridFloorShown ?? getFeatureFlag(contentCustomization, FF.Viewer.GridFloorShown),
    );
    const [isModelBoxVisible, toggleModelBox] = useReducer(
        previous => !previous,
        undefined,
        () => modelBoxShown ?? getFeatureFlag(contentCustomization, FF.Viewer.ModelBoxShown),
    );
    const [isPrinterBoxVisible, togglePrinterBox] = useReducer(
        previous => !previous,
        undefined,
        () => printerBoxShown ?? getFeatureFlag(contentCustomization, FF.Viewer.PrinterBoxShown),
    );

    const { t } = useTranslation();

    const createOnClickFunction = (object: ModelViewerObjects, toggle: DispatchWithoutAction) => () => {
        modelViewer?.changeObjectActivity(object);
        toggle();
    };

    const buttons = [
        {
            shown: gridFloorBtnShown,
            flagKey: FF.Viewer.GridFloorBtnShown,
            active: !isGridVisible,
            icon: Icons.Grid,
            tooltip: {
                id: _id('toggle_grid'),
                tooltip: t(['tooltips.displayGrid', 'Display grid']),
                vwMargin: 20,
            },
            button: {
                'aria-label': t(['buttons.grid', 'Grid']),
                onClick: createOnClickFunction(ModelViewerObjects.Grid, toggleGrid),
            },
        },
        {
            shown: axesBtnShown,
            flagKey: FF.Viewer.AxesBtnShown,
            active: !isAxesVisible,
            icon: Icons.ArrowTriple,
            tooltip: {
                id: _id('toggle_axes'),
                tooltip: t(['tooltips.displayAxes', 'Display axes']),
            },
            button: {
                'aria-label': t(['buttons.axes', 'Axes']),
                onClick: createOnClickFunction(ModelViewerObjects.Axes, toggleAxes),
            },
        },
        {
            shown: printerBoxBtnShown,
            flagKey: FF.Viewer.PrinterBoxBtnShown,
            active: !isPrinterBoxVisible,
            icon: Icons.Square,
            tooltip: {
                id: _id('toggle_printer_box'),
                tooltip: t(['tooltips.displayPrinterBox', 'Display printer box']),
            },
            button: {
                'aria-label': t(['buttons.printerBox', 'Printer Box']),
                onClick: createOnClickFunction(ModelViewerObjects.PrinterBoundingBox, togglePrinterBox),
            },
        },
        {
            shown: modelBoxBtnShown,
            flagKey: FF.Viewer.ModelBoxBtnShown,
            active: !isModelBoxVisible,
            icon: Icons.Box,
            tooltip: {
                id: _id('toggle_model_box'),
                tooltip: t(['tooltips.displayModelBox', 'Display model box']),
            },
            button: {
                'aria-label': t(['buttons.modelBox', 'Model Box']),
                onClick: createOnClickFunction(ModelViewerObjects.ModelBoundingBox, toggleModelBox),
            },
        },
    ];

    return (
        <ViewerInnerPanel className={cn([styles.box, { invisible: !show }])}>
            <Typography type="headline-6" className={styles.title} component={'span'}>
                {t(['labels.show', 'Show'])}:
            </Typography>

            {buttons
                .filter(btn => btn.shown)
                .map(({ active, button, flagKey, icon, tooltip }) => {
                    const Icon = icon;

                    return (
                        <FeatureToggle flagKey={flagKey} key={flagKey}>
                            <FeatureOn>
                                <Tooltipped lineWrap={false} spacing={10} disableAutoSpacing {...tooltip}>
                                    <Button
                                        className={cn([styles.btn, { [styles.active]: active }])}
                                        theme="clear"
                                        buttonType="icon"
                                        themeType="outline"
                                        {...button}
                                    >
                                        <Icon />
                                    </Button>
                                </Tooltipped>
                            </FeatureOn>
                        </FeatureToggle>
                    );
                })}
        </ViewerInnerPanel>
    );
};
