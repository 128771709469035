import cn from 'classnames';
import React, { memo } from 'react';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Divider } from '@react-md/divider';
import { Typography } from '@react-md/typography';
import { Label } from '@components/label';
import { FeatureContent, ContentKeys as FC } from '@components/feature-content';
import { ProductDrawings } from '@components/product-drawings';
import { DrawingsAccepting } from '@types';
import { getNodeIdComposer } from '@utils';
import { useWidgetMaterial } from '@pages/widget/order/hooks';
import { selectAppSettings } from '@modules/app';
import { selectDraftedDrawings, selectDrawingsByProductId } from '@modules/product';
import { selectCurrentProduct } from '@modules/quotation';
import { SpecificationDrawingsProps } from './types';
import { RootPrefix } from '../constants';

import styles from './specification-drawings.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'specification_drawings');

export const SpecificationDrawings = memo<SpecificationDrawingsProps>(
    ({ model }) => {
        const { widgetMaterial: material } = useWidgetMaterial();
        const { is_drawings_optional_for_non_processable_file_formats: nonCadDrawingsOptional } =
            useAppSelector(selectAppSettings);
        const currentProduct = useAppSelector(selectCurrentProduct);
        const drawings = useAppSelector(state => selectDrawingsByProductId(state, currentProduct?.id));
        const draftedDrawings = useAppSelector(selectDraftedDrawings);

        const { t } = useTranslation();

        if (!material || material.accept_drawing === DrawingsAccepting.NotAccepted) return null;

        const parentModelId = model.parent_model || model.id;
        const drawingsRequired =
            !model.is_processable && nonCadDrawingsOptional
                ? false
                : material.accept_drawing === DrawingsAccepting.Required;

        return (
            <div className={cn(styles.box, 'w-full')}>
                <Divider />
                <Typography id={_id('title')} type="headline-5">
                    <FeatureContent
                        contentKey={_id('title')}
                        fallback={t('subtitles.drawings', 'Technical drawings')}
                    />
                </Typography>

                {drawingsRequired && (
                    <Label className={cn(styles.required, 'rmd-typography--theme-warning')}>
                        {t('errors.required', 'Required')}
                    </Label>
                )}

                <ProductDrawings
                    className="margin-top"
                    parentModelId={parentModelId}
                    productId={currentProduct?.id}
                    draftedDrawings={draftedDrawings}
                    drawings={drawings}
                />
                <Divider className="margin-top" style={{ marginBottom: '0' }} />
            </div>
        );
    },
    (prev, next) => {
        return (prev.model.parent_model || prev.model.id) === (next.model.parent_model || next.model.id);
    },
);
