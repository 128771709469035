import { createEvent } from 'react-event-hook';
import { Rotation } from './types';

interface InitScene {
    success: boolean;
}

const events = {
    ...createEvent('mv-scene-init')<InitScene>(),
    ...createEvent('mv-scene-clear')(),
    // ...createEvent('mv-printer-size-change')(),
    ...createEvent('mv-model-setup')(),
    ...createEvent('mv-model-cleanup')(),
    ...createEvent('mv-model-fits-into-printer')<boolean>(),
    ...createEvent('mv-model-rotation-change')<Rotation[]>(),
};

export default events;
