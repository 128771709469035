import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
import { AppSession } from '../app-session';
import { shouldIgnoreError } from './helpers';

export function sentrySetup() {
    // Init the sentry conditionally.
    // https://docs.sentry.io/platforms/javascript/configuration/options/#enabled
    const isSentryRunnable = [
        process.env.NODE_ENV === 'production',
        process.env.REACT_APP_SENTRY_DSN,
        process.env.REACT_APP_SENTRY_ENVIRONMENT,
        process.env.REACT_APP_RELEASE_VERSION,
    ].every(Boolean);

    if (isSentryRunnable) {
        Sentry.init({
            // dsn: 'https://139606bfd51540d7a2b947ce4361431e@o350186.ingest.sentry.io/4504163515891712',
            dsn: process.env.REACT_APP_SENTRY_DSN,
            environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
            release: process.env.REACT_APP_RELEASE_VERSION,

            // debug: true,
            // enabled: false,
            initialScope: {
                contexts: {
                    app: {
                        mode: AppSession.mode,
                        iFrame: AppSession.iFrame,
                    },
                },
                // user: {
                //     id: 42, // todo persist and get from redux?
                // },
            },
            normalizeDepth: 7,

            // integrations: [new BrowserTracing()],
            // // Set tracesSampleRate to 1.0 to capture 100%
            // // of transactions for performance monitoring.
            // // We recommend adjusting this value in production
            // tracesSampleRate: 1.0,

            beforeSend: (error, hint) => {
                // https://docs.sentry.io/platforms/javascript/data-management/sensitive-data/
                if (error.user) {
                    // Don't send user's email address
                    delete error.user.email;
                }

                return shouldIgnoreError(error, hint) ? null : error;
            },

            // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
            // https://gist.github.com/Chocksy/e9b2cdd4afc2aadc7989762c4b8b495a
            ignoreErrors: [
                // Random plugins/extensions
                'top.GLOBALS',
                // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
                'originalCreateNotification',
                'canvas.contentDocument',
                'MyApp_RemoveAllHighlights',
                'http://tt.epicplay.com',
                "Can't find variable: ZiteReader",
                'jigsaw is not defined',
                'ComboSearch is not defined',
                'http://loading.retry.widdit.com/',
                'atomicFindClose',
                // Facebook borked
                'fb_xd_fragment',
                // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
                // reduce this. (thanks @acdha)
                // See http://stackoverflow.com/questions/4113268
                'bmi_SafeAddOnload',
                'EBCallBackMessageReceived',
                // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                'conduitPage',
                // Generic error code from errors outside the security sandbox
                // You can delete this if using raven.js > 1.0, which ignores these automatically.
                'Script error.',
                // Avast extension error
                '_avast_submit',
                // safari webkit
                /.*@webkit-masked-url.*/,
            ],
            denyUrls: [
                // Google Adsense
                /pagead\/js/i,
                // Facebook flakiness
                /graph\.facebook\.com/i,
                // Facebook blocked
                /connect\.facebook\.net\/en_US\/all\.js/i,
                // Woopra flakiness
                /eatdifferent\.com\.woopra-ns\.com/i,
                /static\.woopra\.com\/js\/woopra\.js/i,
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                /^chrome-extension:\/\//i,
                // Other plugins
                /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                /webappstoolbarba\.texthelp\.com\//i,
                /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
            ],
        });
    }
}
