import isNumber from 'lodash/isNumber';
import mapValues from 'lodash/mapValues';
import currency, { Options } from 'currency.js';
import { ObjectModel, ObjectModelUnits, ObjectModelUnitsTypes } from '@types';
import { ConvertOptions, convertUnits } from '@utils';

type ObjectModelConvertOptions = ConvertOptions<ObjectModel['units']>;

export function formatUnits(value: number = 0, { symbol = '', ...opts }: Options) {
    return currency(value, { symbol, ...opts }).format();
}

// force mm to cm
export function getForcedVolumeUnits(target: ObjectModelUnits) {
    if (target === ObjectModelUnitsTypes.Millimeters) {
        return ObjectModelUnitsTypes.Centimeters;
    } else {
        return target;
    }
}

export function convertVolume(value: ObjectModel['volume'], opts: ObjectModelConvertOptions) {
    const options = mapValues(opts, unit => `${unit}3` as const);
    return value && convertUnits({ value, ...options });
}

export function formatVolume(volume: ObjectModel['volume'], units: ObjectModelUnits) {
    const formatOptions: Options = {};

    switch (units) {
        case ObjectModelUnitsTypes.Inches: {
            formatOptions.precision = 4;
            break;
        }
    }

    return formatUnits(volume, formatOptions);
}

export function convertSizes(size: ObjectModel['size'], convertOptions: ObjectModelConvertOptions) {
    return mapValues(size, value => (isNumber(value) ? convertUnits({ value, ...convertOptions }) : value));
}

export function formatSizes({ x, y, z }: ObjectModel['size'], units: ObjectModel['units']) {
    const formatOptions: Options = {};

    switch (units) {
        case ObjectModelUnitsTypes.Inches: {
            formatOptions.precision = 3;
            break;
        }
    }

    return [x, y, z]
        .filter(Boolean)
        .map(value => formatUnits(value, formatOptions))
        .join(' x ');
}
