import cn from 'classnames';
import { Typography } from '@react-md/typography';
import { FieldGroups } from '@types';
import { OrderFormQuestionnaireFieldFactory } from './order-form-questionnaire-field';

import styles from './order-form-questionnaire.module.scss';

interface Props extends FieldGroups {}

export const OrderFormQuestionnaire = ({ title, fields }: Props) => (
    <div className={styles.box}>
        {title && (
            <Typography type="headline-3" component="div" className="margin-bottom">
                {title}
            </Typography>
        )}
        <div className={cn('fieldset-grid', { [styles.grid]: fields.length > 1 })}>
            {fields?.map(field => (
                <OrderFormQuestionnaireFieldFactory key={field.pointer} field={field} />
            ))}
        </div>
    </div>
);
