import currency, { Options } from 'currency.js';
import { CurrencyConfigType } from '@types';

export type FormatPriceProps = {
    position?: CurrencyConfigType['position'];
    sign?: string;
} & Options;

export function formatPrice(
    value: number | string = 0,
    { position, precision = 0, sign = '', fromCents = false, ...rest }: FormatPriceProps,
) {
    const opts: Options = {
        ...rest,
    };

    const basePattern = position === 'right' ? '# !' : '!#';
    opts.pattern = sign + basePattern;
    opts.negativePattern = sign ? sign + basePattern : '-' + basePattern; // if there is no sign, force draw a minus

    return currency(value, { fromCents, decimal: '.', precision }).format(opts);
}
