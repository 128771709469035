import cn from 'classnames';
import React, { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@react-md/form';
import { UserObjectModelModel } from '@models';
import { DateTransform } from '@components/date';
import {
    ModelRow,
    ModelLabel,
    ModelThumb,
    ModelActions,
    ModelDownloadAction,
    ModelDeleteAction,
} from '@modules/models';
import { LineItem } from '@components/line-items';
import { Fade } from '@components/animations';
import { ModelSizes } from '@modules/models';
import { getNodeIdComposer } from '@utils';
import { DateTransformType } from '@constants';
import { RootPrefix } from './constants';

import styles from './models-page-model.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'model');

interface UserModelRowProps extends React.HTMLAttributes<HTMLElement> {
    model: UserObjectModelModel;
    onChangeCheckbox: ChangeEventHandler<HTMLInputElement>;
    delay: number;
    in?: boolean;
    checked: boolean;
}

export const ModelsPageModel: React.FC<UserModelRowProps> = ({
    model,
    in: show,
    onChangeCheckbox,
    checked,
    ...rest
}) => {
    const { t } = useTranslation();

    const modelId = model.id;
    const facets = model.getFacets(t);

    return (
        <Fade in={show} unmountOnExit {...rest}>
            <ModelRow className={styles.grid}>
                <Checkbox
                    id={_id('checkbox', modelId)}
                    onChange={onChangeCheckbox}
                    checked={checked}
                    value={modelId}
                    disableIconOverlay
                    disableProgrammaticRipple
                    disableRipple
                />
                <ModelThumb size={'large'} thumb={model.thumb_120x120} />

                <ModelLabel label={model.title}>
                    <div className={styles.info}>
                        <ModelSizes size={model.size} units={model.units} />

                        <ModelActions>
                            <ModelDownloadAction
                                modelId={modelId}
                                prefix={_id()}
                                originalUrl={model.file_model_original_url}
                                repairedUrl={model.file_model_stl_repaired_url}
                            />
                            {/*<ModelDeleteAction />*/}
                        </ModelActions>
                    </div>
                </ModelLabel>

                <div className={cn('fieldset-grid', styles.detail)}>
                    {/*<LineItem label={t('labels.status', 'Status')}>*/}
                    {/*    {{ model.get_original_model_file_data.extension|upper }}, {{ model.get_original_model_file_data.filesize|floatformat:3 }} Mb*/}
                    {/*</LineItem>*/}

                    <LineItem
                        label={t('labels.status', 'Status')}
                        value={t(`labels.${model.initial_status}`, model.initial_status)}
                    />

                    {facets && <LineItem label={facets.label} value={facets.value} />}
                </div>

                <LineItem
                    label={t('labels.uploaded', 'Uploaded')}
                    value={<DateTransform dateString={model.date_created} transformTo={DateTransformType.Locale} />}
                />
            </ModelRow>
        </Fade>
    );
};
