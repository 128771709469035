import cn from 'classnames';
import filter from 'lodash/filter';
import React, { useState } from 'react';
import { useAppDispatch } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Button } from '@react-md/button';
import { LineItem } from '@components/line-items';
import { ContentCustomizationResponse } from '@types';
import { getNodeIdComposer, getRotationSteps, ModelViewer as ModelViewerUtil, modelViewerEvents } from '@utils';
import { transformModelInit } from '@modules/models';
import { ModelViewerPrefix } from '../constants';

import styles from './model-viewer-fullscreen-bottom-panel.module.scss';
import { FeatureContent } from '@components/feature-content';

const _id = getNodeIdComposer(ModelViewerPrefix, 'fullscreen_bottom_panel');

export interface ModelViewerFullscreenBottomPanelProps {
    show: boolean;
    modelId: number;
    modelViewer?: ModelViewerUtil;
    contentCustomization: ContentCustomizationResponse;
    className?: string;
    exitRotationMode: () => void;
}

export const ModelViewerFullscreenBottomPanel: React.FC<ModelViewerFullscreenBottomPanelProps> = ({
    show,
    modelId,
    contentCustomization,
    modelViewer,
    className,
    exitRotationMode,
}) => {
    const dispatch = useAppDispatch();
    const [isModelFitted, setIsModelFitted] = useState(true);
    const [rotations, setRotations] = useState(() => getRotationSteps());
    const isRotated = Boolean(filter(rotations, 'angle').length);

    modelViewerEvents.useMvModelFitsIntoPrinterListener(setIsModelFitted);
    modelViewerEvents.useMvModelRotationChangeListener(setRotations);

    const confirm = () => {
        const validRotations = filter(rotations, 'angle');

        if (!isModelFitted || !validRotations.length) return;

        dispatch(
            transformModelInit({
                fromModelId: modelId,
                type: 'rotate',
                rotations: validRotations,
            }),
        );

        exitRotationMode();
    };

    const { t } = useTranslation();

    return (
        <div className={cn([className, styles.box, { hidden: !show }])}>
            <div className={cn([styles.controls])}>
                <FeatureContent
                    contentKey={_id('rotate_confirm')}
                    fallback={t('buttons.confirm', 'Confirm')}
                    wrapper={props => (
                        <Button
                            theme="primary"
                            themeType="contained"
                            onClick={confirm}
                            disabled={!isRotated || !isModelFitted}
                            {...props}
                        />
                    )}
                />

                <FeatureContent
                    contentKey={_id('rotate_reset')}
                    fallback={t('buttons.reset', 'Reset')}
                    wrapper={props => (
                        <Button
                            theme="primary"
                            themeType="outline"
                            onClick={modelViewer?.resetModelTransform}
                            {...props}
                        />
                    )}
                />
            </div>

            <LineItem
                type="body-1"
                className={cn(['inline-block', 'margin-left', 'small-margin-right', 'rmd-typography--right'])}
                label={t('labels.rotationAngles', 'Rotation angles')}
                labelClassName="whitespace-normal"
            >
                <span className="whitespace-pre">
                    {rotations.map(step => ` ${step.axis.toUpperCase()}: ${step.angle}\u00B0`).join('; ')}
                </span>
            </LineItem>
        </div>
    );
};
