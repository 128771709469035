import { DxfViewer } from 'dxf-viewer';
import { useRef, useState } from 'react';
import { useInitDxfViewer, useSetupDxfFile } from '@utils';

function importAll(context: __WebpackModuleApi.RequireContext) {
    return context.keys().map((fileName: string) => ({
        name: fileName.match(/([a-zA-Z0-9\s_\\.\-():])+(.dxf)$/g),
        url: context(fileName),
    }));
}

const files = importAll(
    import.meta.webpackContext!('@assets/dxf/', {
        recursive: false,
        regExp: /\.dxf$/,
    }),
);

export const CustomizeDxfViewerPage = () => {
    const viewer = useRef<DxfViewer>();
    const visualizer = useRef<HTMLDivElement>(null);
    const [url, setFile] = useState(files[0].url);

    useInitDxfViewer(viewer, visualizer);
    useSetupDxfFile(viewer, url);

    return (
        <div style={{ position: 'fixed', top: 0, left: 0, height: '100vh', width: '100vw' }} ref={visualizer}>
            <div style={{ position: 'fixed', top: 0, left: 0, zIndex: 3 }}>
                <select value={url} onChange={event => setFile(event.target.value)}>
                    {files.map((file, index) => (
                        <option key={index} value={file.url}>
                            {file.name}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};
