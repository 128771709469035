import { AppSettings, FieldGroups } from '@types';
import { OrderRevealBlocks } from '../order-reveal-blocks';
import { OrderFormBilling } from './order-form-billing';
import { OrderFormContact } from './order-form-contact';
import { OrderFormDelivery } from './order-form-delivery';
import { OrderFormNotes } from './order-form-notes';
import { OrderFormQuestionnaire } from './order-form-questionnaire';
import { useGetShippingRates } from './hooks';
import { OrderFormId } from './constants';

interface Props {
    offset: number;
    settings: AppSettings;
    dynamicFieldGroups?: FieldGroups[];
}

export const OrderForms = ({ offset, settings, dynamicFieldGroups }: Props) => {
    const getRates = useGetShippingRates();

    return (
        <OrderRevealBlocks id={OrderFormId} as="form" offset={offset}>
            {dynamicFieldGroups?.map(({ title, fields }, index) => (
                <OrderFormQuestionnaire key={index} title={title} fields={fields} />
            ))}

            <OrderFormContact getRates={getRates} />

            {(settings.use_delivery || settings.use_local_pickup) && (
                <OrderFormDelivery settings={settings} getRates={getRates} />
            )}

            <OrderFormBilling settings={settings} />
            <OrderFormNotes placeholder={settings.customer_notes_placeholder} />
        </OrderRevealBlocks>
    );
};
