import cn from 'classnames';
import { forwardRef, HTMLAttributes, ChangeEventHandler, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { TextField, Select } from '@react-md/form';
import { useAppSelector } from '@app/hooks';
import { CompanyUserAddress } from '@services/df/clients';
import { selectUser } from '@modules/user';
import { FormField } from '@components/form-field';
import { OrderForm } from '@types';
import { Countries } from '@constants';
import { getInitialState, getStates } from '@utils';
import { cutSelectListIfTooLong, cutSelectListIfTooLongOnKeydown } from '@utils/widget/cut-select-list-if-too-long';
import { OrderRecentAddressesPopover } from './order-recent-addresses-popover';
import { useOrderDeliveryFormLabels, GetShippingRatesSignature } from './hooks';
import { OrderFormId, RatesDeps } from './constants';

import styles from './order-delivery-tab.module.scss';

const countries = Countries.slice();

interface OrderDeliveryTabProps extends HTMLAttributes<HTMLDivElement> {
    getRates: GetShippingRatesSignature;
}

export default forwardRef<HTMLDivElement, OrderDeliveryTabProps>(function OrderDeliveryTab(
    { getRates, children, className, ...props },
    ref,
) {
    const { delivery_addresses: deliveryAddresses = [] } = useAppSelector(selectUser) || {};
    const { t } = useTranslation();
    const {
        control,
        watch,
        getValues,
        setValue,
        reset,
        formState: { errors },
    } = useFormContext<OrderForm>();
    const [country, state] = watch(['delivery_address.country', 'delivery_address.state']);
    const deliveryAddressLabels = useOrderDeliveryFormLabels(t);

    const handleRatesDepsBlur: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        const field = target.name;
        if ((RatesDeps as ReadonlyArray<string>).includes(field)) {
            getRates(getValues, errors, field);
        }
    };

    const handleAddressPick = (newAddress: CompanyUserAddress) => {
        const values = getValues();
        const newValues = { ...values, delivery_address: newAddress };
        reset(newValues);
    };

    return (
        <div {...props} className={cn(styles.box, 'margin-top', className)} ref={ref}>
            <OrderRecentAddressesPopover
                addressesList={deliveryAddresses}
                buttonLabel={deliveryAddressLabels['delivery_address.use_recent_address']}
                onAddressPick={handleAddressPick}
            />
            <div className={cn('margin-top', 'fieldset-grid', styles.grid)}>
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="delivery_address.company_name"
                    component={TextField}
                    label={deliveryAddressLabels['delivery_address.company_name']}
                    widgetProps={{
                        onBlur: handleRatesDepsBlur,
                    }}
                />
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="delivery_address.street_address"
                    component={TextField}
                    label={deliveryAddressLabels['delivery_address.street_address']}
                    widgetProps={{
                        onBlur: handleRatesDepsBlur,
                    }}
                />
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="delivery_address.apartment"
                    component={TextField}
                    label={deliveryAddressLabels['delivery_address.apartment']}
                    widgetProps={{
                        onBlur: handleRatesDepsBlur,
                    }}
                />
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="delivery_address.city"
                    component={TextField}
                    label={deliveryAddressLabels['delivery_address.city']}
                    widgetProps={{
                        onBlur: handleRatesDepsBlur,
                    }}
                />
                {state && (
                    <FormField
                        control={control}
                        prefix={OrderFormId}
                        name={'delivery_address.state'}
                        component={Select}
                        label={deliveryAddressLabels['delivery_address.state']}
                        widgetProps={{
                            options: getStates(country),
                            labelKey: 'name',
                            valueKey: 'code',
                            onChange: () => getRates(getValues, errors, 'delivery_address.state'),
                            onClick: cutSelectListIfTooLong,
                            onKeyDown: (event: KeyboardEvent<HTMLDivElement>) =>
                                cutSelectListIfTooLongOnKeydown(event.key),
                        }}
                    />
                )}
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="delivery_address.postcode"
                    component={TextField}
                    label={deliveryAddressLabels['delivery_address.postcode']}
                    widgetProps={{
                        onBlur: handleRatesDepsBlur,
                    }}
                />
                <FormField
                    control={control}
                    prefix={OrderFormId}
                    name="delivery_address.country"
                    component={Select}
                    label={deliveryAddressLabels['delivery_address.country']}
                    widgetProps={{
                        options: countries,
                        onChange: () => {
                            const country = getValues('delivery_address.country');
                            const prevState = getValues('delivery_address.state');
                            const state = getInitialState(country);

                            if (state !== prevState) {
                                setValue('delivery_address.state', state);
                            }

                            getRates(getValues, errors, 'delivery_address.country');
                        },
                        labelKey: 'name',
                        valueKey: 'code',
                        onClick: cutSelectListIfTooLong,
                        onKeyDown: (event: KeyboardEvent<HTMLDivElement>) => cutSelectListIfTooLongOnKeydown(event.key),
                    }}
                />
            </div>

            {children}
        </div>
    );
});
