import cn from 'classnames';
import isNull from 'lodash/isNull';
import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';

import styles from './dropzone-area.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
    active: boolean;
    disabled?: boolean;
    hint?: ReactNode;
}

export const DropzoneArea = forwardRef<HTMLDivElement, Props>(
    ({ className, children, active, disabled = false, hint, ...rest }, ref) => {
        const { t } = useTranslation();
        return (
            <div
                className={cn(styles.box, className, { [styles.active]: active, [styles.disabled]: disabled })}
                {...rest}
                ref={ref}
            >
                {!isNull(hint) && (
                    <Typography type="headline-4" component={'span'} className={cn('base-paddings', styles.hint)}>
                        {hint ?? t('titles.upload', 'Drag and drop files here')}
                    </Typography>
                )}

                {children}
            </div>
        );
    },
);
