import React, { useEffect, useRef } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { FADE_STAGGER } from '@components/animations';
import { ModelUploadHandler, RejectedFileUploadHandler, TransformedModelHandler } from '../handlers';
import { UploadedModelsProps } from '../../types';

export const UploadedModels: React.FC<UploadedModelsProps> = ({ filesIds, rejectedFiles, transformedModels }) => {
    const initialized = useRef<string[]>([]);

    useEffect(() => {
        initialized.current = filesIds.concat(
            rejectedFiles.map(file => file.uuid),
            transformedModels.map(data => data.fromModelId.toString()),
        );
    }, [filesIds, rejectedFiles, transformedModels]);

    let staggerStep = -1; // to calculate the delay of the appearance animation

    return (
        <TransitionGroup className="w-full">
            {transformedModels.length
                ? transformedModels.map(data => {
                      const init = !initialized.current.includes(data.fromModelId.toString());
                      if (init) staggerStep++;

                      return (
                          <TransformedModelHandler
                              key={data.data.id || data.fromModelId}
                              transformedData={data}
                              delay={init ? FADE_STAGGER * staggerStep : 0}
                          />
                      );
                  })
                : null}

            {filesIds.length
                ? filesIds.map(uuid => {
                      const init = !initialized.current.includes(uuid);
                      if (init) staggerStep++;

                      return (
                          <ModelUploadHandler key={uuid} uuid={uuid} delay={init ? FADE_STAGGER * staggerStep : 0} />
                      );
                  })
                : null}

            {rejectedFiles.length
                ? rejectedFiles.map(file => {
                      const init = !initialized.current.includes(file.uuid);
                      if (init) staggerStep++;
                      return (
                          <RejectedFileUploadHandler
                              key={file.uuid}
                              file={file}
                              delay={init ? FADE_STAGGER * staggerStep : 0}
                          />
                      );
                  })
                : null}
        </TransitionGroup>
    );
};
