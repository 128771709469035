import { isAnyOf } from '@reduxjs/toolkit';
import { ModelSpecifications } from '@types';
import { startAppListening } from '@app/listenerMiddleware';
import { quotationActions } from '../quotation';
import { productActions } from './slice';
import {
    selectWidgetVisibleModelsIds,
    selectWidgetCount,
    selectWidgetTechnologyId,
    selectWidgetMaterialId,
    selectWidgetPriceConfig,
} from '../quotation';

startAppListening({
    actionCreator: productActions.runApplyToAllModels,
    effect: async (action, { condition, dispatch, getState, unsubscribe, subscribe }) => {
        // only one process at a time
        unsubscribe();

        const state = getState();
        const visibleModels = selectWidgetVisibleModelsIds(state);
        const technologyId = selectWidgetTechnologyId(state);
        const materialId = selectWidgetMaterialId(state);
        const config = selectWidgetPriceConfig(state);
        const count = selectWidgetCount(state);

        // const specifications: Record<string, ModelSpecifications> = {};

        let index = visibleModels.length;

        while (index--) {
            const modelId = visibleModels[index];

            // specifications[modelId] = {
            //     technologyId,
            //     materialIds: { [technologyId.toString()]: materialId },
            //     priceConfigs: { [materialId]: config },
            // };

            dispatch(
                quotationActions.updateSpecifications({
                    [modelId]: {
                        technologyId,
                        materialIds: { [technologyId.toString()]: materialId },
                        priceConfigs: { [materialId]: config },
                    },
                }),
            );
            dispatch(
                productActions.add({
                    config,
                    material_id: materialId,
                    model_id: modelId,
                    hideModel: true,
                    count,
                }),
            );

            await condition(isAnyOf(productActions.addSuccess, productActions.addFailure));
        }

        // dispatch(quotationActions.updateSpecifications(specifications));
        dispatch(productActions.finishApplyToAllModels());

        subscribe();
    },
});
