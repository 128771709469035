import { ObjectModelCncFeature } from './cnc-feature-types';

export interface PriceConfigOption {
    title: string;
    uuid: string;
    value: number;
    name_for_user?: string;
}

export interface PriceConfigLeadTimeOption extends PriceConfigOption {
    days: string;
    name: string;
}

export interface PriceConfigToleranceOption extends PriceConfigOption {
    name: string;
}

export interface PriceConfigColorOption extends Omit<PriceConfigOption, 'value'> {
    value: {
        label: string;
        color: string;
    };
}

export interface PriceConfigPostProductionOption extends PriceConfigOption {
    value: number;
    setup_cost: number;
    minimal_price: number;
    manual_review: boolean;
    choice: 'per_model' | 'per_cm_2' | 'per_cm_3' | 'per_bb';
    countable: boolean;
    production_days: number;
    quantity: number;
    chosen_color?: PriceConfigColorOption['value'];
}

export interface PriceConfigCNCFeature
    extends Pick<ObjectModelCncFeature, 'type' | 'diameter' | 'quantity' | 'type_display' | 'nominal'> {
    thread: string;
    thread_display: string;

    // type	"cuboids"
    // diameter	28.725
    // thread	"69e2498b-1267-4a96-85a1-e1ce1c6e0b34"
    // quantity	1
    // type_display	"Hole"
    // thread_display	"РЕЗЬБА"
    // nominal	"—"
}

export interface PriceConfigDfmFeature {
    machine_feature_id: string;
    model_feature_id: string;
}

export interface PriceConfigExtraFieldset {
    fieldset: string;
    choice: string;
}

export interface PriceConfig {
    filling?: PriceConfigOption;
    layer_thickness?: PriceConfigOption;
    lead_time?: PriceConfigLeadTimeOption;
    thickness?: PriceConfigOption;
    tolerance?: PriceConfigToleranceOption;
    color?: PriceConfigColorOption;
    post_production?: PriceConfigPostProductionOption[];
    extra_fieldsets: PriceConfigExtraFieldset[];
    features?: PriceConfigCNCFeature[];
    dfm_features?: PriceConfigDfmFeature[];
    is_non_recurring_cost_included?: boolean;
    auto_features_price?: boolean;
}

export function isPriceConfigOption(value: any): value is PriceConfigOption {
    return value?.uuid !== undefined;
}
