import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@react-md/typography';
import { TextArea } from '@react-md/form';
import { FeatureContent, FeatureMarkup } from '@components/feature-content';
import { FormField } from '@components/form-field';
import { FileAttachments } from '@components/file-attachments';
import { OrderForm } from '@types';
import { useFieldsResponseErrors } from '@hooks';
import { notesId } from './helpers';
import { OrderFormId } from './constants';

import styles from './order-form-notes.module.scss';
import { useAppSelector } from '@app/hooks';
import { selectOrderId } from '@modules/order';
interface OrderFormNotesProps {
    placeholder?: string;
}

export const OrderFormNotes: React.FC<OrderFormNotesProps> = ({ placeholder, children }) => {
    const form = useFormContext<OrderForm>();
    const { control, watch } = form;
    const orderId = useAppSelector(selectOrderId);
    const watchFields = watch(['notes']);

    useFieldsResponseErrors<OrderForm>(form);

    const { t } = useTranslation();

    return (
        <div className={styles.box}>
            <Typography id={notesId('title')} type="headline-3" component="div" className="margin-bottom">
                <FeatureContent contentKey={notesId('title')} fallback={t('titles.notes', 'Notes')} />
            </Typography>

            <FeatureMarkup
                contentKey={notesId('markup_top')}
                wrapper={props => <div className="medium-margin-bottom" {...props} />}
            />

            <div className={cn('fieldset-grid', styles.form)}>
                <FormField
                    prefix={OrderFormId}
                    name="notes"
                    control={control}
                    component={TextArea}
                    widgetProps={{
                        containerProps: {
                            // @ts-ignore
                            'data-replicated-value': watchFields[0],
                        },
                        placeholder:
                            placeholder ||
                            t('messages.orderNotesPlaceholder', 'Put your notes here if necessary (links allowed)'),
                        animate: false,
                        resize: 'both',
                        rows: 3,
                    }}
                />
                {orderId && <FileAttachments entityId={orderId} entityName="orders" />}
            </div>

            <FeatureMarkup
                contentKey={notesId('markup_bottom')}
                wrapper={props => <div className="medium-margin-top" {...props} />}
            />

            {children}
        </div>
    );
};
