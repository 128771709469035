import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import {
    PerPageLimitParam,
    CurrentPageParam,
    PaginationQueryParams,
    getPaginationRequestParams,
} from '@components/pagination';
import { ClientsQueryParams } from './constants';

const getClientsFilters = () => ({
    [PaginationQueryParams.Limit]: PerPageLimitParam,
    [PaginationQueryParams.PageNumber]: CurrentPageParam,
    [ClientsQueryParams.Ordering]: withDefault(StringParam, ''),
    [ClientsQueryParams.Search]: withDefault(StringParam, undefined),
    [ClientsQueryParams.LastTimeUploadedFrom]: withDefault(StringParam, undefined),
    [ClientsQueryParams.LastTimeUploadedTo]: withDefault(StringParam, undefined),
});

export const useClientsFilters = () => {
    const [query, setQuery] = useQueryParams(getClientsFilters());

    const { page, limit, search, ordering, lastTimeUploadedFrom, lastTimeUploadedTo } = query;

    const params = useMemo(() => {
        return {
            ...getPaginationRequestParams(page, limit),
            search,
            ordering,
            lastTimeUploadedFrom,
            lastTimeUploadedTo,
        };
    }, [page, limit, search, ordering, lastTimeUploadedFrom, lastTimeUploadedTo]);

    return { query, setQuery, params };
};

export function useFormLabels(): Record<string, string> {
    const { t } = useTranslation();

    return {
        email: t('labels.email', 'Email'),
        name: t('labels.firstName', 'First name'),
        surname: t('labels.lastName', 'Last name'),
        phone: t('labels.phone', 'Phone'),
        send_notification: t('labels.send_notification', 'Send registration e-mail'),
    };
}
