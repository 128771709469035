import cn from 'classnames';
import { TFunctionResult } from 'i18next';
import React, { FocusEventHandler, ReactNode } from 'react';
import { Trans, TransProps, TFuncKey, Namespace, DefaultNamespace, KeyPrefix, useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { appActions, selectContentCustomization, selectContentEditableMode } from '@modules/app';
import { AsProp, PolymorphicComponentProps } from '@types';
import { prepareCustomizationRequest } from './helpers';
import { useFeatureContent } from './hooks';

import styles from './feature-content.module.scss';
import { Button } from 'react-md';
import { containsHtml } from '@utils/text';

type EditableNodeProps<C extends React.ElementType = 'div'> = PolymorphicComponentProps<
    C,
    {
        contentKey: string; // only keys that return a string are allowed
        hasCustomContent: boolean;
    }
>;

const EditableNode = <C extends React.ElementType = 'div'>({
    contentKey,
    hasCustomContent,
    as,
    children,
    ...rest
}: EditableNodeProps<C>) => {
    const dispatch = useAppDispatch();

    const contentCustomization = useAppSelector(selectContentCustomization);

    const handleContentClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        evt.preventDefault();
        evt.stopPropagation();
    };

    const handleContentBlur: FocusEventHandler<HTMLDivElement> = evt => {
        const newContent = evt.currentTarget.innerText;
        const changed = children !== newContent;

        if (changed) {
            const request = prepareCustomizationRequest(contentKey, contentCustomization, newContent);
            dispatch(appActions.updateAppSettings(request));
        }
    };

    const handleResetButtonClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        evt.preventDefault();
        evt.stopPropagation();

        const request = prepareCustomizationRequest(contentKey, contentCustomization);
        dispatch(appActions.updateAppSettings(request));
    };

    const { t } = useTranslation();

    const Component = as || 'div';

    const reset = hasCustomContent && (
        <Button onClick={handleResetButtonClick} className={cn('rmd-button--flat', styles.reset)}>
            {t('buttons.reset', 'Reset')}
        </Button>
    );

    return (
        <div className={styles['editable-node']}>
            <Component
                className={styles.editable}
                onClick={handleContentClick}
                onBlur={handleContentBlur}
                contentEditable
                suppressContentEditableWarning
                {...rest}
            >
                {children}
            </Component>
            {reset}
        </div>
    );
};

interface WrapperProps {
    id?: string;
    children: string | ReactNode;
}

type FeatureContentProps<C extends React.ElementType = 'div'> = PolymorphicComponentProps<
    C,
    {
        contentKey: string; // only keys that return a string are allowed
        fallback: ReactNode;
        //***** Pass a wrapper function, mainly to create a button with editable text   *****
        //***** This is necessary to avoid issues with the button being pressed         *****
        //***** when hitting the space button while entering text in Content Edit Mode, *****
        //***** or the inability to press 'reset' when the button is disabled           *****
        wrapper?: (props: WrapperProps) => JSX.Element;
    }
>;

const defaultWrapper = (props: WrapperProps) => <span {...props} />;

// todo rename module FeatureContent => CustomContent
export const FeatureContent = <C extends React.ElementType = 'div'>({
    contentKey,
    fallback,
    wrapper = defaultWrapper,
    children,
    ...rest
}: FeatureContentProps<C>) => {
    const content = useFeatureContent(contentKey);
    const isContentEditModeOn = useAppSelector(selectContentEditableMode);

    // allow creating editable titles, which are empty by default
    const text = content || fallback || children || '';

    if (isContentEditModeOn) {
        return (
            <EditableNode contentKey={contentKey} hasCustomContent={!!content} {...rest}>
                {text}
            </EditableNode>
        );
    }

    return text ? wrapper({ id: contentKey, children: text }) : null;
};

type CustomContentProps<C extends React.ElementType = 'div'> = PolymorphicComponentProps<
    C,
    {
        contentKey: string; // only keys that return a string are allowed
        className?: string;
        fallback?: ReactNode | TFunctionResult;
        render?: (markup: ReactNode) => JSX.Element;
    }
>;

export const CustomContent = <C extends React.ElementType = 'div'>({
    as,
    contentKey,
    className,
    fallback,
    render,
}: CustomContentProps<C>) => {
    const content = useFeatureContent(contentKey);
    const editable = useAppSelector(selectContentEditableMode);

    const Component = as || 'div';

    return null;
};

// the component allows you to rewrite compound text inside the Trans component
// Trans is needed for interpolation variables and render nodes
type FeatureContentCompoundProps<
    K extends TFuncKey<N, TKPrefix> extends infer A ? A : never,
    N extends Namespace = DefaultNamespace,
    TKPrefix = undefined,
    E = React.HTMLProps<HTMLDivElement>,
> = {
    contentKey: string;
} & TransProps<K, N, TKPrefix, E>;

// does not work ;(
// const tOptions = {
//     debug: false, // we don't want to receive warnings about the lack of translation of the rewritten text
// };

export const FeatureContentCompound = <
    K extends TFuncKey<N, TKPrefix> extends infer A ? A : never,
    N extends Namespace = DefaultNamespace,
    TKPrefix extends KeyPrefix<N> = undefined,
    E = React.HTMLProps<HTMLDivElement>,
>({
    contentKey,
    i18nKey: _i18nKey,
    defaults,
    ...rest
}: FeatureContentCompoundProps<K, N, TKPrefix, E>) => {
    const content = useFeatureContent(contentKey);
    const i18nKey = (content ? contentKey : _i18nKey) as string;

    return (
        <Trans
            // tOptions={tOptions}
            id={contentKey}
            {...rest}
            defaults={content ?? defaults}
            i18nKey={i18nKey}
        />
    );
};

// the component allows you to display any HTML markup
interface FeatureMarkupProps {
    contentKey: string; // only keys that return a string are allowed
    className?: string;
    fallback?: ReactNode | TFunctionResult;
    wrapper?: (props: WrapperProps) => JSX.Element;
    isEditable?: boolean;
    editableTextFallback?: ReactNode;
}

export const FeatureMarkup = ({
    contentKey,
    className,
    fallback,
    wrapper,
    isEditable = true,
    editableTextFallback = '',
}: FeatureMarkupProps) => {
    const content = useFeatureContent(contentKey);
    const isContentEditModeOn = useAppSelector(selectContentEditableMode);

    if (content && (containsHtml(content) || !isEditable) && !isContentEditModeOn) {
        const markup = <div id={contentKey} className={className} dangerouslySetInnerHTML={{ __html: content }} />;
        return wrapper ? wrapper({ id: contentKey, children: markup }) : markup;
    } else if (isEditable) {
        return <FeatureContent contentKey={contentKey} wrapper={wrapper} fallback={editableTextFallback} />;
    } else if (fallback) {
        return <>{fallback}</>;
    }

    return null;
};
