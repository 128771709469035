import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { getFileError } from '@components/dropzone';
import { Fade } from '@components/animations';
import { ObjectModelStatus } from '@types';
import { AcceptedModelExtensions } from '@constants';
import { selectAppSettings } from '@modules/app';
import { uploadModelsActions } from '@modules/upload-models';
import { RejectedModel } from '../uploaded-models';
import { RejectedFileUploadHandlerProps } from '../../types';

import styles from './model-upload-handler.module.scss';

export const RejectedFileUploadHandler: React.FC<RejectedFileUploadHandlerProps> = ({ file, in: show, ...rest }) => {
    const dispatch = useAppDispatch();
    const {
        allowed_extensions,
        model_size_limit_in_bytes: maxSize,
        upload_models_limit: maxFiles,
        allowed_non_processable_extensions: nonProcessableExtensions,
    } = useAppSelector(selectAppSettings);

    const accept = (allowed_extensions.length && allowed_extensions) || AcceptedModelExtensions;
    const { fileName, errorCodes, codeValues } = file;
    const [code] = errorCodes;

    const error = getFileError(fileName, code, {
        minSize: 0,
        maxSize,
        // this is for IOS for unknown binary files b/c IOS cannot detect what is stl,obj,wrl,step,iges,3mf,dxf types.
        // you can find here https://github.com/react-dropzone/react-dropzone/issues/1039 about it
        accept: [...accept, ...nonProcessableExtensions, 'application/octet-stream'],
        maxFiles,
        ...codeValues[code],
    });

    const onDelete = useCallback(
        (uuid: string) => {
            dispatch(uploadModelsActions.deleteRejectedFile(uuid));
        },
        [dispatch],
    );

    return (
        <Fade in={show} unmountOnExit className={styles.box} duration={300} {...rest}>
            <RejectedModel
                uuid={file.uuid}
                label={file.fileName}
                status={ObjectModelStatus.Failed}
                onDelete={onDelete}
                error={error}
            />
        </Fade>
    );
};
