import cn from 'classnames';
import React, { memo } from 'react';
import { useAppSelector } from '@app/hooks';
import { UnstyledButton } from '@react-md/button';
import { Typography } from '@react-md/typography';
import { Technology } from '@types';
import { Icons } from '@components/icons';
import { selectTechnologies } from '@modules/technologies';
import { useWidgetTechnology } from '@pages/widget/order/hooks';

import styles from './technologies.module.scss';

export const Technologies = memo(() => {
    const technologies = useAppSelector(selectTechnologies);
    const { widgetTechnology, setWidgetTechnology } = useWidgetTechnology();

    const handleTechnologyClick = (technology: Technology) => {
        if (widgetTechnology?.id === technology.id) {
            return;
        }
        setWidgetTechnology(technology);
    };

    return (
        <ul className={cn('fade-in', styles.box)}>
            {technologies.map((technology, index) => {
                const isActive = technology === widgetTechnology;
                return (
                    <li key={technology.id} className={styles.tile}>
                        <UnstyledButton
                            className={styles.technology}
                            aria-current={isActive}
                            // the first technology is established automatically (by preselection/repeat specs)
                            disabled={!widgetTechnology || isActive}
                            onClick={() => handleTechnologyClick(technology)}
                        >
                            {isActive && (
                                <Icons.CheckCircleThin className={cn('rmd-typography--theme-primary', styles.mark)} />
                            )}

                            <div className={styles.preview}>
                                <img src={technology.image} alt={technology.title} />
                            </div>

                            <Typography type="headline-5" className={styles.title}>
                                {technology.title}
                            </Typography>

                            {technology.note && (
                                <Typography
                                    type="body-2"
                                    className={styles.note}
                                    dangerouslySetInnerHTML={{ __html: technology.note }}
                                />
                            )}
                        </UnstyledButton>
                    </li>
                );
            })}
        </ul>
    );
});
