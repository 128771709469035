import cn from 'classnames';
import React, { ChangeEventHandler, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import { Typography } from '@react-md/typography';
import { FADE_STAGGER } from '@components/animations';
import { PreloaderBox } from '@components/preloader';
import {
    modelsPageActions,
    selectIsModelsListLoading,
    selectModelsPageData,
    selectModelIds,
} from '@modules/models-page';
import { ModelsPageModel } from './models-page-model';

import styles from './models-page-body.module.scss';

export const ModelsPageBody: React.FC = () => {
    const dispatch = useAppDispatch();
    const data = useAppSelector(selectModelsPageData);
    const isLoading = useAppSelector(selectIsModelsListLoading);
    const selectedModelIds = useAppSelector(selectModelIds);

    const handleChangeCheckbox: ChangeEventHandler<HTMLInputElement> = useCallback(
        ({ target: { value: id, checked } }) => {
            if (checked) {
                dispatch(modelsPageActions.setSelectedModelIds([...selectedModelIds, id]));
            } else {
                dispatch(modelsPageActions.setSelectedModelIds(selectedModelIds.filter(_id => _id !== id)));
            }
        },
        [dispatch, selectedModelIds],
    );

    const { t } = useTranslation();

    return (
        <PreloaderBox show={isLoading} className={cn('margin-bottom', styles.box)}>
            {!data.length ? (
                <Typography type="body-1" component="div">
                    {t('messages.noMatchingModels', 'No matching models')}
                </Typography>
            ) : (
                <TransitionGroup
                    className={cn('overflow-x-auto', 'fieldset-grid', styles.list)}
                    enter={false}
                    exit={false}
                >
                    {data.map((model, i) => {
                        const modelId = model.id.toString();

                        return (
                            <ModelsPageModel
                                key={model.id}
                                model={model}
                                delay={FADE_STAGGER * i}
                                onChangeCheckbox={handleChangeCheckbox}
                                checked={selectedModelIds.includes(modelId)}
                            />
                        );
                    })}
                </TransitionGroup>
            )}
        </PreloaderBox>
    );
};
