import { useRef, useEffect, useCallback } from 'react';

// https://rajeshnaroth.medium.com/writing-a-react-hook-to-cancel-promises-when-a-component-unmounts-526efabf251f

interface CancellablePromise<T> {
    promise: Promise<T>;
    cancel: () => void;
}

interface CancellablePromiseOptions {
    suppressErrors: boolean;
}

// type MakeCancelableSignature<T> = (promise: Promise<T>) => CancellablePromise<T>;

export function makeCancelable<T>(promise: Promise<T>, options?: CancellablePromiseOptions): CancellablePromise<T> {
    let isCanceled = false;

    const wrappedPromise = new Promise<T>((resolve, reject) => {
        promise
            .then(val => {
                if (options?.suppressErrors) {
                    return !isCanceled && resolve(val);
                }

                return isCanceled ? reject({ isCanceled }) : resolve(val);
            })
            .catch(error => {
                if (options?.suppressErrors) {
                    return !isCanceled && reject(error);
                }

                return isCanceled ? reject({ isCanceled }) : reject(error);
            });
    });

    return {
        promise: wrappedPromise,
        cancel() {
            isCanceled = true;
        },
    };
}

export function useCancellablePromise<T>(options?: CancellablePromiseOptions, cancelable = makeCancelable) {
    if (process.env.NODE_ENV !== 'production') {
        const emptyPromise = Promise.resolve(true);

        // test if the input argument is a cancelable promise generator
        if (cancelable<boolean>(emptyPromise).cancel === undefined) {
            throw new Error('promise wrapper argument must provide a cancel() function');
        }
    }

    const promises = useRef<Array<ReturnType<typeof cancelable>>>();

    useEffect(() => {
        promises.current = promises.current || [];
        return function cancel() {
            promises.current?.forEach(promise => promise.cancel());
            promises.current = [];
        };
    }, []);

    const cancellablePromise = useCallback(
        (promise: Promise<T>) => {
            const cancelablePromise = cancelable<T>(promise, options ?? { suppressErrors: true });
            promises.current?.push(cancelablePromise);
            return cancelablePromise.promise;
        },
        [options, cancelable],
    );

    return { cancellablePromise };
}
