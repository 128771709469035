export function cutSelectListIfTooLong() {
  const windowHeight = window.innerHeight;
  setTimeout(() => {
    const list: HTMLElement | null = document.querySelector('.rmd-list');
    if (list) {
      setTimeout(() => {
        const bottomCoordinates = list?.getBoundingClientRect().bottom;
        if (windowHeight && bottomCoordinates) {
          const bottomPositionReletivelyWindowBottom = windowHeight - bottomCoordinates;
          if (bottomPositionReletivelyWindowBottom < 10) {
            list.style.bottom = '16px';
          }
        }
      }, 300);
    };
  }, 100);
}

const SELECT_OPEN_KEYS = [' ', 'ArrowDown', 'ArrowUp'];

export function cutSelectListIfTooLongOnKeydown(key: string) {
  if (SELECT_OPEN_KEYS.includes(key)) {
    cutSelectListIfTooLong();
  }
}