import cn from 'classnames';
import currency from 'currency.js';
import React from 'react';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { Fade, FADE_APPEAR_DURATION } from '@components/animations';
import { FeatureMarkup, ContentKeys as FC } from '@components/feature-content';
import { StickyColumnWithPreloader } from '@components/sticky-column';
import { PricingList, PricingRecord } from '@components/pricing-list';
import { SidebarSectionHead, SidebarSection } from '@components/sidebar-section';
import { TbdMessages, TbdSidebarContainer } from '@components/tbd';
import { InitialOrder } from '@types';
import { selectAppSettings } from '@modules/app';
import { selectWholeOrderTbd } from '@modules/order';
import { decimalToBoolean } from '@utils';
import { sidebarId } from './helpers';

import styles from './order-sidebar.module.scss';

interface OrderSidebarProps {
    order: InitialOrder;
    loading: boolean;
}

// todo maybe merge with OrderPageSidebar in one component
export const OrderSidebar: React.FC<OrderSidebarProps> = ({ order, loading, children }) => {
    const { t } = useTranslation();
    const settings = useAppSelector(selectAppSettings);
    const allTBDs = useAppSelector(selectWholeOrderTbd);

    // todo remove when we add tax_value for InitialOrder in backend
    const taxValue = Boolean(order.tax_in_percents && order.total_price && order.total_price_without_tax)
        ? currency(order.total_price!).subtract(order.total_price_without_tax!).value
        : null;

    return (
        <Fade in appear duration={FADE_APPEAR_DURATION} className={styles.column}>
            <StickyColumnWithPreloader stickyParentClassName={styles.column} loading={loading}>
                <div className={cn('rounded-box', styles.box)}>
                    <SidebarSectionHead className="margin-bottom">
                        <Typography type="headline-3" component="div">
                            {t('labels.total', 'Total')}
                        </Typography>
                    </SidebarSectionHead>

                    <FeatureMarkup
                        contentKey={sidebarId('markup_top')}
                        wrapper={props => <SidebarSection span {...props} />}
                    />

                    {allTBDs.length ? (
                        <TbdSidebarContainer>
                            <TbdMessages list={allTBDs} />
                        </TbdSidebarContainer>
                    ) : (
                        <SidebarSection span>
                            <PricingList>
                                <PricingRecord
                                    label={t('labels.modelsPrice', 'Models price')}
                                    value={order.models_naked_price}
                                />
                                {decimalToBoolean(order.startup_cost) && (
                                    <PricingRecord
                                        label={t('labels.startupCost', 'Startup cost')}
                                        value={order.startup_cost}
                                    />
                                )}
                                <PricingRecord
                                    label={t('subtitles.shippingPrice', 'Shipping price')}
                                    value={order.delivery_price}
                                />

                                {decimalToBoolean(order.price_corrections) && (
                                    <PricingRecord
                                        label={t('subtitles.priceCorrections', 'Additional goods and services')}
                                        value={order.price_corrections}
                                        sign={parseInt(order.price_corrections) > 0 ? '+' : undefined}
                                    />
                                )}

                                {decimalToBoolean(order.total_discount_value) && (
                                    <PricingRecord
                                        label={t('labels.discount', 'Discount')}
                                        percent={
                                            decimalToBoolean(order.products_discount_value)
                                                ? undefined
                                                : Number(order.order_discount_in_percents)
                                        }
                                        value={order.total_discount_value}
                                        sign="-"
                                    />
                                )}

                                <PricingRecord
                                    label={t('labels.subtotal', 'Subtotal')}
                                    value={order.total_price_without_tax}
                                />

                                {Boolean(taxValue) && (
                                    <PricingRecord
                                        label={settings.tax_name}
                                        value={taxValue!}
                                        percent={order.tax_in_percents}
                                    />
                                )}
                            </PricingList>
                            <PricingList divider>
                                <PricingRecord
                                    label={t('labels.total', 'Total')}
                                    value={order.total_price}
                                    keyType="headline-6"
                                    valueType="headline-6"
                                />
                            </PricingList>
                        </SidebarSection>
                    )}

                    {children}

                    <FeatureMarkup
                        contentKey={sidebarId('markup_bottom')}
                        wrapper={props => <SidebarSection span {...props} />}
                        fallback={
                            <FeatureMarkup
                                wrapper={props => <SidebarSection span {...props} />}
                                contentKey={FC.OrderPage.UnderTotalPriceContentSet}
                            />
                        }
                    />
                </div>
            </StickyColumnWithPreloader>
        </Fade>
    );
};
