import type { ReactElement, ReactNode } from 'react';
import type { ButtonProps } from '@react-md/button';
import { Button } from '@react-md/button';
import type { TooltipHookOptions } from '@react-md/tooltip';
import { Tooltip, useTooltip } from '@react-md/tooltip';

export interface TooltippedButtonProps
    extends ButtonProps,
        Pick<
            TooltipHookOptions<HTMLButtonElement>,
            | 'defaultPosition'
            | 'dense'
            | 'position'
            | 'focusTime'
            | 'touchTime'
            | 'threshold'
            | 'spacing'
            | 'disableAutoSpacing'
            | 'vwMargin'
            | 'vhMargin'
        > {
    id: string;
    tooltip?: ReactNode;
    lineWrap?: boolean;
}

export default function TooltippedButton({
    id,
    tooltip,
    onClick,
    onBlur,
    onFocus,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
    onTouchStart,
    onContextMenu,
    children,
    dense,
    position,
    defaultPosition,
    focusTime,
    touchTime,
    threshold,
    spacing,
    disableAutoSpacing,
    vwMargin,
    vhMargin,
    lineWrap = false,
    ...props
}: TooltippedButtonProps): ReactElement {
    const { elementProps, tooltipProps } = useTooltip({
        baseId: id,
        dense,
        onClick,
        onBlur,
        onFocus,
        onKeyDown,
        onMouseEnter,
        onMouseLeave,
        onTouchStart,
        onContextMenu,
        position,
        focusTime,
        touchTime,
        threshold,
        spacing,
        disableAutoSpacing,
        vwMargin,
        vhMargin,
        defaultPosition,
        disabled: !tooltip,
    });

    return (
        <>
            <Button {...props} {...elementProps}>
                {children}
            </Button>
            <Tooltip {...tooltipProps} lineWrap={lineWrap}>
                {tooltip}
            </Tooltip>
        </>
    );
}

TooltippedButton.defaultProps = {
    themeType: 'outline',
};
