import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from '@react-md/link';
import { Typography } from '@react-md/typography';
import { DateTransform } from '@components/date';
import { FeatureKeys, useFeatureFlag } from '@components/feature-flags';
import { Icons } from '@components/icons';
import { AppSettings, DeliveryType, Order } from '@types';
import { composeFullAddress, composeNodeId, getFileNameByUrl, isValidUrl, wrapLinks } from '@utils';
import { RootPrefix } from './constants';
import { convertDataToString } from './helpers';

export const useInvoiceTilesData = (settings: AppSettings, order?: Order) => {
    const { t } = useTranslation();
    const isEnabledEstimatedManufacturing = useFeatureFlag(FeatureKeys.InvoicePage.EstimatedManufacturingTimeShown);

    if (!order) return {};

    const { company_address, tax_name, tax_number_name } = settings;

    const estimatedManufacturingTime = order.estimated_shipment_date
        ? {
              propertyKey: 'manufacturing_time',
              property: t('labels.estimatedShipmentDate', 'Estimated shipment date'),
              value: <DateTransform dateString={order.estimated_shipment_date} />,
          }
        : {
              propertyKey: 'manufacturing_time',
              property: t('labels.estimatedManufacturingTime', 'Estimated manufacturing business days'),
              value: order.delivery_time ? t('labels.day', '{{count}} days', { count: order.delivery_time }) : '',
          };

    const contact = {
        prefix: composeNodeId(RootPrefix, 'contact'),
        title: t('subtitles.contact', 'Contact'),
        icon: Icons.UserCheck,
        list: [
            { propertyKey: 'first_name', property: t('labels.firstName', 'First name'), value: order.name },
            { propertyKey: 'last_name', property: t('labels.lastName', 'Last name'), value: order.surname },
            { propertyKey: 'phone', property: t('labels.phone', 'Phone'), value: order.phone },
            {
                propertyKey: 'attached_file',
                property: t('labels.attachedFile', 'Attached file'),
                value:
                    order.attached_file && isValidUrl(order.attached_file) ? (
                        <Link href={order.attached_file} target="_blank">
                            {getFileNameByUrl(order.attached_file)}
                        </Link>
                    ) : null,
            },
            {
                propertyKey: 'additional_contacts',
                property: t('labels.additionalContacts', 'Additional contacts'),
                value: convertDataToString(order.additional_billing_contacts),
            },
        ] as const,
    };

    const delivery = {
        prefix: composeNodeId(RootPrefix, 'delivery'),
        title: t('titles.delivery', 'Delivery'),
        icon: Icons.Package,
        list: [
            {
                propertyKey: 'shipping_type',
                property: t('labels.shippingType', 'Shipping type'),
                value: order.delivery_type_title,
            },
            {
                propertyKey: 'pickup_address',
                property: t('subtitles.pickupAddress', 'Pickup address'),
                value:
                    order.delivery_type === DeliveryType.NotSpecifiedDelivery
                        ? composeFullAddress(company_address)
                        : '',
            },
            ...(isEnabledEstimatedManufacturing && estimatedManufacturingTime
                ? ([estimatedManufacturingTime] as const)
                : []),
            ...(order.tracking_number
                ? ([
                      {
                          propertyKey: 'tracking_number',
                          property: t('labels.trackingNumber', 'Tracking number'),
                          value: order.tracking_number,
                      },
                  ] as const)
                : []),
            ...(order.shipping_address
                ? ([
                      {
                          propertyKey: 'postal_code',
                          property: t('labels.postalCode', 'Postal code'),
                          value: order.shipping_address.postcode,
                      },
                      {
                          propertyKey: 'delivery_address',
                          property: t('labels.deliveryAddress', 'Delivery address'),
                          value: composeFullAddress(order.shipping_address, { showCompanyName: true }),
                      },
                  ] as const)
                : []),
        ] as const,
    };

    const billingAddress = order.billing_address
        ? {
              prefix: composeNodeId(RootPrefix, 'billing_address'),
              title: t('subtitles.billingAddress', 'Billing address'),
              icon: Icons.DollarSign,
              list: [
                  {
                      propertyKey: 'company_name',
                      property: t('labels.companyName', 'Company name'),
                      value: order.billing_address.company_name,
                  },
                  {
                      propertyKey: 'country',
                      property: t('labels.country', 'Country'),
                      value: order.billing_address.country,
                  },
                  {
                      propertyKey: 'state',
                      property: t('labels.state', 'State'),
                      value: order.billing_address.state,
                  },
                  {
                      propertyKey: 'city',
                      property: t('labels.city', 'City'),
                      value: order.billing_address.city,
                  },
                  {
                      propertyKey: 'tax_number_name',
                      property: tax_number_name ? tax_number_name : `${tax_name} ${t('labels.number', 'number')}`,
                      value: order.billing_address.vat_number,
                  },
                  {
                      propertyKey: 'address',
                      property: t('labels.address', 'Address'),
                      value: composeFullAddress(order.billing_address),
                  },
                  {
                      propertyKey: 'postal_code',
                      property: t('labels.postalCode', 'Postal code'),
                      value: order.billing_address.postcode,
                  },
              ] as const,
          }
        : undefined;

    const billingContact =
        order.billing_name || order.billing_surname || order.billing_email || order.billing_phone
            ? {
                  prefix: composeNodeId(RootPrefix, 'billing_contact'),
                  title: t('subtitles.billingContact', 'Billing contact'),
                  icon: Icons.UserCheck,
                  list: [
                      {
                          propertyKey: 'first_name',
                          property: t('labels.firstName', 'First name'),
                          value: order.billing_name,
                      },
                      {
                          propertyKey: 'last_name',
                          property: t('labels.lastName', 'Last name'),
                          value: order.billing_surname,
                      },
                      {
                          propertyKey: 'email',
                          property: t('labels.email', 'Email'),
                          value: order.billing_email,
                      },
                      {
                          propertyKey: 'phone',
                          property: t('labels.phone', 'Phone'),
                          value: order.billing_phone,
                      },
                  ] as const,
              }
            : undefined;

    const notes = order.notes
        ? {
              prefix: composeNodeId(RootPrefix, 'notes'),
              title: t('titles.notes', 'Notes'),
              icon: Icons.Notes,
              list: undefined,
              children: (
                  <Typography className={cn('small-margin-top', 'invoice-no-icon-gap')} type="body-2" component="div">
                      <span
                          dangerouslySetInnerHTML={{
                              __html: wrapLinks(order.notes),
                          }}
                      />
                  </Typography>
              ),
          }
        : undefined;

    return {
        contact,
        delivery,
        billingAddress,
        billingContact,
        notes,
    };
};

export type InvoiceTilesData = ReturnType<typeof useInvoiceTilesData>;
export type InvoiceTileData = NonNullable<
    { [K in keyof InvoiceTilesData]: InvoiceTilesData[K] }[keyof InvoiceTilesData]
>;
