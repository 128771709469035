import cn from 'classnames';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useParams } from 'react-router-dom';
import { useAppSize } from '@react-md/utils';
import { Divider } from '@react-md/divider';
import { withBaseErrorBoundary } from '@components/error-boundaries';
import { Fallback } from '@components/fallback';
import { FeatureMarkup } from '@components/feature-content';
import { PreloaderBox } from '@components/preloader';
import { selectAppSettings } from '@modules/app';
import { initInvoicePage, selectInvoiceById, selectIsInvoiceLoading } from '@modules/invoice';
import { InvoiceParts } from './invoice-parts';
import { InvoiceTile } from './invoice-tile';
import { InvoicePriceCorrections } from './invoice-price-corrections';
import { InvoiceSidebar } from './invoice-sidebar';
import { RootPrefix } from './constants';
import { tilesId } from './helpers';
import { useInvoiceTilesData } from './hooks';
import { useGetResizeRef } from '@utils/parant-site-comunicator';
import { FileAttachments } from '@components/file-attachments';

import styles from './invoice-page.module.scss';

const renderMarkup = ({ key }: { key: 'markup_bottom' | 'markup_top' }) => (
    <FeatureMarkup
        contentKey={tilesId(key)}
        wrapper={props => <div className={cn('rounded-box', 'base-paddings')} {...props} />}
    />
);

export const PaymentInvoicePage = withBaseErrorBoundary(() => {
    const dispatch = useAppDispatch();
    const { isDesktop } = useAppSize();
    const { invoiceId: _invoiceId, hash: _hash } = useParams();
    const invoiceId = _invoiceId!;
    const hash = _hash!;
    const settings = useAppSelector(selectAppSettings);
    const invoice = useAppSelector(state => selectInvoiceById(state, invoiceId));
    // when we switch to RTK, it will be the same https://github.com/reduxjs/redux-essentials-example-app/blob/tutorial-steps/src/features/posts/SinglePostPage.js#L11
    const isLoading = useAppSelector(selectIsInvoiceLoading);

    const containerRef = useGetResizeRef();

    const data = useInvoiceTilesData(settings, invoice?.order);

    useEffect(() => {
        dispatch(initInvoicePage({ invoiceId, hash }));
    }, [dispatch, invoiceId, hash]);

    useEffect(() => {
        const gridElementsList = document.querySelectorAll('[data-grid-element=true]');
        if (gridElementsList.length % 2 !== 0) {
            gridElementsList[gridElementsList.length - 1].classList.add(styles.row);
        }
    }, [data]);

    // if (invoice?.id.toString() !== invoiceId) {
    if (!invoice) {
        return <Fallback />;
    }

    const { order } = invoice;

    const hasPriceCorrections = Boolean(order.price_corrections.length);

    return (
        <div id={RootPrefix} className={styles.layout} ref={containerRef}>
            <PreloaderBox show={isLoading} fade>
                <div className="container">
                    <div className={styles.grid}>
                        <div className={styles['left-container']}>
                            <InvoiceParts order={order}>
                                {isDesktop && hasPriceCorrections && (
                                    <>
                                        <Divider />
                                        <InvoicePriceCorrections
                                            className={cn('base-paddings', 'no-top', styles.prices)}
                                            data={order.price_corrections}
                                        />
                                    </>
                                )}
                            </InvoiceParts>

                            {renderMarkup({ key: 'markup_top' })}

                            <div className={styles.left}>
                                {!isDesktop && hasPriceCorrections && (
                                    <InvoicePriceCorrections
                                        className={cn('rounded-box', 'base-paddings', styles.row)}
                                        data={order.price_corrections}
                                    />
                                )}

                                {data.contact && (
                                    <InvoiceTile className={styles.rowHalf} {...data.contact} dataGridElement={true} />
                                )}
                                {data.delivery && (
                                    <InvoiceTile className={styles.rowHalf} {...data.delivery} dataGridElement={true} />
                                )}
                                {data.billingContact && (
                                    <InvoiceTile
                                        className={styles.rowHalf}
                                        {...data.billingContact}
                                        dataGridElement={true}
                                    />
                                )}
                                {data.billingAddress && (
                                    <InvoiceTile
                                        className={styles.rowHalf}
                                        {...data.billingAddress}
                                        dataGridElement={true}
                                    />
                                )}
                                {data.notes && (
                                    <InvoiceTile className={styles.rowHalf} {...data.notes} dataGridElement={true} />
                                )}

                                <FileAttachments
                                    className={cn('relative', 'rounded-box', 'base-paddings', styles.row)}
                                    entityId={invoice.order.id}
                                    entityName="orders"
                                    addTitle={true}
                                />
                            </div>

                            {renderMarkup({ key: 'markup_bottom' })}
                        </div>

                        <InvoiceSidebar invoice={invoice} invoiceId={invoiceId} hash={hash} />
                    </div>
                </div>
            </PreloaderBox>
        </div>
    );
});
