import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icons } from '@components/icons';
import TooltippedButton, { TooltippedButtonProps } from './tooltipped-button';

interface Props extends TooltippedButtonProps {}

export const DeleteIconButton = ({ className, theme, themeType, buttonType, disabled, children, ...props }: Props) => {
    const { t } = useTranslation();
    return (
        <TooltippedButton
            buttonType="icon"
            theme="error"
            // theme="clear"
            themeType="flat"
            className={cn('rmd-button--icon-circle', className)}
            tooltip={t('buttons.delete', 'Delete')}
            position="above"
            spacing={10}
            disableAutoSpacing
            {...props}
        >
            <Icons.Trash />
        </TooltippedButton>
    );
};

export const DuplicateIconButton = ({
    className,
    theme,
    themeType,
    buttonType,
    disabled,
    children,
    ...props
}: Props) => {
    const { t } = useTranslation();
    return (
        <TooltippedButton
            buttonType="icon"
            theme="clear"
            themeType="flat"
            className={cn('rmd-button--icon-circle', className)}
            tooltip={t('buttons.duplicate', 'Duplicate')}
            position="above"
            spacing={10}
            disableAutoSpacing
            {...props}
        >
            <Icons.Copy />
        </TooltippedButton>
    );
};
