import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { Order } from '@types';
import { FeatureContent } from '@components/feature-content';
import { LineItem } from '@components/line-items';
import { getNodeIdComposer } from '@utils';
import { RootPrefix } from '../../constants';

import styles from './order-page-info.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'delivery_section');

interface OrderPageDeliveryProps {
    deliveryTypeTitle?: Order['delivery_type_title'];
    pickupAddress: string;
    shippingAddress: string;
}

export const OrderPageDelivery: React.FC<OrderPageDeliveryProps> = ({
    deliveryTypeTitle,
    pickupAddress,
    shippingAddress,
}) => {
    const { t } = useTranslation();

    return (
        <div className={cn('base-paddings', 'no-top', styles.box)}>
            <Typography id={_id('title')} type="headline-4">
                <FeatureContent contentKey={_id('title')} fallback={t('titles.delivery', 'Delivery')} />
            </Typography>

            <ul className={cn('fieldset-grid', styles.list)}>
                {[
                    { property: t('labels.type', 'Type'), value: deliveryTypeTitle },
                    { property: t('subtitles.pickupAddress', 'Pickup address'), value: pickupAddress },
                    { property: t('labels.address', 'Address'), value: shippingAddress },
                ]
                    .filter(data => Boolean(data.value))
                    .map((data, index) => (
                        <LineItem
                            key={index}
                            component="li"
                            className={styles.field}
                            label={data.property}
                            value={data.value}
                        />
                    ))}
            </ul>
        </div>
    );
};
