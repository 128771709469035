import cn from 'classnames';
import React, { forwardRef, HTMLAttributes, InputHTMLAttributes, ReactNode } from 'react';
import { bem } from '@react-md/utils';
import { InteractionStatesOptions, useInteractionStates } from '@react-md/states';
import { Typography } from '@react-md/typography';
import { useFocusState } from '@hooks';

import styles from './color-field.module.scss';

export interface ColorFieldProps
    extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'readOnly'>,
        Pick<
            InteractionStatesOptions,
            | 'rippleTimeout'
            | 'disableRipple'
            | 'disableProgrammaticRipple'
            | 'disablePressedFallback'
            | 'rippleClassNames'
        > {
    color: string;
    value: string[] | string | number;
    label?: ReactNode;
    type?: 'radio' | 'checkbox';
}

const block = bem('rmd-toggle');

export const ColorField = forwardRef<HTMLInputElement, ColorFieldProps>(function ColorField(
    {
        color,
        checked,
        label,
        onFocus: propOnFocus,
        onBlur: propOnBlur,
        className: propClassName,
        disabled = false,
        disableRipple,
        disableProgrammaticRipple,
        rippleTimeout,
        rippleClassNames,
        children,
        ...props
    },
    ref,
) {
    const { ripples, handlers, className } = useInteractionStates({
        handlers: props,
        disabled,
        className: propClassName,
        disableRipple,
        disableProgrammaticRipple,
        rippleTimeout,
        rippleClassNames,
    });
    const [focused, onFocus, onBlur] = useFocusState({
        onFocus: propOnFocus,
        onBlur: propOnBlur,
    });

    return (
        <span
            className={cn(
                block({
                    focused,
                    disabled,
                }),
                styles.box,
                { [styles.checked]: checked },
                className,
            )}
        >
            <label>
                <input
                    {...props}
                    {...handlers}
                    ref={ref}
                    disabled={disabled}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    className={cn(block('input'))}
                />
                <div className={styles.indicator} style={{ backgroundColor: color }} />
                <span>{label}</span>
            </label>

            {ripples}
            {children}
        </span>
    );
});

export interface ColorFieldsContainerProps
    extends Pick<ColorFieldProps, 'type' | 'name' | 'onChange'>,
        Omit<HTMLAttributes<HTMLUListElement>, 'onChange'> {
    listClassName?: string;
    options: Array<Pick<ColorFieldProps, 'id' | 'value' | 'label' | 'checked' | 'color'>>;
}

export const ColorFieldsContainer = forwardRef<HTMLUListElement, ColorFieldsContainerProps>(
    function ColorFieldsContainer(
        { options, type = 'radio', name, onChange, className, listClassName, ...props },
        ref,
    ) {
        return (
            <div className={className}>
                <ul {...props} className={cn(['emulated-flex-gap', styles.list, listClassName])} ref={ref}>
                    {options.map(item => {
                        return (
                            <li className={styles.item} key={item.id}>
                                <ColorField
                                    type={type}
                                    name={name}
                                    onChange={onChange}
                                    {...item}
                                    // disableProgrammaticRipple
                                    // disableRipple
                                />
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    },
);
