import { Action, Reducer } from 'redux';
import { persistReducer, purgeStoredState, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { AppMode } from '@types';
import { AppSession } from '@utils';
import { AppReducerState } from '@modules/app';
import { AuthReducerState } from '@modules/auth';
import { ClientReducerState } from '@modules/client';
import { ModelsReducerState } from '@modules/models';
import { OrderReducerState } from '@modules/order';
import { UploadModelsReducerState } from '@modules/upload-models';

export const PersistReducerConfigs = {
    app: {
        version: 1,
        key: 'app',
        storage,
        // storage: {
        //     ...storage,
        //     // TODO we have to mock removeItem here, it's the only way to preserve company theme data
        //     //  during app invalidation, i mean call persistor.purge()
        //     removeItem: (key: string): Promise<void> => {
        //         return new Promise((resolve, reject) => {
        //             resolve();
        //         });
        //     },
        // },
        whitelist: ['widget_style_customization'],
    } as PersistConfig<AppReducerState>,
    auth: {
        version: 1,
        key: 'auth',
        storage,
        whitelist: ['token', 'isAuthenticated'],
    } as PersistConfig<AuthReducerState>,
    order: {
        version: 1,
        key: 'order',
        storage,
        whitelist: ['orderId', 'payloadPassthrough'],
    } as PersistConfig<OrderReducerState>,
    client: { version: 1, key: 'client', storage, whitelist: ['clientId'] } as PersistConfig<ClientReducerState>,
    models: { version: 1, key: 'models', storage, whitelist: ['selectedModels'] } as PersistConfig<ModelsReducerState>,
    uploadModels: {
        version: 1,
        key: 'uploadModels',
        storage,
        whitelist: ['uploadJob'],
    } as PersistConfig<UploadModelsReducerState>,
};

const BaseConfigs = ['app', 'auth'];

const ModeSensitiveConfigs = {
    [AppMode.EndUser]: ['models', 'order', 'uploadModels'],
    [AppMode.Iqt]: ['client', 'models', 'order', 'uploadModels'],
    [AppMode.ShortIqt]: undefined,
};

function composePersistReducerKey(companyName: string, key: string, mode?: string) {
    // return key for persist reducer, pattern 'company:mode:reducer' => '4taps:iqt:models'
    return [companyName, mode, key].filter(Boolean).join(':');
}

export function createPersistReducer<S, A extends Action = Action>(
    config: PersistConfig<S>,
    baseReducer: Reducer<S, A>,
) {
    const { allowPersist, companyName, mode } = AppSession;

    const isBaseConfig = BaseConfigs.includes(config.key);
    const modeSensitiveConfigs = ModeSensitiveConfigs[mode];
    const _shouldOmitPersistence = !allowPersist || !companyName;
    const shouldOmitPersistence = isBaseConfig
        ? _shouldOmitPersistence
        : _shouldOmitPersistence || !modeSensitiveConfigs || !modeSensitiveConfigs.includes(config.key);

    if (shouldOmitPersistence) {
        return baseReducer;
    }

    return persistReducer<S, A>(
        {
            ...config,
            get key() {
                return composePersistReducerKey(AppSession.companyName, config.key, isBaseConfig ? undefined : mode);
            },
        },
        baseReducer,
    ) as unknown as Reducer<S, A>;
}

export function purgeStorageAssociatedWithMode(mode: `${AppMode}`) {
    const { companyName } = AppSession;
    const modeSensitiveConfigKeys = ModeSensitiveConfigs[mode];

    const results: Array<Promise<void>> = [];

    modeSensitiveConfigKeys?.forEach(reducerKey => {
        const key = composePersistReducerKey(companyName, reducerKey, mode);
        const config = PersistReducerConfigs[reducerKey as keyof typeof PersistReducerConfigs];
        results.push(purgeStoredState({ ...config, key } as PersistConfig<unknown>));
    });

    return Promise.all(results);
}
