import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FeatureContent } from '@components/feature-content';
import { iqtBannerId } from './helpers';

import styles from './iqt-banner.module.scss';

interface Props {
    className?: string;
}

export const IqtBannerLabel: React.FC<Props> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <div id={iqtBannerId('label')} className={cn(styles.label, className)}>
            <FeatureContent contentKey={iqtBannerId('label')} fallback={t('labels.quotationTool', 'Quotation tool')} />
        </div>
    );
};
