import { of } from 'rxjs';
import { catchError, filter, ignoreElements, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { AppEpic } from '@types';
import { popupsActions } from './popups';

const onClosePopupEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(action => popupsActions.open.match(action) && Boolean(action.payload.onClose)),
        switchMap(action =>
            action$.pipe(
                filter(popupsActions.close.match),
                withLatestFrom(state$),
                take(1),
                tap({
                    next: ([_, state]) => {
                        // @ts-ignore
                        action.payload.onClose(state);
                    },
                }),
                catchError(() => of()),
                ignoreElements(),
            ),
        ),
    );

export const popupsEpics = combineEpics(onClosePopupEpic);
