import React from 'react';
import isNumber from 'lodash/isNumber';
import { Preloader } from '@components/preloader';
import { ModelRow, ModelLabel, ModelThumb } from '@modules/models';
import { Progress } from '@components/progress';
import { UploadableModelProps } from '../types';

export const UploadableModel: React.FC<UploadableModelProps> = ({ fileName, progress, ...rest }) => {
    return (
        <ModelRow {...rest}>
            <ModelThumb size={'small'} />
            <ModelLabel label={fileName} />

            {isNumber(progress) ? <Progress radius={10} stroke={4} value={progress ?? 0} /> : <Preloader size={24} />}
        </ModelRow>
    );
};
