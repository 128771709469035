import React from 'react';
import cn from 'classnames';
import { Transition } from 'react-transition-group';
import { PolymorphicComponentProps } from '@types';
import { getInlineStyles, getTimeoutValue } from './helpers';
import { AnimationProps } from './types';

import styles from './fade.module.scss';
import { FADE_DURATION, FADE_EASING } from '@components/animations/constants';

type Props = AnimationProps & {
    enterOpacity?: number;
    exitOpacity?: number;
};

export type FadeProps<C extends React.ElementType = 'div'> = PolymorphicComponentProps<C, Props>;

export const Fade = <C extends React.ElementType = 'div'>({
    as,
    appear,
    addEndListener,
    className,
    children,
    delay,
    duration,
    easing,
    enterOpacity,
    exitOpacity,
    mountOnEnter,
    unmountOnExit,
    enter,
    exit,
    onEnter,
    onEntering,
    onEntered,
    onExit,
    onExiting,
    onExited,
    style,
    in: show,
    ...other
}: FadeProps<C>) => {
    const statusStyles: Record<string, { opacity: number | undefined }> = {
        entered: {
            opacity: enterOpacity,
        },
        entering: {
            opacity: exitOpacity,
        },
        exited: {
            opacity: exitOpacity,
        },
        exiting: {
            opacity: exitOpacity,
        },
    };

    const Component = as || 'div';

    return (
        <Transition
            addEndListener={addEndListener}
            in={show}
            mountOnEnter={mountOnEnter}
            unmountOnExit={unmountOnExit}
            appear={appear}
            enter={enter}
            exit={exit}
            onEnter={onEnter}
            onEntering={onEntering}
            onEntered={onEntered}
            onExit={onExit}
            onExiting={onExiting}
            onExited={onExited}
            timeout={getTimeoutValue({ delay, duration, enter: show })}
        >
            {status => (
                <Component
                    className={cn(styles.handler, styles[status], className)}
                    style={{
                        ...getInlineStyles({ delay, duration, easing, status, style }),
                        ...statusStyles[status],
                        transitionProperty: 'opacity',
                        willChange: 'opacity',
                    }}
                    {...other}
                >
                    {children}
                </Component>
            )}
        </Transition>
    );
};

Fade.defaultProps = {
    appear: true,
    delay: 0,
    duration: FADE_DURATION,
    easing: FADE_EASING,
    enterOpacity: 1,
    exitOpacity: 0,
};
