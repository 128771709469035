import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { Preloader } from '@components/preloader';
import { ObjectModel } from '@types';
import { useLoadImage } from '@hooks';

import styles from './viewer-thumb.module.scss';

export interface ViewerThumbProps {
    show: boolean;
    expanded: boolean;
    model: ObjectModel;
}

export const ViewerThumb: React.FC<ViewerThumbProps> = ({ show, model, expanded }) => {
    const [isThumbLoaded] = useLoadImage(model.thumb);
    const [isSmallThumbLoaded] = useLoadImage(model.thumb_300x300);
    const thumb = expanded ? model.thumb : model.thumb_300x300;

    const { t } = useTranslation();

    return (
        <div className={cn([styles.box, { invisible: !show, [styles.expanded]: expanded }])}>
            {(!expanded && isSmallThumbLoaded) || (expanded && isThumbLoaded) ? (
                <img className={cn('fade-in', styles.img)} src={thumb} alt="Model rendering result" />
            ) : (
                <div className={cn('fade-in', styles.inner)}>
                    <Preloader className="rmd-typography--hint" />

                    {model.thumb_status === 'in_progress' && (
                        <Typography type="subtitle-2" component={'div'} className="small-margin-top">
                            {t('messages.thumbnailRendering', 'Thumbnail is being rendered')}
                        </Typography>
                    )}
                </div>
            )}
        </div>
    );
};
