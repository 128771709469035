import React from 'react';
import { useAppSelector } from '@app/hooks';
import { selectOrderLoaded } from '@modules/order';
import { useOrderId } from '@pages/widget/order/hooks';
import { SpecificationForm } from './specification-form';
import { SpecificationDrawings } from './specification-drawings';
import { SpecificationDetailsProps } from './types';

export const SpecificationDetails: React.FC<SpecificationDetailsProps> = ({ model }) => {
    const currentOrderId = useOrderId();
    const orderExists = useAppSelector(selectOrderLoaded);
    const hasLoadedOrder = !currentOrderId || orderExists; // if we have order, but it's not loaded yet

    if (!hasLoadedOrder) return null;

    return (
        <SpecificationForm model={model}>
            <SpecificationDrawings model={model} />
        </SpecificationForm>
    );
};
