import * as Three from 'three';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useQueryParams, StringParam, BooleanParam, withDefault } from 'use-query-params';
import { useAppSelector } from '@app/hooks';
import { selectContentCustomization } from '@modules/app';
import { ModelViewerDarkModeBox } from '@pages/widget/order/viewer/model-viewer/model-viewer-dark-mode-box';
import { ModelViewerSceneControlsPanel } from '@pages/widget/order/viewer/model-viewer/model-viewer-scene-controls-panel';
import { FeatureKeys as FF } from '@components/feature-flags';
import { CustomVariables as CV, ModelViewerObjects } from '@utils';
import { ModelViewer } from '@utils';
import { useGetResizeRef } from '@utils/parant-site-comunicator';

import styles from '../widget/order/viewer/viewer-window.module.scss';

enum PageParams {
    ModelFileUrl = 'modelFileUrl',
    WallThicknessFileUrl = 'wallThicknessFileUrl',
}

export function useViewerUrls() {
    const [query] = useQueryParams(
        {
            [PageParams.ModelFileUrl]: withDefault(StringParam, undefined),
            [PageParams.WallThicknessFileUrl]: withDefault(StringParam, undefined),

            [FF.Viewer.DarkModeOn]: withDefault(BooleanParam, false),
            [FF.Viewer.AxesShown]: withDefault(BooleanParam, true),
            [FF.Viewer.GridFloorShown]: withDefault(BooleanParam, true),
            [FF.Viewer.ModelBoxShown]: withDefault(BooleanParam, true),
            [FF.Viewer.PrinterBoxShown]: withDefault(BooleanParam, true),

            [FF.Viewer.DarkModeBtnShown]: withDefault(BooleanParam, true),
            [FF.Viewer.AxesBtnShown]: withDefault(BooleanParam, true),
            [FF.Viewer.GridFloorBtnShown]: withDefault(BooleanParam, true),
            [FF.Viewer.ModelBoxBtnShown]: withDefault(BooleanParam, true),
            [FF.Viewer.PrinterBoxBtnShown]: withDefault(BooleanParam, true),

            [CV.SceneBackgroundColor]: withDefault(StringParam, undefined),
            [CV.ModelColor]: withDefault(StringParam, undefined),
            [CV.ModelEdgesColor]: withDefault(StringParam, undefined),
            [CV.ModelBoundingBoxColor]: withDefault(StringParam, undefined),
            [CV.PrinterBoundingBoxColor]: withDefault(StringParam, undefined),
            [CV.PrinterBoundingBoxSize]: withDefault(StringParam, undefined),
        },
        { removeDefaultsFromUrl: true },
    );

    return query;
}

export const ModelViewerPage = () => {
    let visualizer: HTMLDivElement;
    const modelViewer = useRef<ModelViewer>();
    const [isModelSet, setIsModelSet] = useState(false);
    const contentCustomization = useAppSelector(selectContentCustomization);

    const { modelFileUrl, wallThicknessFileUrl, ...config } = useViewerUrls();

    const [isDarkModeActive, toggleDarkMode] = useReducer(previous => !previous, config[FF.Viewer.DarkModeOn]);

    useEffect(() => {
        modelViewer.current = new ModelViewer(visualizer, {
            darkModeOn: config[FF.Viewer.DarkModeOn],
            backgroundColor: config[CV.SceneBackgroundColor],
            model: {
                color: config[CV.ModelColor],
            },
            objects: {
                [ModelViewerObjects.Axes]: {
                    enabled: [config[FF.Viewer.AxesShown], config[FF.Viewer.AxesBtnShown]].some(Boolean),
                    active: config[FF.Viewer.AxesShown],
                },
                [ModelViewerObjects.Grid]: {
                    enabled: [config[FF.Viewer.GridFloorShown], config[FF.Viewer.GridFloorBtnShown]].some(Boolean),
                    active: config[FF.Viewer.GridFloorShown],
                },
                [ModelViewerObjects.ModelBoundingBox]: {
                    enabled: [config[FF.Viewer.ModelBoxShown], config[FF.Viewer.ModelBoxBtnShown]].some(Boolean),
                    active: config[FF.Viewer.ModelBoxShown],
                    color: config[CV.ModelBoundingBoxColor],
                },
                [ModelViewerObjects.PrinterBoundingBox]: {
                    enabled: [config[FF.Viewer.PrinterBoxShown], config[FF.Viewer.PrinterBoxBtnShown]].some(Boolean),
                    active: config[FF.Viewer.PrinterBoxShown],
                    color: config[CV.PrinterBoundingBoxColor],
                    size: config[CV.PrinterBoundingBoxSize] ?? new Three.Vector3().random().multiplyScalar(1000),
                },
                [ModelViewerObjects.ModelEdges]: {
                    color: config[CV.ModelEdgesColor],
                },
            },
        });

        return () => {
            modelViewer.current?.clear();
            modelViewer.current = undefined;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!modelFileUrl || !modelViewer.current) return;

        const { promise, cancel } = modelViewer.current.loadModel(modelFileUrl);

        promise.then(() => {
            setIsModelSet(true);
        });

        return () => {
            cancel();
        };
    }, [modelFileUrl]);

    // preload walls
    useEffect(() => {
        if (!isModelSet || !wallThicknessFileUrl || !modelViewer.current) return;

        const { promise, cancel } = modelViewer.current.loadThinWalls(wallThicknessFileUrl);
        promise.then(() => {
            modelViewer.current!.highlightThinWallsOn = true;
        });

        return () => {
            cancel();
        };
    }, [wallThicknessFileUrl, isModelSet]);

    const containerRef = useGetResizeRef();

    const axesBtnShown = config[FF.Viewer.AxesBtnShown];
    const gridFloorBtnShown = config[FF.Viewer.GridFloorBtnShown];
    const modelBoxBtnShown = config[FF.Viewer.ModelBoxBtnShown];
    const printerBoxBtnShown = config[FF.Viewer.PrinterBoxBtnShown];
    const controlsShown = axesBtnShown || gridFloorBtnShown || modelBoxBtnShown || printerBoxBtnShown;

    return (
        <>
            <div className={styles.box} tabIndex={-1} ref={containerRef}>
                <div
                    className={styles.inner}
                    ref={ref => {
                        visualizer = ref as HTMLDivElement;
                    }}
                >
                    <ModelViewerDarkModeBox
                        show={config[FF.Viewer.DarkModeBtnShown]}
                        isDarkModeActive={isDarkModeActive}
                        toggleDarkMode={toggleDarkMode}
                        modelViewer={modelViewer.current}
                    />
                    <ModelViewerSceneControlsPanel
                        show={controlsShown}
                        axesBtnShown={axesBtnShown}
                        gridFloorBtnShown={gridFloorBtnShown}
                        modelBoxBtnShown={modelBoxBtnShown}
                        printerBoxBtnShown={printerBoxBtnShown}
                        axesShown={config[FF.Viewer.AxesShown]}
                        gridFloorShown={config[FF.Viewer.GridFloorShown]}
                        modelBoxShown={config[FF.Viewer.ModelBoxShown]}
                        printerBoxShown={config[FF.Viewer.PrinterBoxShown]}
                        contentCustomization={contentCustomization}
                        modelViewer={modelViewer.current}
                    />
                </div>
            </div>
        </>
    );
};
