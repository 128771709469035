import isNil from 'lodash/isNil';
import React, { Children, HTMLAttributes, ReactNode, forwardRef } from 'react';
import cn from 'classnames';
import { Button, ButtonProps } from '@react-md/button';
import { Typography, TypographyProps } from '@react-md/typography';

import styles from './line-items.module.scss';

export const LINE_ITEM_EMPTY_VALUE = '—';

const isEmpty = (node: ReactNode) => node === '' || node === false || isNil(node);

export interface LineItemProps extends TypographyProps {
    label: ReactNode;
    labelClassName?: string;
    value?: ReactNode;
    valueClassName?: string;
}

export const LineItem = ({
    className,
    label,
    labelClassName,
    value,
    valueClassName,
    children,
    ...props
}: LineItemProps) => (
    <Typography type="body-2" component="div" className={cn(styles.box, className)} {...props}>
        <span className={cn('rmd-typography--semi-bold', styles.key, labelClassName)}>
            {label}
            {': '}
        </span>
        {value ? <LineItemValue className={valueClassName}>{value}</LineItemValue> : children}
    </Typography>
);

export const LineItemValue = ({ children, className, ...props }: HTMLAttributes<HTMLSpanElement>) => (
    <span className={cn(styles.value, className)} {...props}>
        {isEmpty(children) ? LINE_ITEM_EMPTY_VALUE : children}
    </span>
);

export const LineItemEditValue = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ children, className, ...props }, ref) => {
        const _isEmpty = isEmpty(children);
        return (
            <Button
                ref={ref}
                theme="primary"
                disableRipple
                className={cn('rmd-button--flat', 'rmd-typography--semi-bold', styles.editValue, className)}
                {...props}
            >
                {_isEmpty ? LINE_ITEM_EMPTY_VALUE : children}
            </Button>
        );
    },
);

export const LineItems = forwardRef<HTMLUListElement, HTMLAttributes<HTMLUListElement>>(
    ({ children, className, ...props }, ref) => {
        const hasChild = Boolean(Children.toArray(children).filter(Boolean).length);
        if (!hasChild) return null;

        return (
            <ul className={cn(styles.list, className)} ref={ref} {...props}>
                {children}
            </ul>
        );
    },
);
