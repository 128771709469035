import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    Pricing,
    TBD,
    PricingError,
    PriorityPriceItem,
    CalculatePricePayload,
    CalculatePriceResult,
    CalculateBulkPriceResult,
    PricingWarning,
} from '@types';

export interface CalculatePriceActionPayload extends CalculatePricePayload {
    is_parent: boolean;
    technology_id: number;
}

interface State {
    data?: Pricing;
    bulkPricesData?: PriorityPriceItem[];
    isLoading: boolean;
    isBulkPricesLoading: boolean;
    errors?: PricingError[];
    warnings?: PricingWarning[];
    tbd: TBD | null;
}

const initialState: State = {
    isLoading: false,
    isBulkPricesLoading: false,
    tbd: null,
};

export const pricingSlice = createSlice({
    name: 'pricing',
    initialState,
    reducers: {
        setPricing: (state, action: PayloadAction<Pricing | undefined>) => {
            state.data = action.payload;
        },
        setBulkPrices: (state, action: PayloadAction<PriorityPriceItem[] | undefined>) => {
            state.bulkPricesData = action.payload;
        },
        setBulkPriceCurrentTbd: (state, action: PayloadAction<TBD>) => {
            const { tbd_reason, tbd_reason_message } = action.payload;
            state.tbd = tbd_reason ? { tbd_reason, tbd_reason_message } : null;
        },

        calculate: (state, action: PayloadAction<CalculatePriceActionPayload>) => {
            state.isLoading = true;
        },
        calculateSuccess: (state, action: PayloadAction<CalculatePriceResult>) => {
            const { prices, analysing_errors, tbd_reason, tbd_reason_message } = action.payload;

            state.isLoading = false;
            state.data = prices;
            state.errors = analysing_errors ? analysing_errors : undefined;
            state.tbd = tbd_reason ? { tbd_reason, tbd_reason_message } : null;
        },
        calculateFailure: (state, action: PayloadAction<PricingError[]>) => {
            state.isLoading = false;
            state.data = undefined;
            state.errors = action.payload;
        },

        calculateBulk: (state, action: PayloadAction<CalculatePriceActionPayload>) => {
            state.isBulkPricesLoading = true;
        },
        calculateBulkSuccess: (state, action: PayloadAction<CalculateBulkPriceResult>) => {
            const { prices, analysing_errors, warnings } = action.payload;

            state.isBulkPricesLoading = false;
            state.bulkPricesData = prices;
            state.errors = analysing_errors ? analysing_errors : undefined;
            state.warnings = warnings;
        },
        calculateBulkFailure: (state, action: PayloadAction<PricingError[]>) => {
            state.isBulkPricesLoading = false;
            state.data = undefined;
            state.bulkPricesData = undefined;
            state.errors = action.payload;
        },
    },
});

export const pricingActions = pricingSlice.actions;
