import React from 'react';
import { useAppDispatch } from '@app/hooks';
import { Fade } from '@components/animations';
import { TransformModelPayload } from '@types';
import { modelsActions } from '@modules/models';
import { UploadedModel } from '../uploaded-models/uploaded-model';
import { TransformedModelHandlerProps } from '../../types';

import styles from './model-upload-handler.module.scss';

export const TransformedModelHandler: React.FC<TransformedModelHandlerProps> = ({
    transformedData,
    in: show,
    ...rest
}) => {
    const dispatch = useAppDispatch();
    const { data } = transformedData;
    const modelId = data.id;

    const onDelete = (payload: TransformModelPayload) => () => {
        dispatch(modelsActions.deleteTransformedModel(payload));
    };

    return (
        <Fade in={show} unmountOnExit className={styles.box} duration={300} {...rest}>
            <UploadedModel
                uuid={`transformed_model_${modelId || transformedData.fromModelId}`}
                status={data.status}
                label={data.title}
                error={data.detail}
                modelId={modelId}
                onDelete={onDelete(transformedData)}
            />
        </Fade>
    );
};
