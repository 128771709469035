import React, { FormEventHandler, useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { BaseStripeElementsProps } from './types';

export function useStripeConfirmPayment({ onSuccess, onError }: BaseStripeElementsProps) {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState<string>();
    const [isSending, setIsSending] = useState(false);

    const handleSubmit: FormEventHandler = async e => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsSending(true);

        const result = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
            confirmParams: {
                // When Stripe redirects the customer to the return_url (for some payment methods like iDEAL),
                // we provide the following URL query parameters: payment_intent + payment_intent_client_secret
                return_url: window.location.href,
            },
        });

        setIsSending(false);

        const error = result.error;
        const paymentIntent = result.paymentIntent;
        console.log(result, 'result');

        // currently we ok with it https://stripe.com/docs/payments/payment-intents/verifying-status#checking-status
        // but if we change the parameters to create the PaymentIntent on the backend (e.g. capture_method='manual'),
        // we need to be sure to process this result (status 'requires_capture') https://stripe.com/docs/payments/capture-later

        if (error) {
            if (error.type !== 'validation_error') {
                setMessage(error.message);
            }
            onError && onError(error);
            return;
        }

        if (paymentIntent) {
            if (paymentIntent.status === 'requires_payment_method') {
            } else {
                onSuccess && onSuccess(paymentIntent);
            }
        }
    };

    const isLoading = !stripe || !elements;

    return {
        handleSubmit,
        isLoading,
        isSending,
        message,
    };
}
