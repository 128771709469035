import { AuthPopup, PopupOpenPayload, AppThunk } from '@types';
import { selectAppSettings } from '../app';
import { popupsActions } from '../ui';

export const openAuthPopup =
    ({ type = AuthPopup.SignUp, ...rest }: Partial<PopupOpenPayload> = {}): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const { enabled_magic_links } = selectAppSettings(state);

        type = enabled_magic_links ? AuthPopup.OTP : type;
        dispatch(popupsActions.open({ type, ...rest }));
    };
