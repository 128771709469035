import { ClientExtendedRepresentation } from '@services/df/clients';
import { getNodeIdComposer } from '@utils';
import { HeaderAction, HeaderLink, HeaderButton } from './types';
import { RootPrefix } from './constants';
import { LanguageList } from '@constants';

export const _id = getNodeIdComposer(RootPrefix);
export const menuId = getNodeIdComposer(RootPrefix, 'menu');
export const iqtBannerId = getNodeIdComposer(RootPrefix, 'iqt_banner');

export function setHeaderHeight(enabled: boolean, headerEl?: HTMLDivElement | null) {
    let value = '100px';

    if (enabled) {
        const headerRect = headerEl && headerEl.getBoundingClientRect();

        if (headerRect && headerRect.height) {
            value = headerRect.height + 'px';
        }
    }

    document.documentElement.style.setProperty('--p-header-height', value);
}

export function isHeaderLink(item: HeaderAction): item is HeaderLink {
    return (item as HeaderLink).link !== undefined;
}

export function isHeaderButton(item: HeaderAction): item is HeaderButton {
    return (item as HeaderButton).onClick !== undefined;
}

export function composeClientLabel(client: ClientExtendedRepresentation) {
    const fullName = [client.name, client.surname].filter(Boolean);

    if (fullName.length) {
        return fullName.join(' ');
    }

    return client.email;
}

export function adaptLanguageList(languages: LanguageList) {
    const adaptedLanguages = languages.map(language => ({
        code: Object.keys(language)[0],
        name: Object.values(language)[0],
    }));
    return adaptedLanguages;
}
