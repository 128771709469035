import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, Invoice, InvoicePayment, OrderUpdateViaInvoiceData, PaymentStage } from '@types';
import { selectInvoice, selectIsPollingPaymentStatusActive, selectInvoicePaymentStage } from './selectors';

interface State {
    data?: Invoice;
    payment?: InvoicePayment;
    paymentStage: PaymentStage;
    isLoading: boolean;
    isLoadingPayment: boolean;
    isUpdating: boolean;
    isPaymentModalOpen: boolean;
    isPollingActive: boolean;
}

const initialState: State = {
    isLoading: false,
    isLoadingPayment: false,
    isUpdating: false,
    paymentStage: PaymentStage.NeedsInitialization,
    isPaymentModalOpen: false,
    isPollingActive: false,
};

function updatePaymentState(invoice: Invoice) {
    return {
        paymentStage: invoice.payment_status,
        payment: invoice.payment,
    };
}

export const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        initPage: (
            state,
            action: PayloadAction<{
                invoiceId: string;
                hash: string;
            }>,
        ) => {
            state.isLoading = true;
        },

        updatePaymentStage: (
            state,
            action: PayloadAction<{
                status?: Invoice['payment_status'];
                nextAction: InvoicePayment['next_action'];
            }>,
        ) => {
            state.paymentStage = action.payload.status ? action.payload.status : state.paymentStage;
            state.payment = state.payment ? { ...state.payment, next_action: action.payload.nextAction } : undefined;
        },
        openPaymentModal: state => {
            state.isPaymentModalOpen = true;
        },
        closePaymentModal: state => {
            state.isPaymentModalOpen = false;
        },

        startPaymentStatusPolling: (
            state,
            action: PayloadAction<{
                invoiceId: string;
                hash: string;
            }>,
        ) => {
            state.isPollingActive = true;
        },
        stopPaymentStatusPolling: state => {
            state.isPollingActive = false;
        },

        load: (
            state,
            action: PayloadAction<{
                invoiceId: string;
                hash: string;
            }>,
        ) => {
            state.isLoading = true;
        },
        loadSuccess: (state, action: PayloadAction<Invoice>) => ({
            ...state,
            data: action.payload,
            isLoading: false,
            // reset previous invoice
            ...updatePaymentState(action.payload),
        }),
        loadFailure: state => {
            state.isLoading = false;
        },

        update: (
            state,
            action: PayloadAction<{
                invoiceId: string;
                hash: string;
                data: OrderUpdateViaInvoiceData;
            }>,
        ) => {
            state.isUpdating = true;
        },
        updateSuccess: (state, action: PayloadAction<Invoice>) => ({
            ...state,
            data: action.payload,
            isUpdating: false,
            ...updatePaymentState(action.payload),
        }),
        updateFailure: state => {
            state.isUpdating = false;
        },

        loadPayment: (
            state,
            action: PayloadAction<{
                invoiceId: string;
                hash: string;
            }>,
        ) => {
            state.isLoadingPayment = true;
        },
        loadPaymentSuccess: (state, action: PayloadAction<Invoice>) => ({
            ...state,
            isLoadingPayment: false,
            ...updatePaymentState(action.payload),
        }),
        loadPaymentFailure: state => {
            state.isLoadingPayment = false;
        },
    },
});

export const invoiceActions = invoiceSlice.actions;

interface InvoiceParams {
    invoiceId: string;
    hash: string;
}

export const initInvoicePage =
    (params: InvoiceParams): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const invoice = selectInvoice(state);

        if (invoice && invoice.id.toString() === params.invoiceId) {
            return;
        }

        dispatch(invoiceActions.load(params));
    };

export const createPaymentStatusPolling =
    (params: InvoiceParams): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const isActive = selectIsPollingPaymentStatusActive(state);
        const stage = selectInvoicePaymentStage(state);

        if (isActive || stage !== PaymentStage.Processing) return;

        dispatch(invoiceActions.startPaymentStatusPolling(params));
    };
