import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { FeatureContent } from '@components/feature-content';
import { SidebarSection } from '@components/sidebar-section';
import { OrderAmountDue } from '@modules/order';
import type { Order } from '@types';
import { sidebarId } from '../helpers';

interface Props {
    order: Order;
}

export const InvoiceSidebarAmountDue = ({ order }: Props) => {
    const { t } = useTranslation();

    if (!order.is_remaining_amount_shown && !order.is_down_payment_shown) {
        return null;
    }

    const labelId = sidebarId('amount_due_hint');

    return (
        <SidebarSection span>
            {order.is_down_payment_shown && (
                <Typography id={labelId} type="body-1" className="medium-margin-bottom">
                    <FeatureContent
                        contentKey={labelId}
                        fallback={t(
                            'messages.downPaymentIsRequired',
                            'A bank transfer down payment is required to initiate your order',
                        )}
                    />
                </Typography>
            )}

            <OrderAmountDue order={order} />
        </SidebarSection>
    );
};
