import { useGetResizeRef } from '@utils/parant-site-comunicator';

import styles from './error-page.module.scss';

interface Props {
    label: string;
}

const Page = ({ label }: Props) => {
    const containerRef = useGetResizeRef();

    return (
        <div className={styles.layout} ref={containerRef}>
            <div className="container">
                <div className="rmd-typography--center">
                    <div className={styles.label}>{label}</div>
                </div>
            </div>
        </div>
    );
};

export const ForbiddenPage = () => <Page label="403" />;
export const NotFoundPage = () => <Page label="404" />;
