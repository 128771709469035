import { useCallback } from 'react';
import { setWidgetIframeHeight } from './set-widget-iframe-height';
import { useResizeDetector } from 'react-resize-detector';

export function useGetResizeRef() {
    const onResize = useCallback(({ height }) => {
        const headersHeight = Number(
            document.documentElement.style.getPropertyValue('--p-header-height').replace('px', ''),
        );
        // ** appPadding must be equal or less than $app-padding or order page will increase the height **
        const appPadding = 20;
        const widgetHeight = height + headersHeight + appPadding;
        setWidgetIframeHeight(widgetHeight);
    }, []);

    const { ref } = useResizeDetector({
        handleWidth: false,
        refreshMode: 'debounce',
        refreshRate: 500,
        onResize,
    });

    return ref;
}
