import uniqueId from 'lodash/uniqueId';
import { Analytics, AnalyticsInstance } from 'analytics';
import hubSpotPlugin from '@analytics/hubspot';
import googleAnalytics from '@analytics/google-analytics';
import googleTagManager from '@analytics/google-tag-manager';
import { APP_GTM_ID } from './constants';

type getAnalyticsConfig<Type> = {
    [Property in Type as `get${Capitalize<string & Property>}AnalyticsConfig`]: () => any;
};

declare global {
    interface Window extends getAnalyticsConfig<'ga' | 'gtm' | 'hubspot'> {
        COMPANY_GA_ID?: string;
        COMPANY_GTM_ID?: string;
        COMPANY_HUBSPOT_ID?: string;
        Analytics?: AnalyticsInstance;
    }
}

// const debuggerPlugin = {
//    name: 'debugger',
//    track: ({ payload, instance }: { payload: any; instance: any }) => {
//        console.log('Track from debugger', payload);
//        console.log(instance.getState());
//    },
// };

export function getInitializedAnalytics() {
    const {
        COMPANY_GTM_ID,
        COMPANY_HUBSPOT_ID,
        COMPANY_GA_ID,
        getGaAnalyticsConfig,
        getGtmAnalyticsConfig,
        getHubspotAnalyticsConfig,
    } = window;

    const ga =
        (getGaAnalyticsConfig && googleAnalytics(getGaAnalyticsConfig())) ||
        (COMPANY_GA_ID && googleAnalytics({ measurementIds: [COMPANY_GA_ID] }));

    const gtm =
        (getGtmAnalyticsConfig && googleTagManager(getGtmAnalyticsConfig())) ||
        (COMPANY_GTM_ID && googleTagManager({ containerId: COMPANY_GTM_ID }));

    const hubspot =
        (getHubspotAnalyticsConfig && hubSpotPlugin(getHubspotAnalyticsConfig())) ||
        (COMPANY_HUBSPOT_ID && hubSpotPlugin({ portalId: COMPANY_HUBSPOT_ID }));

    const plugins = [
        ga,
        gtm,
        hubspot,

        // DF analytics
        APP_GTM_ID &&
            Object.assign({}, googleTagManager({ containerId: APP_GTM_ID }), { name: 'google-tag-manager-df' }),
    ].filter(Boolean);

    return Analytics({
        plugins,
        debug: process.env.NODE_ENV === 'development',
    });
}
