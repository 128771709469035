import isEmpty from 'lodash/isEmpty';
import { Technology, ModelSpecifications, SuitableMaterials } from '@types';
import { isCNC } from '@utils';

function getSpecificationUpdatesBySuitableMaterials(
    modelId: string,
    { is_ready, suitable_materials }: SuitableMaterials,
    technologies: Technology[],
) {
    if (!is_ready) return;

    const specification = {} as ModelSpecifications;

    // We should set parent modelId as correct object model here for all CNC techs of the company
    // if no any suitable material, just for rendering something in the viewer
    for (let tech of technologies) {
        const technologyId = tech.id;
        const hasAnySuitable = tech.materials.some(m => suitable_materials.includes(m.id));

        if (isCNC(tech.tech_id) && !hasAnySuitable) {
            specification['correctObjectModels'] = {
                ...specification['correctObjectModels'],
                [technologyId]: parseInt(modelId),
            };
        }
    }

    return specification;
}

export function getSpecificationsUpdatesBySuitableMaterials(
    readySuitableMaterials: Array<[string, SuitableMaterials]>,
    technologies: Technology[],
) {
    return readySuitableMaterials.reduce((acc, [modelId, readySuitableMaterials]) => {
        const specification = getSpecificationUpdatesBySuitableMaterials(modelId, readySuitableMaterials, technologies);

        if (!specification || isEmpty(specification)) {
            return acc;
        }

        return { ...acc, [modelId]: specification };
    }, {});
}
