import cn from 'classnames';
import React, { FocusEventHandler, KeyboardEventHandler, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, useSelectState } from '@react-md/form';
import { Typography } from '@react-md/typography';
import { CountInput, CountInputProps } from '@components/count-input';
import { ContentKeys as FC, FeatureContent } from '@components/feature-content';
import { Icons } from '@components/icons';
import { LineItem } from '@components/line-items';
import { ObjectModel, ObjectModelUnitsTypes } from '@types';
import { useUpdateModel } from '@pages/widget/order/hooks';
import { useModelUnitsLabels } from '@hooks';
import { getNodeIdComposer } from '@utils';
import { AxisNames } from '@constants';
import { nonCadDetailsId as _id } from './helpers';
import { cutSelectListIfTooLong, cutSelectListIfTooLongOnKeydown } from '@utils/widget/cut-select-list-if-too-long';

import styles from './non-cad-details.module.scss';

const formId = getNodeIdComposer('non_cad_update_form');

interface Props {
    model: ObjectModel;
}

export const NonCadSizes: React.FC<Props> = ({ model }) => {
    const update = useUpdateModel(model);

    // size inputs
    const [size, setSize] = useState<ObjectModel['size']>(model.size);
    const handleSizeChange: CountInputProps<true>['onChange'] = useCallback(
        (value, _, element) => {
            setSize(size => ({ ...size, [element!.name]: value }));
        },
        [setSize],
    );

    const handleSizeBlur: FocusEventHandler<HTMLInputElement> = useCallback(
        ({ target }) => {
            update({ size });
        },
        [size, update],
    );

    const handleSizeKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
        ({ code, currentTarget }) => {
            if (code === 'Enter' || code === 'NumpadEnter') {
                update({ size });
            }
        },
        [size, update],
    );

    // units input
    const [units, setUnits] = useSelectState<ObjectModel['units']>(model.units);
    const handleUnitsChange = useCallback(
        (nextValue: string) => {
            setUnits(nextValue);
            update({ units: nextValue as ObjectModel['units'] });
        },
        [setUnits, update],
    );

    // reset local state to model defaults when switching model or updating units
    useEffect(() => {
        setUnits(model.units);
        setSize(model.size);
    }, [model.size, model.units, setSize, setUnits]);

    const { t } = useTranslation();
    const unitsLabels = useModelUnitsLabels();
    const sizeLabels = {
        x: t('measurements.x', 'x'),
        y: t('measurements.y', 'y'),
        z: t('measurements.z', 'z'),
    };

    return (
        <LineItem
            label={t('labels.modelSize', 'Size')}
            className={cn('inline-flex', styles.form)}
            labelClassName={'small-margin-right'}
        >
            <div className={cn('emulated-flex-gap', styles.sizes)}>
                {AxisNames.map(side => {
                    const labelId = formId(`size_${side}`, 'label');
                    const counterId = formId(`size_${side}`, 'field');
                    return (
                        <div key={side} className={cn('inline-flex', 'items-center', styles.size)}>
                            <Typography
                                id={labelId}
                                type="body-2"
                                component={'label'}
                                className={styles.label}
                                {...{ htmlFor: counterId }}
                            >
                                <FeatureContent contentKey={labelId} fallback={sizeLabels[side]} />
                            </Typography>
                            <CountInput
                                key={side}
                                id={counterId}
                                name={side}
                                min={0}
                                // precision={6}
                                cleanable
                                value={size[side]}
                                className={cn('bg-primary', 'rmd-text-field-container--small')}
                                onChange={handleSizeChange}
                                onBlur={handleSizeBlur}
                                onKeyDown={handleSizeKeyDown}
                                controls={false}
                                inline
                            />
                        </div>
                    );
                })}
            </div>

            <Select
                id={formId('units', 'field')}
                className={cn('rmd-typography--theme-primary', styles.units)}
                onChange={handleUnitsChange}
                options={Object.entries(unitsLabels)
                    .map(([key, value]) => ({
                        label: value.label,
                        value: key,
                    }))
                    .filter(units => units.value !== ObjectModelUnitsTypes.Centimeters)}
                value={units}
                rightChildren={<Icons.Chevron width={12} height={12} />}
                // anchor={BELOW_RIGHT_ANCHOR}
                getDisplayLabel={() => unitsLabels[units].symbol}
                listboxClassName="rmd-listbox--small"
                listboxWidth="min"
                inline
                closeOnResize
                closeOnScroll
                onClick={cutSelectListIfTooLong}
                onKeyDown={event => cutSelectListIfTooLongOnKeydown(event.key)}
            />
        </LineItem>
    );
};
