import { Preselection, Technology, ModelSpecifications } from '@types';
import { isCNC } from '@utils';

function getSpecificationByPreselection(
    { is_ready, material: preselectedMaterial }: Preselection,
    technologies: Technology[],
    fullPreselectionModeOn: boolean = false,
) {
    if (!is_ready) return;

    const specification = {} as ModelSpecifications;

    const technology = preselectedMaterial
        ? technologies.find(technology =>
              technology.materials.find(material => {
                  return material.id === preselectedMaterial;
              }),
          )
        : technologies[0];

    const technologyId = technology!.id;

    if (preselectedMaterial) {
        specification['materialIds'] = { [technologyId.toString()]: preselectedMaterial };
    } else {
        if (fullPreselectionModeOn || !isCNC(technology!.tech_id)) {
            // For 3D we can set first material of technology as fallback
            // and after a request for a price, we will receive a correct object model
            specification['materialIds'] = { [technologyId.toString()]: technology!.materials[0].id };
        }
    }

    return {
        technologyId,
        ...specification,
    };
}

export function getSpecificationsByPreselection(
    preselectionList: Array<[string, Preselection]>,
    technologies: Technology[],
    fullPreselectionModeOn: boolean = false,
) {
    return preselectionList.reduce((acc, [modelId, preselection]) => {
        const specification = getSpecificationByPreselection(preselection, technologies, fullPreselectionModeOn);

        if (!specification) return acc;

        return {
            ...acc,
            [modelId]: specification,
        };
    }, {});
}
