import flatten from 'lodash/flatten';
import isArray from 'lodash/isArray';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DropzoneOptions, FileError, FileRejection } from 'react-dropzone';
import { formatDropzoneRejections, formatBytes } from '@utils';

// todo check backoffice for updates

export function getFileError(
    file: string,
    code: FileError['code'],
    { minSize, maxSize, accept, maxFiles }: Pick<DropzoneOptions, 'minSize' | 'maxSize' | 'accept' | 'maxFiles'>,
) {
    const values = {
        file,
        accept: accept && isArray(accept) ? accept.join(', ') : accept,
        maxSize: maxSize && formatBytes(maxSize, { expressIn: 'MB' }),
        minSize: minSize && formatBytes(minSize, { expressIn: 'MB' }),
        maxFiles,
    };

    switch (code) {
        case 'file-too-large':
            return (
                <Trans
                    i18nKey="errors.fileTooLarge"
                    values={values}
                    defaults={'<strong>{{ file }}</strong> is too big, maximum file size is {{ maxSize }} MB.'}
                />
            );
        case 'file-too-small':
            return (
                <Trans
                    i18nKey="errors.fileTooSmall"
                    values={values}
                    defaults={'<strong>{{ file }}</strong> is smaller than {{ minSize }} MB.'}
                />
            );
        case 'file-invalid-type':
            return (
                <Trans
                    i18nKey="errors.fileInvalidType"
                    values={values}
                    defaults={
                        '<strong>{{ file }}</strong> has wrong file extension, uploads available only for {{ accept }} files.'
                    }
                />
            );
        case 'too-many-files':
            return (
                <Trans
                    i18nKey="errors.tooManyFiles"
                    values={values}
                    defaults={'Files limit exceeded. Maximum {{ maxFiles }} files at one time'}
                />
            );
        default:
            return '';
    }
}

interface DropzoneErrorsProps {
    files: FileRejection[];
    opts: Pick<DropzoneOptions, 'minSize' | 'maxSize' | 'maxFiles' | 'accept'>;
}

export const DropzoneErrors: React.FC<DropzoneErrorsProps> = ({ files, opts }) => {
    const { t } = useTranslation();

    if (!files.length) return null;

    const rejections = formatDropzoneRejections(files); // TODO remove uuid while RTK adding (move it to prepare in reducer)

    const messages: Array<string | React.ReactElement> = rejections.some(file =>
        file.errorCodes.includes('too-many-files'),
    )
        ? [
              t('errors.tooManyFiles', 'Files limit exceeded. Maximum {{ maxFiles }} files at one time', {
                  maxFiles: opts.maxFiles,
              }),
          ]
        : [];

    const errors: Array<Array<string | React.ReactElement>> = rejections.map(({ fileName, errorCodes, codeValues }) => {
        return errorCodes
            .filter(code => code !== 'too-many-files')
            .map(code => getFileError(fileName, code, { ...opts, ...codeValues[code] }));
    });

    messages.push(...flatten(errors).filter(Boolean));

    return <>{messages.length > 1 ? messages.map((message, i) => <div key={i}>{message}</div>) : messages[0]}</>;
};
