import cn from 'classnames';
import React, { DispatchWithoutAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '@react-md/form';
import { Typography } from '@react-md/typography';
import { Tooltipped } from '@react-md/tooltip';
import { FeatureKeys as FF, FeatureOn, FeatureToggle } from '@components/feature-flags';
import { getNodeIdComposer, ModelViewer as ModelViewerUtil } from '@utils';
import { ModelViewerPrefix } from '../constants';

import styles from './model-viewer-dark-mode-box.module.scss';

const _id = getNodeIdComposer(ModelViewerPrefix, 'dark_mode_box');

export interface ModelViewerDarkModeBoxProps {
    show: boolean;
    isDarkModeActive: boolean;
    toggleDarkMode: DispatchWithoutAction;
    modelViewer?: ModelViewerUtil;
}

export const ModelViewerDarkModeBox: React.FC<ModelViewerDarkModeBoxProps> = ({
    show,
    isDarkModeActive,
    toggleDarkMode,
    modelViewer,
}) => {
    const { t } = useTranslation();
    return (
        <FeatureToggle flagKey={FF.Viewer.DarkModeBtnShown}>
            <FeatureOn>
                <div className={cn([styles.box, { invisible: !show }])}>
                    <Tooltipped
                        id={_id('switch')}
                        tooltip={t('tooltips.toggleSceneTheme', 'Toggle scene theme')}
                        position="below"
                        lineWrap={false}
                        spacing={10}
                        vwMargin={20}
                        disableAutoSpacing
                    >
                        <Switch
                            id={_id('switch')}
                            label={
                                <Typography
                                    type="headline-6"
                                    className={cn(styles.label, { [styles.dark]: isDarkModeActive })}
                                    component="span"
                                    color="hint"
                                >
                                    {t(['labels.darkMode', 'Dark mode'])}:
                                </Typography>
                            }
                            className={styles.switch}
                            ballClassName={styles.ball}
                            onClick={() => {
                                if (modelViewer) {
                                    modelViewer.darkModeOn = !isDarkModeActive;
                                }
                                toggleDarkMode();
                            }}
                        />
                    </Tooltipped>
                </div>
            </FeatureOn>
        </FeatureToggle>
    );
};
