import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Button } from '@react-md/button';
import { Typography } from '@react-md/typography';
import { Alert, AlertHeading } from '@components/alert';
import { Fade } from '@components/animations';
import { FeatureContent, FeatureContentCompound } from '@components/feature-content';
import { SidebarSection } from '@components/sidebar-section';
import { InitialOrder } from '@types';
import { decimalToBoolean, formatPrice } from '@utils';
import { selectAppSettings } from '@modules/app';
import { orderActions } from '@modules/order';
import { Link } from '@react-md/link';
import { useGetOrderUrl } from '../../order/hooks';
import { sidebarId } from '../order-sidebar';

import styles from './order-startup-cost-box.module.scss';

interface OrderStartupCostBoxProps {
    order: InitialOrder;
}

export const OrderStartupCostBox: React.FC<OrderStartupCostBoxProps> = ({ order }) => {
    const dispatch = useAppDispatch();
    const settings = useAppSelector(selectAppSettings);
    const currencyConfig = {
        ...settings.currency,
        precision: settings.currency.decimal_digits,
    };
    const totalCost = formatPrice(order.models_price, currencyConfig);
    const priceDiff = formatPrice(order.price_min_difference, currencyConfig);
    const companyMinOrderAmount = formatPrice(settings.minimum_order_amount, currencyConfig);

    const { t } = useTranslation();

    const [getOrderUrl] = useGetOrderUrl();
    const orderUrl = getOrderUrl();

    const toggleStartupCost = useCallback(() => {
        dispatch(
            orderActions.update({
                orderId: order.id!,
                data: { use_additional_startup_cost: !order.use_additional_startup_cost },
            }),
        );
    }, [dispatch, order]);

    const transValues = { priceDiff, totalCost, companyMinOrderAmount, orderUrl };
    const transComps = {
        bold: <strong />,
        lnk: <Link className={styles.link} href={orderUrl} />,
    };

    return (
        <Fade
            as={SidebarSection}
            span
            in={decimalToBoolean(order.price_min_difference)}
            className={styles.box}
            mountOnEnter
            unmountOnExit
        >
            {order.use_additional_startup_cost ? (
                <Alert className={styles.alert} show variant="filled" type="customWarning">
                    <AlertHeading iconType="info">
                        <div className={styles.body}>
                            <Typography type="subtitle-2" color="hint" component="div">
                                <FeatureContentCompound
                                    contentKey={sidebarId('additional_startup_cost_text')}
                                    i18nKey="messages.additionalStartupCostRemove"
                                    defaults={`Additional startup cost: <bold>{{priceDiff}}</bold>. Click the button below to remove.`}
                                    values={transValues}
                                    components={transComps}
                                />
                            </Typography>
                        </div>
                        <FeatureContent
                            contentKey={sidebarId('remove_additional_cost')}
                            fallback={t('buttons.additionalStartupCostRemoveButton', 'Remove additional cost')}
                            wrapper={props => (
                                <Button
                                    className={`${styles.toggleButton} rmd-button--text rmd-button--outline`}
                                    theme="clear"
                                    themeType="outline"
                                    type="button"
                                    disableRipple
                                    onClick={toggleStartupCost}
                                    {...props}
                                />
                            )}
                        />
                    </AlertHeading>
                </Alert>
            ) : (
                <Alert show className={styles.alert} variant="filled" type="customWarning">
                    <AlertHeading className={styles.alertHeading} iconType="info">
                        <div className={styles.body}>
                            <Typography type="subtitle-2" className={styles.text} component={'div'}>
                                <FeatureContentCompound
                                    contentKey={sidebarId('order_price_is_low_text')}
                                    i18nKey="messages.additionalStartupCost"
                                    defaults={`Your order price of <bold>{{totalCost}}</bold> is currently
                                        <bold>{{priceDiff}}</bold> less than the minimum order price required.
                                        Please add more products to your <lnk><bold>shopping cart</bold></lnk>.<br/><br/>
                                        Or click the button below to automatically increase your order price to
                                        <bold>{{companyMinOrderAmount}}</bold>. Adjustment will be reflected as an
                                        increase in startup cost.`}
                                    values={transValues}
                                    components={transComps}
                                />
                            </Typography>
                        </div>
                        <Button
                            className={`${styles.toggleButton} rmd-button--text rmd-button--outline`}
                            theme="clear"
                            themeType="outline"
                            type="button"
                            disableRipple
                            onClick={toggleStartupCost}
                        >
                            <FeatureContentCompound
                                contentKey={sidebarId('update_order_price')}
                                i18nKey="buttons.additionalStartupCostIncreaseButton"
                                defaults={`Update the order price to {{companyMinOrderAmount}}`}
                                values={{ companyMinOrderAmount }}
                            />
                        </Button>
                    </AlertHeading>
                </Alert>
            )}
        </Fade>
    );
};
