import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createPersistReducer, PersistReducerConfigs } from '@app/persist';
import {
    AppThunk,
    ObjectModelStatus,
    UploadFileAcceptType,
    UploadFileRejectType,
    UploadingModel,
    UploadJobResponse,
    UploadModelsResponseError,
    InvalidateLevel,
    UploadModelsObjectResponse,
} from '@types';
import { appActions } from '../app';
import { selectIsPollingCheckModelsStatusActive, selectAnalyzingModelIds } from './selectors';

export interface UploadModelsReducerState {
    uploadJob?: string;
    acceptedFilesIds: string[];
    acceptedFiles: Record<string, UploadingModel>;
    rejectedFiles: UploadFileRejectType[];
    error?: UploadModelsResponseError;
    isUploadJobCreating: boolean;
    isUploadJobBinding: boolean;
    isUploading: boolean;
    isStatusChecking: boolean;
    isPollingActive: boolean;
}

const initialState: UploadModelsReducerState = {
    acceptedFilesIds: [],
    acceptedFiles: {},
    rejectedFiles: [],
    isUploadJobCreating: false,
    isUploadJobBinding: false,
    isUploading: false,
    isStatusChecking: false,
    isPollingActive: false,
};

export const uploadModelsSlice = createSlice({
    name: 'uploadModels',
    initialState,
    reducers: {
        initUploading: (state, action: PayloadAction<UploadFileAcceptType[]>) => {},
        addAcceptedFiles: (state, action: PayloadAction<UploadFileAcceptType[]>) => {
            state.acceptedFilesIds = [...state.acceptedFilesIds, ...action.payload.map(file => file.uuid)];
            state.acceptedFiles = {
                ...state.acceptedFiles,
                ...action.payload.reduce(
                    (acc, file) => ({
                        ...acc,
                        [file.uuid]: {
                            upload: file,
                        },
                    }),
                    {},
                ),
            };
        },
        addRejectedFiles: (state, action: PayloadAction<UploadFileRejectType[]>) => {
            state.rejectedFiles = state.rejectedFiles.concat(action.payload);
        },
        deleteRejectedFile: (state, action: PayloadAction<string>) => {
            state.rejectedFiles = state.rejectedFiles.filter(file => file.uuid !== action.payload);
        },

        createUploadJob: state => {
            state.isUploadJobCreating = true;
        },
        createUploadJobSuccess: (state, action: PayloadAction<UploadJobResponse>) => {
            state.isUploadJobCreating = false;
            state.uploadJob = action.payload.uj;
        },
        createUploadJobFailure: state => {
            state.isUploadJobCreating = false;
        },

        rebindUploadJob: (state, action: PayloadAction<string>) => {
            state.isUploadJobBinding = true;
        },
        rebindUploadJobSuccess: state => {
            state.isUploadJobBinding = false;
        },
        rebindUploadJobFailure: state => {
            state.isUploadJobBinding = false;
        },

        setUploadJob: (state, action: PayloadAction<string | undefined>) => {
            state.uploadJob = action.payload;
        },
        updateProgress: (
            state,
            action: PayloadAction<{
                UUIDs: string[];
                progress: number;
            }>,
        ) => {
            state.acceptedFiles = Object.entries(state.acceptedFiles).reduce((acc, [uuid, uploading]) => {
                if (action.payload.UUIDs.includes(uuid)) {
                    return {
                        ...acc,
                        [uuid]: {
                            ...uploading,
                            upload: {
                                ...uploading.upload,
                                progress: action.payload.progress,
                            },
                        },
                    };
                }

                return { ...acc, [uuid]: uploading };
            }, {});
        },

        upload: (
            state,
            action: PayloadAction<{
                uj: string;
                upload: UploadFileAcceptType[];
            }>,
        ) => {
            state.isUploading = true;
        },
        uploadSuccess: (state, action: PayloadAction<Record<string, UploadingModel>>) => {
            state.isUploading = false;
            state.acceptedFiles = Object.assign(state.acceptedFiles, action.payload);
        },
        uploadFailure: (state, action: PayloadAction<UploadModelsResponseError>) => {
            state.isUploading = false;
            state.error = action.payload;
        },

        deleteUploadedFile: (state, action: PayloadAction<string>) => {
            state.acceptedFilesIds = state.acceptedFilesIds.filter(id => id !== action.payload);
            state.acceptedFiles = Object.entries(state.acceptedFiles).reduce((acc, [uuid, uploading]) => {
                if (uuid === action.payload) {
                    return acc;
                }

                return { ...acc, [uuid]: uploading };
            }, {});
        },
        deleteUploadedModel: (
            state,
            action: PayloadAction<{
                uuid: string;
                modelTitle: string;
            }>,
        ) => {
            let shouldRemoveFile = false;
            const { uuid: fileUUID, modelTitle } = action.payload;

            return {
                ...state,
                acceptedFiles: Object.entries(state.acceptedFiles).reduce((acc, [uuid, uploading]) => {
                    if (uuid === fileUUID) {
                        const models = uploading.uploaded!.object_models!.filter(model => model.title !== modelTitle);

                        if (!models.length) {
                            shouldRemoveFile = true;
                            return acc;
                        }

                        return {
                            ...acc,
                            [uuid]: {
                                ...uploading,
                                uploaded: {
                                    ...uploading.uploaded,
                                    object_models: models,
                                },
                            },
                        };
                    }

                    return { ...acc, [uuid]: uploading };
                }, {}),
                ...(shouldRemoveFile ? { acceptedFilesIds: state.acceptedFilesIds.filter(id => id !== fileUUID) } : {}),
            };
        },
        deleteUploadedModelByIds: (state, action: PayloadAction<number[]>) => {
            const fileUUIDs: string[] = [];

            return {
                ...state,
                acceptedFiles: Object.entries(state.acceptedFiles).reduce((acc, [uuid, uploading]) => {
                    if (!uploading.uploaded || !uploading.uploaded.object_models) {
                        return { ...acc, [uuid]: uploading };
                    }

                    const models = uploading.uploaded.object_models.reduce<UploadModelsObjectResponse[]>(
                        (acc, model) => {
                            if (!model.id) {
                                return [...acc, model];
                            } else if (!action.payload.includes(model.id)) {
                                return [...acc, model];
                            }

                            return acc;
                        },
                        [],
                    );

                    if (!models.length) {
                        fileUUIDs.push(uuid);
                        return acc;
                    }

                    return {
                        ...acc,
                        [uuid]: {
                            ...uploading,
                            uploaded: {
                                ...uploading.uploaded,
                                object_models: models,
                            },
                        },
                    };
                }, {}),
                ...(fileUUIDs.length
                    ? { acceptedFilesIds: state.acceptedFilesIds.filter(id => !fileUUIDs.includes(id)) }
                    : {}),
            };
        },

        startModelsStatusPolling: state => {
            state.isPollingActive = true;
        },
        stopModelsStatusPolling: state => {
            state.isPollingActive = false;
        },

        checkStatus: (state, action: PayloadAction<number[]>) => {
            state.isStatusChecking = true;
        },
        checkStatusSuccess: (state, action: PayloadAction<Record<number, ObjectModelStatus>>) => {
            state.isStatusChecking = false;
            state.acceptedFiles = !Object.values(action.payload).some(status => status !== 'in_progress')
                ? state.acceptedFiles
                : Object.entries(state.acceptedFiles).reduce((acc, [uuid, uploading]) => {
                      if (!uploading.uploaded || !uploading.uploaded.object_models) {
                          return { ...acc, [uuid]: uploading };
                      }

                      const models = uploading.uploaded.object_models.map(model => {
                          if (!model.id) {
                              return model;
                          }

                          return {
                              ...model,
                              status: action.payload[model.id],
                          };
                      });

                      return {
                          ...acc,
                          [uuid]: {
                              ...uploading,
                              uploaded: {
                                  ...uploading.uploaded,
                                  object_models: models,
                              },
                          },
                      };
                  }, {});
        },
        checkStatusFailure: state => {
            state.isStatusChecking = false;
        },
    },
    extraReducers: builder => {
        builder.addCase(appActions.invalidateStore, (state, { payload }) =>
            payload.purge >= InvalidateLevel.Models ? { ...initialState } : state,
        );
    },
});

export const uploadModelsReducer = createPersistReducer(PersistReducerConfigs.uploadModels, uploadModelsSlice.reducer);
export const uploadModelsActions = uploadModelsSlice.actions;

export const createModelsStatusPolling = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const isActive = selectIsPollingCheckModelsStatusActive(state);
    const ids = selectAnalyzingModelIds(state);

    if (isActive || !ids.length) return;

    dispatch(uploadModelsActions.startModelsStatusPolling());
};
