import cn from 'classnames';
import React from 'react';
import { FeatureContent } from '@components/feature-content';
import { LineItems, LineItem } from '@components/line-items';
import { composeNodeId } from '@utils';
import { InvoiceTileData } from './hooks';
import { InvoiceTileHead } from '@components/invoice-title-head';

import styles from './invoice-tile.module.scss';

type InvoiceTileProps = {
    className?: string;
    dataGridElement?: boolean;
} & InvoiceTileData;

export const InvoiceTile: React.FC<InvoiceTileProps> = ({
    className,
    children,
    icon,
    title,
    prefix,
    list,
    dataGridElement,
}) => {
    return (
        <div className={cn('rounded-box', 'base-paddings', styles.box, className)} data-grid-element={dataGridElement}>
            <InvoiceTileHead
                id={composeNodeId(prefix, 'title')}
                icon={icon}
                children={<FeatureContent contentKey={composeNodeId(prefix, 'title' as const)} fallback={title} />}
            />

            {list && list.length && (
                <LineItems className={cn('small-margin-top', 'invoice-no-icon-gap', styles.list)}>
                    {list.map(
                        data =>
                            Boolean(data.value) && (
                                <LineItem
                                    key={data.propertyKey}
                                    component="li"
                                    label={
                                        <FeatureContent
                                            as="span"
                                            contentKey={composeNodeId(prefix, data.propertyKey)}
                                            fallback={data.property}
                                        />
                                    }
                                >
                                    {data.value}
                                </LineItem>
                            ),
                    )}
                </LineItems>
            )}

            {children}
        </div>
    );
};
