import type { TypedAddListener, TypedRemoveListener, TypedStartListening, TypedStopListening } from '@reduxjs/toolkit';
import { createListenerMiddleware, addListener, removeListener } from '@reduxjs/toolkit';
import type { RootState, AppDispatch, AppExtraApi } from '@types';
import { appExtraApi } from './helpers';

export const listenerMiddleware = createListenerMiddleware({ extra: appExtraApi });

export const startAppListening = listenerMiddleware.startListening as TypedStartListening<
    RootState,
    AppDispatch,
    AppExtraApi
>;
export const stopAppListening = listenerMiddleware.stopListening as TypedStopListening<RootState, AppDispatch>;

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch, AppExtraApi>;
export const removeAppListener = removeListener as TypedRemoveListener<RootState, AppDispatch>;
