import cn from 'classnames';
import { useEffect } from 'react';
import { withBaseErrorBoundary } from '@components/error-boundaries';
import { setWidgetIframeHeight } from '@utils/parant-site-comunicator';
import { SignupFormBody } from './signup-form-body';
import { SignupFormFooter } from './signup-form-footer';
import { signUpId } from '../helpers';

import styles from '../auth-page.module.scss';

export const SignupPage = withBaseErrorBoundary(() => {
    useEffect(() => {
        setTimeout(() => {
            setWidgetIframeHeight('default');
        }, 500);
    });

    return (
        <div id={signUpId('page')} className={styles.layout}>
            <div className="container">
                <div className={cn('rounded-box', styles.box)}>
                    <SignupFormBody />
                    <SignupFormFooter type="page" />
                </div>
            </div>
        </div>
    );
});
