import React from 'react';
import cn from 'classnames';
import { AppBar, AppBarNav, AppBarTitle } from '@react-md/app-bar';
import { Dialog } from '@react-md/dialog';
import { Icons } from '@components/icons';
import { FeatureContent } from '../feature-content';
import { sidebarId } from '@pages/payment/invoice/helpers'; // todo wtf

import styles from './pdf-viewer.module.scss';

type Disable = () => void;

interface PdfViewerProps {
    id: string;
    title: string;
    closeButtonTitle: string;
    pdfLink: string;
    isOpen: boolean;
    close: Disable;
}

export const PdfViewer: React.FC<PdfViewerProps> = ({ id, title, closeButtonTitle, pdfLink, isOpen, close }) => {
    return (
        <Dialog
            id={id}
            type="full-page"
            visible={isOpen}
            onRequestClose={close}
            aria-labelledby={sidebarId('pdf_dialog')}
            exit={false}
        >
            <AppBar theme="clear">
                <AppBarNav
                    // theme="clear"
                    // themeType="outline"
                    className={cn('rmd-button--icon-circle', styles.back)}
                    onClick={close}
                    aria-label={closeButtonTitle}
                >
                    <Icons.Chevron className="rmd-typography--secondary" />
                </AppBarNav>
                <AppBarTitle id={sidebarId('invoice_pdf_dialog_title')}>
                    <FeatureContent contentKey={sidebarId('invoice_pdf_dialog_title')} fallback={title} />
                </AppBarTitle>
            </AppBar>
            <iframe title={title} src={pdfLink} width="100%" height="100%" />
        </Dialog>
    );
};
