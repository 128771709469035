import uniqBy from 'lodash/uniqBy';
import { createSelector } from 'reselect';
import { RootState } from '@types';
import { getOrderDiscount } from '../order';

export const selectInvoice = (state: RootState) => state.invoice.data;
export const selectInvoiceById = (state: RootState, invoiceId: string) =>
    state.invoice.data?.id === parseInt(invoiceId) ? state.invoice.data : undefined;
export const selectInvoicePayment = (state: RootState) => state.invoice.payment;
export const selectInvoicePaymentStage = (state: RootState) => state.invoice.paymentStage;
export const selectIsInvoiceLoading = (state: RootState) => state.invoice.isLoading;
export const selectIsInvoiceUpdating = (state: RootState) => state.invoice.isUpdating;

export const selectInvoiceAppliedDiscount = createSelector(selectInvoice, data => getOrderDiscount(data?.order));

export const selectInvoiceTbd = createSelector(selectInvoice, data => {
    if (!data || !data.order.tbd_reason) {
        return [];
    }
    const { tbd_reason, tbd_reason_message } = data.order;
    return [{ tbd_reason, tbd_reason_message }];
});

export const selectInvoiceLineItemsTbd = createSelector(selectInvoice, data => {
    if (!data?.order.line_items) {
        return [];
    }
    return data.order.line_items
        .map(({ id, tbd_reason, tbd_reason_message }) => ({ id, tbd_reason, tbd_reason_message }))
        .filter(({ tbd_reason }) => Boolean(tbd_reason));
});

export const selectInvoiceWholeOrderTbd = createSelector(
    selectInvoiceTbd,
    selectInvoiceLineItemsTbd,
    (orderTbd, lineItemsTbd) => {
        return uniqBy([...orderTbd, ...lineItemsTbd], ({ tbd_reason }) => tbd_reason);
    },
);

export const selectInvoiceExactTriggersTbd = (currentPurchaseId: number) =>
    createSelector(selectInvoiceTbd, selectInvoiceLineItemsTbd, (invoiceTbd, lineItemsTbd) => {
        const currentTbd = lineItemsTbd.find(({ id }) => id === currentPurchaseId);

        if (!currentTbd) {
            return invoiceTbd;
        }

        return [...invoiceTbd, currentTbd];
    });

export const selectIsInvoicePaymentLoading = (state: RootState) => state.invoice.isLoadingPayment;
export const selectIsInvoicePaymentModalOpen = (state: RootState) => state.invoice.isPaymentModalOpen;
export const selectIsPollingPaymentStatusActive = (state: RootState) => state.invoice.isPollingActive;
