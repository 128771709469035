import * as yup from 'yup';

export const createPurchaseOrderValidation = (isPoFileRequired: boolean) => {
    const yupBaseStr = yup.string().ensure().trim();

    const attachedPoSchema = isPoFileRequired ? yupBaseStr.required('PO file is required') : yupBaseStr;

    return yup
        .object({
            po_number: yupBaseStr.max(255).required('PO number is required'),
            attached_po: attachedPoSchema,
        })
        .required();
};
