export const RootPrefix = 'header';

export enum HeaderMenuAction {
    Email = 'email',
    Models = 'models',
    Orders = 'orders',
    Logout = 'logout',
    SignIn = 'signIn',
    SignUp = 'signUp',
    ThemeConfig = 'themeConfig',
    ContentEditableMode = 'contentEditableMode',
}

export const AuthMenuActions = [HeaderMenuAction.Email, HeaderMenuAction.Models, HeaderMenuAction.Orders] as const;
export const AnonMenuActions = [HeaderMenuAction.SignIn, HeaderMenuAction.SignUp] as const;
export const AdminMenuActions = [HeaderMenuAction.ThemeConfig, HeaderMenuAction.ContentEditableMode] as const;
