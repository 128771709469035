import { from, timer, of, concat } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeWhile, withLatestFrom } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { AppEpic } from '@types';
import { CompanyService } from '@services';
import { selectWidgetModelId, selectWidgetMaterialId } from '../quotation';
import { selectPollingWallThicknessIsActive, selectShouldWallThicknessPoll } from './selectors';
import { wallThicknessActions } from './slice';

export const loadWallThicknessEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(wallThicknessActions.load.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            return from(CompanyService.init().wallThicknessStatus(action.payload)).pipe(
                withLatestFrom(state$),
                switchMap(([{ data }, state]) => {
                    return of(wallThicknessActions.loadSuccess({ params: action.payload, data }));
                }),
                catchError(() => of(wallThicknessActions.loadFailure())),
            );
        }),
    );

const startWallThicknessPollingEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(wallThicknessActions.startWallThicknessPolling.match),
        switchMap(() =>
            concat(
                timer(0, 4000).pipe(
                    withLatestFrom(state$),
                    map(([_, state]) => ({
                        modelId: selectWidgetModelId(state)!,
                        materialId: selectWidgetMaterialId(state),
                        continuePoll: selectPollingWallThicknessIsActive(state) && selectShouldWallThicknessPoll(state),
                    })),
                    takeWhile(({ continuePoll }) => continuePoll),
                    switchMap(({ modelId, materialId }) => of(wallThicknessActions.load({ modelId, materialId }))),
                ),
                of(wallThicknessActions.stopWallThicknessPolling()),
            ),
        ),
    );

export const wallThicknessEpics = combineEpics(startWallThicknessPollingEpic, loadWallThicknessEpic);
