import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, WallThicknessData, WallThicknessPayload } from '@types';
import { selectPollingWallThicknessIsActive, selectShouldWallThicknessPoll } from './selectors';

interface State {
    data: WallThicknessData;
    isPollingActive: boolean;
    isLoading: boolean;
}

const initialState: State = {
    data: {},
    isPollingActive: false,
    isLoading: false,
};

export const wallThicknessSlice = createSlice({
    name: 'wallThickness',
    initialState,
    reducers: {
        startWallThicknessPolling: state => {
            state.isPollingActive = true;
        },
        stopWallThicknessPolling: state => {
            state.isPollingActive = false;
        },

        load: (state, action: PayloadAction<WallThicknessPayload>) => {
            state.isLoading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ params: WallThicknessPayload; data: WallThicknessData }>) => {
            const modelId = action.payload.params.modelId;

            state.isLoading = false;
            // parent model ID is used as key (as in request), but the data may be intended for a child
            state.data[modelId] = {
                ...state.data[modelId],
                ...action.payload.data[modelId],
            };
        },
        loadFailure: state => {
            state.isLoading = false;
        },
    },
});

export const wallThicknessActions = wallThicknessSlice.actions;

export const createWallThicknessPolling = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const isActive = selectPollingWallThicknessIsActive(state);
    const shouldPoll = selectShouldWallThicknessPoll(state);

    if (isActive || !shouldPoll) return;

    dispatch(wallThicknessActions.startWallThicknessPolling());
};
