import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { FeatureContent } from '@components/feature-content';
import { Icons } from '@components/icons';
import { Price } from '@components/price';
import { Order } from '@types';
import { getNodeIdComposer } from '@utils';
import { InvoiceTileHead } from '@components/invoice-title-head';
import { RootPrefix } from './constants';

import styles from './invoice-price-corrections.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'price_corrections');

interface InvoicePriceCorrectionsProps {
    className?: string;
    data: Order['price_corrections'];
}

export const InvoicePriceCorrections: React.FC<InvoicePriceCorrectionsProps> = ({ className, data }) => {
    const { t } = useTranslation();

    return (
        <div className={cn(styles.box, className)}>
            <InvoiceTileHead
                id={_id('title')}
                icon={Icons.SlidersHorizontal}
                children={
                    <FeatureContent
                        contentKey={_id('title')}
                        fallback={t('subtitles.priceCorrections', 'Additional goods and services')}
                    />
                }
            />

            <ul className={cn('invoice-no-icon-gap', styles.list)}>
                {data.map((data, index) => (
                    <li key={index} className={styles.row}>
                        <Typography type="headline-6" component="div">
                            {data.comment}
                        </Typography>
                        <Typography type="body-2" component={'div'} className={styles.value}>
                            <Price>{data.value}</Price>
                        </Typography>
                    </li>
                ))}
            </ul>
        </div>
    );
};
