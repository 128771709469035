import { InitAppOptions, PasswordlessLogin } from '@types';

type Params = {
    query: Partial<PasswordlessLogin>;
};

export const getInitAppOptions = ({ query: { email, security_hash } }: Params): InitAppOptions => {
    const silentLogin = email && security_hash ? { email, security_hash } : undefined;
    return { silentLogin };
};
