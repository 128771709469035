import { useQueryParams, NumberParam, withDefault, createEnumParam } from 'use-query-params';
import { GetParams } from '@constants';
import { FilterOrderStatus } from '@types';

const OrderStatusParam = createEnumParam(Object.values(FilterOrderStatus));

export const useOrdersQueryParams = () => {
    const [query, setQuery] = useQueryParams({
        [GetParams.PaginationPage]: withDefault(NumberParam, 1),
        [GetParams.OrderStatus]: withDefault(OrderStatusParam, FilterOrderStatus.All),
    });

    const isAbandoned = query.status === 'abandoned';

    return { isAbandoned, query, setQuery };
};
