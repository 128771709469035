import uniqBy from 'lodash/uniqBy';
import { createSelector } from 'reselect';
import { RootState } from '@types';
import { selectIqtModeOn } from '../user';

export const selectOrderPageData = (state: RootState) => state.orderPage.order;
export const selectIsOrderPageLoading = (state: RootState) => state.orderPage.isLoading;

export const selectOutsideIqtOrderPageTbd = createSelector(selectOrderPageData, selectIqtModeOn, (data, iqtModeOn) => {
    if (!data || !data.tbd_reason || iqtModeOn) {
        return [];
    }
    const { tbd_reason, tbd_reason_message } = data;
    return [{ tbd_reason, tbd_reason_message }];
});

export const selectOutsideIqtOrderPageLineItemsTbd = createSelector(
    selectOrderPageData,
    selectIqtModeOn,
    (data, iqtModeOn) => {
        if (!data?.line_items || iqtModeOn) {
            return [];
        }
        return data.line_items
            .map(({ id, tbd_reason, tbd_reason_message }) => ({ id, tbd_reason, tbd_reason_message }))
            .filter(({ tbd_reason }) => Boolean(tbd_reason));
    },
);

export const selectWholeOrderPageTbd = createSelector(
    selectOutsideIqtOrderPageTbd,
    selectOutsideIqtOrderPageLineItemsTbd,
    (orderTbd, lineItemsTbd) => {
        // disable tbd for iqt
        return uniqBy([...orderTbd, ...lineItemsTbd], ({ tbd_reason }) => tbd_reason);
    },
);

export const selectOrderPageExactTriggersTbd = (currentPurchaseId: number) =>
    createSelector(selectOutsideIqtOrderPageTbd, selectOutsideIqtOrderPageLineItemsTbd, (orderTbd, lineItemsTbd) => {
        const currentTbd = lineItemsTbd.find(({ id }) => id === currentPurchaseId);

        if (!currentTbd) {
            return orderTbd;
        }

        return [...orderTbd, currentTbd];
    });
