import cn from 'classnames';
import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useNavigate, Navigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { Tooltip, useTooltip } from '@react-md/tooltip';
import { TextIconSpacing } from '@react-md/icon';
import { Icons } from '@components/icons';
import { BrowseFiles } from '@components/browse-files';
import { DropzoneArea } from '@components/dropzone';
import { withBaseErrorBoundary } from '@components/error-boundaries';
import { FeatureContent, FeatureContentCompound, FeatureMarkup } from '@components/feature-content';
import { AppSettings } from '@types';
import { useModelUploader } from '@pages/widget/upload/hooks';
import { useGetOrderUrl } from '@pages/widget/order/hooks';
import { openAuthPopup, selectIsAuthenticated } from '@modules/auth';
import { selectAppSettings } from '@modules/app';
import { selectUserPermissions } from '@modules/user';
import { selectSelectedModels } from '@modules/models';
import { useCollectOrderCreationPayload } from '@modules/order';
import { selectUploadJob } from '@modules/upload-models';
import { useMounted } from '@hooks';
import { getNodeIdComposer, formatBytes } from '@utils';
import { Terms } from './terms';
import { RootPrefix } from './constants';
import { useGetResizeRef } from '@utils/parant-site-comunicator';

import styles from './upload-page.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'dropzone');

interface SubtitleProps {
    settings: AppSettings;
}

const ModelsSubtitle: React.FC<SubtitleProps> = ({ settings }) => {
    const [extensions, archives] = settings.allowed_extensions_beauty;
    const roundedSize = formatBytes(settings.model_size_limit_in_bytes, { expressIn: 'MB' });

    const { t } = useTranslation();

    if (!extensions || !archives) {
        return null;
    }

    return (
        <FeatureContentCompound
            contentKey={_id('subtitle')}
            i18nKey="subtitles.upload"
            className={cn('small-margin-top', styles.subtitle)}
            parent="div"
            defaults="{{extensions}} and {{archives}} files up to {{roundedSize}} Mb"
            values={{
                extensions,
                archives,
                roundedSize,
            }}
        />
    );
};

const NonCadSubtitle: React.FC<SubtitleProps> = ({ settings }) => {
    const extensions = settings.allowed_non_processable_extensions;
    const hasExtensions = Boolean(extensions.length);
    const roundedSize = formatBytes(settings.non_processable_model_size_limit_in_bytes, { autoFormat: true });

    const { tooltipProps: anyTypeTooltipProps, elementProps: anyTypeElementProps } = useTooltip({
        baseId: _id('any_type_extensions'),
        position: 'above',
        spacing: 10,
        vwMargin: 20,
        disableAutoSpacing: true,
    });

    if (!hasExtensions) return null;

    return (
        <>
            <FeatureContentCompound
                contentKey={_id('any_type_subtitle')}
                i18nKey="subtitles.anyTypeUpload"
                className={cn('small-margin-top', styles.subtitle)}
                parent="div"
                components={{
                    hint: <span {...anyTypeElementProps} className="rmd-typography--semi-bold" />,
                }}
                values={{
                    roundedSize,
                }}
                defaults={`Or project files of <hint>these formats</hint> up to {{roundedSize}} for manual processing`}
            />
            <Tooltip {...anyTypeTooltipProps} lineWrap={false}>
                {extensions.join(', ')}
            </Tooltip>
        </>
    );
};

const Page = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const mounted = useMounted();
    const settings = useAppSelector(selectAppSettings);
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const { isUploadingModelsAllowed } = useAppSelector(selectUserPermissions);
    const uj = useAppSelector(selectUploadJob);
    const modelsIds = useAppSelector(selectSelectedModels);

    const [getOrderUrl, currentOrderId] = useGetOrderUrl();
    const orderUrl = getOrderUrl();

    const onDropComplete = useCallback(() => {
        const goToOrder = () => navigate(orderUrl, { replace: true });

        if (isUploadingModelsAllowed) {
            goToOrder();
        } else {
            dispatch(openAuthPopup({ onClose: goToOrder }));
        }
    }, [dispatch, navigate, isUploadingModelsAllowed, orderUrl]);

    const { getRootProps, getInputProps, isDragActive, open } = useModelUploader({ initUpload: false, onDropComplete });

    const containerRef = useGetResizeRef();

    const { t } = useTranslation();

    useCollectOrderCreationPayload();

    if (uj || currentOrderId || modelsIds.length) {
        return mounted ? null : <Navigate to={orderUrl} replace />;
    }

    return (
        <div id={RootPrefix} className={styles.layout} ref={containerRef}>
            <div className={cn(['container', styles.container])}>
                <div className={cn(['relative', 'rounded-box', styles.dropzone])}>
                    <DropzoneArea {...getRootProps()} active={isDragActive} hint={null} />
                    <input {...getInputProps()} />

                    <FeatureMarkup contentKey={_id('markup_top')} />

                    <div className={styles.preview}>
                        <Typography id={_id('title')} type="headline-3">
                            <FeatureContent
                                contentKey={_id('title')}
                                fallback={t('titles.upload', 'Drag and drop files here')}
                            />
                        </Typography>

                        <ModelsSubtitle settings={settings} />
                        <NonCadSubtitle settings={settings} />
                        <BrowseFiles isAuthenticated={isAuthenticated} onClick={open} className={styles.gap} />

                        {settings.terms_and_conditions && (
                            <Terms content={settings.terms_and_conditions} className={styles.gap} />
                        )}
                    </div>

                    <FeatureMarkup contentKey={_id('markup_bottom')} />

                    <Typography id={_id('hint')} type="subtitle-2" className={styles.hint} component="div">
                        <TextIconSpacing icon={<Icons.Lock />} forceIconWrap>
                            <FeatureContent
                                contentKey={_id('hint')}
                                fallback={t(
                                    'messages.uploadGuaranties',
                                    'Instant quoting tool secures all uploaded files, protecting your intellectual property',
                                )}
                            />
                        </TextIconSpacing>
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export const WidgetUploadPage = withBaseErrorBoundary(Page);
