import { getNodeIdComposer } from '@utils';
import { RootPrefix, PriceConfigPrefix } from './constants';

export const cartSectionId = getNodeIdComposer(RootPrefix, 'cart_section');
export const lineItemId = getNodeIdComposer(RootPrefix, 'line_item');
export const viewerSectionId = getNodeIdComposer(RootPrefix, 'viewer_section');
export const specificationSectionId = getNodeIdComposer(RootPrefix, 'specification_section');
export const calculationSectionId = getNodeIdComposer(RootPrefix, 'calculation_section');
export const priceConfigId = getNodeIdComposer(PriceConfigPrefix);

// This is for avoiding bug with adding details to order in different tabs by tabs sync (reload inactive tabs)
export const handleOrderChange = (params: { event: StorageEvent; company: string }) => {
    const { event, company } = params;
    if (event.key) {
        const keyArr = event.key.split(':');
        if (keyArr.includes('persist') && keyArr.includes('models') && keyArr.includes(company)) {
            window.location.reload();
        }
    }
};
