import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    InvalidateLevel,
    StoreResponseErrors,
    ShippingRate,
    LoadShippingRatesRequest,
    LoadShippingRatesResponse,
    CheckShippingRatesResponse,
} from '@types';
import { appActions } from '../app';

interface State {
    shippingRates: ShippingRate[];
    error?: StoreResponseErrors;
    depsAreValid: boolean;
    isReady: boolean;
    isPollingActive: boolean;
    isLoading: boolean;
    isChecking: boolean;
}

const initialState: State = {
    shippingRates: [],
    depsAreValid: false,
    isReady: false,
    isPollingActive: false,
    isLoading: false,
    isChecking: false,
};

export const deliveryRatesSlice = createSlice({
    name: 'deliveryRates',
    initialState,
    reducers: {
        setRatesDepsValidity: (state, { payload: isDepsValid }: PayloadAction<boolean>) => {
            state.depsAreValid = isDepsValid;

            if (!isDepsValid) {
                state.shippingRates = [];
            }
        },
        startPolling: (state, action: PayloadAction<string>) => {
            state.isPollingActive = true;
            state.isReady = false;
            state.error = undefined;
        },
        stopPolling: state => {
            state.isPollingActive = false;
        },

        load: (state, action: PayloadAction<LoadShippingRatesRequest>) => {
            state.isLoading = true;
        },
        loadSuccess: (state, action: PayloadAction<LoadShippingRatesResponse>) => {
            state.isLoading = false;
            state.shippingRates = sortBy(uniq(action.payload.rates), rate => !rate.custom);
            state.isReady = !action.payload.errors && !action.payload.task_id;
            state.error = action.payload.errors;
        },
        loadFailure: (state, action: PayloadAction<LoadShippingRatesResponse>) => {
            state.isLoading = false;
            state.error = 'Failed';
        },

        check: (state, action: PayloadAction<string>) => {
            state.isChecking = true;
        },
        checkSuccess: (state, action: PayloadAction<CheckShippingRatesResponse>) => {
            const isReady = Boolean(action.payload.is_ready);
            const isFailed = action.payload.status === 'failed';

            state.isChecking = false;
            state.isReady = isReady;
            state.error = isFailed ? 'Failed' : undefined;

            if (isReady) {
                state.shippingRates = state.shippingRates
                    .filter(rate => rate.custom)
                    .concat(action.payload.rates || []);
            }
        },
        checkFailure: (state, action: PayloadAction<CheckShippingRatesResponse>) => {
            state.isChecking = false;
            state.error = 'Failed';
        },
    },
    extraReducers: builder => {
        builder.addCase(appActions.invalidateStore, (state, { payload }) =>
            payload.purge >= InvalidateLevel.Order ? { ...initialState } : state,
        );
    },
});

export const deliveryRatesActions = deliveryRatesSlice.actions;
