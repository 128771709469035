import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createPersistReducer, PersistReducerConfigs } from '@app/persist';
import {
    InitialOrder,
    InitialOrderUpdateRequest,
    OrderCreateResponse,
    OrderForm,
    FormOnSubmit,
    StoreResponseErrors,
    BasicJSONSchema,
    FormAttributes,
    InvalidateLevel,
} from '@types';
import { appActions } from '../app';

export interface OrderReducerState {
    orderId?: number;
    data?: InitialOrder;
    form?: OrderForm;
    payloadPassthrough?: unknown;
    isLoading: boolean;
    isCreating: boolean;
    isUpdating: boolean;
    isSubmitting: boolean;
    promocodeError: number | null | undefined;

    // todo remove
    additionalContactsList: string[];
    additionalContactsFormAttributes: FormAttributes;

    // Questionnaire
    questionnaireSchema: BasicJSONSchema | null;
    isQuestionnaireLoading: boolean;
}

const initialState: OrderReducerState = {
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    isSubmitting: false,
    promocodeError: null,
    additionalContactsList: [],
    additionalContactsFormAttributes: {
        errors: null,
        watch: null,
    },

    // Questionnaire
    questionnaireSchema: null,
    isQuestionnaireLoading: false,
};

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        defineOrder: (
            state,
            action: PayloadAction<{
                company: string;
                orderId?: string;
                setupUrl: 'widgetOrder' | 'widgetOrderSubmit';
            }>,
        ) => {},

        setAdditionalContactsList: (state, action: PayloadAction<string[]>) => {
            state.additionalContactsList = action.payload;
        },
        setAdditionalContactsFormAttributes: (state, action: PayloadAction<FormAttributes>) => {
            state.additionalContactsFormAttributes = action.payload;
        },

        setOrder: (state, action: PayloadAction<InitialOrder>) => {
            state.data = action.payload;
        },

        load: (
            state,
            action: PayloadAction<{
                orderId?: number;
                currentOrderId?: number;
                replace?: boolean;
                setupUrl?: 'widgetOrder' | 'widgetOrderSubmit';
            }>,
        ) => {
            state.isLoading = true;
        },
        loadSuccess: (state, action: PayloadAction<InitialOrder>) => {
            state.isLoading = false;
            state.data = action.payload;
            state.orderId = action.payload.id; // rewrite for abandoned order
        },
        loadFailure: state => {
            state.isLoading = false;
        },

        collectCreationPayload: (state, action: PayloadAction<unknown>) => {
            state.payloadPassthrough = action.payload;
        },

        create: state => {
            state.isCreating = true;
        },
        createSuccess: (state, action: PayloadAction<InitialOrder>) => {
            state.isCreating = false;
            state.data = action.payload;
            state.orderId = action.payload.id;
            state.payloadPassthrough = undefined;
        },
        createFailure: state => {
            state.isCreating = false;
        },

        update: (
            state,
            action: PayloadAction<{
                orderId: number;
                data: InitialOrderUpdateRequest;
            }>,
        ) => {
            state.isUpdating = true;
        },
        updateSuccess: (state, action: PayloadAction<InitialOrder>) => {
            state.isUpdating = false;
            state.data = action.payload;
        },
        updateFailure: state => {
            state.isUpdating = false;
        },

        submit: (
            state,
            action: PayloadAction<
                FormOnSubmit<OrderForm> & { isBillingAddressChanged: boolean; isDeliveryAddressChanged: boolean }
            >,
        ) => {
            state.isSubmitting = true;
        },
        submitSuccess: (state, action: PayloadAction<OrderCreateResponse>) => {
            state.isSubmitting = false;
        },
        submitFailure: (state, action: PayloadAction<StoreResponseErrors>) => {
            state.isSubmitting = false;
        },

        applyPromocode: (state, action: PayloadAction<{ orderId: number; data: { code: string } }>) => {
            state.isUpdating = true;
        },
        applyPromocodeSuccess: (state, action: PayloadAction<InitialOrder>) => {
            state.isUpdating = false;
            state.data = action.payload;
            state.promocodeError = null;
        },
        applyPromocodeFailure: (state, action: PayloadAction<number | undefined>) => {
            state.isUpdating = false;
            state.promocodeError = action.payload;
        },

        loadQuestionnaire: state => {
            state.isQuestionnaireLoading = true;
        },
        loadQuestionnaireSuccess: (state, action: PayloadAction<BasicJSONSchema>) => {
            state.isQuestionnaireLoading = false;
            state.questionnaireSchema = action.payload;
        },
        loadQuestionnaireFailure: state => {
            state.isQuestionnaireLoading = false;
        },

        saveForm: (state, action: PayloadAction<OrderForm>) => {
            state.form = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(appActions.invalidateStore, (state, { payload }) =>
            payload.purge >= InvalidateLevel.Order ? { ...initialState } : state,
        );
    },
});

export const orderReducer = createPersistReducer(PersistReducerConfigs.order, orderSlice.reducer);
export const orderActions = orderSlice.actions;
