import isBoolean from 'lodash/isBoolean';
import { useEffect } from 'react';
import { useAppSelector } from '@app/hooks';
import { Navigate, Outlet, useParams, To } from 'react-router-dom';
import { Fallback } from '@components/fallback';
import { AppMode, FCC } from '@types';
import { purgeStorageAssociatedWithMode } from '@app/persist';
import { selectClientId } from '@modules/client';
import { selectUserPermissions } from '@modules/user';
import { reverse } from '@utils';

export const IqtRoute: FCC = ({ children }) => {
    const { iqtAllowed } = useAppSelector(selectUserPermissions);

    useEffect(() => {
        if (iqtAllowed) return;

        // clear iqt local storage, reopen page with default mode
        purgeStorageAssociatedWithMode(AppMode.Iqt).then(() => {
            window.location.assign(reverse('widgetUpload', undefined, AppMode.EndUser));
        });
    }, [iqtAllowed]);

    return iqtAllowed ? children ? <>{children}</> : <Outlet /> : <Fallback />;
};

interface IqtClientInspectorProps {
    redirectIfAbsentClient?: boolean | ((params: Record<string, string | undefined>) => boolean);
    redirectTo?: To;
}

export const IqtClientInspector: FCC<IqtClientInspectorProps> = ({
    children,
    redirectTo = reverse('widgetClient', undefined, AppMode.Iqt),
    redirectIfAbsentClient = true,
}) => {
    const clientId = useAppSelector(selectClientId);
    const params = useParams();

    const shouldRedirect = isBoolean(redirectIfAbsentClient) ? redirectIfAbsentClient : redirectIfAbsentClient(params);

    if (!clientId && shouldRedirect) {
        return <Navigate to={redirectTo} replace />;
    }

    return children ? <>{children}</> : <Outlet />;
};
