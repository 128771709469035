import cn from 'classnames';
import React from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Button } from '@react-md/button';
import { TextIconSpacing } from '@react-md/icon';
import { Alert, AlertHeading } from '@components/alert';
import { Icons } from '@components/icons';

export const UploaderErrorBoundary: React.FC = ({ children }) => {
    return (
        <ErrorBoundary
            fallback={({ resetError }) => (
                <Alert show type="error" variant="outlined" className="items-center">
                    {/*<AlertHeading iconType="error">*/}
                    {/*    <span>*/}
                    {/*        <span>"Something busted that we didn't anticipate."</span>*/}
                    {/*        <Button*/}
                    {/*            buttonType="icon"*/}
                    {/*            theme="primary"*/}
                    {/*            className="rmd-button--icon-flat"*/}
                    {/*            onClick={resetErrorBoundary}*/}
                    {/*            aria-label="Refresh"*/}
                    {/*            disableRipple*/}
                    {/*        >*/}
                    {/*            <Icons.Rotate width={18} height={18} />*/}
                    {/*        </Button>*/}
                    {/*    </span>*/}
                    {/*</AlertHeading>*/}

                    <Button
                        theme="primary"
                        className={cn('rmd-button--flat', 'fade-in')}
                        onClick={resetError}
                        disableRipple
                    >
                        <TextIconSpacing icon={<Icons.Rotate width={16} height={16} />} forceIconWrap>
                            Something busted that we didn't anticipate.
                        </TextIconSpacing>
                    </Button>

                    {/*<Button*/}
                    {/*    theme="primary"*/}
                    {/*    themeType="contained"*/}
                    {/*    // className={cn('rmd-button--flat', 'fade-in')}*/}
                    {/*    onClick={resetErrorBoundary}*/}
                    {/*    disableRipple*/}
                    {/*>*/}
                    {/*    Refresh*/}
                    {/*</Button>*/}
                </Alert>
            )}
        >
            {children}
        </ErrorBoundary>
    );
};
