import { RootState } from '@types';

export const selectAuthToken = (state: RootState) => state.auth.token;
export const selectIsTokenObtaining = (state: RootState) => state.auth.isTokenObtaining;
export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectAuthErrors = (state: RootState) => state.auth.errors;
export const selectIsOtpSending = (state: RootState) => state.auth.isOtpSending;
export const selectIsPasswordRecoverySending = (state: RootState) => state.auth.isPasswordRecoverySending;
export const selectIsPasswordRecoverySuccess = (state: RootState) => state.auth.isPasswordRecoverySuccess;
export const selectIsChangePasswordSending = (state: RootState) => state.auth.isChangePasswordSending;
