import { CustomFieldData, FieldGroups, JSONSubSchema, OrderForm } from '@types';

export function mergeFormIntoData(form: OrderForm, schemaFields: JSONSubSchema[] | undefined) {
    try {
        const { custom_fields } = form;

        if (!custom_fields || !schemaFields) {
            return undefined;
        }

        const result = [] as CustomFieldData[];

        Object.keys(custom_fields).forEach(key => {
            const field = schemaFields.find(({ objectName }) => objectName === key);
            result.push({
                key,
                value: custom_fields[key],
                label: field?.JSONSchema?.title,
            });
        });

        return result;
    } catch (error) {
        console.warn(error);
    }
}

export function extractFields(fieldGroups: FieldGroups[] | undefined) {
    if (!fieldGroups) {
        return undefined;
    }

    try {
        return fieldGroups.reduce((acc: JSONSubSchema[], { fields }) => acc.concat(...fields), []);
    } catch (error) {
        console.warn(error);
    }
}
