import type { AxiosRequestConfig } from 'axios';

type Params = AxiosRequestConfig['params'];
type ExtendQueryParams = (endpoint: string, params: Params) => Params;

export const extendQueryParams: ExtendQueryParams = (endpoint, params = {}) => {
    switch (endpoint) {
        case 'ordersRetrieve':
        case 'usersOrdersRetrieve': {
            return { ...params, expand: '~all' };
        }
        case 'ordersList':
        case 'usersOrdersList': {
            return {
                ...params,
                expand: ['applied_discount', 'client'],
            };
        }
        default: {
            return params;
        }
    }
};
