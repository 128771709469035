import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { FormProvider } from 'react-hook-form';
import { ClientExtendedRepresentationDetailed } from '@services/df/clients';
import { AppSettings, FieldGroups, InitialOrder } from '@types';
import { selectOrderForm, saveOrderForm } from '@modules/order';
import { useOrderForm } from './hooks';

interface OrderFormProviderProps {
    settings: AppSettings;
    order: InitialOrder;
    user?: ClientExtendedRepresentationDetailed;
    fieldGroups?: FieldGroups[];
}

export const OrderFormProvider: React.FC<OrderFormProviderProps> = ({
    settings,
    order,
    user,
    fieldGroups,
    children,
}) => {
    const dispatch = useAppDispatch();
    const form = useAppSelector(selectOrderForm);
    const methods = useOrderForm({ form, settings, order, user, fieldGroups });

    useEffect(
        () => () => {
            dispatch(saveOrderForm(methods.getValues()));
        },
        [dispatch, methods],
    );

    return <FormProvider {...methods}>{children}</FormProvider>;
};
