import { useCallback, useState } from 'react';

type ChangeHandler = (nextValue: string) => void;

export default function useSelect(defaultValue: string): [string, ChangeHandler] {
    const [value, setValue] = useState(defaultValue);
    const handleChange = useCallback((nextValue: string) => {
        setValue(nextValue);
    }, []);

    return [value, handleChange];
}
