import { useEffect } from 'react';
import { TaskAbortError } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { addAppListener } from '@app/listenerMiddleware';
import { PollingConfig } from '@constants';
import { selectIqtModeOn } from '@modules/user';
import { suitableMaterialsActions, startSuitableMaterialsPolling } from './slice';
import { selectModelsIdsForSuitableMaterials } from './selectors';

export const useSuitableMaterialsPolling = () => {
    const dispatch = useAppDispatch();
    const isIqtModeOn = useAppSelector(selectIqtModeOn);

    useEffect(() => {
        const unsubscribe = dispatch(
            addAppListener({
                actionCreator: suitableMaterialsActions.pollingStarted,
                effect: async (
                    action,
                    { condition, dispatch, extra: { ModelsService }, getState, fork, unsubscribe, subscribe },
                ) => {
                    // Only allow one instance of this listener to run at a time
                    unsubscribe();

                    const pollingTask = fork(async ({ delay, signal }) => {
                        try {
                            await delay(PollingConfig.suitableMaterials.delay);

                            while (true) {
                                const state = getState();
                                const modelsIds = selectModelsIdsForSuitableMaterials(state);

                                try {
                                    const { data } = await ModelsService.init().loadSuitableMaterials({
                                        modelsIds,
                                        isIqtModeOn,
                                        config: {
                                            signal,
                                        },
                                    });

                                    dispatch(suitableMaterialsActions.loadSuccess(data));

                                    const readySuitableMaterials = Object.entries(data).filter(
                                        ([_, suitableMaterials]) => suitableMaterials.is_ready,
                                    );

                                    if (readySuitableMaterials.length) {
                                        dispatch(suitableMaterialsActions.readyReceived(readySuitableMaterials));
                                    }
                                } catch (error) {
                                    dispatch(suitableMaterialsActions.loadFailure());
                                }

                                const latestState = getState();
                                if (!selectModelsIdsForSuitableMaterials(latestState).length) {
                                    pollingTask.cancel();
                                }

                                await delay(PollingConfig.suitableMaterials.interval);
                            }
                        } catch (err) {
                            if (err instanceof TaskAbortError) {
                                // could do something here to track that the task was cancelled
                            }
                            dispatch(suitableMaterialsActions.pollingStopped());
                        }
                    });

                    // Wait for the "stop polling" action
                    await condition(
                        suitableMaterialsActions.pollingStopped.match,
                        PollingConfig.suitableMaterials.length,
                    );
                    pollingTask.cancel();
                    subscribe();
                },
            }),
        );

        return () => {
            unsubscribe({ cancelActive: true });
        };
    }, [dispatch, isIqtModeOn]);

    // run on mount
    useEffect(() => {
        dispatch(startSuitableMaterialsPolling());
    }, [dispatch]);
};
