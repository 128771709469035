import React, { useCallback } from 'react';
import { useQueryParam, NumberParam, withDefault } from 'use-query-params';
import { GetParams, PaginationConfig } from '@constants';
import { BasePagination } from './base-pagination';
import { getTotalNumPages } from './helpers';
import { PaginationProps, SelectedPage } from './types';

import styles from './pagination.module.scss';

export const Pagination: React.FC<PaginationProps> = ({
    totalCount,
    pageSize = PaginationConfig.pageSize,
    wrap = false,
}) => {
    const [page, setPage] = useQueryParam(GetParams.PaginationPage, withDefault(NumberParam, 1));

    const handleChangePage = useCallback(
        (event: SelectedPage) => {
            setPage(event.selected + 1);
        },
        [setPage],
    );

    if (totalCount <= pageSize) return null;

    const pagination = (
        <BasePagination
            page={page}
            pageCount={getTotalNumPages(totalCount, pageSize)}
            onPageChange={handleChangePage}
        />
    );

    return wrap ? <div className={styles.wrapped}>{pagination}</div> : pagination;
};
