import { AppMode } from '@types';
import {
    getCompanyNameByPath,
    matchesIqtRoutes,
    matchesShortIqtRoutes,
    findNonPersistedRoutes,
} from './routes/matchers';

function getAppMode(pathname: string) {
    if (matchesIqtRoutes(pathname)) {
        return AppMode.Iqt;
    }

    if (matchesShortIqtRoutes(pathname)) {
        return AppMode.ShortIqt;
    }

    return AppMode.EndUser;
}

export const AppSession = Object.freeze(
    (() => {
        const initPathname = window.location.pathname;
        const mode = getAppMode(initPathname);
        const endUserModeOn = mode === AppMode.EndUser;
        const iqtModeOn = mode === AppMode.Iqt;
        const shortIqtModeOn = mode === AppMode.ShortIqt;

        return {
            allowPersist: !Boolean(findNonPersistedRoutes(initPathname)),
            get companyName() {
                return getCompanyNameByPath();
            },
            iFrame: !(window === window.parent || window.opener),
            intentionToOpenIqt: iqtModeOn || shortIqtModeOn,
            iqtModeOn,
            shortIqtModeOn,
            endUserModeOn,
            initPathname,
            mode,
        };
    })(),
);

global.AppSession = AppSession;
