import { Typography, UnstyledButton } from 'react-md';
import { StartPageTileData } from './const';

import styles from './start-page-tile.module.scss';

export function StartPageTile(tile: StartPageTileData) {
    const { title, imgSrc, description, link } = tile;

    const handleClick = () => (window.location.href = link);

    return (
        <UnstyledButton className={styles['tile']} onClick={handleClick}>
            <div className={styles.preview}>
                <img src={imgSrc} alt={title} />
            </div>

            <Typography type="headline-3" className={styles.title}>
                {title}
            </Typography>

            {description}
        </UnstyledButton>
    );
}
