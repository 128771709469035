import { Remote, expose, wrap } from 'comlink';
import { store } from '@app/store';
import { selectIsOrderChanged } from '@modules/product';
import { AppSession } from '@utils';
import { transferModels } from './upload-models';

interface RemoteApi {
    saveOrder: () => void;
}

class ExposedApi {
    public enabled = AppSession.iFrame;
    private listening = false;

    private port1?: MessagePort;
    private port2?: MessagePort;

    // APIs we expose
    public api = {
        // get state() {
        state: () => {
            const _state = store.getState();
            return {
                isOrderChanged: selectIsOrderChanged(_state),
            };
        },
        transferModels,
    };
    // API of opposite side from iframe parent
    public remoteApi?: Remote<RemoteApi>;

    constructor() {
        this.createChannel();

        this.saveOrder = this.saveOrder.bind(this);
    }

    private createChannel() {
        if (!this.enabled) return;

        const { port1, port2 } = new MessageChannel();
        this.port1 = port1;
        this.port2 = port2;
    }

    private notifyParent() {
        window.parent.postMessage(
            {
                status: 'ready',
                message: 'API is ready to listen for calls.',
            },
            '*',
            [this.port2!],
        );
    }

    listen() {
        if (this.listening || !this.enabled) return;

        this.listening = true;
        this.notifyParent();

        // setup local api
        try {
            expose(this.api, this.port1);
        } catch (_) {}
        // TODO catch errors 1) when calling undefined methods 2) throwIfProxyReleased

        // setup remote api
        this.remoteApi = wrap<RemoteApi>(this.port1!);
    }

    public get ready() {
        return Boolean(this.enabled && this.listening);
    }

    async saveOrder() {
        if (!this.ready) return;
        return await this.remoteApi!.saveOrder();
    }

    // type ExposedApiCall = string;
    // private processExposedCall() {}
}

export const exposedApi = new ExposedApi();
