import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { FeatureContent } from '@components/feature-content';
import { Label } from '@components/label';
import { Order } from '@types';
import { OrderPagePart } from './order-page-part';
import { partsId } from '../../helpers';

import styles from './order-page-parts.module.scss';

interface OrderPagePartsProps {
    order: Partial<Order>;
}

export const OrderPageParts: React.FC<OrderPagePartsProps> = ({ order }) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography id={partsId('title')} className="base-paddings" type="headline-4">
                <FeatureContent contentKey={partsId('title')} fallback={t('titles.parts', 'Parts')} />
            </Typography>

            <div className={cn('overflow-x-auto', styles.table)}>
                <table>
                    <thead className={styles.head}>
                        <tr>
                            <td className={styles.cell}>
                                <Label className={styles.label}>{t('labels.productModel', 'Model')}</Label>
                            </td>
                            <td className={styles.cell}>
                                <Label className={styles.label}>{t('labels.material', 'Material')}</Label>
                            </td>
                            <td className={styles.cell}>
                                <Label className={styles.label}>
                                    {t('labels.productSpecification', 'Specification')}
                                </Label>
                            </td>
                            <td className={styles.cell}>
                                <Label className={cn(styles.label, 'rmd-typography--center')}>
                                    {t('labels.productDiscount', 'Batch discount')}
                                </Label>
                            </td>
                            <td className={styles.cell}>
                                <Label className={cn(styles.label, 'rmd-typography--center')}>
                                    {t('labels.quantity', 'Quantity')}
                                </Label>
                            </td>

                            <td className={styles.cell}>
                                <Label className={cn(styles.label, 'rmd-typography--right')}>
                                    {t('labels.productPrice', 'Price')}
                                </Label>
                            </td>
                        </tr>
                    </thead>

                    <tbody className={styles.body}>
                        {order?.line_items?.map(purchase => {
                            return <OrderPagePart key={purchase.id} purchase={purchase} />;
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};
