import { PropsWithChildren } from 'react';
import { AnalyticsProvider } from 'use-analytics';
import { getInitializedAnalytics } from './helpers';

// I put away instance from useMemo because of react strict mode
// https://react.dev/reference/react/StrictMode
// for this reason dataLayer was set to undefined

const instance = getInitializedAnalytics();
window.Analytics = instance;

export const AppAnalyticsProvider = ({ children }: PropsWithChildren<{}>) => {
    return <AnalyticsProvider instance={instance}>{children}</AnalyticsProvider>;
};
