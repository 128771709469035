import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { TextIconSpacing } from '@react-md/icon';
import { Button } from '@react-md/button';
import { Icons } from '@components/icons';
import { ButtonLink } from '@components/link';
import { FeatureContent } from '@components/feature-content';
import { getNodeIdComposer, reverse } from '@utils';
import { useBuildLocation } from '@hooks';

import styles from './browse-files.module.scss';

const _id = getNodeIdComposer('browse_files');

interface BrowseFilesProps {
    isAuthenticated: boolean;
    onClick: () => void;
    className?: string;
}

export const BrowseFiles: React.FC<BrowseFilesProps> = ({ isAuthenticated, onClick, className }) => {
    const modelsLocation = useBuildLocation({
        to: reverse('userModels'),
        setFromLocation: true, // todo for back button? need to check
    });

    const { t } = useTranslation();

    return (
        <div className={cn(styles.box, className)}>
            <div className={cn('emulated-flex-gap', 'justify-center', 'items-center', styles.actions)}>
                <FeatureContent
                    contentKey={_id('upload_files', 'button')}
                    fallback={t('buttons.browseFiles', 'Browse files')}
                    wrapper={props => <Button theme="primary" themeType="outline" onClick={onClick} {...props} />}
                />

                {isAuthenticated && (
                    <ButtonLink
                        id={_id('recent_uploads', 'link')}
                        className={cn('rmd-button--flat', 'rmd-button--underlined')}
                        {...modelsLocation}
                    >
                        <TextIconSpacing icon={<Icons.Inbox />} forceIconWrap>
                            <FeatureContent
                                contentKey={_id('recent_uploads', 'link')}
                                fallback={t('links.recentUploads', 'Recent uploads')}
                            />
                        </TextIconSpacing>
                    </ButtonLink>
                )}
            </div>
        </div>
    );
};
