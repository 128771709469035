import * as Three from 'three';
import { DxfViewer, DxfViewerLoadParams, DxfViewerOptions } from 'dxf-viewer';
import mainFont from '@assets/fonts/dxf/Roboto-LightItalic.ttf';
import aux1Font from '@assets/fonts/dxf/NotoSansDisplay-SemiCondensedLightItalic.ttf';
import aux2Font from '@assets/fonts/dxf/HanaMinA.ttf';
import aux3Font from '@assets/fonts/dxf/NanumGothic-Regular.ttf';

export const workerFactory = () => new Worker(new URL('./dxf-viewer-worker.ts', import.meta.url));

export const mergeDxfViewerLoadParams = (params: Pick<DxfViewerLoadParams, 'url' | 'progressCbk'>) => ({
    workerFactory,
    fonts: [mainFont, aux1Font, aux2Font, aux3Font],
    ...params,
});

export const initDxfViewer = (visualizer: HTMLDivElement, options?: Partial<DxfViewerOptions>) => {
    // @ts-ignore, dxf-viewer has bullshit typing
    return new DxfViewer(visualizer, {
        clearColor: new Three.Color('#fff'),
        autoResize: true,
        colorCorrection: true,
        ...options,
    });
};
