import { createSelector } from 'reselect';
import { RootState, Order } from '@types';
import { selectIqtModeOn } from '../user';
import { getOrderTbd } from '../helpers';

const orders: Order[] = [];

export const selectOrdersData = (state: RootState) => state.orders.data;
export const selectOrdersCount = (state: RootState) => state.orders.data?.count ?? 0;
export const selectOrdersResults = (state: RootState) => state.orders?.data?.results || orders;
export const selectOrdersError = (state: RootState) => state.orders.error;
export const selectIsOrdersLoading = (state: RootState) => state.orders.isLoading;

// todo move to OrderModel properties
export const makeSelectOrdersPageTbdById = () =>
    createSelector(
        (state: RootState, orderId: number) => orderId,
        selectOrdersResults,
        selectIqtModeOn,
        (orderId, orders, iqtModeOn) => {
            const data = orders.find(({ id }) => id === orderId);
            return getOrderTbd(data, iqtModeOn);
        },
    );
