import React from 'react';
import cn from 'classnames';
import { Typography } from '@react-md/typography';

import styles from './model-status.module.scss';

export type ModelStatusTypes = 'error' | 'warning' | 'success';

export interface ModelStatusProps {
    status: ModelStatusTypes;
    className?: string;
}

export const ModelStatus: React.FC<ModelStatusProps> = ({ status, className, children }) => {
    return (
        <Typography
            type="body-2"
            component="div"
            weight="semi-bold"
            className={cn([styles.box, `rmd-typography--theme-${status}`, className])}
        >
            {children}
        </Typography>
    );
};
