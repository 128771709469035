import React, { memo } from 'react';
import { ObjectModel } from '@types';
import { ViewerThumb } from '../viewer-thumb';

import styles from '../viewer-window.module.scss';

interface NonCadViewerProps {
    model: ObjectModel;
}

export const NonCadViewer = memo<NonCadViewerProps>(
    ({ model }) => {
        return (
            <div className={styles.box}>
                <div className={styles.inner}>
                    <ViewerThumb model={model} show expanded={false} />
                </div>
            </div>
        );
    },
    (prev, next) => {
        return prev.model.id === next.model.id;
    },
);
