import cn from 'classnames';
import React from 'react';
import { Button, ButtonProps } from '@react-md/button';
import { CircularProgress } from '@react-md/progress';
import { TextIconSpacing } from '@react-md/icon';
import { composeNodeId } from '@utils';

type SubmitButtonProps = ButtonProps & {
    isSending: boolean;
    large?: boolean;
} & (
        | {
              id: string;
              prefix?: never;
          }
        | {
              id?: never;
              prefix: string;
          }
    );

export const SubmitButton: React.FC<SubmitButtonProps> = ({
    id,
    isSending,
    prefix,
    children,
    large = true,
    ...props
}) => {
    return (
        <Button
            id={id || composeNodeId(prefix, 'submit')}
            type="submit"
            disabled={isSending}
            className={cn({ 'rmd-button--text-large': large })}
            {...props}
        >
            {isSending ? (
                <TextIconSpacing
                    iconAfter
                    className="rmd-toggle--progress-submit"
                    icon={<CircularProgress id={composeNodeId(prefix, 'progress')} centered={false} small />}
                >
                    {children}
                </TextIconSpacing>
            ) : (
                children
            )}
        </Button>
    );
};

SubmitButton.defaultProps = {
    theme: 'primary',
    themeType: 'contained',
};
