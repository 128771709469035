import cn from 'classnames';
import React from 'react';
import { Typography } from '@react-md/typography';
import { Icons } from '@components/icons';

import styles from './icons-page.module.scss';

export const CustomizeIconsPage = () => {
    return (
        <div className="container">
            <div className={cn('fieldset-grid', 'fieldset-grid--columns', 'base-paddings', styles.layout)}>
                {Object.entries(Icons)
                    .sort()
                    .map(([key, Component]) => {
                        return (
                            <div key={key} className={cn('fieldset-grid-cell', 'rmd-typography--center')}>
                                <Component width={24} height={24} />
                                <Typography type="headline-5" className="small-margin-top">
                                    {key}
                                </Typography>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};
