import { DropzoneOptions } from 'react-dropzone';
import { uploadModelsActions } from '@modules/upload-models';
import { formatDropzoneAcceptedFiles, formatDropzoneRejections, getSizesByAcceptedDropzoneValidator } from '@utils';
import { AcceptedModelExtensions } from '@constants';
import { GetModelUploaderOptionsArgs } from './types';

export function getModelUploaderOptions({
    dispatch,
    settings,
    initUpload,
    onDropComplete,
}: GetModelUploaderOptionsArgs): DropzoneOptions {
    const {
        allowed_extensions,
        model_size_limit_in_bytes: maxSize,
        non_processable_model_size_limit_in_bytes: maxSizeNonCad,
        // upload_models_limit: maxFiles,
        allowed_non_processable_extensions: nonProcessableExtensions,
    } = settings;
    const accept = (allowed_extensions.length && allowed_extensions) || AcceptedModelExtensions;

    return {
        // this is for IOS for unknown binary files b/c IOS cannot detect what is stl,obj,wrl,step,iges,3mf,dxf types.
        // you can find here https://github.com/react-dropzone/react-dropzone/issues/1039 about it
        accept: [...accept, ...nonProcessableExtensions, 'application/octet-stream'],
        // maxFiles was excluded to let users add so many files as they want
        // But extra files will be rejected with error, generated by backend
        // maxFiles, // one request maxFiles, if exceeded, then skipping all dragged files
        noClick: true,

        ...(nonProcessableExtensions.length
            ? {
                  validator: getSizesByAcceptedDropzoneValidator([
                      { accept, maxSize },
                      { accept: nonProcessableExtensions, maxSize: maxSizeNonCad },
                  ]),
              }
            : { maxSize }),

        onDrop: (acceptedFiles, fileRejections, event) => {
            if (acceptedFiles.length > 0) {
                const files = formatDropzoneAcceptedFiles(acceptedFiles);

                if (initUpload) {
                    dispatch(uploadModelsActions.initUploading(files));
                } else {
                    dispatch(uploadModelsActions.addAcceptedFiles(files));
                }
            }

            if (fileRejections.length > 0) {
                dispatch(uploadModelsActions.addRejectedFiles(formatDropzoneRejections(fileRejections)));
            }

            onDropComplete && onDropComplete(acceptedFiles, fileRejections, event);
        },
    };
}
