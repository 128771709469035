import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';

import styles from './model-actions.module.scss';

interface ModelActionsProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
}

export const ModelActions = forwardRef<HTMLDivElement, ModelActionsProps>(function ModelActions(
    { children, className, ...props },
    ref,
) {
    return (
        <div {...props} className={cn(styles.box, className)} ref={ref}>
            {children}
        </div>
    );
});
