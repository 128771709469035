import { ObjectModel, TechnologyMaterial } from '@types';
import { ModelViewerProps } from './model-viewer';

function modelsAreEqual(prevModel: ObjectModel, nextModel: ObjectModel) {
    return prevModel.id === nextModel.id;
}

function materialsAreEqual(prevMaterial?: TechnologyMaterial, nextMaterial?: TechnologyMaterial) {
    return prevMaterial?.id === nextMaterial?.id;
}

export function viewerPropsAreEqual(prevProps: ModelViewerProps, nextProps: ModelViewerProps) {
    return (
        prevProps.model.file_model_viewer_url === nextProps.model.file_model_viewer_url &&
        prevProps.correctObjectModelId === nextProps.correctObjectModelId &&
        // modelsAreEqual(prevProps.model, nextProps.model) &&
        materialsAreEqual(prevProps.material, nextProps.material) &&
        prevProps.model.allowToRenderViewer === nextProps.model.allowToRenderViewer
        // nextProps.model.isModelRendered
    );
}
