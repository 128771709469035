import cn from 'classnames';
import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { DropzoneState } from 'react-dropzone';
import { buttonThemeClassNames, ButtonThemeProps } from '@react-md/button';
import { TextIconSpacing, useIcon } from '@react-md/icon';
import { useInteractionStates } from '@react-md/states';

import { bem } from '@react-md/utils';

type InputAttributes = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'defaultValue' | 'value'>;

export interface FileInputProps extends ButtonThemeProps, InputAttributes {
    /**
     * An id for the input. This is required for a11y since it also is applied as
     * the `htmlFor` prop for the label.
     */
    id: string;

    /**
     * An id for the input label.
     */
    labelId?: string;

    /**
     * The change event handler to attach to this input.
     */
    onChange?: React.ChangeEventHandler<HTMLInputElement>;

    /**
     * An optional icon to display for the file input.
     */
    icon?: ReactNode;

    /**
     * Boolean if the icon should appear after the children in the label.
     */
    iconAfter?: boolean;

    /**
     * Boolean if the children should not have some spacing between the icon and
     * itself.  The default behavior is to use the `<TextIconSpacing>` component
     * for text styled input buttons, but this can be disabled if you want to use
     * a screenreader only accessible label.
     */
    disableIconSpacing?: boolean;

    /**
     * Dropzone input props getter.
     */
    getInputProps?: DropzoneState['getInputProps'];
}

const block = bem('rmd-file-input');

/**
 * This component is a wrapper for the `<input type="file" />` that can be themed
 * like a button.
 */
export const FileInput = forwardRef<HTMLInputElement, FileInputProps>(function FileInput(
    {
        style,
        className: propClassName,
        icon: propIcon,
        iconAfter = false,
        children,
        theme = 'primary',
        themeType = 'flat',
        buttonType = 'text',
        multiple = false,
        disableIconSpacing = false,
        onKeyDown,
        onKeyUp,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        onClick,
        onTouchStart,
        onTouchMove,
        onTouchEnd,
        onChange,
        getInputProps,
        labelId,
        ...props
    },
    ref,
) {
    const { id, disabled } = props;
    const icon = useIcon('upload', propIcon);

    const { ripples, className, handlers } = useInteractionStates({
        handlers: {
            onKeyDown,
            onKeyUp,
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            onClick,
            onTouchStart,
            onTouchMove,
            onTouchEnd,
        },
        className: buttonThemeClassNames({
            theme,
            themeType,
            buttonType,
            disabled,
            className: propClassName,
        }),
        // pressing enter or space would trigger two click events otherwise.
        disableEnterClick: true,
    });

    let content: ReactNode = icon;
    if (disableIconSpacing || (children && !icon)) {
        content = (
            <>
                {!iconAfter && icon}
                {children}
                {iconAfter && icon}
            </>
        );
    } else if (children) {
        content = (
            <TextIconSpacing icon={icon} iconAfter={iconAfter} forceIconWrap>
                {children}
            </TextIconSpacing>
        );
    }

    const inputProps = {
        ...props,
        ...handlers,
        onChange,
        type: 'file',
        className: block(),
        multiple,

        // check source getInputProps
        tabIndex: undefined,
        style: undefined,
    };

    return (
        <>
            <input
                ref={ref} // ref is getting overwritten by getInputProps if it is provided
                {...(getInputProps ? getInputProps(inputProps) : inputProps)}
            />
            <label id={labelId} htmlFor={id} style={style} className={cn('rmd-file-input-label', className)}>
                {content}
                {ripples}
            </label>
        </>
    );
});
