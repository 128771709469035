import cn from 'classnames';
import React, { HTMLAttributes, forwardRef, useEffect } from 'react';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import { useAppSize } from '@react-md/utils';
import { ExpansionList, ExpansionPanel, ExpansionPanelProps, usePanels } from '@react-md/expansion-panel';
import { Typography } from '@react-md/typography';
import { TextIconSpacing } from '@react-md/icon';
import { Icons } from '@components/icons';
import { FADE_STAGGER } from '@components/animations';
import { BrowseFiles } from '@components/browse-files';
import { FeatureContent } from '@components/feature-content';
import { DropzoneArea } from '@components/dropzone';
import { PreloaderBox } from '@components/preloader';
import { InitialOrder, ValuesType } from '@types';
import { useScrollableColumn } from '@hooks';
import { selectIsAuthenticated } from '@modules/auth';
import { selectOrderData } from '@modules/order';
import { selectIsApplyToAllModelsActive } from '@modules/product';
import { selectWidgetVisibleModelsIds, selectWidgetModelId } from '@modules/quotation';
import { useModelUploader } from '@pages/widget/upload/hooks';
import { UploaderBox } from '../uploader';
import { lineItemId } from '../helpers';
import { LineItemModel } from './line-item-model';
import { LineItemProduct } from './line-item-product';
import { LineItemProductDetails } from './line-item-product-details';

import styles from './line-items-column.module.scss';
import { selectModelsError } from '@modules/models';

const LineItemsBox = forwardRef<HTMLDivElement, ExpansionPanelProps>(({ children, className, ...props }, ref) => (
    <ExpansionPanel
        persistent
        disableSecondaryColor
        className={cn('flex', 'flex-col', 'relative', styles.half, className)}
        contentClassName={cn('relative', 'bg-primary', 'overflow-hidden', 'flex-1', styles.body)}
        headerClassName={cn('relative', 'bg-secondary', styles.head)}
        ref={ref}
        {...props}
    >
        {children}
    </ExpansionPanel>
));

const LineItemsBody = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ children, className, ...props }, ref) => (
        <div className={cn(styles.scrollable, className)} ref={ref} {...props}>
            {children}
        </div>
    ),
);

interface LineItemsHeaderProps extends React.HTMLAttributes<HTMLElement> {
    quantity: number;
    icon: ValuesType<typeof Icons>;
    counterClassName: string;
}

const LineItemsHeader: React.FC<LineItemsHeaderProps> = ({ id, children, icon, quantity, counterClassName }) => {
    const Icon = icon;

    return (
        <div className={cn('w-full', 'flex', 'items-center', 'justify-between')}>
            <Typography id={id} type="headline-5" className={cn('flex', 'items-center')}>
                <TextIconSpacing icon={<Icon />} forceIconWrap>
                    {children}
                </TextIconSpacing>
            </Typography>

            <Typography
                type="headline-6"
                className={cn('bg-primary', 'rmd-typography--center', styles.counter, counterClassName)}
            >
                {quantity}
            </Typography>
        </div>
    );
};

interface LineItemsBoxProps extends ExpansionPanelProps {
    appear: boolean;
    isScrollable: boolean;
    isLoading?: boolean;
}

const LineItemsModelsBox: React.FC<LineItemsBoxProps> = ({
    appear,
    isScrollable,
    isLoading = false,
    children,
    ...panel
}) => {
    const widgetModelId = useAppSelector(selectWidgetModelId);
    const visibleModels = useAppSelector(selectWidgetVisibleModelsIds);

    const { parentRef, scrollableRef, updateColumn, handleScrollColumn } = useScrollableColumn({
        enabled: isScrollable,
    });

    // update column on items change
    useEffect(() => {
        updateColumn && updateColumn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetModelId, visibleModels, panel.expanded]);

    const { t } = useTranslation();

    return (
        <LineItemsBox
            {...panel}
            ref={parentRef}
            className={styles.models}
            header={
                <LineItemsHeader
                    id={lineItemId('models_title')}
                    icon={Icons.Box}
                    quantity={visibleModels.length}
                    counterClassName="rmd-typography--theme-warning"
                >
                    <FeatureContent contentKey={lineItemId('models_title')} fallback={t('titles.models', 'Models')} />
                </LineItemsHeader>
            }
        >
            <LineItemsBody onScroll={isScrollable ? handleScrollColumn : undefined} ref={scrollableRef}>
                {Boolean(visibleModels.length) && (
                    <TransitionGroup
                        className={styles.list}
                        appear={appear}
                        enter={false}
                        exit={false}
                        component={'ul'}
                    >
                        {visibleModels.map((modelId, index) => (
                            <LineItemModel
                                key={modelId}
                                modelId={modelId}
                                isCurrentModel={widgetModelId === modelId}
                                delay={FADE_STAGGER * index++}
                            />
                        ))}
                    </TransitionGroup>
                )}
                <UploaderBox appear={appear} updateColumn={isScrollable ? updateColumn : undefined}>
                    {children}
                </UploaderBox>
                <PreloaderBox show={isLoading} />
            </LineItemsBody>
        </LineItemsBox>
    );
};

const LineItemsProductsBox: React.FC<LineItemsBoxProps> = ({ appear, isScrollable, ...panel }) => {
    const order = useAppSelector(selectOrderData) || ({} as Partial<InitialOrder>);

    const { parentRef, scrollableRef, updateColumn, handleScrollColumn } = useScrollableColumn({
        enabled: isScrollable && panel.expanded,
    });

    // update column on items change
    useEffect(() => {
        updateColumn && updateColumn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order.products]);

    const { t } = useTranslation();

    return (
        <LineItemsBox
            {...panel}
            marginTop={false}
            ref={parentRef}
            className={styles.cart}
            header={
                <LineItemsHeader
                    id={lineItemId('cart_title')}
                    icon={Icons.Cart}
                    quantity={order.products?.length ?? 0}
                    counterClassName="rmd-typography--theme-success"
                >
                    <FeatureContent contentKey={lineItemId('cart_title')} fallback={t('titles.cart', 'Cart')} />
                </LineItemsHeader>
            }
        >
            <LineItemsBody onScroll={isScrollable ? handleScrollColumn : undefined} ref={scrollableRef}>
                {order.products?.length ? (
                    <TransitionGroup
                        className={styles.list}
                        appear={appear}
                        enter={false}
                        exit={false}
                        component={'ul'}
                    >
                        {order.products.map((product, index) => (
                            <LineItemProduct key={product.id} product={product} delay={FADE_STAGGER * index++}>
                                <LineItemProductDetails product={product} />
                            </LineItemProduct>
                        ))}
                    </TransitionGroup>
                ) : (
                    <Typography
                        type="subtitle-2"
                        weight="semi-bold"
                        className={cn('margin-top', 'margin-bottom', 'rmd-typography--center', styles.empty)}
                    >
                        <FeatureContent
                            contentKey={lineItemId('empty_cart')}
                            fallback={t('messages.emptyCart', 'There’s nothing in the cart yet')}
                        />
                    </Typography>
                )}
            </LineItemsBody>
        </LineItemsBox>
    );
};

interface LineItemsColumnProps {
    appear: boolean;
}

export const LineItemsColumn = ({ appear }: LineItemsColumnProps) => {
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const isApplyToAllModelsActive = useAppSelector(selectIsApplyToAllModelsActive);
    const error = useAppSelector(selectModelsError);

    const { isDesktop, isTablet } = useAppSize();
    const isScrollable = isDesktop || isTablet;
    // const isNotExpandable = isTablet;
    const [panels, onKeyDown] = usePanels({
        idPrefix: lineItemId('panels'),
        count: 2,
        defaultExpandedIndex: -1,
        multiple: true,
        // preventAllClosed: true,
    });

    const [modelsPanelProps, productsPanelProps] = panels;

    const { getRootProps, getInputProps, isDragActive, open } = useModelUploader({
        initUpload: true,
        disabled: isApplyToAllModelsActive,
    });

    return (
        <div className={cn('relative', styles.box)}>
            <ExpansionList
                onKeyDown={onKeyDown}
                className={cn('overflow-hidden', styles.grid, {
                    'sticky-top': isDesktop,
                    [styles.bothOpen]: modelsPanelProps.expanded && productsPanelProps.expanded,
                })}
            >
                <DropzoneArea {...getRootProps()} active={isDragActive} />

                <LineItemsModelsBox
                    appear={appear}
                    isScrollable={isScrollable}
                    isLoading={isApplyToAllModelsActive}
                    {...modelsPanelProps}
                    // disabled={isNotExpandable}
                    disableTransition={isDesktop}
                >
                    {error && (
                        <Typography className={styles.error} type="body-1" component="h6">
                            {error.message}
                        </Typography>
                    )}
                    <input {...getInputProps()} />
                    <BrowseFiles isAuthenticated={isAuthenticated} onClick={open} className="base-paddings" />
                </LineItemsModelsBox>

                <LineItemsProductsBox
                    appear={appear}
                    isScrollable={isScrollable}
                    {...productsPanelProps}
                    // disabled={isNotExpandable}
                    disableTransition={isDesktop}
                />
            </ExpansionList>
        </div>
    );
};
