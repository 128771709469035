import cn from 'classnames';
import uniqueId from 'lodash/uniqueId';
import { useAppSelector } from '@app/hooks';
import { Typography, TypographyProps } from '@react-md/typography';
import { Tooltipped, TooltipPositionHookOptions } from '@react-md/tooltip';
import { selectAppSettings } from '@modules/app';

import styles from './tbd.module.scss';

export interface Props extends TypographyProps {
    truncatedTooltipPos?: TooltipPositionHookOptions['position'];
}

export const TbdLabel = ({ className, truncatedTooltipPos = 'below', ...rest }: Props) => {
    const { tbd_label } = useAppSelector(selectAppSettings);

    const isTruncated = tbd_label.length > 20;
    const labelId = uniqueId('tbd-label-');
    const labelProps: TypographyProps = {
        className: cn(styles.box, className),
        type: 'subtitle-2',
        weight: 'bold',
        component: 'span',
        ...rest,
    };

    if (isTruncated) {
        return (
            <Tooltipped id={labelId} position={truncatedTooltipPos} tooltip={tbd_label}>
                <Typography {...labelProps}>{tbd_label.slice(0, 20)}...</Typography>
            </Tooltipped>
        );
    }

    return (
        <Typography id={labelId} {...labelProps}>
            {tbd_label}
        </Typography>
    );
};
