import { Technology, AppSettings } from '@types';

export function getSpecificationByCompanyMaterial(
    technologies: Technology[],
    defaultMaterialId: AppSettings['default_material_id'],
) {
    const technologyByMaterial = technologies.find(technology =>
        technology.materials.find(material => {
            return material.id === defaultMaterialId;
        }),
    );
    const technology = technologyByMaterial || technologies[0];
    const technologyId = technology.id;
    const materialId = technologyByMaterial ? defaultMaterialId! : technology.materials[0].id;

    return {
        technologyId,
        materialId,
    };
}

export function getSpecificationsByCompanyMaterial(
    selectedModels: number[],
    technologies: Technology[],
    defaultMaterialId: AppSettings['default_material_id'],
) {
    const { technologyId, materialId } = getSpecificationByCompanyMaterial(technologies, defaultMaterialId);

    return selectedModels.reduce((acc, modelId) => {
        const specification = {
            technologyId,
            materialIds: {
                [technologyId]: materialId,
            },
        };

        return {
            ...acc,
            [modelId]: specification,
        };
    }, {});
}
