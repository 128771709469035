import React, { SVGProps } from 'react';
import cn from 'classnames';
import { bem } from '@react-md/utils';
const block = bem('rmd-icon');
const SvgWarning = (props: SVGProps<SVGSVGElement>) => {
    props = Object.assign(
        {
            'aria-hidden': true,
        },
        props,
        {
            className: cn(block(), props.className),
        },
    );
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            fill="none"
            viewBox="0 0 20 20"
            role="img"
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M7.594 2.385c1.07-1.847 3.743-1.847 4.812 0l7.218 12.461C20.693 16.692 19.356 19 17.218 19H2.782C.644 19-.693 16.692.376 14.846L7.594 2.385ZM11 7a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0V7Zm-1 7a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z"
                clipRule="evenodd"
            />
        </svg>
    );
};
export default SvgWarning;
