import cn from 'classnames';
import { useEffect } from 'react';
import { withBaseErrorBoundary } from '@components/error-boundaries';
import { setWidgetIframeHeight } from '@utils/parant-site-comunicator';
import { SignInFormBody } from './sign-in-form-body';
import { SignInFormFooter } from './sign-in-form-footer';
import { signInId } from '../helpers';

import styles from '../auth-page.module.scss';

export const SignInPage = withBaseErrorBoundary(() => {
    useEffect(() => {
        setTimeout(() => {
            setWidgetIframeHeight('default');
        }, 500);
    });

    return (
        <div id={signInId('page')} className={styles.layout}>
            <div className="container">
                <div className={cn('rounded-box', styles.box)}>
                    <SignInFormBody />
                    <SignInFormFooter type="page" />
                </div>
            </div>
        </div>
    );
});
