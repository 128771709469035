import cn from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Trans } from 'react-i18next';
import { useAddMessage } from '@react-md/alert';
import { AlertProps } from '@components/alert';
import { DropzoneAlert, DropzoneArea, DropzoneErrors } from '@components/dropzone';
import { composeNodeId } from '@utils';
import { FileInput, FileInputProps } from './file-input';
import { FilesList } from './files-list';
import { createFileFromUrl, readFile } from './helpers';
import { FileObject, FilesListExpandingProps } from './types';
import { DefaultMaxFiles, DefaultMaxFileSize, DefaultMinFileSize } from './constants';

import styles from './file-field.module.scss';

type FileFieldProps = Omit<FilesListExpandingProps, 'expandToggleId'> & {
    inputProps: FileInputProps;
    // alertSnackbarProps?: AlertProps;
    dropzoneProps?: DropzoneOptions;
    dropzoneHint?: string;
    className?: string;
    dropzoneClassName?: string;
    uploaderClassName?: string;
    filesClassName?: string;
    fileClassName?: string;
    initialFiles?: Array<string | File>;
    showAlerts?: boolean | Array<AlertProps['type']>;
    showPreviews?: boolean;
    previewData?: boolean;
    onChange?: (files: File[]) => void;
    onDelete?: (file: File, index: number) => void;
    getFileAddedMessage?: (file: string) => React.ReactElement;
    getFileRemovedMessage?: (file: string) => React.ReactElement;
    getFileLimitExceedMessage?: (maxFiles: number) => React.ReactElement;
};

export const FileField: React.FC<FileFieldProps> = ({
    inputProps,
    dropzoneProps,
    dropzoneHint,
    className,
    dropzoneClassName,
    uploaderClassName,
    filesClassName,
    fileClassName,
    initialFiles,
    // showAlerts = true,
    showAlerts = ['error'],
    showPreviews = true,
    previewData,
    expandingEnabled = true,
    expandedByDefault = true,
    expandingThreshold = 2,
    onChange,
    onDelete,
    getFileAddedMessage: _getFileAddedMessage,
    getFileRemovedMessage: _getFileRemovedMessage,
    getFileLimitExceedMessage: _getFileLimitExceedMessage,
    children,
}) => {
    const [fileObjects, setFileObjects] = useState<FileObject[]>([]);

    useEffect(() => {
        const loadInitialFiles = async () => {
            // reset previous render results
            fileObjects.length && setFileObjects([]);

            if (!initialFiles) {
                return;
            }

            try {
                const fileObjs = await Promise.all(
                    initialFiles.map(async initialFile => {
                        let file;
                        let uuid;
                        if (typeof initialFile === 'string') {
                            file = await createFileFromUrl(initialFile);
                            uuid = initialFile;
                        } else {
                            file = initialFile;
                            uuid = uuidv4();
                        }

                        // const data = await readFile(file);
                        return {
                            uuid,
                            file,
                            // data,
                        } as FileObject;
                    }),
                );

                // case where attachments were added before initialization
                fileObjs.length && setFileObjects(exists => exists.concat(fileObjs));
            } catch (err) {
                console.log(err);
            }
        };

        loadInitialFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputProps.id]);

    // don't want the first render to trigger the onChange callback, but all changes afterwards should.
    const rendered = useRef(false);

    useEffect(() => {
        if (rendered.current && onChange) {
            onChange(fileObjects.map(fileObject => fileObject.file));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileObjects]);

    useEffect(() => {
        rendered.current = true;
        return () => {
            rendered.current = false;
        };
    }, []);

    // alerts
    const addMessage = useAddMessage();
    const shouldShowAlerts = useCallback(
        (type: AlertProps['type']) =>
            (typeof showAlerts === 'boolean' && showAlerts) || (Array.isArray(showAlerts) && showAlerts.includes(type)),

        [showAlerts],
    );
    const showErrorAlerts = shouldShowAlerts('error');
    const showSuccessAlerts = shouldShowAlerts('success');
    const showInfoAlerts = shouldShowAlerts('info');

    const getFileAddedMessage =
        _getFileAddedMessage ||
        (file => (
            <Trans i18nKey="messages.addFile">
                File <strong>{{ file }}</strong> successfully added.
            </Trans>
        ));
    const getFileLimitExceedMessage =
        _getFileLimitExceedMessage ||
        (maxFiles => (
            <Trans i18nKey="errors.tooManyFiles">Files limit exceeded. Maximum {{ maxFiles }} files at one time</Trans>
        ));
    const getFileRemovedMessage =
        _getFileRemovedMessage ||
        (file => (
            <Trans i18nKey="messages.removedFile">
                File <strong>{{ file }}</strong> removed.
            </Trans>
        ));

    const dropzoneParameters = {
        minSize: DefaultMinFileSize,
        maxSize: DefaultMaxFileSize,
        maxFiles: DefaultMaxFiles,
        ...dropzoneProps,
    };

    const {
        accept,
        minSize = DefaultMinFileSize,
        maxSize = DefaultMaxFileSize,
        maxFiles = DefaultMaxFiles,
    } = dropzoneParameters;

    const isMultiple = maxFiles > 1;
    const disabled = fileObjects.length === maxFiles;

    const handleDropAccepted: DropzoneOptions['onDropAccepted'] = async (acceptedFiles, evt) => {
        if (isMultiple && fileObjects.length + acceptedFiles.length > maxFiles) {
            showErrorAlerts &&
                addMessage({
                    messageId: 'dropzone',
                    // messageId: 'dropzone-errors',
                    messagePriority: 'replace',
                    // disableActionHide: true,
                    className: 'rmd-toast--alert',
                    children: (
                        <DropzoneAlert type="error" variant="filled" children={getFileLimitExceedMessage(maxFiles)} />
                    ),
                });
            return;
        }

        // Notify Drop event
        if (dropzoneProps?.onDropAccepted) {
            dropzoneProps.onDropAccepted(acceptedFiles, evt);
        }

        // Retrieve fileObjects data
        const fileObjs = await Promise.all(
            acceptedFiles.map(async file => {
                // const data = await readFile(file);
                return {
                    uuid: uuidv4(),
                    file,
                    // data,
                };
            }),
        );

        // Notify added files
        setFileObjects(exists => {
            // Handle a single file
            if (maxFiles <= 1) {
                return [fileObjs[0]];
            }

            // Handle multiple files
            return exists.concat(fileObjs);
        });

        // Display message
        if (showSuccessAlerts) {
            const messages = fileObjs.map(fileObj => getFileAddedMessage(fileObj.file.name));
            addMessage({
                messageId: 'dropzone',
                // messageId: 'dropzone-errors',
                messagePriority: 'replace',
                // disableActionHide: true,
                className: 'rmd-toast--alert',
                children: (
                    <DropzoneAlert
                        type="success"
                        variant="filled"
                        children={
                            <>
                                {messages.length > 1
                                    ? messages.map((message, i) => <div key={i}>{message}</div>)
                                    : messages[0]}
                            </>
                        }
                    />
                ),
            });
        }
    };

    const handleDropRejected: DropzoneOptions['onDropRejected'] = (rejectedFiles, evt) => {
        if (dropzoneProps?.onDropRejected) {
            dropzoneProps.onDropRejected(rejectedFiles, evt);
        }

        if (showErrorAlerts) {
            const limitExceed = fileObjects.length + rejectedFiles.length > maxFiles;
            addMessage({
                messageId: 'dropzone',
                // messageId: 'dropzone-errors',
                messagePriority: 'replace',
                // disableActionHide: true,
                className: 'rmd-toast--alert',
                children: (
                    <DropzoneAlert
                        type="error"
                        variant="filled"
                        children={
                            limitExceed ? (
                                getFileLimitExceedMessage(maxFiles)
                            ) : (
                                <DropzoneErrors files={rejectedFiles} opts={{ accept, minSize, maxSize, maxFiles }} />
                            )
                        }
                    />
                ),
            });
        }
    };

    const handleRemove = (fileIndex: number) => (event: MouseEvent) => {
        event.stopPropagation(); // case into dropzone

        const removedFileObj = fileObjects[fileIndex];

        // Notify removed file
        if (onDelete) {
            onDelete(removedFileObj.file, fileIndex);
        }

        // Update local state
        setFileObjects(exists => exists.filter((fileObject, i) => i !== fileIndex));

        if (showInfoAlerts) {
            addMessage({
                messageId: 'dropzone',
                // messageId: 'dropzone-errors',
                messagePriority: 'replace',
                // disableActionHide: true,
                className: 'rmd-toast--alert',
                children: (
                    <DropzoneAlert
                        type="info"
                        variant="filled"
                        children={getFileRemovedMessage(removedFileObj.file.name)}
                    />
                ),
            });
        }
    };

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        // isDragAccept,
        // isDragReject,
    } = useDropzone({
        ...dropzoneParameters,
        onDropAccepted: handleDropAccepted,
        onDropRejected: handleDropRejected,
        disabled: disabled,
        multiple: isMultiple,
        // ref: ref,
    });

    return (
        <div className={cn([styles.box, className])}>
            {showPreviews && fileObjects.length > 0 ? (
                <FilesList
                    className={filesClassName}
                    fileClassName={fileClassName}
                    fileObjects={fileObjects}
                    handleRemove={handleRemove}
                    previewData={previewData}
                    expandingEnabled={expandingEnabled}
                    expandToggleId={composeNodeId(inputProps.id, 'files_expander')}
                    expandedByDefault={expandedByDefault}
                    // expandToggleId={
                    //     expandingEnabled ? composeNodeId(inputProps.id, 'files_expander') : undefined
                    // }
                    // expandingThreshold={expandingEnabled ? expandingThreshold : undefined}
                />
            ) : null}

            <div className={cn([disabled ? 'hidden' : styles.uploader, uploaderClassName])}>
                <FileInput
                    {...inputProps}
                    disabled={disabled || Boolean(inputProps?.disabled)}
                    getInputProps={getInputProps}
                />
            </div>

            {children}

            <DropzoneArea
                hint={dropzoneHint}
                disabled={disabled}
                active={isDragActive}
                {...getRootProps({
                    className: dropzoneClassName,
                })}
            />
        </div>
    );
};
