import React from 'react';
import cn from 'classnames';
import { Typography, TypographyProps } from '@react-md/typography';

import styles from './label.module.scss';

export const Label: React.FC<TypographyProps & { labelColor?: string }> = ({
    className,
    children,
    labelColor,
    ...rest
}) => {
    return (
        <Typography
            className={cn([styles.box, className])}
            type="body-2"
            weight="semi-bold"
            style={{ color: labelColor }}
            component={rest.component || 'div'}
            {...rest}
        >
            {children}
        </Typography>
    );
};

export interface FieldLabelProps extends TypographyProps {
    htmlFor: string;
}

export const FieldLabel: React.FC<FieldLabelProps> = ({ className, children, ...rest }) => {
    return (
        <Typography
            type="body-2"
            weight="semi-bold"
            component={'label'}
            className={cn([styles.box, className])}
            {...rest}
        >
            {children}
        </Typography>
    );
};
