import React, { useEffect } from 'react';
import { useAppSelector } from '@app/hooks';
import { selectStyleCustomization } from '@modules/app';
import { setCustomTheme } from './helpers';
import { ThemeConfig } from './theme-config';

export const Theme = () => {
    const customTheme = useAppSelector(selectStyleCustomization);
    const themeConfigIsOpened = useAppSelector(state => state.app.themeConfigIsOpened);

    useEffect(() => {
        setCustomTheme(customTheme);
    }, [customTheme]);

    return themeConfigIsOpened ? <ThemeConfig /> : null;
};
