import { Attachment, AttachmentsRequest } from '@types';
import { axiosInstance } from './axios-instance';

export class AttachmentsService {
    static init(entityName: string) {
        return new AttachmentsService(entityName);
    }

    constructor(private entityName: string) {}

    getAttachments(id: number) {
        return axiosInstance.get<Attachment[]>(`/v2/${this.entityName}/${id}/attachments/`);
    }

    addAttachment({ entityId, attachmentFiles, fileType: file_type = 'client' }: AttachmentsRequest) {
        const formData = new FormData();

        attachmentFiles.forEach(file => formData.append('attachment_files', file));

        formData.append('file_type', file_type);

        return axiosInstance.post<Attachment[]>(`/v2/${this.entityName}/${entityId}/attachments/`, formData);
    }

    getAttachment(id: number, attachmentId: number) {
        return axiosInstance.get<Attachment>(`/v2/${this.entityName}/${id}/attachments/${attachmentId}/`);
    }

    removeAttachment(id: number, attachmentId: number) {
        return axiosInstance.delete<unknown>(`/v2/${this.entityName}/${id}/attachments/${attachmentId}/`);
    }
}
