import React from 'react';
import cn from 'classnames';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { CountInput, CountInputProps } from '@components/count-input';
import { FeatureContent } from '@components/feature-content';
import { FeatureOn, FeatureOff, FeatureToggle, FeatureKeys as FF } from '@components/feature-flags';
import { FieldLabel } from '@components/label';
import { SidebarSectionHead } from '@components/sidebar-section';
import { selectHasPricingData } from '@modules/pricing';
import { useWidgetCounter } from '@pages/widget/order/hooks';
import { CalculationBulkPrices } from './calculation-bulk-prices';
import { priceConfigId } from '../helpers';

import styles from './calculation-config.module.scss';

export const CalculationConfig = () => {
    const hasPricingData = useAppSelector(selectHasPricingData);
    const { widgetCount: count, setWidgetCount } = useWidgetCounter();

    const { t } = useTranslation();

    const disabled = !hasPricingData;
    const counterId = priceConfigId('quantity', 'field');
    const counterLabelId = priceConfigId('quantity', 'label');
    const counterLabel = <FeatureContent contentKey={counterLabelId} fallback={t('labels.quantity', 'Quantity')} />;
    const countProps: CountInputProps = {
        id: counterId,
        disabled,
        onChange: value => setWidgetCount(value),
        precision: 0,
        min: 1,
        max: 65000,
        value: count,
    };

    return (
        <FeatureToggle flagKey={FF.CartPage.BulkPriceOn}>
            <FeatureOn>
                <CalculationBulkPrices
                    counter={
                        <>
                            <FieldLabel id={counterLabelId} htmlFor={countProps.id}>
                                {counterLabel}
                            </FieldLabel>
                            <CountInput {...countProps} />
                        </>
                    }
                    count={count}
                    setWidgetCount={setWidgetCount}
                    disabled={disabled}
                />
            </FeatureOn>

            <FeatureOff>
                <SidebarSectionHead className="margin-bottom">
                    <div className={cn('rounded-box', 'base-paddings', 'bg-secondary', styles.quantity)}>
                        <Typography
                            id={counterLabelId}
                            type="headline-5"
                            component={'label'}
                            {...{ htmlFor: counterId }}
                        >
                            {counterLabel}
                        </Typography>

                        <CountInput className="bg-primary" {...countProps} />
                    </div>
                </SidebarSectionHead>
            </FeatureOff>
        </FeatureToggle>
    );
};
