import cn from 'classnames';
import { Divider } from '@react-md/divider';
import { withBaseErrorBoundary } from '@components/error-boundaries';
import { BackLink } from '@components/link';
import { composeNodeId } from '@utils';
import { useGetResizeRef } from '@utils/parant-site-comunicator';
import { ModelsPageHeader } from './models-page-header';
import { ModelsPageBody } from './models-page-body';
import { ModelsPageFooter } from './models-page-footer';
import { ModelsPagePagination } from './models-page-pagination';
import { RootPrefix } from './constants';

import styles from './models-page.module.scss';

const Page = () => {
    const containerRef = useGetResizeRef();

    return (
        <div id={RootPrefix} className={styles.layout} ref={containerRef}>
            <div className="container">
                <BackLink id={composeNodeId(RootPrefix, 'back', 'link')} />

                <div className={cn('rounded-box', styles.box)}>
                    <ModelsPageHeader />
                    <ModelsPageBody />
                    <Divider />
                    <ModelsPageFooter />
                </div>
            </div>

            <ModelsPagePagination />
        </div>
    );
};

export const UserModelsPage = withBaseErrorBoundary(Page);
