// import cn from 'classnames';
import { StartPageTile } from './start-page-tile';
import { START_PAGE_INFO, START_PAGE_TILES } from './const';

import styles from './tti-start-page.module.scss';

//***** This page is made specifically for TTI company *****

export function TtiStartPage() {
    const { title, description } = START_PAGE_INFO;

    return (
        <div className={styles['layout']}>
            <div className={styles['header']}>
                <img
                    className={styles['logo']}
                    src="https://app.digifabster.com/img/start-page/tti/logo.png"
                    alt="logo"
                />
            </div>
            <div className={styles['content-container-layout']}>
                <div className={styles['content-container']}>
                    <div className={styles['info-container']}>
                        <h1>{title}</h1>
                        <p>{description}</p>
                    </div>
                    <div className={styles['tiles-container']}>{START_PAGE_TILES.map(tile => StartPageTile(tile))}</div>
                </div>
            </div>
        </div>
    );
}
