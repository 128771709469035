import cn from 'classnames';
import React from 'react';
import { Typography } from '@react-md/typography';
import { useTranslation } from 'react-i18next';
import { FeatureContent, ContentKeys as FC } from '@components/feature-content';
import { FilesList } from '@components/file-field';
import { getFileNameByUrl, getNodeIdComposer, wrapLinks } from '@utils';
import { Order } from '@types';
import { RootPrefix } from '../../constants';

import styles from './order-page-info.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'notes_section');

interface OrderPageNotesProps {
    file: Order['attached_file'];
    notes: Order['notes'];
}

export const OrderPageNotes: React.FC<OrderPageNotesProps> = ({ file, notes }) => {
    const { t } = useTranslation();
    return (
        <div className={cn('base-paddings', 'no-top', styles.box)}>
            <Typography id={_id('title')} type="headline-4">
                <FeatureContent contentKey={_id('title')} fallback={t('titles.notes', 'Notes')} />
            </Typography>

            {notes && (
                <Typography type="body-2" component="div" className="margin-top">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: wrapLinks(notes),
                        }}
                    />
                </Typography>
            )}

            {file && (
                <div className="margin-top">
                    <FilesList expandingEnabled={false} fileObjects={[{ url: file, name: getFileNameByUrl(file) }]} />
                </div>
            )}
        </div>
    );
};
