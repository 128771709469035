import * as yup from 'yup';
import { getBaseStringValidator, getEmailValidator, getPhoneValidator } from '@utils';

export const createAskHelpValidation = () => {
    const validator = getBaseStringValidator();

    return yup.object({
        name: validator.required(),
        phone: getPhoneValidator({ required: true, max: [50] }),
        email: getEmailValidator(),
        description: validator.required(),
    });
};
