import React from 'react';
import cn from 'classnames';
import { Fade } from '@components/animations';

import styles from './preloader.module.scss';

const rays = 8; // has CSS related

export interface PreloaderProps {
    className?: string;
    size?: number;
    stroke?: number;
    // rays?: number;
}

export const Preloader: React.FC<PreloaderProps> = ({
    className = 'rmd-typography--theme-primary',
    size = 32,
    stroke = 3,
    // rays = 8,
}) => {
    return (
        <div className={cn(styles.box, className)} role="alert" aria-busy>
            <svg
                className={styles.loader}
                width={size}
                height={size}
                viewBox="0 0 32 32"
                stroke="currentColor"
                strokeWidth={stroke}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                {Array(rays)
                    .fill(0)
                    .map((ray, index) => (
                        <line key={index} className={styles.ray} x1="24" y1="16" x2="29" y2="16" />
                    ))}
            </svg>
        </div>
    );
};

export interface PreloaderBoxProps {
    show: boolean;
    fade?: boolean;
    className?: string;
    loaderClassName?: string;
}

export const PreloaderBox: React.FC<PreloaderBoxProps> = ({ show, fade, className, loaderClassName, children }) => {
    const loader = (
        <Fade
            in={show}
            className={cn(styles.inner, { [styles.fade]: fade }, loaderClassName)}
            duration={100}
            easing={'ease-in'}
            unmountOnExit
        >
            <Preloader />
        </Fade>
    );

    if (!children) {
        return loader;
    }

    return (
        <div className={cn(styles.wrapper, { [styles.fade]: fade }, className)}>
            {children}
            {loader}
        </div>
    );
};
