import { Join } from '@types';

type NodePath = string | number | undefined | null;

export function composeNodeId<T extends Array<NodePath>>(...path: T) {
    return path.filter(Boolean).join('-') as Join<T>;
}

export function getNodeIdComposer<Prefix extends Array<string>>(...prefix: Prefix) {
    return <T extends Array<NodePath>>(...path: T) => composeNodeId(...prefix, ...path);
}
