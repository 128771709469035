import cn from 'classnames';
import React, { Children, forwardRef, HTMLAttributes } from 'react';
import { Divider } from '@react-md/divider';

import styles from './sidebar-section.module.scss';

export const SidebarSectionHead = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, children, ...rest }, ref) => (
        <div ref={ref} className={cn('base-paddings', 'no-bottom', className)} {...rest}>
            {children}
        </div>
    ),
);

export interface SidebarSectionProps extends HTMLAttributes<HTMLDivElement> {
    divider?: boolean;
    span?: boolean;
}

export const SidebarSection = forwardRef<HTMLDivElement, SidebarSectionProps>(
    ({ className, children, divider = false, span = false, ...rest }, ref) => {
        const hasChild = Boolean(Children.toArray(children).filter(Boolean).length);
        if (!hasChild) return null;

        return (
            <div
                ref={ref}
                className={cn('base-paddings', 'no-top', { 'small-margin-bottom': span }, styles.box, className)}
                {...rest}
            >
                {divider && <Divider className={styles.divider} />}
                {children}
            </div>
        );
    },
);
