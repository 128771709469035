import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CrossFade } from '@react-md/transition';
import { Tabs, TabsManager } from '@react-md/tabs';
import { Typography } from '@react-md/typography';
import { withBaseErrorBoundary } from '@components/error-boundaries';
import { FeatureContent, FeatureMarkup } from '@components/feature-content';
import { CreateUserRequest } from '@services/df/clients';
import { getNodeIdComposer, reverse } from '@utils';
import { GetParams } from '@constants';
import { useBuildLocation, useValidation, useMounted, useQuery } from '@hooks';
import { useGetResizeRef } from '@utils/parant-site-comunicator';
import { createClientValidation } from './form-validation';
import { ClientsSearch } from './clients-search';
import ClientForm from './client-form';
import ClientSelection from './client-selection';
import { RootPrefix } from './constants';

import styles from './client-page.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'choose_client_section');

const Page = () => {
    const validationSchema = useValidation(createClientValidation);
    const form = useForm<CreateUserRequest>({
        defaultValues: {
            email: '',
            name: '',
            surname: '',
            phone: '',
            send_notification: true,
        },
        resolver: yupResolver(validationSchema),
    });

    const uploadLocation = useBuildLocation({
        to: reverse('widgetUpload'),
    });

    // don't want the first render to trigger the animation, but all changes afterwards should.
    const rendered = useMounted();

    const query = useQuery<string | undefined>();
    const [tab, setTab] = useState<number>(() => Number(Boolean(query[GetParams.PaginationPage])));
    const isChooseClientTab = Boolean(tab);

    const containerRef = useGetResizeRef();

    const { t } = useTranslation();

    const tabs = useMemo(
        () => [
            {
                id: _id('create_client_tab'),
                className: styles.tab,
                children: (
                    <FeatureContent
                        contentKey={_id('create_client_tab')}
                        fallback={t('buttons.newClient', 'New Client')}
                    />
                ),
            },
            {
                id: _id('choose_client_tab'),
                className: styles.tab,
                children: (
                    <FeatureContent
                        contentKey={_id('choose_client_tab')}
                        fallback={t('buttons.chooseClient', 'Choose from the list')}
                    />
                ),
            },
        ],
        [t],
    );

    return (
        <div id={RootPrefix} className={styles.layout} ref={containerRef}>
            <div className="container">
                <div className={cn('rounded-box', 'base-paddings', styles.box)}>
                    <Typography type="headline-3" component="div" className="margin-bottom">
                        <FeatureContent
                            contentKey={_id('title')}
                            fallback={t('titles.chooseClient', 'Choose Client')}
                        />
                    </Typography>

                    <FeatureMarkup
                        contentKey={_id('markup_top')}
                        wrapper={props => <div className="medium-margin-bottom" {...props} />}
                    />

                    <TabsManager
                        activeIndex={tab}
                        onActiveIndexChange={index => {
                            setTab(index);
                        }}
                        tabs={tabs}
                        tabsId={_id('tabs')}
                    >
                        <div className={styles.controls}>
                            <Tabs className={styles.tabs} disableTransition />
                            {isChooseClientTab && <ClientsSearch />}
                        </div>
                    </TabsManager>

                    <CrossFade key={tab} appear={rendered}>
                        {isChooseClientTab ? (
                            <ClientSelection to={uploadLocation.to} />
                        ) : (
                            <ClientForm className={styles.form} form={form} to={uploadLocation.to} />
                        )}
                    </CrossFade>
                </div>
            </div>
        </div>
    );
};

export const IqtClientPage = withBaseErrorBoundary(Page);
