import React, { SVGProps } from 'react';
import cn from 'classnames';
import { bem } from '@react-md/utils';
const block = bem('rmd-icon');
const SvgCircle = (props: SVGProps<SVGSVGElement>) => {
    props = Object.assign(
        {
            'aria-hidden': true,
        },
        props,
        {
            className: cn(block(), props.className),
        },
    );
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={10}
            height={10}
            fill="none"
            viewBox="0 0 10 10"
            role="img"
            {...props}
        >
            <circle cx={5} cy={5} r={5} fill="currentColor" />
        </svg>
    );
};
export default SvgCircle;
