import React, { SVGProps } from 'react';
import cn from 'classnames';
import { bem } from '@react-md/utils';
const block = bem('rmd-icon');
const SvgCart = (props: SVGProps<SVGSVGElement>) => {
    props = Object.assign(
        {
            'aria-hidden': true,
        },
        props,
        {
            className: cn(block(), props.className),
        },
    );
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={16}
            fill="none"
            viewBox="0 0 18 16"
            role="img"
            {...props}
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M6.75 14.667c.414 0 .75-.299.75-.667 0-.368-.336-.667-.75-.667S6 13.632 6 14c0 .368.336.667.75.667ZM15 14.667c.414 0 .75-.299.75-.667 0-.368-.336-.667-.75-.667s-.75.299-.75.667c0 .368.336.667.75.667ZM.75.667h3l2.01 8.926c.069.307.256.583.53.78.275.195.618.3.97.294h7.29c.352.006.695-.099.97-.295.274-.196.461-.472.53-.779L17.25 4H4.5"
            />
        </svg>
    );
};
export default SvgCart;
