import cn from 'classnames';
import React from 'react';
import { Preloader, PreloaderBox } from '@components/preloader';

import styles from './fallback.module.scss';

export const Fallback: React.FC = () => {
    return (
        <div className={styles.box}>
            <Preloader className={cn('rmd-typography--theme-primary', styles.loader)} />
            {/*<PreloaderBox show />*/}
        </div>
    );
};
