import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { AppEpic } from '@types';
import { CompanyService } from '@services';
import { postProductionPricingActions } from './slice';

const getPricingEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(postProductionPricingActions.load.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            return from(CompanyService.init().getPostProductionPricing(action.payload)).pipe(
                map(({ data }) => postProductionPricingActions.loadSuccess(data.items)),
                catchError(() => of(postProductionPricingActions.loadFailure())),
            );
        }),
    );

export const postProductionPricingEpics = combineEpics(getPricingEpic);
