import { axiosInstance } from '@services/axios-instance';
import { AppSettingsData, QuestionnaireData } from '@types';

export class AppService {
    static init() {
        return new AppService();
    }

    getSettings() {
        return axiosInstance.post<AppSettingsData>('/v2/app/settings/');
    }

    updateSettings(data: Partial<AppSettingsData>) {
        return axiosInstance.patch<AppSettingsData>('/v2/company/', data);
    }

    getOrderQuestionnaire() {
        return axiosInstance.get<QuestionnaireData>('/v2/app/order-questionnaire/');
    }
}
