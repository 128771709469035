import { useEffect, useCallback, useRef, UIEvent } from 'react';
import { useResizeListener, useScrollListener } from '@react-md/utils';

interface UseScrollableOverlayOptions {
    enabled: boolean;
    overlayScrollLength?: number;
}

export function setColumnOverlayOpacity({
    enabled,
    scrollableEl,
    overlayScrollLength = 20,
    parentEl,
}: UseScrollableOverlayOptions & {
    scrollableEl?: HTMLElement | null;
    parentEl?: HTMLDivElement | null;
}) {
    let topOpacity = 0;
    let bottomOpacity = 0;

    if (enabled && scrollableEl) {
        const { offsetHeight: boxHeight, scrollTop: scrolledLength, scrollHeight: innerHeight } = scrollableEl;

        topOpacity = Math.min(scrolledLength, overlayScrollLength) / overlayScrollLength;
        bottomOpacity = Math.min(innerHeight - scrolledLength - boxHeight, overlayScrollLength) / overlayScrollLength;
    }

    parentEl?.style.setProperty('--p-column-top-overlay-opacity', topOpacity.toString());
    parentEl?.style.setProperty('--p-column-bottom-overlay-opacity', bottomOpacity.toString());
}

export function useScrollableColumn({ enabled }: UseScrollableOverlayOptions) {
    const parentRef = useRef<HTMLDivElement>(null);
    const scrollableRef = useRef<HTMLDivElement>(null);

    const updateColumn = useCallback(() => {
        setColumnOverlayOpacity({ enabled, scrollableEl: scrollableRef.current, parentEl: parentRef.current });
    }, [enabled]);

    useEffect(() => {
        updateColumn();
    }, [updateColumn]);

    useResizeListener({
        enabled,
        immediate: true,
        onResize: updateColumn,
    });

    useScrollListener({
        enabled,
        onScroll: updateColumn,
    });

    const handleScrollColumn = useCallback(
        (event: UIEvent<HTMLDivElement>) => {
            setColumnOverlayOpacity({ enabled, scrollableEl: event.currentTarget, parentEl: parentRef.current });
        },
        [enabled],
    );

    return {
        parentRef,
        scrollableRef,
        updateColumn,
        handleScrollColumn,
    };
}
