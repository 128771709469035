import _map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { isAnyOf } from '@reduxjs/toolkit';
import { useAppDispatch } from '@app/hooks';
import { addAppListener, startAppListening } from '@app/listenerMiddleware';
import { ObjectModel, Product } from '@types';
import { findProductByModel, sortByArray } from '@utils';
import { appActions, selectAppSettings } from '../app';
import {
    modelsActions,
    createModelsPolling,
    selectModelsDict,
    selectParentModelsList,
    selectSelectedModels,
} from '../models';
import { orderActions, selectOrderData, selectOrderId, selectOrderLoadedOrAbsent } from '../order';
import { preselectionActions, selectReadyPreselection } from '../preselection';
import { usePreselectionPolling } from '../preselection/listeners';
import {
    suitableMaterialsActions,
    startSuitableMaterialsPolling,
    selectReadySuitableMaterials,
} from '../suitable-materials';
import { useSuitableMaterialsPolling } from '../suitable-materials/listeners';
import { technologiesActions, selectTechnologies } from '../technologies';
import {
    getProductsSpecifications,
    getSpecificationByCompanyMaterial,
    getSpecificationsByCompanyMaterial,
    getSpecificationsByPreselection,
    getSpecificationsUpdatesBySuitableMaterials,
} from './helpers';
import { quotationActions } from './slice';
import { selectWidgetModelId, selectFullPreselectionModeOn, selectWidgetSpecifications } from './selectors';

startAppListening({
    actionCreator: appActions.initAppSuccess,
    effect: async (action, { dispatch, getState, unsubscribe }) => {
        unsubscribe();

        const state = getState();
        const selectedModels = selectSelectedModels(state);
        const orderExists = selectOrderId(state);

        if (selectedModels.length) {
            if (!orderExists) {
                dispatch(
                    quotationActions.defineWidgetObjects({
                        extendVisibleModels: selectedModels,
                    }),
                );
            }
        }
    },
});

// setSpecifications, setRepeatedSpecification and updateSpecifications will rewrite existing specs,
// it's critical if we want refresh technologies

export const usePreselectionModeListeners = () => {
    const dispatch = useAppDispatch();

    usePreselectionPolling();
    useSuitableMaterialsPolling();

    // set current widget model
    useEffect(
        () =>
            dispatch(
                addAppListener({
                    actionCreator: preselectionActions.readyReceived,
                    effect: async (action, { dispatch, getState, condition }) => {
                        const readyPreselection = action.payload;
                        if (!readyPreselection.length) return;

                        dispatch(startSuitableMaterialsPolling());

                        const state = getState();

                        // set current widget model
                        const widgetModelId = selectWidgetModelId(state);
                        if (widgetModelId) return;

                        const orderLoadedOrAbsent = selectOrderLoadedOrAbsent(state); // todo, take into account the orderId from query parameters?

                        // condition for cases:
                        // 1) when we already have a order, and we've removed all ready models,
                        // but at the same time we have active preselection for the rest models
                        // 2) when we upload/transform the model
                        if (!orderLoadedOrAbsent) return;

                        const modelsIds = selectSelectedModels(state);
                        const models = selectModelsDict(state);
                        const readyPreselectionIds = readyPreselection.map(item => parseInt(item[0]));
                        readyPreselectionIds.sort(sortByArray(modelsIds));
                        const modelId = readyPreselectionIds[0];

                        if (Object.keys(models).includes(modelId.toString())) {
                            dispatch(quotationActions.setModel(modelId));
                        } else {
                            if (await condition(modelsActions.loadSuccess.match)) {
                                const latestState = getState();
                                const widgetModelId = selectWidgetModelId(latestState);
                                const models = selectModelsDict(latestState);

                                if (!widgetModelId && Object.keys(models).includes(modelId.toString())) {
                                    dispatch(quotationActions.setModel(modelId));
                                }
                            }
                        }
                    },
                }),
            ),
        [dispatch],
    );

    // set specifications
    useEffect(
        () =>
            dispatch(
                addAppListener({
                    matcher: isAnyOf(technologiesActions.loadSuccess, preselectionActions.readyReceived),
                    effect: async (action, { dispatch, getState }) => {
                        const state = getState();
                        const fullPreselectionModeOn = selectFullPreselectionModeOn(state);
                        const technologies = technologiesActions.loadSuccess.match(action)
                            ? action.payload.results
                            : selectTechnologies(state);
                        const readyPreselection = preselectionActions.readyReceived.match(action)
                            ? action.payload
                            : Object.entries(selectReadyPreselection(state));

                        if (readyPreselection.length && technologies.length) {
                            dispatch(
                                quotationActions.setSpecifications(
                                    getSpecificationsByPreselection(
                                        readyPreselection,
                                        technologies,
                                        fullPreselectionModeOn,
                                    ),
                                ),
                            );
                        }
                    },
                }),
            ),
        [dispatch],
    );

    // update specifications
    useEffect(
        () =>
            dispatch(
                addAppListener({
                    matcher: isAnyOf(technologiesActions.loadSuccess, suitableMaterialsActions.readyReceived),
                    effect: async (action, { dispatch, getState }) => {
                        const state = getState();
                        const technologies = technologiesActions.loadSuccess.match(action)
                            ? action.payload.results
                            : selectTechnologies(state);
                        const readySuitableMaterials = suitableMaterialsActions.readyReceived.match(action)
                            ? action.payload
                            : Object.entries(selectReadySuitableMaterials(state));

                        if (readySuitableMaterials.length && technologies.length) {
                            const updateSpecifications = getSpecificationsUpdatesBySuitableMaterials(
                                readySuitableMaterials,
                                technologies,
                            );

                            if (!isEmpty(updateSpecifications)) {
                                dispatch(quotationActions.updateSpecifications(updateSpecifications));
                            }
                        }
                    },
                }),
            ),
        [dispatch],
    );
};

export const useRepeatSpecsModeListeners = () => {
    const dispatch = useAppDispatch();

    // set specifications
    useEffect(
        () =>
            dispatch(
                addAppListener({
                    actionCreator: technologiesActions.loadSuccess,
                    effect: (action, { dispatch, getState }) => {
                        const state = getState();
                        const modelsIds = selectSelectedModels(state);
                        const { default_material_id } = selectAppSettings(state);
                        const technologies = action.payload.results;
                        dispatch(
                            quotationActions.setSpecifications(
                                getSpecificationsByCompanyMaterial(modelsIds, technologies, default_material_id),
                            ),
                        );
                        dispatch(
                            quotationActions.setRepeatedSpecification(
                                getSpecificationByCompanyMaterial(technologies, default_material_id),
                            ),
                        );
                    },
                }),
            ),
        [dispatch],
    );

    // set specifications and current widget model
    useEffect(
        () =>
            dispatch(
                addAppListener({
                    actionCreator: modelsActions.loadSuccess,
                    effect: (action, { dispatch, getState }) => {
                        const state = getState();
                        const orderLoadedOrAbsent = selectOrderLoadedOrAbsent(state); // todo, take into account the orderId from query parameters?
                        // condition for cases:
                        // 1) when we already have a order, and we've removed all ready models,
                        // but at the same time we have active preselection for the rest models
                        // 2) when we upload/transform the model
                        if (!orderLoadedOrAbsent) return;

                        // get parent models
                        const models = Object.values(action.payload).reduce(
                            (acc, item) => (!item.parent_model ? [...acc, item] : acc),
                            [] as Array<ObjectModel>,
                        );

                        if (!models.length) return;

                        const { default_material_id } = selectAppSettings(state);
                        const technologies = selectTechnologies(state);
                        if (technologies.length) {
                            const modelIdsWithDefinedSpecifications = Object.keys(selectWidgetSpecifications(state));
                            const modelsWithUnidentifiedSpecifications = models.filter(
                                model => !modelIdsWithDefinedSpecifications.includes(model.id.toString()),
                            ); // difference

                            if (modelsWithUnidentifiedSpecifications.length) {
                                dispatch(
                                    quotationActions.setSpecifications(
                                        getSpecificationsByCompanyMaterial(
                                            _map(modelsWithUnidentifiedSpecifications, 'id'),
                                            technologies,
                                            default_material_id,
                                        ),
                                    ),
                                );
                            }
                        }

                        const modelsIds = selectSelectedModels(state);
                        const widgetModelId = selectWidgetModelId(state);
                        if (!widgetModelId) {
                            models.sort(sortByArray(modelsIds, 'id'));
                            const modelId = models[0].id;

                            dispatch(quotationActions.setModel(modelId));
                        }
                    },
                }),
            ),
        [dispatch],
    );
};

export const useQuotationListeners = () => {
    const dispatch = useAppDispatch();

    // set initial model & product set
    useEffect(
        () =>
            dispatch(
                addAppListener({
                    actionCreator: orderActions.loadSuccess,
                    effect: async (action, { condition, dispatch, getState, unsubscribe }) => {
                        // once
                        unsubscribe();

                        const defineInitialWidgetObjects = (models: ObjectModel[], products: Product[]) => {
                            const model = models[0];
                            const visibleModels = _map(
                                products.length ? models.filter(model => !findProductByModel(products, model)) : models,
                                'id',
                            );
                            const invisibleModels = _map(
                                models.filter(model => !visibleModels.includes(model.id)),
                                'id',
                            );
                            const product = findProductByModel(products, model);

                            dispatch(
                                quotationActions.defineWidgetObjects({
                                    extendVisibleModels: visibleModels,
                                    extendViewedModels: [...invisibleModels, model.id],
                                    modelId: model.id,
                                    product,
                                }),
                            );
                        };

                        const state = getState();
                        const { products } = action.payload;
                        const models = selectParentModelsList(state);

                        if (models.length) {
                            defineInitialWidgetObjects(models, products);
                            return;
                        }

                        if (await condition(modelsActions.loadSuccess.match)) {
                            const latestState = getState();
                            const models = selectParentModelsList(latestState);
                            defineInitialWidgetObjects(models, products);
                        }
                    },
                }),
            ),
        [dispatch],
    );

    // set specifications
    useEffect(
        () =>
            dispatch(
                addAppListener({
                    actionCreator: orderActions.loadSuccess,
                    effect: async (action, { condition, dispatch, getState, unsubscribe }) => {
                        // once
                        unsubscribe();

                        const state = getState();
                        const { products } = action.payload;
                        const technologies = selectTechnologies(state);

                        if (technologies.length) {
                            dispatch(
                                quotationActions.setProductsSpecifications(
                                    getProductsSpecifications(products, technologies),
                                ),
                            );
                            return;
                        }

                        if (await condition(technologiesActions.loadSuccess.match)) {
                            const latestState = getState();
                            const technologies = selectTechnologies(latestState);
                            const order = selectOrderData(latestState);
                            if (!order?.products.length) return;

                            dispatch(
                                quotationActions.setProductsSpecifications(
                                    getProductsSpecifications(order.products, technologies),
                                ),
                            );
                        }
                    },
                }),
            ),
        [dispatch],
    );

    useEffect(() => {
        dispatch(createModelsPolling());
    }, [dispatch]);
};
