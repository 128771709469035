import { useQueryParam, useQueryParams, StringParam, withDefault } from 'use-query-params';
import { matchesAnyRoutes } from '@utils';
import { GetParams } from '@constants';

export function useRedirectUrl() {
    const [redirectUrl] = useQueryParam(GetParams.RedirectUrl, StringParam);
    return redirectUrl && matchesAnyRoutes(redirectUrl) ? redirectUrl : undefined;
}

export function usePasswordlessLogin() {
    const [{ email, hash: security_hash }] = useQueryParams({
        [GetParams.Hash]: withDefault(StringParam, undefined),
        [GetParams.Email]: withDefault(StringParam, undefined),
    });
    return { email, security_hash };
}
