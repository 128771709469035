import cn from 'classnames';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { TextField } from '@react-md/form';
import { FeatureContent } from '@components/feature-content';
import { FormField } from '@components/form-field';
import { SubmitButton } from '@components/submit-button';
import { OrderPriceCorrectionForm } from '@types';
import { priceCorrectionsActions, selectIsPriceCorrectionAdding } from '@modules/price-corrections';
import { useValidation } from '@hooks';
import { OrderPriceCorrectionsList } from './order-price-corrections-list';
import { OrderPriceCorrectionsProps } from './types';
import { createServiceValidation } from './form-validation';
import { _id } from './helpers';

import styles from './order-price-corrections.module.scss';

const FormId = 'order_service_form';

export const OrderPriceCorrections = ({ currency: { symbol } }: OrderPriceCorrectionsProps) => {
    const dispatch = useAppDispatch();
    const isSending = useAppSelector(selectIsPriceCorrectionAdding);

    const validationSchema = useValidation(createServiceValidation);
    const { reset, control, handleSubmit } = useForm<OrderPriceCorrectionForm>({
        defaultValues: {
            comment: '',
            value: 0,
        },
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        dispatch(priceCorrectionsActions.getPriceCorrections());
    }, [dispatch]);

    const onSubmit: SubmitHandler<OrderPriceCorrectionForm> = form => {
        dispatch(priceCorrectionsActions.addPriceCorrection(form));
        reset();
    };

    const { t } = useTranslation();

    return (
        <div className={styles.box}>
            <Typography id={_id('title')} type="headline-3" component="div" className="margin-bottom">
                <FeatureContent contentKey={_id('title')} fallback={t('titles.addServices', 'Add services')} />
            </Typography>

            <form
                id={FormId}
                className={cn('emulated-flex-gap', 'items-end', styles.grid)}
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormField
                    className={cn(styles.desc, styles.max)}
                    name="comment"
                    prefix={FormId}
                    control={control}
                    component={TextField}
                    label={t('labels.description', 'Description')}
                />
                <FormField
                    className={styles.price}
                    name="value"
                    prefix={FormId}
                    control={control}
                    component={TextField}
                    widgetProps={{
                        type: 'number',
                    }}
                    label={t('labels.price', 'Price, {{symbol}}', {
                        symbol,
                    })}
                />

                <SubmitButton id={_id('add_service')} theme="primary" themeType="outline" isSending={isSending}>
                    <FeatureContent contentKey={_id('add_service')} fallback={t('buttons.addService', 'Add service')} />
                </SubmitButton>
            </form>

            <OrderPriceCorrectionsList />
        </div>
    );
};
