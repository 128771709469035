// 3D technologies

export const SLS = 1;
export const FDM = 2;
export const SLM = 3;
export const THREEDP = 4;
export const MULTIJET = 5;
export const POLYJET = 6;
export const SLA = 7;
export const MJF = 8;

// List of 3D printing technologies ids
export const THREE_D_PRINTING = [SLS, FDM, SLM, THREEDP, MULTIJET, POLYJET, SLA, MJF];

// CNC technologies
export const MILLING_5 = 9;
export const CUTTER = 10;
export const TURNING = 11;
export const MILLING_3 = 12;
export const SHEETMETAL = 13;
export const MILLING_4 = 14;

// List of CNC technologies ids
export const CNC = [MILLING_3, MILLING_4, MILLING_5, CUTTER, TURNING, SHEETMETAL];

export const TechnologiesIds = [...THREE_D_PRINTING, ...CNC];

export const PriceConfigFields = [
    'layer_thickness',
    'filling',
    'tolerance',
    'thickness',
    'lead_time',
    'color',
    'extra_fieldsets',
    'post_production',
    'dfm_features',
] as const;
