import { TBD } from './tbd';

export interface Pricing {
    count: number;
    discount_percent: number;
    discount_value: number;
    naked_price: number;
    post_production_price: number;
    priority_price: number;
    startup_cost: number;
    status: string;
    subtotal: number;
    tax: number;
    tax_percent: number;
    total: number;
    without_startup_cost: number;
}

export interface BulkPriceItem extends TBD {
    quantity: number;
    price_info: Pricing;
}

export interface PriorityPriceItem {
    priority_id: string;
    priority_name_for_user?: string;
    priority_prices: BulkPriceItem[];
}

export enum PricingErrorCode {
    SanityFailed = 'sanity_check_failed',
    SizesExceed = 'sizes_exceeded',
    ModelToSmall = 'model_too_small',
    ModelNotSuitable = 'model_not_suitable',
    ExpectedCuraIsMissing = 'expected_cura_is_missing',
    ModelIsFailed = 'model_is_failed',
}

export interface PricingError {
    code: `${PricingErrorCode}`;
    text: string;
}

export enum PricingWarningCode {
    SanityWarning = 'sanity_check_warning',
}

export interface PricingWarning {
    code: PricingWarningCode;
    text: string;
}
