import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { ModalMessage } from '@components/modal-message/ModalMessage';
import { withBaseErrorBoundary } from '@components/error-boundaries';

import styles from './poll-success-page.module.scss';

export const WidgetPollSuccessPage = withBaseErrorBoundary(() => {
    const { t } = useTranslation();

    const headerContent = (
        <Typography className={styles['folded-hands']} type="headline-3" component={'div'}>
            &#128591;
        </Typography>
    );

    const dialogContent = (
        <div className={styles.content}>
            <Typography align="center" type="headline-4" component={'p'}>
                {t('messages.thankForFeedback', 'Thank you for your feedback')}
            </Typography>
            <Typography align="center" type="body-1" component={'p'}>
                {t(
                    'messages.thankForValuableFeedback',
                    'Thank you for reaching out and providing us with valuable feedback.',
                )}
            </Typography>
        </div>
    );

    const modal = {
        className: styles.modal,
        id: 'poll-success',
        hasCrossCloseButton: false,
        hasConfirmationCloseButton: true,
        headerContent,
        dialogContent,
    };

    return <ModalMessage {...modal} />;
});
