export type FeatureTypeEnum = 'cuboids' | 'axial' | 'radial' | 'single_step_reduction';
export type BlankEnum = '';
export type ShapeTypeEnum = 'simple' | 'countersunk' | 'counterdrilled' | 'counterbored';
export type ThroughEnum = 'through' | 'not_through';
export type TopologyTypeEnum = 'hole' | 'slot' | 'pocket' | 'notch' | 'reduction';
export type Feature = {
    id: string;
    name: string;
    price_set_up?: number;
    price_per_feature?: number;
    feature_type?: FeatureTypeEnum | BlankEnum;
    shape_type?: ShapeTypeEnum | BlankEnum;
    through?: ThroughEnum | BlankEnum;
    min_ratio?: number;
    max_ratio?: number;
    is_active?: boolean;
    time_per_feature?: number;
    time_of_programming_recurring?: number;
    time_of_programming_non_recurring?: number;
    radius?: number;
    angle?: number;
    topology_type?: TopologyTypeEnum | BlankEnum;
};

export enum FeatureType {
    Cuboids = 'cuboids',
    Axial = 'axial',
    Radial = 'radial',
    SingleStepReduction = 'single_step_reduction',
}

export enum ShapeType {
    Simple = 'simple',
    Countersunk = 'countersunk',
    Counterdrilled = 'counterdrilled',
    Counterbored = 'counterbored',
}

// dfm feature
interface ModelDfmFeatureBase {
    through: boolean;
    angle?: number;
    radius?: number;
    ratio?: number;
    diameter?: number;
}

export interface SlotModelDfmFeature extends ModelDfmFeatureBase {
    topology_type: 'slot';
    height: number;
    width: number;
    depth: number;
    rounded: boolean;
    direction: {
        x: number;
        y: number;
        z: number;
    };
}

export interface PocketModelDfmFeature extends ModelDfmFeatureBase {
    topology_type: 'pocket';
    depth: number;
    direction: {
        x: number;
        y: number;
        z: number;
    };
    vertical_convex_fillets: number[];
    vertical_concave_fillets: number[];
}

export interface NotchModelDfmFeature extends ModelDfmFeatureBase {
    topology_type: 'notch';
    height: number;
    width: number;
    depth: number;
    direction: {
        x: number;
        y: number;
        z: number;
    };
}

export interface HoleModelDfmFeature extends ModelDfmFeatureBase {
    topology_type: 'hole';
    hash_id: string;
    feature_type: FeatureType;
    shape_type: ShapeType;
    diam01: number;
    diam02: number;
    overall_height: number;
    straight_height: number;
    angle_end_vs_center_line: number;
    direction: {
        x: number;
        y: number;
        z: number;
    };
}

export type ModelDfmFeature = SlotModelDfmFeature | PocketModelDfmFeature | NotchModelDfmFeature | HoleModelDfmFeature;

// https://digifabster.slack.com/archives/C1ENJ3LCW/p1720190696336459?thread_ts=1720178761.378319&cid=C1ENJ3LCW
export interface DfmFeature {
    type: string;
    properties: ModelDfmFeature;
    suitable_dfm_features_ids: string[];
    errors: { code: string; message: string }[];
}

export function isHoleModelDfmFeature(obj: ModelDfmFeature): obj is HoleModelDfmFeature {
    return obj.topology_type === 'hole';
}
