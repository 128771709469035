import cn from 'classnames';
import React, { useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation, Trans } from 'react-i18next';
import { useForm, FieldPath, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToggle, bem } from '@react-md/utils';
import { Dialog, DialogHeader, DialogContent } from '@react-md/dialog';
import { Button } from '@react-md/button';
import { Typography, TextWeight } from '@react-md/typography';
import { TextIconSpacing } from '@react-md/icon';
import { TextField, TextArea } from '@react-md/form';
import { FeatureContent } from '@components/feature-content';
import { FormField } from '@components/form-field';
import { FileField } from '@components/file-field';
import { Icons } from '@components/icons';
import { SubmitButton } from '@components/submit-button';
import { AskForHelpForm, ObjectModel } from '@types';
import { askForHelpActions, selectIsAskForHelpSending } from '@modules/ask-for-help';
import { useFieldsResponseErrors, useValidation } from '@hooks';
import { getNodeIdComposer, setApiErrors } from '@utils';
import { createAskHelpValidation } from './form-validation';
import { useAskForHelpLabels } from './hooks';
import { FormId, ToastFieldsErrors } from './constants';
import { RootPrefix } from '../constants';

import styles from './ask-for-help.module.scss';

const block = bem('rmd-typography');

// todo get RootPrefix from props
const _id = getNodeIdComposer(RootPrefix, 'ask_for_help');
const formId = getNodeIdComposer(FormId);

export interface AskForHelpProps {
    modelId: ObjectModel['id'];
    modelLabel: ObjectModel['title'];
    className?: string;
    weight?: TextWeight | null;
    hasIcon?: boolean;
}

export const AskForHelp: React.FC<AskForHelpProps> = ({
    modelId,
    modelLabel,
    className,
    children,
    weight = 'semi-bold',
    hasIcon = true,
}) => {
    const [isOpen, open, close] = useToggle(false);
    const isSending = useAppSelector(selectIsAskForHelpSending);

    const validationSchema = useValidation(createAskHelpValidation);

    const dispatch = useAppDispatch();
    const form = useForm<AskForHelpForm>({
        defaultValues: {
            name: '',
            phone: '',
            email: '',
            description: '',
        },
        resolver: yupResolver(validationSchema),
    });

    const { control, handleSubmit, setValue, setError, register, reset, watch } = form;

    useEffect(() => {
        register('file');
    }, [register]);

    const watchFields = watch(['description']);
    const handleFileChange = useCallback(files => setValue('file', files.length ? files[0] : undefined), [setValue]);

    const onSubmit: SubmitHandler<AskForHelpForm> = form => {
        dispatch(
            askForHelpActions.sendAskForHelp({
                modelId,
                form,
                onSuccess: () => {
                    reset();
                    close();
                },
                onError: errors => {
                    setApiErrors(setError, errors as Record<FieldPath<AskForHelpForm>, string>);
                },
            }),
        );
    };

    useFieldsResponseErrors<AskForHelpForm>(form, ToastFieldsErrors);

    const onClose = useCallback(() => {
        reset();
        close();
    }, [reset, close]);

    // const elem = useRef(null);
    // useCloseOnOutsideClick({
    //     enabled: visible,
    //     element: elem.current,
    //     onOutsideClick: onClose,
    // });

    const { t } = useTranslation();
    const labels = useAskForHelpLabels(t);
    const attachFileLabelId = formId('file', 'label');

    return (
        <>
            <FeatureContent
                contentKey={_id('open')}
                fallback={children || t('buttons.askForHelp', 'Ask for help')}
                wrapper={props => (
                    <Button
                        theme="primary"
                        onClick={open}
                        disableRipple
                        className={cn(
                            'ask-for-help-button',
                            'rmd-button--flat',
                            'fade-in',
                            block({ [weight || '']: weight }),
                            className,
                        )}
                        {...props}
                    >
                        <TextIconSpacing icon={hasIcon ? <Icons.LifeBuoy /> : null} forceIconWrap>
                            {props.children}
                        </TextIconSpacing>
                    </Button>
                )}
            />

            <Dialog
                id={_id('box')}
                visible={isOpen}
                onRequestClose={onClose}
                aria-labelledby={_id('box')}
                // containerClassName="rmd-dialog-container--scrollable"
                className="relative"
                defaultFocus={`#${formId('name', 'field')}`}
                exit={false}
                // ref={elem}
            >
                <DialogHeader className={styles.header}>
                    <Typography id={_id('title')} type="headline-3" component="div">
                        <FeatureContent
                            contentKey={_id('title')}
                            fallback={t('titles.askForHelp', 'Request manual check')}
                        />
                    </Typography>
                    <Typography type="body-2" className="small-margin-top">
                        <Trans
                            i18nKey="messages.askForHelpNote"
                            defaults={
                                'Please describe your request briefly.<br/>Extensive info about your model {{name}} will be attached.'
                            }
                            values={{
                                name: modelLabel,
                            }}
                        />
                    </Typography>
                    <Button
                        id={_id('close')}
                        buttonType="icon"
                        theme="clear"
                        themeType="outline"
                        className={styles.close}
                        onClick={onClose}
                        aria-label={t('buttons.close', 'Close')}
                    >
                        <Icons.Cross />
                    </Button>
                </DialogHeader>
                <DialogContent>
                    <form id={FormId} className="fieldset-grid" onSubmit={handleSubmit(onSubmit)}>
                        <FormField
                            prefix={FormId}
                            name="name"
                            control={control}
                            component={TextField}
                            label={labels.name}
                            errorAsTooltip
                        />
                        <FormField
                            prefix={FormId}
                            name="phone"
                            control={control}
                            component={TextField}
                            label={labels.phone}
                            errorAsTooltip
                        />
                        <FormField
                            prefix={FormId}
                            name="email"
                            control={control}
                            component={TextField}
                            label={labels.email}
                            errorAsTooltip
                        />
                        <FormField
                            prefix={FormId}
                            name="description"
                            control={control}
                            component={TextArea}
                            label={labels.description}
                            errorAsTooltip
                            widgetProps={{
                                containerProps: {
                                    // @ts-ignore
                                    'data-replicated-value': watchFields[0],
                                },
                                animate: false,
                                resize: 'both',
                                rows: 3,
                            }}
                        />

                        <div className={cn('fieldset-grid-cell', 'flex', 'items-center')}>
                            <SubmitButton prefix={_id()} isSending={isSending}>
                                <FeatureContent contentKey={_id('submit')} fallback={t('buttons.send', 'Send')} />
                            </SubmitButton>

                            <FileField
                                className={styles.file}
                                // className={cn('justify-center', styles.file)}
                                onChange={handleFileChange}
                                dropzoneProps={{ noClick: true }}
                                inputProps={{
                                    id: formId('file', 'field'),
                                    labelId: attachFileLabelId,
                                    name: 'file',
                                    className: cn('rmd-file-input-label', 'rmd-button--flat'),
                                    children: (
                                        <FeatureContent
                                            contentKey={attachFileLabelId}
                                            fallback={t('buttons.attachFile', 'Attach file')}
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};
