import React from 'react';
import cn from 'classnames';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import { Typography } from '@react-md/typography';
import { Order } from '@types';
import { Fade, FADE_STAGGER } from '@components/animations';
import { Label } from '@components/label';
import { selectOrdersResults } from '@modules/orders';
import { UserOrdersItem } from './orders-item';
import { useOrdersQueryParams } from './use-orders-query-params';

import styles from '../orders-page.module.scss';

export const UserOrdersList: React.FC = () => {
    const data = useAppSelector(selectOrdersResults);
    const { isAbandoned } = useOrdersQueryParams();
    const { t } = useTranslation();

    return !data.length ? (
        <Typography type="body-1" component="div" className={cn('base-paddings', 'no-top')}>
            {t('messages.noMatchingResults', 'No matching results')}
        </Typography>
    ) : (
        <TransitionGroup className={cn('overflow-x-auto', styles.table)} enter={false} exit={false}>
            <Fade in unmountOnExit delay={0} className={cn(styles.grid, styles.head)}>
                <Label className={styles.title} labelColor="var(--color-text-secondary)">
                    #
                </Label>
                <Label className={styles.title} labelColor="var(--color-text-secondary)">
                    {t('labels.orderDate', 'Order date')}
                </Label>
                <Label className={styles.title} labelColor="var(--color-text-secondary)">
                    {t('labels.total', 'Total')}
                </Label>
                {/*<Label className={styles.title}>{t('titles.models', 'Models')}</Label>*/}
                <Label
                    className={cn(styles.title, { 'rmd-typography--right': !isAbandoned })}
                    labelColor="var(--color-text-secondary)"
                >
                    {isAbandoned ? t('labels.details', 'Details') : t('labels.status', 'Status')}
                </Label>
            </Fade>

            {data.map((order: Order, i) => {
                return (
                    <UserOrdersItem
                        key={order.id}
                        order={order}
                        className={cn('small-margin-bottom', styles.grid, styles.row)}
                        delay={FADE_STAGGER * (i + 1)}
                        isAbandoned={isAbandoned}
                    />
                );
            })}
        </TransitionGroup>
    );
};
