import { useAppSelector } from '@app/hooks';
import { selectContentCustomization } from '@modules/app';
import { getFeatureFlag, getFeatureFlags } from './helpers';

export const useFeatureFlag = (flagKey: string) => {
    const content = useAppSelector(selectContentCustomization);
    return getFeatureFlag(content, flagKey);
};

export const useFeatureFlags = (flagKeys: string[]) => {
    const content = useAppSelector(selectContentCustomization);
    return getFeatureFlags(content, flagKeys);
};
