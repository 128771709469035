import cn from 'classnames';
import React, { useEffect } from 'react';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@react-md/utils';
import { Button } from '@react-md/button';
import { Select, useSelectState } from '@react-md/form';
import { TextIconSpacing } from '@react-md/icon';
import { Typography } from 'react-md';
import { Tooltipped } from '@react-md/tooltip';
import { Icons } from '@components/icons';
import { FeatureToggle, FeatureKeys as FF, FeatureOn } from '@components/feature-flags';
import { FeatureContent } from '@components/feature-content';
import { ObjectModel } from '@types';
import { selectAppSettings } from '@modules/app';
import { selectWidgetModelId } from '@modules/quotation';
import { ModelLabel, ModelSizes, ModelVolume } from '@modules/models';
import { useModelUnitsLabels } from '@hooks';
import { ModelScaleBox } from './model-scale-box';
import { modelDetailsId as _id } from './helpers';
import { cutSelectListIfTooLong, cutSelectListIfTooLongOnKeydown } from '@utils/widget/cut-select-list-if-too-long';
import { DetailsBox } from './details-box';

import styles from './model-details.module.scss';

interface Props {
    model: ObjectModel;
}

export const ModelDetails: React.FC<Props> = ({ model }) => {
    const settings = useAppSelector(selectAppSettings);
    const widgetModelId = useAppSelector(selectWidgetModelId);
    const unitsLabels = useModelUnitsLabels();

    // scale model box
    const [isScaleBoxOpen, openScaleBox, closeScaleBox] = useToggle(false);

    // convert current model units (just express the current units in another)
    const defaultConvertedUnits = settings.display_widget_units || model.units;
    const [units, handleUnitsChange] = useSelectState<ObjectModel['units']>(defaultConvertedUnits);

    // reset model units to default when switching model
    useEffect(() => {
        handleUnitsChange(defaultConvertedUnits);

        if (isScaleBoxOpen) {
            closeScaleBox();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetModelId]);

    const { t } = useTranslation();

    if (isScaleBoxOpen) return <ModelScaleBox model={model} close={closeScaleBox} />;

    const converter = (
        <Tooltipped
            id={_id('convert_model_units')}
            tooltip={t('tooltips.showIn', 'Show in')}
            position="above"
            lineWrap={false}
            spacing={10}
            disableAutoSpacing
        >
            <Select
                id={_id('convert_model_units')}
                className={cn('rmd-typography--theme-primary', styles.converter)}
                onChange={handleUnitsChange}
                options={Object.entries(unitsLabels).map(([key, value]) => ({
                    label: value.label,
                    value: key,
                }))}
                value={units}
                rightChildren={<Icons.Chevron width={12} height={12} />}
                // anchor={BELOW_RIGHT_ANCHOR}
                getDisplayLabel={() => unitsLabels[units].symbol}
                listboxClassName="rmd-listbox--small"
                listboxWidth="min"
                inline
                closeOnResize
                closeOnScroll
                onClick={cutSelectListIfTooLong}
                onKeyDown={event => cutSelectListIfTooLongOnKeydown(event.key)}
            />
        </Tooltipped>
    );

    const openScaleBoxButton = (
        <FeatureToggle flagKey={FF.CartPage.ResizeModel}>
            <FeatureOn>
                <div className={cn(styles.half, styles.halfRight)}>
                    <FeatureContent
                        contentKey={_id('open_scale_box')}
                        fallback={t('buttons.resizeModel', 'Resize model')}
                        wrapper={props => (
                            <Button
                                theme="primary"
                                className={cn('rmd-button--flat', 'rmd-typography--semi-bold')}
                                onClick={openScaleBox}
                                disableRipple
                                {...props}
                            >
                                <TextIconSpacing icon={<Icons.Edit />} forceIconWrap>
                                    {props.children}
                                </TextIconSpacing>
                            </Button>
                        )}
                    />
                </div>
            </FeatureOn>
        </FeatureToggle>
    );

    return (
        <DetailsBox className={cn(styles.box, 'fade-in')}>
            <div className={styles.half}>
                <ModelLabel className={styles.title} label={model?.title} />
                <ModelSizes className="flex-wrap" size={model.size} units={model.units} convertTo={units}>
                    {converter}
                </ModelSizes>
                <ModelVolume className="flex-wrap" model={model} convertTo={units} />
            </div>

            <div className={styles['button-box']}>
                <div className={styles.divider} />
                {openScaleBoxButton}
            </div>
        </DetailsBox>
    );
};
