// based on https://stackoverflow.com/a/18650828

const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] as const;

type Defaults = {
    decimals: number;
    autoFormat: boolean;
    expressIn?: typeof sizes[number];
};

export const formatBytes = (() => {
    const defaults: Defaults = { decimals: 2, autoFormat: false };

    return (bytes: number, options?: Partial<typeof defaults>) => {
        const { decimals, autoFormat, expressIn } = Object.assign({}, defaults, options);
        const autoExpress = !expressIn;

        if (!+bytes) return autoFormat ? '0 Bytes' : '0';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;

        const exponent = autoExpress ? Math.floor(Math.log(bytes) / Math.log(k)) : sizes.indexOf(expressIn);
        const result = parseFloat((bytes / Math.pow(k, exponent)).toFixed(dm));

        return autoFormat ? `${result} ${sizes[exponent]}` : result.toString();
    };
})();
