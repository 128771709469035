import type { GUI } from 'lil-gui';
import { useCallback, useEffect, useRef } from 'react';
import { WidgetCustomizationValue } from '@types';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectStyleCustomization, appActions } from '@modules/app';
import { createThemeConfigsGui } from './gui-theme-config';

export const ThemeConfig = () => {
    const dispatch = useAppDispatch();
    const customTheme = useAppSelector(selectStyleCustomization);

    const gui = useRef<GUI>();

    const handleSave = useCallback(
        (themeConfig: WidgetCustomizationValue) => {
            dispatch(appActions.updateAppSettings({ widget_style_customization: themeConfig }));
        },
        [dispatch],
    );

    const handleClosePanel = useCallback(() => {
        dispatch(appActions.toggleThemeConfigIsOpened(false));
    }, [dispatch]);

    useEffect(() => {
        if (!gui.current) {
            gui.current = createThemeConfigsGui({
                customTheme,
                onSave: handleSave,
                onClose: handleClosePanel,
            });
        }
        return () => {
            gui.current?.destroy();
            gui.current = undefined;
        };
    }, [handleSave, handleClosePanel, customTheme]);

    return null;
};
