import { useLocation, Location } from 'react-router-dom';
import { AppLocationState } from '@types';

interface GetPathnameOptions {
    location: Location<AppLocationState>;
    to?: string;
}

function getPathname({ location, to }: GetPathnameOptions) {
    const pathname = to || location.pathname;
    return pathname.replace(/\/\//g, '/');
}

interface ComposeSearchParamsOptions {
    location: Location<AppLocationState>;
    query?: Record<string, string>;
    keepOldQuery?: boolean;
    pushToQuery?: Record<string, string>;
}

function composeSearchParams({
    location,
    query = {},
    keepOldQuery = false,
    pushToQuery = {},
}: ComposeSearchParamsOptions) {
    const newQuery = keepOldQuery ? new URLSearchParams(location.search) : new URLSearchParams();

    Object.entries(query).forEach(([key, value]) => {
        newQuery.set(key, value);
    });

    Object.entries(pushToQuery).forEach(([key, value]) => {
        const currentValue = newQuery.get(key);
        const splittedValue = currentValue ? currentValue.split(',') : [];
        splittedValue.push(value);

        newQuery.set(key, splittedValue.join(','));
    });

    return newQuery;
}

type BuildLocationOptions = GetPathnameOptions &
    ComposeSearchParamsOptions & {
        preserveState?: boolean;
        hash?: string;
        state?: AppLocationState;
    };

export function buildLocation({
    location,
    to,
    keepOldQuery = false,
    query = {},
    pushToQuery = {},
    hash,
    preserveState = false,
    state = {},
}: BuildLocationOptions) {
    const pathname = getPathname({
        location,
        to,
    });

    const newQuery = composeSearchParams({
        location,
        keepOldQuery,
        query,
        pushToQuery,
    });

    return {
        to: {
            pathname,
            search: newQuery.toString() ? `?${newQuery.toString()}` : '',
            hash,
        },
        state: {
            ...(preserveState && location.state ? location.state : {}),
            ...state,
        },
    };
}

type UseBuildLocationOptions = Omit<GetPathnameOptions, 'location' | 'match'> &
    Omit<ComposeSearchParamsOptions, 'location'> & {
        preserveState?: boolean;
        setFromLocation?: boolean;
        hash?: string;
        state?: AppLocationState;
    };

export function useBuildLocation({
    to,
    keepOldQuery = false,
    query = {},
    pushToQuery = {},
    hash,
    preserveState = false,
    setFromLocation = false,
    state = {},
}: UseBuildLocationOptions) {
    const location = useLocation();

    return buildLocation({
        location,
        to,
        keepOldQuery,
        query,
        pushToQuery,
        hash,
        preserveState,
        state: setFromLocation ? { from: location, ...state } : state,
    });
}
