import configureMeasurements, {
    length,
    LengthSystems,
    LengthUnits,
    volume,
    VolumeSystems,
    VolumeUnits,
} from 'convert-units';
// @ts-ignore
// import length from 'convert-units/definitions/length';
// @ts-ignore
// import volume from 'convert-units/definitions/volume';

// Measures: The names of the measures being used
export type Measures = 'length' | 'volume';
// Systems: The systems being used across all measures
export type Systems = LengthSystems | VolumeSystems;
// Units: All the units across all measures and their systems
export type Units = LengthUnits | VolumeUnits;

/*
  `configureMeasurements` is a closure that accepts a directory
  of measures and returns a factory function (`convert`) that uses
  only those measures.
*/
export const unitsConverter = configureMeasurements<Measures, Systems, Units>({
    length,
    volume,
});

export type ConvertOptions<U extends Units = Units> = {
    from: U;
    to: U;
};

type ConvertUnits = {
    value: number;
} & ConvertOptions;

export function convertUnits({ value, from, to }: ConvertUnits) {
    return unitsConverter(value).from(from).to(to);
}
