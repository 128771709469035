import React from 'react';
import { useAppSize } from '@react-md/utils';
import { ObjectModelModel } from '@models';
import { ViewerHandler } from './viewer-handler';
import { DetailsHandler } from './details';
import { ModelNotifications } from './model-notifications';

import styles from './viewer-section.module.scss';

interface Props {
    model?: ObjectModelModel;
}

export const ViewerSection: React.FC<Props> = ({ model, children }) => {
    const { isLargeDesktop } = useAppSize();
    return (
        <div className={styles.grid}>
            <div className={isLargeDesktop ? 'sticky-top' : undefined}>
                <ViewerHandler model={model} />

                {model && (
                    <>
                        <DetailsHandler model={model} />
                        <ModelNotifications model={model} />
                    </>
                )}

                {children}
            </div>
        </div>
    );
};
