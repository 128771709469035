import { TransformModelPayload } from '@types';

export function composeTransformedModelPollingId(payload: TransformModelPayload) {
    if (payload.type === 'scale') {
        const { fromModelId, units } = payload;
        return [fromModelId, units].join(':');
    } else {
        const { fromModelId, rotations } = payload;
        const rotationKeyParts = rotations.map(rotation => `${rotation.axis}=${rotation.angle}`);
        return [fromModelId, rotationKeyParts].flat().join(':');
    }
}
