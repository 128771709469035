import * as yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';
import { Layout } from '@stripe/stripe-js/dist/stripe-js/elements/payment';

export enum CustomVariables {
    AppDocumentTitle = 'app-document-title',
    BulkPriceQuantities = 'bulk_price-quantities-range',
    StripePaymentElementOptions = 'stripe-payment_element-options',

    PrinterBoundingBoxSize = '3d_viewer-printer_box-size',
    PrinterBoundingBoxColor = '3d_viewer-printer_box-color',
    ModelBoundingBoxColor = '3d_viewer-model_box-color',
    ModelColor = '3d_viewer-model-color',
    ModelEdgesColor = '3d_viewer-model_edges-color',
    SceneBackgroundColor = '3d_viewer-scene_background-color',
    // Test= 'test',
}

const getCustomVariablesScheme = () => {
    return yup.object({
        AppDocumentTitle: yup
            .string()
            .trim()
            .default(
                process.env.REACT_APP_DOCUMENT_TITLE ??
                    '3D Printing And CNC Quoting Software for Job Shop Manufacturers',
            )
            .max(255),
        BulkPriceQuantities: yup
            .array(yup.number().integer().positive().truncate().stripZero().default(1))
            .ensure()
            .compact()
            .default([1, 2, 3, 4, 5, 10, 50, 100, 500]),

        PrinterBoundingBoxSize: yup.string().trim(),
        PrinterBoundingBoxColor: yup.string().trim(),
        ModelBoundingBoxColor: yup.string().trim(),
        ModelColor: yup.string().trim(),
        ModelEdgesColor: yup.string().trim(),
        SceneBackgroundColor: yup.string().trim(),

        // https://docs.stripe.com/payments/payment-element#options
        // https://docs.stripe.com/js/elements_object/create_payment_element#payment_element_create-options
        StripePaymentElementOptions: yup
            .object({
                layout: yup
                    .object({
                        type: yup
                            .string()
                            .oneOf(['accordion', 'tabs'])
                            .default('tabs')
                            .required() as RequiredStringSchema<Layout>,
                        defaultCollapsed: yup.boolean(),
                        radios: yup.boolean(),
                        spacedAccordionItems: yup.boolean(),
                        visibleAccordionItemsCount: yup.number(),
                    })
                    .default(undefined),
                business: yup
                    .object({
                        name: yup.string().default('').required(),
                    })
                    .default(undefined),
            })
            .default(undefined),
        // Test: yupBaseStr.default('hello test').max(255),
    });
};

export const customVariablesScheme = getCustomVariablesScheme();
export const customVariablesDefaults = customVariablesScheme.getDefault();

export type CustomVariablesType = yup.InferType<typeof customVariablesScheme>;

export let customVariables: CustomVariablesType = customVariablesDefaults;

export const validateCustomVariables = (variables: unknown) => {
    if (variables && typeof variables === 'object') {
        customVariables = Object.entries(CustomVariables).reduce((results, [key, value]) => {
            const variableScheme = customVariablesScheme.pick([key as keyof typeof CustomVariables]);

            try {
                const result = variableScheme.validateSync({
                    [key]: (variables as Record<CustomVariables, unknown>)[value],
                });
                return {
                    ...results,
                    ...result,
                };
            } catch (err) {
                console.log(err);
                // if (ValidationError.isError(err)) return results;
                // throw err;
                return results;
            }
        }, customVariables);
    }

    return customVariables;
};

// export const CustomVariablesDefaults = {
//     [CustomVariables.BulkPriceQuantities]: [false, 1, '35', 4.3, 5, 'dfs106', 50, '1sd0', 500, { sdf: 34 }],
//     [CustomVariables.AppDocumentTitle]: 234234,
//     // [CustomVariables.Test]: 234234,
// };
//
// validateCustomVariables(CustomVariablesDefaults);
// console.log('customVariables:', customVariables, 'file');
//
// const customVariables = customVariablesScheme.cast(CustomVariablesDefaults, { stripUnknown: true });
// const customVariables = customVariablesScheme.validateSync(CustomVariablesDefaults, { stripUnknown: true });
// console.log(customVariables);
