import React, { useContext } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-hook-form';
import { TransitionGroup } from 'react-transition-group';
import { ItemRef } from '@react-md/utils/src/wia-aria/movement/useKeyboardMovement';
import { OrderForm, Product } from '@types';
import { Fade, FADE_STAGGER } from '@components/animations';
import { Label } from '@components/label';
import { FeatureOn, FeatureToggle, FeatureKeys as FF } from '@components/feature-flags';
import { OrderProduct } from './order-product';
import { useOrderSubmitContext } from '../order-submit-page';

import styles from './order-products.module.scss';

export type ProductsRefs<E extends HTMLElement = HTMLElement> = Record<string, ItemRef<E>>;

interface ProductsProps {
    show: boolean;
    products?: Product[];
}

export const OrderProducts: React.FC<ProductsProps> = ({ show, products }) => {
    const tableRef = useOrderSubmitContext();
    const hasAtLeastOneBatchDiscount = products?.some(product => Number(product.discount_percents) !== 0);
    const { isSubmitted } = useFormState<OrderForm>();
    const { t } = useTranslation();

    return (
        <Fade
            in={show}
            className={cn('rounded-box', 'overflow-x-auto', styles.box)}
            mountOnEnter
            // unmountOnExit
        >
            <table className={styles.table} ref={tableRef}>
                <thead className={styles.head}>
                    <tr>
                        <td className={styles.cell} align="left">
                            <Label className={styles.label}>{t('labels.productModel', 'Model')}</Label>
                        </td>
                        <td className={styles.cell}>
                            <Label className={styles.label}>{t('labels.material', 'Material')}</Label>
                        </td>
                        <td className={styles.cell} colSpan={4}>
                            <Label className={styles.label}>{t('labels.productSpecification', 'Specification')}</Label>
                        </td>
                        <td className={styles.cell} align="center">
                            <Label className={styles.label}>{t('labels.quantity', 'Quantity')}</Label>
                        </td>
                        {hasAtLeastOneBatchDiscount && (
                            <FeatureToggle flagKey={FF.OrderPage.ProductBatchDiscountShown}>
                                <FeatureOn>
                                    <td className={styles.cell} align="center">
                                        <Label className={styles.label}>
                                            {t('labels.productDiscount', 'Batch discount')}
                                        </Label>
                                    </td>
                                </FeatureOn>
                            </FeatureToggle>
                        )}
                        <td className={styles.cell} align="center">
                            <Label className={styles.label}>{t('labels.productPricePerPart', 'Price per part')}</Label>
                        </td>
                        <td className={styles.cell} align="center">
                            <Label className={styles.label}>{t('labels.productTotal', 'Price total')}</Label>
                        </td>
                        <td className={styles.cell} />
                    </tr>
                </thead>

                <TransitionGroup className={styles.body} component="tbody">
                    {products &&
                        products.map((product, i) => (
                            <OrderProduct
                                key={product.id}
                                product={product}
                                delay={FADE_STAGGER * i}
                                isSubmitted={isSubmitted}
                                hasAtLeastOneBatchDiscount={hasAtLeastOneBatchDiscount}
                            />
                        ))}
                </TransitionGroup>
            </table>
        </Fade>
    );
};
