import Modal from 'react-responsive-modal';
import { useAppDispatch } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { DialogContent } from '@react-md/dialog';
import { Icons } from '@components/icons';
import { popupsActions } from '@modules/ui';
import { PopupProps } from '@types';

import styles from './modal-notification.module.scss';

// todo
//  1) move to modules/widget/order-submit,
//  2) remove react-responsive-modal
//  3) rewrite with ModalMessage
export const NotificationModal = ({ isOpened, isClosable }: PopupProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    return (
        <Modal
            open={isOpened}
            onClose={() => (isClosable ? dispatch(popupsActions.close()) : {})}
            closeOnEsc={true}
            center={true}
            styles={{
                modal: { width: '348px', minHeight: '260px', padding: '0px' },
            }}
        >
            <DialogContent className={styles.wrapper}>
                <div className={styles.icon}>
                    <Icons.CheckCircleThin className="rmd-typography--theme-success" width={48} height={48} />
                </div>
                <Typography type="headline-4" component="div" className={styles.label}>
                    {t('titles.firmOfferSent', 'Firm Offer Sent')}
                </Typography>
                <Typography type="body-1" component="div" className={styles.text}>
                    {t('messages.pageYourCustomer', 'This is the page your customer has received now')}
                </Typography>
            </DialogContent>
        </Modal>
    );
};
