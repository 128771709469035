import * as Three from 'three';
import type { Box3, ColorRepresentation } from 'three';
import { ModelViewerVariables } from '../constants';

export class ModelBoundingBox extends Three.Box3Helper {
    constructor(box: Box3, color: ColorRepresentation = ModelViewerVariables.ModelBox.Color) {
        super(box, new Three.Color(color));
        this.renderOrder = 6;
    }
}
