import cn from 'classnames';
import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@react-md/button';
import { TextIconSpacing } from '@react-md/icon';
import { FeatureContent } from '@components/feature-content';
import { modelsActions } from '@modules/models';
import { modelsPageActions, selectModelIds } from '@modules/models-page';
import { quotationActions, selectWidgetModelId } from '@modules/quotation';
import { useBuildLocation } from '@hooks';
import { getNodeIdComposer } from '@utils';
import { useGetOrderUrl } from '@pages/widget/order/hooks';
import { RootPrefix } from './constants';

import styles from './models-page-footer.module.scss';

const _id = getNodeIdComposer(RootPrefix, 'footer');

export const ModelsPageFooter: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [getOrderUrl] = useGetOrderUrl();
    const orderLocation = useBuildLocation({
        to: getOrderUrl(),
    });
    const widgetModelId = useAppSelector(selectWidgetModelId);
    const selectedModelIds = useAppSelector(selectModelIds);

    const quote = useCallback(() => {
        const ids = selectedModelIds.map(id => parseInt(id));
        dispatch(modelsActions.addSelectedModels({ ids, isAppend: true }));
        dispatch(
            quotationActions.defineWidgetObjects({
                extendVisibleModels: ids,
                extendViewedModels: ids,
                modelId: widgetModelId ? undefined : ids[0],
            }),
        );
        dispatch(modelsPageActions.setSelectedModelIds([]));
        navigate(orderLocation.to);
    }, [dispatch, navigate, orderLocation.to, selectedModelIds, widgetModelId]);

    const count = selectedModelIds.length.toString();
    const isQuoteDisabled = !Boolean(selectedModelIds.length);

    const { t } = useTranslation();

    return (
        <div className={cn('base-paddings', 'no-top', styles.box)}>
            <FeatureContent
                contentKey={_id('quote')}
                fallback={t('buttons.quote', 'Quote')}
                wrapper={props => (
                    <Button
                        type="button"
                        theme="primary"
                        themeType="contained"
                        className={cn(['rmd-button--text-large', styles.quote])}
                        onClick={quote}
                        disabled={isQuoteDisabled}
                        {...props}
                    >
                        <TextIconSpacing
                            icon={
                                <div
                                    className={cn(styles.counter, {
                                        [styles.counterBig]: count.length > 2,
                                    })}
                                    data-place={count}
                                />
                            }
                            iconAfter
                            forceIconWrap
                        >
                            {props.children}
                        </TextIconSpacing>
                    </Button>
                )}
            />
        </div>
    );
};
