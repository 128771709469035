import { Typography } from 'react-md';

export interface StartPageTileData {
    title: string;
    imgSrc: string;
    description: JSX.Element;
    link: string;
}

export const START_PAGE_TILES: StartPageTileData[] = [
    {
        title: 'MJF',
        imgSrc: 'https://app.digifabster.com/img/start-page/tti/tech_01.png',
        description: (
            <div>
                <Typography>15 x 11 x 15 in</Typography>
                <Typography>(380 x 284 x 380 mm)</Typography>
            </div>
        ),
        link: 'https://app.digifabster.com/tti-floor-care-mjf/widget/upload',
    },
    {
        title: 'FDM',
        imgSrc: 'https://app.digifabster.com/img/start-page/tti/tech_02.png',
        description: (
            <div>
                <Typography>16 x 14 x 16 in</Typography>
                <Typography>(406 x 355 x 406 mm)</Typography>
            </div>
        ),
        link: 'https://app.digifabster.com/tti-floor-care-fdm/widget/upload',
    },
    {
        title: 'SLA',
        imgSrc: 'https://app.digifabster.com/img/start-page/tti/tech_03.png',
        description: (
            <div>
                <Typography>13.2 x 7.9 x 11.2 in</Typography>
                <Typography>(335 x 200 x 300 mm)</Typography>
            </div>
        ),
        link: 'https://app.digifabster.com/tti-floor-care-sla/widget/upload',
    },
    {
        title: 'Vacuum Casting',
        imgSrc: 'https://app.digifabster.com/img/start-page/tti/tech_04.png',
        description: (
            <div>
                <Typography>Molds and Final</Typography>
                <Typography>Geometry Required</Typography>
            </div>
        ),
        link: 'https://app.digifabster.com/tti-floor-care-vacuum-casting/widget/upload',
    },
    {
        title: 'CO2 Laser',
        imgSrc: 'https://app.digifabster.com/img/start-page/tti/tech_05.png',
        description: (
            <div>
                <Typography>24 x 40 in</Typography>
                <Typography>(609 x 1016 mm)</Typography>
            </div>
        ),
        link: 'https://app.digifabster.com/tti-floor-care-co2-laser/widget/upload',
    },
    {
        title: '3D Scanner',
        imgSrc: 'https://app.digifabster.com/img/start-page/tti/tech_06.png',
        description: (
            <div>
                <Typography>Accurate, Reliable</Typography>
                <Typography>& High-Resolution</Typography>
            </div>
        ),
        link: 'https://app.digifabster.com/tti-floor-care-3d-scanner/widget/upload',
    },
    {
        title: 'Custom Painting',
        imgSrc: 'https://app.digifabster.com/img/start-page/tti/tech_07.png',
        description: (
            <div>
                <Typography>CMF document Required</Typography>
                <Typography>if more then one color</Typography>
            </div>
        ),
        link: 'https://app.digifabster.com/tti-floor-care-custom-painting/widget/upload',
    },
];

interface StartPageInfo {
    title: string;
    description: string;
}

export const START_PAGE_INFO: StartPageInfo = {
    title: 'Step 1:',
    description: `Please choose the technology that best fits your order's needs.
    Orders are limited to a single technology. If you have any questions about
    technology selection, feel free to reach out to one of the Model Shop members.
    We'd be happy to help.`,
};
